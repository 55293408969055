import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../pages/_post-opportunity/forms/Tooltip';
import { BookmarkIcon } from '@heroicons/react/24/solid';

Bookmark.propTypes = {
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  tooltip: PropTypes.string
};

Bookmark.defaultProps = {
  selected: false,
  tooltip: '',
  onClick: () => {}
};

export default function Bookmark(props) {
  const { selected, className, tooltip, onClick } = props;

  const icon = selected ? (
    <BookmarkIcon className="w-10 h-10 text-cs-orange-500 stroke-white" />
  ) : (
    <BookmarkIcon className="w-10 h-10 text-white stroke-cs-gray-500" />
  );

  const content = (
    <div
      className={`cursor-pointer w-10 ${className}`}
      onClick={onClick}
    >
      {icon}
    </div>
  );

  if (tooltip) {
    return (
      <Tooltip tooltip={tooltip} icon={false}>
        {content}
      </Tooltip>
    );
  }

  return content;
};
