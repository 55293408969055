import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Overlay from '../../../forms/_common/Overlay';
import Tag from '../../../components/Tag';
import typeColorMap from '../../../maps/typeColorMap';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';

Ages.propTypes = {
  userAges: PropTypes.object
};

export default function Ages(props) {
  const {
    user,
    ages,
    userAges,
    selectedAges
  } = props;

  const mutation_create = useQueryMutation('tagNode.create');
  const mutation_destroy = useQueryMutation('tagNode.destroy2');

  const saving = (
    mutation_create.isLoading ||
    mutation_destroy.isLoading
  );

  function onSelectAge(age) {
    const userAge = _.find(userAges.data, function(userAge) {
      return userAge._tag.id === age.id;
    });

    if (!userAge) {
      mutation_create.mutate({
        userId: user.id,
        tagId: age.id
      }, {
        onSuccess: payload => userAges._add(payload)
      });
    } else {
      mutation_destroy.mutate(userAge._tagNode, {
        onSuccess: payload => userAges._remove(payload)
      });
    }
  }

  return (
    <Overlay isVisible={saving}>
      <div className="flex flex-wrap -m-4">
        <div className="w-full p-4">
          {ages.data.map(function(age) {
            const selectedAge = _.find(userAges.data, function(userAge) {
              return userAge._tag.id === age.id;
            });

            const selected = !!selectedAge;

            return (
              <Tag
                key={age.id}
                label={age.data.name}
                tooltip={age.data.description}
                selected={selected}
                color={typeColorMap['Experience']}
                onClick={() => onSelectAge(age, selectedAge)}
              />
            );
          })}
        </div>
      </div>
    </Overlay>
  );
}
