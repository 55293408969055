import React from 'react';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import ParentLayout from '../Layout';
import Urls from '../Urls';
import Sites from './Sites';
import { PayloadStates } from '@lore/utils';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { history, match } = useRouter();

  const user = useUser();

  const event = useConnect('event.byId', {
    id: Number(match.params.eventId)
  });

  const roles = useConnect('role.find', {
    where: {
      eager: {
        $where: {
          userId: user.id,
          siteId: {
            $exists: true
          }
        }
      }
    },
    pagination: {
      page: 1,
      pageSize: 1000,
      order: 'createdAt asc'
    }
  });

  if (
    event.state === PayloadStates.FETCHING ||
    roles.state === PayloadStates.FETCHING
  ) {
    return null;
  }

  return (
    <ParentLayout
      event={event}
      page="Site"
      title={(
        <>
          Which school or site is associated with this event?
        </>
      )}
      description={(
        <div className="space-y-4">
          <div>
            This is where the learners are! We've listed the schools or sites you
            specified as places you work. Feel free to delete or add new sites.
          </div>
          <div className="font-semibold">
            Choose ONE school or site for this event.
          </div>
        </div>
      )}
    >
      <Sites
        user={user}
        event={event}
        roles={roles}
        // navigation={(
        //   <Navigation v5={true} activeStep={1} steps={20}/>
        // )}
        // onPrevious={(newData) => history.push(Urls(event).HOME)}
        onNext={(newData) => history.push(Urls(event).ACTIVITY)}
        onSkip={() => history.push(Urls(event).ACTIVITY)}
      />
    </ParentLayout>
  );
};
