import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import validators from '../../../utils/validators';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import FormTemplate from '../../../dialogs_common/SetupTemplate';
import PrimaryButton from '../../../components_setup/PrimarySetupButton';
import Community from './Community';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import { Link } from 'react-router-dom';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';

Communities.propTypes = {
  user: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func,
  onSkip: PropTypes.func
};

export default function Communities(props) {
  const {
    user,
    navigation,
    onNext,
    onPrevious,
    onSkip
  } = props;

  const [data, setData] = useState(_.pick(user.data, [
    'networkId'
  ]));
  const [showErrors, setShowErrors] = useState(false);

  const mutation = useQueryMutation('currentUser.updateSingleton', user);
  const { saving, success, error, request } = mutation;

  const networks = useConnect('network.find', {
    where: {
      eager: {
        $where: {
          isActive: true
        }
      }
    },
    pagination: {
      pageSize: 100
    }
  });

  useEffect(() => {
    if (success) {
      onNext(data);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate(data);
  }

  function onChange(name, value) {
    setData(_.merge({}, data, {
      [name]: value
    }));
  }

  return (
    <div className="my-auto">
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          networkId: [validators.number.isRequired]
        }}>
          {({ errors, hasError }) => {
            return (
              <FormTemplate
                className="w-full"
                onPrevious={onPrevious}
                navigation={navigation}
                title="Which community are you here to join?"
                alert={error ? (
                  <RequestError request={request} v2={true} />
                ) : showErrors ? (
                  <ErrorMessage v2={true}>
                    Please fill in all required fields.
                  </ErrorMessage>
                ) : null}
                body={(
                  <>
                    <div className="flex flex-col space-y-2">
                      {networks.state === PayloadStates.FETCHING ? (
                        <Loader />
                      ) : (
                        <>
                          {_.sortBy(networks.data, a => a.data.name).map(function(network) {
                            return (
                              <Community
                                key={network.id || network.cid}
                                network={network}
                                onClick={() => onChange('networkId', network.id)}
                                selected={data.networkId === network.id}
                              />
                            );
                          })}
                        </>
                      )}
                    </div>
                    <div className="mt-16">
                      <h5 className="text-3xl text-cs-gray-900 font-semibold text-left m-auto mb-4">
                        Don't see your community?
                      </h5>
                      <Link to="/setup/unavailable" className="text-lg font-semibold text-cs-orange-500 hover:text-cs-yellow-500 underline">
                        I do not belong to any of the above.
                      </Link>
                    </div>
                  </>
                )}
                footer={(
                  <>
                    {/*<div className="text-cs-orange-500 underline cursor-pointer mt-6 sm:mt-0 mr-0 sm:mr-12" onClick={onSkip}>*/}
                    {/*  Skip this step*/}
                    {/*</div>*/}
                    <PrimaryButton disabled={hasError} onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
                      Next
                    </PrimaryButton>
                  </>
                )}
              />
            );
          }}
        </FormErrors>
      </Overlay>
    </div>
  );
};
