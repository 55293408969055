import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card from './Card';

FocusAreaEntry.propTypes = {
  entry: PropTypes.object.isRequired,
  subtitle: PropTypes.node,
  selectable: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  count: PropTypes.node
};

export default function FocusAreaEntry(props) {
  const {
    entry,
    subtitle,
    selectable,
    selected,
    onClick,
    count,
    icon
  } = props;

  return (
    <Card
      border={false}
      rounded={false}
      background={false}
      className={classNames(
          'flex -m-px border rounded-cs-10 p-6',
        selectable && 'cursor-pointer hover:bg-cs-orange-50',
        selected ? 'bg-cs-orange-50 border-cs-orange-500 text-white' : 'border-cs-gray-100 bg-white'
      )}
      onClick={onClick}
    >
      <div>
        <div className="flex items-center">
          {icon && (
            <div className="pr-1 text-white">
              <props.icon className="h-6 w-6 text-cs-gray-900" aria-hidden="true" />
            </div>
          )}
          <span className="text-base text-cs-gray-900">
            {entry.data.name}
          </span>
        </div>
        {subtitle && (
          <div className="text-sm text-cs-gray-500 mt-0.5">
            {subtitle}
          </div>
        )}
      </div>
    </Card>
  );
};
