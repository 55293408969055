export default function propTypeOneOfFunction(validFunctions = []) {
  return function(props, propName, componentName) {
    const name = props[propName].name;

    const validNames = validFunctions.map(function(func) {
      return func.name;
    });

    if (validNames.indexOf(name) < 0) {
      return new Error([
        `Invalid prop ${propName} supplied to ${componentName}.`,
        `Expected function with name in [${validNames.join(',')}].`,
        `Got ${name}.`
      ].join(' '));
    }
  }
}
