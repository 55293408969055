import React from 'react';
import { UsersIcon } from '@heroicons/react/24/outline';
import useRouter from '../../hooks/useRouter';
import Urls from '../event-create/Urls';
import DataCard from '../profile-edit/DataCard';
import usePageRouter from '../../dialogs-routable/_hooks/usePageRouter';

export default function StudentSize(props) {
  const { event } = props;
  const { history } = usePageRouter();

  return (
    <DataCard
      title="Student Group Size"
      icon={UsersIcon}
      subtitle={event.data.groupSize || '(not provided)'}
      onClick={() => history.push(Urls(event).STUDENT_SIZE)}
    />
  );
};
