import React from 'react';
import { PhotoIcon } from '@heroicons/react/24/outline';
import useRouter from '../../hooks/useRouter';
import Urls from '../profile/Urls';
import DataCard from './DataCard';
import ProfilePhoto from '../../components/Avatar';

export default function Avatar(props) {
  const { user } = props;

  const { history } = useRouter();

  return (
    <DataCard
      title="Profile Photo"
      icon={PhotoIcon}
      // subtitle={user.data.avatarUrl ? 'Yes' : '(not provided)'}
      subtitle={(
        <ProfilePhoto
          className="mt-1"
          size={10}
          src={user.data.avatarUrl}
        />
      )}
      onClick={() => history.push(Urls(user).AVATAR)}
    />
  );
};
