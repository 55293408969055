import React from 'react';
import GreenTag from '../_base/GreenTag';

export default function CompletedTag(props) {
  const { resource } = props;

  if (!resource.data.completed) {
    return null;
  }

  return (
    <GreenTag label="COMPLETED" />
  );
}
