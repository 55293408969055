import { extractArrayFromQuery } from '../../../utils/query-utils';

export default {
  param: extractArrayFromQuery,
  query: function(values) {
    if (values.length > 0) {
      return {
        $or: [
          {
            'project.networkId': {
              $in: values
            }
          },
          {
            'event.networkId': {
              $in: values
            }
          }
        ]
      }
    }
  }
}
