import React from 'react';
import { DateTime } from 'luxon';
import HiddenTag from '../../../../components_cards/tags/_resource/HiddenTag';
import MissingFieldsTag from '../../../../components_cards/tags/MissingFieldsTag';
import CancelledTag from '../../../../components_cards/tags/_resource/CancelledTag';
import CompletedTag from '../../../../components_cards/tags/_resource/CompletedTag';
import ExpiredTag from '../../../../components_cards/tags/_resource/ExpiredTag';
import useMissingFields from '../../../offer-manage/useMissingFields';

export default function StatusTags(props) {
  const { offer } = props;

  const { missingFields } = useMissingFields(offer);

  if (offer.data.cancelled) {
    return (
      <div className="flex space-x-4">
        <CancelledTag resource={offer} />
        {!offer.data.published && (
          <HiddenTag resource={offer} />
        )}
      </div>
    );
  }

  if (offer.data.completed) {
    return (
      <div className="flex space-x-4">
        <CompletedTag resource={offer} />
        {!offer.data.published && (
          <HiddenTag resource={offer} />
        )}
      </div>
    );
  }

  const diff = DateTime.now().diff(DateTime.fromISO(offer.data.endDate)).as('days');

  if (
      diff >= 0 ||
      !offer.data.published ||
      missingFields.data.length > 0
  ) {
    return (
      <div className="flex space-x-4">
        {diff >= 0 && (
          <ExpiredTag resource={offer} />
        )}
        {!offer.data.published && (
          <HiddenTag resource={offer} />
        )}
        {missingFields.data.length > 0 && (
          <MissingFieldsTag resource={offer} />
        )}
      </div>
    );
  }

  return null;
}
