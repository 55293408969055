import React from 'react';
import { Helmet } from 'react-helmet-async';
import PendingEvaluations from './PendingEvaluations';
import CompletedEvaluations from './CompletedEvaluations';
import PageHeader from '../people-people/PageHeader';

function Header(props) {
  const { children } = props;

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Share your impact</title>
      </Helmet>
      <PageHeader
        title="Share your impact"
        subtitle={(
          <>
            Take a moment to reflect on your experience and impact – it takes just 15 minutes! Your
            insights contribute valuable ideas to our community and help us strengthen our program.
            Whether your project was a success or a valuable learning experience, your input matters.
          </>
        )}
      />
      <div className="space-y-8">
        {children}
      </div>
    </div>
  );
}

export default function Layout(props) {
  return (
    <Header>
      <PendingEvaluations />
      {/*<CompletedEvaluations />*/}
    </Header>
  );
}
