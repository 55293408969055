export default function(evaluation) {
  return {
    START: `/evaluation-event-educator/${evaluation.id}/start`,
    STUDENTS: `/evaluation-event-educator/${evaluation.id}/students`,

    ENGAGED: `/evaluation-event-educator/${evaluation.id}/engaged`,
    SKILLS: `/evaluation-event-educator/${evaluation.id}/skills`,
    AWARENESS: `/evaluation-event-educator/${evaluation.id}/awareness`,
    UNDERSTANDING: `/evaluation-event-educator/${evaluation.id}/understanding`,
    PRACTICED: `/evaluation-event-educator/${evaluation.id}/practiced`,

    DESCRIPTION: `/evaluation-event-educator/${evaluation.id}/description`,
    CHANGES: `/evaluation-event-educator/${evaluation.id}/changes`,

    UPLOADS: `/evaluation-event-educator/${evaluation.id}/uploads`,

    RECOMMEND: `/evaluation-event-educator/${evaluation.id}/recommend`,
    END: `/evaluation-event-educator/${evaluation.id}/end`,
    PROJECT_MANAGE: `/event/${evaluation.data.eventId}/manage`
  }
};
