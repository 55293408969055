import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Overlay from '../../../forms/_common/Overlay';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import TagCheckboxCards from '../_common/TagCheckboxCards';

Subjects.propTypes = {
  userSubjects: PropTypes.object
};

export default function Subjects(props) {
  const {
    user,
    subjects,
    userSubjects,
    selectable
  } = props;

  const launch = useDialogLauncher();

  const mutation_create = useQueryMutation('tagNode.create');
  const mutation_destroy = useQueryMutation('tagNode.destroy2');

  const saving = (
    mutation_create.isLoading ||
    mutation_destroy.isLoading
  );

  function onToggleSubject(subject) {
    const userSubject = _.find(userSubjects.data, function(userSubject) {
      return userSubject._tag.id === subject.id;
    });

    if (!userSubject) {
      mutation_create.mutate({
        userId: user.id,
        tagId: subject.id
      }, {
        onSuccess: payload => userSubjects._add(payload)
      });
    } else {
      mutation_destroy.mutate(userSubject._tagNode, {
        onSuccess: payload => userSubjects._remove(payload)
      });
    }
  }

  function onCreateUserSubject(subject, userSubject) {
    launch(DialogManager.TagNodeCreate.Config(subject.id, {
      modelName: 'user',
      modelId: user.id
    }));
  }

  function onUpdateUserSubject(subject, userSubject) {
    launch(DialogManager.TagNodeUpdate.Config(userSubject._tagNode.id));
  }

  function onDestroyUserSubject(subject, userSubject) {
    launch(DialogManager.TagNodeDestroy.Config(userSubject._tagNode.id));
  }

  return (
    <Overlay isVisible={saving}>
      <TagCheckboxCards
        tags={subjects}
        facadeTagNodes={userSubjects}
        onChange={function(tag, facadeTagNode) {
          if (facadeTagNode?._tagNode.data.description) {
            return onDestroyUserSubject(tag, facadeTagNode);
          }

          return onToggleSubject(tag);
        }}
        onUpdateDescription={onUpdateUserSubject}
        selectable={selectable}
      />
    </Overlay>
  );
}
