import React from 'react';
import PropTypes from 'prop-types';
import FormField from '../../forms/_common/FormField';
import { OutlineButton, SolidButton } from '../../components/Button';
import TextAreaField from '../../forms/_fields_v2/TextAreaField';
import FormTemplate from '../../dialogs_common/FormTemplate';
import FormSection from '../../pages/_post-opportunity/forms/FormSection';
import Avatar from '../../components/Avatar';
import { DateTime } from 'luxon';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';
import Message from '../../pages/project-messages-user/Message';
import Card from '../../components/Card';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onCancel,
    claim
  } = props;

  const currentUser = useUser();

  const offer = useConnect('offer.byId', {
    id: claim.data.offerId
  });

  const creator = useConnect('user.byId', {
    id: offer.data.creatorId
  }, {
    enabled: !!offer.data.creatorId
  });

  if (offer.state === PayloadStates.FETCHING) {
    return (
      <FormTemplate
        title="Continue Connecting"
        subtitle={(
          <>
            On the next screen, you'll be able to chat directly with the partner throughout the
            offer. It's a place provide feedback, recommend others to be involved, or ask any
            questions you have about the offer and to begin planning together.
          </>
        )}
        onClose={onCancel}
        body={(
          <div>
            <Loader />
          </div>
        )}
        footer={(
          <>
            <OutlineButton onClick={onCancel}>
              Cancel
            </OutlineButton>
          </>
        )}
      />
    );
  }

  return (
    <FormTemplate
      title="Continue Connecting"
      subtitle={(
        <>
          On the next screen, you'll be able to chat directly with the partner throughout the
          offer. It's a place provide feedback, recommend others to be involved, or ask any
          questions you have about the offer and to begin planning together.
        </>
      )}
      onClose={onCancel}
      alert={alert}
      body={(
        <div className="space-y-10">
          {/*<p className="m-modal__description">*/}
          {/*  Tell us your idea. You can always change things later.*/}
          {/*</p>*/}
          <FormSection
            title={`Here's a reminder of the message ${creator.data.firstName} sent to you.`}
          >
            <Card className="p-4 w-full">
              <Message message={{
                data: {
                  senderId: offer.data.creatorId,
                  content: claim.data.requestMessage,
                  // createdAt: question.data.createdAt,
                }
              }} />
            </Card>
          </FormSection>
          <FormSection
            // title="What additional information would you like to know?*"
            title={`How would you like to respond to ${creator.data.firstName}?`}
            subtitle="Once you're ready, send this message and we'll take you to a screen where you can continue the conversation."
          >
            <div className="flex space-x-4 mt-8">
              <Avatar size={12} src={currentUser.data.avatarUrl}/>
              <div className="flex-1">
                <FormField name="customMessage" data={data} errors={showErrors && errors} onChange={onChange}>
                  {(field) => (
                    <TextAreaField
                      // label="Message*"
                      placeholder="Enter message..."
                      rows={4}
                      value={field.value}
                      error={field.error}
                      onChange={field.onChange}
                    />
                  )}
                </FormField>
              </div>
            </div>
          </FormSection>
        </div>
      )}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onCancel}>
            Cancel
          </OutlineButton>
          <SolidButton onClick={onSubmit}>
            Send Message
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
