import React from 'react';
import { useUser } from '@lore/auth';
import ParentLayout from '../profile/ParentLayout';
import User from '../user/User';
import PageHeader from '../people-people/PageHeader';

export default function(props) {
  const currentUser = useUser();

  return (
    <ParentLayout page="View">
      <div className="p-8 space-y-8">
        <PageHeader
          title="Profile"
          subtitle="This is how your profile appears to other people."
        />
        <User user={currentUser} />
      </div>
    </ParentLayout>
  );
};
