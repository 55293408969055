import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useDialogConfig from '../_hooks/useDialogConfig';
import Dialog from '../../dialogs/evaluation_project_partner';

export default function Launcher(props) {
  const config = useDialogConfig();

  const evaluationProjectPartner = useConnect('evaluationProjectPartner.byId', {
    id: config.params.evaluationProjectPartnerId
  });

  if (evaluationProjectPartner.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <Dialog
      evaluationProjectPartner={evaluationProjectPartner}
      onClose={props.onClose}
    />
  );
};
