import React, { useState } from 'react';
import _ from 'lodash';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import Question from './Question';

export default function Questions(props) {
  const { project, responses = [], emptyState, onViewMessages } = props;

  const [timestamp] = useState(new Date().toISOString());

  const questions = useConnect('question.find', {
    where: {
      eager: {
        $where: {
          projectId: project.id,
          response: {
            $or: responses
          },
          createdAt: {
            $lt: timestamp
          }
        }
      }
    },
    pagination: {
      pageSize: 100
    }
  });

  if (questions.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  if (questions.data.length === 0) {
    return (
      <div className="space-y-2">
        {emptyState}
      </div>
    );
  }

  return _.sortBy(questions.data, function(question) {
    return {
      Connect: 0,
      Pending: 1,
      Decline: 2
    }[question.data.response];
  }).map(function(question, index) {
    return (
      <Question
        key={question.id || question.cid}
        question={question}
        onViewMessages={onViewMessages}
      />
    );
  });
};
