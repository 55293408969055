import React from 'react';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import { SolidButton } from '../../../components/Button';
import GrayOutlineButton from '../../../components/Button/OutlineButton.gray';
import ComposableEventHeader from '../../../components_cards/ComposableEventHeader';
import DividerCard from '../../../components/DividerCard';
import TagRow from '../../../components_cards/tags/TagRow';
import EventTag from '../../../components_cards/tags/EventTag';
import ExpiredTag from '../../../components_cards/tags/_resource/ExpiredTag';
import CreatorTag from '../../../components_cards/tags/CreatorTag';
import EventMissingFieldsTag from '../../../components_cards/ComposableEventHeader/EventMissingFieldsTag';

export default function ExpiredEvent(props) {
  const { event } = props;

  const launch = useDialogLauncher();

  function onClickView() {
    launch(DialogManager.EventManage.Config(event.id, `/view`));
  }

  function onClickEdit() {
    launch(DialogManager.EventManage.Config(event.id, `/edit`));
  }

  return (
    <DividerCard>
      <ComposableEventHeader
        event={event}
        tags={(
          <TagRow>
            <EventTag />
            {/*<CreatorTag />*/}
            <EventMissingFieldsTag event={event} />
            {/*<ExpiredTag resource={event} />*/}
          </TagRow>
        )}
        showTags={false}
        showCreator={false}
      />
      <div className="w-full bg-cs-gray-50 py-4 px-4 flex justify-between items-center">
        <div />
        <div className="flex flex-wrap gap-2">
          <GrayOutlineButton
            onClick={onClickView}
            label="View event"
          />
          <SolidButton
            onClick={onClickEdit}
            label="Continue describing event"
          />
        </div>
      </div>
    </DividerCard>
  );
}
