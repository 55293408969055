import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import SectionTitle from '../project-manage/SectionTitle';
import Invitation from './Invitation';

export default function InvitationsMaybe(props) {
  const { event, onViewMessages } = props;

  const invitations = useConnect('invitation.find', {
    where: {
      eager: {
        $where: {
          eventId: event.id,
          response: 'Maybe'
        }
      }
    },
    pagination: {
      pageSize: 100
    }
  });

  if (invitations.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  if (invitations.data.length === 0) {
    return null;
  }

  return (
    <SectionTitle
      title="Potential Partners"
      subtitle="These partners said they might be able to attend the event, engaging with you and/or your students virtually or in person."
    >
      <div className="grid grid-cols-1 gap-4">
        {invitations.data.map(function (invitation, index) {
          return (
            <Invitation
              key={invitation.id || invitation.cid}
              invitation={invitation}
              onViewMessages={onViewMessages}
            />
          );
        })}
      </div>
    </SectionTitle>
  );
};
