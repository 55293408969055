import React from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import { Helmet } from 'react-helmet-async';
import SectionTitle from '../_common/SectionTitle';
import Students from './sections/Students';
import Outcome from './sections/Outcome';
import Reflections from './sections/Reflections';
import Recommend from './sections/Recommend';
import Photographs from './sections/Photographs';
import Card from '../../components/Card';
import EventHeader from '../../components_cards/ComposableEventHeader';

Template.propTypes = {
  event: PropTypes.object.isRequired
};

export default function Template(props) {
  const { event, evaluationEventEducator } = props;

  if (event.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  const page = `Educator Event Evaluation #${evaluationEventEducator.id}`;

  return (
    <div className="space-y-6 -mt-12">
      <Helmet>
        <title>
          {event.data.title ? `${event.data.title}: ${page}` : page}
        </title>
      </Helmet>
      <Header
        event={event}
        evaluationEventEducator={evaluationEventEducator}
      />
      <div className="space-y-12">
        <SectionTitle
          title="Event"
          subtitle="The activity the evaluation is for."
        >
          <Card>
            <EventHeader
              event={event}
              showTags={false}
            />
          </Card>
        </SectionTitle>
      </div>
      <div className="space-y-12">
        <SectionTitle
          title="Response"
          subtitle="What you said about your experience."
        >
          <div className="grid grid-cols-1 gap-4">
            <Students evaluation={evaluationEventEducator} />
            <Outcome evaluation={evaluationEventEducator} />
            <Reflections evaluation={evaluationEventEducator} />
            <Recommend evaluation={evaluationEventEducator} />
            <Photographs evaluation={evaluationEventEducator} />
          </div>
        </SectionTitle>
      </div>
    </div>
  );
};
