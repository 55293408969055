import React, { useState } from 'react';
import { UsersIcon } from '@heroicons/react/24/outline';
import { PayloadStates } from '@lore/utils';
import useRouter from '../../hooks/useRouter';
import Urls from '../profile/Urls';
import DataCard from './DataCard';
import useUserTags from '../../hooks/useUserTags';
import TagTypes from '../../constants/TagTypes';
import UserTagCards from '../setup/experience/UserTagCards';
import EditLink from './EditLink';
import useTags from '../../hooks/useTags';

export default function ExpertiseCards(props) {
  const { user } = props;

  const { history } = useRouter();

  const skills = useTags(TagTypes.Expertise);
  const userSkills = useUserTags(user, TagTypes.Expertise);

  let subtitle = '';

  if (userSkills.state === PayloadStates.FETCHING) {
    subtitle = '...';
  } else {
    subtitle = userSkills.data.map(function(userSkill, index) {
      return (
        <div key={index}>
          {userSkill._tag.data.name}
        </div>
      );
    });
  }

  if (
    skills.state === PayloadStates.FETCHING ||
    userSkills.state === PayloadStates.FETCHING ||
    userSkills.data.length === 0
  ) {
    return (
      <DataCard
        title="Skills"
        icon={UsersIcon}
        subtitle={subtitle || '(not provided)'}
        onClick={() => history.push(Urls(user).SKILLS)}
      />
    );
  }

  const filteredSkills = {
    ...skills,
    data: skills.data.filter(function(skill) {
      return !!_.find(userSkills.data, function(userSkill) {
        return userSkill._tag.id === skill.id;
      });
    })
  }

  return (
    <div className="space-y-4">
      <UserTagCards
        user={user}
        tags={filteredSkills}
        userTags={userSkills}
        selectable={false}
      />
      <EditLink
        title="Change skills and experience"
        onClick={() => history.push(Urls(user).SKILLS)}
      />
    </div>
  );
};
