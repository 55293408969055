import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormField from '../../forms/_common/FormField';
import { OutlineButton, SolidButton } from '../../components/Button';
import StringField from '../../forms/_fields_v2/StringField';
import FormTemplate from '../../dialogs_common/FormTemplate';
import SelectField from '../../forms/_fields_v2/SelectField';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';

Form.propTypes = {
  user: PropTypes.object.isRequired,
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

Form.defaultProps = {
  data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    user,
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onChangeMultiple,
    onSubmit,
    onCancel
  } = props;

  const [isAutocomplete, setIsAutocomplete] = useState(true);

  return (
    <FormTemplate
      title="Change job"
      // subtitle="Locations you create are visible only to you"
      onClose={onCancel}
      alert={alert}
      body={(
        <>
          <FormField name="title" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <StringField
                label="Position/Title"
                value={field.value}
                error={field.error}
                onChange={field.onChange}
              />
            )}
          </FormField>
        </>
      )}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onCancel}>
            Cancel
          </OutlineButton>
          <SolidButton onClick={onSubmit}>
            Save
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
