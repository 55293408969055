import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { UsersIcon } from '@heroicons/react/24/outline';
import { OutlineButton, SolidButton } from '../../../components/Button';
import FormTemplate from '../../../dialogs_common/FormTemplate';
import EmptyState from '../../../components/EmptyState';
import { useConnect } from '@lore/query-connect';
import Question from './Question';
import FormTemplateButtonFooter from '../../../dialogs_common/FormTemplateButtonFooter';

Classify.propTypes = {
  project: PropTypes.object
};

export default function Classify(props) {
  const {
    project,
    onCancel,
    onPrevious,
    onNext
  } = props;

  const [timestamp] = useState(new Date().toISOString())

  const questions = useConnect('question.find', {
    where: {
      eager: {
        $where: {
          projectId: project.id,
          response: {
            $in: [
              'Connect'
            ]
          },
          createdAt: {
            $lt: timestamp
          }
        }
      }
    },
    pagination: {
      pageSize: 100
    }
  });

  return (
    <FormTemplate
      icon={(
        <div className="flex justify-center mb-4">
          <UsersIcon className="text-cs-orange-500 h-12 w-12" />
        </div>
      )}
      title="Who contributed to this project?"
      subtitle={(
        <>
          The partners you connected with are listed below. Let us know how they
          contributed so we can give them credit for helping you. Partners you
          worked with directly will also receive an evaluation to generate funding
          in the community for more projects like yours.
        </>
      )}
      onClose={onCancel}
      body={(
        <div className="flex justify-center space-y-8">
          {questions.data.length === 0 ? (
            <EmptyState>
              There are no partners needing classification.
            </EmptyState>
          ) : (
            <div className="grid grid-cols-1 gap-4 w-full">
              {questions.data.map(function(question) {
                return (
                  <Question
                    key={question.id || question.cid}
                    question={question}
                  />
                );
              })}
            </div>
          )}
        </div>
      )}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onCancel}>
            Close
          </OutlineButton>
          <SolidButton onClick={onNext}>
            Next
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
