import React from 'react';
import Card from './Card';
import classNames from 'classnames';

export default function DividerCard(props) {
  const { children, className, border } = props;

  return (
    <Card border={border} className={classNames(
      'overflow-hiddenx divide-y divide-gray-100',
      className
    )}>
      {children}
    </Card>
  );
};
