import React from 'react';
import { CalendarIcon } from '@heroicons/react/24/outline';
import IconSection from '../../_new_card_components/IconSection';
import { formatDate, formatDateRange } from '../../../utils/dateTimeUtils';

export default function Date(props) {
  const { offer } = props;

  if (offer.data.specificDate === 'Yes') {
    return (
      <IconSection
        icon={CalendarIcon}
        title={formatDate(offer.data.date)}
      />
    );
  }

  return (
    <IconSection
      icon={CalendarIcon}
      title={formatDateRange(offer.data.startDate, offer.data.endDate)}
    />
  );
}
