import React, { useState } from 'react';
import { useConnect } from '@lore/query-connect';
import useRouter from '../../hooks/useRouter';
import Urls from '../profile/Urls';
import DataCard from './DataCard';
import { PayloadStates } from '@lore/utils';
import Job from '../setup/organization/Job';
import EditLink from './EditLink';

export default function Organizations(props) {
  const { user } = props;

  const { history } = useRouter();

  const jobs = useConnect('job.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    },
    pagination: {
      page: 1,
      pageSize: 100,
      order: 'createdAt asc'
    }
  });

  return (
    <div className="space-y-6">
      {jobs.data.map(function(job) {
        return (
          <Job
            key={job.id}
            job={job}
          />
        );
      })}
      <EditLink
        title="Add or change organizations"
        onClick={() => history.push(Urls(user).ORGANIZATIONS)}
      />
    </div>
  );
};
