import React from 'react';
import ChildLayout from '../user/Layout'
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import { Helmet } from 'react-helmet-async';
import useRouter from '../../hooks/useRouter';

export default function Layout(props) {
  const { location, history, match } = useRouter();

  const project = useConnect('project.byId', {
    id: Number(match.params.projectId)
  });

  if (project.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <>
      <Helmet>
        <title>Project #{`${project.id}`} - Invite Partner</title>
      </Helmet>
      <ChildLayout
        {...props}
        project={project}
        header={false}
      />
    </>
  );
};
