import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import validators from '../../../utils/validators';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import SolidButton from '../../../components/Button/SolidButton';
import FormTemplate2 from '../../_post-opportunity/FlowTemplate';
import FormSection from '../../_post-opportunity/forms/FormSection';
import NodeEntries from '../NodeEntries';
import useTags from '../../../hooks/useTags';
import TagTypes from '../../../constants/TagTypes';
import useProjectTags from '../../../hooks/useProjectTags';
import { useQueryMutation } from '@lore/query-connect';

Form.propTypes = {
  user: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func,
  onSkip: PropTypes.func
};

const MAX_LENGTH = 1000;

export default function Form(props) {
  const {
    user,
    project,
    navigation,
    onNext,
    onPrevious,
    onSkip,
    onExit,
    FormTemplate = FormTemplate2,
    buttonText = 'Next'
  } = props;

  const [data, setData] = useState(_.pick(project.data, [
    // 'desiredOutcome',
  ]));
  const [showErrors, setShowErrors] = useState(false);
  const [exit, setExit] = useState(false);

  const mutation = useQueryMutation('project.update', project);
  const { saving, success, error, request } = mutation;

  const subjects = useTags(TagTypes.Subject);
  const projectSubjects = useProjectTags(project, TagTypes.Subject);

  useEffect(() => {
    if (success) {
      exit ? onExit(data) : onNext(data);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate(data);
  }

  function onSubmitAndExit() {
    onSubmit();
    setExit(true);
  }

  function onChange(name, value) {
    setData(_.merge({}, data, {
      [name]: value
    }));
  }

  return (
    <Overlay isVisible={saving}>
      <FormErrors data={data} validators={{
        desiredOutcome: [validators.maxLength(MAX_LENGTH)],
        interactionDescription: [validators.maxLength(MAX_LENGTH)]
      }}>
        {({ errors, hasError }) => {
          return (
            <FormTemplate
              onExit={() => hasError ? setShowErrors(true) : onSubmitAndExit()}
              onPrevious={onPrevious}
              navigation={navigation}
              // title="Step 3: Description"
              // subtitle="What changes/results do you hope to see in you or your student(s) as a result of this interaction?"
              // titleLink={(
              //   <div className="text-base font-normal text-cs-orange-500 underline cursor-pointer lg:absolute right-0 top-0 leading-10 mt-3" onClick={onSkip}>
              //     Skip this step
              //   </div>
              // )}
              alert={error ? (
                <RequestError request={request} />
              ) : showErrors ? (
                <ErrorMessage>
                  Please fill in all required fields.
                </ErrorMessage>
              ) : null}
              body={(
                <FormSection
                  // title="Subjects"
                  // subtitle="Please select ALL that apply."
                >
                  {/*<Subjects*/}
                  {/*  request={project}*/}
                  {/*  subjects={subjects}*/}
                  {/*  requestSubjects={requestSubjects}*/}
                  {/*  selectedSubjects={requestSubjects.data.map(requestSubject => {*/}
                  {/*    return _.find(subjects.data, { id: requestSubject.data.subjectId });*/}
                  {/*  })}*/}
                  {/*/>*/}
                  <NodeEntries
                    project={project}
                    tags={subjects}
                    nodes={projectSubjects}
                  />
                </FormSection>
              )}
              footer={(
                <>
                  <SolidButton onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
                    {buttonText}
                  </SolidButton>
                </>
              )}
            />
          );
        }}
      </FormErrors>
    </Overlay>
  );
};
