import React, { useState } from 'react';
import { ClockIcon } from '@heroicons/react/24/outline';
import useRouter from '../../hooks/useRouter';
import Urls from '../offer-create/Urls';
import DataCard from '../profile-edit/DataCard';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import useOfferTags from '../../hooks/useOfferTags';
import TagTypes from '../../constants/TagTypes';
import { formatTimeRange } from '../../utils/dateTimeUtils';
import usePageRouter from '../../dialogs-routable/_hooks/usePageRouter';

export default function Time(props) {
  const { offer } = props;
  const [timestamp] = useState(new Date().toISOString());

  const { history } = usePageRouter();
  const currentUser = useUser();

  let subtitle = '';

  const offerTimes = useOfferTags(offer, TagTypes.Time, timestamp);

  if (offer.data.specificTime === 'Yes') {
    subtitle = formatTimeRange(offer.data.startTime, offer.data.endTime, currentUser.data.timezone);
  } else if(offerTimes.state === PayloadStates.FETCHING) {
    subtitle = '...';
  } else {
    subtitle = offerTimes.data.map(function(offerTime) {
      return offerTime.data.name;
    }).join(', ');
  }

  return (
    <DataCard
      title="Time"
      icon={ClockIcon}
      subtitle={subtitle || '(not provided)'}
      onClick={() => history.push(Urls(offer).TIME)}
    />
  );
};
