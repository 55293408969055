import React from 'react';
import PropTypes from 'prop-types';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import Offer from '../../pages/offer/Offer';
import CloseDialogButton from '../../dialogs_common/CloseDialogButton';
import { useConnect } from '../../../hooks/@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
// import Banner from '../../pages/offer/Banner';
import Banner from './Banner';

ClaimViewDialog.propTypes = {
  claim: PropTypes.object.isRequired
};

export default function ClaimViewDialog(props) {
  const { claim } = props;

  const offer = useConnect('offer.byId', {
    id: claim.data.offerId
  });

  if (offer.state === PayloadStates.FETCHING) {
    return (
      <DialogTemplate className="max-w-screen-lg">
        <Loader />
      </DialogTemplate>
    );
  }

  return (
    <DialogTemplate className="max-w-screen-lg">
      <CloseDialogButton onCancel={props.onCancel} />
      <div className="px-6 pt-16 pb-6">
        <div className="space-y-8">
          <Banner
            claim={claim}
            offer={offer}
          />
          <Offer offer={offer} />
        </div>
      </div>
    </DialogTemplate>
  );
};
