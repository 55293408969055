import React from 'react';
import PropTypes from 'prop-types';
import InteractionType from './InteractionType';
import { BuildingOfficeIcon } from '@heroicons/react/24/outline';

InPerson.propTypes = {
  interactionType: PropTypes.object.isRequired
};

export default function InPerson(props) {
  const { interactionType, selected, onClick } = props;

  return (
    <InteractionType
      entry={interactionType}
      title={interactionType.data.name}
      icon={BuildingOfficeIcon}
      subtitle={(
        <span>
          Connect with partners in your local community. These can require a little more
          effort but tend to have a greater impact on students.
        </span>
      )}
      selectable={true}
      selected={selected}
      onClick={() => onClick(interactionType)}
    />
  );
}
