import React from 'react';
import { parse } from 'query-string';
import RemoveLoadingScreen from '../RemoveLoadingScreen';
import letter from '../../../assets/images/letter_open.png';
import pattern from '../../../assets/images/patterns/balloons.png';
import { Helmet } from 'react-helmet-async';
import useRouter from '../../hooks/useRouter';
import SolidButton from '../../components/Button/SolidButton';
import Card from '../../components/Card';
import classNames from 'classnames';

export default function Layout(props) {
  const { history, location, match } = useRouter();

  const { email } = parse(location.search);

  return (
    <div className="p-2 md:p-6">
      <Helmet>
        <title>Unauthorized</title>
      </Helmet>
      <RemoveLoadingScreen/>
      <Card
        border={false}
        className={classNames(
          'shadow-welcome max-w-screen-md mx-auto',
          'py-12 px-10',
          'space-y-12'
        )}
      >
        {/*<div*/}
        {/*  className={classNames(*/}
        {/*    '-mx-10 -mt-12 rounded-t-cs-10 h-40 w-100 overflow-hidden relative flex justify-center items-center'*/}
        {/*  )}*/}
        {/*  style={{ backgroundColor: 'rgb(255, 153, 0)'}}*/}
        {/*>*/}
        {/*  <img src={pattern} className="h-40 object-cover absolute z-0 opacity-50 bg-orange-500x" alt="Background pattern" />*/}
        {/*  <h1 className="text-white relative z-1 text-3xl text-center">*/}
        {/*    Welcome to CommunityShare!*/}
        {/*  </h1>*/}
        {/*</div>*/}
        <div>
          <div className="mb-12">
            <img className="h-48 m-auto" src={letter} alt="logo"/>
          </div>
          <h5 className="text-3xl font-semibold text-cs-gray-900 text-center max-w-xl m-auto">
            Please check your email inbox
          </h5>
          <h6 className="text-base text-cs-gray-700 text-center mt-4">
            <div>
              Let's verify this account belongs to you.
            </div>
            <div>
              We sent a verification email to <span className="font-semibold">{email || 'your inbox'}.</span>
            </div>
            <div>
              Make sure to check your spam!
            </div>
          </h6>
        </div>
        <div className="flex justify-center">
          <SolidButton className="px-20" onClick={() => history.push('/login')}>
            I clicked the verification link
          </SolidButton>
        </div>
        <div className="flex items-center justify-end">
          <div className="text-base text-cs-gray-700 text-center m-auto">
            <div>Need help?</div>
            <div>
              Ask us at <a className="text-cs-orange-500" href="mailto:support@communityshare.org">support@communityshare.org</a>.
            </div>
          </div>
        </div>
      </Card>
      {/*<div className="bg-cs-yellow-500 fixed top-0 bottom-0 left-0 right-0 -z-1" />*/}
      {/*<div className="bg-cs-yellow-500 fixed top-0 bottom-0 left-0 right-0 -z-1" />*/}
      <div className="bg-gradient-to-b from-cs-yellow-100 to-cs-yellow-300 fixed top-0 bottom-0 left-0 right-0 -z-1" />
    </div>
  );
}
