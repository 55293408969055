import React from 'react';
import PropTypes from 'prop-types';
import InteractionType from './InteractionType';
import { GlobeAltIcon } from '@heroicons/react/24/outline';

Virtual.propTypes = {
  interactionType: PropTypes.object.isRequired
};

export default function Virtual(props) {
  const { interactionType, selected, onClick } = props;

  return (
    <InteractionType
      entry={interactionType}
      title={interactionType.data.name}
      icon={GlobeAltIcon}
      subtitle={(
        <span>
          Connect with partners in your local community as well as other communities
          across the nation. You'll have a wider range of opportunities. Virtual also
          tends to be lower effort.
        </span>
      )}
      selectable={true}
      selected={selected}
      onClick={onClick}
    />
  );
}
