import { useEffect } from 'react';
import { DialogManager } from '../../dialogs_new/DialogManager';
import useDialogLauncher from '../../dialogs-routable/_hooks/useDialogLauncher';

export default function Launcher(props) {
  const { user } = props;

  const launch = useDialogLauncher();

  useEffect(() => {
    launch(DialogManager.Messages.Config(user.id));
  }, []);

  return null;
}
