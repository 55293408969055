import React from 'react';
import classNames from 'classnames';
import Site from '../../pages/my-projects/Project/Header-new/Site';
import DateRange from '../../pages/my-projects/Project/Header-new/DateRange';
import Creator from '../../pages/my-projects/Project/Header-new/Creator';
import Title from './Title';
import Description from './Description';
import StatusTags from '../../pages/my-projects/Project/Header-new/StatusTags';
import { useConnect } from '../../../hooks/@lore/query-connect';
import Distance from '../../pages/projects/Project/Distance';
import QuestionMeta from './QuestionMeta';
import Meta from './Meta';

export default function ComposableQuestionHeader(props) {
  const {
    question,
    tags,
    onClick,
    showTags = true,
    showTitle = true,
    showDescription = true,
    showDateRange = true,
    showCreator = true,
    showSite = true,
    showDistance = true,
    showQuestionMeta = true
  } = props;

  const project = useConnect('project.byId', {
    id: question.data.projectId
  });

  return (
    <div className="flex flex-col p-4 space-y-2">
      <div className="space-y-2">
        {tags}
        {showTags && (
          <StatusTags project={project}/>
        )}
        <div
          className={classNames(
            'space-y-1 pr-10',
            onClick && 'cursor-pointer hover:text-cs-gray-500'
          )}
          onClick={onClick ? () => onClick(project) : undefined}
        >
          {showTitle && (
            <Title question={question} />
          )}
          {showDescription && (
            <Description question={question}/>
          )}
        </div>
      </div>
      <div className="flex justify-between flex-wrap gap-x-4 gap-y-2">
        <div className="flex flex-wrap gap-x-4 gap-y-2">
          {showDateRange && (
            <DateRange project={project}/>
          )}
          {showCreator && (
            <Creator project={project}/>
          )}
          {showSite && (
            <Site project={project}/>
          )}
          {/*{showQuestionMeta && (*/}
          {/*  <QuestionMeta question={question} />*/}
          {/*)}*/}
          {showDistance && (
            <Distance project={project}/>
          )}
        </div>
        {showQuestionMeta && (
          <Meta question={question}/>
        )}
      </div>
    </div>
  );
}
