import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import CheckboxField from '../../../forms/_fields_v2/CheckboxField';

TagCheckboxFields.propTypes = {
  tags: PropTypes.object.isRequired,
  facadeTagNodes: PropTypes.object.isRequired
};

export default function TagCheckboxFields(props) {
  const {
    tags,
    facadeTagNodes,
    onChange,
    selectable,
    className
  } = props;

  return (
    <div className={className}>
      {tags.data.map(function renderExperiences(tag, index) {
        const facadeTagNode = _.find(facadeTagNodes.data, function(facadeTagNode) {
          return facadeTagNode._tag.id === tag.id;
        });

        const selected = !!facadeTagNode;

        return (
          <CheckboxField
            key={index}
            label={tag.data.name}
            description={tag.data.description}
            checked={selected}
            onChange={() => onChange(tag)}
            selectable={selectable}
          />
        );
      })}
    </div>
  );
}
