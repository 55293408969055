import React from 'react';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { formatDate, formatDateRange } from '../../../../utils/dateTimeUtils';
import IconSection from '../../../_new_card_components/IconSection';

export default function DateRange(props) {
  const { offer } = props;

  return (
    <IconSection
      icon={CalendarIcon}
      title={offer.data.specificDate === 'Yes' ? (
        <span>
          {formatDate(offer.data.date)}
        </span>
      ) : (
        <span>
          {formatDateRange(offer.data.startDate, offer.data.endDate)}
        </span>
      )}
      tooltip="Date range"
    />
  );
};
