import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useDialogConfig from '../_hooks/useDialogConfig';
import ProjectCloseExtendDialog from '../../dialogs/project.close-extend';
import { useQueryClient } from '@tanstack/react-query';

export default function Launcher(props) {
  const config = useDialogConfig();
  const queryClient = useQueryClient();

  const project = useConnect('project.byId', {
    id: config.params.projectId
  });

  if (project.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <ProjectCloseExtendDialog
      project={project}
      onCancel={props.onClose}
      onSubmit={function(request) {
        queryClient.invalidateQueries({ queryKey: ['project'] });
      }}
    />
  );
};
