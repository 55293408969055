import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '../../../../hooks/@lore/query-connect';
import VirtualAvatar from '../../../components/User/VirtualAvatar';
import classNames from 'classnames';

ActiveProjectBanner.propTypes = {
  project: PropTypes.object.isRequired
};

export default function ActiveProjectBanner(props) {
  const { project } = props;

  const creator = useConnect('user.byId', {
    id: project.data.creatorId
  });

  return (
    <div className={classNames(
      'flex bg-cs-orange-500 bg-opacity-5 space-x-4 shadow-cs-flat',
      'px-6 py-5',
      '-m-px border border-cs-orange-500 rounded-cs-10'
    )}>
      <div className="mb-4x">
        <VirtualAvatar size={14} user={creator} />
      </div>
      <div className="flex-1 space-y-0.5">
        <div className="text-lg font-semibold text-cs-orange-500">
          Can you help?
        </div>
        <div className="text-sm text-cs-orange-500 opacity-90">
          <span className="underline">View the project</span> and connect
          with {creator.data.firstName} to express interest in participating,
          ask questions, offer ideas, or to refer someone you think might be a good fit.
        </div>
      </div>
    </div>
  );
}
