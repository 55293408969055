import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Entries from '../../project-create/Entries';
import Overlay from '../../../forms/_common/Overlay';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';

TagCards.propTypes = {
  user: PropTypes.object.isRequired,
  tags: PropTypes.object.isRequired,
  nodes: PropTypes.object.isRequired
};

export default function TagCards(props) {
  const {
    user,
    tags,
    nodes,
    children
  } = props;

  const mutation_create = useQueryMutation('tagNode.create');
  const mutation_destroy = useQueryMutation('tagNode.destroy2');

  function onSelectTag(tag) {
    const node = _.find(nodes.data, function(node) {
      return node._tag.id === tag.id;
    });

    if (!node) {
      mutation_create.mutate({
        userId: user.id,
        tagId: tag.id
      }, {
        onSuccess: payload => nodes._add(payload)
      });
    } else {
      mutation_destroy.mutate(node._tagNode, {
        onSuccess: payload => nodes._remove(payload)
      });
    }
  }

  return (
    <Overlay isVisible={mutation_create.saving || mutation_destroy.saving}>
      <div className="grid grid-cols-1 gap-3">
        <Entries
          tags={tags}
          nodes={nodes}
          onSelectTag={onSelectTag}
        >
          {children}
        </Entries>
      </div>
    </Overlay>
  );
}
