import React from 'react';
import ParentLayout from '../profile/ParentLayout';
import DialogLayout from './DialogLayout';
import PageHeader from '../people-people/PageHeader';

export default function Layout() {
  return (
    <ParentLayout page="Settings">
      <div className="p-8 space-y-8">
        <PageHeader
          title="Settings"
          subtitle="Sleep your profile, change your profile type, or delete your account."
        />
        <DialogLayout />
      </div>
    </ParentLayout>
  );
};
