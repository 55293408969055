import React from 'react';
import { WhiteSolidButton } from '../components/Button';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

export default function ContactUs() {
  return (
    <div id="custom_intercom_button" className={classNames(
      'shadow-cs-flat',
      'bg-cs-orange-500 p-4 pt-8',
      'rounded-lg',
      'relative'
    )}>
      <div
        className={classNames(
          'shadow-cs-flat bg-white',
          'border border-gray-200',
          'w-12 h-12 rounded-full',
          'absolute -top-6',
          'left-0 right-0',
          'mx-auto',
          'flex items-center justify-center'
        )}
      >
        <div className={classNames(
          'bg-cs-orange-500 py-0.5 px-2 rounded-full',
          'text-white text-sm font-semibold'
        )}>
          ?
        </div>
      </div>
      <div className="flex flex-col space-y-3 items-center">
        <div className="space-y-1">
          <div className="text-white text-lg font-semibold text-center">
            Need some support?
          </div>
          <div className="text-cs-orange-200 text-base text-center">
            Have a question? We've got answers.
          </div>
        </div>
        <WhiteSolidButton
          label="Ask us here!"
          overrideClassNames={{
            custom: 'w-full'
          }}
        />
      </div>
    </div>
  )
}
