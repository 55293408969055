import React from 'react';
import { stringify } from 'query-string';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import Callback from '../../components/Callback';
import useProjectTags from '../../hooks/useProjectTags';
import TagTypes from '../../constants/TagTypes';
import { useUser } from '@lore/auth';
import useRouter from '../../hooks/useRouter';

export default function Layout(props) {
  const { project } = props;

  const currentUser = useUser();
  const router = useRouter();

  const projectExpertiseTypes = useProjectTags(project, TagTypes.ExpertiseType);

  if (projectExpertiseTypes.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <Callback
      callback={() => router.navigate({
        pathname: `/invite`,
        search: stringify({
          expertiseTypes: projectExpertiseTypes.data.map(projectExpertiseType => projectExpertiseType._tag.id).join(','),
          locationCondition: JSON.stringify({
            address: currentUser.data.zipCode,
            latitude: currentUser.data.latitude,
            longitude: currentUser.data.longitude,
            radius: 50
          })
        })
      }, { replace: true })}
    />
  );
}
