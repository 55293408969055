import { extractObjectFromQuery } from '../../../utils/query-utils';

export default {
  param: extractObjectFromQuery(),
  query: function(value) {
    if (
      value.radius &&
      value.address &&
      value.latitude &&
      value.longitude
    ) {
      return {
        $or: [
          {
            'project.id': {
              $location: {
                radius: value.radius,
                latitude: value.latitude,
                longitude: value.longitude,
                // address: state.value.address
              }
            }
          },
          {
            'event.id': {
              $location: {
                radius: value.radius,
                latitude: value.latitude,
                longitude: value.longitude,
                // address: state.value.address
              }
            }
          }
        ]
      };
    }
  }
}
