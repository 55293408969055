import React from 'react';
import PropTypes from 'prop-types';
import FormTemplate from '../../dialogs_common/OnboardingTemplate';
import SolidButton from '../../components/Button/SolidButton';
import Card from '../../components/Card';
import { DialogManager } from '../../dialogs_new/DialogManager';
import useDialogLauncher from '../../dialogs-routable/_hooks/useDialogLauncher';

ProfileType.propTypes = {
  user: PropTypes.object.isRequired,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  onSkip: PropTypes.func
};

export default function ProfileType(props) {
  const { user } = props;

  const launch = useDialogLauncher();

  function onShowDeleteAccountDialog() {
    launch(DialogManager.ProfileDelete.Config());
  }

  return (
    <Card>
      <FormTemplate
        className="w-full"
        title="Would you like to delete your account?"
        subtitle="Click the button below to let us know that you would like us to delete your account."
        footer={(
          <div className="flex justify-center w-full">
            <SolidButton onClick={onShowDeleteAccountDialog}>
              Delete account
            </SolidButton>
          </div>
        )}
      />
    </Card>
  );
};
