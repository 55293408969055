import React from 'react';
import { UseQueryConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import Claim from './Claim';

export default function Claims(props) {
  const { claims } = props;

  return (
    <>
      {claims.data.map(function(claim, index) {
        return (
          <UseQueryConnect key={index} _key="offer.byId" params={{ id: claim.data.offerId }}>
            {function(offer) {
              if (offer.state === PayloadStates.FETCHING) {
                return (
                  <Loader />
                );
              }

              return (
                <Claim claim={claim} />
              );
            }}
          </UseQueryConnect>
        );
      })}
    </>
  );
}
