import React from 'react';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import ParentLayout from '../Layout';
import Navigation from '../../../dialogs_common/Navigation';
import Urls from '../Urls';
import Form from './Form';
import { PayloadStates } from '@lore/utils';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { location, history, match } = useRouter();
  const user = useUser();
  const project = useConnect('project.byId', {
    id: Number(match.params.projectId)
  });

  if (project.state === PayloadStates.FETCHING) {
    return null;
  }

  return (
    <ParentLayout
      project={project}
      page="Title"
      title={(
        <>
          What is the guiding or essential question your students want to explore?
        </>
      )}
      description={(
        <>
          <span className="font-semibold italic">TIP</span>: If you aren't sure where to begin,
          CommunityShare recommends this formula to help think through a project: How can we,
          as _____ (role), do _____ (task) so that ____ (audience and/or purpose)? This question
          will become the title of your project to draw potential partners into your inquiry.
        </>
      )}
      examples={(
        <div className="bg-white bg-opacity-100 text-cs-gray-900 max-w-lg">
          <div className="text-base font-semibold mb-1">
            Examples
          </div>
          <ul className="list-disc ml-6 text-base">
            {[
              `How can we, as storytellers, use art and science to share trickster tales in a puppet show for our parents?`,
              `How can we, as interviewers, discover how Pythagoras' Theorem is used to solve problems that affect our daily lives?`,
              `How can we encourage elementary students (who have been turned away from music due to the pandemic) to return back to playing music again and join mariachi ensembles?`,
              `How can we, as historians, determine which invention had the biggest effect on the Civil War to share with fifth graders?`,
              `How can we, as biologists, share how animals adapt to their environment for kindergarten readers?`,
              `How can we represent the many ways particles act like a wave?`,
              `How can we determine when laws are fair?`,
              `How can we, as future voters, increase voter engagement now?`
            ].map(function(text, index) {
              return (
                <li key={index} className="mb-1">
                  {text}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    >
      <Form
        user={user}
        project={project}
        navigation={(
          <Navigation v5={true} activeStep={1} steps={19}/>
        )}
        onPrevious={(newData) => history.push(Urls(project).ACTIVITY)}
        onNext={(newData) => history.push(Urls(project).OUTCOME)}
        onSkip={() => history.push(Urls(project).OUTCOME)}
        onExit={() => history.push(Urls(project).PROJECT_EDIT)}
      />
    </ParentLayout>
  );
};
