import React from 'react';
import Header from './Header';
import Date from './Date';
import Time from './Time';
import Creator from './Creator';
import Participation from './Participation';
import classNames from 'classnames';
import School from './School';
import Distance from './Distance';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';

export default function Event(props) {
  const { event, className, children } = props;

  const launch = useDialogLauncher();

  function onClick() {
    launch(DialogManager.Event.Config(event.id));
  }

  return (
    <div className={classNames(
      'flex flex-col bg-white',
      'shadow-cs-flat rounded-cs-10',
      '-m-0.5 border-2 border-cs-gray-100',
      'relative overflow-hidden',
      'hover:bg-cs-orange-50 cursor-pointer',
      className
    )} onClick={onClick}>
      <div className="flex flex-col py-4 px-4 space-y-2">
        <Header event={event} />
        <div className="flex flex-col sm:flex-row items-start justify-between">
          <div className="flex flex-1 flex-wrap gap-x-4 gap-y-2 mb-2 sm:mb-0">
            <Date event={event} />
            <Time event={event} />
            <Creator event={event} />
            <School event={event} />
          </div>
          <Distance event={event} />
        </div>
      </div>
      {false && (
        <div className={classNames(
          'w-full py-4 px-4',
          'bg-cs-gray-50',
          '-mt-px border-t border-cs-gray-100',
        )}>
          <Participation event={event} />
        </div>
      )}
      {children}
    </div>
  );
}
