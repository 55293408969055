import React from 'react';
import { useConnect } from '@lore/query-connect';
import Avatar from '../../components/Avatar';

export default function Title(props) {
  const { question } = props;

  const creator = useConnect('user.byId', {
    id: question.data.creatorId
  });

  const project = useConnect('project.byId', {
    id: question.data.projectId
  });

  const activity = useConnect('tag.byId', {
    id: project.data.activityId
  }, {
    enabled: !!project.data.activityId
  });

  // return (
  //   <div className="flex flex-1 items-center space-x-4">
  //     <div className="text-xl font-semibold text-cs-gray-900">
  //       From: {creator.data.firstName} {creator.data.lastName}
  //     </div>
  //     <Avatar size={8} src={creator.data.avatarUrl} />
  //   </div>
  // );

  // return (
  //   <div className="text-xl font-semibold text-cs-gray-900">
  //     From: {creator.data.firstName} {creator.data.lastName} {project.data.creatorId !== creator.id ? '(Steward)' : ''}
  //   </div>
  // );

  return (
    <div className="text-xl font-semibold text-cs-gray-900">
      {project.data.title || activity?.data.name}
    </div>
  );
}
