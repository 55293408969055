export const TextColor = {
  GrayDark: 'GrayDark',
  GrayMedium: 'GrayMedium',
};

const TextColorMap = {
  GrayDark: 'text-cs-gray-900',
  GrayMedium: 'text-cs-gray-500',
};

export const TextWeight = {
  Normal: 'Normal',
  Semibold: 'Semibold'
};

const TextWeightMap = {
  Normal: 'font-normal',
  Semibold: 'font-semibold'
};

export const TextSize = {
  Xs: 'Xs',
  Sm: 'Sm',
  Base: 'Base',
  Lg: 'Lg',
  Xl: 'Xl',
  Xl2: 'Xl2'
};

const TextSizeMap = {
  Xs: 'text-xs',
  Sm: 'text-sm',
  Base: 'text-base',
  Lg: 'text-lg',
  Xl: 'text-2xl',
  Xl2: 'text-4xl'
};


export function getSize(value) {
  return TextSizeMap[value] || TextSizeMap.Base;
}

export function getColor(value) {
  return TextColorMap[value] || TextColorMap.GrayDark;
}

export function getWeight(value) {
  return TextWeightMap[value] || TextWeightMap.Normal;
}
