import React from 'react';
import { DocumentTextIcon } from '@heroicons/react/24/outline';
import useRouter from '../../hooks/useRouter';
import Urls from '../project-create/Urls';
import DataCard from '../profile-edit/DataCard';
import usePageRouter from '../../dialogs-routable/_hooks/usePageRouter';

export default function Resources(props) {
  const { project } = props;
  const { history } = usePageRouter();

  return (
    <DataCard
      title="Resources Needed"
      icon={DocumentTextIcon}
      subtitle={project.data.resources || '(not provided)'}
      onClick={() => history.push(Urls(project).RESOURCES)}
    />
  );
};
