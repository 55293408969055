import React from 'react';
import _ from 'lodash';
import { useUser } from '@lore/auth';
import { parse, stringify } from 'query-string';
import { GlobeAmericasIcon, MapPinIcon } from '@heroicons/react/24/outline';
import getSearchParamsFromQuery from '../../utils/user/getSearchParamsFromQuery';
import useRouter from '../../hooks/useRouter';
import DropdownFilter from '../../components/DropdownFilter';

export default function NetworkFilter(props) {
  const { location, history } = useRouter();

  const currentUser = useUser();

  const searchParams = getSearchParamsFromQuery(parse(location.search));

  function onChange(networkId) {
    const search = parse(location.search) || {};

    search.networks = networkId;
    delete search.page;

    history.push({
      pathname: location.pathname,
      search: stringify(_.extend({}, search))
    });
  }

  return (
    <DropdownFilter
      subtitle="Network:"
      title={!searchParams.networks ? 'Any' : 'Mine'}
      count={searchParams.networks.length}
    >
      <DropdownFilter.MenuItem
        icon={MapPinIcon}
        title="Mine"
        subtitle="Only show results that are part of your network."
        selected={searchParams.networks == currentUser.data.networkId}
        onClick={() => onChange(currentUser.data.networkId)}
      />
      <DropdownFilter.MenuItem
        icon={GlobeAmericasIcon}
        title="Any"
        subtitle="Show all results regardless of their network."
        selected={!searchParams.networks}
        onClick={() => onChange(undefined)}
      />
    </DropdownFilter>
  );
}
