import React from 'react';
import { DateTime } from 'luxon';
import { PayloadStates } from '@lore/utils';
import MissingField from '../project-manage/MissingField';
import HiddenField from '../project-manage/HiddenField';
import useMissingFields from './useMissingFields';

export default function MissingFields(props) {
  const { event } = props;

  const { missingFields } = useMissingFields(event);

  if (missingFields.state === PayloadStates.FETCHING) {
    return null;
  }

  if (
    missingFields.data.length > 0
  ) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {missingFields.data.map(function(missingField) {
          return (
            <MissingField
              key={missingField.key}
              name={missingField.name}
              description={(
                <>
                  You must provide this field before you can connect with partners,
                  or before your event will be shown to others.
                </>
              )}
              to={missingField.to}
            />
          );
        })}
      </div>
    );
  }

  const inPast = (
    event.data.specificDate === 'Yes' &&
    event.data.date &&
    DateTime.now().diff(DateTime.fromISO(event.data.date)).milliseconds > 0
  ) || (
    event.data.endDate &&
    DateTime.now().diff(DateTime.fromISO(event.data.endDate)).milliseconds > 0
  );

  if (inPast) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <HiddenField
          name="Your event has expired!"
          description={(
            <>
              Partners can no longer see your event because it's set in the
              past. Change the date to make it visible again.
            </>
          )}
          to={`/post-event/${event.id}/date`}
        />
      </div>
    );
  }

  return null;
};
