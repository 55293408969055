import React from 'react';
import classNames from 'classnames';

export default function ActionBarContainer(props) {
  const { children } = props;

  return (
    <div className={classNames(
      'flex items-center justify-center',
      'px-3 py-2',
      'bg-white',
      'border border-cs-gray-200',
      'rounded-full',
      'shadow-cs-flat'
    )}>
      <div className="flex flex-wrapx gap-4 justify-center w-full">
        {children}
      </div>
    </div>
  );
};
