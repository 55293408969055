import React from 'react';
import { marked } from 'marked';
import classNames from 'classnames';

export default function Markdown(props) {
  const { text, white = false } = props;

  if (text) {
    return (
      <span className={classNames(
        'prose',
         white && 'prose-a:text-white prose-p:text-white',
        'prose-p:leading-6',
        'prose-p:mb-4',
        'last-of-type:prose-p:mb-0',
        'text-base'
      )}
        dangerouslySetInnerHTML={{__html: marked.parse(text)}}
      />
    );
  }

  return null;
};
