import React from 'react';
import PropTypes from 'prop-types';
import FormField from '../../forms/_common/FormField';
import { OutlineButton, SolidButton } from '../../components/Button';
import FormTemplate from '../../dialogs_common/FormTemplate';
import RadioButtonField from '../../pages/_post-opportunity/_fields/RadioButtonField.cards';
import FormSection from '../../pages/_post-opportunity/forms/FormSection';
import { ArchiveBoxIcon } from '@heroicons/react/24/outline';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  activityTypes: PropTypes.object,
  ages: PropTypes.object
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onCancel,
    project
  } = props;

  return (
    <FormTemplate
      icon={(
        <div className="flex justify-center mb-4">
          <ArchiveBoxIcon className="text-cs-orange-500 h-12 w-12" />
        </div>
      )}
      title="Were you able to complete this project?"
      // subtitle="text"
      onClose={onCancel}
      alert={alert}
      body={(
        <div className="space-y-8">
          <FormSection
            // title="How did you connect?*"
            // subtitle="Select one or many."
          >
            <FormField name="completed" data={data} errors={showErrors && errors} onChange={onChange}>
              {(field) => (
                <RadioButtonField
                  name="completed"
                  // label="How did you connect?*"
                  value={
                    data.completed === true ? 1 :
                    data.cancelled === true ? 2 :
                    undefined
                  }
                  error={field.error}
                  // placeholder="Select a role..."
                  options={{
                    data: [
                      `Yes, this project is done!`,
                      `No, I won't be able to complete this project.`
                    ].map((option, index) => {
                      return {
                        id: index + 1,
                        data: {
                          name: option
                        }
                      }
                    })
                  }}
                  optionLabel="name"
                  onChange={function(value) {
                    if (value === 1) {
                      onChange('completed', true);
                    } else {
                      onChange('cancelled', true);
                    }
                  }}
                />
              )}
            </FormField>
          </FormSection>
          {data.cancelled && (
            <FormSection
              title="Sorry to hear that."
              subtitle="Tell us a little about why it didn't work out."
            >
              <FormField name="cancelledMessage" data={data} errors={showErrors && errors} onChange={onChange}>
                {(field) => {
                  const reasons = [
                    `I couldn't find the right partner(s).`,
                    `I couldn't get schedules to align/ran out of time.`,
                    `I couldn't secure funding or resources I needed.`,
                    `I changed my mind about trying this project now.`
                  ];

                  const index = reasons.indexOf(field.value);
                  const value = index >= 0 ? index + 1 : undefined;

                  return (
                    <RadioButtonField
                      name="cancelledMessage"
                      // label="How did you connect?*"
                      value={value}
                      error={field.error}
                      // placeholder="Select a role..."
                      options={{
                        data: reasons.map((option, index) => {
                          return {
                            id: index + 1,
                            data: {
                              name: option
                            }
                          }
                        })
                      }}
                      optionLabel="name"
                      onChange={function(value) {
                        field.onChange(reasons[value - 1]);
                      }}
                    />
                  );
                }}
              </FormField>
            </FormSection>
          )}
        </div>
      )}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onCancel}>
            Cancel
          </OutlineButton>
          <SolidButton disabled={!data.completed && !data.cancelled} onClick={onSubmit}>
            Next
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
