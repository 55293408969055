import { useConnect } from '@lore/query-connect';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import { useState } from 'react';
import getProjectBucketParams from '../my-projects/getBucketParams';
import getEventBucketParams from '../my-events/getBucketParams';
import getOfferBucketParams from '../my-offers/getBucketParams';

export default function useDraftOpportunityCount(props) {
  const currentUser = useUser();

  const [timestamp] = useState(new Date().toISOString());

  const projectParams = getProjectBucketParams(timestamp);
  const eventParams = getEventBucketParams(timestamp);
  const offerParams = getOfferBucketParams(timestamp);

  // const params = {
  //   where: {
  //     eager: {
  //       $where: {
  //         userId: currentUser.id,
  //         response: 'Pending'
  //       }
  //     }
  //   },
  //   pagination: {
  //     pageSize: 100,
  //     order: 'createdAt desc'
  //   }
  // };

  const projects = useConnect('project.find', {
    where: {
      eager: {
        $where: {
          creatorId: currentUser.id,
          activityId: {
            $exists: true
          },
          // createdAt: {
          //   $lte: timestamp
          // },
          ...projectParams.draft
        }
      }
    },
    pagination: {
      pageSize: 100,
      order: 'createdAt desc'
    }
  });

  const events = useConnect('event.find', {
    where: {
      eager: {
        $where: {
          creatorId: currentUser.id,
          activityId: {
            $exists: true
          },
          // createdAt: {
          //   $lte: timestamp
          // },
          ...eventParams.draft
        }
      }
    },
    pagination: {
      pageSize: 100,
      order: 'createdAt desc'
    }
  });

  const offers = useConnect('offer.find', {
    where: {
      eager: {
        $where: {
          creatorId: currentUser.id,
          activityId: {
            $exists: true
          },
          // createdAt: {
          //   $lte: timestamp
          // },
          ...offerParams.draft
        }
      }
    },
    pagination: {
      pageSize: 100,
      order: 'createdAt desc'
    }
  });

  if (
    projects.state === PayloadStates.FETCHING ||
    events.state === PayloadStates.FETCHING ||
    offers.state === PayloadStates.FETCHING
  ) {
    return '...';
  }

  if (
    projects.data.length === 0 &&
    events.data.length === 0 &&
    offers.data.length === 0
  ) {
    return null;
  }

  return (
    projects.data.length +
    events.data.length +
    offers.data.length
  );
}
