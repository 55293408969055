import React from 'react';
import { useConnect } from '@lore/query-connect';
import { DateTime } from 'luxon';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import IconSection from '../../pages/_new_card_components/IconSection';

export default function Meta(props) {
  const { claim } = props;

  const creator = useConnect('user.byId', {
    id: claim.data.creatorId
  });

  const offer = useConnect('offer.byId', {
    id: claim.data.offerId
  });

  return (
    <IconSection
      icon={EnvelopeIcon}
      title={offer.data.creatorId !== creator.id ?
        `Invited ${DateTime.fromISO(claim.data.createdAt).toRelative()} by ${creator.data.firstName} ${creator.data.lastName} (Steward)` :
        `Invited ${DateTime.fromISO(claim.data.createdAt).toRelative()}`
      }
    />
  );
};
