import React from 'react';
import Text, { TextColor } from '../../../typography';

export default function Entry(props) {
  const { title, description } = props;

  return (
    <div className="space-y-0.5">
      <Text.Base>
        {title}
      </Text.Base>
      {description && (
        <Text.BaseGrayMedium>
          {description}
        </Text.BaseGrayMedium>
      )}
    </div>
  );
}
