import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import validators from '../../../utils/validators';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import FormField from '../../../forms/_common/FormField';
import SolidButton from '../../../components/Button/SolidButton';
import FormTemplate2 from '../../_post-opportunity/FlowTemplate';
import TextAreaField from '../../../forms/_fields_v2/TextAreaField';
import FormSection from '../../_post-opportunity/forms/FormSection';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';

Form.propTypes = {
  user: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func,
  onSkip: PropTypes.func
};

const MAX_LENGTH = 512;

export default function Form(props) {
  const {
    user,
    event,
    navigation,
    onNext,
    onPrevious,
    onSkip,
    onExit,
    FormTemplate = FormTemplate2,
    buttonText = 'Next'
  } = props;

  const [data, setData] = useState(_.pick(event.data, [
    'interactionDescription',
  ]));
  const [showErrors, setShowErrors] = useState(false);
  const [exit, setExit] = useState(false);

  const mutation = useQueryMutation('event.update', event);
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      exit ? onExit(data) : onNext(data);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate(data);
  }

  function onSubmitAndExit() {
    onSubmit();
    setExit(true);
  }

  function onChange(name, value) {
    setData(_.merge({}, data, {
      [name]: value
    }));
  }

  return (
    <Overlay isVisible={saving}>
      <FormErrors data={data} validators={{
        interactionDescription: [validators.maxLength(MAX_LENGTH)]
      }}>
        {({ errors, hasError }) => {
          return (
            <FormTemplate
              onExit={() => hasError ? setShowErrors(true) : onSubmitAndExit()}
              onPrevious={onPrevious}
              navigation={navigation}
              alert={error ? (
                <RequestError request={request} />
              ) : showErrors ? (
                <ErrorMessage>
                  Please fill in all required fields.
                </ErrorMessage>
              ) : null}
              body={(
                <FormSection
                  // title="Examples"
                >
                  <FormField name="interactionDescription" data={data} errors={showErrors && errors} onChange={onChange}>
                    {(field) => (
                      <TextAreaField
                        // label={`Interaction description (${data.interactionDescription ? data.interactionDescription.length : 0}/${MAX_LENGTH} characters)`}
                        // explanation="What learning goals do you have for your students that you hope a community partner will be able to support?"
                        description={`${data.interactionDescription ? data.interactionDescription.length : 0}/${MAX_LENGTH} characters`}
                        rows={10}
                        placeholder="Summary..."
                        // description={(
                        //   <div className="text-cs-orange-500 cursor-pointer" onClick={onShowSuggestions}>
                        //     Need suggestions for what to include?
                        //   </div>
                        // )}
                        value={field.value}
                        error={field.error}
                        onChange={field.onChange}
                      />
                    )}
                  </FormField>
                </FormSection>
              )}
              footer={(
                <>
                  <SolidButton onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
                    {buttonText}
                  </SolidButton>
                </>
              )}
            />
          );
        }}
      </FormErrors>
    </Overlay>
  );
};
