import React from 'react';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import { stringify } from 'query-string';
import { MapPinIcon } from '@heroicons/react/24/outline';
import OptionCard from '../../my-network/OptionCard';
import useQuery from '../../projects-events/useQuery';
import useUserTags from '../../../hooks/useUserTags';
import TagTypes from '../../../constants/TagTypes';
import Loader from '../../../components/Loader';

export default function NearbyProjectsEvents(props) {
  const currentUser = useUser();

  const userExpertises = useUserTags(currentUser, TagTypes.Expertise);

  const params = {
    search: {
      networks: `${currentUser.data.networkId}`,
      locationCondition: JSON.stringify({
        address: currentUser.data.zipCode,
        latitude: currentUser.data.latitude,
        longitude: currentUser.data.longitude,
        radius: 10
      })
    }
  };

  const projects = useQuery(params);

  if (
    userExpertises.state === PayloadStates.FETCHING ||
    projects.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  if (
    userExpertises.meta?.totalCount === 0 ||
    projects.meta?.totalCount === 0
  ) {
    return null;
  }

  return (
    <OptionCard
      count={projects.meta?.totalCount}
      // imageUrl={bookshelfImage}
      icon={MapPinIcon}
      title="Discover projects and events within 10 miles of you"
      subtitle="Educators are seeking experience like yours for these activities."
      linkText="View"
      to={{
        pathname: '/projects-events',
        search: stringify(params.search)
      }}
    />
  );
}
