import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import SolidButton from '../../../components/Button/SolidButton';
import FormTemplate2 from '../../_post-opportunity/FlowTemplate';
import FormSection from '../../_post-opportunity/forms/FormSection';
import NodeEntries from '../NodeEntries';
import useTags from '../../../hooks/useTags';
import TagTypes from '../../../constants/TagTypes';
import useEventTags from '../../../hooks/useEventTags';
import Virtual from '../../project-create/location/InteractionTypes/Virtual';
import InPerson from '../../project-create/location/InteractionTypes/InPerson';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';

Form.propTypes = {
  user: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func,
  onSkip: PropTypes.func
};

export default function Form(props) {
  const {
    user,
    event,
    navigation,
    onNext,
    onPrevious,
    onSkip,
    onExit,
    FormTemplate = FormTemplate2,
    buttonText = 'Next'
  } = props;

  const [data, setData] = useState(_.pick(event.data, [
    'interactionType',
    'location'
  ]));
  const [showErrors, setShowErrors] = useState(false);
  const [exit, setExit] = useState(false);

  const mutation = useQueryMutation('event.update', event);
  const { saving, success, error, request } = mutation;

  const interactionTypes = useTags(TagTypes.InteractionType);
  const eventInteractionTypes = useEventTags(event, TagTypes.InteractionType);

  const locations = useTags(TagTypes.Location);
  const eventLocations = useEventTags(event, TagTypes.Location);

  useEffect(() => {
    if (success) {
      exit ? onExit(data) : onNext(data);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate(data);
  }

  function onSubmitAndExit() {
    onSubmit();
    setExit(true);
  }

  function onChange(name, value) {
    setData(_.merge({}, data, {
      [name]: value
    }));
  }

  const isInPerson = _.find(eventInteractionTypes.data, model => model.data.name === 'In-person');

  return (
    <Overlay isVisible={saving}>
      <FormErrors data={data} validators={{
        // firstName: [validators.isRequired],
        // lastName: [validators.isRequired]
      }}>
        {({ errors, hasError }) => {
          return (
            <FormTemplate
              onExit={() => hasError ? setShowErrors(true) : onSubmitAndExit()}
              onPrevious={onPrevious}
              navigation={navigation}
              alert={error ? (
                <RequestError request={request} />
              ) : showErrors ? (
                <ErrorMessage>
                  Please fill in all required fields.
                </ErrorMessage>
              ) : null}
              body={(
                <div className="space-y-10">
                  <FormSection
                    // title="Location Type"
                    // subtitle="Please choose one or both."
                    // tooltip="Does this event need to be completed in-person, virtually, or is it flexible?"
                  >
                    <NodeEntries
                      event={event}
                      tags={interactionTypes}
                      nodes={eventInteractionTypes}
                    >
                      {({ tag: interactionType, selected, onClick }) => {
                        if (interactionType.data.name === 'Virtual') {
                          return (
                            <Virtual
                              key={interactionType.id}
                              interactionType={interactionType}
                              selected={selected}
                              onClick={onClick}
                            />
                          );
                        }

                        if (interactionType.data.name === 'In-person') {
                          return (
                            <InPerson
                              key={interactionType.id}
                              interactionType={interactionType}
                              selected={selected}
                              onClick={onClick}
                            />
                          );
                        }

                        return null;
                      }}
                    </NodeEntries>
                  </FormSection>
                  {false && isInPerson && (
                    <FormSection
                      title="Possible Locations"
                      subtitle="Please choose ANY that apply."
                      tooltip="Does this event require a specific location, or is it flexible?"
                    >
                      <NodeEntries
                        event={event}
                        tags={locations}
                        nodes={eventLocations}
                      />
                    </FormSection>
                  )}
                </div>
              )}
              footer={(
                <>
                  <SolidButton onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
                    {buttonText}
                  </SolidButton>
                </>
              )}
            />
          );
        }}
      </FormErrors>
    </Overlay>
  );
};
