import React from 'react';
import PropTypes from 'prop-types';
import FormField from '../../forms/_common/FormField';
import { OutlineButton, SolidButton } from '../../components/Button';
import FormTemplate from '../../dialogs_common/FormTemplate';
import FormSection from '../../pages/_post-opportunity/forms/FormSection';
import RadioButtonField from '../../pages/_post-opportunity/_fields/RadioButtonField.cards';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';
import { useUser } from '@lore/auth';
import { useConnect, UseQueryConnect } from '../../../hooks/@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import Role from '../../pages/project-create/site/Role';
import EmptyState from '../../components/EmptyState/EmptyState.legacy';
import useDialogLauncher from '../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../dialogs_new/DialogManager';

SiteForm.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  activityTypes: PropTypes.object,
  ages: PropTypes.object
};

SiteForm.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function SiteForm(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onPrevious,
    onSubmit,
    onCancel
  } = props;

  const currentUser = useUser();
  const launch = useDialogLauncher();

  function onCreateSite() {
    launch(DialogManager.SiteCreate.Config());
  }

  const roles = useConnect('role.find', {
    where: {
      eager: {
        $where: {
          userId: currentUser.id,
          siteId: {
            $exists: true
          }
        }
      }
    },
    pagination: {
      page: 1,
      pageSize: 1000,
      order: 'createdAt asc'
    }
  });

  return (
    <FormTemplate
      title={(
        <>
          Which school or site is associated with this project or event?
        </>
      )}
      subtitle={(
        <>
          Projects and events can be created at sites supported by the CommunityShare
          network. If you recently tried to add a new site, you'll need to wait to share
          your idea until your site is ready to go!
        </>
      )}
      onClose={onCancel}
      alert={alert}
      body={roles.state === PayloadStates.FETCHING ? (
        <Loader />
      ) : (
        <div className="space-y-6">
          <div className="grid grid-cols-1 gap-3">
            {roles.data.map(function (role) {
              return (
                <UseQueryConnect key={role.id} _key="site.byId" params={{id: role.data.siteId}}>
                  {function (site) {
                    return (
                      <Role
                        role={role}
                        onClick={(site) => onSubmit(site)}
                      />
                    );
                  }}
                </UseQueryConnect>
              );
            })}
          </div>
          <div className="hover:opacity-80 hover:bg-gray-50 cursor-pointer" onClick={onCreateSite}>
            <EmptyState
              title="Add new school/site"
            />
          </div>
        </div>
      )}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onPrevious}>
            Back
          </OutlineButton>
          <SolidButton onClick={onSubmit} disabled={true}>
            Next
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
