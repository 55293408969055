import React, { Fragment } from 'react';
import { useUser } from '@lore/auth';
import Section from '../../my-network/Section';
import Option from '../../my-network/Option';
import Divider from '../../../forms/_fields_v2/Divider';
import Card from '../../../components/Card';
import {
  ClockIcon,
  EnvelopeIcon,
  GlobeAmericasIcon,
  NewspaperIcon,
  PencilIcon,
  UserIcon
} from '@heroicons/react/24/outline';
import usePendingInvitationsCount from '../../my-invitations/usePendingInvitationsCount';
import usePendingEvaluationsCount from '../../my-evaluations/usePendingEvaluationsCount';
import useMissingFields from '../../profile/useMissingFields';
import usePendingExpiredCount from '../../my-expired/usePendingExpiredCount';
import useDraftOpportunityCount from '../../my-expired/useDraftOpportunityCount';

export default function NeedsAttention(props) {
  const currentUser = useUser();

  const { missingFields } = useMissingFields(currentUser);

  const missingFieldsCount = missingFields.data.length;
  const pendingInvitationsCount = usePendingInvitationsCount() || 0;
  const pendingExpiredCount = usePendingExpiredCount() || 0;
  const pendingEvaluationsCount = usePendingEvaluationsCount() || 0;
  const draftOpportunityCount = useDraftOpportunityCount() || 0;

  const totalCount = (
    missingFieldsCount +
    pendingInvitationsCount +
    pendingExpiredCount +
    pendingEvaluationsCount +
    draftOpportunityCount
  );

  if (totalCount === 0) {
    return null;
  }

  const options = _.remove([
    missingFieldsCount > 0 && (
      <Option
        icon={UserIcon}
        title="Finish your profile"
        subtitle="Missing fields. Your profile will be hidden until completed."
        linkText="Finish profile"
        to="/profile/edit"
        count={missingFieldsCount}
      />
    ),
    pendingInvitationsCount > 0 && (
      <Option
        icon={EnvelopeIcon}
        title="Respond to your invitations"
        subtitle={(
          <>
            Whether your answer is yes or not at this time, please respond quickly to invitations
            so activities can move forward smoothly.
          </>
        )}
        linkText="Respond to invites"
        to="/my-invitations"
        count={pendingInvitationsCount}
      />
    ),
    pendingExpiredCount > 0 && (
      <Option
        icon={ClockIcon}
        title="Close or update your projects and events"
        subtitle={(
          <>
            Have you completed your project/event or need more time? Keep your activities
            up-to-date by updating or closing it, so partners know whether it's active or
            completed.
          </>
        )}
        linkText="Manage activities"
        to="/my-expired"
        count={pendingExpiredCount}
      />
    ),
    pendingEvaluationsCount > 0 && (
      <Option
        icon={GlobeAmericasIcon}
        title="Share the impact of your project or event"
        subtitle={(
          <>
            Now that your project or event is complete, share your impact to inspire
            others and help us keep improving!
          </>
        )}
        linkText="Share impact"
        to="/my-evaluations"
        count={pendingEvaluationsCount}
      />
    ),
    draftOpportunityCount > 0 && (
      <Option
        icon={PencilIcon}
        title="Update your drafts"
        subtitle="It looks like some of your activities are missing information. Complete your activities here to make them live and viewable by others in the community."
        linkText="Manage activities"
        to="/my-leading"
        count={draftOpportunityCount}
      />
    )
  ]);

  return (
    <Section
      title="Your next steps"
      subtitle={(
        <>
          Keep the momentum going! Complete these steps to keep activities moving forward.
        </>
      )}
    >
      <Card>
        {options.map(function(option, index) {
          return (
            <Fragment key={index}>
              {option}
              {(
                (index + 1) < options.length
              ) && (
                <div className="px-4">
                  <Divider />
                </div>
              )}
            </Fragment>
          );
        })}
      </Card>
    </Section>
  );
};
