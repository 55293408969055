import React from 'react';
import { Helmet } from 'react-helmet-async';
import Events from './Events';
import useRouter from '../../hooks/useRouter';
import PageHeader from '../people-people/PageHeader';

export default function Layout(props) {
  const router = useRouter();

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Explore active events</title>
      </Helmet>
      <PageHeader
        title="Explore active events"
        subtitle={(
          <>
            Check out inspiring events where educators and learners are looking
            for partners. See how you can connect and contribute your skills and experiences to
            make a difference. Use filters to find events that interest you.
          </>
        )}
      />
      <Events
        router={router}
      />
    </div>
  );
}
