import React from 'react';
import { DocumentTextIcon } from '@heroicons/react/24/outline';
import useRouter from '../../hooks/useRouter';
import Urls from '../project-create/Urls';
import DataCard from '../profile-edit/DataCard';
import usePageRouter from '../../dialogs-routable/_hooks/usePageRouter';

export default function ProjectSummary(props) {
  const { project } = props;
  const { history } = usePageRouter();

  return (
    <DataCard
      title="What you hope partners will do or share"
      icon={DocumentTextIcon}
      subtitle={project.data.interactionDescription || '(not provided)'}
      onClick={() => history.push(Urls(project).SUMMARY)}
    />
  );
};
