import React from 'react';
import { UserIcon } from '@heroicons/react/24/outline';
import useRouter from '../../hooks/useRouter';
import Urls from '../profile/Urls';
import DataCard from './DataCard';

export default function Name(props) {
  const { user } = props;

  const { history } = useRouter();

  return (
    <div className="flex space-x-4">
      {/*<DataCard*/}
      {/*  title="Name"*/}
      {/*  icon={UserIcon}*/}
      {/*  subtitle={`${user.data.firstName} ${user.data.lastName}`.trim() || '(not provided)'}*/}
      {/*  onClick={() => history.push(Urls(user).NAME)}*/}
      {/*/>*/}
      <DataCard
        title="First name"
        icon={UserIcon}
        subtitle={`${user.data.firstName}`.trim() || '(not provided)'}
        onClick={() => history.push(Urls(user).NAME)}
      />
      <DataCard
        title="Last name"
        icon={UserIcon}
        subtitle={`${user.data.lastName}`.trim() || '(not provided)'}
        onClick={() => history.push(Urls(user).NAME)}
      />
    </div>
  );
};
