import React from 'react';
import { useConnect } from '@lore/query-connect';

export default function Title(props) {
  const { invitation } = props;

  const creator = useConnect('user.byId', {
    id: invitation.data.creatorId
  });

  const event = useConnect('event.byId', {
    id: invitation.data.eventId
  });

  const activity = useConnect('tag.byId', {
    id: event.data.activityId
  }, {
    enabled: !!event.data.activityId
  });

  // return (
  //   <div className="text-xl font-semibold text-cs-gray-900">
  //     From: {creator.data.firstName} {creator.data.lastName} {event.data.creatorId !== creator.id ? '(Steward)' : ''}
  //   </div>
  // );

  return (
    <div className="text-xl font-semibold text-cs-gray-900">
      {event.data.title || activity?.data.name}
    </div>
  );
}
