import React, { useState } from 'react';
import { MapPinIcon } from '@heroicons/react/24/outline';
import useRouter from '../../hooks/useRouter';
import Urls from '../project-create/Urls';
import DataCard from '../profile-edit/DataCard';
import { PayloadStates } from '@lore/utils';
import useProjectTags from '../../hooks/useProjectTags';
import TagTypes from '../../constants/TagTypes';
import usePageRouter from '../../dialogs-routable/_hooks/usePageRouter';

export default function Location(props) {
  const { project } = props;

  const { history } = usePageRouter();

  const [timestamp] = useState(new Date().toISOString());

  const projectInteractionTypes = useProjectTags(project, TagTypes.InteractionType, timestamp);

  let subtitle = '';

  if (projectInteractionTypes.state === PayloadStates.FETCHING) {
    subtitle = '...';
  } else {
    subtitle = projectInteractionTypes.data.map(function(projectInteractionType, index) {
      return (
        <div key={index}>
          {projectInteractionType.data.name}
        </div>
      );
    });
  }

  return (
    <DataCard
      title="Participation options"
      icon={MapPinIcon}
      subtitle={subtitle || '(not provided)'}
      onClick={() => history.push(Urls(project).LOCATION)}
    />
  );
};
