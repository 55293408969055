import React from 'react';

export default function YellowTag(props) {
  const { label } = props;

  return (
    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-cs-yellow-100 text-cs-yellow-800">
      {label}
    </span>
  );
}
