import React from 'react';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import { useConnect } from '../../../../hooks/@lore/query-connect';
import Loader from '../../../components/Loader';
import Card from '../../../components/Card';
import ComposableProjectHeader from '../../../components_cards/ComposableProjectHeader';
import TagRow from '../../../components_cards/tags/TagRow';
import ProjectTag from '../../../components_cards/tags/ProjectTag';
import OutlineButtonGray from '../../../components/Button/OutlineButton.gray';
import ParticipantTag from '../../../components_cards/tags/ParticipantTag';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import CompletedTag from '../../../components_cards/tags/_resource/CompletedTag';
import CancelledTag from '../../../components_cards/tags/_resource/CancelledTag';
import ExpiredTag from '../../../components_cards/tags/_resource/ExpiredTag';
import usePageRouter from '../../../dialogs-routable/_hooks/usePageRouter';
import EvaluationText from '../EvaluationText';
import EvaluationButton from '../EvaluationButton';
import LazyCard from '../../../components/LazyCard';

export default function Question(props) {
  const { question } = props;

  const currentUser = useUser();
  const launch = useDialogLauncher();
  const { history } = usePageRouter();

  const project = useConnect('project.byId', {
    id: question.data.projectId
  });

  const evaluation = useConnect('evaluationProjectPartner.first', {
    where: {
      eager: {
        $where: {
          projectId: project.id,
          userId: currentUser.id
        }
      }
    }
  });

  function onViewProject() {
    launch(DialogManager.QuestionView.Config(question.id));
  }

  function onViewEvaluation() {
    launch(DialogManager.EvaluationProjectPartner.Config(evaluation.id));
  }

  function redirectToEvaluation() {
    history.push(`/evaluation-project-partner/${evaluation.id}`);
  }

  if (project.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <LazyCard divider={true}>
      <ComposableProjectHeader
        project={project}
        showTags={false}
        tags={(
          <TagRow>
            <ProjectTag />
            <ParticipantTag />
            <CompletedTag resource={project} />
            <CancelledTag resource={project} />
            <ExpiredTag resource={project} />
          </TagRow>
        )}
        showPartners={false}
      />
      <div className="flex justify-between p-4 bg-gray-50 flex-wrap gap-2">
        <div>
          {evaluation.state === PayloadStates.RESOLVED && (
            <EvaluationText
              pendingTitle="Share the impact of your project"
              evaluation={evaluation}
              onView={onViewEvaluation}
            />
          )}
        </div>
        <div className="flex flex-wrap gap-2">
          <OutlineButtonGray
            label="View project"
            onClick={onViewProject}
          />
          {evaluation.state === PayloadStates.RESOLVED && (
            <EvaluationButton
              evaluation={evaluation}
              onEdit={redirectToEvaluation}
            />
          )}
        </div>
      </div>
    </LazyCard>
  );
}
