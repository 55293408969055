import React from 'react';
import { DateTime } from 'luxon';
import MiddleDot from '../evaluations/_common/MiddleDot';
import { getTimestampDiff } from '../../utils/dateTimeUtils';

export default function EvaluationText(props) {
  const {
    pendingTitle = 'Share the impact',
    evaluation,
    onView
  } = props;

  const diff = getTimestampDiff(evaluation.data.updatedAt, evaluation.data.createdAt);

  if (evaluation.data.completed) {
    return (
      <div className="flex flex-col">
        <div className="text-lg font-semibold text-gray-700">
          Thanks for sharing your experience!
        </div>
        <div className="flex text-sm text-gray-500 flex-wrap gap-1">
          <div>
            Evaluation completed {DateTime.fromISO(evaluation.data.updatedAt).toRelative()}
          </div>
          <span>
            <MiddleDot/>
          </span>
          <div className="text-cs-blue-500 hover:text-blue-300 cursor-pointer" onClick={onView}>
            Review impact
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col">
      <div className="text-lg font-semibold text-gray-700">
        {pendingTitle}
      </div>
      <div className="flex text-sm text-gray-500 flex-wrap gap-1">
        {diff > 0 ? (
          <>
            <div>
              Evaluation last updated {DateTime.fromISO(evaluation.data.updatedAt).toRelative()}
            </div>
            <span>
              <MiddleDot/>
            </span>
            <div className="text-cs-blue-500 hover:text-blue-300 cursor-pointer" onClick={onView}>
              View progress
            </div>
          </>
        ) : (
          <span>
            Evaluation created {DateTime.fromISO(evaluation.data.createdAt).toRelative()}
          </span>
        )}
      </div>
    </div>
  );
}
