import React from 'react';
import { Helmet } from 'react-helmet-async';
import Questions from '../my-invitations/Questions';
import Invitations from '../my-invitations/Invitations';
import Claims from '../my-invitations/Claims';
import PageHeader from '../people-people/PageHeader';
import useActiveQuestions from './useActiveQuestions';
import useActiveInvitations from './useActiveInvitations';
import useActiveClaims from './useActiveClaims';
import ParticipatingCallToAction from './ParticipatingCallToAction';
import Question from './Question';
import Invitation from './Invitation';
import Claim from './Claim';

function Header(props) {
  const { children } = props;

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Participating</title>
      </Helmet>
      <PageHeader
        title="Opportunities You're Involved With"
        subtitle="View the active projects, events, and offers you've expressed interest in as a collaborator."
      />
      <div className="space-y-8">
        {children}
      </div>
    </div>
  );
}

export default function Layout(props) {
  const questions = useActiveQuestions();
  const invitations = useActiveInvitations();
  const claims = useActiveClaims();

  return (
    <Header>
      <div className="space-y-4">
        <ParticipatingCallToAction/>
        <div className="space-y-4">
          {questions.data.map(function (question) {
            return (
              <Question
                key={question.id}
                question={question}
              />
            );
          })}
          {invitations.data.map(function (invitation) {
            return (
              <Invitation
                key={invitation.id}
                invitation={invitation}
              />
            );
          })}
          {claims.data.map(function (claim) {
            return (
              <Claim
                key={claim.id}
                claim={claim}
              />
            );
          })}
        </div>
      </div>
    </Header>
  );
}
