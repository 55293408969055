import { PayloadStates } from '@lore/utils';
import useExpiredProjects from './useExpiredProjects';
import useExpiredEvents from './useExpiredEvents';

export default function usePendingExpiredCount(props) {
  const projects = useExpiredProjects();
  const events = useExpiredEvents();

  if (
    projects.state === PayloadStates.FETCHING ||
    events.state === PayloadStates.FETCHING
  ) {
    return '...';
  }

  if (
    projects.data.length === 0 &&
    events.data.length === 0
  ) {
    return null;
  }

  return (
    projects.data.length +
    events.data.length
  );
}
