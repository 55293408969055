import React from 'react';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';

export default function Header(props) {
  const launch = useDialogLauncher();

  function onSiteMissing() {
    launch(DialogManager.SiteMissing.Config());
  }

  return (
    <div className="flex flex-col">
      <div className="mb-6">
        <h5 className="text-3xl text-cs-gray-900 font-semibold text-left">
          Where do you work?
        </h5>
        <h6 className="font-normal mt-2 text-cs-gray-800 text-left text-lg mb-4">
          <span className="block mb-2">
            Tell us the school or site where you work with your learners. If you work at more
            than one school, you can select both. If you work at a district office, we suggest
            selecting the district name for now or the site where you are most likely to work
            with partners. You can always add or remove sites.
          </span>
          <span className="block">
            Have a non-school learning location or can't find your school or site in the list
            below? <span className="text-cs-orange-500 hover:text-cs-yellow-500 cursor-pointer underline" onClick={onSiteMissing}>We can help</span>.
          </span>
        </h6>
      </div>
    </div>
  );
};
