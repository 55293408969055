import React from 'react';
import SectionHeader from '../../../components_profiles/SectionHeader';
import Images from './Images';

export default function ImageGallery(props) {
  const { images } = props;

  return (
    <div className="space-y-4">
      <SectionHeader
        title="Image Gallery"
        subtitle="Photos from the activities, student work, etc."
      />
      <Images
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6"
        images={images}
      />
    </div>
  );
};
