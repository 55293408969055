import React from 'react';
import PropTypes from 'prop-types';
import ConnectButton from '../../../pages/_banner/ConnectButton';
import Wrapper from '../../../pages/_banner/Wrapper';
import useDialogLauncher from '../../_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useRouter from '../../../hooks/useRouter';

CancelledBanner.propTypes = {
  project: PropTypes.object.isRequired
};

export default function CancelledBanner(props) {
  const { project, creator } = props;

  const launch = useDialogLauncher();
  const { history } = useRouter();

  function onDuplicate() {
    launch(DialogManager.ProjectDuplicate.Config(project.id));
  }

  function onFindPartners() {
    launch(DialogManager.ProjectManage.Config(project.id, '/invite-populate'));
  }

  return (
    <Wrapper
      color="red"
      creator={creator}
      title={(
        <>
          Your project was cancelled
        </>
      )}
      subtitle={(
        <>
          If you want to try the same idea again, you can duplicate the project for
          a faster start.
        </>
      )}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        {/*<ConnectButton outline={true} onClick={() => history.push('/manage')}>*/}
        {/*  Go to messages*/}
        {/*</ConnectButton>*/}
        <ConnectButton color="red" onClick={onDuplicate}>
          Duplicate project
        </ConnectButton>
        {/*<ConnectButton disabled={true}>*/}
        {/*  This is you*/}
        {/*</ConnectButton>*/}
      </div>
    </Wrapper>
  );
};
