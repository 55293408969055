import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

PrimarySetupButton.propTypes = {
  children: PropTypes.node.isRequired
};

export default function PrimarySetupButton(props) {
  const {
    children,
    onClick,
    className,
    disabled
  } = props;

  return (
    <button
      className={classNames(
        'py-4 px-8 rounded-full text-white font-semibold shadow text-lg leading-6',
        disabled ?
          'bg-cs-gray-400 cursor-not-allowed' :
          'bg-cs-orange-500 hover:bg-cs-orange-400 focus:bg-cs-orange-500 focus:ring-cs-orange-500 focus:outline-none focus:shadow-outline cursor-pointer',
        className
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
