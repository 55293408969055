import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import validators from '../../utils/validators';
import Overlay from '../../forms/_common/Overlay';
import FormErrors from '../../forms/_common/FormErrors';
import RequestError from '../../forms/_common/RequestError';
import ErrorMessage from '../../forms/_common/ErrorMessage';
import SuccessMessage from '../../forms/_common/SuccessMessage';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import FormConnect from './Form';
import FormDiscover from '../claim.discover/Form';
import { useConnect } from '@lore/query-connect';
import useOfferInterestEmailPreview from '../../utils/offer/useOfferInterestEmailPreview';
import { useQueryMutation } from '../../../hooks/@lore/query-connect';

ClaimConnectDialog.propTypes = {
  claim: PropTypes.object.isRequired
};

export default function ClaimConnectDialog(props) {
  const { offer, claim } = props;

  const invitedByCreator = claim.data.creatorId === offer.data.creatorId;

  const [data, setData] = useState({
    customMessage: invitedByCreator ?
      '' :
      `I'm interested in this offer. Would you be interested in discussing further?`
  });
  const [showErrors, setShowErrors] = useState(false);

  const mutation = useQueryMutation('claim.update', claim);
  const { saving, success, error, request } = mutation;

  const creator = useConnect('user.byId', {
    id: offer.data.creatorId
  });
  const activity = useConnect('tag.byId', {
    id: offer.data.activityId
  });
  const emailPreview = useOfferInterestEmailPreview(offer, creator, data.customMessage);

  useEffect(() => {
    if (success) {
      props.onCancel();
      props.onSubmit && props.onSubmit(request);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate({
      response: 'Connect',
      responseMessage: data.customMessage
    });
  }

  function onChange(name, value) {
    const nextData = _.merge({}, data);
    nextData[name] = value;
    setData(nextData);
  }

  onChange = onChange.bind(data);

  return (
    <DialogTemplate className="w-screen max-w-screen-sm">
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          customMessage: [validators.isRequired]
        }}>
          {({errors, hasError}) => invitedByCreator ? (
            <FormConnect
              data={data}
              errors={errors}
              showErrors={showErrors}
              onChange={onChange}
              onSubmit={() => hasError ? setShowErrors(true) : onSubmit()}
              onCancel={props.onCancel}
              alert={error ? (
                <RequestError request={request}/>
              ) : showErrors ? (
                <ErrorMessage>
                  Please fill in all required fields.
                </ErrorMessage>
              ) : (false && success) ? (
                <SuccessMessage>
                  Idea created.
                </SuccessMessage>
              ) : null}
              claim={claim}
            />
          ) : (
            <FormDiscover
              data={data}
              errors={errors}
              showErrors={showErrors}
              onChange={onChange}
              onSubmit={() => hasError ? setShowErrors(true) : onSubmit()}
              onCancel={props.onCancel}
              alert={error ? (
                <RequestError request={request}/>
              ) : showErrors ? (
                <ErrorMessage>
                  Please fill in all required fields.
                </ErrorMessage>
              ) : (false && success) ? (
                <SuccessMessage>
                  Idea created.
                </SuccessMessage>
              ) : null}
              claim={claim}
              creator={creator}
              activity={activity}
              emailPreview={emailPreview}
            />
          )}
        </FormErrors>
      </Overlay>
    </DialogTemplate>
  );
};
