import React from 'react';
import classNames from 'classnames';
import getUploadInfo from '../pages/evaluation-project-educator/Uploads/getUploadInfo';

export default function Resource(props) {
  const { title = '', description, url } = props;

  const { name, extension, icon } = getUploadInfo(title);

  return (
    <div
      className={classNames(
        'flex flex-colx',
        'bg-white rounded-cs-10 overflow-hidden',
        'shadow-cs-flat -m-px border border-cs-gray-200',
        'cursor-pointer hover:bg-cs-orange-50'
      )}
      onClick={() => window.open(url)}
    >
      <div className="flex items-center justify-center bg-cs-gray-50 px-6">
        <icon.hack className="h-8 w-8 text-cs-yellow-500" />
      </div>
      <div className="flex-1 space-y-1 p-4 border-l border-cs-gray-100">
        {extension && (
          <div className="text-sm font-semibold text-cs-blue-500">
            {extension}
          </div>
        )}
        <div className="text-base font-semibold text-cs-gray-900">
          {name}
        </div>
        {description && (
          <div className="text-sm text-cs-gray-500">
            {description}
          </div>
        )}
      </div>
    </div>
  );
}
