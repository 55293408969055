import React from 'react';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Navigation from '../../../dialogs_common/Navigation';
import ParentLayout from '../ParentLayout';
import Urls from '../Urls';
import Form from './Form';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { location, history, match } = useRouter();
  const user = useUser();
  const evaluation = useConnect('evaluationEventEducator.byId', {
    id: Number(match.params.evaluationId)
  })

  if (evaluation.state === PayloadStates.FETCHING) {
    return null;
  }

  return (
    <ParentLayout
      evaluation={evaluation}
      page="Description"
      title={(
        <>
          In a few sentences explain why you decided to do this event and what you actually
          did with your students and partner(s).
        </>
      )}
      description={(
        <>
          <span className="font-semibold">Example:</span> I wanted to figure out how to cover our
          state's history standards in ways that would help my students see that history is alive
          today and can be explored in creative ways. I made a list of potential community partners
          who could speak about our local history and asked my students to vote on which partners
          and ideas seemed most exciting and relevant. I asked if they knew people in their own
          family or communities who might be good speakers and then looked on CommunityShare to
          find potential partners. I brought in a panel of guest speakers to offer different
          perspectives – an indigenous artist, a neighborhood elder and a geologist.
        </>
      )}
    >
      <Form
        user={user}
        evaluation={evaluation}
        navigation={(
          <Navigation v5={true} activeStep={7} steps={10}/>
        )}
        onPrevious={(newData) => history.push(Urls(evaluation).PRACTICED)}
        onNext={(newData) => history.push(Urls(evaluation).CHANGES)}
        onSkip={() => history.push(Urls(evaluation).CHANGES)}
        onExit={() => history.push(Urls(evaluation).PROJECT_MANAGE)}
      />
    </ParentLayout>
  );
};
