import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import ActionBar from './ActionBar';
import Event from '../../pages/event/Event';
import EventEditLayout from '../../pages/event-edit/Dialog';
import EventManageLayout from '../../pages/event-manage/Dialog';
import EventClassifyLayout from '../../pages/event-classify/Dialog';
import EventInvitePopulateLayout from '../../pages/event-invite-populate/Dialog';
import EventInviteLayout from '../../pages/event-invite/Dialog';
import useDialogConfig from '../_hooks/useDialogConfig';
import useDialogRouter from '../_hooks/useDialogRouter';
import MissingFields from '../../pages/event-manage/MissingFields';
import Banner from './Banner';

export default function EventManageDialogLayout(props) {
  const { event } = props;

  const { id, route } = useDialogConfig();
  const router = useDialogRouter(id);

  useEffect(() => {
    if (route === '/') {
      router.navigate('/manage');
    }
  }, []);

  if (event.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  const pageMap = {
    '/manage': 'Manage',
    '/view': 'View',
    '/edit': 'Edit',
    '/invite-populate': 'Invite',
    '/invite': 'Invite',
    '/classify': 'Classify'
  };

  const pathname = route.split('?')[0];

  // const page = pageMap[route] || route;
  const page = pageMap[pathname] || pathname;

  return (
    <div className="space-y-6">
      <Helmet>
        <title>
          {event.data.title ? `${event.data.title}: ${page}` : `Event #${event.id}: ${page}`}
        </title>
      </Helmet>
      <Banner event={event} />
      <ActionBar event={event} />
      <div className="space-y-12">
        {pathname === `/view` && (
          <Event event={event} />
        )}
        {pathname === `/edit` && (
          <>
            <MissingFields event={event} />
            <EventEditLayout
              event={event}
              onClose={props.onCancel}
            />
          </>
        )}
        {pathname === `/invite-populate` && (
          <EventInvitePopulateLayout
            event={event}
            onClose={props.onCancel}
          />
        )}
        {pathname === `/invite` && (
          <EventInviteLayout
            event={event}
            router={router}
            onClose={props.onCancel}
          />
        )}
        {pathname === `/manage` && (
          <EventManageLayout
            event={event}
            onClose={props.onCancel}
          />
        )}
        {pathname === `/classify` && (
          <EventClassifyLayout
            event={event}
            onClose={props.onCancel}
          />
        )}
      </div>
    </div>
  );
};
