import React from 'react';
import { BanknotesIcon } from '@heroicons/react/24/outline';
import useRouter from '../../hooks/useRouter';
import Urls from '../offer-create/Urls';
import DataCard from '../profile-edit/DataCard';
import usePageRouter from '../../dialogs-routable/_hooks/usePageRouter';

export default function Cost(props) {
  const { offer } = props;
  const { history } = usePageRouter();

  return (
    <DataCard
      title="Cost"
      icon={BanknotesIcon}
      subtitle={offer.data.hasCost === 'Yes' ?
        offer.data.cost || '(not provided)' :
        'Free'
    }
      onClick={() => history.push(Urls(offer).COST)}
    />
  );
};
