import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Entries from './Entries';
import Overlay from '../../../forms/_common/Overlay';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';

Practices.propTypes = {
  userPractices: PropTypes.object
};

export default function Practices(props) {
  const {
    user,
    practices,
    userPractices
  } = props;

  const mutation_create = useQueryMutation('tagNode.create');
  const mutation_destroy = useQueryMutation('tagNode.destroy2');

  const saving = (
    mutation_create.isLoading ||
    mutation_destroy.isLoading
  );

  function onSelectPractice(practice) {
    const userPractice = _.find(userPractices.data, function(userPractice) {
      return userPractice._tag.id === practice.id;
    });

    if (!userPractice) {
      mutation_create.mutate({
        userId: user.id,
        tagId: practice.id
      }, {
        onSuccess: payload => userPractices._add(payload)
      });
    } else {
      mutation_destroy.mutate(userPractice._tagNode, {
        onSuccess: payload => userPractices._remove(payload)
      });
    }
  }

  return (
    <Overlay isVisible={saving}>
      <div className="flex flex-wrap -m-4">
        <div className="w-full flex p-4">
          <Entries
            // title={category.data.name}
            // subtitle="Which of these areas do you have experience with?"
            user={user}
            practices={practices}
            userPractices={userPractices}
            onSelectPractice={onSelectPractice}
          />
        </div>
      </div>
    </Overlay>
  );
}
