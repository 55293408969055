import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useDialogConfig from '../_hooks/useDialogConfig';
import RoleUpdateDialog from '../../dialogs/role.update';
import { useQueryClient } from '@tanstack/react-query';

export default function Launcher(props) {
  const config = useDialogConfig();
  const queryClient = useQueryClient();

  const role = useConnect('role.byId', {
    id: config.params.roleId
  });

  if (role.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <RoleUpdateDialog
      role={role}
      onCancel={props.onClose}
      onSubmit={function(request) {
        queryClient.invalidateQueries({ queryKey: ['role'] });
      }}
    />
  );
};
