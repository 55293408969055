import React from 'react';
import { useUser } from '@lore/auth';
import useUserProfileMissingFields from '../hooks/useUserProfileMissingFields';
import Banner from './Banner';
import { DialogManager } from '../dialogs_new/DialogManager';
import useDialogLauncher from '../dialogs-routable/_hooks/useDialogLauncher';

export default function Layout(props) {
  const user = useUser();
  const missingFields = useUserProfileMissingFields();

  const launch = useDialogLauncher();

  if (!missingFields) {
    return null;
  }

  const fieldCount = missingFields.profile.length + missingFields.interests.length;

  function onCompleteProfile() {
    launch(DialogManager.ProfileMissing.Config());
  }

  if (fieldCount > 0) {
    return (
      <Banner>
        {user.data.isEducator ? (
          <div>
            You need to <span className="mx-1 text-white underline cursor-pointer" onClick={onCompleteProfile}>complete your profile</span> before you can create a project or event!
          </div>
        ) : (
          <div>
            You need to <span className="mx-1 text-white underline cursor-pointer" onClick={onCompleteProfile}>complete your profile</span> before educators will be able to invite you to projects and events!
          </div>
        )}
      </Banner>
    );
  }

  return null;
};
