import { useEffect, useState } from 'react';

async function getImageMimeType(url) {
  try {
    const response = await fetch(url, { method: 'HEAD' });
    if (response.ok) {
      const contentType = response.headers.get('Content-Type');
      return contentType;
    } else {
      console.error('Failed to fetch image. Status:', response.status);
      return null;
    }
  } catch (error) {
    console.error('An error occurred:', error);
    return null;
  }
}

export default function useImageMimeType(imageUrl) {
  const [mimeType, setMimeType] = useState(undefined);

  useEffect(() => {
    if (!imageUrl) {
      return;
    }

    getImageMimeType(imageUrl)
      .then(function(mimeType){
        if (mimeType) {
          // console.log('MIME Type:', mimeType);
          setMimeType(mimeType);
        }
      })
      .catch(error => {
        console.error(`An error occurred fetching the MIME type for the image url ${imageUrl}: `, error);
      });
  }, []);

  return mimeType;
};
