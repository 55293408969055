import React from 'react';
import { Helmet } from 'react-helmet-async';
import Offers from './Offers';
import useRouter from '../../hooks/useRouter';
import PageHeader from '../people-people/PageHeader';

export default function Layout(props) {
  const router = useRouter();

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Discover offers</title>
      </Helmet>
      <PageHeader
        title="Discover offers"
        subtitle={(
          <>
            Check out the exciting offers that community partners have made
            available to educators and learners below. Use filters to find the
            opportunities you're looking for.
          </>
        )}
      />
      <Offers
        router={router}
      />
    </div>
  );
}
