import React from 'react';
import Claims from './Claims';
import ComposableOfferHeader from '../../../components_cards/ComposableOfferHeader';
import Card from '../../../components/Card';
import OfferTag from '../../../components_cards/tags/OfferTag';
import TagRow from '../../../components_cards/tags/TagRow';
import CreatorTag from '../../../components_cards/tags/CreatorTag';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import OutlineButtonGray from '../../../components/Button/OutlineButton.gray';
import CompletedTag from '../../../components_cards/tags/_resource/CompletedTag';
import CancelledTag from '../../../components_cards/tags/_resource/CancelledTag';
import ExpiredTag from '../../../components_cards/tags/_resource/ExpiredTag';
import LazyCard from '../../../components/LazyCard';

export default function OfferCreated(props) {
  const { offer } = props;

  const launch = useDialogLauncher();

  function onViewOffer() {
    launch(DialogManager.OfferManage.Config(offer.id, '/view'));
  }

  function onDuplicateOffer() {
    launch(DialogManager.OfferDuplicate.Config(offer.id));
  }

  return (
    <LazyCard divider={true}>
      <ComposableOfferHeader
        offer={offer}
        showTags={false}
        tags={(
          <>
            <TagRow>
              <OfferTag />
              <CreatorTag />
              <CompletedTag resource={offer} />
              <CancelledTag resource={offer} />
              <ExpiredTag resource={offer} />
            </TagRow>
            <Claims offer={offer} />
          </>
        )}
      />
      <div className="flex justify-between p-4 bg-gray-50 flex-wrap gap-2">
        <div />
        <div className="flex flex-wrap gap-2">
          <OutlineButtonGray
            label="View offer"
            onClick={onViewOffer}
          />
          <OutlineButtonGray
            label="Duplicate offer"
            onClick={onDuplicateOffer}
          />
        </div>
      </div>
    </LazyCard>
  );
}
