import React from 'react';
import SelectorProjectEventOfferDialog from '../../dialogs/selector.project-event-offer';

export default function Launcher(props) {
  return (
    <SelectorProjectEventOfferDialog
      onCancel={props.onClose}
    />
  );
};
