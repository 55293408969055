import React from 'react'
import PropTypes from 'prop-types';
import { marked } from 'marked';
import Dotdotdot from 'react-dotdotdot'

ClampableText.propTypes = {
  children: PropTypes.node.isRequired,
  clamp: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]).isRequired,
  className: PropTypes.string,
  markup: PropTypes.bool
};

ClampableText.defaultProps = {
  children: '',
  clamp: false
};

export default function ClampableText(props) {
  const {
    children,
    clamp,
    className,
    markup
  } = props;

  if (clamp === false) {
    return markup ? (
      <div className={className} dangerouslySetInnerHTML={{__html: marked.parse(children)}} />
    ) : (
      <div className={className}>
        {children}
      </div>
    )
  }

  return (
    <Dotdotdot clamp={clamp}>
      {markup ? (
        <div className={className} dangerouslySetInnerHTML={{__html: marked.parse(children)}} />
      ) : (
        <div className={className}>
          {children}
        </div>
      )}
    </Dotdotdot>
  );
};
