import React from 'react';
import { useConnect } from '@lore/query-connect';
import { BuildingOfficeIcon } from '@heroicons/react/24/outline';
import Urls from '../project-create/Urls';
import DataCard from '../profile-edit/DataCard';
import usePageRouter from '../../dialogs-routable/_hooks/usePageRouter';

export default function School(props) {
  const { project } = props;
  const { history } = usePageRouter();

  const site = useConnect('site.byId', {
    id: project.data.siteId
  }, {
    enabled: !!project.data.siteId
  });

  if (!project.data.siteId) {
    return (
      <DataCard
        title="School"
        icon={BuildingOfficeIcon}
        subtitle="(not provided)"
        onClick={() => history.push(Urls(project).SCHOOL)}
      />
    );
  }

  return (
    <DataCard
      title="School"
      icon={BuildingOfficeIcon}
      subtitle={site.data.name || '...'}
      onClick={() => history.push(Urls(project).SCHOOL)}
    />
  );
};
