import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import DialogTemplate from '../../../dialogs_common/DialogTemplate';
import SolidButton from '../../../components/Button/SolidButton';
import FormTemplate from '../../../dialogs_common/FormTemplate';
import usePageRouter from '../../../dialogs-routable/_hooks/usePageRouter';
import auth from '../../../utils/auth';
import { CheckIcon } from '@heroicons/react/24/outline';

Confirmation.propTypes = {
  onCancel: PropTypes.func.isRequired
};

export default function Confirmation(props) {
  const { data, onCancel } = props;

  const { history } = usePageRouter();

  useEffect(() => {
    auth.deleteToken();
  }, []);

  function onNavigate() {
    onCancel();
    history.push(`/logout`);
  }

  return (
    <DialogTemplate>
      <FormTemplate
        icon={(
          <div className="flex justify-center mb-4">
            <CheckIcon className="text-cs-orange-500 h-12 w-12" />
          </div>
        )}
        title="Email changed!"
        subtitle="Log out and back in with your updated email."
        body={(
          <div className="space-y-2 text-cs-gray-500x text-centerx text-lgx">
            {/*<div>*/}
            {/*  <strong>Log out and back in with your new email.</strong>*/}
            {/*</div>*/}
            <div className="space-y-3">
              <div>
                <span className="font-semibold">If you previously logged in using an email/password</span>, then you
                can use the new email with your old password.
              </div>
              <div>
                <span className="font-semibold">If you previously logged in using a social provider</span> (like Google,
                Facebook, etc) then you will need to log in using a social provider linked to your
                new email, or create a new email/password account using your new email.
              </div>
              <div>
                <span className="font-semibold">If you log in again using any method linked to your
                previous email, a new account will be created.</span>
              </div>
            </div>
          </div>
        )}
        footer={(
          <div className="flex flex-col m-auto text-center space-y-4">
            <SolidButton onClick={onNavigate}>
              Next / Log out
            </SolidButton>
            {/*<OutlineButton onClick={onCancel}>*/}
            {/*  Close*/}
            {/*</OutlineButton>*/}
          </div>
        )}
      />
    </DialogTemplate>
  );
};
