import React from 'react';
import EmptyState, { EmptyStateActions } from '../../components/EmptyState';
import { UsersIcon } from '@heroicons/react/24/outline';
import { SolidButton, OutlineButton } from '../../components/Button';
import useRouter from '../../hooks/useRouter';

export default function ParticipatingCallToAction(props) {
  const { history } = useRouter();

  return (
    <EmptyState
      icon={UsersIcon}
      title="Looking for a way to get involved?"
      subtitle={(
        <>
          Explore existing projects, events, and offers created by others where you
          can contribute your experience and make an impact.
        </>
      )}
    >
      <EmptyStateActions>
        <OutlineButton
          label="Explore existing opportunities"
          onClick={() => history.push('/opportunities')}
        />
      </EmptyStateActions>
    </EmptyState>
  );
}
