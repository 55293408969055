import React from 'react';
import PropTypes from 'prop-types';
import ConnectButton from '../../../pages/_banner/ConnectButton';
import Wrapper from '../../../pages/_banner/Wrapper';
import useDialogLauncher from '../../_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';

CreatorBanner.propTypes = {
  offer: PropTypes.object.isRequired
};

export default function CreatorBanner(props) {
  const { offer, creator } = props;

  const launch = useDialogLauncher();

  function onManage() {
    launch(DialogManager.OfferManage.Config(offer.id, '/manage'));
  }

  return (
    <Wrapper
      creator={creator}
      title={(
        <>
          This is your offer.
        </>
      )}
      subtitle={(
        <>
          Click the button below to edit the details, invite educators, or message
          existing collaborators.
        </>
      )}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        <ConnectButton onClick={onManage}>
          Manage offer
        </ConnectButton>
      </div>
    </Wrapper>
  );
};
