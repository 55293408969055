import React from 'react';
import useDialogConfig from '../_hooks/useDialogConfig';
import FiltersEducatorDialog from '../../dialogs/filters.educators';
import useDialogRouter from '../_hooks/useDialogRouter';
import usePageRouter from '../_hooks/usePageRouter';

export default function Launcher(props) {
  const config = useDialogConfig();

  const router = config.params.parentDialogId ?
    useDialogRouter(config.params.parentDialogId) :
    usePageRouter();

  return (
    <FiltersEducatorDialog
      router={router}
      onCancel={props.onClose}
    />
  );
};
