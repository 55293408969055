import React from 'react';
import { useUser } from '@lore/auth';
import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import OptionCard from '../my-network/OptionCard';
import Images from '../../constants/Images';
import Recommended from './Recommended';

function Header(props) {
  const { children } = props;

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Explore People</title>
      </Helmet>
      <PageHeader
        title="Discover people in your community"
        subtitle={(
          <>
            Explore educators who are leading exciting projects and events you might
            contribute to. Find community partners who have skills, knowledge, and
            experiences to help bring your projects to life.
          </>
        )}
      />
      <div className="space-y-8">
        {children}
      </div>
    </div>
  );
}

export default function Layout(props) {
  const currentUser = useUser();

  return (
    <Header currentUser={currentUser}>
      {/*<div className="space-y-8">*/}
      {/*  <BrowseEducators/>*/}
      {/*  <BrowsePartners/>*/}
      {/*</div>*/}
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4">
        <OptionCard
          // imageUrl={teamworkImage}
          imageUrl={Images.FIND_PARTNERS}
          title="Find partners to help bring your project to life"
          subtitle="Discover partners with skills and experience to contribute to your projects."
          linkText="Browse partners"
          to="/partners-populate"
        />
        <OptionCard
          // imageUrl={bookshelfImage}
          imageUrl={Images.EXPLORE_EDUCATORS}
          title="Explore educators to collaborate with"
          subtitle="Find educators leading projects where you can contribute your skills."
          linkText="Browse educators"
          to="/educators-populate"
        />
      </div>
      <Recommended />
    </Header>
  );
}
