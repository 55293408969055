import React from 'react';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import { SolidButton } from '../../../components/Button';
import GrayOutlineButton from '../../../components/Button/OutlineButton.gray';
import ComposableOfferHeader from '../../../components_cards/ComposableOfferHeader';
import DividerCard from '../../../components/DividerCard';
import TagRow from '../../../components_cards/tags/TagRow';
import OfferTag from '../../../components_cards/tags/OfferTag';
import ExpiredTag from '../../../components_cards/tags/_resource/ExpiredTag';
import CreatorTag from '../../../components_cards/tags/CreatorTag';
import OfferMissingFieldsTag from '../../../components_cards/ComposableOfferHeader/OfferMissingFieldsTag';

export default function ExpiredOffer(props) {
  const { offer } = props;

  const launch = useDialogLauncher();

  function onClickView() {
    launch(DialogManager.OfferManage.Config(offer.id, `/view`));
  }

  function onClickEdit() {
    launch(DialogManager.OfferManage.Config(offer.id, `/edit`));
  }

  return (
    <DividerCard>
      <ComposableOfferHeader
        offer={offer}
        tags={(
          <TagRow>
            <OfferTag />
            {/*<CreatorTag />*/}
            <OfferMissingFieldsTag offer={offer} />
            {/*<ExpiredTag resource={offer} />*/}
          </TagRow>
        )}
        showTags={false}
        showCreator={false}
      />
      <div className="w-full bg-cs-gray-50 py-4 px-4 flex justify-between items-center">
        <div />
        <div className="flex flex-wrap gap-2">
          <GrayOutlineButton
            onClick={onClickView}
            label="View offer"
          />
          <SolidButton
            onClick={onClickEdit}
            label="Continue describing offer"
          />
        </div>
      </div>
    </DividerCard>
  );
}
