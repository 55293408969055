import React from 'react';
import PropTypes from 'prop-types';
import ConnectButton from '../../../pages/_banner/ConnectButton';
import Wrapper from '../../../pages/_banner/Wrapper';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../_hooks/useDialogLauncher';

PendingBanner.propTypes = {
  event: PropTypes.object.isRequired,
  invitation: PropTypes.object.isRequired
};

export default function PendingBanner(props) {
  const { event, invitation, creator } = props;

  const launch = useDialogLauncher();

  function showYesDialog() {
    launch(DialogManager.InvitationYes.Config(invitation.id));
  }

  function showNoDialog() {
    launch(DialogManager.InvitationNo.Config(invitation.id));
  }

  function showMaybeDialog() {
    launch(DialogManager.InvitationMaybe.Config(invitation.id));
  }

  return (
    <Wrapper
      color="yellow"
      creator={creator}
      title={(
        <>
          {creator.data.firstName} invited you to an event! Are you available?
        </>
      )}
      subtitle={(
        <>
          Let {creator.data.firstName} know if you can attend, have questions,
          or won't be able to make it.
        </>
      )}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        <ConnectButton outline={true} onClick={showNoDialog}>
          No, I can't attend
        </ConnectButton>
        <ConnectButton outline={true} onClick={showMaybeDialog}>
          Maybe, I have questions first
        </ConnectButton>
        <ConnectButton color="yellow" onClick={showYesDialog}>
          Yes, I'll attend
        </ConnectButton>
      </div>
    </Wrapper>
  );
};
