import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/logo.png';
import Sidebar from './Sidebar';
import Network from '../Network';
import classNames from 'classnames';
import { useConfig } from '@lore/config';

export default function Header(props) {
  const config = useConfig();

  return (
    <nav className={classNames(
      'block bg-white py-5 px-6',
      '-mb-px border-b border-cs-gray-200',
    )}>
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row space-x-4">
          <Link className="cursor-pointer" to="/">
            <img
              className={classNames(
                'h-12',
                config.flags.grayscale && 'grayscale'
              )}
              src={logo}
              alt="logo"
            />
          </Link>
          <Network showName={false}/>
        </div>
        <Sidebar/>
      </div>
    </nav>
  );
}
