import React from 'react';
import _ from 'lodash';
import { UseQueryConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import { Disclosure } from '@headlessui/react';
import DisclosureTagFilterButton from './DisclosureTagFilterButton';
import CheckboxField from './CheckboxField';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DisclosureFilterCount from '../../pages/people-partners/DisclosureFilterCount';

DisclosureBaseFilter.propTypes = {
  getParams: PropTypes.func.isRequired
};

export default function DisclosureBaseFilter(props) {
  const {
    label,
    tooltip,
    getParams,
    value = [],
    onChange,
    modelName = 'user',
    tags
  } = props;

  const numberSelected = value.length;

  function isTagSelected(tag) {
    return !!_.find(value, function(id) {
      return id === tag.id;
    });
  }

  return (
    <Disclosure as="div" defaultOpen={numberSelected > 0}>
      {({ open }) => (
        <>
          <DisclosureTagFilterButton
            // label={numberSelected ? `${label} (${numberSelected})` : label}
            label={(
              <span className="flex items-center space-x-2">
                <span>
                  {label}
                </span>
                <DisclosureFilterCount
                  count={numberSelected}
                />
              </span>
            )}
            tooltip={tooltip}
            open={open}
          />
          <Disclosure.Panel className="px-4 py-4 space-y-4">
            {tags.data.map(function(tag, index) {
              const params = getParams(tag);

              const selected = isTagSelected(tag);

              return (
                <UseQueryConnect key={index} _key={`${modelName}.find`} params={params}>
                  {function(model) {
                    const count = model.state === PayloadStates.FETCHING ? '...' : model.meta.totalCount;

                    return (
                      <CheckboxField
                        label={(
                          <span className="flex justify-between">
                            <span className="text-base">
                              {tag.data.name}
                            </span>
                            <span className={classNames(
                              'text-sm text-cs-gray-500 h-5',
                            )}>
                              {count}
                            </span>
                          </span>
                        )}
                        // description={tag.data.description}
                        description={(
                          <div className="mr-6">
                            {tag.data.description}
                          </div>
                        )}
                        checked={selected}
                        onChange={() => onChange(tag.id)}
                      />
                    );
                  }}
                </UseQueryConnect>
              );
            })}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
