import React, { useState } from 'react';
import { UsersIcon } from '@heroicons/react/24/outline';
import useRouter from '../../hooks/useRouter';
import Urls from '../event-create/Urls';
import DataCard from '../profile-edit/DataCard';
import { PayloadStates } from '@lore/utils';
import useEventTags from '../../hooks/useEventTags';
import TagTypes from '../../constants/TagTypes';
import usePageRouter from '../../dialogs-routable/_hooks/usePageRouter';

export default function Participants(props) {
  const { event } = props;

  const { history } = usePageRouter();

  const [timestamp] = useState(new Date().toISOString());

  const eventParticipants = useEventTags(event, TagTypes.Participant, timestamp);

  let subtitle = '';

  if (eventParticipants.state === PayloadStates.FETCHING) {
    subtitle = '...';
  } else {
    subtitle = eventParticipants.data.map(function(eventParticipant) {
      return eventParticipant.data.name;
    }).join(', ');
  }

  return (
    <DataCard
      title="Participants"
      icon={UsersIcon}
      subtitle={subtitle || '(not provided)'}
      onClick={() => history.push(Urls(event).PARTICIPANTS)}
    />
  );
};
