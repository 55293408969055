import React from 'react';
import _ from 'lodash';
import { useConnect } from '@lore/query-connect';
import User from '../people-partners/User';
import mapResponse from '../../utils/mapResponse';

export default function OfferUser(props) {
  const { user, offer } = props;

  const claims = useConnect('claim.find', {
    where: {
      eager: {
        $where: {
          offerId: offer.id
        }
      }
    },
    pagination: {
      pageSize: 1000
    }
  });

  const claim = _.find(claims.data, function(claim) {
    return claim.data.userId === user.id;
  });

  const selected = !!claim;

  return (
    <User
      user={user}
      offer={offer}
      selected={selected}
      footer={claim ? mapResponse(claim.data.response) : undefined}
      to={`/offer/${offer.id}/invite/${user.id}`}
    />
  );
}
