import { useConnect } from '@lore/query-connect';
import { useUser } from '@lore/auth';

export default function usePendingProjectPartnerEvaluations(props) {
  const currentUser = useUser();

  const params = {
    where: {
      eager: {
        $where: {
          userId: currentUser.id,
          completed: false
        }
      }
    },
    pagination: {
      pageSize: 100,
      order: 'createdAt desc'
    }
  };

  return useConnect('evaluationProjectPartner.find', params);
}
