import REQUIRED_FIELDS from './REQUIRED_FIELDS';
import MISSING_FIELDS from './MISSING_FIELDS';

export default function getBucketParams(timestamp) {
  return {
    active: {
      $and: REQUIRED_FIELDS.$and,
      // published: true,
      cancelled: false,
      completed: false,
      $or: [
        {
          specificDate: 'Yes',
          date: {
            $gt: timestamp
          }
        },
        {
          specificDate: 'No',
          endDate: {
            $gt: timestamp
          }
        }
      ]
    },
    draft: {
      cancelled: false,
      completed: false,
      $or: {
        ...MISSING_FIELDS
      }
    },
    // past: {
    //   $or: [
    //     { cancelled: true },
    //     { completed: true },
    //     {
    //       $and: [
    //         ...REQUIRED_FIELDS.$and,
    //         {
    //           $or: [
    //             {
    //               specificDate: 'Yes',
    //               date: {
    //                 $lte: timestamp
    //               }
    //             },
    //             {
    //               specificDate: 'No',
    //               endDate: {
    //                 $lte: timestamp
    //               }
    //             }
    //           ]
    //         }
    //       ]
    //     }
    //   ]
    // },
    past: {
      $or: [
        { cancelled: true },
        { completed: true },
        // {
        //   $and: [
        //     ...REQUIRED_FIELDS.$and,
        //     {
        //       $or: [
        //         {
        //           specificDate: 'Yes',
        //           date: {
        //             $lte: timestamp
        //           }
        //         },
        //         {
        //           specificDate: 'No',
        //           endDate: {
        //             $lte: timestamp
        //           }
        //         }
        //       ]
        //     }
        //   ]
        // }
      ],
    },
    expired: {
      $and: REQUIRED_FIELDS.$and,
      // published: true,
      cancelled: false,
      completed: false,
      $or: [
        {
          specificDate: 'Yes',
          date: {
            $lte: timestamp
          }
        },
        {
          specificDate: 'No',
          endDate: {
            $lte: timestamp
          }
        }
      ]
    }
  };
}
