import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import FormTemplate from '../../../dialogs_common/SetupTemplate';
import PrimaryButton from '../../../components_setup/PrimarySetupButton';
import Ages from './Ages';
import Groups from './Groups';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';

Form.propTypes = {
  user: PropTypes.object.isRequired,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  onSkip: PropTypes.func
};

export default function Form(props) {
  const {
    user,
    groups,
    userGroups,
    ages,
    userAges,
    navigation,
    onNext,
    onPrevious,
    onSkip
  } = props;

  const [data, setData] = useState(_.pick(user.data, [
    // 'motivation'
  ]));
  const [showErrors, setShowErrors] = useState(false);

  const mutation = useQueryMutation('currentUser.updateSingleton', user);
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      onNext(data);
    }
  }, [success]);

  function onSubmit() {
    // mutation.mutate(data);
    onNext(data);
  }

  function onChange(name, value) {
    setData(_.merge({}, data, {
      [name]: value
    }));
  }

  return (
    <div className="my-auto">
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          // name: [validators.isRequired]
        }}>
          {({ errors, hasError }) => {
            return (
              <FormTemplate
                key={`${data.name}.${data.place_id}.${data.type}`}
                onPrevious={onPrevious}
                navigation={navigation}
                title="What kinds of students are you interested in helping?"
                subtitle="Youth often want to connect with adults who have similar backgrounds to them. Tell us who you're most interested in working with and we'll connect you to educators working with them."
                alert={error ? (
                  <RequestError request={request} v2={true} />
                ) : showErrors ? (
                  <ErrorMessage v2={true}>
                    Please fill in all required fields.
                  </ErrorMessage>
                ) : null}
                body={(
                  <>
                    <div className="flex flex-col mb-12 max-w-4xl">
                      <div className="mb-6">
                        <h5 className="text-3xl text-cs-gray-900 font-semibold text-left">
                          Student age groups
                        </h5>
                      </div>
                      <Ages
                        user={user}
                        ages={ages}
                        userAges={userAges}
                      />
                    </div>
                    <div className="flex flex-col max-w-4xl">
                      <div className="mb-6">
                        <h5 className="text-3xl text-cs-gray-900 font-semibold text-left">
                          Student backgrounds
                        </h5>
                      </div>
                      <Groups
                        user={user}
                        groups={groups}
                        userGroups={userGroups}
                      />
                    </div>
                  </>
                )}
                footer={(
                  <>
                    {/*<div className="text-cs-orange-500 underline cursor-pointer mt-6 sm:mt-0 mr-0 sm:mr-12" onClick={onSkip}>*/}
                    {/*  Skip this step*/}
                    {/*</div>*/}
                    <PrimaryButton disabled={hasError} onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
                      Next
                    </PrimaryButton>
                  </>
                )}
              />
            );
          }}
        </FormErrors>
      </Overlay>
    </div>
  );
};
