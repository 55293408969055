import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SaveExitButton from './SaveExitButton';

FlowTemplate.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  alert: PropTypes.node,
  body: PropTypes.node,
  footer: PropTypes.node,
  onClose: PropTypes.func,
  onPrevious: PropTypes.func,
  showClose: PropTypes.bool
};

FlowTemplate.defaultProps = {
  showClose: true
};

export default function FlowTemplate(props) {
  const {
    navigation,
    title,
    subtitle,
    alert,
    body,
    footer,
    onClose,
    onPrevious,
    onExit,
    className,
    showClose,
    titleLink
  } = props;

  return (
    <div className={`m-dialog-formx ${className}x`}>
      {onExit && (
        <SaveExitButton onClick={onExit} />
      )}
      {(onPrevious || navigation) && (
        <div className="fixed z-10 bottom-0 right-0 w-full lg:w-1/2">
          <div className="bg-white shadow-md relative">
            <div className="flex justify-between items-center h-24 px-12">
              {onPrevious && (
                <div className="text-xl text-cs-orange-500 font-semibold cursor-pointer" onClick={onPrevious}>
                  Back
                </div>
              )}
              <div className="absolute z-20 top-0 left-0 right-0">
                {navigation}
              </div>
              {footer}
            </div>
          </div>
        </div>
      )}
      <div className={classNames(
        'px-16 lg:px-32 pt-32',
        navigation ? 'pb-48' : 'pb-32'
      )}>
        <div>
          <h5 className="text-3xl font-light text-center max-w-xl-x m-auto relative">
            {title}
            {titleLink}
          </h5>
          {subtitle ? (
            <h6 className="font-light mt-10 text-cs-gray-500 text-center text-lg">
              {subtitle}
            </h6>
          ) : null}
        </div>
        {alert}
        <div className="">
          {body}
        </div>
      </div>
    </div>
  );
}
