import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import validators from '../../utils/validators';
import Overlay from '../../forms/_common/Overlay';
import FormErrors from '../../forms/_common/FormErrors';
import RequestError from '../../forms/_common/RequestError';
import ErrorMessage from '../../forms/_common/ErrorMessage';
import SuccessMessage from '../../forms/_common/SuccessMessage';
import Form from './Form';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import { useQueryMutation } from '../../../hooks/@lore/query-connect';

export default function EventDeclineDialog(props) {
  const { invitation } = props;

  const [data, setData] = useState({
    reason: ''
  });
  const [showErrors, setShowErrors] = useState(false);

  const mutation = useQueryMutation('invitation.update', invitation);
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      props.onCancel();
      props.onSubmit && props.onSubmit(request);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate({
      response: 'No',
      responseMessage: data.reason
    });
  }

  function onChange(name, value) {
    const nextData = _.merge({}, data);
    nextData[name] = value;
    setData(nextData);
  }

  onChange = onChange.bind(data);

  return (
    <DialogTemplate className="w-screen max-w-screen-sm">
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          reason: [validators.isRequired]
        }}>
          {({errors, hasError}) => (
            <Form
              data={data}
              errors={errors}
              showErrors={showErrors}
              onChange={onChange}
              onSubmit={() => hasError ? setShowErrors(true) : onSubmit()}
              onCancel={props.onCancel}
              alert={error ? (
                <RequestError request={request}/>
              ) : showErrors ? (
                <ErrorMessage>
                  Please select a reason.
                </ErrorMessage>
              ) : (false && success) ? (
                <SuccessMessage>
                  Idea created.
                </SuccessMessage>
              ) : null}
            />
          )}
        </FormErrors>
      </Overlay>
    </DialogTemplate>
  );
};
