import React from 'react';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import ParentLayout from '../Layout';
import Navigation from '../../../dialogs_common/Navigation';
import Urls from '../Urls';
import Activities from './Activities';
import { PayloadStates } from '@lore/utils';
import useRouter from '../../../hooks/useRouter';
import useTags from '../../../hooks/useTags';
import TagTypes from '../../../constants/TagTypes';

export default function Layout(props) {
  const { history, match } = useRouter();

  const user = useUser();

  const project = useConnect('project.byId', {
    id: Number(match.params.projectId)
  });

  const activities = useTags(TagTypes.Activity);

  if (
    project.state === PayloadStates.FETCHING ||
    activities.state === PayloadStates.FETCHING
  ) {
    return null;
  }

  return (
    <ParentLayout
      project={project}
      page="Activity"
      title={(
        <>
          Which form of <a className="text-white underline hover:text-cs-orange-500" href="https://drive.google.com/file/d/1XcGLTbR8WA8dETan3DeGfUUHYXZxkRcl/view" target="_blank">Community-Engaged Learning</a> would best support your project?
        </>
      )}
      description={(
        <div className="space-y-4">
          <div>
            <span className="font-semibold italic">TIP</span>: If you have an involved project and
            are looking for several different partners to engage in a variety of different ways
            with you or your students, a "Challenge" is a good place to start.
          </div>
          <div className="font-semibold">
            Choose ONE Activity Type.
          </div>
        </div>
      )}
    >
      <Activities
        user={user}
        project={project}
        activities={activities}
        navigation={(
          <Navigation v5={true} activeStep={0} steps={19}/>
        )}
        onPrevious={(newData) => history.push(Urls(project).SCHOOL)}
        onNext={(newData) => history.push(Urls(project).TITLE)}
        onSkip={() => history.push(Urls(project).TITLE)}
      />
    </ParentLayout>
  );
};
