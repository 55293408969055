import React from 'react';
import classNames from 'classnames';

export default function Divider(props) {
  const { className } = props;

  return (
    <div className={classNames(
      `h-0 border-t border-cs-gray-100`,
      className
    )} />
  );
}
