import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';

Navigation.propTypes = {
  activeStep: PropTypes.number
};

export default function Navigation(props) {
  const { activeStep, steps = 0, invisible = false, v2 = false, v5 = false } = props;

  if (v5) {
    return (
      <div className="bg-cs-gray-200 rounded-full overflow-hidden w-full">
        <div className="h-0.5 bg-cs-blue-500" style={{ width: `${activeStep/steps*100}%` }} />
      </div>
    );
  }

  if (v2) {
    return (
      <div className="bg-cs-gray-200 rounded-full overflow-hidden w-full">
        <div className="h-2 bg-cs-orange-500 rounded-full" style={{ width: `${activeStep/steps*100}%` }} />
      </div>
    );
  }

  return (
    <div className={classNames(
      'flex justify-center space-x-3',
      invisible && 'invisible'
    )}>
      {_.range(steps).map((step) => {
        const currentStep = step + 1;
        const isPast = currentStep < activeStep;
        const isActive = currentStep === activeStep;
        const isFuture = currentStep > activeStep;

        return (
          <div
            key={step}
            className={classNames(
            'w-3 h-3 border-2 border-cs-orange-500 rounded-full',
              isPast && 'bg-cs-orange-500 opacity-60',
              isActive && 'bg-cs-orange-500',
              isFuture && 'bg-white opacity-60'
            )}
          />
        );
      })}
    </div>
  );
}
