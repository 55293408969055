import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Overlay from '../../forms/_common/Overlay';
import FormErrors from '../../forms/_common/FormErrors';
import RequestError from '../../forms/_common/RequestError';
import ErrorMessage from '../../forms/_common/ErrorMessage';
import SuccessMessage from '../../forms/_common/SuccessMessage';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import Form from './Form';
import { useQueryMutation } from '../../../hooks/@lore/query-connect';

DestroyOrganizationDialog.propTypes = {
  job: PropTypes.object.isRequired
};

export default function DestroyOrganizationDialog(props) {
  const { job, onCancel } = props;

  const [data, setData] = useState({});
  const [showErrors, setShowErrors] = useState(false);

  const mutation = useQueryMutation('job.destroy', job);
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      props.onCancel();
      props.onSubmit && props.onSubmit(request);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate();
  }

  return (
    <DialogTemplate>
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          // name: [validators.isRequired]
        }}>
          {({ errors, hasError }) => (
            <Form
              data={data}
              errors={errors}
              showErrors={showErrors}
              onChange={(name, value) => {
                setData(_.merge({}, data, {
                  [name]: value
                }));
              }}
              onSubmit={() => hasError ? setShowErrors(true) : onSubmit()}
              onCancel={onCancel}
              alert={error ? (
                <RequestError request={request} />
              ) : showErrors ? (
                <ErrorMessage>
                  Please fill in all required fields.
                </ErrorMessage>
              ) : (false && success) ? (
                <SuccessMessage>
                  Contact information updated.
                </SuccessMessage>
              ) : null}
            />
          )}
        </FormErrors>
      </Overlay>
    </DialogTemplate>
  );
};
