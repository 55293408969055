import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useDialogConfig from '../_hooks/useDialogConfig';
import ConversationDialog from '../../dialogs/conversation.view';

export default function Launcher(props) {
  const config = useDialogConfig();

  const user = useConnect('user.byId', {
    id: config.params.userId
  });

  const project = useConnect('project.byId', {
    id: config.params.projectId
  }, {
    enabled: !!config.params.projectId
  });

  const event = useConnect('event.byId', {
    id: config.params.eventId
  }, {
    enabled: !!config.params.eventId
  });

  const offer = useConnect('offer.byId', {
    id: config.params.offerId
  }, {
    enabled: !!config.params.offerId
  });

  const steward = useConnect('user.byId', {
    id: config.params.stewardId
  }, {
    enabled: !!config.params.stewardId
  });

  if (
    user.state === PayloadStates.FETCHING ||
    (
      config.params.projectId &&
      project.state === PayloadStates.FETCHING
    ) || (
      config.params.eventId &&
      event.state === PayloadStates.FETCHING
    ) || (
      config.params.offerId &&
      offer.state === PayloadStates.FETCHING
    ) || (
      config.params.stewardId &&
      steward.state === PayloadStates.FETCHING
    )
  ) {
    return (
      <Loader />
    );
  }

  return (
    <ConversationDialog
      user={user}
      project={project.id ? project : undefined}
      event={event.id ? event : undefined}
      offer={offer.id ? offer : undefined}
      steward={steward.id ? steward : undefined}
      onCancel={props.onClose}
    />
  );
};
