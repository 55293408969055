import React, { useState } from 'react';
import { BeakerIcon } from '@heroicons/react/24/outline';
import useRouter from '../../hooks/useRouter';
import Urls from '../offer-create/Urls';
import DataCard from '../profile-edit/DataCard';
import { PayloadStates } from '@lore/utils';
import useOfferTags from '../../hooks/useOfferTags';
import TagTypes from '../../constants/TagTypes';
import usePageRouter from '../../dialogs-routable/_hooks/usePageRouter';

export default function GroupSize(props) {
  const { offer } = props;

  const { history } = usePageRouter();

  const [timestamp] = useState(new Date().toISOString());

  const offerSizes = useOfferTags(offer, TagTypes.Size, timestamp);

  let subtitle = '';

  if (offerSizes.state === PayloadStates.FETCHING) {
    subtitle = '...';
  } else {
    subtitle = offerSizes.data.map(function(offerSize) {
      return offerSize.data.name;
    }).join(', ');
  }

  return (
    <DataCard
      title="Group Size"
      icon={BeakerIcon}
      subtitle={subtitle || '(not provided)'}
      onClick={() => history.push(Urls(offer).DESIGN)}
    />
  );
};
