import React from 'react';
import Text from '../../../typography';

export default function HeaderIcon(props) {
  const { label, icon } = props;

  return (
    <div className="flex space-x-1 items-center">
      <props.icon className="text-cs-gray-500 h-6 w-6" />
      <Text.SmGrayMedium>
        {label}
      </Text.SmGrayMedium>
    </div>
  );
};
