import React, { useState } from 'react';
import { BriefcaseIcon } from '@heroicons/react/24/outline';
import useRouter from '../../hooks/useRouter';
import Urls from '../project-create/Urls';
import DataCard from '../profile-edit/DataCard';
import { PayloadStates } from '@lore/utils';
import useProjectTags from '../../hooks/useProjectTags';
import TagTypes from '../../constants/TagTypes';
import usePageRouter from '../../dialogs-routable/_hooks/usePageRouter';

export default function Skills(props) {
  const { project } = props;

  const { history } = usePageRouter();

  const [timestamp] = useState(new Date().toISOString());

  const projectExpertiseTypes = useProjectTags(project, TagTypes.ExpertiseType, timestamp);

  let subtitle = '';

  if (projectExpertiseTypes.state === PayloadStates.FETCHING) {
    subtitle = '...';
  } else {
    subtitle = projectExpertiseTypes.data.map(function(projectExpertiseType, index) {
      return (
        <div key={index}>
          {projectExpertiseType.data.name}
        </div>
      );
    });
  }

  return (
    <DataCard
      title="Career experience you are looking for in potential partners"
      icon={BriefcaseIcon}
      subtitle={subtitle || '(not provided)'}
      onClick={() => history.push(Urls(project).PARTNER)}
    />
  );
};
