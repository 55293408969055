import React from 'react';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Navigation from '../../../dialogs_common/Navigation';
import ParentLayout from '../../evaluation-project-educator/ParentLayout';
import Urls from '../Urls';
import Form from './Form';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { location, history, match } = useRouter();
  const user = useUser();
  const evaluation = useConnect('evaluationProjectPartner.byId', {
    id: Number(match.params.evaluationId)
  });

  if (evaluation.state === PayloadStates.FETCHING) {
    return null;
  }

  return (
    <ParentLayout
      evaluation={evaluation}
      page="Outcome"
      title="As a community partner, please rate the following statements as they relate to your experience."
      // description={(
      //   <>
      //     Words
      //   </>
      // )}
    >
      <Form
        user={user}
        evaluation={evaluation}
        navigation={(
          <Navigation v5={true} activeStep={3} steps={5}/>
        )}
        onPrevious={(newData) => evaluation.data.type === 'Full' ?
          history.push(Urls(evaluation).HOURS) :
          history.push(Urls(evaluation).START)
      }
        onNext={(newData) => history.push(Urls(evaluation).CONTRIBUTION)}
        onSkip={() => history.push(Urls(evaluation).CONTRIBUTION)}
        onExit={() => history.push(Urls(evaluation).PROJECT_VIEW)}
      />
    </ParentLayout>
  );
};
