import React from 'react';
import PropTypes from 'prop-types';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import Story from '../../pages/story/Story'
import CloseDialogButton from '../../dialogs_common/CloseDialogButton';

StoryViewDialog.propTypes = {
  story: PropTypes.object.isRequired
};

export default function StoryViewDialog(props) {
  const { story } = props;

  return (
    <DialogTemplate className="max-w-screen-lg">
      <CloseDialogButton onCancel={props.onCancel} />
      <div className="px-6 py-6 pt-16">
        <Story
          story={story}
          onClose={props.onCancel}
        />
      </div>
    </DialogTemplate>
  );
};
