import React from 'react';
import PropTypes from 'prop-types';
import useRouter from '../../hooks/useRouter';
import ProtectedButton from './ProtectedButton';
import validButtons from './validButtons';

ProtectedLinkButton.propTypes = {
  button: validButtons,
  to: PropTypes.string
};

export default function ProtectedLinkButton(props) {
  const {
    button,
    to,
    ...other
  } = props;

  const { history } = useRouter();

  return (
    <ProtectedButton
      button={button}
      onClick={() => history.push(to)}
      { ...other}
    />
  );
}
