import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useDialogConfig from '../_hooks/useDialogConfig';
import ClaimConnectDialog from '../../dialogs/claim.connect';
import { useQueryClient } from '@tanstack/react-query';

export default function Launcher(props) {
  const config = useDialogConfig();
  const queryClient = useQueryClient();

  const claim = useConnect('claim.byId', {
    id: config.params.claimId
  });

  const offer = useConnect('offer.byId', {
    id: claim.data.offerId
  }, {
    enabled: !!claim.data.offerId
  });

  if (
    claim.state === PayloadStates.FETCHING ||
    offer.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  return (
    <ClaimConnectDialog
      claim={claim}
      offer={offer}
      onCancel={props.onClose}
      onSubmit={function(request) {
        queryClient.invalidateQueries({ queryKey: ['claim'] });
      }}
    />
  );
};
