import React from 'react';
import PropTypes from 'prop-types';
import ConnectButton from '../../../pages/_banner/ConnectButton';
import Wrapper from '../../../pages/_banner/Wrapper';
import useDialogLauncher from '../../_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useRouter from '../../../hooks/useRouter';

DraftBanner.propTypes = {
  project: PropTypes.object.isRequired
};

export default function DraftBanner(props) {
  const { project, creator } = props;

  const launch = useDialogLauncher();
  const { history } = useRouter();

  function onCancel() {
    launch(DialogManager.ProjectClose.Config(project.id));
  }

  function onFindPartners() {
    launch(DialogManager.ProjectManage.Config(project.id, '/invite-populate'));
  }

  return (
    <Wrapper
      color="gray"
      creator={creator}
      title={(
        <>
          Your project is missing required fields!
        </>
      )}
      subtitle={(
        <>
          You must provide all required fields before you connect with partners, or
          before your project will be shown to others.
        </>
      )}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        <ConnectButton outline={true} onClick={onCancel}>
          Cancel project
        </ConnectButton>
        <ConnectButton onClick={() => history.push('/edit')} gray={true}>
          View missing fields
        </ConnectButton>
        {/*<ConnectButton disabled={true}>*/}
        {/*  This is you*/}
        {/*</ConnectButton>*/}
      </div>
    </Wrapper>
  );
};
