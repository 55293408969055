import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function SectionTitle(props) {
  const { title, subtitle, children, className } = props;

  return (
    <div className={classNames('space-y-6', className)}>
      <div>
        <div className="text-2xl font-semibold">
          {title}
        </div>
        <div className="text-base text-gray-500">
          {subtitle}
        </div>
      </div>
      <div>
        {children}
      </div>
    </div>
  );
}
