import { extractTextFromQuery } from '../../../utils/query-utils';

export default {
  param: extractTextFromQuery,
  query: function(text) {
    const words = text.trim().split(' ');

    if (text) {
      return {
        $or: [
          {
            $or: words.map(function (word) {
              return {
                'project.site.name': {
                  $ilike: `%${word}%`
                }
              };
            })
          },
          {
            $or: words.map(function (word) {
              return {
                'event.site.name': {
                  $ilike: `%${word}%`
                }
              };
            })
          }
        ]
      }
    }
  }
}
