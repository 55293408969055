import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useDialogConfig from '../_hooks/useDialogConfig';
import QuestionDiscoverDialog from '../../dialogs/question.discover';
import { useQueryClient } from '@tanstack/react-query';

export default function Launcher(props) {
  const config = useDialogConfig();
  const queryClient = useQueryClient();

  const project = useConnect('project.byId', {
    id: config.params.projectId
  });

  const creator = useConnect('user.byId', {
    id: project.data.creatorId
  }, {
    enabled: !!project.data.creatorId
  });

  if (
    project.state === PayloadStates.FETCHING ||
    creator.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  return (
    <QuestionDiscoverDialog
      creator={creator}
      project={project}
      onCancel={props.onClose}
      onSubmit={function(request) {
        queryClient.invalidateQueries({ queryKey: ['question'] });
      }}
    />
  );
};
