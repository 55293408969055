import React from 'react';
import PropTypes from 'prop-types';
import ConnectButton from '../../../pages/_banner/ConnectButton';
import Wrapper from '../../../pages/_banner/Wrapper';
import usePageRouter from '../../_hooks/usePageRouter';

CompletedBanner.propTypes = {
  claim: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired
};

export default function CompletedBanner(props) {
  const { claim, offer, creator } = props;

  const { history } = usePageRouter();

  return (
    <Wrapper
      color="green"
      creator={creator}
      title={(
        <>
          Thanks for being part of this offer!
        </>
      )}
      subtitle={(
        <>
          These opportunities make for vibrant learning experiences. Thanks for being a part of
          them! Ready to discover new opportunities to connect? Start exploring now!
        </>
      )}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        <ConnectButton color="green" onClick={() => history.push(`/opportunities`)}>
          Explore opportunities
        </ConnectButton>
      </div>
    </Wrapper>
  );
};
