import React from 'react';
import PropTypes from 'prop-types';
import FormField from '../../forms/_common/FormField';
import { OutlineButton, SolidButton } from '../../components/Button';
import FormTemplate from '../../dialogs_common/FormTemplate';
import RadioButtonField from '../../pages/_post-opportunity/_fields/RadioButtonField.cards';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  activityTypes: PropTypes.object,
  ages: PropTypes.object
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onCancel
  } = props;

  return (
    <FormTemplate
      title="Tell us more"
      subtitle="It helps partners make better offers to know why this won't work for you."
      onClose={onCancel}
      alert={alert}
      body={(
        <>
          {/*<p className="m-modal__description">*/}
          {/*  Tell us your idea. You can always change things later.*/}
          {/*</p>*/}
          <FormField name="reason" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <RadioButtonField
                name="reason"
                // label="How did you connect?*"
                value={field.value}
                error={field.error}
                // placeholder="Select a role..."
                options={{
                  data: [
                    `I'm too busy right now, but ask me later.`,
                    `I can't make the day or time work in my schedule.`,
                    `I can't invest the time this will take.`,
                    `I don't think my learners are ready for this.`,
                    `This is located too far away for me and my learners.`,
                    `This just isn't a good fit for my curriculum or needs.`
                    // `The partner stopped communicating in a timely fashion.`,
                  ].map((option) => {
                    return {
                      id: option,
                      data: {
                        name: option
                      }
                    }
                  })
                }}
                optionLabel="name"
                onChange={function(value) {
                  field.onChange(value);
                }}
              />
            )}
          </FormField>
        </>
      )}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onCancel}>
            Cancel
          </OutlineButton>
          <SolidButton onClick={onSubmit}>
            Decline
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
