import React from 'react';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import { useConnect } from '../../../hooks/@lore/query-connect';
import Card from '../../components/Card';
import ComposableEventHeader from '../../components_cards/ComposableEventHeader';
import TagRow from '../../components_cards/tags/TagRow';
import EventTag from '../../components_cards/tags/EventTag';
import OutlineButtonGray from '../../components/Button/OutlineButton.gray';
import ParticipantTag from '../../components_cards/tags/ParticipantTag';
import { DialogManager } from '../../dialogs_new/DialogManager';
import useDialogLauncher from '../../dialogs-routable/_hooks/useDialogLauncher';
import CompletedTag from '../../components_cards/tags/_resource/CompletedTag';
import CancelledTag from '../../components_cards/tags/_resource/CancelledTag';
import ExpiredTag from '../../components_cards/tags/_resource/ExpiredTag';
import Creator from '../my-invitations/Invitation/Creator';
import { SolidButton } from '../../components/Button';

export default function Invitation(props) {
  const { invitation } = props;

  const launch = useDialogLauncher();

  const event = useConnect('event.byId', {
    id: invitation.data.eventId
  });

  const creator = useConnect('user.byId', {
    id: event.data.creatorId
  }, {
    enabled: !!event.data.creatorId
  });

  function onViewEvent() {
    launch(DialogManager.InvitationView.Config(invitation.id));
  }

  function onMessageCreator() {
    launch(DialogManager.Messages.Config(creator.id));
  }

  if (event.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <Card divider={true}>
      <ComposableEventHeader
        event={event}
        showTags={false}
        tags={(
          <TagRow>
            <EventTag />
            <ParticipantTag />
            <CompletedTag resource={event} />
            <CancelledTag resource={event} />
            <ExpiredTag resource={event} />
          </TagRow>
        )}
        showPartners={false}
        showDistance={true}
      />
      <div className="flex justify-between p-4 bg-gray-50">
        <div className="flex space-x-2">
          <Creator event={event} />
        </div>
        <div className="flex space-x-2">
          <OutlineButtonGray
            label="View event"
            onClick={onViewEvent}
          />
          <SolidButton
            label={`Message ${creator.data.firstName || '...'}`}
            onClick={onMessageCreator}
          />
        </div>
      </div>
    </Card>
  );
}
