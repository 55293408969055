import React, { useEffect } from 'react';
import { useUser } from '@lore/auth';
import ParentLayout from './Layout';
import useRouter from '../../hooks/useRouter';
import Loader from '../../components/Loader';
import { useQueryMutation } from '../../../hooks/@lore/query-connect';

export default function Layout(props) {
  const { history, match } = useRouter();

  const user = useUser();

  const mutation = useQueryMutation('project.create');
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      history.push(`/post-project/${request.id}`)
    }
  }, [success]);

  useEffect(() => {
    mutation.mutate({
      creatorId: user.id
    });
  }, []);

  return (
    <ParentLayout
      page="Create project"
      title={(
        <Loader/>
      )}
    >
      <div className="px-16 lg:px-32 pt-32">
        <Loader/>
      </div>
    </ParentLayout>
  );
};
