import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import classNames from 'classnames';

export default function StatusTag(props) {
  const { evaluation } = props;

  // const diff = moment(evaluation.data.updatedAt).unix() - moment(evaluation.data.createdAt).unix();
  const diff = DateTime.fromISO(evaluation.data.updatedAt).diff(DateTime.fromISO(evaluation.data.createdAt)).milliseconds;

  const defaultClassNames = 'px-2 inline-flex text-xs leading-5 font-semibold rounded-full';

  if (evaluation.data.completed) {
    return (
      <span className={classNames(
        defaultClassNames,
        'bg-green-100 text-green-800',
      )}>
        COMPLETE
      </span>
    );
  }

  if (diff > 100) {
    return (
      <span className={classNames(
        defaultClassNames,
        // 'bg-yellow-100 text-yellow-800',
        'bg-cs-blue-100 text-cs-blue-800',
        'space-x-1'
      )}>
        <span className="uppercase">Started</span>
        <span> · </span>
        <span className="uppercase">Incomplete</span>
      </span>
    );
  }

  return (
    <span className={classNames(
      defaultClassNames,
      'bg-cs-gray-100 text-cs-gray-800'
    )}>
      NOT STARTED
    </span>
  );
}
