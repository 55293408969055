import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import MessageList from '../../pages/messages-user/MessageList';
import CloseDialogButton from '../../dialogs_common/CloseDialogButton';
import useMessages from '../../pages/messages-user/useMessages';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import classNames from 'classnames';

ConversationDialog.propTypes = {
  user: PropTypes.object.isRequired
};

export default function ConversationDialog(props) {
  const { user, project, event, offer, steward } = props;

  const [timestamp] = useState(new Date().toISOString());

  const messages = useMessages(user, timestamp);

  if (messages.state === PayloadStates.FETCHING) {
    return (
      <DialogTemplate className="max-w-screen-sm w-96">
        <CloseDialogButton onCancel={props.onCancel} />
        <div className="py-12">
          <Loader />
        </div>
      </DialogTemplate>
    );
  }

  return (
    <DialogTemplate className={classNames(
      // 'max-w-screen-md',
      'w-screen max-w-screen-md',
      steward && 'border-2 border-cs-orange-500'
    )}>
      <div className={classNames(
        steward && 'relative top-6'
      )}>
        <CloseDialogButton onCancel={props.onCancel} />
      </div>
      {steward && (
        <div className="bg-cs-orange-100 px-4 py-1 text-sm text-cs-gray-500 border-b border-cs-orange-500">
          Connection made by {steward.data.firstName} {steward.data.lastName} (Steward)
        </div>
      )}
      <div className={classNames(
        'h-screenx h-[calc(100dvh-32px)] max-h-[calc(720px)]',
        steward && 'borderx border-cs-orange-500x'
      )}>
        <MessageList
          // key={user.id}
          timestamp={timestamp}
          user={user}
          project={project}
          event={event}
          offer={offer}
        />
      </div>
    </DialogTemplate>
  );
};
