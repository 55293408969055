import React from 'react';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import ParentLayout from '../Layout';
import Urls from '../Urls';
import Activities from './Activities';
import { PayloadStates } from '@lore/utils';
import useTags from '../../../hooks/useTags';
import TagTypes from '../../../constants/TagTypes';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { location, history, match } = useRouter();
  const user = useUser();
  const offer = useConnect('offer.byId', {
    id: Number(match.params.offerId)
  });

  const activities = useTags(TagTypes.Activity);

  if (
    offer.state === PayloadStates.FETCHING ||
    activities.state === PayloadStates.FETCHING
  ) {
    return null;
  }

  return (
    <ParentLayout
      offer={offer}
      page="Activity"
      title="Which of these sounds most like what you or your organization is willing to offer?"
      // description="Does your lesson, program, or event sound like any of these types of activities?"
      description={(
        <div className="font-semibold">
          Choose ONE Activity Type.
        </div>
      )}
    >
      <Activities
        user={user}
        offer={offer}
        activities={activities}
        // navigation={(
        //   <Navigation v5={true} activeStep={2} steps={10}/>
        // )}
        // onPrevious={(newData) => history.push(Urls(offer).ORGANIZATION)}
        onNext={(newData) => history.push(Urls(offer).DESCRIPTION)}
        onSkip={() => history.push(Urls(offer).DESCRIPTION)}
      />
    </ParentLayout>
  );
};
