import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import validators from '../../../utils/validators';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import FormField from '../../../forms/_common/FormField';
import FormTemplate from '../../_post-opportunity/FlowTemplate';
import TextAreaField from '../../../forms/_fields_v2/TextAreaField';
import ABOUT_MAX_LENGTH from '../../setup/hope/HOPE_MAX_LENGTH';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';

Form.propTypes = {
  user: PropTypes.object.isRequired
};

export default function Form(props) {
  const {
    user,
    navigation,
    onNext,
    onPrevious,
    onSkip,
    onExit,
    buttonText = 'Next'
  } = props;

  const launch = useDialogLauncher();

  const [data, setData] = useState(_.pick(user.data, [
    'biography'
  ]));
  const [showErrors, setShowErrors] = useState(false);
  const [exit, setExit] = useState(false);

  const mutation = useQueryMutation('currentUser.updateSingleton', user);
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      exit ? onExit(data) : onNext(data);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate(data);
  }

  function onSubmitAndExit() {
    onSubmit();
    setExit(true);
  }

  function onChange(name, value) {
    setData(_.merge({}, data, {
      [name]: value
    }));
  }

  function onShowSuggestions() {
    launch(DialogManager.BiographySuggestions.Config());
  }

  return (
    <Overlay isVisible={saving}>
      <FormErrors data={data} validators={{
        biography: [
          validators.maxLength(ABOUT_MAX_LENGTH)
        ]
      }}>
        {({ errors, hasError }) => {
          return (
            <FormTemplate
              onExit={() => hasError ? setShowErrors(true) : onSubmitAndExit()}
              alert={error ? (
                <RequestError request={request} />
              ) : showErrors ? (
                <ErrorMessage>
                  Please fill in all required fields.
                </ErrorMessage>
              ) : null}
              body={(
                <div className="row">
                  <div className="col-md-12">
                    <FormField name="biography" data={data} errors={showErrors && errors} onChange={onChange}>
                      {(field) => (
                        <TextAreaField
                          label={(
                            <span>
                            In 1-2 sentences how would you describe yourself? (<span className="text-cs-orange-500 underline cursor-pointer" onClick={onShowSuggestions}>Need an example?</span>)
                          </span>
                          )}
                          placeholder="Describe yourself..."
                          rows={5}
                          description={`${data.biography ? data.biography.length : 0}/${ABOUT_MAX_LENGTH} characters`}
                          value={field.value}
                          error={field.error}
                          onChange={field.onChange}
                        />
                      )}
                    </FormField>
                  </div>
                </div>
              )}
              // footer={(
              //   <>
              //     <PrimaryButton onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
              //       {buttonText}
              //     </PrimaryButton>
              //   </>
              // )}
            />
          );
        }}
      </FormErrors>
    </Overlay>
  );
};
