import React from 'react';
import ChangeEmailDialog from '../../dialogs/emailChangeRequest.create';

export default function Launcher(props) {
  return (
    <ChangeEmailDialog
      onCancel={props.onClose}
    />
  );
};
