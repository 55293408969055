import React from 'react';
import { useUser } from '@lore/auth';
import { Helmet } from 'react-helmet-async';
import ParentLayout from '../Layout';
import Navigation from '../../../dialogs_common/Navigation';
import Urls from '../Urls';
import Form from './Form';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { history } = useRouter();
  const user = useUser();

  return (
    <ParentLayout>
      <Helmet>
        <title>Setup - About</title>
      </Helmet>
      <Form
        user={user}
        navigation={user.data.isEducator ?
          <Navigation activeStep={4} steps={9}/> :
          <Navigation activeStep={4} steps={9}/>
        }
        onPrevious={(newData) => history.push(Urls.ZIP_CODE)}
        onNext={(newData) => history.push(Urls.SKILLS)}
        onSkip={() => history.push(Urls.SKILLS)}
      />
    </ParentLayout>
  );
};
