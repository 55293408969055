import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import ConnectButton from '../../../pages/_banner/ConnectButton';
import Wrapper from '../../../pages/_banner/Wrapper';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../_hooks/useDialogLauncher';
import usePageRouter from '../../_hooks/usePageRouter';

DiscoverBanner.propTypes = {
  project: PropTypes.object.isRequired,
  creator: PropTypes.object.isRequired
};

export default function DiscoverBanner(props) {
  const { project, creator } = props;

  const [timestamp] = useState(new Date().toISOString());

  const launch = useDialogLauncher();
  const { history } = usePageRouter();

  function showConnectDialog() {
    launch(DialogManager.QuestionDiscover.Config(project.id));
  }

  if (
    project.data.endDate &&
    DateTime.fromISO(timestamp).diff(DateTime.fromISO(project.data.endDate)).milliseconds > 0
  ) {
    return (
      <Wrapper
        color="gray"
        creator={creator}
        title="The timeframe for this project has ended."
        subtitle={(
          <>
            But you can explore other opportunities to collaborate and make an impact.
          </>
        )}
      >
        <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
          <ConnectButton color="gray" onClick={() => history.push(`/opportunities`)}>
            Explore opportunities
          </ConnectButton>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper
      creator={creator}
      title={`Want to get involved in ${creator.data.firstName}'s project?`}
      subtitle={(
        <>
          Connect with {creator.data.firstName} to share your interest! Ask questions, offer ideas,
          or suggest someone else who might be a good fit!
        </>
      )}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        <ConnectButton onClick={showConnectDialog}>
          Start the conversation
        </ConnectButton>
      </div>
    </Wrapper>
  );
};
