import React from 'react';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import { useUser } from '@lore/auth';
import { CalendarIcon } from '@heroicons/react/24/outline';
import ContentSection from '../../../components_profiles/ContentSection';
import ContentHeading from '../../../components_profiles/ContentHeading';
import ContentCard from '../../../components_profiles/ContentCard';
import useOfferTags from '../../../hooks/useOfferTags';
import TagTypes from '../../../constants/TagTypes';
import { formatDate, formatDateRange, formatTimeRange } from '../../../utils/dateTimeUtils';
import SubSectionHeader from '../../../components_profiles/SubSectionHeader';
import TextContentSection from '../../../components_profiles/TextContentSection';

export default function Commitment(props) {
  const { offer } = props;

  const currentUser = useUser();
  const offerTimes = useOfferTags(offer, TagTypes.Time);

  if (offerTimes.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <ContentCard className="space-y-8">
      <SubSectionHeader
        icon={CalendarIcon}
        title="Date & Time"
      />
      {offer.data.specificDate === 'Yes' ? (
        <TextContentSection title="Date">
          {formatDate(offer.data.date, currentUser.data.timezone)}
        </TextContentSection>
      ) : (
        <>
          <TextContentSection title="Date Window">
            {formatDateRange(offer.data.startDate, offer.data.endDate, currentUser.data.timezone)}
          </TextContentSection>
        </>
      )}
      {offer.data.specificTime === 'Yes' ? (
        <TextContentSection title="Time">
          {formatTimeRange(offer.data.startTime, offer.data.endTime, currentUser.data.timezone)}
        </TextContentSection>
      ) : (
        <TextContentSection title="Possible Times of Day">
          <ul className="list-disc ml-6">
            {offerTimes.data.map(function(offerTime) {
              return (
                <li key={offerTime.id || offerTime.cid}>
                  {offerTime.data.name}
                </li>
              );
            })}
          </ul>
        </TextContentSection>
      )}
    </ContentCard>
  );
}
