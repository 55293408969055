import React from 'react';
import SelectorProjectEventDialog from '../../dialogs/selector.project-event';

export default function Launcher(props) {
  return (
    <SelectorProjectEventDialog
      onCancel={props.onClose}
    />
  );
};
