import React from 'react';
import PropTypes from 'prop-types';
import ConnectButton from '../../../pages/_banner/ConnectButton';
import Wrapper from '../../../pages/_banner/Wrapper';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../_hooks/useDialogLauncher';

DeclineBanner.propTypes = {
  offer: PropTypes.object.isRequired,
  claim: PropTypes.object.isRequired
};

export default function DeclineBanner(props) {
  const { offer, claim, creator } = props;

  const launch = useDialogLauncher();
  const color = 'gray';

  function showConnectDialog() {
    launch(DialogManager.ClaimConnect.Config(claim.id));
  }

  return (
    <Wrapper
      color={color}
      creator={creator}
      title={(
        <>
          Changed your mind? Let {creator.data.firstName} know!
        </>
      )}
      subtitle={(
        <>
          You previously declined this offer, but you can still change your
          mind and tell {creator.data.firstName} you're interested.
        </>
      )}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        <ConnectButton color={color} onClick={showConnectDialog}>
          I'm interested
        </ConnectButton>
      </div>
    </Wrapper>
  );
};
