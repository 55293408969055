import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Overlay from '../../forms/_common/Overlay';
import RequestError from '../../forms/_common/RequestError';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import FormTemplate from '../../dialogs_common/FormTemplate';
import Loader from '../../components/Loader';
import { useQueryMutation } from '../../../hooks/@lore/query-connect';

Step2.propTypes = {
  data: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired
};

export default function Step2(props) {
  const {
    data: _data,
    onNext,
    onCancel
  } = props;

  const {
    offer,
    tagNodes
  } = _data;

  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);

  const mutation = useQueryMutation('tagNode.create');

  useEffect(function() {
    if (tagNodes.data.length === 0) {
      return onNext();
    }

    async function copy() {
      const mutations = tagNodes.data.map(function(tagNode) {
        return mutation.mutateAsync({
          offerId: offer.id,
          tagId: tagNode.data.tagId
        });
      });

      setSaving(true);

      try {
        await Promise.all(mutations);
      } catch (error) {
        setError(error);
      }

      setSaving(false);
      onNext();
    }

    copy();
  }, []);

  return (
    <DialogTemplate>
      <Overlay isVisible={saving}>
        <FormTemplate
          title="Duplicating Offer"
          subtitle={(
            <span>
              Copying associations...
            </span>
          )}
          onClose={onCancel}
          alert={error ? (
            <RequestError request={error} />
          ) : null}
          body={saving && (
            <div className="flex pb-12">
              <Loader />
            </div>
          )}
        />
      </Overlay>
    </DialogTemplate>
  );
};
