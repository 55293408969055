import React from 'react';
import { BanknotesIcon } from '@heroicons/react/24/outline';

export default function Cost(props) {
  const { offer } = props;

  return (
    <div className="flex items-center space-x-1">
      <BanknotesIcon className="w-5 h-5 text-cs-gray-500" />
      <div className="text-sm text-cs-gray-500">
        {offer.data.hasCost === 'Yes' ? 'Cost' : 'Free'}
      </div>
    </div>
  );
}
