import React from 'react';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import EmptyState from '../../components/EmptyState';
import SectionTitle from '../home/SectionTitle';
import useDraftProjects from './useDraftProjects';
import useDraftEvents from './useDraftEvents';
import useDraftOffers from './useDraftOffers';
import DraftProject from './DraftProject';
import DraftEvent from './DraftEvent';
import DraftOffer from './DraftOffer';

export default function DraftLeading(props) {
  const title = 'Draft';
  const subtitle = (
    <>
      Activities shown here are missing required fields. You must finish
      describing them before they can shown to potential collaborators.
    </>
  );

  const projects = useDraftProjects();
  const events = useDraftEvents();
  const offers = useDraftOffers();

  if (
    projects.state === PayloadStates.FETCHING ||
    events.state === PayloadStates.FETCHING ||
    offers.state === PayloadStates.FETCHING
  ) {
    return (
      <div>
        <SectionTitle
          title={title}
          subtitle={subtitle}
        />
        <Loader />
      </div>
    );
  }

  if (
    projects.data.length === 0 &&
    events.data.length === 0 &&
    offers.data.length === 0
  ) {
    // return (
    //   <div>
    //     <SectionTitle
    //       title={title}
    //       subtitle={subtitle}
    //     />
    //     <EmptyState
    //       icon={PencilIcon}
    //       title="You have no draft activities."
    //       subtitle={(
    //         <>
    //           Drafts will appear here when you start creating an activity but don't
    //           finish filling out the required fields.
    //         </>
    //       )}
    //     />
    //   </div>
    // );
    return null;
  }

  return (
    <div>
      <SectionTitle
        title={title}
        subtitle={subtitle}
      />
      <div className="space-y-4">
        {projects.data.map(function(project) {
          return (
            <DraftProject
              key={project.id}
              project={project}
            />
          );
        })}
        {events.data.map(function(event) {
          return (
            <DraftEvent
              key={event.id}
              event={event}
            />
          );
        })}
        {offers.data.map(function(offer) {
          return (
            <DraftOffer
              key={offer.id}
              offer={offer}
            />
          );
        })}
      </div>
    </div>
  );
}
