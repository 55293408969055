import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

SuccessMessage.propTypes = {
  children: PropTypes.node.isRequired,
  timeout: PropTypes.number,
  className: PropTypes.string
};

SuccessMessage.defaultProps = {
  timeout: 3000,
  className: ''
};

export default function SuccessMessage(props) {
  const { timeout, children, className } = props;

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    let mounted = true;

    if (timeout < 0) {
      return;
    }

    setTimeout(() => {
      if (mounted) {
        setIsVisible(false);
      }
    }, timeout);

    return () => {
      mounted = false;
    }
  }, []);

  return (
    <div className={`bg-green-200 text-green-900 py-6 px-20 my-5 ${className} ${isVisible ? 'block' : 'hidden'}`}>
      {children}
    </div>
  );
}
