import React from 'react';
import classNames from 'classnames';

export default function Loader(props) {
  const { className } = props;

  return (
    <div className={classNames(
      'a-loader',
      className
    )} />
  );
};
