import React from 'react';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useDialogLauncher from '../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../dialogs_new/DialogManager';
import ClaimsConnected from './ClaimsConnected';
import ClaimsInvited from './ClaimsInvited';
import ClaimsDeclined from './ClaimsDeclined';
import { useConnect } from '../../../hooks/@lore/query-connect';
import EmptyState from '../../components/EmptyState';
import { EnvelopeIcon } from '@heroicons/react/24/outline';

export default function OfferManageDialogLayout(props) {
  const { offer } = props;

  const launch = useDialogLauncher();

  const claims = useConnect('claim.find', {
    where: {
      eager: {
        $where: {
          offerId: offer.id
        }
      }
    }
  });

  function onClick(user) {
    launch(DialogManager.Messages.Config(user.id));
  }

  if (
    offer.state === PayloadStates.FETCHING ||
    claims.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  if (claims.data.length === 0) {
    return (
      <EmptyState
        icon={EnvelopeIcon}
        title="No educators were invited."
        subtitle="If they had been, this page woud provide a summary of who responded."
      />
    );
  }

  return (
    <>
      <ClaimsConnected
        offer={offer}
        onViewMessages={onClick}
      />
      <ClaimsInvited
        offer={offer}
        onViewMessages={onClick}
      />
      <ClaimsDeclined
        offer={offer}
        onViewMessages={onClick}
      />
    </>
  );
};
