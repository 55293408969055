import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../../components/Loader';
import classNames from 'classnames';

Overlay.propTypes = {
  children: PropTypes.node.isRequired,
  isVisible: PropTypes.bool,
  loader: PropTypes.bool
};

Overlay.defaultProps = {
  isVisible: false
};

export default function Overlay(props) {
  const {
    children,
    isVisible,
    loader
  } = props;

  return (
    <div className={classNames(
      isVisible && 'pointer-events-none',
      'relative w-full'
    )}>
      {loader && (
        <div className="flex justify-center items-center absolute w-full h-full">
          <Loader />
        </div>
      )}
      <div className={classNames(
        isVisible ? 'opacity-50' : 'opacity-100'
      )}>
        {React.cloneElement(children)}
      </div>
    </div>
  );
}
