import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useConnect } from '@lore/query-connect';
import { useUser } from '@lore/auth';
import validators from '../../utils/validators';
import Overlay from '../../forms/_common/Overlay';
import FormErrors from '../../forms/_common/FormErrors';
import RequestError from '../../forms/_common/RequestError';
import ErrorMessage from '../../forms/_common/ErrorMessage';
import SuccessMessage from '../../forms/_common/SuccessMessage';
import Form from './Form';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import useUserProfileMissingFields from '../../hooks/useUserProfileMissingFields';
import useOfferInterestEmailPreview from '../../utils/offer/useOfferInterestEmailPreview';
import { useQueryMutation } from '../../../hooks/@lore/query-connect';

InviteDialog.propTypes = {
  creator: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired
};

export default function InviteDialog(props) {
  const { creator, offer } = props;
  const missingFields = useUserProfileMissingFields();

  const currentUser = useUser();
  const activity = useConnect('tag.byId', {
    id: offer.data.activityId
  });

  const [data, setData] = useState({
    customMessage: [
      // `Hi ${creator.data.firstName}!\n\n`,
      `I'm interested in this offer. Would you be interested in discussing further?`
    ].join('')
  });
  const [showErrors, setShowErrors] = useState(false);

  const emailPreview = useOfferInterestEmailPreview(offer, creator, data.customMessage);

  const mutation = useQueryMutation('claim.create');
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      props.onCancel();
      props.onSubmit && props.onSubmit(request);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate({
      offerId: offer.id,
      userId: currentUser.id,
      request: 'Participation',
      requestMessage: `My organization and I have mapped out an idea of how we'd like to engage with you and your students. By clicking "Respond or Decline" you'll be able to see the full outline of the idea. I'd love to answer any questions you have or talk more about this possibility with you. Thank you for considering us!`,
      response: 'Connect',
      responseMessage: data.customMessage
    });
  }

  function onCompleteProfile() {
    // props.onCancel();
    props.onCompleteProfile && props.onCompleteProfile();
  }

  function onChange(name, value) {
    const nextData = _.merge({}, data);
    nextData[name] = value;
    setData(nextData);
  }

  onChange = onChange.bind(data);

  // if (
  //   missingFields &&
  //   (missingFields.profile.length + missingFields.interests.length) > 0
  // ) {
  //   return (
  //     <IdeaCreateBlockerDialog
  //       onSubmit={onCompleteProfile}
  //       onCancel={props.onCancel}
  //     />
  //   );
  // }

  return (
    <DialogTemplate className="w-screen max-w-screen-sm">
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          customMessage: [validators.isRequired]
        }}>
          {({errors, hasError}) => (
            <Form
              data={data}
              errors={errors}
              showErrors={showErrors}
              onChange={onChange}
              onSubmit={() => hasError ? setShowErrors(true) : onSubmit()}
              onCancel={props.onCancel}
              alert={error ? (
                <RequestError request={request}/>
              ) : showErrors ? (
                <ErrorMessage>
                  Please fill in all required fields.
                </ErrorMessage>
              ) : (false && success) ? (
                <SuccessMessage>
                  Idea created.
                </SuccessMessage>
              ) : null}
              creator={creator}
              activity={activity}
              emailPreview={emailPreview}
            />
          )}
        </FormErrors>
      </Overlay>
    </DialogTemplate>
  );
};
