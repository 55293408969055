import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import ConnectButton from '../../../pages/_banner/ConnectButton';
import Wrapper from '../../../pages/_banner/Wrapper';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../_hooks/useDialogLauncher';
import usePageRouter from '../../_hooks/usePageRouter';

DiscoverBanner.propTypes = {
  event: PropTypes.object.isRequired,
  creator: PropTypes.object.isRequired
};

export default function DiscoverBanner(props) {
  const { event, creator } = props;

  const [timestamp] = useState(new Date().toISOString());

  const launch = useDialogLauncher();
  const { history } = usePageRouter();

  function showYesDialog() {
    launch(DialogManager.InvitationDiscoverYes.Config(event.id));
  }

  function showMaybeDialog() {
    launch(DialogManager.InvitationDiscoverMaybe.Config(event.id));
  }

  if (
    event.data.date &&
    DateTime.fromISO(timestamp).diff(DateTime.fromISO(event.data.date)).milliseconds > 0
  ) {
    return (
      <Wrapper
        color="gray"
        creator={creator}
        title="The timeframe for this event has ended."
        subtitle={(
          <>
            But you can explore other opportunities to collaborate and make an impact.
          </>
        )}
      >
        <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
          <ConnectButton color="gray" onClick={() => history.push(`/opportunities`)}>
            Explore opportunities
          </ConnectButton>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper
      creator={creator}
      title={`Want to get involved with ${creator.data.firstName}'s event?`}
      subtitle={(
        <>
          Let {creator.data.firstName} know you're interested in collaborating! You can ask
          questions and adjust your commitment later if necessary.
        </>
      )}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        <ConnectButton outline={true} onClick={showMaybeDialog}>
          I have some questions first
        </ConnectButton>
        <ConnectButton onClick={showYesDialog}>
          Yes, count me in
        </ConnectButton>
      </div>
    </Wrapper>
  );
};
