import React from 'react';
import PropTypes from 'prop-types';

OutlineButton.propTypes = {
  children: PropTypes.node
};

export default function OutlineButton(props) {
  const {
    children,
    icon,
    onClick,
    className
  } = props;

  if (!children) {
    return (
      <button className={`bg-white hover:bg-cs-orange-500 hover:text-white p-4 px-4 rounded-full text-cs-orange-500 border-2 border-cs-orange-500 cursor-pointer uppercase font-semibold ${className}`} onClick={onClick}>
        <div className="flex justify-center space-x-2">
          {icon && (
            <props.icon className="w-6 h-6" />
          )}
        </div>
      </button>
    );
  }

  return (
    <button className={`bg-white hover:bg-cs-orange-500 hover:text-white p-4 px-6 rounded-full text-cs-orange-500 border-2 border-cs-orange-500 cursor-pointer uppercase font-semibold ${className}`} onClick={onClick}>
      <div className="flex justify-center space-x-2">
        {icon && (
          <props.icon className="w-6 h-6" />
        )}
        {children}
      </div>
    </button>
  );
};
