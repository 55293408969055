import { extractArrayFromQuery, mapAttributes } from '../../../utils/query-utils';

import networks from './networks';
import activities from './activities';
import costs from './costs';
import name from './name';
import organization from './organization';
import searchOffer from './searchOffer';
import title from './title';
import locationCondition from '../../projects/queryUtils/locationCondition';

function tag() {
  return {
    param: extractArrayFromQuery,
    query: function(values) {
      if (values.length > 0) {
        return {
          'tagNodes.tagId': {
            $in: values
          }
        }
      }
    }
  };
}

function tagTree() {
  return {
    param: extractArrayFromQuery,
    query: function(values) {
      if (values.length > 0) {
        return {
          'tagTrees.parentId': {
            $in: values
          }
        }
      }
    }
  };
}

const attributes = {
  networks,
  activities,
  costs,
  groups: tag(),
  guidances: tag(),
  interactionTypes: tag(),
  locationCondition,
  name,
  organization,
  participants: tag(),
  searchOffer,
  subjects: tag(),
  supplies: tag(),
  times: tag(),
  title
};

export const attributeKeys = _.keys(attributes);

export default mapAttributes(attributes);
