import React from 'react';

export default function IconSection(props) {
  const { title, subtitle, xs = false, icon } = props;

  return (
    <div className="flex items-start space-x-2">
      <props.icon className="text-cs-gray-900 h-6 w-6"/>
      <div className="flex-1 space-y-0.5">
        <div className="text-cs-gray-900 text-base">
          {title}
        </div>
        <div className="text-cs-gray-500 text-sm">
          {subtitle}
        </div>
      </div>
    </div>
  );
}
