import React from 'react';
import PropTypes from 'prop-types';
import FormField from '../../forms/_common/FormField';
import { OutlineButton, SolidButton } from '../../components/Button';
import FormTemplate from '../../dialogs_common/FormTemplate';
import FormSection from '../../pages/_post-opportunity/forms/FormSection';
import RadioButtonField from '../../pages/_post-opportunity/_fields/RadioButtonField.cards';
import { CalendarIcon, FolderIcon } from '@heroicons/react/24/outline';
import Type from './Type';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  activityTypes: PropTypes.object,
  ages: PropTypes.object
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onCancel
  } = props;

  return (
    <FormTemplate
      title={(
        <div className="text-2xl font-normal">
          We're here to help you find the right partner(s) to make your idea a reality. Which
          sounds <span className="font-semibold underline">more</span> like who you're hoping to find with
          CommunityShare?
        </div>
      )}
      // subtitle="Are the date and time fixed or are they flexible?"
      onClose={onCancel}
      alert={alert}
      body={(
        <div className="space-y-8">
          <FormSection
            // title="Date is..."
            // subtitle="Select one or many."
          >
            <FormField name="type" data={data} errors={showErrors && errors} onChange={onChange}>
              {(field) => (
                <>
                  <RadioButtonField
                    name="type"
                    // label="Does your partner need to come from specific career field(s) or have special expertise?"
                    value={field.value}
                    error={field.error}
                    placeholder="Select a statement..."
                    options={{
                      data: [
                        [
                          'fixed',
                          // `I'm looking for one or many partners who can help me with an activity that can only occur at a specific date and time.`
                          <span className="font-normal">
                            I'm looking for one or many partners who can help me with an activity that can
                            only occur at a specific date and time.
                          </span>
                        ],
                        [
                          `flexible`,
                          // `I'm looking for the right partner(s) to help and can adjust my ideal date and time to fit their availability if they have the career or life experience I need.`
                          <span className="font-normal">
                            I'm looking for the right partner(s) to help and can adjust my ideal date and
                            time to fit their availability if they have the career or life experience I need.
                          </span>
                        ]
                      ].map((option) => {
                        return {
                          id: option[0],
                          data: {
                            name: option[1]
                          }
                        }
                      })
                    }}
                    optionLabel="name"
                    onChange={(value) => field.onChange(value)}
                  />

                </>
              )}
            </FormField>
          </FormSection>
          {data.type === 'fixed' && (
            <FormSection
              title="Let's plan an event!"
              // subtitle="Select one or many."
            >
              <div className="space-y-4">
                <Type
                  icon={CalendarIcon}
                  title="Events"
                  description={(
                    <ul className="list-disc ml-6 mt-1 space-y-0.5 text-sm text-cs-gray-500">
                      <li>
                        Have a fixed date and time.
                      </li>
                      <li>
                        Invite your potential partners to respond yes/no to ensure you have all
                        the partners you need for your event.
                      </li>
                    </ul>
                  )}
                />
                <div>
                  <a href="https://intercom.help/communityshare/en/articles/6673448-events" target="_blank" className="hover:underline">
                    Learn more about events.
                  </a>
                </div>
              </div>
            </FormSection>
          )}
          {data.type === 'flexible' && (
            <FormSection
              title="Let's plan a project!"
              // subtitle="Select one or many."
            >
              <div className="space-y-4">
                <Type
                  icon={FolderIcon}
                  title="Projects"
                  description={(
                    <ul className="list-disc ml-6 mt-1 space-y-0.5 text-sm text-cs-gray-500">
                      <li>
                        Have a flexible date and time or may occur at different dates/times with different
                        partners.
                      </li>
                      <li>
                        Are often co-created with partner(s).
                      </li>
                      <li>
                        Invite your potential partners to begin chatting with you in a more open-ended,
                        conversational way, allowing you to ask a question of an expert, engage with or
                        without your students, or invite a partner to work directly with your students.
                      </li>
                    </ul>
                  )}
                />
                <div>
                  <a href="https://intercom.help/communityshare/en/articles/6673449-projects" target="_blank" className="hover:underline">
                    Learn more about projects.
                  </a>
                </div>
              </div>
            </FormSection>
          )}
        </div>
      )}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onCancel}>
            Cancel
          </OutlineButton>
          <SolidButton onClick={onSubmit}>
            {
              data.type === 'fixed' ? 'Create Event' :
              data.type === 'flexible' ? 'Create Project' :
              'Next'
            }
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
