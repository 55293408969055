import React from 'react';
import { PayloadStates } from '@lore/utils';
import MissingField from '../project-manage/MissingField';
import HiddenField from '../project-manage/HiddenField';
import useMissingFields from './useMissingFields';
import isSleeping from '../../utils/isSleeping';

export default function MissingFields(props) {
  const { user } = props;

  const { missingFields } = useMissingFields(user);

  if (missingFields.state === PayloadStates.FETCHING) {
    return null;
  }

  if (
    missingFields.data.length > 0
  ) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {missingFields.data.map(function(missingField) {
          return (
            <MissingField
              key={missingField.key}
              name={missingField.name}
              description={(
                <>
                  You must provide this field before you can connect with others,
                  or before your profile will be shown to others.
                </>
              )}
              to={missingField.to}
            />
          );
        })}
      </div>
    );
  }

  const isAsleep = isSleeping(user);

  if (isAsleep) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <HiddenField
          name="Your profile is asleep!"
          description={(
            <>
              Community members are currently unable to message you because your profile is
              asleep. Wake it up to allow members to connect with you.
            </>
          )}
          to={`/profile/settings`}
        />
      </div>
    );
  }

  return null;
};
