import React from 'react';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import ParentLayout from '../Layout';
import Navigation from '../../../dialogs_common/Navigation';
import Urls from '../Urls';
import Form from './Form';
import { PayloadStates } from '@lore/utils';
import _ from 'lodash';
import useProjectTags from '../../../hooks/useProjectTags';
import TagTypes from '../../../constants/TagTypes';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { location, history, match } = useRouter();
  const user = useUser();
  const project = useConnect('project.byId', {
    id: Number(match.params.projectId)
  });

  const projectParticipants = useProjectTags(project, TagTypes.Participant);

  if (
    project.state === PayloadStates.FETCHING ||
    projectParticipants.state === PayloadStates.FETCHING
  ) {
    return null;
  }

  return (
    <ParentLayout
      project={project}
      page="Subjects"
      title="What subject areas does this project incorporate?"
      description={(
        <>
          <span className="font-semibold italic">TIP</span>: Choose as many subjects as apply, not just
          what's technically in your curriculum.
        </>
      )}
    >
      <Form
        user={user}
        project={project}
        navigation={(
          <Navigation v5={true} activeStep={9} steps={19}/>
        )}
        onPrevious={(newData) => {
          _.find(projectParticipants.data, m => m.data.name === 'Students') ?
            history.push(Urls(project).STUDENT_SIZE) :
            history.push(Urls(project).PARTICIPANTS);
        }}
        onNext={(newData) => history.push(Urls(project).PARTNER)}
        onSkip={() => history.push(Urls(project).PARTNER)}
        onExit={() => history.push(Urls(project).PROJECT_EDIT)}
      />
    </ParentLayout>
  );
};
