import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { DateTime } from 'luxon';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import SolidButton from '../../../components/Button/SolidButton';
import FormTemplate2 from '../../_post-opportunity/FlowTemplate';
import DateField from '../../_post-opportunity/_fields/DateField.v2';
import FormSection from '../../_post-opportunity/forms/FormSection';
import useTags from '../../../hooks/useTags';
import TagTypes from '../../../constants/TagTypes';
import useEventTags from '../../../hooks/useEventTags';
import NodeEntries from '../NodeEntries';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';

Form.propTypes = {
  user: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func,
  onSkip: PropTypes.func
};

export default function Form(props) {
  const {
    user,
    event,
    navigation,
    onNext,
    onPrevious,
    onSkip,
    onExit,
    FormTemplate = FormTemplate2,
    buttonText = 'Next'
  } = props;

  const [data, setData] = useState({
    // specificDate: event.data.specificDate || 'Yes',
    specificDate: 'Yes',
    date: event.data.date || new Date().toISOString(),
    startDate: event.data.startDate || new Date().toISOString(),
    endDate: event.data.endDate || new Date().toISOString(),
    commitmentNotes: event.data.commitmentNotes || ''
  });
  const [showErrors, setShowErrors] = useState(false);
  const [exit, setExit] = useState(false);

  const mutation = useQueryMutation('event.update', event);
  const { saving, success, error, request } = mutation;

  const days = useTags(TagTypes.Day);
  const eventDays = useEventTags(event, TagTypes.Day);

  const commitments = useTags(TagTypes.Commitment);
  const eventCommitments = useEventTags(event, TagTypes.Commitment);

  useEffect(() => {
    if (success) {
      exit ? onExit(data) : onNext(data);
    }
  }, [success]);

  function onSubmit() {
    if (data.specificDate === 'Yes') {
      mutation.mutate({
        ...data,
        startDate: data.date,
        endDate: data.date
      });
    } else {
      mutation.mutate(data);
    }
  }

  function onSubmitAndExit() {
    onSubmit();
    setExit(true);
  }

  // function onChange(name, value) {
  //   setData(_.merge({}, data, {
  //     [name]: value
  //   }));
  // }

  function onChange(name, value) {
    const nextData = _.merge({}, data);
    nextData[name] = value;

    if (
      name === 'startDate' &&
      (!nextData.endDate || DateTime.fromISO(nextData.endDate).diff(DateTime.fromISO(value)).milliseconds < 0)
    ) {
      nextData.endDate = value;
    }

    if (
      name === 'endDate' &&
      (!nextData.startDate || DateTime.fromISO(nextData.startDate).diff(DateTime.fromISO(value)).milliseconds > 0)
    ) {
      nextData.startDate = value;
    }

    setData(nextData);
  }

  return (
    <Overlay isVisible={saving}>
      <FormErrors data={data} validators={{
        // firstName: [validators.isRequired],
        // lastName: [validators.isRequired]
      }}>
        {({ errors, hasError }) => {
          return (
            <FormTemplate
              onExit={() => hasError ? setShowErrors(true) : onSubmitAndExit()}
              onPrevious={onPrevious}
              navigation={navigation}
              alert={error ? (
                <RequestError request={request} />
              ) : showErrors ? (
                <ErrorMessage>
                  Please fill in all required fields.
                </ErrorMessage>
              ) : null}
              body={(
                <div className="space-y-10">
                  {/*<FormSection*/}
                  {/*  title="Does this event need to occur on a specific date or within a date range?"*/}
                  {/*  // subtitle="Does this event have a specific date?"*/}
                  {/*>*/}
                  {/*  <FormField name="specificDate" data={data} errors={showErrors && errors} onChange={onChange}>*/}
                  {/*    {(field) => (*/}
                  {/*      <>*/}
                  {/*        <RadioButtonField*/}
                  {/*          name="specificDate"*/}
                  {/*          // label="Does this event have a specific date?"*/}
                  {/*          value={field.value}*/}
                  {/*          error={field.error}*/}
                  {/*          // placeholder="Select a date..."*/}
                  {/*          options={{*/}
                  {/*            data: [*/}
                  {/*              ['Yes', 'Specific Date'],*/}
                  {/*              ['No', 'Date Range']*/}
                  {/*            ].map((option) => {*/}
                  {/*              return {*/}
                  {/*                id: option[0],*/}
                  {/*                data: {*/}
                  {/*                  name: option[1]*/}
                  {/*                }*/}
                  {/*              }*/}
                  {/*            })*/}
                  {/*          }}*/}
                  {/*          optionLabel="name"*/}
                  {/*          columns={2}*/}
                  {/*          onChange={field.onChange}*/}
                  {/*        />*/}
                  {/*      </>*/}
                  {/*    )}*/}
                  {/*  </FormField>*/}
                  {/*</FormSection>*/}
                  {data.specificDate === 'Yes' ? (
                    <FormSection
                      // title="What is the anticipated date?"
                    >
                      <DateField
                        key={`${data.date}`}
                        // label="What is the anticipated date?"
                        date={data.date}
                        error={showErrors && errors.date}
                        onChange={(value) => {
                          return onChange('date', value);
                        }}
                      />
                    </FormSection>
                  ) : (
                    <>
                      <FormSection
                        title="What is the earliest date this could take place?"
                      >
                        <DateField
                          key={`${data.startDate}`}
                          // label="What is the anticipated date?"
                          date={data.startDate}
                          error={showErrors && errors.startDate}
                          onChange={(value) => {
                            return onChange('startDate', value);
                          }}
                        />
                      </FormSection>
                      <FormSection
                        title="What is the latest date this could take place?"
                      >
                        <DateField
                          key={`${data.endDate}`}
                          // label="What is the anticipated date?"
                          date={data.endDate}
                          error={showErrors && errors.endDate}
                          onChange={(value) => {
                            return onChange('endDate', value);
                          }}
                        />
                      </FormSection>
                      <FormSection
                        title="Possible days"
                        subtitle="Select one or many."
                        tooltip="Which day(s) of the week would you welcome a community partner?"
                      >
                        <NodeEntries
                          event={event}
                          tags={days}
                          nodes={eventDays}
                        />
                      </FormSection>
                      <FormSection
                        title="Desired Frequency"
                        subtitle="Select all that apply."
                        tooltip="Which of the following time commitments would be acceptable?"
                      >
                        <NodeEntries
                          event={event}
                          tags={commitments}
                          nodes={eventCommitments}
                        />
                      </FormSection>
                    </>
                  )}
                </div>
              )}
              footer={(
                <>
                  <SolidButton onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
                    {buttonText}
                  </SolidButton>
                </>
              )}
            />
          );
        }}
      </FormErrors>
    </Overlay>
  );
};
