import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import Card from '../../../components/Card';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import DividerCard from '../../../components/DividerCard';
import ComposableClaimHeader from '../../../components_cards/ComposableClaimHeader';
import GrayOutlineButton from '../../../components/Button/OutlineButton.gray';
import { SolidButton } from '../../../components/Button';
import OfferTag from '../../../components_cards/tags/OfferTag';
import TagRow from '../../../components_cards/tags/TagRow';
import Creator from './Creator';
import Meta from '../../../components_cards/ComposableClaimHeader/Meta';

export default function Claim(props) {
  const { claim } = props;

  const launch = useDialogLauncher();

  const recipient = useConnect('user.byId', {
    id: claim.data.userId
  });

  const creator = useConnect('user.byId', {
    id: claim.data.creatorId
  });

  const offer = useConnect('offer.byId', {
    id: claim.data.offerId
  });

  const offerCreator = useConnect('user.byId', {
    id: offer.data.creatorId
  }, {
    enabled: !!offer.data.creatorId
  });

  if (
    recipient.state === PayloadStates.FETCHING ||
    offer.state === PayloadStates.FETCHING ||
    creator.state === PayloadStates.FETCHING ||
    offerCreator.state === PayloadStates.FETCHING
  ) {
    return (
      <Card>
        <Loader />
      </Card>
    );
  }

  function onViewOffer() {
    launch(DialogManager.ClaimView.Config(claim.id));
  }

  return (
    <DividerCard className="overflow-hidden -m-px border border-cs-orange-500" border={false}>
      <ComposableClaimHeader
        claim={claim}
        creator={creator}
        tags={(
          <TagRow>
            <OfferTag />
          </TagRow>
        )}
        showTags={false}
        showCreator={false}
      />
      <div className="w-full bg-cs-gray-50 py-4 px-4 flex justify-between items-center">
        <Creator offer={offer} />
        <div className="flex items-center space-x-2">
          {/*<GrayOutlineButton*/}
          {/*  onClick={onViewOffer}*/}
          {/*  label="View offer"*/}
          {/*/>*/}
          {/*<SolidButton*/}
          {/*  onClick={onViewOffer}*/}
          {/*  label="Check it out!"*/}
          {/*/>*/}
          <SolidButton
            onClick={onViewOffer}
            label="Check it out!"
          />
          {/*<div className="flex flex-col items-end space-y-2">*/}
          {/*  <SolidButton*/}
          {/*    onClick={onViewOffer}*/}
          {/*    label="Check it out!"*/}
          {/*  />*/}
          {/*  <Meta claim={claim} />*/}
          {/*</div>*/}
        </div>
      </div>
    </DividerCard>
  );
};
