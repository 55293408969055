import React from 'react';

export default function GreenTag(props) {
  const { label } = props;

  return (
    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
      {label}
    </span>
  );
}
