import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import validators from '../../../utils/validators';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import SolidButton from '../../../components/Button/SolidButton';
import FormTemplate2 from '../../_post-opportunity/FlowTemplate';
import FormSection from '../../_post-opportunity/forms/FormSection';
import TextAreaField from '../../../forms/_fields_v2/TextAreaField';
import FormField from '../../../forms/_common/FormField';
import { useQueryMutation } from '@lore/query-connect';

Form.propTypes = {
  user: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func,
  onSkip: PropTypes.func
};

const MAX_LENGTH = 512;

export default function Form(props) {
  const {
    user,
    project,
    navigation,
    onNext,
    onPrevious,
    onSkip,
    onExit,
    FormTemplate = FormTemplate2,
    buttonText = 'Next'
  } = props;

  const [data, setData] = useState(_.pick(project.data, [
    'demonstration'
  ]));
  const [showErrors, setShowErrors] = useState(false);
  const [exit, setExit] = useState(false);

  const mutation = useQueryMutation('project.update', project);
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      exit ? onExit(data) : onNext(data);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate(data);
  }

  function onSubmitAndExit() {
    onSubmit();
    setExit(true);
  }

  function onChange(name, value) {
    setData(_.merge({}, data, {
      [name]: value
    }));
  }

  return (
    <Overlay isVisible={saving}>
      <FormErrors data={data} validators={{
        demonstration: [validators.maxLength(MAX_LENGTH)]
      }}>
        {({ errors, hasError }) => {
          return (
            <FormTemplate
              onExit={() => hasError ? setShowErrors(true) : onSubmitAndExit()}
              onPrevious={onPrevious}
              navigation={navigation}
              alert={error ? (
                <RequestError request={request} />
              ) : showErrors ? (
                <ErrorMessage>
                  Please fill in all required fields.
                </ErrorMessage>
              ) : null}
              body={(
                <>
                  <FormSection
                    // title="Professional competencies"
                    // subtitle="Please select ALL that apply."
                  >
                    <FormField name="demonstration" data={data} errors={showErrors && errors} onChange={onChange}>
                      {(field) => (
                        <TextAreaField
                          // label={`Desired outcome (${data.desiredOutcome ? data.desiredOutcome.length : 0}/${MAX_LENGTH} characters)`}
                          // explanation="What learning goals do you have for your students that you hope a community partner will be able to support?"
                          description={`${data.demonstration ? data.demonstration.length : 0}/${MAX_LENGTH} characters`}
                          rows={10}
                          placeholder="Description..."
                          // description={(
                          //   <div className="text-cs-orange-500 cursor-pointer" onClick={onShowSuggestions}>
                          //     Need suggestions for what to include?
                          //   </div>
                          // )}
                          value={field.value}
                          error={field.error}
                          onChange={field.onChange}
                        />
                      )}
                    </FormField>
                  </FormSection>
                </>
              )}
              footer={(
                <>
                  <SolidButton onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
                    {buttonText}
                  </SolidButton>
                </>
              )}
            />
          );
        }}
      </FormErrors>
    </Overlay>
  );
};
