import React from 'react';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useMissingFields from '../event-manage/useMissingFields';
import EmptyState from '../../components/EmptyState/EmptyState.legacy';
import Partners from '../people-partners/Partners';

export default function Layout(props) {
  const { event, router } = props;

  const { missingFields } = useMissingFields(event);

  if (missingFields.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  if (missingFields.data.length > 0) {
    return (
      <EmptyState
        title="Your event is missing required fields."
        subtitle="You must provide this information before you can invite partners."
      />
    );
  }

  return (
    <div className="space-y-8">
      <Partners
        event={event}
        router={router}
      />
    </div>
  );
};
