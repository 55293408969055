import React from 'react';
import { ArrowRightIcon, EnvelopeIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

export default function Option(props) {
  const { title, subtitle, linkText, link, className, to, count = 0 } = props;

  const temp = {
    icon: props.icon || EnvelopeIcon
  };

  return (
    <div className={classNames(
      (link || to) && 'hover:bg-cs-gray-50 cursor-pointer',
      'relative',
      className
    )}>
      {link && (
        <a href={link} target="_blank" className="absolute w-full h-full" />
      )}
      {to && (
        <Link to={to} className="absolute w-full h-full" />
      )}
      <div className="flex flex-col sm:flex-row p-4 space-x-0 sm:space-x-4 space-y-4 sm:space-y-0">
        <div className="flex flex-row sm:flex-col">
          <div className="p-2 bg-cs-blue-500 bg-opacity-10 rounded-lg relative">
            <temp.icon className="h-8 w-8 text-cs-blue-500" />
            {count > 0 && (
              <div className="absolute -right-1.5 -top-1.5">
                <div className="w-5 h-5 bg-cs-orange-500 rounded-full">
                  <div className="text-center leading-5 text-white text-xs">
                    {count}
                  </div>
                </div>
              </div>
            )}
            {/*{count > 0 && (*/}
            {/*  <div className="absolute -right-2 -top-2">*/}
            {/*    <div className="w-6 h-6 bg-red-500 rounded-full">*/}
            {/*      <div className="text-center leading-6 text-white text-sm">*/}
            {/*        {count}*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*)}*/}
          </div>
        </div>
        <div className="flex-1 text-cs-gray-900 pr-0 sm:pr-16">
          {/*<div className="flex space-x-2">*/}
          {/*  <div className="w-6 h-6 bg-red-500 rounded-full">*/}
          {/*    <div className="text-center leading-6 text-white text-sm">*/}
          {/*      {count}*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*  <div className="text-base font-semibold">*/}
          {/*    {title}*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className="text-base font-semibold">
            {title}
          </div>
          <div className="text-sm text-cs-gray-500 max-w-3xl">
            {subtitle}
          </div>
        </div>
        {linkText && (
          <div className="flex items-start">
            <div className="flex items-center text-cs-blue-500 space-x-1">
              <div className="text-sm">
                {linkText}
              </div>
              <ArrowRightIcon className="h-5 w-5" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
