import React from 'react';
import TagRow from '../../../components_cards/tags/TagRow';
import ProjectTag from '../../../components_cards/tags/ProjectTag';
import ParticipantTag from '../../../components_cards/tags/ParticipantTag';
import CompletedTag from '../../../components_cards/tags/_resource/CompletedTag';
import CancelledTag from '../../../components_cards/tags/_resource/CancelledTag';
import ExpiredTag from '../../../components_cards/tags/_resource/ExpiredTag';

export default function Tags(props) {
  const { project } = props;

  return (
    <TagRow>
      <ProjectTag />
      <ParticipantTag />
      <CompletedTag resource={project} />
      <CancelledTag resource={project} />
      <ExpiredTag resource={project} />
    </TagRow>
  );
}
