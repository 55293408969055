import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

ProfileType.propTypes = {
  title: PropTypes.node.isRequired,
  avatar: PropTypes.node.isRequired,
  description: PropTypes.node.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

export default function ProfileType(props) {
  const {
    title,
    avatar,
    description,
    selected,
    onClick,
    className
  } = props;

  return (
    <div className={classNames('text-center w-72', className)}>
      <div
        className={classNames(
          'p-6 shadow-cs-flatx rounded-lg cursor-pointer h-full hover:bg-cs-orange-50',
          selected ? 'border-cs-orange-500 border-2 bg-cs-orange-50' : 'border border-cs-gray-300 m-px'
        )}
        onClick={onClick}
      >
        <div className="text-2xl text-cs-gray-800 font-semibold mb-6">
          {title}
        </div>
        <img src={avatar} className="w-36 rounded-full bg-cs-orange-50 inline-block mb-6"/>
        <div className="text-lg text-cs-gray-800">
          {description}
        </div>
      </div>
    </div>
  );
};
