import React from 'react';
import PaginationLink from './PaginationLink';
import { ArrowLeftIcon, ArrowRightIcon, EllipsisHorizontalIcon } from '@heroicons/react/24/outline';

export default function PaginationLinks(props) {
  const { totalCount, pageSize, onNavigate } = props;
  const currentPage = Number(props.currentPage);

  function renderPaginationLink(pageNumber, currentPage) {
    if(pageNumber === currentPage) {
      return (
        <PaginationLink key={pageNumber} page={pageNumber} isActive={true} />
      );
    }

    return (
      <PaginationLink key={pageNumber} page={pageNumber} isActive={false} onNavigate={onNavigate}/>
    );
  }

  function renderPreviousPageLink(currentPage) {
    const previousPage = currentPage - 1;

    if(currentPage === 1) {
      return (
        <PaginationLink key="previous" isDisabled={true} text={"&laquo;"} icon={(
          <ArrowLeftIcon className="text-sm text-cs-gray-500x h-5 w-5" />
        )} />
      );
    }

    return (
      <PaginationLink key="previous" page={previousPage} text={"&laquo;"} icon={(
        <ArrowLeftIcon className="text-sm text-cs-gray-500x h-5 w-5" />
      )} onNavigate={onNavigate}/>
    );
  }

  function renderNextPageLink(currentPage, totalPages) {
    const nextPage = currentPage + 1;

    if(currentPage === totalPages) {
      return (
        <PaginationLink key="next" isDisabled={true} text={"&raquo;"} icon={(
          <ArrowRightIcon className="text-sm text-cs-gray-500x h-5 w-5" />
        )} />
      );
    }

    return (
      <PaginationLink key="next" page={nextPage} text={"&raquo;"} icon={(
        <ArrowRightIcon className="text-sm text-cs-gray-500x h-5 w-5" />
      )} onNavigate={onNavigate}/>
    );
  }

  function renderDividerLink(key) {
    return (
      <PaginationLink key={key} text={'...'} isDivider={true} icon={(
        <EllipsisHorizontalIcon className="text-sm h-4 w-4" />
      )} />
    );
  }

  function renderPageLinks(currentPage, totalPages) {
    const links = [];

    if (totalPages <= 5) {
      for(let i = 1; i <= totalPages; i++) {
        links.push(renderPaginationLink(i, currentPage));
      }
      // links.push(renderPaginationLink(1, currentPage));
      // links.push(renderPaginationLink(2, currentPage));
      // links.push(renderPaginationLink(3, currentPage));
      // links.push(renderPaginationLink(4, currentPage));
      // links.push(renderPaginationLink(5, currentPage));
      return links;
    }

    if(currentPage < 3) {
      links.push(renderPaginationLink(1, currentPage));
      links.push(renderPaginationLink(2, currentPage));
      links.push(renderPaginationLink(3, currentPage));
      links.push(renderDividerLink('divider-1'));
      links.push(renderPaginationLink(totalPages, currentPage));
    } else if(currentPage === 3) {
      links.push(renderPaginationLink(1, currentPage));
      links.push(renderPaginationLink(2, currentPage));
      links.push(renderPaginationLink(3, currentPage));
      links.push(renderPaginationLink(4, currentPage));
      links.push(renderDividerLink('divider-1'));
      links.push(renderPaginationLink(totalPages, currentPage));
    } else if(currentPage === 4) {
      links.push(renderPaginationLink(1, currentPage));
      links.push(renderPaginationLink(2, currentPage));
      links.push(renderPaginationLink(3, currentPage));
      links.push(renderPaginationLink(4, currentPage));
      links.push(renderPaginationLink(5, currentPage));
      links.push(renderDividerLink('divider-1'));
      links.push(renderPaginationLink(totalPages, currentPage));
    } else if(currentPage < totalPages - 3) {
      links.push(renderPaginationLink(1, currentPage));
      links.push(renderDividerLink('divider-1'));
      links.push(renderPaginationLink(currentPage -1, currentPage));
      links.push(renderPaginationLink(currentPage, currentPage));
      links.push(renderPaginationLink(currentPage + 1, currentPage));
      links.push(renderDividerLink('divider-2'));
      links.push(renderPaginationLink(totalPages, currentPage));
    } else if(currentPage === totalPages - 3) {
      links.push(renderPaginationLink(1, currentPage));
      links.push(renderDividerLink('divider-1'));
      links.push(renderPaginationLink(totalPages - 4, currentPage));
      links.push(renderPaginationLink(totalPages - 3, currentPage));
      links.push(renderPaginationLink(totalPages - 2, currentPage));
      links.push(renderPaginationLink(totalPages - 1, currentPage));
      links.push(renderPaginationLink(totalPages, currentPage));
    } else if(currentPage === totalPages - 2) {
      links.push(renderPaginationLink(1, currentPage));
      links.push(renderDividerLink('divider-1'));
      links.push(renderPaginationLink(totalPages - 3, currentPage));
      links.push(renderPaginationLink(totalPages - 2, currentPage));
      links.push(renderPaginationLink(totalPages - 1, currentPage));
      links.push(renderPaginationLink(totalPages, currentPage));
    } else {
      links.push(renderPaginationLink(1, currentPage));
      links.push(renderDividerLink('divider-1'));
      links.push(renderPaginationLink(totalPages - 2, currentPage));
      links.push(renderPaginationLink(totalPages - 1, currentPage));
      links.push(renderPaginationLink(totalPages, currentPage));
    }

    return links;
  }

  // Create pagination links
  const totalPages = Math.ceil(totalCount/pageSize);
  const paginationLinks = renderPageLinks(currentPage, totalPages);

  if (totalCount === 0) {
    return null;
  }

  return (
    <nav>
      <div className="flex space-x-2">
        {renderPreviousPageLink(currentPage)}
        {paginationLinks}
        {renderNextPageLink(currentPage, totalPages)}
      </div>
    </nav>
  );
};
