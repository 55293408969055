import React from 'react';
import PropTypes from 'prop-types';
import { XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import Tooltip from '../pages/_post-opportunity/forms/Tooltip';

Tag.propTypes = {
  label: PropTypes.string,
  color: PropTypes.string
};

export default function Tag(props) {
  const {
    label,
    tooltip,
    selected = false,
    onClick,
    onCancel,
    color = 'gray'
  } = props;

  const bgColorMap = {
    'red': selected ? 'bg-red-500' : 'bg-red-50',
    'purple': selected ? 'bg-purple-500' : 'bg-purple-50',
    'green': selected ? 'bg-green-500' : 'bg-green-50',
    'orange': selected ? 'bg-cs-orange-500' : 'bg-cs-orange-50',
    'blue': selected ? 'bg-cs-blue-500' : 'bg-cs-blue-50',
    'yellow': selected ? 'bg-cs-yellow-500' : 'bg-cs-yellow-50',
    'pink': selected ? 'bg-pink-500' : 'bg-pink-50',
    'gray': selected ? 'bg-cs-gray-500' : 'bg-cs-gray-50'
  };

  const borderColorMap = {
    'red': selected ? '-m-px border border-red-500' : '-m-px border border-red-200',
    'purple': selected ? '-m-px border border-purple-500' : '-m-px border border-purple-200',
    'green': selected ? '-m-px border border-green-500' : '-m-px border border-green-200',
    'orange': selected ? '-m-px border border-cs-orange-500' : '-m-px border border-cs-orange-200',
    'blue': selected ? '-m-px border border-cs-blue-500' : '-m-px border border-cs-blue-200',
    'yellow': selected ? '-m-px border border-cs-yellow-500' : '-m-px border border-cs-yellow-200',
    'pink': selected ? '-m-px border border-pink-500' : '-m-px border border-pink-200',
    'gray': selected ? '-m-px border border-cs-gray-500' : '-m-px border border-cs-gray-200'
  };

  const textColorMap = {
    'red': selected ? 'text-white' : 'text-red-500',
    'purple': selected ? 'text-white' : 'text-purple-500',
    'green': selected ? 'text-white' : 'text-green-600',
    'orange': selected ? 'text-white' : 'text-cs-orange-500',
    'blue': selected ? 'text-white' : 'text-cs-blue-500',
    'yellow': selected ? 'text-white' : 'text-cs-yellow-500',
    'pink': selected ? 'text-white' : 'text-pink-500',
    'gray': selected ? 'text-white' : 'text-cs-gray-500',
  };

  const content = (
    <div className={classNames(
      `inline-block px-2 py-0.5`,
      'rounded-cs-20',
      onClick ? 'cursor-pointer': '',
      bgColorMap[color],
      // !selected && borderColorMap[color],
      borderColorMap[color],
    )} onClick={onClick}>
      <div className="flex items-center space-x-2">
        <div className={classNames(
          'text-sm',
          'line-clamp-1 ',
          textColorMap[color]
        )}>
          {label}
        </div>
        {onCancel && (
          <XMarkIcon className={classNames(
            'h-4 w-4',
            textColorMap[color]
          )}/>
        )}
      </div>
    </div>
  );

  if (tooltip) {
    return (
      <Tooltip tooltip={tooltip} icon={false}>
        {content}
      </Tooltip>
    );
  }

  return content;
};
