import React from 'react';
import PropTypes from 'prop-types';
import FormTemplate from '../../dialogs_common/OnboardingTemplate';
import SolidButton from '../../components/Button/SolidButton';
import useRouter from '../../hooks/useRouter';
import Card from '../../components/Card';

Community.propTypes = {
  user: PropTypes.object.isRequired,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  onSkip: PropTypes.func
};

export default function Community(props) {
  const { user } = props;
  const { history } = useRouter();

  return (
    <Card>
      <FormTemplate
        className="w-full"
        title="Would you like to change your community?"
        subtitle={(
          <span>
            Click the button below to join a different community. This will change your
            stewards as well as which projects, events, and offers you see by default.
          </span>
        )}
        footer={(
          <div className="flex justify-center w-full">
            <SolidButton onClick={() => history.push('/setup/network')}>
              Change community
            </SolidButton>
          </div>
        )}
      />
    </Card>
  );
};
