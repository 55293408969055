import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Entries from './Entries';
import Overlay from '../../../forms/_common/Overlay';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';

Groups.propTypes = {
  userGroups: PropTypes.object
};

export default function Groups(props) {
  const {
    user,
    groups,
    userGroups,
    selectedGroups
  } = props;

  const mutation_create = useQueryMutation('tagNode.create');
  const mutation_destroy = useQueryMutation('tagNode.destroy2');

  const saving = (
    mutation_create.isLoading ||
    mutation_destroy.isLoading
  );

  function onSelectGroup(group) {
    const userGroup = _.find(userGroups.data, function(userGroup) {
      return userGroup._tag.id === group.id;
    });

    if (!userGroup) {
      mutation_create.mutate({
        userId: user.id,
        tagId: group.id
      }, {
        onSuccess: payload => userGroups._add(payload)
      });
    } else {
      mutation_destroy.mutate(userGroup._tagNode, {
        onSuccess: payload => userGroups._remove(payload)
      });
    }
  }

  return (
    <Overlay isVisible={saving}>
      <div className="flex flex-wrap -m-4">
        <div className="w-full flex p-4">
          <Entries
            // title={category.data.name}
            // subtitle="Which of these areas do you have experience with?"
            user={user}
            groups={groups}
            userGroups={userGroups}
            onSelectGroup={onSelectGroup}
          />
        </div>
      </div>
    </Overlay>
  );
}
