import { extractArrayFromQuery } from '../query-utils';

export default function getSearchParamsFromQuery(query) {
  return {
    networks: query.networks || '',
    search: query.search || '',
    searchPartner: query.searchPartner || '',
    searchEducator: query.searchEducator || '',
    searchOffer: query.searchOffer || '',
    name: query.name || '',
    title: query.title || '',
    organization: query.organization || '',
    school: query.school || '',
    activities: extractArrayFromQuery(query, 'activities'),
    activityTypes: extractArrayFromQuery(query, 'activityTypes'),
    ages: extractArrayFromQuery(query, 'ages'),
    commitments: extractArrayFromQuery(query, 'commitments'),
    costs: extractArrayFromQuery(query, 'costs'),
    instructions: extractArrayFromQuery(query, 'instructions'),
    days: extractArrayFromQuery(query, 'days'),
    experiences: extractArrayFromQuery(query, 'experiences'),
    expertises: extractArrayFromQuery(query, 'expertises'),
    expertiseTypes: extractArrayFromQuery(query, 'expertiseTypes'),
    grades: extractArrayFromQuery(query, 'grades'),
    groups: extractArrayFromQuery(query, 'groups'),
    guidances: extractArrayFromQuery(query, 'guidances'),
    interactionTypes: extractArrayFromQuery(query, 'interactionTypes'),
    participants: extractArrayFromQuery(query, 'participants'),
    practices: extractArrayFromQuery(query, 'practices'),
    subjects: extractArrayFromQuery(query, 'subjects'),
    supplies: extractArrayFromQuery(query, 'supplies'),
    times: extractArrayFromQuery(query, 'times'),
    trainings: extractArrayFromQuery(query, 'trainings'),
    locationCondition: query.locationCondition ? JSON.parse(query.locationCondition) : {
      // radius: 5
    }
  };
}
