/**
 * Configuration file for the Filestack file picker
 *
 * This file is where you define overrides for the default file picker parameters
 */

export default {

  /**
   * API key associated with account files should be uploaded to
   */

  stewardUrl: function() {
    return `https://steward.communityshare.org`
  }

};
