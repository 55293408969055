import React from 'react';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import { useConnect } from '../../../../hooks/@lore/query-connect';
import Loader from '../../../components/Loader';
import Card from '../../../components/Card';
import ComposableEventHeader from '../../../components_cards/ComposableEventHeader';
import TagRow from '../../../components_cards/tags/TagRow';
import EventTag from '../../../components_cards/tags/EventTag';
import OutlineButtonGray from '../../../components/Button/OutlineButton.gray';
import ParticipantTag from '../../../components_cards/tags/ParticipantTag';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import CompletedTag from '../../../components_cards/tags/_resource/CompletedTag';
import CancelledTag from '../../../components_cards/tags/_resource/CancelledTag';
import ExpiredTag from '../../../components_cards/tags/_resource/ExpiredTag';
import usePageRouter from '../../../dialogs-routable/_hooks/usePageRouter';
import EvaluationText from '../EvaluationText';
import EvaluationButton from '../EvaluationButton';
import LazyCard from '../../../components/LazyCard';

export default function Invitation(props) {
  const { invitation } = props;

  const currentUser = useUser();
  const launch = useDialogLauncher();
  const { history } = usePageRouter();

  const event = useConnect('event.byId', {
    id: invitation.data.eventId
  });

  const evaluation = useConnect('evaluationEventPartner.first', {
    where: {
      eager: {
        $where: {
          eventId: event.id,
          userId: currentUser.id
        }
      }
    }
  });

  function onViewEvent() {
    launch(DialogManager.InvitationView.Config(invitation.id));
  }

  function onViewEvaluation() {
    launch(DialogManager.EvaluationEventPartner.Config(evaluation.id));
  }

  function redirectToEvaluation() {
    history.push(`/evaluation-event-partner/${evaluation.id}`);
  }

  if (event.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <LazyCard divider={true}>
      <ComposableEventHeader
        event={event}
        showTags={false}
        tags={(
          <TagRow>
            <EventTag />
            <ParticipantTag />
            <CompletedTag resource={event} />
            <CancelledTag resource={event} />
            <ExpiredTag resource={event} />
          </TagRow>
        )}
        showPartners={false}
      />
      <div className="flex justify-between p-4 bg-gray-50 flex-wrap gap-2">
        <div>
          {evaluation.state === PayloadStates.RESOLVED && (
            <EvaluationText
              pendingTitle="Share the impact of your event"
              evaluation={evaluation}
              onView={onViewEvaluation}
            />
          )}
        </div>
        <div className="flex flex-wrap gap-2">
          <OutlineButtonGray
            label="View event"
            onClick={onViewEvent}
          />
          {evaluation.state === PayloadStates.RESOLVED && (
            <EvaluationButton
              evaluation={evaluation}
              onEdit={redirectToEvaluation}
            />
          )}
        </div>
      </div>
    </LazyCard>
  );
}
