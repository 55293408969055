import React from 'react';
import ConnectButton from '../../../pages/_banner/ConnectButton';
import Wrapper from '../../../pages/_banner/Wrapper';
import useDialogLauncher from '../../_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';

export default function MaybeBanner(props) {
  const { invitation, creator } = props;

  const launch = useDialogLauncher();

  function onViewMessages() {
    launch(DialogManager.Messages.Config(creator.id, {
      eventId: invitation.data.eventId
    }));
  }

  function showInterestedDialog() {
    launch(DialogManager.InvitationYes.Config(invitation.id));
  }

  function showDeclineDialog() {
    launch(DialogManager.InvitationNo.Config(invitation.id));
  }

  return (
    <Wrapper
      color="accent"
      creator={creator}
      title={`You said "maybe" — be sure to confirm soon!`}
      subtitle={(
        <>
          Once you let {creator.data.firstName} know you're attending the event, you can
          ask questions, offer ideas, or refer someone else who might be a good fit!
        </>
      )}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        <ConnectButton outline={true} onClick={onViewMessages}>
          Message {creator.data.firstName || 'Educator'}
        </ConnectButton>
        <ConnectButton outline={true} onClick={showDeclineDialog}>
          Sorry, I can't attend
        </ConnectButton>
        <ConnectButton onClick={showInterestedDialog}>
          Yes, I'll attend
        </ConnectButton>
      </div>
    </Wrapper>
  );
};
