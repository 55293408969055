import React from 'react';
import { useUser } from '@lore/auth';
import { Helmet } from 'react-helmet-async';
import ParentLayout from '../Layout';
import Navigation from '../../../dialogs_common/Navigation';
import Urls from '../Urls';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import Form from './Form';
import useTags from '../../../hooks/useTags';
import TagTypes from '../../../constants/TagTypes';
import useUserTags from '../../../hooks/useUserTags';
import useRouter from '../../../hooks/useRouter';
import { useConnect } from '../../../../hooks/@lore/query-connect';

export default function Layout(props) {
  const { history } = useRouter();
  const user = useUser();

  const roles = useConnect('role.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    }
  });

  const experiences = useTags(TagTypes.Experience);
  const userExperiences = useUserTags(user, TagTypes.Experience);

  if (
    experiences.state === PayloadStates.FETCHING ||
    userExperiences.state === PayloadStates.FETCHING ||
    roles.state === PayloadStates.FETCHING
  ) {
    return (
      <ParentLayout>
        <Helmet>
          <title>Setup - Experience</title>
        </Helmet>
        <Loader />
      </ParentLayout>
    );
  }

  return (
    <ParentLayout>
      <Helmet>
        <title>Setup - Experience</title>
      </Helmet>
      <Form
        user={user}
        experiences={experiences}
        userExperiences={userExperiences}
        navigation={user.data.isEducator ?
          <Navigation activeStep={6} steps={10}/> :
          <Navigation activeStep={6} steps={12}/>
        }
        // onPrevious={(newData) => history.push(Urls.SKILLS)}
        onPrevious={(newData) => {
          if (user.data.isEducator) {
            _.find(roles.data, m => m.data.title === 'Classroom Teacher') ?
              history.push(Urls.PRACTICES) :
              history.push(Urls.SCHOOL);
          } else {
            history.push(Urls.ACTIVITY_TYPES);
          }
        }}
        onNext={(newData) => history.push(Urls.HOPE)}
        onSkip={() => history.push(Urls.HOPE)}
      />
    </ParentLayout>
  );
};
