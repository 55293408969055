import React from 'react';
import { useConnect } from '@lore/query-connect';

export default function Title(props) {
  const { claim } = props;

  const creator = useConnect('user.byId', {
    id: claim.data.creatorId
  });

  const offer = useConnect('offer.byId', {
    id: claim.data.offerId
  });

  const activity = useConnect('tag.byId', {
    id: offer.data.activityId
  }, {
    enabled: !!offer.data.activityId
  });

  // return (
  //   <div className="text-xl font-semibold text-cs-gray-900">
  //     From: {creator.data.firstName} {creator.data.lastName} {offer.data.creatorId !== creator.id ? '(Steward)' : ''}
  //   </div>
  // );

  return (
    <div className="text-xl font-semibold text-cs-gray-900">
      {offer.data.title || activity?.data.name}
    </div>
  );
}
