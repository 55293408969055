import React from 'react';
import Activity from './Activity';
import Description from './Description';
import Students from './Students';
import Commitment from './Commitment';
import Creator from './Creator';
import Section from '../../../components_profiles/Section';
import Participation from './Participation';
import Uploads from './Uploads';
import Experience from './Experience';
import Subjects from './Subjects';
import Website from './Website';
import Cost from './Cost';
import Supplies from './Supplies';
import useOfferTags from '../../../hooks/useOfferTags';
import TagTypes from '../../../constants/TagTypes';

export default function Offer(props) {
  const { offer } = props;

  const offerGuidances = useOfferTags(offer, TagTypes.Guidance);
  const offerSubjects = useOfferTags(offer, TagTypes.Subject);

  return (
    <div className="flex flex-col md:flex-row space-x-8">
      <div className="w-full md:w-2/3 space-y-8">
        <Section title="Activity">
          <Activity offer={offer} />
          <div className="w-full space-y-4 block md:hidden">
            <Commitment offer={offer} />
            <Cost offer={offer} />
            <Supplies offer={offer} />
            <Participation offer={offer} />
          </div>
        </Section>
        <Section title="Details">
          <Description offer={offer} />
          <Website offer={offer} />
        </Section>
        <Section
          title="Audience"
          subtitle="Learners the activity is designed for."
        >
          <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
            <div className="w-full">
              <Creator offer={offer} />
            </div>
            <div className="w-full">
              <Students offer={offer} />
            </div>
          </div>
        </Section>
        {(
          offerSubjects.data.length > 0 ||
          offerGuidances.data.length > 0
        ) && (
          <Section title="Subjects and skills addressed">
            <Subjects offer={offer} />
            <Experience offer={offer} />
          </Section>
        )}
        <Uploads offer={offer} />
      </div>
      <div className="w-full md:w-1/3 space-y-8 mt-12 hidden md:block">
        <Commitment offer={offer} />
        <Cost offer={offer} />
        <Supplies offer={offer} />
        <Participation offer={offer} />
      </div>
    </div>
  );
};
