import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useDialogConfig from '../_hooks/useDialogConfig';
import QuestionDeclineDialog from '../../dialogs/question.decline';
import { useQueryClient } from '@tanstack/react-query';

export default function Launcher(props) {
  const config = useDialogConfig();
  const queryClient = useQueryClient();

  const question = useConnect('question.byId', {
    id: config.params.questionId
  });

  const project = useConnect('project.byId', {
    id: question.data.projectId
  }, {
    enabled: !!question.data.projectId
  });

  if (
    question.state === PayloadStates.FETCHING ||
    project.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  return (
    <QuestionDeclineDialog
      question={question}
      project={project}
      onCancel={props.onClose}
      onSubmit={function(request) {
        queryClient.invalidateQueries({ queryKey: ['question'] });
      }}
    />
  );
};
