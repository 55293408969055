import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import Text from '../../typography';
import Tooltip from '../../pages/_post-opportunity/forms/Tooltip';
import StringField from '../../forms/_fields_v2/StringField';

LocationSearchField.propTypes = {
  address: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

LocationSearchField.defaultProps = {
  condition: {}
};

export default function LocationSearchField(props) {
  const {
    label,
    error,
    description,
    disabled,
    placeholder = "Enter a name or address",
    onChange,
    displayMap = true,
    optional = false,
    required = false,
    tooltip,
    icon
  } = props;

  const [address, setAddress] = useState(props.address || '');

  const inputRef = useRef(null);
  const inputWindowContentRef = useRef(null);
  const mapRef = useRef(null);

  useEffect(() => {
    // const map = new google.maps.Map(document.getElementById('map'));
    const map = new google.maps.Map(mapRef.current, {
      center: {
        lat: 37.923483,
        lng: -99.650695
      },
      zoom: 3
    });
    const input = inputRef.current;
    // const infoWindowContent = inputWindowContentRef.current;
    const marker = new google.maps.Marker({
      map: map,
      // anchorPoint: new google.maps.Point(0, -29)
    });

    const autocomplete = new google.maps.places.Autocomplete(input, {
      // types: ['address']
    });

    autocomplete.setFields([
      'place_id',
      'name',
      'formatted_address',
      'address_components',
      'geometry',
      // 'icon'
    ]);

    // const infoWindow = new google.maps.InfoWindow();
    // infoWindow.setContent(infoWindowContent);

    autocomplete.addListener('place_changed', function() {
      // infoWindow.close();
      marker.setVisible(false);
      const place = autocomplete.getPlace();

      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        // window.alert("No details available for input: '" + place.name + "'");
        return;
      }

      // If the place has a geometry, then present it on a map.
      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
      } else {
        map.setCenter(place.geometry.location);
        map.setZoom(17);  // Why 17? Because it looks good.
      }
      marker.setPosition(place.geometry.location);
      marker.setVisible(true);

      let address = '';
      if (place.address_components) {
        address = [
          (place.address_components[0] && place.address_components[0].short_name || ''),
          (place.address_components[1] && place.address_components[1].short_name || ''),
          (place.address_components[2] && place.address_components[2].short_name || '')
        ].join(' ');
      }

      // infoWindowContent.children['place-icon'].src = place.icon;
      // infoWindowContent.children['place-name'].textContent = place.name;
      // infoWindowContent.children['place-address'].textContent = address;
      // infoWindow.open(map, marker);

      const latlng = place.geometry.location;

      const street_number = _.find(place.address_components, function(address_component) {
        return _.find(address_component.types, function(type) {
          return type === 'street_number';
        })
      });

      const route = _.find(place.address_components, function(address_component) {
        return _.find(address_component.types, function(type) {
          return type === 'route';
        })
      });

      const locality = _.find(place.address_components, function(address_component) {
        return _.find(address_component.types, function(type) {
          return type === 'locality';
        })
      });

      const administrative_area_level_1 = _.find(place.address_components, function(address_component) {
        return _.find(address_component.types, function(type) {
          return type === 'administrative_area_level_1';
        })
      });

      const postal_code = _.find(place.address_components, function(address_component) {
        return _.find(address_component.types, function(type) {
          return type === 'postal_code';
        })
      });

      onChange({
        address: place.formatted_address,
        latitude: latlng.lat(),
        longitude: latlng.lng()
      }, place, {
        place_id: place.place_id,
        name: place.name,
        addressLine1: `${street_number && street_number.short_name || ''}${route && ` ${route.short_name}` || ''}`,
        addressLine2: '',
        addressLine3: '',
        city: locality && locality.short_name || '',
        state: administrative_area_level_1 && administrative_area_level_1.short_name || '',
        zipCode: postal_code && postal_code.short_name || ''
      });

      // onChange({
      //   address: place.formatted_address,
      //   latitude: latlng.lat(),
      //   longitude: latlng.lng()
      // }, place, place.address_components ? {
      //   place_id: place.place_id,
      //   name: place.name,
      //   addressLine1: `${place.address_components[0].short_name} ${place.address_components[1].short_name}`,
      //   addressLine2: '',
      //   addressLine3: '',
      //   city: place.address_components[3].short_name,
      //   state: place.address_components[5].short_name,
      //   zipCode: place.address_components[7].short_name,
      // } : {
      //   place_id: place.place_id,
      //   name: place.name,
      //   addressLine1: '',
      //   addressLine2: '',
      //   addressLine3: '',
      //   city: '',
      //   state: '',
      //   zipCode: ''
      // });
    });

    // Remove the pac-container that Google fails to cleanup when this component unmounts.
    return function() {
      const nodes = document.getElementsByClassName('pac-container');
      for (let node of nodes) {
        node.remove();
      }
    }

  }, []);

  return (
    <div>
      <StringField
        {...props}
        inputRef={inputRef}
        defaultValue={address}
        placeholder={placeholder}
        readOnly={disabled}
      />
      <div ref={mapRef} className={`${displayMap ? 'block' : 'hidden'} w-full mt-6`} style={{ height: '350px' }}/>
    </div>
  );

  return (
    <>
      {/*<div ref={mapRef} className="block w-full mb-6" style={{ height: '350px' }}/>*/}
      {/*<div ref={inputWindowContentRef} className="hidden">*/}
      {/*  <img src="" width="16" height="16" id="place-icon" />*/}
      {/*  <div id="place-name" />*/}
      {/*  <div id="place-address" className=""/>*/}
      {/*</div>*/}
      <div>
        {label && (
          <div className="relative text-sm text-cs-gray-500 mb-1">
            {required && (
              <span className="inline-block text-cs-orange-500">*</span>
            )}
            <span className="flex items-center space-x-1">
            <Text.Base>
              {label}
            </Text.Base>
            {tooltip && (
              <div>
                <Tooltip tooltip={tooltip} label={true} />
              </div>
            )}
          </span>
            {optional && (
              <div className="absolute top-0 right-0 text-cs-gray-300 text-sm leading-6">
                Optional
              </div>
            )}
          </div>
        )}
        <input
          ref={inputRef}
          type="text"
          className={classNames(
            // 'form-control',
            'relative w-full bg-white',
            'py-2 pl-3 pr-10 text-left',
            '-m-px border border-cs-gray-200 rounded-md',
            'placeholder:text-cs-gray-400 placeholder:font-normal',
            'focus:ring-cs-blue-500 focus:border-cs-blue-500',
            error ? 'is-invalid' : ''
          )}
          // value={value}
          defaultValue={address}
          placeholder={placeholder}
          readOnly={disabled}
          // onChange={(event) => {
          //   onChange(event.target.value)
          // }}
        />
        {error ? (
          <div className="mt-2 text-sm text-red-500">
            {error}
          </div>
        ) : null}
        {description ? (
          <Text.SmGrayMedium className="mt-2">
            {description}
          </Text.SmGrayMedium>
        ) : null}
        <div ref={mapRef} className={`${displayMap ? 'block' : 'hidden'} w-full mt-6`} style={{ height: '350px' }}/>
      </div>
      {/*<StringField*/}
      {/*  ref={inputRef}*/}
      {/*  placeholder="Enter an address or zip code"*/}
      {/*  value={address}*/}
      {/*  onChange={_onChange}*/}
      {/*  error={error}*/}
      {/*/>*/}
    </>
  );
};
