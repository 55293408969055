import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import DividerCard from '../../../components/DividerCard';
import ComposableEventHeader from '../../../components_cards/ComposableEventHeader';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import OutlineButtonBlue100 from '../../../components/Button/OutlineButton.blue-100';
import Evaluation from './Evaluation';
import Tags from './Tags';
import PendingEvaluationText from '../PendingEvaluationText';

export default function EvaluationEventPartner(props) {
  const { evaluation } = props;

  const event = useConnect('event.byId', {
    id: evaluation.data.eventId
  });

  const invitation = useConnect('invitation.first', {
    where: {
      eager: {
        $where: {
          eventId: event.id,
          userId: evaluation.data.userId
        }
      }
    }
  });

  const launch = useDialogLauncher();

  function onViewEvent() {
    launch(DialogManager.InvitationView.Config(invitation.id));
  }

  function onViewEvaluation() {
    launch(DialogManager.EvaluationEventPartner.Config(evaluation.id));
  }

  if (
    event.state === PayloadStates.FETCHING ||
    invitation.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  return (
    <DividerCard className="overflow-hidden -m-px border border-cs-blue-500" border={false}>
      <ComposableEventHeader
        tags={(
          <Tags event={event} />
        )}
        showTags={false}
        event={event}
        showPartners={false}
      />
      <div className="flex justify-between p-4 bg-cs-blue-500 flex-wrap gap-2">
        <PendingEvaluationText
          title="Share the impact of your event"
          evaluation={evaluation}
          onView={onViewEvaluation}
        />
        <div className="flex flex-wrap gap-2">
          {invitation.state === PayloadStates.RESOLVED && (
            <OutlineButtonBlue100
              label="View event"
              onClick={onViewEvent}
            />
          )}
          <Evaluation evaluation={evaluation}/>
        </div>
      </div>
    </DividerCard>
  );
}
