import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import FormField from '../../../forms/_common/FormField';
import SolidButton from '../../../components/Button/SolidButton';
import FormTemplate2 from '../../_post-opportunity/FlowTemplate';
import RadioButtonField from '../../_post-opportunity/_fields/RadioButtonField.cards';
import FormSection from '../../_post-opportunity/forms/FormSection';
import TextAreaField from '../../../forms/_fields_v2/TextAreaField';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';

const MAX_LENGTH = 256;

Form.propTypes = {
  user: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func,
  onSkip: PropTypes.func
};

export default function Form(props) {
  const {
    user,
    offer,
    navigation,
    onNext,
    onPrevious,
    onSkip,
    onExit,
    FormTemplate = FormTemplate2,
    buttonText = 'Next'
  } = props;

  const [data, setData] = useState(_.pick(offer.data, [
    'hasCost',
    'cost'
  ]));
  const [showErrors, setShowErrors] = useState(false);
  const [exit, setExit] = useState(false);

  const mutation = useQueryMutation('offer.update', offer);
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      exit ? onExit(data) : onNext(data);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate(data);
  }

  function onSubmitAndExit() {
    onSubmit();
    setExit(true);
  }

  function onChange(name, value) {
    setData(_.merge({}, data, {
      [name]: value
    }));
  }

  return (
    <Overlay isVisible={saving}>
      <FormErrors data={data} validators={{
        // firstName: [validators.isRequired],
        // lastName: [validators.isRequired]
      }}>
        {({ errors, hasError }) => {
          return (
            <FormTemplate
              onExit={() => hasError ? setShowErrors(true) : onSubmitAndExit()}
              onPrevious={onPrevious}
              navigation={navigation}
              alert={error ? (
                <RequestError request={request} />
              ) : showErrors ? (
                <ErrorMessage>
                  Please fill in all required fields.
                </ErrorMessage>
              ) : null}
              body={(
                <div className="space-y-10">
                  <FormSection
                    // title="Does your offer have any costs to educators or students?"
                  >
                    <FormField name="hasCost" data={data} errors={showErrors && errors} onChange={onChange}>
                      {(field) => (
                        <>
                          <RadioButtonField
                            name="hasCost"
                            // label="Does your partner need to come from specific career field(s) or have special expertise?"
                            value={field.value === 'Yes' ? 'Yes' : 'No'}
                            error={field.error}
                            placeholder="Select a role..."
                            options={{
                              data: [
                                'Yes',
                                'No'
                              ].map((option) => {
                                return {
                                  id: option,
                                  data: {
                                    name: option
                                  }
                                }
                              })
                            }}
                            optionLabel="name"
                            onChange={(value) => field.onChange(value)}
                          />

                        </>
                      )}
                    </FormField>
                  </FormSection>
                  {data.hasCost === 'Yes' && (
                    <FormSection
                      title="What is the cost?"
                      subtitle={(
                        <>
                          *CommunityShare can't process payments or facilitate the exchange of
                          fees. You'll be responsible for determining final costs and gathering payment.
                        </>
                      )}
                    >
                      <FormField name="cost" data={data} errors={showErrors && errors} onChange={onChange}>
                        {(field) => (
                          <TextAreaField
                            // label="What is the cost?"
                            // explanation={(
                            //   <span>
                            //     *CommunityShare is here to help you connect, but we are unable to process
                            //     any payments or facilitate the exchange of fees. You are responsible for
                            //     working with the educator(s) to determine the final cost and method of
                            //     payment.
                            //   </span>
                            // )}
                            description={`${data.cost ? data.cost.length : 0}/${MAX_LENGTH} characters`}
                            rows={5}
                            placeholder="Cost description..."
                            // description={(
                            //   <div className="text-cs-orange-500 cursor-pointer" onClick={onShowSuggestions}>
                            //     Need suggestions for what to include?
                            //   </div>
                            // )}
                            value={field.value}
                            error={field.error}
                            onChange={field.onChange}
                          />
                        )}
                      </FormField>
                    </FormSection>
                  )}
                </div>
              )}
              footer={(
                <>
                  <SolidButton onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
                    {buttonText}
                  </SolidButton>
                </>
              )}
            />
          );
        }}
      </FormErrors>
    </Overlay>
  );
};
