import React, { useState } from 'react';
import _ from 'lodash';
import { useUser } from '@lore/auth';
import Steps from './_common/Steps';
import Info from './Info';
import Confirmation from './Confirmation';

export default function ReactiveProfileDialog(props) {
  const { onCancel } = props;

  const user = useUser();

  const [data, setData] = useState(_.extend({}, user.data));
  const [key, setKey] = useState(0);
  const [stepIndex, setStepIndex] = useState(Steps.INFO);

  function onNext(newData, newStepIndex) {
    setStepIndex(newStepIndex || (stepIndex + 1));
    setData(_.extend({}, data, newData));
  }

  function onPrevious(newData, newStepIndex) {
    setStepIndex(newStepIndex || (stepIndex - 1));
    setData(_.extend({}, data, newData));
  }

  function onChangeStep(newData, newStepIndex) {
    setStepIndex(newStepIndex);
    setData(_.extend({}, data, newData));
  }

  const steps = [
    (props) => {
      return (
        <Info
          {...props}
          onNext={(newData) => onNext(newData, Steps.CONFIRMATION)}
          onCancel={onCancel}
        />
      );
    },
    (props) => {
      return (
        <Confirmation
          {...props}
          onCancel={onCancel}
        />
      );
    }
  ];

  const step = steps[stepIndex]({
    user: user,
    data: data
  });

  return (
    <div key={key}>
      {step}
    </div>
  );
}
