import classNames from 'classnames';

import { getSize, getColor, getWeight } from './utils';

export default function Text(props) {
  const { size, weight, color } = props;

  return (
    <div className={classNames(
      getSize(size),
      getColor(color),
      getWeight(weight),
      props.className
    )}>
      {props.children}
    </div>
  );
}
