import React from 'react';
import { Helmet } from 'react-helmet-async';
import Partners from './Partners';
import useRouter from '../../hooks/useRouter';
import PageHeader from '../people-people/PageHeader';
import { useUser } from '@lore/auth';

export default function Layout(props) {
  const {
    project,
    event
  } = props;

  const router = useRouter();
  const currentUser = useUser();

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Find partners</title>
      </Helmet>
      <PageHeader
        title="Find partners"
        subtitle={(
          <>
            Explore and connect with community members with skills and experiences
            to help bring your project or event to life. Use filters to search by skills,
            life experiences, and more.
          </>
        )}
      />
      <Partners
        project={project}
        event={event}
        router={router}
        // distance={currentUser.data}
      />
    </div>
  );
}
