import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useDialogConfig from '../_hooks/useDialogConfig';
import OfferCancelDialog from '../../dialogs/offer.cancel';
import { useQueryClient } from '@tanstack/react-query';

export default function Launcher(props) {
  const config = useDialogConfig();
  const queryClient = useQueryClient();

  const offer = useConnect('offer.byId', {
    id: config.params.offerId
  });

  if (offer.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <OfferCancelDialog
      offer={offer}
      onCancel={props.onClose}
      onSubmit={function(request) {
        queryClient.invalidateQueries({ queryKey: ['offer'] });
      }}
    />
  );
};
