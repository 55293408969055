import React, { useRef } from 'react';
import { parse } from 'query-string';
import Pagination from '../../components/pagination/Pagination';
import useScrollToTop from '../../hooks/useScrollToTop';
import Results from './Results';
import useFilterCount from '../../hooks/useFilterCount';
import useQuery from './useQuery';
import DistanceFilter from './DistanceFilter';
import FilterButton from '../people-partners/FilterButton';
import PaginationInformation from '../../components/pagination/PaginationInformation';
import TypeFilter from './TypeFilter';
import NetworkFilter from './NetworkFilter';
import SortBy from './SortBy';
import { attributeKeys } from './queryUtils';
import useDialogLauncher from '../../dialogs-routable/_hooks/useDialogLauncher';
import useDialogConfig from '../../dialogs-routable/_hooks/useDialogConfig';
import { DialogManager } from '../../dialogs_new/DialogManager';
import _ from 'lodash';

export default function Layout(props) {
  const { router } = props;
  const { location } = router;

  const search = parse(location.search);
  const filterCount = useFilterCount(search, _.filter(attributeKeys, key => [
    'networks',
    'interactionTypes',
    'locationCondition'
  ].indexOf(key) < 0));

  const node = useRef();
  const scrollToTop = useScrollToTop();

  const pageSize = 4;

  const result = useQuery({
    search,
    pageSize
  });

  const launch = useDialogLauncher();
  const config = useDialogConfig();

  function showFilters() {
    launch(DialogManager.FiltersProject.Config(config?.id));
  }

  return (
    <>
      <div ref={node} className="flex flex-col sm:flex-row flex-wrap gap-4 justify-between">
        <div className="flex flex-col sm:flex-row gap-4">
          <NetworkFilter />
          <TypeFilter />
          <DistanceFilter />
        </div>
        <div className="flex flex-col sm:flex-row gap-4">
          <SortBy />
          <FilterButton
            count={filterCount}
            onClick={showFilters}
          />
        </div>
      </div>
      <Results result={result} />
      <div className="flex items-center justify-between">
        <PaginationInformation
          pageSize={pageSize}
          totalCount={result.meta?.totalCount}
        />
        <Pagination
          pageSize={pageSize}
          totalCount={result.meta?.totalCount}
          onNavigate={() => scrollToTop(node)}
        />
      </div>
    </>
  );
}
