import React from 'react';
import { CurrencyDollarIcon } from '@heroicons/react/24/outline';
import useRouter from '../../hooks/useRouter';
import Urls from '../project-create/Urls';
import DataCard from '../profile-edit/DataCard';
import usePageRouter from '../../dialogs-routable/_hooks/usePageRouter';

export default function Budget(props) {
  const { project } = props;
  const { history } = usePageRouter();

  return (
    <DataCard
      title="Budget Request"
      icon={CurrencyDollarIcon}
      subtitle={project.data.budget || '(not provided)'}
      onClick={() => history.push(Urls(project).BUDGET)}
    />
  );
};
