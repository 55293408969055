import React from 'react';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import { SolidButton } from '../../../components/Button';
import GrayOutlineButton from '../../../components/Button/OutlineButton.gray';
import ComposableProjectHeader from '../../../components_cards/ComposableProjectHeader';
import DividerCard from '../../../components/DividerCard';
import TagRow from '../../../components_cards/tags/TagRow';
import ProjectTag from '../../../components_cards/tags/ProjectTag';
import ExpiredTag from '../../../components_cards/tags/_resource/ExpiredTag';
import CreatorTag from '../../../components_cards/tags/CreatorTag';
import ProjectMissingFieldsTag from '../../../components_cards/ComposableProjectHeader/ProjectMissingFieldsTag';

export default function ExpiredProject(props) {
  const { project } = props;

  const launch = useDialogLauncher();

  function onClickView() {
    launch(DialogManager.ProjectManage.Config(project.id, `/view`));
  }

  function onClickEdit() {
    launch(DialogManager.ProjectManage.Config(project.id, `/edit`));
  }

  return (
    <DividerCard>
      <ComposableProjectHeader
        project={project}
        tags={(
          <TagRow>
            <ProjectTag />
            {/*<CreatorTag />*/}
            <ProjectMissingFieldsTag project={project} />
            {/*<ExpiredTag resource={project} />*/}
          </TagRow>
        )}
        showTags={false}
        showCreator={false}
      />
      <div className="w-full bg-cs-gray-50 py-4 px-4 flex justify-between items-center">
        <div />
        <div className="flex flex-wrap gap-2">
          <GrayOutlineButton
            onClick={onClickView}
            label="View project"
          />
          <SolidButton
            onClick={onClickEdit}
            label="Continue describing project"
          />
        </div>
      </div>
    </DividerCard>
  );
}
