import React from 'react';
import _ from 'lodash';
import { LinkIcon, MagnifyingGlassIcon, PencilIcon, UsersIcon } from '@heroicons/react/24/outline';
import useDialogConfig from '../../_hooks/useDialogConfig';
import useDialogRouter from '../../_hooks/useDialogRouter';
import ActionBarContainer from '../../_components_action_bar/ActionBarContainer';
import ActionBarButton from '../../_components_action_bar/ActionBarButton';
import ActionBarMenu from './ActionBarMenu';

export default function ActionBar(props) {
  const { event } = props;

  const { id } = useDialogConfig();
  const router = useDialogRouter(id);

  const isClosed = (
    event.data.completed ||
    event.data.cancelled
  );

  const links = _.remove([
    {
      icon: LinkIcon,
      label: 'View event',
      to: `/view`
    },
    !isClosed && {
      icon: PencilIcon,
      label: 'Edit event',
      to: `/edit`
    },
    !isClosed && {
      icon: MagnifyingGlassIcon,
      label: 'Find partners',
      to: `/invite-populate`
    },
    !isClosed && {
      icon: UsersIcon,
      label: 'Manage partners',
      to: `/manage`
    },
    isClosed && {
      icon: UsersIcon,
      label: 'View partners',
      to: `/classify`
    }
  ], entry => entry);

  return (
    <ActionBarContainer>
      {links.map(function(link, index) {
        const active = (
          router.location.pathname.indexOf(link.to) === 0 ||
          link.to.indexOf(router.location.pathname) === 0
        );

        return (
          <ActionBarButton
            key={index}
            label={link.label}
            icon={link.icon}
            onClick={() => router.navigate(link.to)}
            isActive={active}
          />
        );
      })}
      <ActionBarMenu event={event} />
    </ActionBarContainer>
  );
};
