import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import FormTemplate from '../../../dialogs_common/SetupTemplate';
import PrimaryButton from '../../../components_setup/PrimarySetupButton';
import Expertises from './Expertises';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';

Form.propTypes = {
  user: PropTypes.object.isRequired,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  onSkip: PropTypes.func
};

export default function Form(props) {
  const {
    user,
    expertiseTypes,
    userExpertises,
    navigation,
    onNext,
    onPrevious,
    onSkip
  } = props;

  const [data, setData] = useState({
    userExpertises: []
  });
  const [showErrors, setShowErrors] = useState(false);

  const mutation = useQueryMutation('currentUser.updateSingleton', user);
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      onNext(data);
    }
  }, [success]);

  function onSubmit() {
    // mutation.mutate(data);
    onNext(data);
  }

  function onChange(name, value) {
    setData(_.merge({}, data, {
      [name]: value
    }));
  }

  return (
    <div className="my-auto">
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          // name: [validators.isRequired]
          userExpertises: [function(value) {
            if (userExpertises.data.length < 1) {
              return 'Must select at least one topic';
            }
          }],
        }}>
          {({ errors, hasError }) => {
            return (
              <FormTemplate
                onPrevious={onPrevious}
                navigation={navigation}
                // title="What's your school or organization?"
                // subtitle="Locations you create are visible only to you"
                alert={error ? (
                  <RequestError request={request} v2={true} />
                ) : showErrors ? (
                  <ErrorMessage v2={true}>
                    {/*Please fill in all required fields.*/}
                    Please select at least one topic.
                  </ErrorMessage>
                ) : null}
                body={(
                  <>
                    <div className="flex flex-col max-w-4xl">
                      <div className="mb-6">
                        <h5 className="text-3xl text-cs-gray-900 font-semibold text-left">
                          Thinking of your experiences, skills, and passions, select all the topics you
                          might share with students and educators.
                        </h5>
                        <h6 className="font-normal mt-2 text-cs-gray-800 text-left text-lg mb-4">
                          <span className="block mb-2 font-semibold">
                            *You must select at least one.
                          </span>
                          {/*<span className="block">*/}
                          {/*  These help educators know which topics you might be interested in exploring with students.*/}
                          {/*</span>*/}
                        </h6>
                      </div>
                      <Expertises
                        user={user}
                        expertiseTypes={expertiseTypes}
                        userExpertises={userExpertises}
                        columnLayout={true}
                      />
                    </div>
                  </>
                )}
                footer={(
                  <>
                    {/*<div className="text-cs-orange-500 underline cursor-pointer mt-6 sm:mt-0 mr-0 sm:mr-12" onClick={onSkip}>*/}
                    {/*  Skip this step*/}
                    {/*</div>*/}
                    <PrimaryButton disabled={hasError} onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
                      Next
                    </PrimaryButton>
                  </>
                )}
              />
            );
          }}
        </FormErrors>
      </Overlay>
    </div>
  );
};
