import React from 'react';
import { ArrowRightIcon, EnvelopeIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Card from '../../components/Card';
import Image from '../stories/Story/Image';

export default function OptionCard(props) {
  const { title, subtitle, linkText, link, className, to, count = 0, imageUrl, centeredImage, centeredImageBackgroundColor = 'bg-cs-yellow-500' } = props;

  const temp = {
    icon: props.icon || EnvelopeIcon
  };

  return (
    <Card>
      <div className={classNames(
        (link || to) && 'hover:bg-cs-gray-50 cursor-pointer',
        'relative',
        className
      )}>
        {link && (
          <a href={link} target="_blank" className="absolute w-full h-full" />
        )}
        {to && (
          <Link to={to} className="absolute w-full h-full" />
        )}
        <div className="flex flex-col p-4 space-x-0 sm:space-x-4x space-y-3 h-full">
          {centeredImage ? (
            <div className={classNames(
              'h-48 rounded-cs-10 overflow-hidden relativex',
              centeredImageBackgroundColor
            )}>
              {/*<Image story={{*/}
              {/*  data: {*/}
              {/*    imageUrl: imageUrl*/}
              {/*  }*/}
              {/*}} />*/}
              <div className="flex items-center justify-center h-full">
                {centeredImage}
              </div>
              {count > 0 && (
                // <div className="absolute -right-1.5 -top-1.5">
                // <div className="absolute right-2 top-2">
                //   <div className="w-5 h-5 bg-cs-orange-500 rounded-full">
                //     <div className="text-center leading-5 text-white text-xs">
                //       {count}
                //     </div>
                //   </div>
                // </div>
                <div className="absolute right-2 top-2">
                  <div className="w-5x h-5 bg-cs-orange-500 rounded-full">
                    <div className="text-center leading-5 text-white text-xs px-1.5">
                      {count}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : imageUrl ? (
            <div className="h-48 rounded-cs-10 overflow-hidden relativex">
              <Image story={{
                data: {
                  imageUrl: imageUrl
                }
              }} />
              {count > 0 && (
                // <div className="absolute -right-1.5 -top-1.5">
                <div className="absolute right-2 top-2">
                  <div className="w-5x h-5 bg-cs-orange-500 rounded-full">
                    <div className="text-center leading-5 text-white text-xs px-1.5">
                      {count}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="flex flex-row sm:flex-colx">
              <div className="p-2 bg-cs-blue-500 bg-opacity-10 rounded-lg relative">
                <temp.icon className="h-8 w-8 text-cs-blue-500" />
                {count > 0 && (
                  <div className="absolute -right-1.5 -top-1.5">
                    <div className="w-5x h-5 bg-cs-orange-500 rounded-full">
                      <div className="text-center leading-5 text-white text-xs px-1.5">
                        {count}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="flex flex-col flex-1 text-cs-gray-900 space-y-1">
            <div className="flex flex-col flex-1">
              <div className="text-base font-semibold">
                {title}
              </div>
              <div className="text-sm text-cs-gray-500 max-w-3xl">
                {subtitle}
              </div>
            </div>
            {linkText && (
              <div className="flex items-start">
                <div className="flex items-center text-cs-blue-500 space-x-1">
                  <div className="text-sm">
                    {linkText}
                  </div>
                  <ArrowRightIcon className="h-5 w-5" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};
