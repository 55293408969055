import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import PendingBanner from './PendingBanner';
import YesBanner from './YesBanner';
import NoBanner from './NoBanner';
import LoadingBanner from '../../../pages/_banner/LoadingBanner';
import CompletedBanner from './CompletedBanner';
import CancelledBanner from './CancelledBanner';
import MaybeBanner from './MaybeBanner';

Banner.propTypes = {
  invitation: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired
};

export default function Banner(props) {
  const { invitation, event } = props;

  const creator = useConnect('user.byId', {
    id: event.data.creatorId
  });

  if (creator.state === PayloadStates.FETCHING) {
    return (
      <LoadingBanner creator={creator} />
    );
  }

  // return (
  //   <>
  //     <PendingBanner
  //       event={event}
  //       invitation={invitation}
  //       creator={creator}
  //     />
  //     <YesBanner
  //       event={event}
  //       creator={creator}
  //       invitation={invitation}
  //     />
  //     <MaybeBanner
  //       event={event}
  //       creator={creator}
  //       invitation={invitation}
  //     />
  //     <CompletedBanner
  //       event={event}
  //       creator={creator}
  //       invitation={invitation}
  //     />
  //     <NoBanner
  //       event={event}
  //       creator={creator}
  //       invitation={invitation}
  //     />
  //     <CancelledBanner
  //       event={event}
  //       creator={creator}
  //       invitation={invitation}
  //     />
  //   </>
  // );

  if (event.data.cancelled) {
    return (
      <CancelledBanner
        event={event}
        creator={creator}
        invitation={invitation}
      />
    );
  }

  if (event.data.completed) {
    return (
      <CompletedBanner
        event={event}
        creator={creator}
        invitation={invitation}
      />
    );
  }

  if (invitation.data.response === 'Yes') {
    return (
      <YesBanner
        event={event}
        creator={creator}
        invitation={invitation}
      />
    );
  }

  if (invitation.data.response === 'Maybe') {
    return (
      <MaybeBanner
        event={event}
        creator={creator}
        invitation={invitation}
      />
    );
  }

  if (invitation.data.response === 'No') {
    return (
      <NoBanner
        event={event}
        creator={creator}
        invitation={invitation}
      />
    );
  }

  return (
    <PendingBanner
      event={event}
      invitation={invitation}
      creator={creator}
    />
  );
}
