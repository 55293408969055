import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import useOfferTags from '../../../hooks/useOfferTags';
import TagTypes from '../../../constants/TagTypes';
import BaseParticipation from '../../../components_profiles/BaseParticipation';

Participation.propTypes = {
  offer: PropTypes.object.isRequired
};

export default function Participation(props) {
  const { offer } = props;

  const creator = useConnect('user.byId', {
    id: offer.data.creatorId
  });

  const offerInteractionTypes = useOfferTags(offer, TagTypes.InteractionType);

  return (
    <BaseParticipation
      creator={creator}
      interactionTypes={offerInteractionTypes}
    />
  );
}
