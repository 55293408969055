import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

FormTemplate.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  alert: PropTypes.node,
  body: PropTypes.node,
  footer: PropTypes.node,
  onClose: PropTypes.func,
  onPrevious: PropTypes.func,
  showClose: PropTypes.bool
};

FormTemplate.defaultProps = {
  showClose: true
};

export default function FormTemplate(props) {
  const {
    header,
    navigation,
    title,
    subtitle,
    alert,
    body,
    footer,
    onClose,
    onPrevious,
    className,
    showClose
  } = props;

  return (
    <div className={classNames(
      'my-16 lg:my-32x',
      className
    )}>
      {header}
      <div className="px-10 pb-0 md:px-24">
        {navigation && (
          <div className="flex items-center justify-between mb-12">
            {React.cloneElement(navigation, { v2: true })}
          </div>
        )}
        {false && navigation && (
          <div className="flex items-center justify-between mb-12">
            {true ? (
              <div className={classNames(
                'text-cs-orange-500 dialog-previous-button',
                onPrevious ? 'cursor-pointer' : 'invisible',
              )} onClick={onPrevious}>
                <ArrowLeftIcon className="w-8 h-8 text-cs-orange-500 stroke-2" />
              </div>
            ) : (
              <div/>
            )}
            <div>
              {React.cloneElement(navigation, { v2: true })}
            </div>
            <div/>
          </div>
        )}
        {false && navigation}
        {title && (
          <h5 className="text-3xl font-semibold text-cs-gray-900 text-left m-auto">
            {title}
          </h5>
        )}
        {subtitle ? (
          <h6 className="text-lg font-normal mt-2 text-cs-gray-700 text-left">
            {subtitle}
          </h6>
        ) : null}
      </div>
      <div className={classNames(
        (title || subtitle) && 'pt-16',
        'px-10 pb-0 md:px-24'
      )}>
        {body}
      </div>
      {alert}
      {footer ? (
        <div className="flex items-center justify-start pt-16 px-10 md:px-24">
          {onPrevious && (
            <div className="text-cs-orange-500 underline cursor-pointer mr-8 sm:mr-12" onClick={onPrevious}>
              Back
            </div>
          )}
          {footer}
        </div>
      ) : null}
    </div>
  );
}
