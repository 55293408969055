import React from 'react';
import Avatar from '../components/Avatar';
import ContentCard from './ContentCard';
import UserOrganization from '../pages/people/__common/UserOrganization.v2';
import { DialogManager } from '../dialogs_new/DialogManager';
import useDialogLauncher from '../dialogs-routable/_hooks/useDialogLauncher';

export default function BaseCreator(props) {
  const { title, creator } = props;

  const launch = useDialogLauncher();

  function onClick() {
    launch(DialogManager.User.Config(creator.id));
  }

  return (
    <ContentCard className="overflow-hidden">
      <div
        className="relative -m-6 p-6 hover:bg-cs-orange-50 cursor-pointer"
        onClick={onClick}
      >
        <div className="flex space-x-5">
          <Avatar size={20} src={creator.data.avatarUrl} />
          <div className="flex flex-col">
            <div className="text-cs-gray-500">
              {title}
            </div>
            <div className="text-2xl font-semibold text-cs-gray-900">
              {`${creator.data.firstName} ${creator.data.lastName}`.trim()}
            </div>
            <div className="text-base text-cs-gray-500">
              <UserOrganization user={creator} />
            </div>
          </div>
        </div>
      </div>
    </ContentCard>
  );
}
