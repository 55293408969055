import { useConnect } from '@lore/query-connect';

export default function UserRole(props) {
  const { role } = props;

  const site = useConnect('site.byId', {
    id: role.data.siteId
  });

  return [
    role.data.title || '...',
    ' at ',
    site.data.name || '...'
  ].join('');
}
