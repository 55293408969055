import React from 'react';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import { SolidButton } from '../../../components/Button';
import GrayOutlineButton from '../../../components/Button/OutlineButton.gray';
import ComposableOfferHeader from '../../../components_cards/ComposableOfferHeader';
import DividerCard from '../../../components/DividerCard';
import TagRow from '../../../components_cards/tags/TagRow';
import OfferTag from '../../../components_cards/tags/OfferTag';
import ExpiredTag from '../../../components_cards/tags/_resource/ExpiredTag';
import CreatorTag from '../../../components_cards/tags/CreatorTag';
import OfferMissingFieldsTag from '../../../components_cards/ComposableOfferHeader/OfferMissingFieldsTag';
import Claims from '../../my-past/OfferCreated/Claims';

export default function ActiveOffer(props) {
  const { offer } = props;

  const launch = useDialogLauncher();

  function onClickView() {
    launch(DialogManager.OfferManage.Config(offer.id, `/view`));
  }

  function onClickEdit() {
    launch(DialogManager.OfferManage.Config(offer.id, `/edit`));
  }

  function onClickInvite() {
    launch(DialogManager.OfferManage.Config(offer.id, `/invite-populate`));
  }

  function onClickManage() {
    launch(DialogManager.OfferManage.Config(offer.id, `/manage`));
  }

  function onClickClose() {
    launch(DialogManager.OfferCancel.Config(offer.id));
  }

  return (
    <DividerCard>
      <ComposableOfferHeader
        offer={offer}
        tags={(
          <>
            <TagRow>
              <OfferTag />
              {/*<CreatorTag />*/}
              {/*<OfferMissingFieldsTag offer={offer} />*/}
              {/*<ExpiredTag resource={offer} />*/}
            </TagRow>
            <Claims offer={offer} />
          </>
        )}
        showTags={false}
        showCreator={false}
      />
      <div className="w-full bg-cs-gray-50 py-4 px-4 flex justify-between items-center flex-wrap gap-2">
        <div className="flex flex-wrap gap-2">
          {/*<GrayOutlineButton*/}
          {/*  onClick={onClickView}*/}
          {/*  label="View offer"*/}
          {/*/>*/}
          {/*<GrayOutlineButton*/}
          {/*  onClick={onClickEdit}*/}
          {/*  label="Edit offer"*/}
          {/*/>*/}
        </div>
        <div className="flex flex-wrap gap-2">
          <GrayOutlineButton
            onClick={onClickClose}
            label="Cancel offer"
          />
          {/*<GrayOutlineButton*/}
          {/*  onClick={onClickInvite}*/}
          {/*  label="Invite educators"*/}
          {/*/>*/}
          <SolidButton
            onClick={onClickManage}
            label="Manage educators"
          />
        </div>
      </div>
    </DividerCard>
  );
}
