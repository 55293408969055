import React from 'react';
import useUserProfileMissingFields from '../../../hooks/useUserProfileMissingFields';
import Loader from '../../../components/Loader';
import classNames from 'classnames';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';

export default function ConnectButton(props) {
  const { children, onClick, disabled = false } = props;

  const missingFields = useUserProfileMissingFields();

  const launch = useDialogLauncher();

  if (!missingFields) {
    return (
      <Loader />
    );
  }

  const fieldCount = missingFields.profile.length + missingFields.interests.length;

  function onCompleteProfile() {
    launch(DialogManager.ProfileMissing.Config());
  }

  const className = classNames(
    'bg-white text-cs-blue-500 font-semibold uppercase rounded-lg text-center py-3 px-3 w-56',
    disabled && 'opacity-80',
    !disabled && 'cursor-pointer hover:bg-cs-blue-50 shadow-lg'
  )

  if (disabled) {
    return (
      <div className={className}>
        {children}
      </div>
    )
  }

  if (fieldCount > 0) {
    return (
      <div className={className} onClick={onCompleteProfile}>
        {children}
      </div>
    );
  }

  return (
    <div className={className} onClick={onClick}>
      {children}
    </div>
  );
};
