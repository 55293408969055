import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import { Link } from 'react-router-dom';
import Urls from '../Urls';
import SolidButton from '../../../components/Button/SolidButton';
import BubbleLayout from '../BubbleLayout';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { location, history, match } = useRouter();
  const offer = useConnect('offer.byId', {
    id: Number(match.params.offerId)
  })

  if (offer.state === PayloadStates.FETCHING) {
    return null;
  }

  return (
    <BubbleLayout
      offer={offer}
      page="Search Explanation"
    >
      <div className="px-10 pt-32 pb-32 max-w-screen-lg m-auto">
        <div className="text-3xl font-semibold text-cs-gray-900 text-center mb-4">
          What's next?
        </div>
        <div className="text-base text-cs-gray-700 text-center space-y-2 mb-12">
          <div>
            Now that your offer is live, educators can find your offer and express
            interest. You'll get an email when this happens. You can also jumpstart
            the process by inviting educators to take you up on your offer.
          </div>
          <div>
            The next screen will show you a list of educators in your area that match the
            subjects you provided. You can expand or contract your list by adjusting the filters.
          </div>
          <div>
            Thanks again for sharing your
            offer with our community!
          </div>
        </div>
        <div className="flex flex-col items-center space-y-6 text-right">
          <SolidButton onClick={() => history.push(Urls(offer).OFFER_INVITE)}>
            Next
          </SolidButton>
          <Link className="text-lg text-cs-orange-500 hover:text-cs-yellow-500 font-semibold" to={Urls(offer).WEBSITE}>
            Back
          </Link>
        </div>
      </div>
    </BubbleLayout>
  );
};
