import React from 'react';
import _ from 'lodash';
import { DocumentDuplicateIcon, EyeIcon } from '@heroicons/react/24/outline';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../_hooks/useDialogLauncher';
import ActionBarMenuBase from '../../_components_action_bar/ActionBarMenuBase';

export default function ActionBarMenu(props) {
  const { project } = props;

  const launch = useDialogLauncher();

  function onChangeVisibility() {
    launch(DialogManager.ProjectVisibility.Config(project.id));
  }

  function onDuplicate() {
    launch(DialogManager.ProjectDuplicate.Config(project.id));
  }

  const links = _.remove([
    {
      type: 'button',
      icon: DocumentDuplicateIcon,
      label: 'Duplicate Project',
      onClick: onDuplicate,
      active: false
    },
    {
      type: 'button',
      icon: EyeIcon,
      label: project.data.published ?
        'Set Project Visibility' :
        'Set Project Visibility',
      onClick: onChangeVisibility,
      active: project.data.published,
      activeColor: 'green'
    }
  ], entry => entry);

  return (
    <ActionBarMenuBase links={links} />
  );
};
