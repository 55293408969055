import React from 'react';
import { CalendarIcon } from '@heroicons/react/24/outline';
import { formatDate, formatDateRange } from '../../../../utils/dateTimeUtils';
import IconSection from '../../../_new_card_components/IconSection';

export default function Date(props) {
  const { event } = props;

  return (
    <IconSection
      icon={CalendarIcon}
      title={event.data.specificDate === 'Yes' ? (
        <span>
          {formatDate(event.data.date)}
        </span>
      ) : (
        <span>
          {formatDateRange(event.data.startDate, event.data.endDate)}
        </span>
      )}
      tooltip="Date"
    />
  );
};
