import React from 'react';
import PropTypes from 'prop-types';
import SolidButton from '../components/Button/SolidButton';
import FormTemplate from './FormTemplate';

Blocker.propTypes = {
  title: PropTypes.node.isRequired,
  subtitle: PropTypes.node.isRequired,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

export default function Blocker(props) {
  const {
    title,
    subtitle,
    onSubmit,
    onCancel
  } = props;

  return (
    <FormTemplate
      title={title}
      onClose={onCancel}
      body={(
        <div className="text-center">
          {subtitle}
        </div>
      )}
      footer={(
        <div className="text-center m-auto">
          <SolidButton onClick={onSubmit}>
            Complete your profile
          </SolidButton>
          <div className="text-cs-orange-500 underline cursor-pointer mt-8" onClick={onCancel}>
            I'll do this later
          </div>
        </div>
      )}
    />
  );
}
