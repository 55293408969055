import React from 'react';
import ActiveBanner from './ActiveBanner';
import { useConnect } from '../../../../hooks/@lore/query-connect';
import useMissingFields from '../../../pages/project-manage/useMissingFields';
import DraftBanner from './DraftBanner';
import CompletedBanner from './CompletedBanner';
import CancelledBanner from './CancelledBanner';
import ExpiredBanner from './ExpiredBanner';
import { DateTime } from 'luxon';
import { getTimestampDiff } from '../../../utils/dateTimeUtils';

export default function Banner(props) {
  const { project } = props;

  const { missingFields } = useMissingFields(project);

  const creator = useConnect('user.byId', {
    id: project.data.creatorId
  });

  // return (
  //   <>
  //     <ActiveBanner
  //       project={project}
  //       creator={creator}
  //     />
  //     <DraftBanner
  //       project={project}
  //       creator={creator}
  //     />
  //     <CompletedBanner
  //       project={project}
  //       creator={creator}
  //     />
  //     <CancelledBanner
  //       project={project}
  //       creator={creator}
  //     />
  //     <ExpiredBanner
  //       project={project}
  //       creator={creator}
  //     />
  //   </>
  // );

  if (project.data.completed) {
    return (
      <CompletedBanner
        project={project}
        creator={creator}
      />
    );
  }

  if (project.data.cancelled) {
    return (
      <CancelledBanner
        project={project}
        creator={creator}
      />
    );
  }

  if (missingFields.data.length > 0) {
    return (
      <DraftBanner
        project={project}
        creator={creator}
      />
    );
  }

  const diff = getTimestampDiff(DateTime.now().toISO(), project.data.endDate);

  if (diff > 0) {
    return (
      <ExpiredBanner
        project={project}
        creator={creator}
      />
    );
  }

  return (
    <ActiveBanner
      project={project}
      creator={creator}
    />
  );
};
