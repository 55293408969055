import getQueryUtils from './queryUtils';

export default function useStewardshipFilter(search = {}) {
  const queryUtils = getQueryUtils(search, [
    'networks'
  ]);

  const values = queryUtils.params.networks;

  if (values.length > 0) {
    return {
      $or: [
        {
          'project.networkId': {
            $in: values
          }
        },
        {
          'event.networkId': {
            $in: values
          }
        }
      ]
    };
  }
};
