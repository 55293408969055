import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import TagTypes from '../../../constants/TagTypes';
import useEventTags from '../../../hooks/useEventTags';
import BaseParticipation from '../../../components_profiles/BaseParticipation';

Participation.propTypes = {
  event: PropTypes.object.isRequired
};

export default function Participation(props) {
  const { event } = props;

  const creator = useConnect('user.byId', {
    id: event.data.creatorId
  });

  const eventInteractionTypes = useEventTags(event, TagTypes.InteractionType);

  return (
    <BaseParticipation
      creator={creator}
      interactionTypes={eventInteractionTypes}
    />
  );
}
