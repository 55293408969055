import React, { useState } from 'react';
import { BriefcaseIcon } from '@heroicons/react/24/outline';
import useRouter from '../../hooks/useRouter';
import Urls from '../event-create/Urls';
import DataCard from '../profile-edit/DataCard';
import { PayloadStates } from '@lore/utils';
import useEventTags from '../../hooks/useEventTags';
import TagTypes from '../../constants/TagTypes';
import usePageRouter from '../../dialogs-routable/_hooks/usePageRouter';

export default function Skills(props) {
  const { event } = props;

  const { history } = usePageRouter();

  const [timestamp] = useState(new Date().toISOString());

  const eventExpertiseTypes = useEventTags(event, TagTypes.ExpertiseType, timestamp);

  let subtitle = '';

  if (eventExpertiseTypes.state === PayloadStates.FETCHING) {
    subtitle = '...';
  } else {
    subtitle = eventExpertiseTypes.data.map(function(eventExpertiseType) {
      return eventExpertiseType.data.name;
    }).join(', ');
  }

  return (
    <DataCard
      title="Partner Career and Life Experience"
      icon={BriefcaseIcon}
      subtitle={subtitle || '(not provided)'}
      onClick={() => history.push(Urls(event).PARTNER)}
    />
  );
};
