import React, { useState } from 'react';
import { parse, stringify } from 'query-string';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import CloseDialogButton from '../../dialogs_common/CloseDialogButton';
import TagTypes from '../../constants/TagTypes';
import DisclosureTagFilter from '../../forms/_fields_v2/DisclosureTagFilter';
import SearchTags from '../../components/SearchTags';
import ClearAllFilters from '../../components/ClearAllFilters';
import { getSearchUtils } from '../../utils/query-utils';
import useQuery from '../../pages/stories/useQuery';
import useFilterCount from '../../hooks/useFilterCount';
import SolidButton from '../../components/Button/SolidButton';
import { attributeKeys } from '../../pages/stories/queryUtils';
import SearchFilter from '../../forms/_fields_v2/SearchFilter';
import DisclosureFilter from '../../forms/_fields_v2/DisclosureFilter';
import _ from 'lodash';

export default function StoryFiltersDialog(props) {
  const { router } = props;

  const { location, history } = router;

  const [search, setSearch] = useState(parse(location.search));

  const filterFields = {
    search: 'search',
    ages: 'ages',
    grades: 'grades',
    interactionTypes: 'interactionTypes',
    activities: 'activities',
    subjects: 'subjects'
  };

  const filterCount = useFilterCount(search, _.keys(filterFields));

  function onSubmit() {
    history.push({
      pathname: location.pathname,
      search: stringify({
        ...search,
        page: 1
      })
    });
    props.onCancel();
  }

  function onChange(nextSearch) {
    setSearch(nextSearch);
  }

  function getTagParams(tag) {
    return {
      where: {
        eager: {
          $where: {
            'tagNodes.tagId': tag.id
          }
        }
      },
      pagination: {
        pageSize: 1
      }
    };
  }

  const searchUtils = getSearchUtils(search, onChange);

  const result = useQuery({
    search
  });

  return (
    <DialogTemplate className="w-screen max-w-screen-sm h-[calc(100dvh-32px)] max-h-[calc(720px)]">
      <CloseDialogButton onCancel={props.onCancel} />
      <div className="flex flex-col space-y-8x h-full">
        <div className="space-y-1 p-6 border-b border-cs-gray-200">
          <h2 className="text-cs-gray-900 text-2xl font-semibold">
            Filters
          </h2>
          <div className="text-cs-gray-500 text-sm">
            Narrow your search results.
          </div>
          {/*<div>*/}
          {/*  <SearchTags*/}
          {/*    search={search}*/}
          {/*    onChange={setSearch}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
        <div className="px-6 py-8 space-y-8 overflow-y-auto h-full">
          <div className="space-y-4">
            <DisclosureFilter
              label="Search"
              value={[
                searchUtils(filterFields.search).value
              ]}
            >
              <SearchFilter
                label="Description"
                description="Find impact stories with a description that contains the phrase you type."
                value={searchUtils(filterFields.search).value}
                onChange={searchUtils(filterFields.search).onChangeValue}
              />
            </DisclosureFilter>
          </div>
          {/*<Divider />*/}
          <DisclosureTagFilter
            modelName="story"
            type={TagTypes.Age}
            label="Age group"
            getParams={getTagParams}
            value={searchUtils(filterFields.ages).value}
            onChange={searchUtils(filterFields.ages).onChangeArray}
          />
          <DisclosureTagFilter
            modelName="story"
            type={TagTypes.Grade}
            label="Grade levels"
            getParams={getTagParams}
            value={searchUtils(filterFields.grades).value}
            onChange={searchUtils(filterFields.grades).onChangeArray}
          />
          <DisclosureTagFilter
            modelName="story"
            type={TagTypes.InteractionType}
            label="Location Type"
            getParams={getTagParams}
            value={searchUtils(filterFields.interactionTypes).value}
            onChange={searchUtils(filterFields.interactionTypes).onChangeArray}
          />
          <DisclosureTagFilter
            modelName="story"
            type={TagTypes.Activity}
            label="Activities"
            getParams={getTagParams}
            value={searchUtils(filterFields.activities).value}
            onChange={searchUtils(filterFields.activities).onChangeArray}
          />
          <DisclosureTagFilter
            modelName="story"
            type={TagTypes.Subject}
            label="Subject areas"
            getParams={getTagParams}
            value={searchUtils(filterFields.subjects).value}
            onChange={searchUtils(filterFields.subjects).onChangeArray}
          />
        </div>
        <div className="p-6 flex items-center justify-between border-t border-cs-gray-200">
          <ClearAllFilters
            show={true}
            className="hover:opacity-70"
            onClick={() => setSearch(_.omit(search, _.keys(filterFields)))}
            count={filterCount}
          />
          <SolidButton onClick={onSubmit}>
            Show {result.meta?.totalCount} results
          </SolidButton>
        </div>
      </div>
    </DialogTemplate>
  );
};
