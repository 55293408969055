import React from 'react';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import ParentLayout from '../Layout';
import Navigation from '../../../dialogs_common/Navigation';
import Urls from '../Urls';
import Form from './Form';
import { PayloadStates } from '@lore/utils';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { location, history, match } = useRouter();
  const user = useUser();
  const project = useConnect('project.byId', {
    id: Number(match.params.projectId)
  });

  if (project.state === PayloadStates.FETCHING) {
    return null;
  }

  return (
    <ParentLayout
      project={project}
      page="Partner"
      title="What career experience would you like your potential partners to bring to your students and/or the project?"
      description={(
        <>
          <span className="text-bold italic">TIP</span>: Your project will appear in search results for any that you choose, so select all that could help.
        </>
      )}
    >
      <Form
        user={user}
        project={project}
        navigation={(
          <Navigation v5={true} activeStep={10} steps={19}/>
        )}
        onPrevious={(newData) => history.push(Urls(project).SUBJECTS)}
        onNext={(newData) => history.push(Urls(project).DATE)}
        onSkip={() => history.push(Urls(project).DATE)}
        onExit={() => history.push(Urls(project).PROJECT_EDIT)}
      />
    </ParentLayout>
  );
};
