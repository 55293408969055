import React from 'react';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import SectionHeader from '../../../components_profiles/SectionHeader';
import MatchingProjectsEvents from './MatchingProjectsEvents';
import MatchingOffers from './MatchingOffers';
import NearbyProjectsEvents from './NearbyProjectsEvents';
import useHasChildren from '../../../components_cards/useHasChildren';
import EmptyState, { EmptyStateActions } from '../../../components/EmptyState';
import useRouter from '../../../hooks/useRouter';
import SpanLink from './SpanLink';
import { LinkButton, OutlineButton } from '../../../components/Button';

export default function Recommended(props) {
  const cards = [
    <MatchingProjectsEvents key="matching-projects-events" />,
    // <MatchingProjects />,
    // <MatchingEvents />,
    <MatchingOffers key="matching-offers" />,
    <NearbyProjectsEvents key="nearby-projects-events" />
  ];

  const hasChildren = useHasChildren(cards);
  const { history } = useRouter();

  function onEditProfile() {
    history.push('/profile/edit');
  }

  // if (!hasChildren) {
  //   return null;
  // }

  return (
    <div className="space-y-4">
      <SectionHeader
        title="Recommended just for you"
        subtitle={(
          <>
            There are many exciting ways to get involved - here are a few opportunities
            we think match your interests and experiences.
          </>
        )}
      />
      {hasChildren ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4">
          {cards}
        </div>
      ) : (
        <EmptyState
          icon={UserCircleIcon}
          title="Recommendations coming soon!"
          subtitle={(
            <>
              Exciting activities are created all the time, so be sure to check back
              regularly for new recommendations. In the meantime, you can update your
              profile so we can connect you to more relevant opportunities.
            </>
          )}
        >
          <EmptyStateActions>
            <LinkButton
              button={OutlineButton}
              to={`/profile/edit`}
              label="Update profile"
            />
          </EmptyStateActions>
        </EmptyState>
      )}
    </div>
  );
}
