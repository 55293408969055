import React from 'react';
import { DocumentTextIcon } from '@heroicons/react/24/outline';
import useRouter from '../../hooks/useRouter';
import Urls from '../profile/Urls';
import DataCard from './DataCard';

export default function About(props) {
  const { user } = props;

  const { history } = useRouter();

  return (
    <DataCard
      title="About"
      icon={DocumentTextIcon}
      subtitle={user.data.biography || '(not provided)'}
      onClick={() => history.push(Urls(user).ABOUT)}
    />
  );
};
