import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import LoadingBanner from '../../../pages/_banner/LoadingBanner';
import QuestionBanner from '../../question_manage/Banner';
import CreatorBanner from './CreatorBanner';
import DiscoverBanner from './DiscoverBanner';
import CancelledBanner from './CancelledBanner';
import CompletedBanner from './CompletedBanner';

Banner.propTypes = {
  project: PropTypes.object.isRequired
};

export default function Banner(props) {
  const { project } = props;

  const currentUser = useUser();

  const creator = useConnect('user.byId', {
    id: project.data.creatorId
  });

  const activity = useConnect('tag.byId', {
    id: project.data.activityId
  });

  const question = useConnect('question.first', {
    where: {
      eager: {
        $where: {
          projectId: project.id,
          userId: currentUser.id
        }
      }
    }
  });

  if (
    creator.state === PayloadStates.FETCHING ||
    activity.state === PayloadStates.FETCHING ||
    question.state === PayloadStates.FETCHING
  ) {
    return (
      <LoadingBanner creator={creator} />
    );
  }

  const isCreator = currentUser.id === project.data.creatorId;

  // return (
  //   <>
  //     <CreatorBanner
  //       project={project}
  //       creator={creator}
  //     />
  //     <DiscoverBanner
  //       project={project}
  //       creator={creator}
  //     />
  //     <QuestionBanner
  //       question={question}
  //       project={project}
  //       creator={creator}
  //     />
  //     <CancelledBanner
  //       project={project}
  //       creator={creator}
  //     />
  //     <CompletedBanner
  //       project={project}
  //       creator={creator}
  //     />
  //   </>
  // );

  if (isCreator) {
    return (
      <CreatorBanner
        project={project}
        creator={creator}
      />
    );
  }

  if (question.state === PayloadStates.RESOLVED) {
    return (
      <QuestionBanner
        question={question}
        project={project}
      />
    );
  }

  if (project.data.completed) {
    return (
      <CompletedBanner
        project={project}
        creator={creator}
      />
    );
  }

  if (project.data.cancelled) {
    return (
      <CancelledBanner
        project={project}
        creator={creator}
      />
    );
  }

  return (
    <DiscoverBanner
      project={project}
      creator={creator}
    />
  );
}
