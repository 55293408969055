import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import SectionTitle from '../project-manage/SectionTitle';
import Question from '../project-manage/Question';

export default function QuestionsContributed(props) {
  const { project, onViewMessages } = props;

  const questions = useConnect('question.find', {
    where: {
      eager: {
        $where: {
          projectId: project.id,
          response: 'Connect',
          result: 'Contributed'
        }
      }
    },
    pagination: {
      pageSize: 100
    }
  });

  if (questions.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  if (questions.data.length === 0) {
    return null;
  }

  return (
    <SectionTitle
      title="Contributing Partners"
      subtitle="These partners offered you advice on a project idea, shared resources, or referred you to another person or organization."
    >
      <div className="grid grid-cols-1 gap-4">
        {questions.data.map(function (question, index) {
          return (
            <Question
              key={question.id || question.cid}
              question={question}
              onViewMessages={onViewMessages}
            />
          );
        })}
      </div>
    </SectionTitle>
  );
};
