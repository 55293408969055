import React from 'react';

export default function Description(props) {
  const { project } = props;

  if (!_.trim(project.data.interactionDescription)) {
    return null;
  }

  return (
    <div className="text-base text-cs-gray-500 line-clamp-2">
      {project.data.interactionDescription}
    </div>
  );
}
