import React from 'react';
import { useConnect } from '@lore/query-connect';

export default function Title(props) {
  const { event } = props;

  const activity = useConnect('tag.byId', {
    id: event.data.activityId
  });

  return (
    <div className="flex flex-col space-y-2">
      <div className="text-xl font-semibold text-cs-gray-900">
        {event.data.title || activity.data.name}
      </div>
    </div>
  );
}
