import React from 'react';
import ChildLayout from '../user/Layout'
import { parse } from 'query-string';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import { Helmet } from 'react-helmet-async';
import useRouter from '../../hooks/useRouter';

export default function Layout(props) {
  const { location, history, match } = useRouter();
  const query = parse(location.search);

  const offer = useConnect('offer.byId', {
    id: Number(match.params.offerId)
  })

  if (offer.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <>
      <Helmet>
        <title>Offer #{`${offer.id}`} - Invite Educator</title>
      </Helmet>
      <ChildLayout
        {...props}
        offer={offer}
        header={false}
      />
    </>
  );
};
