import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import FormTemplate from '../../../dialogs_common/SetupTemplate';
import PrimaryButton from '../../../components_setup/PrimarySetupButton';
import Header from './Header';
import Roles from './Roles';
import EmptyState from '../../../components/EmptyState/EmptyState.legacy';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';

Form.propTypes = {
  user: PropTypes.object.isRequired,
  role: PropTypes.object,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  onSkip: PropTypes.func
};

export default function Form(props) {
  const {
    user,
    roles,
    navigation,
    onNext,
    onPrevious,
    onSkip
  } = props;

  const launch = useDialogLauncher();

  function onCreateSite() {
    launch(DialogManager.SiteCreate.Config(user.id));
  }

  const [data, setData] = useState({
    // nothing
  });
  const [showErrors, setShowErrors] = useState(false);

  function onSubmit() {
    onNext(data);
  }

  return (
    <div className="my-auto">
      <Overlay isVisible={false}>
        <FormErrors data={data} validators={{
          // name: [validators.isRequired]
        }}>
          {({ errors, hasError }) => {
            return (
              <FormTemplate
                onPrevious={onPrevious}
                navigation={navigation}
                // title="What's your school or organization?"
                // subtitle="Locations you create are visible only to you"
                body={(
                  <>
                    <Header />
                    <Roles roles={roles}>
                      <div className="hover:shadow-cs-flat hover:opacity-80 cursor-pointer" onClick={onCreateSite}>
                        <EmptyState
                          title="Add school/site"
                        />
                      </div>
                    </Roles>
                  </>
                )}
                footer={(
                  <>
                    {/*<div className="text-cs-orange-500 underline cursor-pointer mt-6 sm:mt-0 mr-0 sm:mr-12" onClick={onSkip}>*/}
                    {/*  Skip this step*/}
                    {/*</div>*/}
                    <PrimaryButton disabled={hasError} onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
                      Next
                    </PrimaryButton>
                  </>
                )}
              />
            );
          }}
        </FormErrors>
      </Overlay>
    </div>
  );
};
