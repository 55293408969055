import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import FocusAreaEntry from '../../components/FocusAreaEntry';

Entries.propTypes = {
  tags: PropTypes.object.isRequired,
  nodes: PropTypes.object.isRequired,
  onSelectTag: PropTypes.func.isRequired
};

export default function Entries(props) {
  const {
    tags,
    nodes,
    onSelectTag,
    children
  } = props;

  return tags.data.map(function(tag) {
    const selectedNode = _.find(nodes.data, function(node) {
      return tag.id === node._tag.id;
    });

    const selected = !!selectedNode;

    if (tag.data.name === 'Other') {
      return null;
    }

    // children prop is a customer renderer
    if (children) {
      return children({
        tag,
        selected,
        onClick: () => onSelectTag(tag, selectedNode)
      });
    }

    return (
      <FocusAreaEntry
        key={tag.id}
        entry={tag}
        // subtitle={`${tag.data.description}`}
        selectable={true}
        selected={selected}
        onClick={() => onSelectTag(tag, selectedNode)}
      />
    );
  });
}
