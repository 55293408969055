import React from 'react';
import _ from 'lodash';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import ParentLayout from '../Layout';
import Navigation from '../../../dialogs_common/Navigation';
import Urls from '../Urls';
import Form from './Form';
import useProjectTags from '../../../hooks/useProjectTags';
import TagTypes from '../../../constants/TagTypes';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { history, match } = useRouter();
  const user = useUser();
  const project = useConnect('project.byId', {
    id: Number(match.params.projectId)
  });

  const projectParticipants = useProjectTags(project, TagTypes.Participant);

  if (
    project.state === PayloadStates.FETCHING ||
    projectParticipants.state === PayloadStates.FETCHING
  ) {
    return null;
  }

  return (
    <ParentLayout
      project={project}
      page="Participants"
      title="Who will partners primarily work with on this project?"
      description={(
        <>
          <span className="font-semibold italic">TIP</span>: Choose Educator if they will ONLY be
          working directly with you  or colleagues and there are no students involved.
        </>
      )}
    >
      <Form
        user={user}
        project={project}
        navigation={(
          <Navigation v5={true} activeStep={5} steps={19}/>
        )}
        onPrevious={(newData) => history.push(Urls(project).SUMMARY)}
        onNext={(newData) => {
          _.find(projectParticipants.data, m => m.data.name === 'Students') ?
            history.push(Urls(project).STUDENT_COUNT) :
            history.push(Urls(project).SUBJECTS);
        }}
        onSkip={(newData) => {
          _.find(projectParticipants.data, m => m.data.name === 'Students') ?
            history.push(Urls(project).STUDENT_COUNT) :
            history.push(Urls(project).SUBJECTS);
        }}
        onExit={() => history.push(Urls(project).PROJECT_EDIT)}
      />
    </ParentLayout>
  );
};
