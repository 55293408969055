import React from 'react';
import { BuildingOfficeIcon } from '@heroicons/react/24/outline';
import { useConnect } from '@lore/query-connect';
import IconSection from '../../../_new_card_components/IconSection';

export default function Site(props) {
  const { event } = props;

  const site = useConnect('site.byId', {
    id: event.data.siteId
  }, {
    enabled: !!event.data.siteId
  });

  return (
    <IconSection
      icon={BuildingOfficeIcon}
      title={site.data.name || '[missing]'}
      tooltip="Site"
    />
  );
};
