import React from 'react';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import { useUser } from '@lore/auth';
import { CalendarIcon } from '@heroicons/react/24/outline';
import ContentCard from '../../../components_profiles/ContentCard';
import useTags from '../../../hooks/useTags';
import TagTypes from '../../../constants/TagTypes';
import useEventTags from '../../../hooks/useEventTags';
import { formatDate, formatDateRange, formatTimeRange } from '../../../utils/dateTimeUtils';
import SubSectionHeader from '../../../components_profiles/SubSectionHeader';
import TextContentSection from '../../../components_profiles/TextContentSection';

export default function Commitment(props) {
  const { event } = props;

  const currentUser = useUser();

  const days = useTags(TagTypes.Day);
  const eventDays = useEventTags(event, TagTypes.Day);

  const times = useTags(TagTypes.Time);
  const eventTimes = useEventTags(event, TagTypes.Time);

  if (
    days.state === PayloadStates.FETCHING ||
    eventDays.state === PayloadStates.FETCHING ||
    times.state === PayloadStates.FETCHING ||
    eventTimes.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  return (
    <ContentCard className="space-y-8">
      <SubSectionHeader
        icon={CalendarIcon}
        title="Date & Time"
      />
      {event.data.specificDate === 'Yes' ? (
        <TextContentSection title="Date">
          {formatDate(event.data.date)}
        </TextContentSection>
      ) : (
        <>
          <TextContentSection title="Date Window">
            {formatDateRange(event.data.startDate, event.data.endDate)}
          </TextContentSection>
          {eventDays.data.length > 0 && (
            <TextContentSection title="Possible Days">
              <ul className="list-disc ml-6">
                {eventDays.data.map(function(eventDay) {
                  return (
                    <li key={eventDay.id || eventDay.cid}>
                      {eventDay.data.name}
                    </li>
                  );
                })}
              </ul>
            </TextContentSection>
          )}
        </>
      )}
      {event.data.specificTime === 'Yes' ? (
        <TextContentSection title="Time">
          {formatTimeRange(event.data.startTime, event.data.endTime, currentUser.data.timezone)}
        </TextContentSection>
      ) : (
        <TextContentSection title="Possible Times of Day">
          <ul className="list-disc ml-6">
            {eventTimes.data.map(function(eventTime) {
              return (
                <li key={eventTime.id || eventTime.cid}>
                  {eventTime.data.name}
                </li>
              );
            })}
          </ul>
        </TextContentSection>
      )}
    </ContentCard>
  );
}
