import React from 'react';
import _ from 'lodash';
import RemoveLoadingScreen from '../RemoveLoadingScreen';
import ScrollToTop from '../../components/ScrollToTop';
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/logo.png';
import { Helmet } from 'react-helmet-async';
import FormSection from '../_post-opportunity/forms/FormSection';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

export default function Layout(props) {
  const {
    event,
    page,
    title,
    description,
    navigation,
    onPrevious,
    onNext,
    onSkip,
    children,
    examples
  } = props;

  return (
    <div className="flex flex-col lg:flex-row h-100 lg:h-screen">
      {event && (
        <Helmet>
          <title>Event #{`${event.id}`}: {page}</title>
        </Helmet>
      )}
      <RemoveLoadingScreen/>
      <div className="w-full lg:w-1/2 bg-gradient-to-b from-cs-yellow-500 to-cs-orange-500 overflow-y-auto relative">
        <Link className="cursor-pointer absolute top-5 left-6" to="/">
          <img className="h-16" src={logo} alt="logo"/>
        </Link>
        <div className="px-32 pt-32 space-y-2 pb-12">
          <h1 className="text-3xl font-semibold text-white">
            {title}
          </h1>
          <div className="text-lg text-white">
            {description}
          </div>
          {examples && (
            <FormSection
              white={true}
              title="Examples"
              tooltip={examples}
            />
          )}
        </div>
      </div>
      <div className="w-full lg:w-1/2 overflow-y-auto relative">
        <ScrollToTop/>
        {_.isFunction(children) ?
          children({
            onPrevious,
            onNext,
            onSkip
          }) :
          children
        }
        {/*<div className="bg-cs-yellow-500 fixed top-0 bottom-0 left-0 right-0 -z-1" />*/}
        {(onPrevious || navigation) && (
          <div className="fixed bottom-0 right-0 w-1/2">
            <div className="bg-cs-gray-200 shadow-md relative">
              <div className="relative pt-12 px-6 pb-1">
                <div className="">
                  {onPrevious ? (
                    <div className="absolute p-4 -m-4 -mt-6 text-cs-orange-500 cursor-pointer" onClick={onPrevious}>
                      <ArrowLeftIcon className="w-8 h-8 text-cs-orange-500 stroke-2" />
                    </div>
                  ) : null}
                  {navigation}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
