import React from 'react';
import useHasChildren from '../useHasChildren';

export default function TagRow(props) {
  const { children, hideIfNoChildren = true } = props;

  const hasChildren = useHasChildren(children);

  if (hideIfNoChildren && !hasChildren) {
    return null;
  }

  return (
    <div className="flex flex-wrap gap-x-4 gap-y-2">
      {children}
    </div>
  );
}
