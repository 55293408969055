import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormField from '../../forms/_common/FormField';
import { OutlineButton, SolidButton } from '../../components/Button';
import StringField from '../../forms/_fields_v2/StringField';
import FormTemplate from '../../dialogs_common/FormTemplate';
import LocationSearchField from './LocationSearchField';
import SelectField from '../../forms/_fields_v2/SelectField';
import { useUser } from '@lore/auth';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';

Form.propTypes = {
  user: PropTypes.object.isRequired,
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

Form.defaultProps = {
  data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    user,
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onChangeMultiple,
    onSubmit,
    onCancel
  } = props;

  const currentUser = useUser();

  const [isAutocomplete, setIsAutocomplete] = useState(true);

  return (
    <FormTemplate
      title="Add organization"
      // subtitle="Locations you create are visible only to you"
      onClose={onCancel}
      alert={alert}
      body={(
        <div className="space-y-6">
          {isAutocomplete ? (
            <LocationSearchField
              label="Organization Name"
              displayMap={false}
              address={data.name}
              error={showErrors && errors.name ?
                `Your organization could not be found. Please click 'Add it manually' if it does not appear in the dropdown.` :
                undefined
              }
              description={(
                <>
                  Can't find your school or organization? <span className="text-cs-orange-500 cursor-pointer underline hover:opacity-80" onClick={() => setIsAutocomplete(false)}>Add it manually</span>.
                </>
              )}
              onChange={function(addressLatitudeLongitude, place, placeComponents) {
                onChangeMultiple(placeComponents);
                // onChange('place_id', placeComponents.place_id);
                // onChange('name', placeComponents.name);
                // onChange('addressLine1', placeComponents.addressLine1);
                // onChange('addressLine2', placeComponents.addressLine2);
                // onChange('addressLine3', placeComponents.addressLine3);
                // onChange('city', placeComponents.city);
                // onChange('state', placeComponents.state);
                // onChange('zipCode', placeComponents.zipCode);
                // onChangeLocationCondition(_.extend({}, locationCondition, addressLatitudeLongitude))
              }}
            />
          ) : (
            <>
              <FormField name="name" data={data} errors={showErrors && errors} onChange={onChange}>
                {(field) => (
                  <StringField
                    label="Organization Name"
                    // description="Example: Grand Canyon"
                    value={field.value}
                    error={field.error}
                    onChange={field.onChange}
                  />
                )}
              </FormField>
              <FormField name="addressLine1" data={data} errors={showErrors && errors} onChange={onChange}>
                {(field) => (
                  <StringField
                    label="Address Line 1"
                    // description="Example: 9 Village Loop Drive"
                    value={field.value}
                    error={field.error}
                    onChange={field.onChange}
                  />
                )}
              </FormField>
              <FormField name="addressLine2" data={data} errors={showErrors && errors} onChange={onChange}>
                {(field) => (
                  <StringField
                    label="Address Line 2"
                    value={field.value}
                    error={field.error}
                    onChange={field.onChange}
                  />
                )}
              </FormField>
              <FormField name="addressLine3" data={data} errors={showErrors && errors} onChange={onChange}>
                {(field) => (
                  <StringField
                    label="Address Line 3"
                    value={field.value}
                    error={field.error}
                    onChange={field.onChange}
                  />
                )}
              </FormField>
              <FormField name="city" data={data} errors={showErrors && errors} onChange={onChange}>
                {(field) => (
                  <StringField
                    label="City"
                    // description="Example: Grand Canyon Village"
                    value={field.value}
                    error={field.error}
                    onChange={field.onChange}
                  />
                )}
              </FormField>
              <FormField name="state" data={data} errors={showErrors && errors} onChange={onChange}>
                {(field) => (
                  <StringField
                    label="State"
                    // description="Example: AZ"
                    value={field.value}
                    error={field.error}
                    onChange={field.onChange}
                  />
                )}
              </FormField>
              <FormField name="zipCode" data={data} errors={showErrors && errors} onChange={onChange}>
                {(field) => (
                  <StringField
                    label="ZIP Code"
                    // description="Example: 86023"
                    value={field.value}
                    error={field.error}
                    onChange={field.onChange}
                  />
                )}
              </FormField>
            </>
          )}
          <FormField name="title" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <StringField
                label="Position/Title"
                value={field.value}
                error={field.error}
                onChange={field.onChange}
              />
            )}
          </FormField>
          <FormField name="type" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <SelectField
                label="Type of organization"
                value={field.value}
                error={field.error}
                placeholder="Select a type..."
                options={{
                  data: [
                    'Company',
                    'Freelancer',
                    'Nonprofit',
                    'Academic',
                    'Government',
                    'Other'
                  ].map((option) => {
                    return {
                      id: option,
                      data: {
                        name: option
                      }
                    }
                  })
                }}
                optionLabel="name"
                onChange={field.onChange}
              />
            )}
          </FormField>
        </div>
      )}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onCancel}>
            Cancel
          </OutlineButton>
          <SolidButton onClick={onSubmit}>
            Add organization
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
