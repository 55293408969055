const typeColorMap = {
  Age: 'red',
  Expertise: 'purple',
  ExpertiseType: 'purple',
  Subject: 'purple',
  Activity: 'green',
  ActivityType: 'green',
  Group: 'orange',
  Experience: 'blue',
  Practice: 'yellow',
  Guidance: 'pink'
};

export default typeColorMap;
