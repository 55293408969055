import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import FormField from '../../../forms/_common/FormField';
import StringField from '../../../forms/_fields_v2/StringField';
import FormTemplate from '../../_post-opportunity/FlowTemplate';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';

Form.propTypes = {
  user: PropTypes.object.isRequired
};

export default function Form(props) {
  const {
    user,
    navigation,
    onNext,
    onPrevious,
    onSkip,
    onExit,
    buttonText = 'Next'
  } = props;

  const [data, setData] = useState(_.pick(user.data, [
    'website',
    'linkedinUrl',
    'twitterHandle'
  ]));
  const [showErrors, setShowErrors] = useState(false);
  const [exit, setExit] = useState(false);

  const mutation = useQueryMutation('currentUser.updateSingleton', user);
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      exit ? onExit(data) : onNext(data);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate(data);
  }

  function onSubmitAndExit() {
    onSubmit();
    setExit(true);
  }

  function onChange(name, value) {
    setData(_.merge({}, data, {
      [name]: value
    }));
  }

  return (
    <Overlay isVisible={saving}>
      <FormErrors data={data} validators={{
        // field: [validators.isRequired]
      }}>
        {({ errors, hasError }) => {
          return (
            <FormTemplate
              onExit={() => hasError ? setShowErrors(true) : onSubmitAndExit()}
              alert={error ? (
                <RequestError request={request} />
              ) : showErrors ? (
                <ErrorMessage>
                  Please fill in all required fields.
                </ErrorMessage>
              ) : null}
              body={(
                <div className="space-y-6">
                  <FormField name="website" data={data} errors={showErrors && errors} onChange={onChange}>
                    {(field) => (
                      <StringField
                        label="Website"
                        placeholder="https://..."
                        value={field.value}
                        error={field.error}
                        onChange={field.onChange}
                      />
                    )}
                  </FormField>
                  <FormField name="linkedinUrl" data={data} errors={showErrors && errors} onChange={onChange}>
                    {(field) => (
                      <StringField
                        label="LinkedIn URL"
                        placeholder="https://www.linkedin.com/..."
                        description="Share a link to your profile so community members can learn more about your professional experience."
                        value={field.value}
                        error={field.error}
                        onChange={field.onChange}
                      />
                    )}
                  </FormField>
                  {/*<FormField name="twitterHandle" data={data} errors={showErrors && errors} onChange={onChange}>*/}
                  {/*  {(field) => (*/}
                  {/*    <StringField*/}
                  {/*      label="Twitter Handle"*/}
                  {/*      placeholder="@handle"*/}
                  {/*      value={field.value}*/}
                  {/*      error={field.error}*/}
                  {/*      onChange={field.onChange}*/}
                  {/*    />*/}
                  {/*  )}*/}
                  {/*</FormField>*/}
                </div>
              )}
              // footer={(
              //   <>
              //     <PrimaryButton onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
              //       {buttonText}
              //     </PrimaryButton>
              //   </>
              // )}
            />
          );
        }}
      </FormErrors>
    </Overlay>
  );
};
