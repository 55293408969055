import React from 'react';
import PropTypes from 'prop-types';
import FormField from '../../forms/_common/FormField';
import { OutlineButton, SolidButton } from '../../components/Button';
import StringField from '../../forms/_fields_v2/StringField';
import FormTemplate from '../../dialogs_common/FormTemplate';
import SiteSearchField from './SiteSearchField';
import SelectField from '../../forms/_fields_v2/SelectField';
import useDialogLauncher from '../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../dialogs_new/DialogManager';

Form.propTypes = {
  user: PropTypes.object.isRequired,
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

Form.defaultProps = {
  data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    user,
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onChangeMultiple,
    onSubmit,
    onCancel
  } = props;

  const launch = useDialogLauncher();

  function onSiteMissing() {
    launch(DialogManager.SiteMissing.Config(user.id));
  }

  return (
    <FormTemplate
      title="Add school or site"
      // subtitle="Locations you create are visible only to you"
      onClose={onCancel}
      alert={alert}
      body={(
        <div className="space-y-6">
          <SiteSearchField
            label="School or Site Name"
            text={data.name}
            error={showErrors && errors.name ?
              `Your school or site could not be found. Please click 'Let us know' if it does not appear in the dropdown.` :
              undefined
            }
            description={(
              <>
                Can't find your school or site? <span className="text-cs-orange-500 cursor-pointer underline" onClick={onSiteMissing}>Let us know</span>.
              </>
            )}
            onChange={function(placeComponents) {
              onChangeMultiple(placeComponents);
            }}
            selected={{ data: data }}
          />
          <FormField name="title" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <SelectField
                label="Role"
                value={field.value}
                error={field.error}
                placeholder="Select a role..."
                options={{
                  data: [
                    'Classroom Teacher',
                    'Curriculum Coordinator',
                    'Administrator',
                    'Counselor',
                    'Parent Leader',
                    'Other'
                  ].map((option) => {
                    return {
                      id: option,
                      data: {
                        name: option
                      }
                    }
                  })
                }}
                optionLabel="name"
                onChange={field.onChange}
              />
            )}
          </FormField>
          {/*{user.data.isEducator ? (*/}
          {/*  <FormField name="type" data={data} errors={showErrors && errors} onChange={onChange}>*/}
          {/*    {(field) => (*/}
          {/*      <SelectField*/}
          {/*        label="Type of Organization"*/}
          {/*        value={field.value}*/}
          {/*        error={field.error}*/}
          {/*        placeholder="Select a type..."*/}
          {/*        options={{*/}
          {/*          data: [*/}
          {/*            'Public District School',*/}
          {/*            'Public Charter',*/}
          {/*            'Private School',*/}
          {/*            'Home School',*/}
          {/*            'Higher Education',*/}
          {/*            'Nonprofit',*/}
          {/*            'After School Program',*/}
          {/*            'Other'*/}
          {/*          ].map((option) => {*/}
          {/*            return {*/}
          {/*              id: option,*/}
          {/*              data: {*/}
          {/*                name: option*/}
          {/*              }*/}
          {/*            }*/}
          {/*          })*/}
          {/*        }}*/}
          {/*        optionLabel="name"*/}
          {/*        onChange={field.onChange}*/}
          {/*      />*/}
          {/*    )}*/}
          {/*  </FormField>*/}
          {/*) : (*/}
          {/*  <FormField name="type" data={data} errors={showErrors && errors} onChange={onChange}>*/}
          {/*    {(field) => (*/}
          {/*      <SelectField*/}
          {/*        label="Type of organization"*/}
          {/*        value={field.value}*/}
          {/*        error={field.error}*/}
          {/*        placeholder="Select a type..."*/}
          {/*        options={{*/}
          {/*          data: [*/}
          {/*            'Company',*/}
          {/*            'Freelancer',*/}
          {/*            'Nonprofit',*/}
          {/*            'Academic',*/}
          {/*            'Government',*/}
          {/*            'Other'*/}
          {/*          ].map((option) => {*/}
          {/*            return {*/}
          {/*              id: option,*/}
          {/*              data: {*/}
          {/*                name: option*/}
          {/*              }*/}
          {/*            }*/}
          {/*          })*/}
          {/*        }}*/}
          {/*        optionLabel="name"*/}
          {/*        onChange={field.onChange}*/}
          {/*      />*/}
          {/*    )}*/}
          {/*  </FormField>*/}
          {/*)}*/}
        </div>
      )}
      footer={(
        <div className="flex space-x-4">
          <OutlineButton onClick={onCancel}>
            Cancel
          </OutlineButton>
          <SolidButton onClick={onSubmit}>
            Add school/site
          </SolidButton>
        </div>
      )}
    />
  );
}
