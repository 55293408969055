import React from 'react';
import PropTypes from 'prop-types';
import ClampableText from '../../../components/ClampableText';
import Subjects from './Subjects';
import Ages from './Ages';
import Activities from './Activities';
import SectionHeader from '../../../components_profiles/SectionHeader';

Summary.propTypes = {
  story: PropTypes.object.isRequired
};

export default function Summary(props) {
  const { story } = props;

  return (
    <div>
      <div className="space-y-2">
        <SectionHeader
          title="Summary"
        />
        <div className="text-base text-cs-gray-500">
          <ClampableText markup={true}>
            {story.data.description || '[No summary provided]'}
          </ClampableText>
        </div>
      </div>
      <div className="block xl:hidden px-6 pb-6 space-y-6">
        {/*<Date story={story} />*/}
        <Ages story={story} />
        <Activities story={story} />
        <Subjects story={story} />
      </div>
    </div>
  );
};
