export default function checkProfile(user, props) {
  const {
    userExpertises
  } = props;

  /*
   * 3 educator required fields:
   * first name
   * last name
   * zip code
   *
   * 4 partner required fields:
   * first name
   * last name
   * zip code
   * expertise areas
   */

  const profile = {
    fields: []
  };

  const interests = {
    fields: []
  };

  if (!user.data.firstName) {
    profile.fields.push({
      field: 'firstName',
      name: 'first name',
      label: 'First name'
    });
  }

  if (!user.data.lastName) {
    profile.fields.push({
      field: 'lastName',
      name: 'last name',
      label: 'Last name'
    });
  }

  if (!user.data.zipCode) {
    profile.fields.push({
      field: 'zipCode',
      name: 'zip code',
      label: 'ZIP Code'
    });
  }

  if (
    !user.data.isEducator &&
    userExpertises.data.length < 1
  ) {
    interests.fields.push({
      field: 'expertises',
      name: 'expertise areas',
      label: 'At least one expertise area'
    });
  }

  return {
    profile: profile.fields,
    interests: interests.fields
  };
};
