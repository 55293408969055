import React from 'react';
import PropTypes from 'prop-types';
import { PayloadStates } from '@lore/utils';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import Loader from '../../components/Loader';
import User from '../../pages/user/User'
import CloseDialogButton from '../../dialogs_common/CloseDialogButton';
import Banner from '../../pages/user/Banner';

UserProfileDialog.propTypes = {
  user: PropTypes.object.isRequired
};

export default function UserProfileDialog(props) {
  const { user, project, event, offer } = props;

  if (user.state === PayloadStates.FETCHING) {
    return (
      <DialogTemplate>
        <Loader />
      </DialogTemplate>
    );
  }

  return (
    <DialogTemplate className="max-w-screen-lg">
      <CloseDialogButton onCancel={props.onCancel} />
      <div className="px-6 pt-16 pb-6">
        <div className="space-y-8">
          <Banner
            user={user}
            project={project}
            event={event}
            offer={offer}
            onClose={props.onCancel}
          />
          <User
            user={user}
            project={project}
            event={event}
            offer={offer}
            onClose={props.onCancel}
          />
        </div>
      </div>
    </DialogTemplate>
  );
};
