export default function(project) {
  return {
    HOME: `/post-project`,
    SCHOOL: `/post-project/${project.id}/site`,
    ACTIVITY: `/post-project/${project.id}/activity`,

    TITLE:  `/post-project/${project.id}/title`,
    OUTCOME:  `/post-project/${project.id}/outcome`,
    DEMONSTRATION: `/post-project/${project.id}/demonstration`,
    SUMMARY:  `/post-project/${project.id}/summary`,

    PARTICIPANTS:  `/post-project/${project.id}/participants`,
    STUDENT_COUNT:  `/post-project/${project.id}/student-count`,
    STUDENT_AGE:  `/post-project/${project.id}/student-age`,
    STUDENT_SIZE:  `/post-project/${project.id}/student-size`,

    SUBJECTS:  `/post-project/${project.id}/subjects`,
    PARTNER: `/post-project/${project.id}/partner`,

    DATE: `/post-project/${project.id}/date`,
    DAYS: `/post-project/${project.id}/days`,
    COMMITMENT: `/post-project/${project.id}/commitment`,

    TIME: `/post-project/${project.id}/time`,
    LOCATION: `/post-project/${project.id}/location`,

    DOCUMENTS: `/post-project/${project.id}/documents`,

    RESOURCES: `/post-project/${project.id}/resources`,
    GRANT: `/post-project/${project.id}/grant`,
    BUDGET: `/post-project/${project.id}/budget`,

    SEARCH_EXPLANATION: `/post-project/${project.id}/search-explanation`,

    PROJECT_INVITE: `/project/${project.id}/invite-populate`,
    PROJECT_MANAGE: `/project/${project.id}/manage`,
    PROJECT_EDIT: `/project/${project.id}/edit`,

    MY_PROJECTS: `/my-projects`,
  }
};
