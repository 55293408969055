import React from 'react';
import SectionTitle from '../home/SectionTitle';
import usePastProjects from './usePastProjects';
import usePastEvents from './usePastEvents';
import usePastOffers from './usePastOffers';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import EmptyState from '../../components/EmptyState';
import { ArchiveBoxIcon } from '@heroicons/react/24/outline';
import ProjectCreated from './ProjectCreated';
import EventCreated from './EventCreated';
import OfferCreated from './OfferCreated';

export default function PastCreatedActivities(props) {
  const title = 'Activities you led';

  const projects = usePastProjects();
  const events = usePastEvents();
  const offers = usePastOffers();

  if (
    projects.state === PayloadStates.FETCHING ||
    events.state === PayloadStates.FETCHING ||
    offers.state === PayloadStates.FETCHING
  ) {
    return (
      <div>
        <SectionTitle title={title}/>
        <Loader/>
      </div>
    );
  }

  if (
    projects.data.length === 0 &&
    events.data.length === 0 &&
    offers.data.length === 0
  ) {
    return (
      <div>
        <SectionTitle title={title}/>
        <EmptyState
          icon={ArchiveBoxIcon}
          title="You have not led any activities."
          subtitle="Activities you create will appear here after you close them."
        />
      </div>
    );
  }

  return (
    <div>
      <SectionTitle title={title} />
      <div className="space-y-4">
        {projects.data.map(function(project) {
          return (
            <ProjectCreated
              key={project.id}
              project={project}
            />
          );
        })}
        {events.data.map(function(event) {
          return (
            <EventCreated
              key={event.id}
              event={event}
            />
          );
        })}
        {offers.data.map(function(offer) {
          return (
            <OfferCreated
              key={offer.id}
              offer={offer}
            />
          );
        })}
      </div>
    </div>
  );
}
