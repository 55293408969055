import React, { useState } from 'react';
import { parse, stringify } from 'query-string';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import CloseDialogButton from '../../dialogs_common/CloseDialogButton';
import TagTypes from '../../constants/TagTypes';
import SearchFilter from '../../forms/_fields_v2/SearchFilter';
import { REQUIRED_PARTNER_FIELDS } from '../../utils/user/REQUIRED_FIELDS';
import useLocationCondition from '../../pages/people-partners/useLocationCondition';
import DisclosureTagFilter from '../../forms/_fields_v2/DisclosureTagFilter';
import SearchTags from '../../components/SearchTags';
import ClearAllFilters from '../../components/ClearAllFilters';
import DisclosureFilter from '../../forms/_fields_v2/DisclosureFilter';
import { getSearchUtils } from '../../utils/query-utils';
import useQuery from '../../pages/people-partners/useQuery';
import useFilterCount from '../../hooks/useFilterCount';
import SolidButton from '../../components/Button/SolidButton';
import { attributeKeys } from '../../pages/people-partners/queryUtils';
import _ from 'lodash';

export default function PartnerFiltersDialog(props) {
  const { router } = props;

  const { location, history } = router;

  const [search, setSearch] = useState(parse(location.search));

  const locationCondition = useLocationCondition(search);

  const filterFields = {
    name: 'name',
    organization: 'organization',
    searchPartner: 'searchPartner',
    expertiseTypes: 'expertiseTypes',
    activities: 'activities',
    experiences: 'experiences',
    guidances: 'guidances',
    groups: 'groups'
  };

  const filterCount = useFilterCount(search, _.keys(filterFields));

  function onSubmit() {
    history.push({
      pathname: location.pathname,
      search: stringify({
        ...search,
        page: 1
      })
    });
    props.onCancel();
  }

  function onChange(nextSearch) {
    setSearch(nextSearch);
  }

  function getTagParams(tag) {
    return {
      where: {
        eager: {
          $where: {
            id: {
              ...locationCondition
            },
            'tagNodes.tagId': tag.id,
            isEducator: false,
            ...REQUIRED_PARTNER_FIELDS
          },
        }
      },
      pagination: {
        pageSize: 1
      }
    };
  }

  function getParentTagParams(tag) {
    return {
      where: {
        eager: {
          $where: {
            id: {
              // $includes_any: {
              //   "tagNodes.tagId": [tag.id]
              // },
              ...locationCondition
            },
            'tagTrees.parentId': tag.id,
            isEducator: false,
            ...REQUIRED_PARTNER_FIELDS
          },
        }
      },
      pagination: {
        pageSize: 1
      }
    }
  }

  const searchUtils = getSearchUtils(search, onChange);

  const result = useQuery({
    search
  });

  return (
    <DialogTemplate className="w-screen max-w-screen-sm h-[calc(100dvh-32px)] max-h-[calc(720px)]">
      <CloseDialogButton onCancel={props.onCancel} />
      <div className="flex flex-col space-y-8x h-full">
        <div className="space-y-1 p-6 border-b border-cs-gray-200">
          <h2 className="text-cs-gray-900 text-2xl font-semibold">
            Filters
          </h2>
          <div className="text-cs-gray-500 text-sm">
            Narrow your search results.
          </div>
          {/*<div>*/}
          {/*  <SearchTags*/}
          {/*    search={search}*/}
          {/*    onChange={setSearch}*/}
          {/*  />*/}
          {/*</div>*/}
        </div>
        <div className="px-6 py-8 space-y-8 overflow-y-auto h-full">
          <div className="space-y-4">
            <DisclosureFilter
              label="Search"
              value={[
                searchUtils(filterFields.name).value,
                searchUtils(filterFields.organization).value,
                searchUtils(filterFields.searchPartner).value
              ]}
            >
              <SearchFilter
                label="Name"
                // placeholder="Enter name..."
                description="Find partners whose first or last name matches any of the words you enter."
                value={searchUtils(filterFields.name).value}
                onChange={searchUtils(filterFields.name).onChangeValue}
              />
              <SearchFilter
                label="Organization"
                // placeholder="Enter organization name..."
                description="Find partners affiliated with an organization whose name contains the phrase you type."
                value={searchUtils(filterFields.organization).value}
                onChange={searchUtils(filterFields.organization).onChangeValue}
              />
              <SearchFilter
                label="Profile"
                // placeholder="Enter word or phrase..."
                description="Find partners that have a specific phrase in their biography, or in the description of their skills and experience."
                value={searchUtils(filterFields.searchPartner).value}
                onChange={searchUtils(filterFields.searchPartner).onChangeValue}
              />
            </DisclosureFilter>
          </div>
          {/*<Divider />*/}
          <DisclosureTagFilter
            type={TagTypes.ExpertiseType}
            label="Skills and experience"
            getParams={getParentTagParams}
            value={searchUtils(filterFields.expertiseTypes).value}
            onChange={searchUtils(filterFields.expertiseTypes).onChangeArray}
          />
          <DisclosureTagFilter
            type={TagTypes.Activity}
            label="Activities"
            getParams={getTagParams}
            value={searchUtils(filterFields.activities).value}
            onChange={searchUtils(filterFields.activities).onChangeArray}
          />
          <DisclosureTagFilter
            type={TagTypes.Experience}
            label="Life experience"
            getParams={getTagParams}
            value={searchUtils(filterFields.experiences).value}
            onChange={searchUtils(filterFields.experiences).onChangeArray}
          />
          <DisclosureTagFilter
            type={TagTypes.Guidance}
            label="Professional competencies"
            getParams={getTagParams}
            value={searchUtils(filterFields.guidances).value}
            onChange={searchUtils(filterFields.guidances).onChangeArray}
          />
          <DisclosureTagFilter
            type={TagTypes.Group}
            label="Youth types"
            getParams={getTagParams}
            value={searchUtils(filterFields.groups).value}
            onChange={searchUtils(filterFields.groups).onChangeArray}
          />
        </div>
        <div className="p-6 flex items-center justify-between border-t border-cs-gray-200">
          <ClearAllFilters
            show={true}
            className="hover:opacity-70"
            onClick={() => setSearch(_.omit(search, _.keys(filterFields)))}
            count={filterCount}
          />
          <SolidButton onClick={onSubmit}>
            Show {result.meta?.totalCount} results
          </SolidButton>
        </div>
      </div>
    </DialogTemplate>
  );
};
