import React from 'react';
import PropTypes from 'prop-types';
import ConnectButton from '../../../pages/_banner/ConnectButton';
import Wrapper from '../../../pages/_banner/Wrapper';
import useDialogLauncher from '../../_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useRouter from '../../../hooks/useRouter';

ActiveBanner.propTypes = {
  event: PropTypes.object.isRequired
};

export default function ActiveBanner(props) {
  const { event, creator } = props;

  const launch = useDialogLauncher();
  const { history } = useRouter();

  function onViewMessage() {
    launch(DialogManager.EventManage.Config(event.id, '/manage'));
  }

  function onFindPartners() {
    launch(DialogManager.EventManage.Config(event.id, '/invite-populate'));
  }

  function onClose() {
    launch(DialogManager.EventClose.Config(event.id));
  }

  return (
    <Wrapper
      creator={creator}
      title={(
        <>
          Your event is active!
        </>
      )}
      subtitle={(
        <>
          Whether you're adding new partners or refining your event, you can manage
          all your activities below to keep the momentum going.
        </>
      )}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        <ConnectButton outline={true} onClick={() => history.push('/manage')}>
          Go to messages
        </ConnectButton>
        <ConnectButton onClick={() => history.push('/invite-populate')}>
          Find partners
        </ConnectButton>
      </div>
    </Wrapper>
  );
};
