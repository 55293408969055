import React from 'react';
import _ from 'lodash';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import { stringify } from 'query-string';
import { AcademicCapIcon } from '@heroicons/react/24/outline';
import OptionCard from '../../my-network/OptionCard';
import useQuery from '../../people-educators/useQuery';
import useUserTags from '../../../hooks/useUserTags';
import TagTypes from '../../../constants/TagTypes';
import Loader from '../../../components/Loader';

export default function MatchingEducators(props) {
  const currentUser = useUser();

  const userSubjects = useUserTags(currentUser, TagTypes.Subject);

  const params = {
    search: {
      // networks: `${currentUser.data.networkId}`,
      // locationCondition: JSON.stringify({
      //   address: currentUser.data.zipCode,
      //   latitude: currentUser.data.latitude,
      //   longitude: currentUser.data.longitude,
      //   radius: 50
      // }),
      subjects: userSubjects.data.length > 0 ?
        _.uniq(userSubjects.data.map(userSubject => userSubject._tag.id)).join(',') :
        undefined
    }
  };

  const users = useQuery(params);

  if (
    userSubjects.state === PayloadStates.FETCHING ||
    users.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  if (
    userSubjects.meta.totalCount === 0 ||
    users.meta.totalCount === 0
  ) {
    return null;
  }

  return (
    <OptionCard
      count={users.meta?.totalCount}
      // imageUrl={bookshelfImage}
      icon={AcademicCapIcon}
      title="Educators teaching the same subjects you are"
      subtitle="See what projects educators like you are trying!"
      linkText="View"
      to={{
        pathname: '/educators',
        search: stringify(params.search)
      }}
    />
  );
}
