import React from 'react';
import { PayloadStates } from '@lore/utils';
import { useUser } from '@lore/auth';
import Loader from '../../components/Loader';
import { useConnect } from '../../../hooks/@lore/query-connect';
import Card from '../../components/Card';
import ComposableOfferHeader from '../../components_cards/ComposableOfferHeader';
import TagRow from '../../components_cards/tags/TagRow';
import OfferTag from '../../components_cards/tags/OfferTag';
import OutlineButtonGray from '../../components/Button/OutlineButton.gray';
import ParticipantTag from '../../components_cards/tags/ParticipantTag';
import { DialogManager } from '../../dialogs_new/DialogManager';
import useDialogLauncher from '../../dialogs-routable/_hooks/useDialogLauncher';
import CompletedTag from '../../components_cards/tags/_resource/CompletedTag';
import CancelledTag from '../../components_cards/tags/_resource/CancelledTag';
import ExpiredTag from '../../components_cards/tags/_resource/ExpiredTag';
import Creator from '../my-invitations/Claim/Creator';
import { SolidButton } from '../../components/Button';

export default function Claim(props) {
  const { claim } = props;

  const currentUser = useUser();
  const launch = useDialogLauncher();

  const offer = useConnect('offer.byId', {
    id: claim.data.offerId
  });

  const creator = useConnect('user.byId', {
    id: offer.data.creatorId
  }, {
    enabled: !!offer.data.creatorId
  });

  function onViewOffer() {
    launch(DialogManager.ClaimView.Config(claim.id));
  }

  function onMessageCreator() {
    launch(DialogManager.Messages.Config(creator.id));
  }

  if (offer.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <Card divider={true}>
      <ComposableOfferHeader
        offer={offer}
        showTags={false}
        tags={(
          <TagRow>
            <OfferTag />
            <ParticipantTag />
            <CompletedTag resource={offer} />
            <CancelledTag resource={offer} />
            <ExpiredTag resource={offer} />
          </TagRow>
        )}
        showPartners={false}
        showDistance={true}
      />
      <div className="flex justify-between p-4 bg-gray-50">
        <div className="flex space-x-2">
          <Creator offer={offer} />
        </div>
        <div className="flex space-x-2">
          <OutlineButtonGray
            label="View offer"
            onClick={onViewOffer}
          />
          <SolidButton
            label={`Message ${creator.data.firstName || '...'}`}
            onClick={onMessageCreator}
          />
        </div>
      </div>
    </Card>
  );
}
