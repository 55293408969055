import React from 'react';
import { PayloadStates } from '@lore/utils';
import useRouter from '../../hooks/useRouter';
import Loader from '../../components/Loader';
import User from '../people-partners/User';
import EmptyState from '../../components/EmptyState';
import classNames from 'classnames';
import OfferUser from '../offer-invite/OfferUser';
import { AcademicCapIcon } from '@heroicons/react/24/outline';

export default function Results(props) {
  const {
    offer,
    result
  } = props;

  const { location, history } = useRouter();

  if (result.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  if (result.data.length === 0) {
    return (
      <EmptyState
        icon={AcademicCapIcon}
        title="Can't find who you're looking for?"
        subtitle={(
          <div className="max-w-screen-sm m-auto space-y-6">
            <div>
              Don't worry - there are more people here.
            </div>
            <div className="mt-1 text-cs-gray-500 space-x-1">
              <span>Remove a filter, or start over and</span>
              <span
                className="text-cs-orange-500 underline cursor-pointer"
                onClick={() => history.push(location.pathname)}
              >
                clear all filters
              </span>.
            </div>
          </div>
        )}
      />
    );
  }

  return (
    <div className={classNames(
      result._isPreviousData && 'opacity-50',
        'w-full',
      'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6'
    )}>
      {result.data.map(function(user) {
        if (offer) {
          return (
            <OfferUser
              key={user.id || user.cid}
              user={user}
              offer={offer}
            />
          );
        }

        return (
          <User
            key={user.id || user.cid}
            user={user}
          />
        );
      })}
    </div>
  );
}
