import React from 'react';
import { useUser } from '@lore/auth';
import _ from 'lodash';
import { parse, stringify } from 'query-string';
import { GlobeAmericasIcon, MapIcon, MapPinIcon } from '@heroicons/react/24/outline';
import getSearchParamsFromQuery from '../../utils/user/getSearchParamsFromQuery';
import useRouter from '../../hooks/useRouter';
import DropdownFilter from '../../components/DropdownFilter';

export default function DistanceFilter(props) {
  const { location, history } = useRouter();
  const currentUser = useUser();

  const searchParams = getSearchParamsFromQuery(parse(location.search));

  function onChangeLocationCondition(radius) {
    const search = parse(location.search) || {};

    if (radius < 99999) {
      search.locationCondition = JSON.stringify({
        address: currentUser.data.zipCode,
        latitude: currentUser.data.latitude,
        longitude: currentUser.data.longitude,
        radius: radius
      });
    } else {
      delete search.locationCondition;
    }

    history.push({
      pathname: location.pathname,
      search: stringify(_.extend({}, search))
    });
  }

  const { locationCondition } = searchParams;

  const hasAddress = !!locationCondition.address;

  return (
    <DropdownFilter
      subtitle="Distance:"
      title={!hasAddress ? 'Anywhere' : `${locationCondition.radius} miles`}
      count={_.keys(locationCondition).length > 0 ? 1 : 0}
    >
      <DropdownFilter.MenuItem
        icon={MapPinIcon}
        title="10 miles"
        subtitle={`Show results within 10 miles of ${currentUser.data.zipCode}.`}
        selected={hasAddress && locationCondition.radius === 10}
        onClick={() => onChangeLocationCondition(10)}
      />
      <DropdownFilter.MenuItem
        icon={MapIcon}
        title="50 miles"
        subtitle={`Show results within 50 miles of ${currentUser.data.zipCode}.`}
        selected={hasAddress && locationCondition.radius === 50}
        onClick={() => onChangeLocationCondition(50)}
      />
      <DropdownFilter.MenuItem
        icon={GlobeAmericasIcon}
        title="Anywhere"
        subtitle="Show all results regardless of distance."
        selected={!hasAddress}
        onClick={() => onChangeLocationCondition(99999)}
      />
    </DropdownFilter>
  );
}
