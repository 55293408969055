import React from 'react';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import ParentLayout from '../Layout';
import Navigation from '../../../dialogs_common/Navigation';
import Urls from '../Urls';
import Activities from './Activities';
import { PayloadStates } from '@lore/utils';
import useRouter from '../../../hooks/useRouter';
import useTags from '../../../hooks/useTags';
import TagTypes from '../../../constants/TagTypes';

export default function Layout(props) {
  const { history, match } = useRouter();

  const user = useUser();

  const event = useConnect('event.byId', {
    id: Number(match.params.eventId)
  });

  const activities = useTags(TagTypes.Activity);

  if (
    event.state === PayloadStates.FETCHING ||
    activities.state === PayloadStates.FETCHING
  ) {
    return null;
  }

  return (
    <ParentLayout
      event={event}
      page="Activity"
      title="Which ONE of these activity types is closest to the event you're planning?"
      description={(
        <div className="space-y-4">
          <div>
            <span className="font-semibold italic">TIP</span>: You can change this later, but it helps
            us make some assumptions so try to be as accurate as possible.
          </div>
          <div className="font-semibold">
            Choose ONE Activity Type.
          </div>
        </div>
      )}
    >
      <Activities
        user={user}
        event={event}
        activities={activities}
        navigation={(
          <Navigation v5={true} activeStep={0} steps={13}/>
        )}
        onPrevious={(newData) => history.push(Urls(event).SCHOOL)}
        onNext={(newData) => history.push(Urls(event).PARTICIPANTS)}
        onSkip={() => history.push(Urls(event).PARTICIPANTS)}
      />
    </ParentLayout>
  );
};
