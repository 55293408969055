import React from 'react';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import EmptyState from '../../components/EmptyState';
import SectionTitle from '../home/SectionTitle';
import EvaluationProjectEducator from './EvaluationProjectEducator';
import EvaluationProjectPartner from './EvaluationProjectPartner';
import EvaluationEventEducator from './EvaluationEventEducator';
import EvaluationEventPartner from './EvaluationEventPartner';
import usePendingProjectEducatorEvaluations from './usePendingProjectEducatorEvaluations';
import usePendingProjectPartnerEvaluations from './usePendingProjectPartnerEvaluations';
import usePendingEventEducatorEvaluations from './usePendingEventEducatorEvaluations';
import usePendingEventPartnerEvaluations from './usePendingEventPartnerEvaluations';
import { ClipboardDocumentListIcon } from '@heroicons/react/24/outline';

export default function PendingEvaluations(props) {
  const title = 'Pending';

  const evaluationProjectEducators = usePendingProjectEducatorEvaluations();
  const evaluationProjectPartners = usePendingProjectPartnerEvaluations();
  const evaluationEventEducators = usePendingEventEducatorEvaluations();
  const evaluationEventPartners = usePendingEventPartnerEvaluations();

  if (
    evaluationProjectEducators.state === PayloadStates.FETCHING ||
    evaluationProjectPartners.state === PayloadStates.FETCHING ||
    evaluationEventEducators.state === PayloadStates.FETCHING ||
    evaluationEventPartners.state === PayloadStates.FETCHING
  ) {
    return (
      <div>
        {/*<SectionTitle title={title} />*/}
        <Loader />
      </div>
    );
  }

  if (
    evaluationProjectEducators.data.length === 0 &&
    evaluationProjectPartners.data.length === 0 &&
    evaluationEventEducators.data.length === 0 &&
    evaluationEventPartners.data.length === 0
  ) {
    return (
      <div>
        {/*<SectionTitle title={title} />*/}
        <EmptyState
          icon={ClipboardDocumentListIcon}
          title="You have no pending evaluations."
          subtitle="Evaluations will appear when an activity that you led or participated in is closed."
        />
      </div>
    );
  }

  return (
    <div>
      {/*<SectionTitle title={title} />*/}
      <div className="grid grid-cols-1 gap-4">
        {evaluationProjectEducators.data.map(function(evaluation) {
          return (
            <EvaluationProjectEducator
              key={evaluation.id || evaluation.cid}
              evaluation={evaluation}
            />
          );
        })}
        {evaluationProjectPartners.data.map(function(evaluation) {
          return (
            <EvaluationProjectPartner
              key={evaluation.id || evaluation.cid}
              evaluation={evaluation}
            />
          );
        })}
        {evaluationEventEducators.data.map(function(evaluation) {
          return (
            <EvaluationEventEducator
              key={evaluation.id || evaluation.cid}
              evaluation={evaluation}
            />
          );
        })}
        {evaluationEventPartners.data.map(function(evaluation) {
          return (
            <EvaluationEventPartner
              key={evaluation.id || evaluation.cid}
              evaluation={evaluation}
            />
          );
        })}
      </div>
    </div>
  );
}
