import React from 'react';
import { DateTime } from 'luxon';
import RightMediaSection from '../../_common/RightMediaSection';
import MiddleDot from '../../_common/MiddleDot';

export default function Complete(props) {
  const { evaluation, onClickView, onClickEdit } = props;

  return (
    <RightMediaSection
      title={(
        <span className="text-green-600">
          Evaluation completed
        </span>
      )}
      subtitle={(
        <div className="flex space-x-1">
          <span>
            Completed {DateTime.fromISO(evaluation.data.updatedAt).toRelative()}
          </span>
          {onClickEdit && (
            <>
              <span>
                <MiddleDot />
              </span>
              <span className="text-blue-500 hover:text-blue-300 cursor-pointer" onClick={onClickEdit}>
                Edit
              </span>
            </>
          )}
          {onClickView && (
            <>
              <span>
                <MiddleDot />
              </span>
              <span className="text-blue-500 hover:text-blue-300 cursor-pointer" onClick={onClickView}>
                View
              </span>
            </>
          )}
        </div>
      )}
    />
  );
};
