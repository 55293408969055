import React from 'react';

export default function Description(props) {
  const { question } = props;

  if (!_.trim(question.data.requestMessage)) {
    return null;
  }

  return (
    <div className="text-base text-cs-gray-500">
      "{question.data.requestMessage}"
    </div>
  );
}
