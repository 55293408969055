import React from 'react';
import _ from 'lodash';
import { LinkIcon, MagnifyingGlassIcon, PencilIcon, UsersIcon } from '@heroicons/react/24/outline';
import ActionBarMenu from './ActionBarMenu';
import useDialogConfig from '../../_hooks/useDialogConfig';
import useDialogRouter from '../../_hooks/useDialogRouter';
import ActionBarContainer from '../../_components_action_bar/ActionBarContainer';
import ActionBarButton from '../../_components_action_bar/ActionBarButton';
import useHasExpired from '../../../pages/offer-classify/useHasExpired';

export default function ActionBar(props) {
  const { offer } = props;

  const { id } = useDialogConfig();
  const router = useDialogRouter(id);
  const hasExpired = useHasExpired(offer.data.endDate);

  const isClosed = (
    hasExpired ||
    offer.data.completed ||
    offer.data.cancelled
  );

  const links = _.remove([
    {
      icon: LinkIcon,
      label: 'View offer',
      to: `/view`
    },
    !isClosed && {
      icon: PencilIcon,
      label: 'Edit offer',
      to: `/edit`
    },
    !isClosed && {
      icon: MagnifyingGlassIcon,
      label: 'Find educators',
      to: `/invite-populate`
    },
    !isClosed && {
      icon: UsersIcon,
      label: 'Manage educators',
      to: `/manage`
    },
    isClosed && {
      icon: UsersIcon,
      label: 'View educators',
      to: `/classify`
    }
  ], entry => entry);

  return (
    <ActionBarContainer>
      {links.map(function(link, index) {
        const active = (
          router.location.pathname.indexOf(link.to) === 0 ||
          link.to.indexOf(router.location.pathname) === 0
        );

        return (
          <ActionBarButton
            key={index}
            label={link.label}
            icon={link.icon}
            onClick={() => router.navigate(link.to)}
            isActive={active}
          />
        );
      })}
      <ActionBarMenu offer={offer} />
    </ActionBarContainer>
  );
};
