import React from 'react';
import MissingFieldsTag from '../tags/MissingFieldsTag';
import useMissingFields from '../../pages/project-manage/useMissingFields';

export default function ProjectMissingFieldsTag(props) {
  const { project } = props;

  const { missingFields } = useMissingFields(project);

  if (missingFields.data.length > 0) {
    return (
      <MissingFieldsTag resource={project} />
    );
  }

  return null;
}
