import React from 'react';
import { useConnect } from '@lore/query-connect';
import Tooltip from '../../../_post-opportunity/forms/Tooltip';

export default function Partners(props) {
  const { offer } = props;

  const invited = useConnect('claim.find', {
    where: {
      eager: {
        $where: {
          offerId: offer.id,
          response: 'Pending'
        }
      }
    },
    pagination: {
      pageSize: 1000,
      order: 'createdAt asc'
    }
  });

  const declined = useConnect('claim.find', {
    where: {
      eager: {
        $where: {
          offerId: offer.id,
          response: 'Decline'
        }
      }
    },
    pagination: {
      pageSize: 1000,
      order: 'createdAt asc'
    }
  });

  const connected = useConnect('claim.find', {
    where: {
      eager: {
        $where: {
          offerId: offer.id,
          response: 'Connect'
        }
      }
    },
    pagination: {
      pageSize: 1000,
      order: 'createdAt asc'
    }
  });

  return (
    <div className="flex items-center whitespace-nowrap space-x-2">
      <Tooltip tooltip="Declined" icon={false}>
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
          {declined.data.length}
        </span>
      </Tooltip>
      <Tooltip tooltip="Invited" icon={false}>
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-cs-yellow-100 text-cs-yellow-800">
          {invited.data.length}
        </span>
      </Tooltip>
      <Tooltip tooltip="Connected" icon={false}>
        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
          {connected.data.length}
        </span>
      </Tooltip>
    </div>
  );
}
