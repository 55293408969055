import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useUser } from '@lore/auth';
import { BuildingOfficeIcon, GlobeAltIcon } from '@heroicons/react/24/outline';
import { getDistanceFromLatLonInMiles } from '../../../components/User/utils';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import useEventTags from '../../../hooks/useEventTags';
import TagTypes from '../../../constants/TagTypes';
import TextIconGroup from '../../../components_profiles/TextIconGroup';

Participation.propTypes = {
  event: PropTypes.object.isRequired
};

export default function Participation(props) {
  const { event } = props;

  const currentUser = useUser();

  const creator = useConnect('user.byId', {
    id: event.data.creatorId
  });

  const eventInteractionTypes = useEventTags(event, TagTypes.InteractionType);

  if (
    creator.state === PayloadStates.FETCHING ||
    eventInteractionTypes.state === PayloadStates.FETCHING
  ) {
    return (
      <div>
        <div className="text-sm text-cs-gray-500 font-semibold uppercase mb-2">
          Participation options
        </div>
        <Loader/>
      </div>
    );
  }

  const distance = getDistanceFromLatLonInMiles(creator.data, currentUser.data);

  const isInPerson = _.find(eventInteractionTypes.data, function(eventInteractionType) {
    return eventInteractionType.data.name === 'In-person';
  });

  const isVirtual = _.find(eventInteractionTypes.data, function(eventInteractionType) {
    return eventInteractionType.data.name === 'Virtual';
  });

  return (
    <div className="space-y-2">
      <div className="text-sm text-cs-gray-500">
        Participation options
      </div>
      <div className="flex space-x-6">
        {isInPerson && (
          <TextIconGroup
            icon={BuildingOfficeIcon}
            title="In-person"
            // subtitle={distance <= 1 ? '1 mile away' : `${distance} miles away`}
          />
        )}
        {isVirtual && (
          <TextIconGroup
            icon={GlobeAltIcon}
            title="Virtual"
            // subtitle={isInPerson ? undefined : distance <= 1 ? '1 mile away' : `${distance} miles away`}
          />
        )}
      </div>
    </div>
  );
}
