import React from 'react';
import Header from './Header';
import Date from './Date';
import Creator from './Creator';
import Participation from './Participation';
import classNames from 'classnames';
import Organization from './Organization';
import Distance from './Distance';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import Cost from '../../my-offers/Offer/Header-new/Cost';

export default function Offer(props) {
  const { offer, className, minimal = false } = props;

  const launch = useDialogLauncher();

  function onClick() {
    launch(DialogManager.Offer.Config(offer.id));
  }

  return (
    <div className={classNames(
      'flex flex-col bg-white',
      'shadow-cs-flat rounded-cs-10',
      '-m-0.5 border-2 border-cs-gray-100',
      'relative overflow-hidden',
      'hover:bg-cs-orange-50 cursor-pointer',
      className
    )} onClick={onClick}>
      {/*<Link to={`/offers/${offer.id}`} className="absolute w-full h-full" />*/}
      <div className="flex flex-col py-4 px-4 space-y-2">
        <Header offer={offer} />
        <div className="flex flex-col sm:flex-row items-start justify-between">
          <div className="flex flex-1 flex-wrap gap-x-4 gap-y-2 mb-2 sm:mb-0">
            <Date offer={offer} />
            {/*<Time offer={offer} />*/}
            <Creator offer={offer} />
            <Organization offer={offer} />
            <Cost offer={offer} />
          </div>
          <Distance offer={offer} />
        </div>
      </div>
      {false && (
        <div className={classNames(
          'w-full py-4 px-4',
          'bg-cs-gray-50',
          '-mt-px border-t border-cs-gray-100',
        )}>
          <Participation offer={offer} />
        </div>
      )}
    </div>
  );
}
