import React from 'react';
import RemoveLoadingScreen from '../RemoveLoadingScreen';
import ScrollToTop from '../../components/ScrollToTop';
import logo from '../../../assets/images/logo.png';
import { Helmet } from 'react-helmet-async';
import Impersonation from '../Impersonation';
import useIntercom from '../../hooks/useIntercom';

export default function Layout(props) {
  const { children } = props;

  useIntercom();

  return (
    <div className="bg-white">
      <Helmet>
        <title>Setup</title>
      </Helmet>
      <ScrollToTop />
      <RemoveLoadingScreen/>
      <Impersonation />
      <div className="flex flex-col lg:flex-row h-100 lg:h-screen">
        <div className="flex items-center justify-center bg-gradient-to-b from-cs-yellow-100 to-cs-yellow-300 px-16 h-40 py-8 lg:h-screen">
          <img src={logo} className="w-24 lg:w-40 xl:w-64 filter drop-shadow-md mx-4" alt="logo" />
        </div>
        <div className="flex-1 flex flex-col h-auto lg:h-100 lg:overflow-scroll">
          {children}
        </div>
      </div>
    </div>
  );
};
