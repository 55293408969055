import React from 'react';
import RemoveLoadingScreen from '../RemoveLoadingScreen';
import ScrollToTop from '../../components/ScrollToTop';
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/logo.png';
import { Helmet } from 'react-helmet-async';
import Impersonation from '../Impersonation';

export default function Layout(props) {
  const {
    page,
    title,
    description,
    children
  } = props;

  return (
    <div className="flex flex-col lg:flex-row h-100 lg:h-screen">
      <Helmet>
        <title>Edit Profile: {page}</title>
      </Helmet>
      <RemoveLoadingScreen/>
      <Impersonation />
      <div className="w-full lg:w-1/2 bg-gradient-to-b from-cs-yellow-100 to-cs-yellow-300 overflow-y-auto relative">
        <Link className="cursor-pointer absolute top-5 left-6" to="/">
          <img className="h-16" src={logo} alt="logo" />
        </Link>
        <div className="px-16 lg:px-32 pt-32 lg:pt-32 space-y-2 pb-12">
          <h1 className="text-3xl font-semibold text-cs-gray-900">
            {title}
          </h1>
          <div className="text-xl text-cs-gray-500">
            {description}
          </div>
        </div>
      </div>
      <div className="w-full lg:w-1/2 overflow-y-auto relative bg-white">
        <ScrollToTop />
        {children}
      </div>
    </div>
  );
};
