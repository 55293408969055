import React from 'react';
import { useUser } from '@lore/auth';
import Sleeping from './Sleeping';
import ProfileType from './ProfileType';
import DeleteAccount from './DeleteAccount';
import ChangeEmail from './ChangeEmail';
import Community from './Community';

export default function DialogLayout() {
  const currentUser = useUser();

  return (
    <div className="space-y-8">
      <ChangeEmail user={currentUser} />
      <Sleeping user={currentUser} />
      <ProfileType user={currentUser} />
      <Community user={currentUser} />
      <DeleteAccount user={currentUser} />
    </div>
  );
};
