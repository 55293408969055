import React from 'react';
import { useConnect } from '@lore/query-connect';
import { BuildingOfficeIcon } from '@heroicons/react/24/outline';
import IconSection from '../../_new_card_components/IconSection';

export default function Organization(props) {
  const { offer } = props;

  const creator = useConnect('user.byId', {
    id: offer.data.creatorId
  });

  const role = useConnect('role.first', {
    where: {
      eager: {
        $where: {
          userId: offer.data.creatorId
        }
      }
    }
  });

  const organization = useConnect('organization.byId', {
    id: role.data.organizationId
  }, {
    enabled: !!role.data.organizationId
  });

  if (!organization.data.name) {
    return null;
  }

  return (
    <IconSection
      icon={BuildingOfficeIcon}
      title={organization.data.name}
    />
  );
}
