import React from 'react';
import PropTypes from 'prop-types';
import { PayloadStates } from '@lore/utils';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import Loader from '../../components/Loader';
import Template from './Template';
import CloseDialogButton from '../../dialogs_common/CloseDialogButton';
import { useConnect } from '../../../hooks/@lore/query-connect';

EvaluationEventPartnerDialog.propTypes = {
  evaluationEventPartner: PropTypes.object.isRequired
};

export default function EvaluationEventPartnerDialog(props) {
  const { evaluationEventPartner } = props;

  const event = useConnect('event.byId', {
    id: evaluationEventPartner.data.eventId
  });

  if (event.state === PayloadStates.FETCHING) {
    return (
      <DialogTemplate>
        <Loader />
      </DialogTemplate>
    );
  }

  return (
    <DialogTemplate className="w-full max-w-screen-lg">
      <CloseDialogButton onCancel={props.onClose} />
      <div className="px-6 pt-16 pb-6">
        <Template
          event={event}
          evaluationEventPartner={evaluationEventPartner}
          onClose={props.onClose}
        />
      </div>
    </DialogTemplate>
  );
};
