import React from 'react';
import PropTypes from 'prop-types';
import { useUser } from '@lore/auth';
import { MapPinIcon } from '@heroicons/react/24/outline';
import { getDistanceFromLatLonInMiles } from '../../../components/User/utils';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import IconSection from '../../_new_card_components/IconSection';

Distance.propTypes = {
  event: PropTypes.object.isRequired
};

export default function Distance(props) {
  const { event } = props;

  const currentUser = useUser();

  const creator = useConnect('user.byId', {
    id: event.data.creatorId
  });

  const site = useConnect('site.byId', {
    id: event.data.siteId
  }, {
    enabled: !!event.data.siteId
  });

  // const school = useConnect('school.byId', {
  //   id: site.data.schoolId
  // }, {
  //   enabled: !!site.data.schoolId
  // });

  if (
    creator.state === PayloadStates.FETCHING ||
    (site && site.state === PayloadStates.FETCHING)
    // (school && school.state === PayloadStates.FETCHING)
  ) {
    return (
      <IconSection
        icon={MapPinIcon}
        title="..."
      />
    );
  }

  const distance = getDistanceFromLatLonInMiles(
    event.data.siteId ? site.data : creator.data,
    currentUser.data
  );

  return (
    <IconSection
      icon={MapPinIcon}
      title={distance <= 1 ? '1 mile away' : `${distance} miles away`}
    />
  );
}
