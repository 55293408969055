/**
 * This file a component that you can use to block access to the
 * application (or some part of it) if the user is not logged in.
 *
 * https://www.lorejs.org/anatomy/src/decorators/user-is-authenticated/
 */

import { useEffect, useState } from 'react';
import auth from '../utils/auth';
import storage from '../utils/storage';
import isBlacklistedRedirectUrl from '../utils/isBlacklistedRedirectUrl';
import useRouter from '../hooks/useRouter';
import { Outlet } from 'react-router-dom';

export default function UserIsAuthenticated(props) {
  const { children } = props;

  const { history } = useRouter();

  const [ authenticated, setAuthenticated] = useState(auth.hasToken());

  useEffect(() => {
    if (!authenticated) {
      // console.log('No redirect URL provided');
      const redirectUrl = `${window.location.pathname}${window.location.search}`;

      if (
        !storage.get('redirectUrl') &&
        !isBlacklistedRedirectUrl(redirectUrl)
      ) {
        storage.set('redirectUrl', redirectUrl);
      }
      history.push('/login');
    }
  }, []);

  if (authenticated) {
    // return children;
    return (
      <Outlet />
    );
  }

  return null;
};
