import React from 'react';
import { useConnect } from '@lore/query-connect';
import UserJob from './UserJob';

const MAX_ROLES = 2;

export default function UserJobs(props) {
  const { user } = props;

  const _jobs = useConnect('job.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    },
    pagination: {
      page: 1,
      pageSize: 100,
      order: 'createdAt asc'
    }
  });

  const jobs = {
    ..._jobs,
    data: _jobs.data.slice(0, MAX_ROLES)
  };

  return (
    <div className="text-sm text-cs-gray-500 text-center">
      {jobs.data.map(function(job, index) {
        return [
          <UserJob key={`job-${index}`} job={job}/>,
          index < jobs.data.length - 1 ? <span key={`dot-${index}`}>{' • '}</span> : null
        ];
      })}
    </div>
  );
}
