import React from 'react';
import MissingFieldsTag from '../tags/MissingFieldsTag';
import useMissingFields from '../../pages/offer-manage/useMissingFields';

export default function OfferMissingFieldsTag(props) {
  const { offer } = props;

  const { missingFields } = useMissingFields(offer);

  if (missingFields.data.length > 0) {
    return (
      <MissingFieldsTag resource={offer} />
    );
  }

  return null;
}
