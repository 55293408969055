import React from 'react';
import PropTypes from 'prop-types';
import DialogTemplate from '../../../dialogs_common/DialogTemplate';
import SolidButton from '../../../components/Button/SolidButton';
import FormTemplate from '../../../dialogs_common/FormTemplate';
import { TrashIcon } from '@heroicons/react/24/outline';

Confirmation.propTypes = {
  onCancel: PropTypes.func.isRequired
};

export default function Confirmation(props) {
  const { onCancel } = props;

  return (
    <DialogTemplate>
      <FormTemplate
        icon={(
          <div className="flex justify-center mb-4">
            <TrashIcon className="text-cs-orange-500 h-12 w-12" />
          </div>
        )}
        title="Your request to delete your account has been received."
        subtitle="Once your account has been deleted, we will send you an email to confirm the action was taken."
        // body={(
        //   <div className="text-cs-gray-500 text-center text-lgx">
        //     Once your account has been deleted, we will send you an email to confirm the action was taken.
        //   </div>
        // )}
        footer={(
          <div className="text-center w-full">
            <SolidButton onClick={onCancel}>
              Close
            </SolidButton>
          </div>
        )}
      />
    </DialogTemplate>
  );
};
