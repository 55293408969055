import getQueryUtils from './queryUtils';

export default function useInteractionTypesFilterParam(search = {}) {
  const queryUtils = getQueryUtils(search, [
    'interactionTypes'
  ]);

  const values = queryUtils.params.interactionTypes;

  if (values.length > 0) {
    return {
      $or: [
        {
          'project.tagNodes.tagId': {
            $in: values
          }
        },
        {
          'event.tagNodes.tagId': {
            $in: values
          }
        }
      ]
    };
  }
}
