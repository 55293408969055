import React from 'react';
import PropTypes from 'prop-types';
import { PayloadStates } from '@lore/utils';
import ConnectButton from '../../../pages/_banner/ConnectButton';
import Wrapper from '../../../pages/_banner/Wrapper';
import useDialogLauncher from '../../_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import { useConnect } from '../../../../hooks/@lore/query-connect';
import usePageRouter from '../../_hooks/usePageRouter';
import LoadingBanner from '../../../pages/_banner/LoadingBanner';

CompletedBanner.propTypes = {
  invitation: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired
};

export default function CompletedBanner(props) {
  const { invitation, event, creator } = props;

  const launch = useDialogLauncher();
  const { history } = usePageRouter();

  const evaluation = useConnect('evaluationEventPartner.first', {
    where: {
      eager: {
        $where: {
          eventId: invitation.data.eventId,
          userId: invitation.data.userId
        }
      }
    }
  });

  function onViewEvaluation() {
    launch(DialogManager.EvaluationEventPartner.Config(evaluation.id));
  }

  function onEditEvaluation() {
    history.push(`/evaluation-event-partner/${evaluation.id}`);
  }

  if (evaluation.state === PayloadStates.FETCHING) {
    return (
      <LoadingBanner
        color="green"
        creator={creator}
      />
    );
  }

  if (
    evaluation.state === PayloadStates.RESOLVED &&
    !evaluation.data.completed
  ) {
    return (
      <Wrapper
        color="green"
        creator={creator}
        title={(
          <>
            Thank you for making a difference! Please share your experience.
          </>
        )}
        subtitle={(
          <>
            We invite you to take 5 minutes to share your experience. Your perspective will
            inspire other and help us improve for future events.
          </>
        )}
      >
        <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
          {/*<ConnectButton outline={true} onClick={onViewEvent}>*/}
          {/*  View your event*/}
          {/*</ConnectButton>*/}
          <ConnectButton color="green" onClick={onEditEvaluation}>
            Share your impact!
          </ConnectButton>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper
      color="green"
      creator={creator}
      title={(
        <>
          Thanks for being part of this event!
        </>
      )}
      subtitle={(
        <>
          Take a moment to celebrate your valuable contribution! We're grateful to have you in
          our community. Ready to discover new opportunities to connect and collaborate with
          educators and learners? Start exploring now!
        </>
      )}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        {evaluation.state === PayloadStates.RESOLVED && (
          <ConnectButton outline={true} onClick={onViewEvaluation}>
            Revisit your impact
          </ConnectButton>
        )}
        <ConnectButton color="green" onClick={() => history.push(`/opportunities`)}>
          Explore opportunities
        </ConnectButton>
      </div>
    </Wrapper>
  );
};
