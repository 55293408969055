import React from 'react';
import { Helmet } from 'react-helmet-async';
import { stringify } from 'query-string';
import Redirect from '../../components/Redirect';
import { useUser } from '@lore/auth';
import PageHeader from '../people-people/PageHeader';

export default function Layout(props) {
  const currentUser = useUser();

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Explore active events</title>
      </Helmet>
      <PageHeader
        title="Explore active events"
        subtitle={(
          <>
            Check out inspiring events where educators and learners are looking
            for partners. See how you can connect and contribute your skills and experiences to
            make a difference. Use filters to find events that interest you.
          </>
        )}
      />
      <Redirect
        to={{
          pathname: '/events',
          search: stringify({
            networks: [currentUser.data.networkId],
            locationCondition: JSON.stringify({
              address: currentUser.data.zipCode,
              latitude: currentUser.data.latitude,
              longitude: currentUser.data.longitude,
              radius: 50
            })
          })
        }}
        replace={true}
      />
    </div>
  );
}
