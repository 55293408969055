import React from 'react';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Navigation from '../../../dialogs_common/Navigation';
import ParentLayout from '../ParentLayout';
import Urls from '../Urls';
import Form from './Form';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { location, history, match } = useRouter();
  const user = useUser();
  const evaluation = useConnect('evaluationProjectEducator.byId', {
    id: Number(match.params.evaluationId)
  })

  if (evaluation.state === PayloadStates.FETCHING) {
    return null;
  }

  return (
    <ParentLayout
      evaluation={evaluation}
      page="Lessons"
      title={(
        <>
          Looking back, what lessons did you learn that might help other educators about to
          begin their first community-engaged learning journey?
        </>
      )}
      description={(
        <>
          <span className="font-semibold">Example:</span> I was excited by what the kids learned
          but also felt a little bummed that they didn't get to work on a single project with
          the same partner, so they could build a deeper relationship. But I reminded myself it
          is ok to start small with a few speakers and then take what I learned to do something
          more complex next year. I think student voice was key to our success, as it was clear
          to me that students felt an ownership of their own learning because they had the
          opportunity to select the types of speakers and engage their own family. Also, one of
          the speakers was a little dry and I realized I should have asked my students to prepare
          questions for him so he didn't feel like he needed to come up with all the content.
        </>
      )}
    >
      <Form
        user={user}
        evaluation={evaluation}
        navigation={(
          <Navigation v5={true} activeStep={12} steps={14}/>
        )}
        onPrevious={(newData) => history.push(Urls(evaluation).CHANGES)}
        onNext={(newData) => history.push(Urls(evaluation).UPLOADS)}
        onSkip={() => history.push(Urls(evaluation).UPLOADS)}
        onExit={() => history.push(Urls(evaluation).PROJECT_MANAGE)}
      />
    </ParentLayout>
  );
};
