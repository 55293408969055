import React from 'react';
import { TicketIcon } from '@heroicons/react/24/outline';
import useRouter from '../../hooks/useRouter';
import Urls from '../event-create/Urls';
import DataCard from '../profile-edit/DataCard';
import usePageRouter from '../../dialogs-routable/_hooks/usePageRouter';

export default function EventTitle(props) {
  const { event } = props;
  const { history } = usePageRouter();

  return (
    <DataCard
      title="Number of Partners"
      icon={TicketIcon}
      subtitle={event.data.slots || '(not provided)'}
      onClick={() => history.push(Urls(event).SLOTS)}
    />
  );
};
