import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useDialogConfig from '../_hooks/useDialogConfig';
import InvitationNoDialog from '../../dialogs/invitation.no';
import { useQueryClient } from '@tanstack/react-query';

export default function Launcher(props) {
  const config = useDialogConfig();
  const queryClient = useQueryClient();

  const invitation = useConnect('invitation.byId', {
    id: config.params.invitationId
  });

  const event = useConnect('event.byId', {
    id: invitation.data.eventId
  }, {
    enabled: !!invitation.data.eventId
  });

  if (
    invitation.state === PayloadStates.FETCHING ||
    event.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  return (
    <InvitationNoDialog
      invitation={invitation}
      event={event}
      onCancel={props.onClose}
      onSubmit={function(request) {
        queryClient.invalidateQueries({ queryKey: ['invitation'] });
      }}
    />
  );
};
