import React, { useState } from 'react';
import { UsersIcon } from '@heroicons/react/24/outline';
import useRouter from '../../hooks/useRouter';
import Urls from '../project-create/Urls';
import DataCard from '../profile-edit/DataCard';
import { PayloadStates } from '@lore/utils';
import useProjectTags from '../../hooks/useProjectTags';
import TagTypes from '../../constants/TagTypes';
import usePageRouter from '../../dialogs-routable/_hooks/usePageRouter';

export default function StudentAges(props) {
  const { project } = props;

  const { history } = usePageRouter();

  const [timestamp] = useState(new Date().toISOString());

  const projectAges = useProjectTags(project, TagTypes.Age, timestamp);

  let subtitle = '';

  if (projectAges.state === PayloadStates.FETCHING) {
    subtitle = '...';
  } else {
    subtitle = projectAges.data.map(function(projectAge, index) {
      return (
        <div key={index}>
          {projectAge.data.name}
        </div>
      );
    });
  }

  return (
    <DataCard
      title="Student ages"
      icon={UsersIcon}
      subtitle={subtitle || '(not provided)'}
      onClick={() => history.push(Urls(project).STUDENT_AGE)}
    />
  );
};
