import React from 'react';
import PropTypes from 'prop-types';
import { OutlineButton, SolidButton } from '../../components/Button';
import FormTemplate from '../../dialogs_common/FormTemplate';
import FormSection from '../../pages/_post-opportunity/forms/FormSection';
import Avatar from '../../components/Avatar';
import { useUser } from '@lore/auth';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  activityTypes: PropTypes.object,
  ages: PropTypes.object
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onCancel,
    invitation,
    emailPreview,
    creator
  } = props;

  return (
    <FormTemplate
      icon={(
        <div className="flex justify-center mb-4">
          <Avatar size={32} src={creator.data.avatarUrl} />
        </div>
      )}
      title="Maybe Attend Event"
      subtitle={(
        <>
          Submit this form, and we'll redirect you to a place you can start
          a conversation with {creator.data.firstName} to ask any questions you have.
        </>
      )}
      onClose={onCancel}
      alert={alert}
      body={(
        <div className="space-y-10">
          <FormSection
            title="Email Preview"
            subtitle="This is what we'll send to the educator."
          >
            <div className="bg-white border border-cs-gray-200 shadow-cs-flat rounded-lg h-96 overflow-auto">
              {emailPreview}
            </div>
          </FormSection>
        </div>
      )}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onCancel}>
            Cancel
          </OutlineButton>
          <SolidButton onClick={onSubmit}>
            Maybe Attend Event
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
