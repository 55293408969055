import React from 'react';
import { useConnect } from '@lore/query-connect';
import { DateTime } from 'luxon';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import IconSection from '../../pages/_new_card_components/IconSection';

export default function Meta(props) {
  const { invitation } = props;

  const creator = useConnect('user.byId', {
    id: invitation.data.creatorId
  });

  const event = useConnect('event.byId', {
    id: invitation.data.eventId
  });

  return (
    <IconSection
      icon={EnvelopeIcon}
      title={event.data.creatorId !== creator.id ?
        `Invited ${DateTime.fromISO(invitation.data.createdAt).toRelative()} by ${creator.data.firstName} ${creator.data.lastName} (Steward)` :
        `Invited ${DateTime.fromISO(invitation.data.createdAt).toRelative()}`
      }
    />
  );
};
