import { useConnect } from '@lore/query-connect';
import _ from 'lodash';
import { PayloadStates } from '@lore/utils';

export default function useBookmark(props) {
  const { currentUser, user } = props;

  const userBookmarks = useConnect('bookmark.find', {
    where: {
      eager: {
        $where: {
          creatorId: currentUser.id
          // bookmarkId: user.id
        }
      }
    },
    pagination: {
      pageSize: 1000
    }
  });

  const userBookmark = _.find(userBookmarks.data, function(userBookmark) {
    return (
      userBookmark.data.creatorId === currentUser.id &&
      userBookmark.data.userId === user.id &&
      userBookmark.state !== PayloadStates.DELETED
    );
  }) || {
    state: PayloadStates.NOT_FOUND
  };

  return userBookmark;
}
