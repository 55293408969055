import React from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

export default function MissingField(props) {
  const { name, description, to } = props;

  return (
    <Link
      to={to}
      className={classNames(
        'flex bg-cs-orange-50 rounded-lg py-3 px-4 space-x-4',
        '-m-px border border-cs-gray-200',
        'shadow-cs-flat',
        'hover:bg-cs-orange-100'
      )}
    >
      <div>
        <ExclamationTriangleIcon className="h-8 w-8 text-cs-orange-500" />
      </div>
      <div className="flex-1 flex flex-col">
        <div className="text-base font-semibold text-cs-gray-900">
          Missing Field: {name}
        </div>
        <div className="text-sm text-cs-gray-500">
          {description}
        </div>
      </div>
    </Link>
  );
}
