import React from 'react';
import useDialogConfig from '../_hooks/useDialogConfig';
import FiltersProjectDialog from '../../dialogs/filters.projects';
import useDialogRouter from '../_hooks/useDialogRouter';
import usePageRouter from '../_hooks/usePageRouter';

export default function Launcher(props) {
  const config = useDialogConfig();

  const router = config.params.parentDialogId ?
    useDialogRouter(config.params.parentDialogId) :
    usePageRouter();

  return (
    <FiltersProjectDialog
      router={router}
      showPast={config.params.showPast}
      onCancel={props.onClose}
    />
  );
};
