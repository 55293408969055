import React from 'react';
import {
  BookmarkIcon,
  ChatBubbleBottomCenterTextIcon,
  EnvelopeIcon,
  HomeIcon,
  UsersIcon,
  SparklesIcon,
  CheckCircleIcon,
  ChartBarIcon,
  DocumentCheckIcon,
  BookOpenIcon,
  CursorArrowRaysIcon
} from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import Network from '../components/Network';
import Account from './Account';
import useRouter from '../hooks/useRouter';
import logo from '../../assets/images/logo.png';
import classNames from 'classnames';
import { useConfig } from '@lore/config';
import NavigationLink from './NavigationLink';
import isActiveChecker from '../utils/isActiveChecker';
import usePendingEvaluationsCount from './my-evaluations/usePendingEvaluationsCount';
import usePendingInvitationsCount from './my-invitations/usePendingInvitationsCount';
import useActiveInvitationsCount from './my-participating/useActiveInvitationsCount';
import usePendingExpiredCount from './my-expired/usePendingExpiredCount';
import useDraftOpportunityCount from './my-expired/useDraftOpportunityCount';
import useActiveOpportunityCount from './my-expired/useActiveOpportunityCount';
import ContactUs from './ContactUs';
// import usePendingInvitationsCount from './my-projects/use';
// import usePendingInvitationsCount from './my-invitations/usePendingInvitationsCount';

function NavigationLabel(props) {
  const { children } = props;

  return (
    <h3 className="text-cs-gray-900 text-xs font-semibold tracking-wider">
      {children}
    </h3>
  );
}

function NavigationGroup(props) {
  const { children } = props;

  return (
    <div className="px-8 space-y-3">
      {children}
    </div>
  );
}

export default function Navigation() {
  const config = useConfig();

  const { location, history } = useRouter();
  const currentRoute = location.pathname;

  const createConnectCount = (
    useDraftOpportunityCount() +
    useActiveOpportunityCount() +
    useActiveInvitationsCount()
  );

  const home = [
    { name: 'Home', icon: HomeIcon, to: '/dashboard', startsWithOneOf: ['/dashboard'] },
    { name: 'Respond to Invites', icon: EnvelopeIcon, to: '/my-invitations', startsWithOneOf: ['/my-invitations'], count: usePendingInvitationsCount(), highlight: true },
    { name: 'Create and Connect', icon: CursorArrowRaysIcon, to: '/my-opportunities', startsWithOneOf: ['/my-opportunities', '/my-leading', '/my-participating'], count: createConnectCount, highlight: true },
    { name: 'Close Activities', icon: CheckCircleIcon, to: '/my-expired', startsWithOneOf: ['/my-expired', '/expired'], count: usePendingExpiredCount(), highlight: true },
    { name: 'Share Impact', icon: ChartBarIcon, to: '/my-evaluations', startsWithOneOf: ['/my-evaluations'], count: usePendingEvaluationsCount(), highlight: true },
    { name: 'See Past Activities', icon: DocumentCheckIcon, to: '/my-past', startsWithOneOf: ['/my-past'] },
    { name: 'View Conversations', icon: ChatBubbleBottomCenterTextIcon, to: '/messages', startsWithOneOf: ['/messages'] }
  ];

  // const myNavigation = [
  //   { name: 'Invitations', icon: EnvelopeIcon, to: '/my-invitations', startsWithOneOf: ['/my-invitations'], count: usePendingInvitationsCount(), highlight: true },
  //   // { name: 'Leading', icon: UserGroupIcon, to: '/my-leading', startsWithOneOf: ['/my-leading', '/leading'] },
  //   { name: 'Leading / Participating', icon: SparklesIcon, to: '/my-opportunities', startsWithOneOf: ['/my-opportunities', '/my-leading', 'my-participating'] },
  //   { name: 'Expired', icon: ClockIcon, to: '/my-expired', startsWithOneOf: ['/my-expired', '/expired'] },
  //   // { name: 'Participating', icon: CalendarIcon, to: '/my-participating', startsWithOneOf: ['/my-participating', '/participating'], count: useActiveInvitationsCount() },
  //   { name: 'Past', icon: ArchiveBoxIcon, to: '/my-past', startsWithOneOf: ['/my-past'] },
  //   { name: 'Evaluations', icon: ClipboardDocumentListIcon, to: '/my-evaluations', startsWithOneOf: ['/my-evaluations'], count: usePendingEvaluationsCount(), highlight: true }
  // ];

  const exploreNavigation = [
    { name: 'People', icon: UsersIcon, to: '/people', startsWithOneOf: ['/people', '/educators', '/partners'] },
    // { name: 'Partners', icon: UsersIcon, to: '/partners-populate', startsWithOneOf: ['/partners'] },
    // { name: 'Educators', icon: UsersIcon, to: '/educators-populate', startsWithOneOf: ['/educators'] },
    // { name: 'Projects', icon: FolderIcon, to: '/projects-populate', startsWithOneOf: ['/projects'] },
    // { name: 'Events', icon: CalendarIcon, to: '/events-populate', startsWithOneOf: ['/events'] },
    { name: 'Opportunities', icon: SparklesIcon, to: '/opportunities', startsWithOneOf: ['/projects', '/events', '/offers', '/opportunities'] },
    // { name: 'Inspiration (Past)', icon: ClockIcon, to: '/projects2-populate', startsWithOneOf: ['/projects'] },
    // { name: 'Offers', icon: NewspaperIcon, to: '/offers-populate', startsWithOneOf: ['/offers'] },
    { name: 'Impact Stories', icon: BookOpenIcon, to: '/stories', startsWithOneOf: ['/stories'] },
    { name: 'Bookmarks', icon: BookmarkIcon, to: '/bookmarks', startsWithOneOf: ['/bookmarks'] },
    // { name: 'Past Projects', icon: FolderIcon, to: '/past-projects-populate', startsWithOneOf: ['/past-projects'] },
    // { name: 'Past Events', icon: CalendarIcon, to: '/past-events-populate', startsWithOneOf: ['/past-events'] },
  ];

  return (
    <div className="space-y-12 bg-white">
      <NavigationGroup>
        <Link to="/">
          <img
            className={classNames(
              'h-14',
              config.flags.grayscale && 'grayscale'
            )}
            src={logo}
            alt="logo"
          />
        </Link>
      </NavigationGroup>
      <div className="space-y-8">
        <NavigationGroup>
          <NavigationLabel>
            Your Network Resources
          </NavigationLabel>
          <Network />
        </NavigationGroup>
        <NavigationGroup>
          <NavigationLabel>
            Your Account
          </NavigationLabel>
          <div className="-mx-3">
            <Account />
          </div>
        </NavigationGroup>
        <NavigationGroup>
          <div className="space-y-1">
            {home.map((item) => (
              <NavigationLink
                // key={item.name}
                // item={item}
                key={item.name}
                name={item.name}
                icon={item.icon}
                count={item.count}
                highlight={item.highlight}
                // onClick={() => history.push(item.to)}
                to={item.to}
                isActive={item.startsWithOneOf ?
                  isActiveChecker.startsWithOneOf(currentRoute, item.startsWithOneOf) :
                  isActiveChecker.startsWith(currentRoute, item.to)
                }
              />
            ))}
          </div>
        </NavigationGroup>
        {/*<NavigationGroup>*/}
        {/*  <NavigationLabel>*/}
        {/*    Your Stuff*/}
        {/*  </NavigationLabel>*/}
        {/*  <div className="space-y-1">*/}
        {/*    {myNavigation.map((item) => (*/}
        {/*      <NavigationLink*/}
        {/*        // key={item.name}*/}
        {/*        // item={item}*/}
        {/*        key={item.name}*/}
        {/*        name={item.name}*/}
        {/*        icon={item.icon}*/}
        {/*        count={item.count}*/}
        {/*        highlight={item.highlight}*/}
        {/*        // onClick={() => history.push(item.to)}*/}
        {/*        to={item.to}*/}
        {/*        isActive={item.startsWithOneOf ?*/}
        {/*          isActiveChecker.startsWithOneOf(currentRoute, item.startsWithOneOf) :*/}
        {/*          isActiveChecker.startsWith(currentRoute, item.to)*/}
        {/*        }*/}
        {/*      />*/}
        {/*    ))}*/}
        {/*  </div>*/}
        {/*</NavigationGroup>*/}
        <NavigationGroup className="flex-grow flex flex-col">
          <NavigationLabel>
            Explore
          </NavigationLabel>
          <div className="space-y-1">
            {exploreNavigation.map((item) => (
              <NavigationLink
                key={item.name}
                name={item.name}
                icon={item.icon}
                count={item.count}
                highlight={item.highlight}
                // onClick={() => history.push(item.to)}
                to={item.to}
                isActive={item.startsWithOneOf ?
                  isActiveChecker.startsWithOneOf(currentRoute, item.startsWithOneOf) :
                  isActiveChecker.startsWith(currentRoute, item.to)
                }
              />
            ))}
          </div>
        </NavigationGroup>
        {config.intercom.enabled && (
          <NavigationGroup>
            <div className="pt-4">
              <ContactUs />
            </div>
          </NavigationGroup>
        )}
      </div>
    </div>
  )
}
