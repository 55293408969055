import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Avatar from '../../../components/Avatar';
import Loader from '../../../components/Loader';

export default function Creator(props) {
  const { project } = props;

  const creator = useConnect('user.byId', {
    id: project.data.creatorId
  });

  if (creator.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <div className="flex flex-1 items-center space-x-4">
      <Avatar size={12} src={creator.data.avatarUrl}/>
      <div className="flex flex-col">
        <div className="text-lg font-semibold text-cs-gray-900">
          {creator.data.firstName} {creator.data.lastName}
        </div>
        <div className="text-sm text-cs-gray-500 space-x-1">
          <span>Project Creator</span>
        </div>
      </div>
    </div>
  );
};
