import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import { DialogManager } from '../../dialogs_new/DialogManager';
import useDialogLauncher from '../../dialogs-routable/_hooks/useDialogLauncher';

MessageOffer.propTypes = {
  message: PropTypes.object.isRequired
};

export default function MessageOffer(props) {
  const { message } = props;

  const launch = useDialogLauncher();

  const offer = useConnect('offer.byId', {
    id: message.data.offerId
  }, {
    enabled: !!message.data.offerId
  });

  const activity = useConnect('tag.byId', {
    id: offer.data.activityId
  }, {
    enabled: !!offer.data.activityId
  });

  if (
    !message.data.offerId ||
    offer.state === PayloadStates.FETCHING
  ) {
    return null;
  }

  function onClick() {
    launch(DialogManager.Offer.Config(offer.id));
  }

  return (
    <span>
      Related to <span className="text-cs-orange-500 cursor-pointer hover:text-cs-yellow-500" onClick={onClick}>{offer.data.title || activity.data.name}</span> (Offer)
    </span>
  );
}
