import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import SiteCreateDialog from '../../dialogs/site.create';

export default function Launcher(props) {
  const queryClient = useQueryClient();

  return (
    <SiteCreateDialog
      onCancel={props.onClose}
      onSubmit={function(request) {
        queryClient.invalidateQueries({ queryKey: ['role'] });
      }}
    />
  );
};
