import _ from 'lodash';

export default function mapAttributes(attributes) {
  return function(query, selectedParams = [], timestamp) {
    const selectedAttributes = _.pick(attributes, selectedParams);

    const params = _.mapValues(selectedAttributes, function(attribute, name) {
      return attribute.param(query, name);
    });

    const queries = _.mapValues(selectedAttributes, function(attribute, name) {
      const param = params[name];
      return attribute.query(param, timestamp);
    });

    if (selectedParams.length > 0) {
      return {
        params: params,
        queries: queries,
        where: _.reduce(queries, function(result, query) {
          if (query) {
            result.push(query);
          }

          return result;
        }, [])
      };
    }

    return {
      params: params,
      queries: queries,
      where: []
      // where: _.reduce(queries, function(result, query) {
      //   if (query) {
      //     result.push(query);
      //   }
      //
      //   return result;
      // }, [])
    };

  }
}
