import { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import { useQueryMutation } from '../../../hooks/@lore/query-connect';

FindOrCreateOrganization.propTypes = {
  place_id: PropTypes.string,
  user: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

export default function FindOrCreateOrganization(props) {
  const { place_id, user, data, onSubmit } = props;

  const organization = useConnect('organization.first', {
    where: {
      eager: {
        $where: place_id !== data.name ? {
          place_id: data.place_id
        } : {
          name: {
            $ilike: `${data.name}`
          }
        }
      }
    }
  });

  const mutation = useQueryMutation('organization.create');

  useEffect(() => {
    if (organization.state === PayloadStates.RESOLVED) {
      onSubmit({
        request: organization,
        saving: false,
        success: true,
        error: false
      });
    }

    if (organization.state === PayloadStates.NOT_FOUND) {
      mutation.mutate(_.extend({
        userId: user.id
      }, _.omit(data, ['title'])));
    }
  }, [organization.state])

  useEffect(() => {
    if (mutation.success) {
      onSubmit(mutation);
    }
  }, [mutation.success]);

  return null;
};
