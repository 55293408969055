import React from 'react';
import { Helmet } from 'react-helmet-async';
import { stringify } from 'query-string';
import Redirect from '../../components/Redirect';
import { useUser } from '@lore/auth';
import PageHeader from '../people-people/PageHeader';

export default function Layout(props) {
  const currentUser = useUser();

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Discover offers</title>
      </Helmet>
      <PageHeader
        title="Discover offers"
        subtitle={(
          <>
            Check out the exciting offers that community partners have made
            available to educators and learners below. Use filters to find the
            opportunities you're looking for.
          </>
        )}
      />
      <Redirect
        to={{
          pathname: '/offers',
          search: stringify({
            networks: [currentUser.data.networkId],
            locationCondition: JSON.stringify({
              address: currentUser.data.zipCode,
              latitude: currentUser.data.latitude,
              longitude: currentUser.data.longitude,
              radius: 50
            })
          })
        }}
        replace={true}
      />
    </div>
  );
}
