import { useEffect } from 'react';

export default function ScrollToTop(props) {
  useEffect(() => {
    const scrollAnchor = document.getElementById('scrollAnchor');

    const params = {
      // behavior: 'smooth',
      top: 0,
      left: 0
    };

    scrollAnchor ?
      scrollAnchor.scrollTo(params) :
      window.scrollTo(params);
  }, []);

  return null;
};
