import React from 'react';
import WelcomeDialog from '../../dialogs/Welcome';

export default function Launcher(props) {
  return (
    <WelcomeDialog
      onCancel={props.onClose}
    />
  );
};
