import useImageMimeType from './useImageMimeType';

export default function useFilestackImage(imageUrl) {
  let url = imageUrl;
  const mimeType = useImageMimeType(imageUrl);
  const origin = 'https://cdn.filestackcontent.com';

  if (
    (
      mimeType === 'image/heif' ||
      mimeType === 'image/heic'
    ) && _.startsWith(url, origin)
  ) {
    /*
     * Converts a URL like this:
     * https://cdn.filestackcontent.com/NsUsL5EJRnWWDKybGaID
     * to a URL like this:
     * https://cdn.filestackcontent.com/output=format:jpg/NsUsL5EJRnWWDKybGaID
     */
    url = url.split(origin).join(`${origin}/output=format:jpg`);
  }

  return url;
};
