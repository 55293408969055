import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import SolidButton from '../../../components/Button/SolidButton';
import FormTemplate2 from '../../_post-opportunity/FlowTemplate';
import FormSection from '../../_post-opportunity/forms/FormSection';
import useTags from '../../../hooks/useTags';
import TagTypes from '../../../constants/TagTypes';
import useProjectTags from '../../../hooks/useProjectTags';
import NodeEntries from '../NodeEntries';
import { useQueryMutation } from '@lore/query-connect';

Form.propTypes = {
  user: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func,
  onSkip: PropTypes.func
};

export default function Form(props) {
  const {
    user,
    project,
    navigation,
    onNext,
    onPrevious,
    onSkip,
    onExit,
    FormTemplate = FormTemplate2,
    buttonText = 'Next'
  } = props;

  const [data, setData] = useState({
    // 'field'
  });
  const [showErrors, setShowErrors] = useState(false);
  const [exit, setExit] = useState(false);

  const mutation= useQueryMutation('project.update', project);
  const { saving, success, error, request } = mutation;

  const commitments = useTags(TagTypes.Commitment);
  const projectCommitments = useProjectTags(project, TagTypes.Commitment);

  useEffect(() => {
    if (success) {
      exit ? onExit(data) : onNext(data);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate(data);
  }

  function onSubmitAndExit() {
    onSubmit();
    setExit(true);
  }

  // function onChange(name, value) {
  //   setData(_.merge({}, data, {
  //     [name]: value
  //   }));
  // }

  function onChange(name, value) {
    const nextData = _.merge({}, data);
    nextData[name] = value;
    setData(nextData);
  }

  return (
    <Overlay isVisible={saving}>
      <FormErrors data={data} validators={{
        // firstName: [validators.isRequired],
        // lastName: [validators.isRequired]
      }}>
        {({ errors, hasError }) => {
          return (
            <FormTemplate
              onExit={() => hasError ? setShowErrors(true) : onSubmitAndExit()}
              onPrevious={onPrevious}
              navigation={navigation}
              alert={error ? (
                <RequestError request={request} />
              ) : showErrors ? (
                <ErrorMessage>
                  Please fill in all required fields.
                </ErrorMessage>
              ) : null}
              body={(
                <>
                  <FormSection
                    // title="Desired Frequency"
                    // subtitle="Select all that apply."
                    // tooltip="Which of the following time commitments would be acceptable?"
                  >
                    <NodeEntries
                      project={project}
                      tags={commitments}
                      nodes={projectCommitments}
                    />
                  </FormSection>
                </>
              )}
              footer={(
                <>
                  <SolidButton onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
                    {buttonText}
                  </SolidButton>
                </>
              )}
            />
          );
        }}
      </FormErrors>
    </Overlay>
  );
};
