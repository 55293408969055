import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FormTemplate from '../../dialogs_common/FormTemplate';
import { FolderIcon } from '@heroicons/react/24/outline';
import { OutlineButton, SolidButton } from '../../components/Button';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';
import { useQueryMutation } from '../../../hooks/@lore/query-connect';
import { useUser } from '@lore/auth';

export default function ProjectConfirmation(props) {
  const {
    site,
    onCancel,
    onPrevious,
    onNext
  } = props;

  const currentUser = useUser();

  const mutation = useQueryMutation('project.create');
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      onNext(request);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate({
      creatorId: currentUser.id,
      siteId: site.id
    });
  }

  return (
    <FormTemplate
      // className="w-screen max-w-screen-sm"
      icon={(
        <div className="flex justify-center mb-4">
          <FolderIcon className="text-cs-orange-500 h-12 w-12" />
        </div>
      )}
      title="Let's create your project!"
      subtitle={(
        <>
          Projects are activities without a set date. They can happen once or multiple
          times and allow you to collaborate on the idea and the schedule with a community
          partner.
        </>
      )}
      onClose={onCancel}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onPrevious}>
            Back to options
          </OutlineButton>
          <SolidButton onClick={onSubmit}>
            Create project
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
