import React from 'react';
import PropTypes from 'prop-types';
import CheckboxField from '../../../forms/_fields_v2/CheckboxField';
import Card from '../../../components/Card';
import classNames from 'classnames';

CheckboxCard.propTypes = {
  label: PropTypes.node.isRequired,
  description: PropTypes.node,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default function CheckboxCard(props) {
  const {
    label,
    description,
    checked,
    onChange,
    children,
    selectable = true
  } = props;

  return (
    <Card
      rounded={false}
      border={false}
      className={classNames(
        'p-4',
          'rounded-cs-10',
          '-m-px border border-cs-gray-100',
          'space-y-3'
      )}
    >
      <CheckboxField
        label={label}
        description={description}
        checked={checked}
        onChange={onChange}
        selectable={selectable}
      />
      {children}
    </Card>
  );
}
