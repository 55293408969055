import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Entries from '../project-create/Entries';
import Overlay from '../../forms/_common/Overlay';
import { useQueryMutation } from '../../../hooks/@lore/query-connect';

NodeEntries.propTypes = {
  offer: PropTypes.object.isRequired,
  tags: PropTypes.object.isRequired,
  nodes: PropTypes.object.isRequired
};

export default function NodeEntries(props) {
  const {
    offer,
    tags,
    nodes,
    children
  } = props;

  const [_request, setRequest] = useState(null);

  const mutation_create = useQueryMutation('tagNode.create');
  const mutation_destroy = useQueryMutation('tagNode.destroy2');

  function onSelectTag(tag) {
    const node = _.find(nodes.data, function(node) {
      return node._tag.id === tag.id;
    });

    if (!node) {
      mutation_create.mutate({
        offerId: offer.id,
        tagId: tag.id
      }, {
        onSuccess: payload => nodes._tagNodes._add(payload)
      });
    } else {
      mutation_destroy.mutate(node._tagNode, {
        onSuccess: payload => nodes._tagNodes._remove(payload)
      });
    }
  }

  return (
    <Overlay isVisible={mutation_create.saving || mutation_destroy.saving}>
      <>
        {/*<label>*/}
        {/*  Does your idea primarily focus on serving any of the following groups of youth?*/}
        {/*</label>*/}
        <div className="grid grid-cols-1 gap-3">
          <Entries
            tags={tags}
            nodes={nodes}
            onSelectTag={onSelectTag}
          >
            {children}
          </Entries>
        </div>
      </>
    </Overlay>
  );
}
