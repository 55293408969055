import TagTypes from '../../constants/TagTypes';

const REQUIRED_FIELDS_PAST = {
  $and: [
    // Must provide activity type and description
    {
      activityId: {
        $exists: true
      },
      siteId: {
        $exists: true
      },
      interactionDescription: {
        $not: '',
        $exits: true
      }
    },
    // Date must be provided
    {
      $or: [
        {
          specificDate: 'Yes',
          date: {
            $exits: true
          }
        },
        {
          specificDate: 'No',
          startDate: {
            $exists: true
          },
          endDate: {
            $exits: true
          }
        }
      ]
    },
    // Must specify a participant
    {
      tags: {
        $tag_type_count_gte: {
          type: TagTypes.Participant,
          value: 1
        }
      }
    },
    // Must specify a method of interaction
    {
      tags: {
        $tag_type_count_gte: {
          type: TagTypes.InteractionType,
          value: 1
        }
      }
    },
    // Must be visible, completed, and not cancelled
    {
      cancelled: false,
      completed: true,
      // published: true
    }
  ]
}

export default REQUIRED_FIELDS_PAST;
