import React from 'react';
import StatusTag from '../_common_evaluation/StatusTag';
import ProjectEvaluationUserStatusCard from '../../pages/evaluations/ProjectEvaluation/ProjectEvaluationUserStatusCard';

export default function Header(props) {
  const { project, evaluationProjectEducator } = props;

  return (
    <div className="flex flex-col space-y-6">
      <div className="space-y-2">
        <div className="flex space-x-4">
          <div className="text-sm text-gray-500">
            Evaluation
          </div>
          <div className="flex space-x-2">
            <StatusTag evaluation={evaluationProjectEducator} />
          </div>
        </div>
        <div className="text-3xl font-bold text-cs-gray-700">
          Project Evaluation (Educator)
        </div>
      </div>
      <ProjectEvaluationUserStatusCard
        evaluation={evaluationProjectEducator}
        project={project}
        showViewLink={false}
      />
    </div>
  );
};
