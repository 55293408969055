import React from 'react';
import classNames from 'classnames';

export default function SaveExitButton(props) {
  const { onClick } = props;

  return (
    <div className="z-10 absolute top-8 right-12">
      <button
        type="button"
        className={classNames(
          'bg-white hover:bg-cs-gray-100 hover:shadow py-2 px-5 rounded-xl text-cs-gray-500 cursor-pointer',
          'border border-cs-gray-500'
        )}
        onClick={onClick}
      >
        Save and exit
      </button>
    </div>
  );
};
