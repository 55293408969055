import React from 'react';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import { useConnect } from '../../../hooks/@lore/query-connect';
import Card from '../../components/Card';
import ComposableProjectHeader from '../../components_cards/ComposableProjectHeader';
import TagRow from '../../components_cards/tags/TagRow';
import ProjectTag from '../../components_cards/tags/ProjectTag';
import OutlineButtonGray from '../../components/Button/OutlineButton.gray';
import ParticipantTag from '../../components_cards/tags/ParticipantTag';
import { DialogManager } from '../../dialogs_new/DialogManager';
import useDialogLauncher from '../../dialogs-routable/_hooks/useDialogLauncher';
import CompletedTag from '../../components_cards/tags/_resource/CompletedTag';
import CancelledTag from '../../components_cards/tags/_resource/CancelledTag';
import ExpiredTag from '../../components_cards/tags/_resource/ExpiredTag';
import Creator from '../my-invitations/Question/Creator';
import { SolidButton } from '../../components/Button';

export default function Question(props) {
  const { question } = props;

  const launch = useDialogLauncher();

  const project = useConnect('project.byId', {
    id: question.data.projectId
  });

  const creator = useConnect('user.byId', {
    id: project.data.creatorId
  }, {
    enabled: !!project.data.creatorId
  });

  function onViewProject() {
    launch(DialogManager.QuestionView.Config(question.id));
  }

  function onMessageCreator() {
    launch(DialogManager.Messages.Config(creator.id));
  }

  if (project.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <Card divider={true}>
      <ComposableProjectHeader
        project={project}
        showTags={false}
        tags={(
          <TagRow>
            <ProjectTag />
            <ParticipantTag />
            <CompletedTag resource={project} />
            <CancelledTag resource={project} />
            <ExpiredTag resource={project} />
          </TagRow>
        )}
        showPartners={false}
        showDistance={true}
      />
      <div className="flex justify-between p-4 bg-gray-50">
        <div className="flex space-x-2">
          <Creator project={project} />
        </div>
        <div className="flex space-x-2">
          <OutlineButtonGray
            label="View project"
            onClick={onViewProject}
          />
          <SolidButton
            label={`Message ${creator.data.firstName || '...'}`}
            onClick={onMessageCreator}
          />
        </div>
      </div>
    </Card>
  );
}
