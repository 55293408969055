import React from 'react';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import Claims from './Claims';
import EmptyState, { EmptyStateActions } from '../../components/EmptyState';
import { LinkButton, OutlineButton } from '../../components/Button';
import SectionTitle from '../project-manage/SectionTitle';
import useDialogLauncher from '../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../dialogs_new/DialogManager';

export default function Layout(props) {
  const { offer } = props;

  const launch = useDialogLauncher();

  function onClick(user) {
    launch(DialogManager.Messages.Config(user.id));
  }

  if (offer.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <>
      <SectionTitle
        title="Interested"
        subtitle="Educators who expressed positive interest in the offer."
      >
        <div className="grid grid-cols-1 gap-4">
          <Claims
            offer={offer}
            responses={[
              'Connect'
            ]}
            emptyState={(
              <EmptyState
                title="No educators have expressed interest yet."
                subtitle="The more people you invite, the better your chances of finding a match!"
              >
                <EmptyStateActions>
                  <LinkButton
                    button={OutlineButton}
                    to={`/offer/${offer.id}/invite-populate`}
                    label="Find Educators"
                  />
                </EmptyStateActions>
              </EmptyState>
            )}
            onViewMessages={onClick}
          />
        </div>
      </SectionTitle>
      <SectionTitle
        title="Invited"
        subtitle="Educators who were invited to connect but have not yet responded."
      >
        <div className="grid grid-cols-1 gap-4">
          <Claims
            offer={offer}
            responses={[
              'Pending'
            ]}
            emptyState={(
              <EmptyState
                title="You haven't invited anybody yet."
                subtitle="Explore your community and find some educators to invite to your Offer."
              >
                <EmptyStateActions>
                  <LinkButton
                    button={OutlineButton}
                    to={`/offer/${offer.id}/invite-populate`}
                    label="Find Educators"
                  />
                </EmptyStateActions>
              </EmptyState>
            )}
            onViewMessages={onClick}
          />
        </div>
      </SectionTitle>
      <SectionTitle
        title="Declined"
        subtitle="Educators who are either uninterested or unable to participate."
      >
        <div className="grid grid-cols-1 gap-4">
          <Claims
            offer={offer}
            responses={[
              'Decline'
            ]}
            emptyState={(
              <EmptyState
                title="Educators who turn down your invitation will appear here."
                // subtitle="Educators who are unable to participate will show up here."
              />
            )}
            onViewMessages={onClick}
          />
        </div>
      </SectionTitle>
    </>
  );
};
