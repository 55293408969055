import React from 'react';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import ParentLayout from '../Layout';
import Navigation from '../../../dialogs_common/Navigation';
import Urls from '../Urls';
import Form from './Form';
import { PayloadStates } from '@lore/utils';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { location, history, match } = useRouter();
  const user = useUser();
  const project = useConnect('project.byId', {
    id: Number(match.params.projectId)
  });

  if (project.state === PayloadStates.FETCHING) {
    return null;
  }

  return (
    <ParentLayout
      project={project}
      page="Budget"
      title="What's your project budget?"
      description={(
        <div className="space-y-4">
          <div>
            Grant funds can be used for materials, equipment, travel, compensating
            community partners, community celebrations, and other expenses related to
            community engagement. If you are engaging a community partner for more than
            four hours of planning and doing, we highly encourage you to ask the community
            partner if she or he needs compensation for associated time and materials.
          </div>
          <div>
            List the items you anticipate needing with their associated costs. Include
            any supplies, materials, equipment, travel expenses, and partner fees. If
            you don't know exact costs at this point, do your best to estimate.
          </div>
        </div>
      )}
    >
      <Form
        user={user}
        project={project}
        navigation={(
          <Navigation v5={true} activeStep={19} steps={19}/>
        )}
        onPrevious={(newData) => history.push(Urls(project).GRANT)}
        onNext={(newData) => history.push(Urls(project).SEARCH_EXPLANATION)}
        onSkip={() => history.push(Urls(project).SEARCH_EXPLANATION)}
        onExit={() => history.push(Urls(project).PROJECT_EDIT)}
      />
    </ParentLayout>
  );
};
