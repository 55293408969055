import { PayloadStates } from '@lore/utils';
import useActiveClaims from './useActiveClaims';
import useActiveInvitations from './useActiveInvitations';
import useActiveQuestions from './useActiveQuestions';

export default function useActiveInvitationsCount(props) {
  const questions = useActiveQuestions();
  const invitations = useActiveInvitations()
  const claims = useActiveClaims();

  if (
    questions.state === PayloadStates.FETCHING ||
    invitations.state === PayloadStates.FETCHING ||
    claims.state === PayloadStates.FETCHING
  ) {
    return '...';
  }

  if (
    questions.data.length === 0 &&
    invitations.data.length === 0 &&
    claims.data.length === 0
  ) {
    return null;
  }

  return (
    questions.data.length +
    invitations.data.length +
    claims.data.length
  );
}
