import React from 'react';
import PropTypes from 'prop-types';
import { ArrowTopRightOnSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useQueryMutation } from '@lore/query-connect';
import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import Card from '../../../components/Card';

Upload.propTypes = {
  upload: PropTypes.object
};

export default function Upload(props) {
  const { upload } = props;

  const queryClient = useQueryClient();

  const mutation = useQueryMutation('upload.destroy', upload);
  const { saving, success, error, request } = mutation;

  function onDestroy() {
    mutation.mutate(null, {
      onSuccess: function() {
        queryClient.invalidateQueries({ queryKey: ['upload', 'find'] });
      }
    });
  }

  return (
    <Card className={classNames(
      'flex items-center px-4 py-4 space-x-4 justify-between',
      saving && 'opacity-50'
    )}>
      <a className="flex flex-1x items-center text-cs-orange-500 space-x-1 hover:underline" href={upload.data.url} target="_blank">
        <ArrowTopRightOnSquareIcon className="h-5 w-5" />
        <span className="font-semibold">
          {upload.data.name}
        </span>
      </a>
      <div className="cursor-pointer text-cs-gray-500  hover:bg-cs-orange-100 -m-2 p-2 rounded-full" onClick={onDestroy}>
        <TrashIcon className="h-6 w-6" />
      </div>
    </Card>
  );
}
