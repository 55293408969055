import React from 'react';
import GirlRaisingHandJpeg from '../../../assets/images/girl-raising-hand-square.jpg';

function Bubble(props) {
  const {
    width,
    height,
    left,
    right,
    bottom,
    src,
    className
  } = props;

  if (src) {
    return (
      <img
        className={className}
        style={{
          width: `${width}px`,
          height: `${height}px`,
          [left ? 'left' : 'right']: `${left || right}px`,
          bottom: `${bottom}px`,
        }}
        src={src}
      />
    )
  }

  return (
    <div
      className={className}
      style={{
        width: `${width}px`,
        height: `${height}px`,
        [left ? 'left' : 'right']: `${left || right}px`,
        bottom: `${bottom}px`,
      }}
    />
  );
}

export default function Bubbles(props) {
  return (
    <>
      <Bubble
        className="hidden md:block absolute bg-cs-orange-500 opacity-50 rounded-full"
        width={87}
        height={87}
        left={13}
        bottom={214}
      />
      <Bubble
        className="absolute bg-cs-yellow-500 opacity-50 rounded-full"
        width={224}
        height={224}
        left={-12}
        bottom={-61}
      />
      <Bubble
        className="absolute bg-cs-orange-500 opacity-50 rounded-full"
        width={141}
        height={141}
        left={141}
        bottom={-90}
      />
      <Bubble
        className="hidden md:block absolute bg-cs-yellow-500 opacity-50 rounded-full"
        width={284}
        height={284}
        right={226}
        bottom={-161}
      />
      <Bubble
        className="hidden md:block absolute bg-cs-gray-900 opacity-50 rounded-full"
        width={400}
        height={400}
        right={-44}
        bottom={-119}
        src={GirlRaisingHandJpeg}
      />
      <Bubble
        className="block md:hidden absolute bg-cs-gray-900 opacity-50 rounded-full"
        width={200}
        height={200}
        right={-22}
        bottom={-60}
        src={GirlRaisingHandJpeg}
      />
    </>
  );
};
