import React from 'react';
import _ from 'lodash';

export default function useHasChildren(children) {
  if (!children) {
    return false;
  }

  const result = React.Children.map(children, function(child) {
    return (
      _.isString(child.type) ||
      !!child.type(child.props)
    );
  });

  return !!_.find(result);
}
