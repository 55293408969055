import React from 'react';
import { CalendarIcon } from '@heroicons/react/24/outline';
import useRouter from '../../hooks/useRouter';
import Urls from '../offer-create/Urls';
import DataCard from '../profile-edit/DataCard';
import { useUser } from '@lore/auth';
import { formatDate } from '../../utils/dateTimeUtils';
import usePageRouter from '../../dialogs-routable/_hooks/usePageRouter';

export default function Date(props) {
  const { offer } = props;
  const { history } = usePageRouter();

  const currentUser = useUser();

  const subtitle = offer.data.specificDate === 'Yes' ?
    formatDate(offer.data.date, currentUser.data.timezone) :
    formatDate(offer.data.startDate, offer.data.endDate, currentUser.data.timezone);

  return (
    <DataCard
      title="Date"
      icon={CalendarIcon}
      subtitle={subtitle}
      onClick={() => history.push(Urls(offer).DATE)}
    />
  );
};
