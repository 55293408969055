import React from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

export default function Alert(props) {
  const {
    title,
    subtitle,
    icon = ExclamationTriangleIcon,
    color = 'yellow'
  } = props;

  const colorMap = {
    yellow: {
      bg: 'bg-cs-yellow-50',
      icon: 'text-cs-yellow-800',
      title: 'text-cs-yellow-800',
      subtitle: 'text-cs-yellow-800',
    },
    blue: {
      bg: 'bg-cs-blue-100',
      icon: 'text-cs-blue-800',
      title: 'text-cs-blue-800',
      subtitle: 'text-cs-blue-800'
    },
    gray: {
      bg: 'bg-cs-gray-200',
      icon: 'text-cs-gray-900',
      title: 'text-cs-gray-900',
      subtitle: 'text-cs-gray-900'
    }
  }

  return (
    <div className={`rounded-md ${colorMap[color].bg} p-4 shadow-md`}>
      <div className="flex space-x-2">
        <div className="flex-shrink-0">
          <props.icon className={classNames(
            'h-7 w-7 mt-0.5',
            colorMap[color].icon
          )} aria-hidden="true" />
        </div>
        <div className="space-y-0.5">
          <h3 className={`text-lg font-semibold ${colorMap[color].title}`}>
            {title}
          </h3>
          <div className={`text-base ${colorMap[color].subtitle}`}>
            {subtitle}
          </div>
        </div>
      </div>
    </div>
  );
}
