import React from 'react';
import ProfileDeleteDialog from '../../dialogs/profile.delete';

export default function Launcher(props) {
  return (
    <ProfileDeleteDialog
      onCancel={props.onClose}
    />
  );
};
