import React from 'react';
import { Helmet } from 'react-helmet-async';
import Stories from './Stories';
import useRouter from '../../hooks/useRouter';
import PageHeader from '../people-people/PageHeader';

export default function Layout(props) {
  const router = useRouter();

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Explore Impact Stories</title>
      </Helmet>
      <PageHeader
        title="Explore Impact Stories"
        subtitle={(
          <>
            Discover projects and events brought to life by educators, learners,
            and partners like you! Learn about their journeys and the positive impact
            they've made. These stories could spark your next idea or collaboration!
          </>
        )}
      />
      <Stories
        router={router}
      />
    </div>
  );
}
