import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useDialogConfig from '../_hooks/useDialogConfig';
import OfferDuplicateDialog from '../../dialogs/offer.duplicate';

export default function Launcher(props) {
  const config = useDialogConfig();

  const offer = useConnect('offer.byId', {
    id: config.params.offerId
  });

  if (offer.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <OfferDuplicateDialog
      offer={offer}
      onCancel={props.onClose}
    />
  );
};
