import React from 'react';
import { Helmet } from 'react-helmet-async';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import Questions from './Questions';
import Invitations from './Invitations';
import Claims from './Claims';
import PageHeader from '../people-people/PageHeader';
import usePendingQuestions from './usePendingQuestions';
import usePendingInvitations from './usePendingInvitations';
import usePendingClaims from './usePendingClaims';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import EmptyState, { EmptyStateActions } from '../../components/EmptyState';
import { LinkButton, SolidButton } from '../../components/Button';

function Header(props) {
  const { children } = props;

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Respond to invites</title>
      </Helmet>
      <PageHeader
        title="Respond to invites"
        subtitle={(
          <>
            See the projects, events, and offers you've been invited to get involved
            with. Please respond in a timely manner to help keep projects moving forward.
          </>
        )}
      />
      <div className="space-y-8">
        {children}
      </div>
    </div>
  );
}

export default function Layout(props) {
  const questions = usePendingQuestions();
  const invitations = usePendingInvitations();
  const claims = usePendingClaims();

  if (
    questions.state === PayloadStates.FETCHING ||
    invitations.state === PayloadStates.FETCHING ||
    claims.state === PayloadStates.FETCHING
  ) {
    return (
      <Header>
        <Loader />
      </Header>
    );
  }

  if (
    questions.data.length === 0 &&
    invitations.data.length === 0 &&
    claims.data.length === 0
  ) {
    return (
      <Header>
        <EmptyState
          icon={EnvelopeIcon}
          title="No invitations...yet!"
          subtitle={(
            <>
              You don't have any invitations right now, but new possibilities are just
              around the corner. Ready to discover what's out there?
            </>
          )}
        >
          <EmptyStateActions>
            <LinkButton
              button={SolidButton}
              to={`/opportunities`}
              label="Explore opportunities!"
            />
          </EmptyStateActions>
        </EmptyState>
      </Header>
    );
  }

  return (
    <Header>
      <div className="space-y-4">
        <Questions questions={questions} />
        <Invitations invitations={invitations} />
        <Claims claims={claims} />
      </div>
    </Header>
  );
}
