import React from 'react';
import { useUser } from '@lore/auth';
import ParentLayout from '../Layout';
import Urls from '../Urls';
import Form from './Form';
import { Helmet } from 'react-helmet-async';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { history } = useRouter();
  const user = useUser();

  return (
    <ParentLayout>
      <Helmet>
        <title>Setup - Unavailable</title>
      </Helmet>
      <Form
        user={user}
        // navigation={user.data.isEducator ?
        //   <Navigation activeStep={0} steps={10}/> :
        //   <Navigation activeStep={0} steps={12}/>
        // }
        onPrevious={(newData) => history.push(Urls.NETWORK)}
        onNext={(newData) => history.push(Urls.THANKS)}
        onSkip={() => history.push(Urls.THANKS)}
      />
    </ParentLayout>
  );
};
