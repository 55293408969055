import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useConnect } from '@lore/query-connect';
import validators from '../../utils/validators';
import Overlay from '../../forms/_common/Overlay';
import FormErrors from '../../forms/_common/FormErrors';
import RequestError from '../../forms/_common/RequestError';
import ErrorMessage from '../../forms/_common/ErrorMessage';
import SuccessMessage from '../../forms/_common/SuccessMessage';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import useEventYesEmailPreview from '../../utils/event/useEventYesEmailPreview';
import Form from './Form';
import { useUser } from '@lore/auth';
import { useQueryMutation } from '../../../hooks/@lore/query-connect';

EventDiscoverYesDialog.propTypes = {
  event: PropTypes.object.isRequired
};

export default function EventDiscoverYesDialog(props) {
  const { event } = props;

  const [data, setData] = useState({
    customMessage: 'Yes, I will attend.'
  });
  const [showErrors, setShowErrors] = useState(false);

  const currentUser = useUser();

  const creator = useConnect('user.byId', {
    id: event.data.creatorId
  });

  const emailPreview = useEventYesEmailPreview(event, creator, data.customMessage);

  const mutation = useQueryMutation('invitation.create');
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      props.onCancel();
      props.onSubmit && props.onSubmit(request);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate({
      eventId: event.id,
      userId: currentUser.id,
      request: 'Participation',
      requestMessage: 'My students and I have planned an event and we invite you to join us!',
      response: 'Yes',
      responseMessage: data.customMessage
    });
  }

  function onChange(name, value) {
    const nextData = _.merge({}, data);
    nextData[name] = value;
    setData(nextData);
  }

  onChange = onChange.bind(data);

  return (
    <DialogTemplate className="w-screen max-w-screen-sm">
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          customMessage: [validators.isRequired]
        }}>
          {({errors, hasError}) => (
            <Form
              data={data}
              errors={errors}
              showErrors={showErrors}
              onChange={onChange}
              onSubmit={() => hasError ? setShowErrors(true) : onSubmit()}
              onCancel={props.onCancel}
              alert={error ? (
                <RequestError request={request}/>
              ) : showErrors ? (
                <ErrorMessage>
                  Please fill in all required fields.
                </ErrorMessage>
              ) : (false && success) ? (
                <SuccessMessage>
                  Idea created.
                </SuccessMessage>
              ) : null}
              event={event}
              emailPreview={emailPreview}
              creator={creator}
            />
          )}
        </FormErrors>
      </Overlay>
    </DialogTemplate>
  );
};
