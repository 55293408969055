import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useDialogConfig from '../_hooks/useDialogConfig';
import TagNodeCreateDialog from '../../dialogs/tagNode.create';
import { useQueryClient } from '@tanstack/react-query';

export default function Launcher(props) {
  const config = useDialogConfig();
  const queryClient = useQueryClient();

  const tag = useConnect('tag.byId', {
    id: config.params.tagId
  });

  const model = useConnect(`${config.params.modelName}.byId`, {
    id: config.params.modelId
  });

  if (
    tag.state === PayloadStates.FETCHING ||
    model.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  const extra = {
    [config.params.modelName]: model
  };

  return (
    <TagNodeCreateDialog
      tag={tag}
      {...extra}
      onCancel={props.onClose}
      onSubmit={function(request) {
        queryClient.invalidateQueries({ queryKey: ['tagNode'] });
      }}
    />
  );
};
