import React from 'react';
import PropTypes from 'prop-types';
import ConnectButton from '../../../pages/_banner/ConnectButton';
import Wrapper from '../../../pages/_banner/Wrapper';
import usePageRouter from '../../_hooks/usePageRouter';

CancelledBanner.propTypes = {
  offer: PropTypes.object.isRequired
};

export default function CancelledBanner(props) {
  const { offer, creator } = props;

  const { history } = usePageRouter();
  const color = 'gray';

  return (
    <Wrapper
      color={color}
      creator={creator}
      title={(
        <>
          This offer was cancelled.
        </>
      )}
      subtitle={(
        <>
          Offer cancelled? It happens sometimes, but you can explore other
          opportunities to collaborate and make an impact.
        </>
      )}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        <ConnectButton color={color} onClick={() => history.push(`/opportunities`)}>
          Explore opportunities
        </ConnectButton>
      </div>
    </Wrapper>
  );
};
