import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Message from '../project-messages-user/Message';
import PlaceholderMessage from '../project-messages-user/PlaceholderMessage';
import TextDivider from './TextDivider';
import { DateTime } from 'luxon';

Messages.propTypes = {
  messages: PropTypes.object.isRequired
};

export default function Messages(props) {
  const { user, messages } = props;

  const node = useRef();

  useEffect(() => {
    node.current.scrollTo({
      top: node.current.scrollHeight,
      // behavior: 'smooth'
    });
    // node.current.scrollIntoView();
  }, []);

  return (
    // <div className="flex-1 overflow-scroll border-l border-r border-cs-gray-200 px-8 py-8">
    <div ref={node} className="flex-1 overflow-y-scroll bg-white px-6 py-4 divide-yx divide-gray-100x">
      <TextDivider title="Beginning of messages" />
      {messages.data.map(function(message, index) {
        // return (
        //   <Message
        //     key={message.id || message.cid}
        //     message={message}
        //     className="py-6"
        //   />
        // );
        const nextMessage = messages.data[index + 1];

        const date = DateTime.fromISO(message.data.createdAt).setZone(user.data.timezone).toLocaleString(DateTime.DATE_MED);
        let nextDate = date;

        if (nextMessage) {
          nextDate = DateTime.fromISO(nextMessage.data.createdAt).setZone(user.data.timezone).toLocaleString(DateTime.DATE_MED);
        }

        return [
          index === 0 ? (
            <TextDivider
              key={`d-before-${message.id || message.cid}`}
              title={date}
            />
          ) : null,
          <Message
            key={message.id || message.cid}
            message={message}
            className="py-6"
          />,
          date !== nextDate ? (
            <TextDivider
              key={`d-after-${message.id || message.cid}`}
              title={nextDate}
            />
          ) : null
        ];
      })}
      {messages.data.length === 0 && (
        <PlaceholderMessage
          user={user}
          className="py-6"
        />
      )}
      <TextDivider title="End of messages" />
    </div>
  );
};
