import { extractTextFromQuery } from '../../../utils/query-utils';

export default {
  param: extractTextFromQuery,
  query: function(text) {
    const words = text.trim().split(' ');

    if (text) {
      return {
        $or: [
          {
            $or: words.map(function(word) {
              return {
                'project.creator.firstName': {
                  $ilike: `%${word}%`
                }
              };
            }).concat(words.map(function(word) {
              return {
                'project.creator.lastName': {
                  $ilike: `%${word}%`
                }
              };
            }))
          },
          {
            $or: words.map(function(word) {
              return {
                'event.creator.firstName': {
                  $ilike: `%${word}%`
                }
              };
            }).concat(words.map(function(word) {
              return {
                'event.creator.lastName': {
                  $ilike: `%${word}%`
                }
              };
            }))
          }
        ]
      }
    }
  }
}
