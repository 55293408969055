import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import useStoryTags from '../../../hooks/useStoryTags';
import TagTypes from '../../../constants/TagTypes';
import useTags from '../../../hooks/useTags';
import TextContentSection from '../../../components_profiles/TextContentSection';

Subjects.propTypes = {
  story: PropTypes.object.isRequired
};

export default function Subjects(props) {
  const { story } = props;

  const subjects = useTags(TagTypes.Subject);
  const storySubjects = useStoryTags(story, TagTypes.Subject);

  if (storySubjects.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <TextContentSection title="Related subject areas">
      <ul className="list-disc ml-6">
        {subjects.data.map(function(subject) {
          const storySubject = _.find(storySubjects.data, function(storySubject) {
            return storySubject._tag.id === subject.id;
          });

          if (!storySubject) {
            return null;
          }

          return (
            <li key={subject.id || subject.cid}>
              {subject.data.name}
            </li>
          );
        })}
      </ul>
    </TextContentSection>
  );
};
