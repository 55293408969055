import React from 'react';
import { EyeSlashIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

export default function HiddenField(props) {
  const { name, description, to, icon } = props;

  const data = {
    icon: props.icon || EyeSlashIcon
  };

  return (
    <Link
      to={to}
      className={classNames(
        'flex bg-cs-blue-50 rounded-lg py-3 px-4 space-x-4',
        '-m-px border border-cs-gray-200',
        'shadow-cs-flat',
        'hover:bg-cs-blue-100'
      )}
    >
      <div>
        <data.icon className="h-8 w-8 text-cs-blue-500" />
      </div>
      <div className="flex-1 flex flex-col">
        <div className="text-base font-semibold text-cs-gray-900">
          {name}
        </div>
        <div className="text-sm text-cs-gray-500">
          {description}
        </div>
      </div>
    </Link>
  );
}
