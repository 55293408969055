import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Overlay from '../../forms/_common/Overlay';
import FormErrors from '../../forms/_common/FormErrors';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import { OutlineButton, SolidButton } from '../../components/Button';
import FormTemplate from '../../dialogs_common/FormTemplate';
import { useQueryMutation } from '../../../hooks/@lore/query-connect';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';

TagNodeDestroyDialog.propTypes = {
  tag: PropTypes.object.isRequired,
  tagNode: PropTypes.object.isRequired,
  onSubmit: PropTypes.func
};

export default function TagNodeDestroyDialog(props) {
  const {
    title,
    subtitle,
    tag,
    tagNode,
    onCancel
  } = props;

  const [data, setData] = useState({
    // name: ''
  });
  const [showErrors, setShowErrors] = useState(false);
  const [_request, setRequest] = useState(null);

  const mutation = useQueryMutation('tagNode.destroy2');
  const { request, saving, success, error } = mutation;

  useEffect(() => {
    if (mutation.isSuccess) {
      props.onCancel();
      props.onSubmit && props.onSubmit(request);
    }
  }, [mutation.isSuccess]);

  function onSubmit() {
    mutation.mutate(tagNode);
  }

  return (
    <DialogTemplate className="w-screen max-w-md">
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          // description: [validators.isRequired]
        }}>
          {({ errors, hasError }) => (
            <FormTemplate
              title={title || tag.data.name}
              subtitle={subtitle || 'Would you like to delete this association?'}
              onClose={onCancel}
              footer={(
                <FormTemplateButtonFooter>
                  <OutlineButton onClick={onCancel}>
                    Cancel
                  </OutlineButton>
                  <SolidButton onClick={onSubmit}>
                    Delete
                  </SolidButton>
                </FormTemplateButtonFooter>
              )}
            />
          )}
        </FormErrors>
      </Overlay>
    </DialogTemplate>
  );
};
