import React from 'react';
import PropTypes from 'prop-types';
import { useConnect, UseQueryConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import Avatar from '../../../components/Avatar';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';

Claims.propTypes = {
  offer: PropTypes.object.isRequired
};

export default function Claims(props) {
  const { offer } = props;

  const MAX_CONVERSATIONS = 7;

  const launch = useDialogLauncher();

  function onClick(user) {
    launch(DialogManager.User.Config(user.id));
  }

  const responses = [
    'Connect',
    // 'Pending'
  ];

  const claims = useConnect('claim.find', {
    where: {
      eager: {
        $where: {
          offerId: offer.id,
          response: {
            $in: responses
          }
        }
      }
    }
  });

  if (claims.state === PayloadStates.FETCHING) {
    return (
      <Loader/>
    );
  }

  function Circle(props) {
    return (
      <div className="h-8 w-8 bg-cs-orange-200 rounded-full text-cs-gray-900 flex items-center text-sm justify-center">
        {props.children}
      </div>
    );
  }

  if (claims.data.length > 0) {
    return (
      <div className="flex space-x-2">
        {claims.data.slice(0,MAX_CONVERSATIONS).map(function(claim, index) {
          return (
            <UseQueryConnect key={index} _key="user.byId" params={{ id: claim.data.userId }}>
              {function(user) {
                return (
                  <Avatar
                    key={index}
                    size={8}
                    src={user.data.avatarUrl}
                    tooltip={`${user.data.firstName} ${user.data.lastName}`}
                    onClick={() => onClick(user)}
                  />
                );
              }}
            </UseQueryConnect>
          );
        })}
        {claims.data.slice(MAX_CONVERSATIONS).length > 0 && (
          <Circle>
            +{claims.data.slice(MAX_CONVERSATIONS).length}
          </Circle>
        )}
      </div>
    );
  }

  return null;
}
