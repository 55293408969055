import React from 'react';
import { GlobeAltIcon } from '@heroicons/react/24/outline';
import useRouter from '../../hooks/useRouter';
import Urls from '../profile/Urls';
import DataCard from './DataCard';

export default function LinkedIn(props) {
  const { user } = props;
  const { history } = useRouter();

  return (
    <DataCard
      title="LinkedIn URL"
      icon={GlobeAltIcon}
      subtitle={user.data.linkedinUrl || '(not provided)'}
      onClick={() => history.push(Urls(user).LINKEDIN)}
    />
  );
};
