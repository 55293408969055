import React from 'react';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import Header from './Header';
import Messages from './Messages';
import Footer from './Footer';
import useMessages from './useMessages';

export default function MessageList(props) {
  const { user, project, event, offer, timestamp } = props;

  const messages = useMessages(user, timestamp);

  if (messages.state === PayloadStates.FETCHING) {
    return (
      <div className="bg-white h-full flex flex-col">
        <Loader />
      </div>
    );
  }

  return (
    <div className="h-full flex flex-col">
      <Header
        user={user}
        messages={messages}
      />
      <Messages
        key={messages.data.length}
        user={user}
        messages={messages}
      />
      <Footer
        user={user}
        project={project}
        event={event}
        offer={offer}
      />
    </div>
  );
};
