import React from 'react';
import { SparklesIcon } from '@heroicons/react/24/outline';
import useRouter from '../../hooks/useRouter';
import Urls from '../profile/Urls';
import DataCard from './DataCard';

export default function Opportunities(props) {
  const { user } = props;

  const { history } = useRouter();

  const subtitle = _.remove([
    user.data.virtual ? 'Virtual' : undefined,
    user.data.inPerson ? 'In-person' : undefined
  ]).join(', ');

  return (
    <DataCard
      title="Opportunities"
      icon={SparklesIcon}
      // subtitle={subtitle || '(not provided)'}
      subtitle={(
        <>
          {user.data.inPerson && (
            <div>In-person</div>
          )}
          {user.data.virtual && (
            <div>Virtual</div>
          )}
        </>
      )}
      onClick={() => history.push(Urls(user).OPPORTUNITIES)}
    />
  );
};
