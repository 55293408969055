import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import PendingBanner from './PendingBanner';
import ConnectBanner from './ConnectBanner';
import DeclineBanner from './DeclineBanner';
import LoadingBanner from '../../../pages/_banner/LoadingBanner';
import CompletedBanner from './CompletedBanner';
import CancelledBanner from './CancelledBanner';

Banner.propTypes = {
  question: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired
};

export default function Banner(props) {
  const { question, project } = props;

  const creator = useConnect('user.byId', {
    id: project.data.creatorId
  });

  if (creator.state === PayloadStates.FETCHING) {
    return (
      <LoadingBanner creator={creator} />
    );
  }

  // return (
  //   <>
  //     <PendingBanner
  //       project={project}
  //       question={question}
  //       creator={creator}
  //     />
  //     <ConnectBanner
  //       project={project}
  //       creator={creator}
  //       question={question}
  //     />
  //     <CompletedBanner
  //       project={project}
  //       creator={creator}
  //       question={question}
  //     />
  //     <DeclineBanner
  //       project={project}
  //       creator={creator}
  //       question={question}
  //     />
  //     <CancelledBanner
  //       project={project}
  //       creator={creator}
  //       question={question}
  //     />
  //   </>
  // );

  if (project.data.cancelled) {
    return (
      <CancelledBanner
        project={project}
        creator={creator}
        question={question}
      />
    );
  }

  if (project.data.completed) {
    return (
      <CompletedBanner
        project={project}
        creator={creator}
        question={question}
      />
    );
  }

  if (question.data.response === 'Connect') {
    return (
      <ConnectBanner
        project={project}
        creator={creator}
        question={question}
      />
    );
  }

  if (question.data.response === 'Decline') {
    return (
      <DeclineBanner
        project={project}
        creator={creator}
        question={question}
      />
    );
  }

  return (
    <PendingBanner
      project={project}
      question={question}
      creator={creator}
    />
  );
}
