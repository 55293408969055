import { useConnect } from '@lore/query-connect';
import useTags from './useTags';

export default function useOfferTags(offer, type, timestamp) {
  const tags = useTags();

  const extraArgs = timestamp ? {
    createdAt: {
      $lte: timestamp
    }
  } : {};

  const tagNodes = useConnect('tagNode.find', {
    where: {
      eager: {
        $where: {
          'tag.type': type,
          offerId: offer.id,
          ...extraArgs,
        }
      }
    },
    pagination: {
      pageSize: 1000
    }
  });

  return {
    state: tagNodes.state,
    data: tagNodes.data.map(function(tagNode) {
      const tag = _.find(tags.data, tag => tag.id === tagNode.data.tagId);

      return {
        id: tagNode.id,
        cid: tagNode.cid,
        data: tag.data,
        _tag: tag,
        _tagNode: tagNode,
        _meta: 'generated in useOfferTags',
      }
    }),
    _tagNodes: tagNodes
  }
}
