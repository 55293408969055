import React from 'react';
import { Helmet } from 'react-helmet-async';
import ScrollToTop from '../../../components/ScrollToTop';
import RemoveLoadingScreen from '../../RemoveLoadingScreen';
import logo from '../../../../assets/images/logo.png';
import PrimaryButton from '../../../components_setup/PrimarySetupButton';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { history } = useRouter();

  return (
    <div className="flex justify-center h-screen">
      <Helmet>
        <title>Setup - Finish</title>
      </Helmet>
      <ScrollToTop />
      <RemoveLoadingScreen/>
      <div className="my-auto flex flex-col items-center">
        <div className="text-center mx-auto">
          <img src={logo} className="w-64 m-8x filter drop-shadow-md m-8"  alt="Logo" />
        </div>
        <div className="pb-12"/>
        <h5 className="text-3xl font-semibold leading-snug text-cs-gray-800 text-center mb-4x mb-12">
          Get started by building your profile.
        </h5>
        {/*<h6 className="font-normal text-cs-gray-800 text-center text-lg leading-snug mb-12">*/}
        {/*  You'll be able to connect with others when your profile is 100% complete.*/}
        {/*</h6>*/}
        <div className="text-center">
          <PrimaryButton className="text-centerx mb-6" onClick={() => history.push('/profile')}>
            Start profile now
          </PrimaryButton>
          <div className="text-cs-orange-900 hover:text-cs-orange-500 font-semibold uppercase cursor-pointer" onClick={() => history.push('/')}>
            Skip
          </div>
        </div>
      </div>
      <div className="bg-gradient-to-b from-cs-yellow-100 to-cs-yellow-300 fixed top-0 bottom-0 left-0 right-0 -z-1" />
    </div>
  );
};
