import React from 'react';
import { useUser } from '@lore/auth';
import { useConnect } from '../../../../hooks/@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Invitations from './Invitations';
import ComposableEventHeader from '../../../components_cards/ComposableEventHeader';
import Card from '../../../components/Card';
import EventTag from '../../../components_cards/tags/EventTag';
import TagRow from '../../../components_cards/tags/TagRow';
import CreatorTag from '../../../components_cards/tags/CreatorTag';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import OutlineButtonGray from '../../../components/Button/OutlineButton.gray';
import CompletedTag from '../../../components_cards/tags/_resource/CompletedTag';
import CancelledTag from '../../../components_cards/tags/_resource/CancelledTag';
import ExpiredTag from '../../../components_cards/tags/_resource/ExpiredTag';
import usePageRouter from '../../../dialogs-routable/_hooks/usePageRouter';
import EvaluationText from '../EvaluationText';
import EvaluationButton from '../EvaluationButton';
import LazyCard from '../../../components/LazyCard';

export default function EventCreated(props) {
  const { event } = props;

  const currentUser = useUser();
  const launch = useDialogLauncher();
  const { history } = usePageRouter();

  const evaluation = useConnect('evaluationEventEducator.first', {
    where: {
      eager: {
        $where: {
          eventId: event.id,
          userId: currentUser.id
        }
      }
    }
  });

  function onViewEvent() {
    launch(DialogManager.EventManage.Config(event.id, '/view'));
  }

  function onDuplicateEvent() {
    launch(DialogManager.EventDuplicate.Config(event.id));
  }

  function onViewEvaluation() {
    launch(DialogManager.EvaluationEventEducator.Config(evaluation.id));
  }

  function redirectToEvaluation() {
    history.push(`/evaluation-event-educator/${evaluation.id}`);
  }

  return (
    <LazyCard divider={true}>
      <ComposableEventHeader
        event={event}
        showTags={false}
        tags={(
          <>
            <TagRow>
              <EventTag />
              <CreatorTag />
              <CompletedTag resource={event} />
              <CancelledTag resource={event} />
              <ExpiredTag resource={event} />
            </TagRow>
            <Invitations event={event} />
          </>
        )}
      />
      <div className="flex justify-between p-4 bg-gray-50 flex-wrap gap-2">
        <div>
          {evaluation.state === PayloadStates.RESOLVED && (
            <EvaluationText
              pendingTitle="Share the impact of your event"
              evaluation={evaluation}
              onView={onViewEvaluation}
            />
          )}
        </div>
        <div className="flex flex-wrap gap-2">
          <OutlineButtonGray
            label="View event"
            onClick={onViewEvent}
          />
          <OutlineButtonGray
            label="Duplicate event"
            onClick={onDuplicateEvent}
          />
          {evaluation.state === PayloadStates.RESOLVED && (
            <EvaluationButton
              evaluation={evaluation}
              onEdit={redirectToEvaluation}
            />
          )}
        </div>
      </div>
    </LazyCard>
  );
}
