import React from 'react';
import PropTypes from 'prop-types';
import ConnectButton from '../../../pages/_banner/ConnectButton';
import Wrapper from '../../../pages/_banner/Wrapper';
import useDialogLauncher from '../../_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useRouter from '../../../hooks/useRouter';

DraftBanner.propTypes = {
  offer: PropTypes.object.isRequired
};

export default function DraftBanner(props) {
  const { offer, creator } = props;

  const launch = useDialogLauncher();
  const { history } = useRouter();

  function onCancel() {
    launch(DialogManager.OfferCancel.Config(offer.id));
  }

  function onFindEducators() {
    launch(DialogManager.OfferManage.Config(offer.id, '/invite-populate'));
  }

  return (
    <Wrapper
      color="gray"
      creator={creator}
      title={(
        <>
          Your offer is missing required fields!
        </>
      )}
      subtitle={(
        <>
          To make your offer visible on the platform, please provide the missing information
          below. After providing these details, your offer will be active and you'll be able
          to collaborate with others.
        </>
      )}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        <ConnectButton outline={true} onClick={onCancel}>
          Cancel offer
        </ConnectButton>
        <ConnectButton onClick={() => history.push('/edit')} gray={true}>
          View missing fields
        </ConnectButton>
      </div>
    </Wrapper>
  );
};
