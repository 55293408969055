import React from 'react';
import ProfileReactivateDialog from '../../dialogs/profile.reactivate';

export default function Launcher(props) {
  return (
    <ProfileReactivateDialog
      onCancel={props.onClose}
    />
  );
};
