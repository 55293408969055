import React from 'react';
import Text from '../../../typography';

export default function Category(props) {
  const { children } = props;

  return (
    <Text.LgSemibold>
      {children}
    </Text.LgSemibold>
  );
}
