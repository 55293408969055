import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import useStoryTags from '../../../hooks/useStoryTags';
import TagTypes from '../../../constants/TagTypes';
import useTags from '../../../hooks/useTags';
import SubSectionHeader from '../../../components_profiles/SubSectionHeader';
import Tag from '../../../components/Tag';
import TextContentSection from '../../../components_profiles/TextContentSection';

Activities.propTypes = {
  story: PropTypes.object.isRequired
};

export default function Activities(props) {
  const { story } = props;

  const activities = useTags(TagTypes.Activity);
  const storyActivities = useStoryTags(story, TagTypes.Activity);

  if (storyActivities.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <TextContentSection title="Activities">
      <ul className="list-disc ml-6">
        {activities.data.map(function(activity) {
          const storyActivity = _.find(storyActivities.data, function(storyActivity) {
            return storyActivity._tag.id === activity.id;
          });

          if (!storyActivity) {
            return null;
          }

          return (
            <li key={activity.id || activity.cid}>
              {activity.data.name}
            </li>
          );
        })}
      </ul>
    </TextContentSection>
  );
};
