import React from 'react';
import Activity from './Activity';
import School from './School';
import EventSummary from './EventSummary';
import EventTitle from './EventTitle';
import StudentAges from './StudentAges';
import StudentSize from './StudentSize';
import StudentCount from './StudentCount';
import Participants from './Participants';
import Subjects from './Subjects';
import Skills from './Skills';
import Location from './Location';
import Documents from './Documents';
import Date from './Date';
import Time from './Time';
import Slots from './Slots';
import Divider from '../../forms/_fields_v2/Divider';
import Section from '../profile-edit/Section';

export default function Layout(props) {
  const { event } = props;

  return (
    <div className="space-y-8">
      {/*<div className="text-2xl font-semibold text-cs-gray-900">*/}
      {/*  Edit Event*/}
      {/*</div>*/}
      {/*<Divider />*/}
      <Section
        title="School"
        subtitle={(
          <>
            Where are your learners located?
          </>
        )}
      >
        <div className="grid grid-cols-1 gap-6">
          <School event={event} />
        </div>
      </Section>
      <Divider />
      <Section
        title="Vision"
        subtitle={(
          <>
            What do you want to happen and what needs do you have?
          </>
        )}
      >
        <div className="grid grid-cols-1 gap-6">
          <Activity event={event} />
          <EventTitle event={event} />
          <EventSummary event={event} />
        </div>
      </Section>
      <Divider />
      <Section
        title="Audience"
        subtitle={(
          <>
            Who will partners work with?
          </>
        )}
      >
        <div className="grid grid-cols-1 gap-6">
          <Participants event={event} />
          <StudentCount event={event} />
          <StudentAges event={event} />
          <StudentSize event={event} />
        </div>
      </Section>
      <Divider />
      <Section
        title="Partners"
        subtitle={(
          <>
            How many partners do you need and what do they need to know?
          </>
        )}
      >
        <div className="grid grid-cols-1 gap-6">
          <Subjects event={event} />
          <Slots event={event} />
          <Skills event={event} />
        </div>
      </Section>
      <Divider />
      <Section
        title="Timing & Logistics"
        subtitle={(
          <>
            When do you need a partner? How will you meet?
          </>
        )}
      >
        <div className="grid grid-cols-1 gap-6">
          <Date event={event} />
          <Time event={event} />
          <Location event={event} />
        </div>
      </Section>
      <Divider />
      <Section
        title="Documents"
        subtitle={(
          <>
            Do you have any supporting documents to help partners understand your idea?
          </>
        )}
      >
        <div className="grid grid-cols-1 gap-6">
          <Documents event={event} />
        </div>
      </Section>
    </div>
  );
};
