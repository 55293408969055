export default function(evaluation) {
  return {
    START: `/evaluation-project-educator/${evaluation.id}/start`,
    STUDENTS: `/evaluation-project-educator/${evaluation.id}/students`,

    SUPPORTED: `/evaluation-project-educator/${evaluation.id}/supported`,
    ENGAGED: `/evaluation-project-educator/${evaluation.id}/engaged`,
    SKILLS: `/evaluation-project-educator/${evaluation.id}/skills`,
    AWARENESS: `/evaluation-project-educator/${evaluation.id}/awareness`,
    UNDERSTANDING: `/evaluation-project-educator/${evaluation.id}/understanding`,
    PRACTICED: `/evaluation-project-educator/${evaluation.id}/practiced`,

    JOURNEY: `/evaluation-project-educator/${evaluation.id}/journey`,
    ADVENTURES: `/evaluation-project-educator/${evaluation.id}/adventures`,
    CHALLENGES: `/evaluation-project-educator/${evaluation.id}/challenges`,
    CHANGES: `/evaluation-project-educator/${evaluation.id}/changes`,
    LESSONS: `/evaluation-project-educator/${evaluation.id}/lessons`,

    UPLOADS: `/evaluation-project-educator/${evaluation.id}/uploads`,

    RECOMMEND: `/evaluation-project-educator/${evaluation.id}/recommend`,
    END: `/evaluation-project-educator/${evaluation.id}/end`,
    PROJECT_MANAGE: `/project/${evaluation.data.projectId}/manage`
  }
};
