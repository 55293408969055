import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import { useUser } from '@lore/auth';
import ClampableText from '../../components/ClampableText';
import Avatar from '../../components/Avatar';
import logo from '../../../assets/images/logo.png';
import UserOrganization from '../../pages/people/__common/UserOrganization.v2';

useProjectInterestEmailPreview.propTypes = {
  project: PropTypes.object.isRequired
};

export default function useProjectInterestEmailPreview(project, partner, customMessage) {
  // const { project } = props;

  const currentUser = useUser();

  const activity = useConnect('tag.byId', {
    id: project.data.activityId
  });

  if (activity.state === PayloadStates.FETCHING) {
    return (
      <div className="bg-cs-gray-50 p-6">
        ...
      </div>
    );
  }

  return (
    <div className="bg-cs-gray-50 p-6">
      <div className="h-1 bg-cs-orange-500 w-full mb-8"/>
      <div className="p-6 mb-8">
        <img
          className="w-24 mx-auto"
          src={logo}
          alt="logo"
        />
      </div>
      <div className="mb-8">
        <div className="text-base text-cs-gray-900">
          Hi {partner.data.firstName},
        </div>
        <div className="mt-3 text-base text-cs-gray-900">
          <ClampableText markup>
            {customMessage}
          </ClampableText>
        </div>
        <div className="mt-3 mb-3">
          <div className="text-base text-cs-gray-900">
            Cheers,
          </div>
        </div>
        <div className="flex items-center space-x-3">
          <div>
            <Avatar size={12} src={currentUser.data.avatarUrl} />
          </div>
          <div>
            <div className="text-lg text-cs-gray-900 font-semibold">
              {currentUser.data.firstName} {currentUser.data.lastName}
            </div>
            <div className="text-sm text-cs-gray-500">
              <UserOrganization user={currentUser} />
            </div>
          </div>
        </div>
      </div>
      <div className="mb-8">
        <div className="flex flex-col text-center justify-center bg-cs-orange-500 rounded-xl p-6">
          <div className="text-2xl text-white font-semibold mb-2">
            Let's keep the momentum going!
          </div>
          <div className="text-base text-white mb-4">
            Now that <span className="font-semibold">{currentUser.data.firstName}</span> has connected with
            you about <span className="font-semibold">{project.data.title || activity.data.name}</span>, the
            next step is to respond and keep coordinating together. We have tips below if you need help!
          </div>
          <div className="bg-white rounded-lg w-56 text-center py-3 m-auto">
            <div className="text-base text-cs-orange-500 uppercase font-semibold">
              Respond
            </div>
          </div>
        </div>
      </div>
      <div className="mb-8 space-y-4">
        <div className="bg-white rounded-lg p-6 space-y-8">
          <div>
            <div className="text-2xl font-semibold text-cs-gray-900 mb-2">
              Need a little extra guidance on how to communicate with your partner?
            </div>
            <div className="text-base text-cs-gray-500">
              Most importantly, we recommend responding promptly to show appreciation
              for the partner. Here are some topics you might discuss with your partner
              using CommunityShare's messaging feature or by setting up a phone call.
            </div>
          </div>
          <div>
            <div className="text-xl font-semibold text-cs-gray-900">
              Scheduling
            </div>
            <div className="text-base text-cs-gray-500 mb-6">
              How might you structure your time together to fit both class and partner needs?
            </div>
            <ul className="ml-6 list-disc text-base text-cs-gray-500">
              <li>
                Be flexible. You might have to start with one visit before they are willing
                to commit to regular visits. Your partner might need to break a project into
                smaller sessions to meet with multiple classes.
              </li>
            </ul>
          </div>
          <div>
            <div className="text-xl font-semibold text-cs-gray-900">
              Collaboration
            </div>
            <div className="text-base text-cs-gray-500 mb-6">
              What support will make the partner feel welcome and give them the tools they need?
            </div>
            <ul className="ml-6 list-disc text-base text-cs-gray-500">
              <li>
                How best can I support your experience in my classroom?
              </li>
              <li>
                I can prepare my students for your visit by... (e.g. preparing questions, researching partner, etc.)
              </li>
              <li>
                What do you need in terms of classroom set-up and support?
              </li>
              <li>
                Do you need internet access?
              </li>
              <li>
                Do you need any specific equipment (e.g. projector, whiteboard, speakers etc...)?
              </li>
              <li>
                Will you have handouts that need to be printed?
              </li>
            </ul>
          </div>
          <div>
            <div className="text-xl font-semibold text-cs-gray-900">
              Visit Logistics
            </div>
            <div className="text-base text-cs-gray-500 mb-6">
              What does the partner need to know about visiting your class?
            </div>
            <ul className="ml-6 list-disc text-base text-cs-gray-500">
              <li>
                My school's policies regarding visitors are...
              </li>
              <li>
                You can park at...and check-in and get a badge at...
              </li>
              <li>
                You will be able to get to my class by...
              </li>
            </ul>
          </div>
          <div>
            <div className="text-xl font-semibold text-cs-gray-900">
              Teacher Role During the Project
            </div>
            <div className="text-base text-cs-gray-500 mb-6">
              What role will you play while the partner is with your students?
            </div>
            <ul className="ml-6 list-disc text-base text-cs-gray-500">
              <li>
                I will introduce you.
              </li>
              <li>
                I will be engaged throughout, will actively support you and guide the conversation as needed.
              </li>
              <li>
                I will keep my students on task as best as I can and address any disciplinary issues.
              </li>
              <li>
                I will reschedule our visit if I need to call a substitute teacher that day.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="h-1 bg-cs-orange-500 w-full"/>
    </div>
  );
};
