import React from 'react';
import _ from 'lodash';
import { CogIcon, LinkIcon, MagnifyingGlassIcon, PencilIcon, UsersIcon } from '@heroicons/react/24/outline';
import useDialogConfig from '../../_hooks/useDialogConfig';
import useDialogRouter from '../../_hooks/useDialogRouter';
import ActionBarButton from '../../_components_action_bar/ActionBarButton';
import ActionBarContainer from '../../_components_action_bar/ActionBarContainer';
import ActionBarMenu from './ActionBarMenu';

export default function ActionBar(props) {
  const { project } = props;

  const { id } = useDialogConfig();
  const router = useDialogRouter(id);

  const links = _.remove([
    {
      icon: LinkIcon,
      label: 'View profile',
      to: `/view`
    },
    {
      icon: PencilIcon,
      label: 'Edit profile',
      to: `/edit`
    },
    {
      icon: CogIcon,
      label: 'Settings',
      to: `/settings`
    }
  ], entry => entry);

  return (
    <ActionBarContainer>
      {links.map(function(link, index) {
        const active = (
          router.location.pathname.indexOf(link.to) === 0 ||
          link.to.indexOf(router.location.pathname) === 0
        );

        return (
          <ActionBarButton
            key={index}
            label={link.label}
            icon={link.icon}
            onClick={() => router.navigate(link.to)}
            isActive={active}
          />
        );
      })}
      {/*<ActionBarMenu project={project} />*/}
    </ActionBarContainer>
  );
};
