import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useDialogConfig from '../_hooks/useDialogConfig';
import Dialog from '../../dialogs/evaluation_event_partner';

export default function Launcher(props) {
  const config = useDialogConfig();

  const evaluationEventPartner = useConnect('evaluationEventPartner.byId', {
    id: config.params.evaluationEventPartnerId
  });

  if (evaluationEventPartner.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <Dialog
      evaluationEventPartner={evaluationEventPartner}
      onClose={props.onClose}
    />
  );
};
