import React from 'react';
import { PayloadStates } from '@lore/utils';
import useRouter from '../../hooks/useRouter';
import Loader from '../../components/Loader';
import EmptyState from '../../components/EmptyState';
import classNames from 'classnames';
import Project from '../projects/Project';
import Event from '../events/Event';
import { FolderIcon } from '@heroicons/react/24/outline';
import { UseQueryConnect } from '../../../hooks/@lore/query-connect';

export default function Results(props) {
  const { result } = props;

  const { location, history } = useRouter();

  if (result.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  if (result.data.length === 0) {
    return (
      <EmptyState
        icon={FolderIcon}
        title="Having trouble finding what you're looking for?"
        subtitle={(
          <div className="max-w-screen-sm m-auto space-y-6">
            <div className="text-cs-gray-500 space-x-1">
              <span>Try removing a filter or start fresh by</span>
              <span
                className="text-cs-orange-500 underline cursor-pointer"
                onClick={() => history.push(location.pathname)}
              >
                clearing all filters
              </span>.
            </div>
          </div>
        )}
      />
    );
  }

  return (
    <div className={classNames(
      result._isPreviousData && 'opacity-50',
      'w-full grid grid-cols-1 gap-4'
    )}>
      {result.data.map(function(opportunity, index) {
        if (opportunity.data.projectId) {
          return (
            <UseQueryConnect key={index} _key="project.byId" params={{ id: opportunity.data.projectId }}>
              {function(project) {
                if (project.state === PayloadStates.FETCHING) {
                  return (
                    <Loader />
                  );
                }

                return (
                  <Project
                    project={project}
                  />
                );
              }}
            </UseQueryConnect>
          );
        }

        if (opportunity.data.eventId) {
          return (
            <UseQueryConnect key={index} _key="event.byId" params={{ id: opportunity.data.eventId }}>
              {function(event) {
                if (event.state === PayloadStates.FETCHING) {
                  return (
                    <Loader />
                  );
                }

                return (
                  <Event
                    event={event}
                  />
                );
              }}
            </UseQueryConnect>
          );
        }

        return (
          <Loader key={index} />
        );
      })}
    </div>
  );
}
