import React, { useState } from 'react';
import _ from 'lodash';
import { useUser } from '@lore/auth';
import Steps from './_common/Steps';
import Start from './Start';
import Step1 from './Step1';
import Confirmation from './Confirmation';

export default function ChangeEmailDialog(props) {
  const { onCancel } = props;

  const user = useUser();

  const [data, setData] = useState({
    email: '',
    emailChangeRequest: null
  });
  const [key, setKey] = useState(0);
  const [stepIndex, setStepIndex] = useState(Steps.START);

  function onNext(newData, newStepIndex) {
    setStepIndex(newStepIndex || (stepIndex + 1));
    setData(_.extend({}, data, newData));
  }

  function onPrevious(newData, newStepIndex) {
    setStepIndex(newStepIndex || (stepIndex - 1));
    setData(_.extend({}, data, newData));
  }

  function onChangeStep(newData, newStepIndex) {
    setStepIndex(newStepIndex);
    setData(_.extend({}, data, newData));
  }

  const steps = [
    (props) => {
      return (
        <Start
          {...props}
          user={user}
          // onNext={(newData) => onNext(newData, Steps.STEP1)}
          onNext={(newData) => onNext({
            emailChangeRequest: newData
          }, Steps.STEP1)}
          onCancel={onCancel}
        />
      );
    },
    (props) => {
      return (
        <Step1
          {...props}
          user={user}
          emailChangeRequest={data.emailChangeRequest}
          onNext={(newData) => onNext({
            // offer: newData
          }, Steps.CONFIRMATION)}
          onCancel={onCancel}
        />
      );
    },
    (props) => {
      return (
        <Confirmation
          {...props}
          onCancel={onCancel}
        />
      );
    }
  ];

  const step = steps[stepIndex]({
    user: user,
    data: data
  });

  return (
    <div key={key}>
      {step}
    </div>
  );
}
