import { useNavigate, useParams } from 'react-router-dom';
import usePageRouter from '../dialogs-routable/_hooks/usePageRouter';
import useDialogRouter from '../dialogs-routable/_hooks/useDialogRouter';

export default function useRouter(props) {
  const pageRouter = usePageRouter();
  const dialogRouter = useDialogRouter();

  return dialogRouter || pageRouter;

  // const navigate = useNavigate();
  // const location = window.location;
  // const params = useParams();
  //
  // return {
  //   navigate: navigate,
  //   history: {
  //     push: navigate
  //   },
  //   location,
  //   match: {
  //     params
  //   }
  // };
}
