import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import SectionTitle from '../project-manage/SectionTitle';
import Claim from '../offer-manage/Claim';

export default function ClaimsInvited(props) {
  const { offer, onViewMessages } = props;

  const claims = useConnect('claim.find', {
    where: {
      eager: {
        $where: {
          offerId: offer.id,
          response: 'Pending'
        }
      }
    },
    pagination: {
      pageSize: 100
    }
  });

  if (claims.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  if (claims.data.length === 0) {
    return null;
  }

  return (
    <SectionTitle
      title="Invited Educators"
      subtitle="These educators were invited but did not respond to the invitation."
    >
      <div className="grid grid-cols-1 gap-4">
        {claims.data.map(function (claim, index) {
          return (
            <Claim
              key={claim.id || claim.cid}
              claim={claim}
              onViewMessages={onViewMessages}
            />
          );
        })}
      </div>
    </SectionTitle>
  );
};
