import React from 'react';
import PropTypes from 'prop-types';
import { useConnect, UseQueryConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import User from '../../people-partners/User';
import SectionHeader from '../../../components_profiles/SectionHeader';

Users.propTypes = {
  story: PropTypes.object.isRequired
};

export default function Users(props) {
  const { story } = props;

  const storyUsers = useConnect('userNode.find', {
    where: {
      eager: {
        $where: {
          storyId: story.id,
          'user.inactive': false
        }
      }
    },
    pagination: {
      page: 1,
      pageSize: 100,
      order: 'createdAt desc'
    }
  });

  if (storyUsers.data.length === 0) {
    return null;
  }

  return (
    <div className="space-y-4">
      <SectionHeader
        title="People involved"
        subtitle="Some of the educators and partners who participated."
      />
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-2 gap-6">
        {storyUsers.data.map(function(storyUser, index) {
          return (
            <UseQueryConnect key={index} _key="user.byId" params={{ id: storyUser.data.userId }}>
              {function(user) {
                if (user.state === PayloadStates.FETCHING) {
                  return (
                    <Loader key={storyUser.id || storyUser.cid} />
                  );
                }

                return (
                  <User
                    user={user}
                  />
                );
              }}
            </UseQueryConnect>
          );
        })}
      </div>
    </div>
  );
};
