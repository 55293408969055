import React from 'react';
import ExpertiseSuggestionsDialog from '../../dialogs/ExpertiseSuggestions';

export default function Launcher(props) {
  return (
    <ExpertiseSuggestionsDialog
      onCancel={props.onClose}
    />
  );
};
