import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { OutlineButton, SolidButton } from '../../components/Button';
import FormTemplate from '../../dialogs_common/FormTemplate';
import { CheckIcon } from '@heroicons/react/24/outline';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';

Finish.propTypes = {
  event: PropTypes.object
};

export default function Finish(props) {
  const {
    event,
    onCancel,
    onPrevious,
    onNext
  } = props;

  const [timestamp] = useState(new Date().toISOString());

  const evaluation = useConnect('evaluationEventEducator.first', {
    where: {
      eager: {
        $where: {
          eventId: event.id,
          userId: event.data.creatorId,
          createdAt: {
            $lte: timestamp
          }
        }
      }
    }
  });

  if (evaluation.state === PayloadStates.FETCHING) {
    return (
      <FormTemplate
        icon={(
          <div className="flex justify-center mb-4">
            <CheckIcon className="text-cs-orange-500 h-12 w-12" />
          </div>
        )}
        title="You're on a roll."
        subtitle={(
          <div className="space-y-2">
            <div>
              Next, fill out an evaluation to let your steward know how things went.
            </div>
            <div>
              You can also end your session here and come back later if you don't have time.
            </div>
          </div>
        )}
        body={(
          <div className="flex justify-center space-y-8">
            <Loader />
          </div>
        )}
        onClose={onCancel}
      />
    );
  }

  if (evaluation.state === PayloadStates.NOT_FOUND) {
    return (
      <FormTemplate
        icon={(
          <div className="flex justify-center mb-4">
            <CheckIcon className="text-cs-orange-500 h-12 w-12" />
          </div>
        )}
        title="Oh no! There's a problem."
        subtitle={(
          <>
            Typically we'd ask you to complete an evaluation at this point, but there's
            an issue and the evaluation isn't ready to be completed yet. We'll reach out
            to you when it's ready to complete.
          </>
        )}
        onClose={onCancel}
        footer={(
          <div className="space-x-4">
            <SolidButton onClick={onCancel}>
              Exit
            </SolidButton>
          </div>
        )}
      />
    );
  }

  return (
    <FormTemplate
      icon={(
        <div className="flex justify-center mb-4">
          <CheckIcon className="text-cs-orange-500 h-12 w-12" />
        </div>
      )}
      title="You're on a roll."
      subtitle={(
        <div className="space-y-2">
          <div>
            Next, fill out an evaluation to let your steward know how things went.
          </div>
          <div>
            You can also end your session here and come back later if you don't have time.
          </div>
        </div>
      )}
      onClose={onCancel}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onCancel}>
            Exit
          </OutlineButton>
          <SolidButton onClick={() => onNext(evaluation)}>
            Start Evaluation
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
