import React from 'react';
import PropTypes from 'prop-types';
import ConnectButton from '../../../pages/_banner/ConnectButton';
import Wrapper from '../../../pages/_banner/Wrapper';
import useDialogLauncher from '../../_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import usePageRouter from '../../_hooks/usePageRouter';

ExpiredBanner.propTypes = {
  project: PropTypes.object.isRequired
};

export default function ExpiredBanner(props) {
  const { project, creator } = props;

  const launch = useDialogLauncher();
  const { history } = usePageRouter();

  function onCloseExtend() {
    launch(DialogManager.ProjectCloseExtend.Config(project.id));
  }

  function onClose() {
    launch(DialogManager.ProjectClose.Config(project.id));
  }

  return (
    <Wrapper
      color="yellow"
      creator={creator}
      title={(
        <>
          Your project timeline has ended.
        </>
      )}
      subtitle={(
        <>
          Partners can no longer see your project because it's set in the past. Update your
          timeline to share it again. Or close your project if it has ended.
        </>
      )}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        <ConnectButton outline={true} onClick={onClose}>
          Close your project
        </ConnectButton>
        <ConnectButton outline={true} onClick={() => history.push(`/post-project/${project.id}/date`)}>
          Extend your timeline
        </ConnectButton>
      </div>
    </Wrapper>
  );
};
