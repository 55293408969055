import React from 'react';
import { UsersIcon } from '@heroicons/react/24/outline';
import IconSection from '../../../_new_card_components/IconSection';

export default function Slots(props) {
  const { event } = props;

  return (
    <IconSection
      icon={UsersIcon}
      title={event.data.slots}
      tooltip="Partner slots"
    />
  );
};
