import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Overlay from '../../../forms/_common/Overlay';
import Activity from './Activity';
import classNames from 'classnames';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';

Activities.propTypes = {
  userActivities: PropTypes.object
};

export default function Activities(props) {
  const {
    user,
    activities,
    userActivities,
    columnLayout = false
  } = props;

  const mutation_create = useQueryMutation('tagNode.create');
  const mutation_destroy = useQueryMutation('tagNode.destroy2');

  const saving = (
    mutation_create.isLoading ||
    mutation_destroy.isLoading
  );

  function onSelectActivity(activity, userActivity) {
    if (!userActivity) {
      mutation_create.mutate({
        userId: user.id,
        tagId: activity.id
      }, {
        onSuccess: payload => userActivities._add(payload)
      });
    } else {
      mutation_destroy.mutate(userActivity._tagNode, {
        onSuccess: payload => userActivities._remove(payload)
      });
    }
  }

  return (
    <Overlay isVisible={saving}>
      <div className={classNames(
        'grid grid-cols-1 gap-3',
        columnLayout && 'xl:grid-cols-2'
      )}>
        {activities.data.map(function(activity) {
          const selectedActivity = _.find(userActivities.data, function(userActivity) {
            return userActivity._tag.id === activity.id;
          });

          const selected = !!selectedActivity;

          return (
            <Activity
              key={activity.id}
              activity={activity}
              selected={selected}
              onClick={() => onSelectActivity(activity, selectedActivity)}
            />
          );
        })}
      </div>
    </Overlay>
  );
}
