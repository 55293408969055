import Text from './Text';
import { TextColor, TextWeight, TextSize, getSize, getColor, getWeight } from './utils';

export default function BaseGrayMedium(props) {
  return (
    <Text
      {...props}
      size={TextSize.Base}
      color={TextColor.GrayMedium}
     />
  );
}
