import React from 'react';

export default function EmptyStateActions(props) {
  const { children } = props;

  return (
    <div className="flex justify-center space-x-2">
      {children}
    </div>
  );
}
