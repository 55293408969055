import React from 'react';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import ParentLayout from '../Layout';
import Navigation from '../../../dialogs_common/Navigation';
import Urls from '../Urls';
import Form from './Form';
import { PayloadStates } from '@lore/utils';
import useNetwork from '../../../hooks/useNetwork';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { location, history, match } = useRouter();
  const user = useUser();
  const project = useConnect('project.byId', {
    id: Number(match.params.projectId)
  });

  const network = useNetwork();

  if (project.state === PayloadStates.FETCHING) {
    return null;
  }

  return (
    <ParentLayout
      project={project}
      page="Resources"
      title="Are there additional resources you need to complete this project?"
      description={(
        <>
          These might include items, funds, or access to specific tools. While we can't
          guarantee CommunityShare, {network.data.name}, or a partner can meet these needs, it
          never hurts to broadcast your need! Be as specific as you can.
        </>
      )}
    >
      <Form
        user={user}
        project={project}
        navigation={(
          <Navigation v5={true} activeStep={17} steps={19}/>
        )}
        onPrevious={(newData) => history.push(Urls(project).DOCUMENTS)}
        onNext={(newData) => network.data.grants === 'Yes' ?
          history.push(Urls(project).GRANT) :
          history.push(Urls(project).SEARCH_EXPLANATION)
        }
        onSkip={(newData) => network.data.grants === 'Yes' ?
          history.push(Urls(project).GRANT) :
          history.push(Urls(project).SEARCH_EXPLANATION)
        }
        onExit={() => history.push(Urls(project).PROJECT_EDIT)}
      />
    </ParentLayout>
  );
};
