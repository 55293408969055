import { useState } from 'react';
import { DateTime } from 'luxon';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import { useUser } from '@lore/auth';

export default function useMessages(user, _timestamp) {
  const currentUser = useUser();
  const [timestamp] = useState(_timestamp || new Date().toISOString());

  return useConnect('message.find', {
    where: {
      eager: {
        $where: {
          $and: [
            {
              $or: [
                { senderId: user.id },
                { senderId: currentUser.id }
              ]
            },
            {
              $or: [
                { recipientId: user.id },
                { recipientId: currentUser.id }
              ]
            }
          ],
          // createdAt: {
          //   $lte: timestamp
          // }
        }
      }
    },
    pagination: {
      page: 1,
      pageSize: 1000,
      order: 'createdAt asc'
    }
  });
};
