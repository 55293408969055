import React from 'react';
import { useConnect } from '@lore/query-connect';
import UserRole from './UserRole';

const MAX_ROLES = 2;

export default function UserRoles(props) {
  const { user } = props;

  const _roles = useConnect('role.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    },
    pagination: {
      page: 1,
      pageSize: 100,
      order: 'createdAt asc'
    }
  });

  const roles = {
    ..._roles,
    data: _roles.data.slice(0, MAX_ROLES)
  };

  return (
    <div className="text-sm text-cs-gray-500 text-center">
      {roles.data.map(function(role, index) {
        return [
          <UserRole key={`role-${index}`} role={role}/>,
          index < roles.data.length - 1 ? <span key={`dot-${index}`}>{' • '}</span> : null
        ];
      })}
    </div>
  );
}
