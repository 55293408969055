import React, { useState } from 'react';
import { BookOpenIcon } from '@heroicons/react/24/outline';
import useRouter from '../../hooks/useRouter';
import Urls from '../project-create/Urls';
import DataCard from '../profile-edit/DataCard';
import { PayloadStates } from '@lore/utils';
import useProjectTags from '../../hooks/useProjectTags';
import TagTypes from '../../constants/TagTypes';
import usePageRouter from '../../dialogs-routable/_hooks/usePageRouter';

export default function Subjects(props) {
  const { project } = props;

  const { history } = usePageRouter();

  const [timestamp] = useState(new Date().toISOString());

  const projectSubjects = useProjectTags(project, TagTypes.Subject, timestamp);

  let subtitle = '';

  if (projectSubjects.state === PayloadStates.FETCHING) {
    subtitle = '...';
  } else {
    subtitle = projectSubjects.data.map(function(projectSubject, index) {
      return (
        <div key={index}>
          {projectSubject.data.name}
        </div>
      );
    });
  }

  return (
    <DataCard
      title="Subject areas this project incorporates"
      icon={BookOpenIcon}
      subtitle={subtitle || '(not provided)'}
      onClick={() => history.push(Urls(project).SUBJECTS)}
    />
  );
};
