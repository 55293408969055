import React from 'react';
import _ from 'lodash';
import { parse, stringify } from 'query-string';
import { BuildingOfficeIcon, CheckIcon, GlobeAltIcon } from '@heroicons/react/24/outline';
import getSearchParamsFromQuery from '../../utils/user/getSearchParamsFromQuery';
import useRouter from '../../hooks/useRouter';
import DropdownFilter from '../../components/DropdownFilter';
import useTags from '../../hooks/useTags';
import TagTypes from '../../constants/TagTypes';

// http://app.communityshare.test/projects?locationCondition=%7B%22address%22%3A%2285712%22%2C%22latitude%22%3A%2232.2540946000000%22%2C%22longitude%22%3A%22-110.8850949000000%22%2C%22radius%22%3A10%7D&page=1
// http://app.communityshare.test/projects?locationCondition=%7B%22address%22%3A%2285712%22%2C%22latitude%22%3A%2232.2540946000000%22%2C%22longitude%22%3A%22-110.8850949000000%22%2C%22radius%22%3A10%7D&page=1

// http://app.communityshare.test/projects?locationCondition=%7B%22address%22%3A%2285712%22%2C%22latitude%22%3A%2232.2540946000000%22%2C%22longitude%22%3A%22-110.8850949000000%22%2C%22radius%22%3A50%7D&page=1
// http://app.communityshare.test/projects?locationCondition=%7B%22address%22%3A%2285712%22%2C%22latitude%22%3A%2232.2540946000000%22%2C%22longitude%22%3A%22-110.8850949000000%22%2C%22radius%22%3A50%7D&page=1

// http://app.communityshare.test/projects?page=1
//
export default function TypeFilter(props) {
  const { location, history } = useRouter();

  const searchParams = getSearchParamsFromQuery(parse(location.search));

  const interactionTypes = useTags(TagTypes.InteractionType);
  const inPersonTag = _.find(interactionTypes.data, m => m.data.name === 'In-person');
  const virtualTag = _.find(interactionTypes.data, m => m.data.name === 'Virtual');

  function onChange(value) {
    const search = parse(location.search) || {};

    history.push({
      pathname: location.pathname,
      search: stringify(_.extend({}, search, {
        interactionTypes: value,
      }))
    });
  }

  const title = (
    inPersonTag && searchParams.interactionTypes.indexOf(inPersonTag.id) >= 0 ? 'In-person' :
    virtualTag && searchParams.interactionTypes.indexOf(virtualTag.id) >= 0 ? 'Virtual' :
    'All'
  );

  return (
    <DropdownFilter
      subtitle="Type:"
      title={title}
      count={searchParams.interactionTypes.length}
    >
      {inPersonTag && (
        <DropdownFilter.MenuItem
          icon={BuildingOfficeIcon}
          title="In-person"
          subtitle={`Show results desiring an in-person interaction.`}
          selected={searchParams.interactionTypes.indexOf(inPersonTag.id) >= 0}
          onClick={() => onChange(inPersonTag.id)}
        />
      )}
      {virtualTag && (
        <DropdownFilter.MenuItem
          icon={GlobeAltIcon}
          title="Virtual"
          subtitle={`Show results that I can participate in virtually.`}
          selected={searchParams.interactionTypes.indexOf(virtualTag.id) >= 0}
          onClick={() => onChange(virtualTag.id)}
        />
      )}
      <DropdownFilter.MenuItem
        icon={CheckIcon}
        title="All"
        subtitle={`Show results matching any interaction type.`}
        selected={!searchParams.interactionTypes.length}
        onClick={() => onChange()}
      />
    </DropdownFilter>
  );
}
