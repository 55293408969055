import React, { useState } from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import validators from '../../../utils/validators';
import Avatar from '../../../components/Avatar';
import Loader from '../../../components/Loader';
import SelectField from '../../../forms/_fields_v2/SelectField';
import FormField from '../../../forms/_common/FormField';
import _ from 'lodash';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import UserOrganization from '../../../pages/people/__common/UserOrganization.v2';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';
import Card from '../../../components/Card';
import OutlineButton from '../../../components/Button/OutlineButton.gray';

const resultMap = {
  'Nothing':      `Partner did not provide advice, connections, or work on this project.`,
  'Contributed':  `Partner provided me with advice on a project idea, resources for the project, or a referral to a different partner.`,
  'Participated': `Partner visited me and/or my students virtually or in-person. We worked on a project together.`
};

export default function Question(props) {
  const { question, onViewMessages } = props;

  const recipient = useConnect('user.byId', {
    id: question.data.userId
  });

  const project = useConnect('project.byId', {
    id: question.data.projectId
  });

  const [data, setData] = useState({
    result: question.data.result
  });
  const [showErrors, setShowErrors] = useState(false);

  const mutation = useQueryMutation('question.update', question);
  const { saving, success, error, request } = mutation;

  // useEffect(() => {
  //   if (success) {
  //     onNext(data);
  //   }
  // }, [success]);

  function onSubmit(data) {
    mutation.mutate(data);
  }

  function onChange(name, value) {
    const nextData = _.merge({}, data);
    nextData[name] = value;
    setData(nextData);
    onSubmit(nextData);
  }

  if (
    recipient.state === PayloadStates.FETCHING ||
    project.state === PayloadStates.FETCHING
  ) {
    return (
      <div className="flex bg-white shadow-lg rounded-xl p-2.5 w-full">
        <Loader />
      </div>
    );
  }

  return (
    <Overlay isVisible={saving}>
      <FormErrors data={data} validators={{
        result: [validators.isRequired]
      }}>
        {({ errors, hasError }) => {
          return (
            <Card className="flex flex-col space-y-3 p-4 w-full">
              <div className="flex justify-between flex-wrap gap-4">
                <div className="flex flex-1x items-center space-x-4">
                  <Avatar size={12} src={recipient.data.avatarUrl}/>
                  <div className="flex flex-col">
                    <div className="text-lg font-semibold text-cs-gray-900">
                      {recipient.data.firstName} {recipient.data.lastName}
                    </div>
                    <div className="text-sm text-cs-gray-500">
                      <UserOrganization user={recipient} />
                    </div>
                  </div>
                </div>
                {onViewMessages && (
                  <OutlineButton
                    label="View messages"
                    onClick={() => onViewMessages(recipient)}
                  />
                )}
              </div>
              <div className="flex-1">
                <FormField name="result" data={data} errors={showErrors && errors} onChange={onChange}>
                  {(field) => (
                    <SelectField
                      // label="Role"
                      // disabled={saving}
                      value={field.value}
                      error={field.error}
                      placeholder="Select contribution level..."
                      options={{
                        data: _.keys(resultMap).map((option) => {
                          return {
                            id: option,
                            data: {
                              name: resultMap[option]
                            }
                          }
                        })
                      }}
                      optionLabel="name"
                      onChange={field.onChange}
                    />
                  )}
                </FormField>
              </div>
            </Card>
          );
        }}
      </FormErrors>
    </Overlay>
  );
};
