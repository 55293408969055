import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import validators from '../../utils/validators';
import Overlay from '../../forms/_common/Overlay';
import FormErrors from '../../forms/_common/FormErrors';
import RequestError from '../../forms/_common/RequestError';
import ErrorMessage from '../../forms/_common/ErrorMessage';
import SuccessMessage from '../../forms/_common/SuccessMessage';
import Form from './Form';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import useOfferEmailPreview from '../../utils/offer/useOfferEmailPreview';
import { useQueryMutation } from '../../../hooks/@lore/query-connect';

InviteDialog.propTypes = {
  user: PropTypes.object.isRequired
};

const customMessageMap = {
  // 0: `My students and I are planning a project and a basic summary of the project is below for context. We did have a quick question. My students and I were curious about...`,
  1: `My organization and I have an idea we'd like to offer educators and students. We want to check the viability of this idea, know if this is a good fit for you as an educator, and understand if we're framing our description in a relevant way. By clicking "Respond or Decline" you'll be able to see the full outline of the idea. We specifically wanted feedback on...`,
  2: `My organization and I have mapped out an idea of how we'd like to engage with you and your students. By clicking "Respond or Decline" you'll be able to see the full outline of the idea. I'd love to answer any questions you have or talk more about this possibility with you. Thank you for considering us!`
};

const reasonMap = {
  1: 'Feedback',
  2: 'Participation',
  3: 'Referral'
};

export default function InviteDialog(props) {
  const { user, offer } = props;

  const [data, setData] = useState({
    customMessage: customMessageMap[1],
    reason: null
  });
  const [showErrors, setShowErrors] = useState(false);

  const emailPreview = useOfferEmailPreview(offer, user, data.customMessage);

  const mutation = useQueryMutation('claim.create');
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      props.onCancel();
      props.onSubmit && props.onSubmit(request);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate({
      userId: user.id,
      offerId: offer.id,
      request: reasonMap[data.reason],
      requestMessage: data.customMessage
    });
  }

  function onChange(name, value) {
    const nextData = _.merge({}, data);
    nextData[name] = value;

    if (name === 'reason') {
      nextData.customMessage = customMessageMap[value];
    }

    setData(nextData);
  }

  onChange = onChange.bind(data);

  return (
    <DialogTemplate className="w-screen max-w-screen-sm">
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          customMessage: [validators.isRequired],
          reason: [validators.number.isRequired]
        }}>
          {({errors, hasError}) => (
            <Form
              data={data}
              errors={errors}
              showErrors={showErrors}
              onChange={onChange}
              onSubmit={() => hasError ? setShowErrors(true) : onSubmit()}
              onCancel={props.onCancel}
              alert={error ? (
                <RequestError request={request}/>
              ) : showErrors ? (
                <ErrorMessage>
                  Please fill in all required fields.
                </ErrorMessage>
              ) : (false && success) ? (
                <SuccessMessage>
                  Idea created.
                </SuccessMessage>
              ) : null}
              user={user}
              emailPreview={emailPreview}
            />
          )}
        </FormErrors>
      </Overlay>
    </DialogTemplate>
  );
};
