import React from 'react';
import usePageRouter from '../../../dialogs-routable/_hooks/usePageRouter';
import PendingEvaluationButton from '../PendingEvaluationButton';

export default function Evaluation(props) {
  const { evaluation } = props;

  const { history } = usePageRouter();

  function redirectToEvaluation() {
    history.push(`/evaluation-project-educator/${evaluation.id}`);
  }

  return (
    <PendingEvaluationButton
      evaluation={evaluation}
      onEdit={redirectToEvaluation}
    />
  );
}
