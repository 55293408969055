import React from 'react';
import PropTypes from 'prop-types';

EmptyState.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  children: PropTypes.node
};

export default function EmptyState(props) {
  const { title, subtitle, children } = props;

  return (
    <div className="text-center border-2 border-cs-gray-200 border-dashed rounded-lg p-12">
      <div className="mt-2 space-y-1">
        <h3 className="text-xl font-semibold text-cs-gray-500">
          {title}
        </h3>
        <div className="text-base text-cs-gray-500">
          {subtitle}
        </div>
      </div>
      {children}
    </div>
  );
}
