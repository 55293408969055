import React from 'react';
import { useUser } from '@lore/auth';
import ParentLayout from '../EditLayout';
import Form from './Form';
import Urls from '../Urls';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { history } = useRouter();

  const currentUser = useUser();

  return (
    <ParentLayout
      page="Sites"
      title={`${currentUser.data.firstName ? `Hi ${currentUser.data.firstName}! ` : ''} Where do you work?`}
      description="Knowing where you work helps us connect you to community partners nearby. Your address will not show up in your public profile."
    >
      <Form
        user={currentUser}
        // navigation={(
        //   <Navigation v5={true} activeStep={2} steps={16}/>
        // )}
        // onPrevious={(newData) => history.push(Urls(offer).DESCRIPTION)}
        // onNext={(newData) => history.push(Urls(offer).OBJECTIVES)}
        // onSkip={() => history.push(Urls(offer).OBJECTIVES)}
        onExit={() => history.push(Urls(currentUser).PROFILE_EDIT)}
      />
    </ParentLayout>
  );
};
