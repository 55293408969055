import React from 'react';
import BiographySuggestionsDialog from '../../dialogs/BiographySuggestions';

export default function Launcher(props) {
  return (
    <BiographySuggestionsDialog
      onCancel={props.onClose}
    />
  );
};
