import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../components/Loader';

LocationMap.propTypes = {
  location: PropTypes.object.isRequired
};

export default function LocationMap(props) {
  const { location, cursor = 'pointer' } = props;

  useEffect(() => {
    function onGeocodedAddress(results, status) {
      if (status !== 'OK') {
        const map = new GMaps({
          div: `#map-${location.id}`,
          lat: 37.923483,
          lng: -99.650695,
          zoom: 3,
          // hide controls
          // zoomControl: false,
          // mapTypeControl: false,
          // scaleControl: false,
          // streetViewControl: false,
          // rotateControl: false,
          // fullscreenControl: false,
          // disable default UI and set cursor to pointer
          draggable : false,
          disableDefaultUI : true,
          clickableIcons: false,
          keyboardShortcuts: false,
          gestureHandling: 'none',
          draggableCursor: cursor
        });

        return;
      }

      const latlng = results[0].geometry.location;

      const map = new GMaps({
        div: `#map-${location.id}`,
        lat: latlng.lat(),
        lng: latlng.lng(),
        zoom: 13,
        // hide controls
        // zoomControl: false,
        // mapTypeControl: false,
        // scaleControl: false,
        // streetViewControl: false,
        // rotateControl: false,
        // fullscreenControl: false,
        // disable default UI and set cursor to pointer
        draggable : false,
        disableDefaultUI : true,
        clickableIcons: false,
        keyboardShortcuts: false,
        gestureHandling: 'none',
        draggableCursor: cursor
      });

      const hide = {
        stylers: [{visibility: 'off'}]
      };

      /*
       * Docs for changing map styles can be found here:
       * https://developers.google.com/maps/documentation/javascript/style-reference
       */

      map.setOptions({
        styles: [
          // { featureType: 'all', ...hide },
          { featureType: 'administrative', ...hide },
          // { featureType: 'landscape', ...hide },
          { featureType: 'poi', ...hide },
          // { featureType: 'road', ...hide },
          { featureType: 'transit', ...hide },
          // { featureType: 'water', ...hide },
        ]
      });

      // // map.setCenter(latlng.lat(), latlng.lng());
      map.addMarker({
        lat: latlng.lat(),
        lng: latlng.lng()
      });
    }

    GMaps.geocode({
      address: `${location.data.addressLine1},${location.data.city},${location.data.state},${location.data.zipCode}`,
      callback: onGeocodedAddress.bind(this)
    });
  }, []);

  return (
    <div id={`map-${location.id}`} className="cs-map">
      <Loader />
    </div>
  );
};
