import React from 'react';
import { DateTime } from 'luxon';
import Complete from './status/Complete';
import Incomplete from './status/Incomplete';
import NotStarted from './status/NotStarted';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import { PayloadStates } from '@lore/utils';
import { useConnect } from '../../../../hooks/@lore/query-connect';
import usePageRouter from '../../../dialogs-routable/_hooks/usePageRouter';
import Loader from '../../../components/Loader';
import RightMediaSection from '../_common/RightMediaSection';

export default function StatusMediaSection(props) {
  const { evaluation, showViewLink = true, showEditLink = true } = props;

  const { history } = usePageRouter();

  const user = useConnect('user.byId', {
    id: evaluation.data.userId
  });

  const project = useConnect('project.byId', {
    id: evaluation.data.projectId
  });

  const launch = useDialogLauncher();

  function onClickView() {
    if (user.id === project.data.creatorId) {
      launch(DialogManager.EvaluationProjectEducator.Config(evaluation.id));
    } else {
      launch(DialogManager.EvaluationProjectPartner.Config(evaluation.id));
    }
  }

  function onClickEdit() {
    if (user.id === project.data.creatorId) {
      history.push(`/evaluation-project-educator/${evaluation.id}`);
    } else {
      history.push(`/evaluation-project-partner/${evaluation.id}`);
    }
  }

  const diff = DateTime.fromISO(evaluation.data.updatedAt).toMillis() - DateTime.fromISO(evaluation.data.createdAt).toMillis();

  if (project.state === PayloadStates.FETCHING) {
    // return (
    //   <div className="w-24">
    //     <Loader />
    //   </div>
    // );
    return (
      <RightMediaSection
        title="..."
        subtitle="..."
      />
    )
  }

  // return (
  //   <NotStarted
  //     evaluation={evaluation}
  //     onClickView={showViewLink && onClickView}
  //     onClickEdit={showEditLink && onClickEdit}
  //   />
  // );

  if (evaluation.data.completed) {
    return (
      <Complete
        evaluation={evaluation}
        onClickView={showViewLink && onClickView}
        onClickEdit={showEditLink && onClickEdit}
      />
    );
  }

  if (diff > 100) {
    return (
      <Incomplete
        evaluation={evaluation}
        onClickView={showViewLink && onClickView}
        onClickEdit={showEditLink && onClickEdit}
      />
    );
  }

  return (
    <NotStarted
      evaluation={evaluation}
      onClickView={showViewLink && onClickView}
      onClickEdit={showEditLink && onClickEdit}
    />
  );
};
