import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import RemoveLoadingScreen from '../RemoveLoadingScreen';
import ScrollToTop from '../../components/ScrollToTop';
import logo from '../../../assets/images/logo.png';
import Bubbles from './Bubbles';

export default function Layout(props) {
  const {
    evaluation,
    page,
    onPrevious,
    onNext,
    onSkip,
    children
  } = props;

  return (
    <div className="flex h-screen overflow-hidden relative">
      {evaluation && (
        <Helmet>
          <title>{`Evaluation #${evaluation.id}: ${page}`}</title>
        </Helmet>
      )}
      <RemoveLoadingScreen/>
      <Link className="cursor-pointer absolute top-5 left-6" to="/">
        <img className="h-16" src={logo} alt="logo" />
      </Link>
      <Bubbles />
      <div className="w-full relative">
        <ScrollToTop />
        {_.isFunction(children) ?
          children({
            onPrevious,
            onNext,
            onSkip
          }) :
          children
        }
      </div>
    </div>
  );
};
