import React from 'react';

export default function Section(props) {
  const { children, className } = props;

  return (
    <div className={className}>
      {children}
    </div>
  );
};
