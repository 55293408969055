import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import DividerCard from '../../../components/DividerCard';
import ComposableProjectHeader from '../../../components_cards/ComposableProjectHeader';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import OutlineButtonBlue100 from '../../../components/Button/OutlineButton.blue-100';
import Evaluation from './Evaluation';
import Tags from './Tags';
import Questions from '../../my-past/ProjectCreated/Questions';
import PendingEvaluationText from '../PendingEvaluationText';

export default function EvaluationProjectEducator(props) {
  const { evaluation } = props;

  const project = useConnect('project.byId', {
    id: evaluation.data.projectId
  });

  const launch = useDialogLauncher();

  function onViewProject() {
    launch(DialogManager.ProjectManage.Config(project.id, '/view'));
  }

  function onViewEvaluation() {
    launch(DialogManager.EvaluationProjectEducator.Config(evaluation.id));
  }

  if (project.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <DividerCard className="overflow-hidden -m-px border border-cs-blue-500" border={false}>
      <ComposableProjectHeader
        tags={(
          <>
            <Tags project={project} />
            <Questions project={project} />
          </>
        )}
        showTags={false}
        project={project}
      />
      <div className="flex justify-between p-4 bg-cs-blue-500 flex-wrap gap-2">
        <PendingEvaluationText
          title="Share the impact of your project"
          evaluation={evaluation}
          onView={onViewEvaluation}
        />
        <div className="flex flex-wrap gap-2">
          <OutlineButtonBlue100
            label="View project"
            onClick={onViewProject}
          />
          <Evaluation evaluation={evaluation}/>
        </div>
      </div>
    </DividerCard>
  );
}
