import React from 'react';
import PropTypes from 'prop-types';
// import Role from './Role.old';
import Role from './Role';

Roles.propTypes = {
  roles: PropTypes.object.isRequired
};

export default function Roles(props) {
  const { roles, children } = props;

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 gap-6">
        {roles.data.map(function(role) {
          return (
            <Role
              key={role.id}
              role={role}
            />
          );
        })}
      </div>
      {children}
    </div>
  );
};
