import React from 'react';
import { useUser } from '@lore/auth';
import ParentLayout from '../EditLayout';
import Form from './Form';
import Urls from '../Urls';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { history } = useRouter();

  const currentUser = useUser();

  return (
    <ParentLayout
      page="Phone"
      title="How can we reach you?"
      description={(
        <>
          We'll never display your phone number on your public profile. It will only be
          used to help contact you in case of a cancellation or time-sensitive issue
          related to an activity you've created or joined.
        </>
      )}
    >
      <Form
        user={currentUser}
        // navigation={(
        //   <Navigation v5={true} activeStep={2} steps={16}/>
        // )}
        // onPrevious={(newData) => history.push(Urls(offer).DESCRIPTION)}
        // onNext={(newData) => history.push(Urls(offer).OBJECTIVES)}
        // onSkip={() => history.push(Urls(offer).OBJECTIVES)}
        onExit={() => history.push(Urls(currentUser).PROFILE_EDIT)}
      />
    </ParentLayout>
  );
};
