import React from 'react';
import PropTypes from 'prop-types';
import DialogTemplate from '../../../dialogs_common/DialogTemplate';
import SolidButton from '../../../components/Button/SolidButton';
import FormTemplate from '../../../dialogs_common/FormTemplate';

Confirmation.propTypes = {
  onCancel: PropTypes.func.isRequired
};

export default function Confirmation(props) {
  const { onCancel } = props;

  return (
    <DialogTemplate>
      <FormTemplate
        title="Your request has been received."
        subtitle={(
          <>
            Support will review your request and contact you once your account is reactivated, or
            if there are any issues that need to be resolved first.
          </>
        )}
        footer={(
          <div className="text-center w-full">
            <SolidButton onClick={onCancel}>
              Close
            </SolidButton>
          </div>
        )}
      />
    </DialogTemplate>
  );
};
