import React from 'react';
import ConnectButton from '../../../pages/_banner/ConnectButton';
import Wrapper from '../../../pages/_banner/Wrapper';
import useDialogLauncher from '../../_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';

export default function ConnectBanner(props) {
  const { question, creator } = props;

  const launch = useDialogLauncher();

  function onViewMessages() {
    launch(DialogManager.Messages.Config(creator.id, {
      projectId: question.data.projectId
    }));
  }

  return (
    <Wrapper
      color="accent"
      creator={creator}
      title="Thanks for being a collaborator on this project!"
      subtitle={(
        <>
          Keep the momentum going by responding to messages, and once the project is
          complete, we'll invite you to share your experience with us.
        </>
      )}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        <ConnectButton onClick={onViewMessages}>
          Go to messages
        </ConnectButton>
      </div>
    </Wrapper>
  );
};
