import React from 'react';

export default function PageHeader(props) {
  const { title, subtitle } = props;

  return (
    <div className="flex flex-col space-y-1">
      <div className="text-3xl font-semibold text-cs-gray-900">
        {title}
      </div>
      {subtitle && (
        <div className="text-base font-normal text-cs-gray-500">
          {subtitle}
        </div>
      )}
    </div>
  );
}
