import React from 'react';
import classNames from 'classnames';

export default function BubbleTemplate(props) {
  const { icon, title, subtitle, children } = props;

  return (
    <div className="px-10 pt-32 pb-32 max-w-screen-lg m-auto">
      {icon}
      {title && (
        <div className="text-3xl text-cs-gray-900 font-semibold text-center mb-4">
          {title}
        </div>
      )}
      <div className={classNames(
        'text-lg text-cs-gray-700 text-center space-y-4',
        children && 'mb-12'
      )}>
        {subtitle}
      </div>
      {children}
    </div>
  );
};
