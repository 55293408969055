import React, { useState } from 'react';
import { ClockIcon } from '@heroicons/react/24/outline';
import useRouter from '../../hooks/useRouter';
import Urls from '../project-create/Urls';
import DataCard from '../profile-edit/DataCard';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import useProjectTags from '../../hooks/useProjectTags';
import TagTypes from '../../constants/TagTypes';
import { formatTimeRange } from '../../utils/dateTimeUtils';
import usePageRouter from '../../dialogs-routable/_hooks/usePageRouter';

export default function Time(props) {
  const { project } = props;
  const [timestamp] = useState(new Date().toISOString());

  const { history } = usePageRouter();
  const currentUser = useUser();

  let subtitle = '';

  const projectTimes = useProjectTags(project, TagTypes.Time, timestamp);

  if (project.data.specificTime === 'Yes') {
    subtitle = formatTimeRange(project.data.startTime, project.data.endTime, currentUser.data.timezone);
  } else if(projectTimes.state === PayloadStates.FETCHING) {
    subtitle = '...';
  } else {
    subtitle = projectTimes.data.map(function(projectTime) {
      return projectTime.data.name;
    }).join(', ');
  }

  return (
    <DataCard
      title="Time"
      icon={ClockIcon}
      subtitle={subtitle || '(not provided)'}
      onClick={() => history.push(Urls(project).TIME)}
    />
  );
};
