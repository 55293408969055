import React, { useEffect } from 'react';
import useImpersonation from './useImpersonation';
import classNames from 'classnames';
import { useQueryMutation } from '../../hooks/@lore/query-connect';
import { useQueryClient } from '@tanstack/react-query';

export default function Impersonation(props) {
  const impersonation = useImpersonation();
  const queryClient = useQueryClient();

  const mutation = useQueryMutation('impersonation.destroy2');
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      queryClient.invalidateQueries();
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate(impersonation);
  }

  if (impersonation) {
    return (
      <>
        <div className="absolute top-0 left-2 right-2 flex justify-center items-center z-60">
          <div className="absolute top-0 left-0 right-0 h-2 bg-red-500 opacity-70 z-10" />
          <div className="absolute top-2 w-60 h-14 pb-2 bg-red-500 opacity-70 z-20 rounded-b-lg" />
          <div className="absolute top-2 left-1/2 -mx-28 pl-1 z-20">
            <div
              className={classNames(
                'bg-white text-red-500 font-semibold uppercase rounded-lg text-center py-3 px-4',
                saving && 'opacity-50',
                !saving && 'cursor-pointer hover:bg-red-50 shadow-lg'
              )}
              onClick={onSubmit}
            >
              Stop Impersonating
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 left-2 right-2 h-2 bg-red-500 opacity-70 shadow z-60" />
        <div className="absolute left-0 w-2 h-full bg-red-500 opacity-70 shadow z-60" />
        <div className="absolute right-0 w-2 h-full bg-red-500 opacity-70 shadow z-60" />
      </>
    );
  }

  return null;
};
