import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../components/Avatar';
import useConversations from '../messages/useConversations';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import Text, { TextColor, TextWeight } from '../../typography';

Header.propTypes = {
  user: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired
};

export default function Header(props) {
  const { user, messages } = props;

  const conversations = useConversations();

  if (conversations.state === PayloadStates.FETCHING) {
    return (
      <div className="bg-white z-10">
        <Loader />
      </div>
    );
  }

  return (
    <div className="bg-white z-10">
      <div className="flex flex-col -mb-px border-b border-cs-gray-200 shadow-cs-flat px-6 pt-6 pb-5">
        <div className="hiddenx md:blockx">
          <div className="flex items-start space-x-0 sm:space-x-3">
            <div className="hidden sm:block">
              <Avatar size={12} src={user.data.avatarUrl}/>
            </div>
            <div className="flex-1 space-y-0.5">
              <Text.LgSemibold className="line-clamp-1">
                Messages with {user.data.firstName} {user.data.lastName}
              </Text.LgSemibold>
              <div className="flex flex-col sm:flex-row justify-between items-end space-x-6">
                <Text.BaseGrayMedium className="flex-1 line-clamp-2">
                  {/*<UserRole user={user} />*/}
                  {user.data.biography}
                </Text.BaseGrayMedium>
                <Text.BaseGrayMedium>
                  {messages.data.length} messages
                </Text.BaseGrayMedium>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
