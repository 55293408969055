import Text from './Text';
import { TextColor, TextWeight, TextSize, getSize, getColor, getWeight } from './utils';

export default function BaseSemibold(props) {
  return (
    <Text
      {...props}
      size={TextSize.Base}
      weight={TextWeight.Semibold}
    />
  );
}
