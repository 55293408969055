import React from 'react';
import { DateTime } from 'luxon';
import MiddleDot from '../evaluations/_common/MiddleDot';
import { getTimestampDiff } from '../../utils/dateTimeUtils';

export default function PendingEvaluationText(props) {
  const {
    title = 'Share the impact',
    evaluation,
    onView
  } = props;

  const diff = getTimestampDiff(evaluation.data.updatedAt, evaluation.data.createdAt);

  return (
    <div className="flex flex-col">
      <div className="text-lg font-semibold text-white">
        {title}
      </div>
      <div className="flex text-sm text-white flex-wrap gap-1">
        {diff > 0 ? (
          <>
            <div>
              Evaluation last updated {DateTime.fromISO(evaluation.data.updatedAt).toRelative()}
            </div>
            <span>
              <MiddleDot/>
            </span>
            <div className="text-white underline hover:opacity-70 cursor-pointer" onClick={onView}>
              View progress
            </div>
          </>
        ) : (
          <span>
            Evaluation created {DateTime.fromISO(evaluation.data.createdAt).toRelative()}
          </span>
        )}
      </div>
    </div>
  );
}
