import React from 'react';
import classNames from 'classnames';
import Site from '../../pages/my-events/Event/Header-new/Site';
import Date from '../../pages/my-events/Event/Header-new/Date';
import Creator from '../../pages/my-events/Event/Header-new/Creator';
import Title from './Title';
import Description from './Description';
import StatusTags from '../../pages/my-events/Event/Header-new/StatusTags';
import { useConnect } from '../../../hooks/@lore/query-connect';
import Distance from '../../pages/events/Event/Distance';
import InvitationMeta from './InvitationMeta';
import Meta from './Meta';

export default function ComposableInvitationHeader(props) {
  const {
    invitation,
    tags,
    onClick,
    showTags = true,
    showTitle = true,
    showDescription = true,
    showDate = true,
    showCreator = true,
    showSite = true,
    showDistance = true,
    showInvitationMeta = true
  } = props;

  const event = useConnect('event.byId', {
    id: invitation.data.eventId
  });

  return (
    <div className="flex flex-col p-4 space-y-2">
      <div className="space-y-2">
        {tags}
        {showTags && (
          <StatusTags event={event}/>
        )}
        <div
          className={classNames(
            'space-y-1 pr-10',
            onClick && 'cursor-pointer hover:text-cs-gray-500'
          )}
          onClick={onClick ? () => onClick(event) : undefined}
        >
          {showTitle && (
            <Title invitation={invitation} />
          )}
          {showDescription && (
            <Description invitation={invitation}/>
          )}
        </div>
      </div>
      <div className="flex justify-between flex-wrap gap-x-4 gap-y-2">
        <div className="flex flex-wrap gap-x-4 gap-y-2">
          {showDate && (
            <Date event={event}/>
          )}
          {showCreator && (
            <Creator event={event}/>
          )}
          {showSite && (
            <Site event={event}/>
          )}
          {/*{showInvitationMeta && (*/}
          {/*  <InvitationMeta invitation={invitation} />*/}
          {/*)}*/}
          {showDistance && (
            <Distance event={event}/>
          )}
        </div>
        {showInvitationMeta && (
          <Meta invitation={invitation}/>
        )}
      </div>
    </div>
  );
}
