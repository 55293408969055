import React from 'react';
import { BuildingOfficeIcon, CalendarIcon } from '@heroicons/react/24/outline';
import { useConnect } from '@lore/query-connect';
import IconSection from '../../../_new_card_components/IconSection';
import { formatDate, formatDateRange } from '../../../../utils/dateTimeUtils';

export default function Site(props) {
  const { project } = props;

  const site = useConnect('site.byId', {
    id: project.data.siteId
  }, {
    enabled: !!project.data.siteId
  });

  return (
    <IconSection
      icon={BuildingOfficeIcon}
      title={site.data.name || '[missing]'}
      tooltip="Site"
    />
  );
};
