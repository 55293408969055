import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { PencilSquareIcon } from '@heroicons/react/24/outline';

EditLink.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

export default function EditLink(props) {
  const {
    title = 'Edit',
    onClick
  } = props;

  return (
    <div onClick={onClick} className={classNames(
      'flex items-center space-x-1',
      'cursor-pointer hover:opacity-60'
    )}>
      <div className="text-sm text-cs-blue-500">
        {title}
      </div>
      <PencilSquareIcon className="w-4 h-4 text-cs-blue-500" />
    </div>
  );
}
