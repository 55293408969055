import React from 'react';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import ParentLayout from '../Layout';
import Navigation from '../../../dialogs_common/Navigation';
import Urls from '../Urls';
import Form from './Form';
import { PayloadStates } from '@lore/utils';
import _ from 'lodash';
import useOfferTags from '../../../hooks/useOfferTags';
import TagTypes from '../../../constants/TagTypes';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { location, history, match } = useRouter();
  const user = useUser();
  const offer = useConnect('offer.byId', {
    id: Number(match.params.offerId)
  });

  const offerParticipants = useOfferTags(offer, TagTypes.Participant);

  if (
    offer.state === PayloadStates.FETCHING ||
    offerParticipants.state === PayloadStates.FETCHING
  ) {
    return null;
  }

  return (
    <ParentLayout
      offer={offer}
      page="Date"
      title="When can this offer happen?"
      // description="Description"
    >
      <Form
        user={user}
        offer={offer}
        navigation={(
          <Navigation v5={true} activeStep={11} steps={16}/>
        )}
        onPrevious={(newData) => {
          _.find(offerParticipants.data, m => m.data.name === 'Students') ?
            history.push(Urls(offer).COMPETENCIES) :
            history.push(Urls(offer).PARTICIPANTS);
        }}
        onNext={(newData) => history.push(Urls(offer).TIME)}
        onSkip={() => history.push(Urls(offer).TIME)}
        onExit={() => history.push(Urls(offer).OFFER_EDIT)}
      />
    </ParentLayout>
  );
};
