import TagTypes from '../../constants/TagTypes';

const timestamp = new Date().toISOString();

const REQUIRED_FIELDS_PUBLIC_PROJECT = {
  $and: [
    // Must provide activity type and description
    {
      'project.activityId': {
        $exists: true
      },
      'project.siteId': {
        $exists: true
      },
      'project.interactionDescription': {
        $not: '',
        $exits: true
      }
    },
    // Date must be provided and in the future
    {
      $or: [
        {
          'project.specificDate': 'Yes',
          'project.date': {
            $gte: timestamp
          }
        },
        {
          'project.specificDate': 'No',
          'project.startDate': {
            $exists: true
          },
          'project.endDate': {
            $gte: timestamp
          }
        }
      ]
    },
    // Must specify a participant
    {
      'project.tags': {
        $tag_type_count_gte: {
          type: TagTypes.Participant,
          value: 1
        }
      }
    },
    // Must specify a method of interaction
    {
      'project.tags': {
        $tag_type_count_gte: {
          type: TagTypes.InteractionType,
          value: 1
        }
      }
    },
    // Must be visible, and not completed or cancelled
    {
      'project.cancelled': false,
      'project.completed': false,
      'project.published': true
    }
  ]
}

export default REQUIRED_FIELDS_PUBLIC_PROJECT;
