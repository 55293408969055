import React from 'react';
import _ from 'lodash';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import ParentLayout from '../Layout';
import Navigation from '../../../dialogs_common/Navigation';
import Urls from '../Urls';
import Form from './Form';
import useEventTags from '../../../hooks/useEventTags';
import TagTypes from '../../../constants/TagTypes';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { history, match } = useRouter();
  const user = useUser();
  const event = useConnect('event.byId', {
    id: Number(match.params.eventId)
  });

  const eventParticipants = useEventTags(event, TagTypes.Participant);

  if (
    event.state === PayloadStates.FETCHING ||
    eventParticipants.state === PayloadStates.FETCHING
  ) {
    return null;
  }

  return (
    <ParentLayout
      event={event}
      page="Participants"
      title="Who will partners primarily work with during this event?"
      description={(
        <>
          <span className="font-semibold italic">TIP</span>: Choose Educator if they will ONLY be
          working directly with you or colleagues and there are no students involved.
        </>
      )}
    >
      <Form
        user={user}
        event={event}
        navigation={(
          <Navigation v5={true} activeStep={1} steps={13}/>
        )}
        onPrevious={(newData) => history.push(Urls(event).ACTIVITY)}
        onNext={(newData) => {
          _.find(eventParticipants.data, m => m.data.name === 'Students') ?
            history.push(Urls(event).STUDENT_COUNT) :
            history.push(Urls(event).TITLE);
        }}
        onSkip={(newData) => {
          _.find(eventParticipants.data, m => m.data.name === 'Students') ?
            history.push(Urls(event).STUDENT_COUNT) :
            history.push(Urls(event).TITLE);
        }}
        onExit={() => history.push(Urls(event).EVENT_EDIT)}
      />
    </ParentLayout>
  );
};
