import TagTypes from '../../constants/TagTypes';
import { BookOpenIcon } from '@heroicons/react/24/outline';

const tagPrompts = function(tag) {
  const prompts = {
    [TagTypes.Experience]: {
      // Traveling to other countries
      // 477: {
      [`Traveling to other countries`]: {
        label: 'Which countries?',
        placeholder: 'List countries...',
        isRequired: true
      },

      // Speaking multiple languages
      // 478: {
      [`Speaking multiple languages`]: {
        label: 'Which languages?',
        placeholder: 'List languages...',
        isRequired: true
      },

      // Serving as an elected official/community leader
      // 483: {
      [`Serving as an elected official/community leader`]: {
        label: 'Please describe your service.',
        placeholder: 'Describe your service...',
        isRequired: true
      },

      // My neighborhood or local history
      // 485: {
      [`My neighborhood or local history`]: {
        label: 'Which area?',
        placeholder: 'List area...',
        isRequired: true
      },

      // My Hobbies (e.g. Athletics, Arts, Gardening, Genealogy, Music, Outdoor Activities, etc.)
      // 486: {
      [`My Hobbies (e.g. Athletics, Arts, Gardening, Genealogy, Music, Outdoor Activities, etc.)`]: {
        label: 'Please describe your hobbies.',
        placeholder: 'Describe your hobbies...',
        isRequired: true
      },

      // Other
      // 11: {
      [`Other`]: {
        label: 'Please describe your experience.',
        placeholder: 'Describe your experience...',
        isRequired: true
      }
    }
  };

  const typePrompt = prompts[tag.data.type] || {};
  const tagPrompt = typePrompt[tag.data.name] || {};
  return tagPrompt;
};

const typePrompts = function(tag) {
  return {
    [TagTypes.Experience]: {
      subtitle: 'Life experiences and interests'
    },
    [TagTypes.Subject]: {
      subtitle: 'Subjects',
      label: 'What do you teach within this subject area?',
      icon: BookOpenIcon
    },
  }[tag.data.type] || {};
}

export default {
  prompts: function(tag) {
    const _prompts = _.defaults(
      tagPrompts(tag),
      typePrompts(tag),
      {
        title: tag.data.name,
        subtitle: '',
        label: 'Would you like to tell us more?',
        placeholder: 'Describe your experience...',
        isRequired: false
      }
    );

    return _prompts;
  },

  isRequired: function(tag) {
    const _prompts = _.defaults(
      tagPrompts(tag),
      typePrompts(tag),
      {
        isRequired: false
      }
    );

    return _prompts.isRequired;
  }
}
