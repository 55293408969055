import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import validators from '../../utils/validators';
import Overlay from '../../forms/_common/Overlay';
import FormErrors from '../../forms/_common/FormErrors';
import ErrorMessage from '../../forms/_common/ErrorMessage';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import Form from './Form';
import usePageRouter from '../../dialogs-routable/_hooks/usePageRouter';
import ProjectCloseDialog from '../project.close';

ProjectCloseExtendDialog.propTypes = {
  project: PropTypes.object.isRequired
};

export default function ProjectCloseExtendDialog(props) {
  const { project } = props;

  const { history } = usePageRouter();

  const [showProjectCloseDialog, setShowProjectCloseDialog] = useState(false);

  const [data, setData] = useState({
    action: ''
  });
  const [showErrors, setShowErrors] = useState(false);

  function onSubmit() {
    if (data.action === 'extend') {
      props.onCancel();
      history.push(`/post-project/${project.id}/date`);
    }

    if (data.action === 'close') {
      setShowProjectCloseDialog(true);
    }
  }

  function onChange(name, value) {
    const nextData = _.merge({}, data);
    nextData[name] = value;
    setData(nextData);
  }

  if (showProjectCloseDialog) {
    return (
      <ProjectCloseDialog {...props} />
    );
  }

  return (
    <DialogTemplate className="w-screen max-w-screen-md">
      <Overlay isVisible={false}>
        <FormErrors data={data} validators={{
          action: [validators.isRequired]
        }}>
          {({errors, hasError}) => {
            return (
              <Form
                data={data}
                errors={errors}
                showErrors={showErrors}
                onChange={onChange}
                onSubmit={() => hasError ? setShowErrors(true) : onSubmit()}
                onCancel={props.onCancel}
                alert={showErrors ? (
                  <ErrorMessage>
                    Please fill in all required fields.
                  </ErrorMessage>
                ) : null}
                project={project}
              />
            )
          }}
        </FormErrors>
      </Overlay>
    </DialogTemplate>
  );
};
