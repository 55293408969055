export default {
    INTRODUCTION: '/welcome/start',
    NAME:  '/welcome/name',
    ORGANIZATION:  '/welcome/organization',
    ENGAGEMENT_TYPES: '/welcome/engagement-types',
    EXPERTISE_AREAS: '/welcome/expertise-areas',
    EXPERIENCE_AREAS: '/welcome/experience',
    GUIDANCE_AREAS: '/welcome/guidance',
    INTEREST_AREAS: '/welcome/interests',
    ZIP_CODE: '/welcome/zip-code',
    SOCIAL: '/welcome/social',
    GRADE_LEVELS:  '/welcome/grade-levels',
    AGES:  '/welcome/ages',
    SUBJECT_AREAS:  '/welcome/subject-areas',
    TEACHING_PRACTICES:  '/welcome/teaching-practices',
    BIOGRAPHY:  '/welcome/biography',
    GOALS:  '/welcome/goals',
    AVATAR: '/welcome/avatar',
    PHONE: '/welcome/phone',
    DEMOGRAPHICS: '/welcome/demographics',
    CONFIRMATION: '/welcome/end',
    DASHBOARD: '/'
};
