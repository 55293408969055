import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import ActionBar from './ActionBar';
import Offer from '../../pages/offer/Offer';
import OfferEditLayout from '../../pages/offer-edit/Dialog';
import OfferManageLayout from '../../pages/offer-manage/Dialog';
import OfferClassifyLayout from '../../pages/offer-classify/Dialog';
import OfferInvitePopulateLayout from '../../pages/offer-invite-populate/Dialog';
import OfferInviteLayout from '../../pages/offer-invite/Dialog';
import useDialogConfig from '../_hooks/useDialogConfig';
import useDialogRouter from '../_hooks/useDialogRouter';
import MissingFields from '../../pages/offer-manage/MissingFields';
import Banner from './Banner';

export default function OfferManageDialogLayout(props) {
  const { offer } = props;

  const { id, route } = useDialogConfig();
  const router = useDialogRouter(id);

  useEffect(() => {
    if (route === '/') {
      router.navigate('/manage');
    }
  }, []);

  if (offer.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  const pageMap = {
    '/manage': 'Manage',
    '/view': 'View',
    '/edit': 'Edit',
    '/invite-populate': 'Invite',
    '/invite': 'Invite',
    '/classify': 'Classify'
  };

  const pathname = route.split('?')[0];

  // const page = pageMap[route] || route;
  const page = pageMap[pathname] || pathname;

  return (
    <div className="space-y-6">
      <Helmet>
        <title>
          {offer.data.title ? `${offer.data.title}: ${page}` : `Offer #${offer.id}: ${page}`}
        </title>
      </Helmet>
      <Banner offer={offer} />
      <ActionBar offer={offer} />
      <div className="space-y-12">
        {pathname === `/view` && (
          <Offer offer={offer} />
        )}
        {pathname === `/edit` && (
          <>
            <MissingFields offer={offer} />
            <OfferEditLayout
              offer={offer}
              onClose={props.onCancel}
            />
          </>
        )}
        {pathname === `/invite-populate` && (
          <OfferInvitePopulateLayout
            offer={offer}
            onClose={props.onCancel}
          />
        )}
        {pathname === `/invite` && (
          <OfferInviteLayout
            offer={offer}
            router={router}
            onClose={props.onCancel}
          />
        )}
        {pathname === `/manage` && (
          <OfferManageLayout
            offer={offer}
            onClose={props.onCancel}
          />
        )}
        {pathname === `/classify` && (
          <OfferClassifyLayout
            offer={offer}
            onClose={props.onCancel}
          />
        )}
      </div>
    </div>
  );
};
