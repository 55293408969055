import Text from './Text';
import { TextColor, TextWeight, TextSize, getSize, getColor, getWeight } from './utils';

export default function Sm(props) {
  return (
    <Text
      {...props}
      size={TextSize.Sm}
    />
  );
}
