import React from 'react';
import { useConnect } from '@lore/query-connect';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import IconSection from '../../_new_card_components/IconSection';

export default function Creator(props) {
  const { project } = props;

  const creator = useConnect('user.byId', {
    id: project.data.creatorId
  });

  return (
    <IconSection
      icon={UserCircleIcon}
      title={`${creator.data.firstName} ${creator.data.lastName}`}
    />
  );
}
