import React from 'react';
import { PayloadStates } from '@lore/utils';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader';
import classNames from 'classnames';
import Invitations from './Invitations';
import { EmptyStateActions } from '../../components/EmptyState';
import EmptyState from '../../components/EmptyState/EmptyState.legacy';
import { PlusCircleIcon } from '@heroicons/react/24/solid';
import { LinkButton, OutlineButton } from '../../components/Button';
import SectionTitle from '../project-manage/SectionTitle';
import useDialogLauncher from '../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../dialogs_new/DialogManager';

export default function Layout(props) {
  const { event } = props;

  const launch = useDialogLauncher();

  function onClick(user) {
    launch(DialogManager.Messages.Config(user.id));
  }

  if (event.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <div className="flex-1 space-y-12">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
        <Invitations
          event={event}
          title="Yes"
          header={event.data.slots ? function(length) {
            const percent = Math.round(length / event.data.slots * 100);
            return (
              <div className="p-4 mb-2">
                <div className="text-cs-gray-500 mb-1">
                  <span className="text-cs-gray-900 text-lg font-semibold">{length} Accepted</span> / {event.data.slots} Partners Needed
                </div>
                <div className="relative">
                  <div className="absolute z-10 bg-cs-orange-500 rounded h-4" style={{ width: `${percent}%` }}/>
                  <div className="absolute z-1 bg-cs-orange-50 rounded h-4 w-full" />
                </div>
              </div>
            );
          } : null}
          footer={(
            <div className="p-2">
              <Link className={classNames(
                'bg-cs-orange-50 text-cs-orange-500 py-4 w-full border border-cs-orange-500 border-dashed rounded-lg hover:bg-cs-orange-100 cursor-pointer',
                'flex items-center justify-center space-x-2'
              )} to={`/event/${event.id}/invite-populate`}>
                <PlusCircleIcon className="h-6 w-6"/>
                <span>
                  Invite more partners
                </span>
              </Link>
            </div>
          )}
          responses={[
            'Yes'
          ]}
          emptyState={(
            <EmptyState
              title="No partners have expressed interest yet."
              subtitle="The more people you invite, the better your chances of finding a match!"
            >
              <EmptyStateActions>
                <LinkButton
                  button={OutlineButton}
                  to={`/event/${event.id}/invite-populate`}
                  label="Find Partners"
                />
              </EmptyStateActions>
            </EmptyState>
          )}
          onViewMessages={onClick}
        />
        <Invitations
          event={event}
          title="Maybe"
          responses={[
            'Maybe'
          ]}
          emptyState={(
            <EmptyState
              title="You haven't invited anybody yet."
              subtitle="Explore your community and find some partners to invite to your Event."
            >
              <EmptyStateActions>
                <LinkButton
                  button={OutlineButton}
                  to={`/event/${event.id}/invite-populate`}
                  label="Find Partners"
                />
              </EmptyStateActions>
            </EmptyState>
          )}
        />
        <Invitations
          event={event}
          title="Pending"
          responses={[
            'Pending'
          ]}
          emptyState={(
            <EmptyState
              title="You haven't invited anybody yet."
              subtitle="Explore your community and find some partners to invite to your Event."
            >
              <EmptyStateActions>
                <LinkButton
                  button={OutlineButton}
                  to={`/event/${event.id}/invite-populate`}
                  label="Find Partners"
                />
              </EmptyStateActions>
            </EmptyState>
          )}
          onViewMessages={onClick}
        />
      </div>
      <SectionTitle
        title="Declined"
        subtitle="Partners who are either uninterested or unable to participate."
      >
        <div className="grid grid-cols-1 gap-4">
          <Invitations
            event={event}
            title="No"
            responses={[
              'No'
            ]}
            emptyState={(
              <EmptyState
                title="Partners who turn down your invitation will appear here."
                // subtitle="Partners who are unable to participate will show up here."
              />
            )}
            onViewMessages={onClick}
          />
        </div>
      </SectionTitle>
    </div>
  );
};
