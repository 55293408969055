import React from 'react';
import PropTypes from 'prop-types';
import Avatar from './Avatar';
import { PencilIcon } from '@heroicons/react/24/solid';

UserAvatar.propTypes = {
  user: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  size: PropTypes.number
};

export default function UserAvatar(props) {
  const { user, size, onChange, className, avatarClassName } = props;

  return (
    <div className={`relative inline-block ${onChange ? 'cursor-pointer' : ''} ${className}`} onClick={onChange}>
      <Avatar size={size} className={avatarClassName} src={user.data.avatarUrl} />
      {onChange && (
        <div className="absolute top-3 right-3 bg-cs-orange-500 rounded-full h-8 w-8 flex items-center justify-center">
          <PencilIcon className="text-white h-5 w-5" />
        </div>
      )}
    </div>
  );
};
