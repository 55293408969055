import React from 'react';
import RenderOnlyWhenInViewport from './RenderOnlyWhenInViewport';
import Card from './Card';

export default function LazyCard(props) {
  return (
    <RenderOnlyWhenInViewport>
      <Card {...props} />
    </RenderOnlyWhenInViewport>
  );
};
