import { extractArrayFromQuery, mapAttributes } from '../../../utils/query-utils';

import networks from './networks';
import activities from './activities';
import name from './name';
import school from './school';
import title from './title';
import locationCondition from './locationCondition';

function tag() {
  return {
    param: extractArrayFromQuery,
    query: function(values) {
      if (values.length > 0) {
        return {
          $or: [
            {
              'project.tagNodes.tagId': {
                $in: values
              }
            },
            {
              'event.tagNodes.tagId': {
                $in: values
              }
            }
          ]
        }
      }
    }
  };
}

function tagTree() {
  return {
    param: extractArrayFromQuery,
    query: function(values) {
      if (values.length > 0) {
        return {
          $or: [
            {
              'project.tagTrees.parentId': {
                $in: values
              }
            },
            {
              'event.tagTrees.parentId': {
                $in: values
              }
            }
          ]
        }
      }
    }
  };
}

const attributes = {
  networks,
  activities,
  ages: tag(),
  commitments: tag(),
  days: tag(),
  expertiseTypes: tag(),
  groups: tag(),
  guidances: tag(),
  interactionTypes: tag(),
  locationCondition,
  name,
  participants: tag(),
  school,
  subjects: tag(),
  times: tag(),
  title
};

export const attributeKeys = _.keys(attributes);

export default mapAttributes(attributes);
