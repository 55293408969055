import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import Card from '../../../components/Card';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import DividerCard from '../../../components/DividerCard';
import ComposableInvitationHeader from '../../../components_cards/ComposableInvitationHeader';
import GrayOutlineButton from '../../../components/Button/OutlineButton.gray';
import { SolidButton } from '../../../components/Button';
import EventTag from '../../../components_cards/tags/EventTag';
import TagRow from '../../../components_cards/tags/TagRow';
import Creator from './Creator';
import Meta from '../../../components_cards/ComposableInvitationHeader/Meta';

export default function Invitation(props) {
  const { invitation } = props;

  const launch = useDialogLauncher();

  const recipient = useConnect('user.byId', {
    id: invitation.data.userId
  });

  const creator = useConnect('user.byId', {
    id: invitation.data.creatorId
  });

  const event = useConnect('event.byId', {
    id: invitation.data.eventId
  });

  const eventCreator = useConnect('user.byId', {
    id: event.data.creatorId
  }, {
    enabled: !!event.data.creatorId
  });

  if (
    recipient.state === PayloadStates.FETCHING ||
    event.state === PayloadStates.FETCHING ||
    creator.state === PayloadStates.FETCHING ||
    eventCreator.state === PayloadStates.FETCHING
  ) {
    return (
      <Card>
        <Loader />
      </Card>
    );
  }

  function onViewEvent() {
    launch(DialogManager.InvitationView.Config(invitation.id));
  }

  return (
    <DividerCard className="overflow-hidden -m-px border border-cs-orange-500" border={false}>
      <ComposableInvitationHeader
        invitation={invitation}
        creator={creator}
        tags={(
          <TagRow>
            <EventTag />
          </TagRow>
        )}
        showTags={false}
        showCreator={false}
      />
      <div className="w-full bg-cs-gray-50 py-4 px-4 flex justify-between items-center">
        <Creator event={event} />
        <div className="flex items-center space-x-2">
          {/*<GrayOutlineButton*/}
          {/*  onClick={onViewEvent}*/}
          {/*  label="View event"*/}
          {/*/>*/}
          <SolidButton
            onClick={onViewEvent}
            label="Check it out!"
          />
          {/*<div className="flex flex-col items-end space-y-2">*/}
          {/*  <SolidButton*/}
          {/*    onClick={onViewEvent}*/}
          {/*    label="Check it out!"*/}
          {/*  />*/}
          {/*  <Meta invitation={invitation} />*/}
          {/*</div>*/}
        </div>
      </div>
    </DividerCard>
  );
};
