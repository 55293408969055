import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useUser } from '@lore/auth';
import Loader from '../../components/Loader';
import ActionBar from './ActionBar';
import ProjectEditLayout from '../../pages/project-edit/Dialog';
import ProjectManageLayout from '../../pages/project-manage/Dialog';
import useDialogConfig from '../_hooks/useDialogConfig';
import useDialogRouter from '../_hooks/useDialogRouter';
import MissingFields from '../../pages/project-manage/MissingFields';
import Banner from './Banner';
import User from '../../pages/user/User';
import EditLayout from '../../pages/profile-edit/DialogLayout';
import SettingsLayout from '../../pages/profile-settings/DialogLayout';

export default function ProfileManageDialogLayout(props) {
  const { project } = props;

  const { id, route } = useDialogConfig();
  const router = useDialogRouter(id);

  useEffect(() => {
    if (route === '/') {
      router.navigate('/view');
    }
  }, []);

  // if (project.state === PayloadStates.FETCHING) {
  //   return (
  //     <Loader />
  //   );
  // }

  const pageMap = {
    '/view': 'View',
    '/edit': 'Edit',
    '/settings': 'Settings'
  };

  const pathname = route.split('?')[0];

  // const page = pageMap[route] || route;
  const page = pageMap[pathname] || pathname;

  const currentUser = useUser();

  return (
    <div className="space-y-6">
      <Helmet>
        <title>
          {`Profile: ${page}`}
        </title>
      </Helmet>
      {/*<Banner project={project} />*/}
      <ActionBar />
      <div className="space-y-12">
        {pathname === `/view` && (
          <User user={currentUser} />
        )}
        {pathname === `/edit` && (
          <>
            {/*<MissingFields project={project} />*/}
            <EditLayout
              onClose={props.onCancel}
            />
          </>
        )}
        {pathname === `/settings` && (
          <SettingsLayout
            onClose={props.onCancel}
          />
        )}
      </div>
    </div>
  );
};
