import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useConnect } from '@lore/query-connect';
import { useUser } from '@lore/auth';
import validators from '../../utils/validators';
import Overlay from '../../forms/_common/Overlay';
import FormErrors from '../../forms/_common/FormErrors';
import RequestError from '../../forms/_common/RequestError';
import ErrorMessage from '../../forms/_common/ErrorMessage';
import SuccessMessage from '../../forms/_common/SuccessMessage';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import useProjectInterestEmailPreview from '../../utils/project/useProjectInterestEmailPreview';
import Form from './Form';
import { useQueryMutation } from '../../../hooks/@lore/query-connect';

InviteDialog.propTypes = {
  project: PropTypes.object.isRequired,
  creator: PropTypes.object.isRequired
};

export default function InviteDialog(props) {
  const { creator, project } = props;

  const activity = useConnect('tag.byId', {
    id: project.data.activityId
  });

  const currentUser = useUser();

  const [data, setData] = useState({
    customMessage: [
      // `Hi ${creator.data.firstName}!\n\n`,
      `I'm interested in participating in this project. Would you be interested in discussing further?`
    ].join('')
  });
  const [showErrors, setShowErrors] = useState(false);

  const emailPreview = useProjectInterestEmailPreview(project, creator, data.customMessage);

  const mutation = useQueryMutation('question.create');
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      props.onCancel();
      props.onSubmit && props.onSubmit(request);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate({
      userId: currentUser.id,
      projectId: project.id,
      request: 'Participation',
      requestMessage: `My students and I have mapped out an idea of how we'd like to engage with you. We've included the outline of what we're thinking below, but we're flexible based on your interests, expertise, and availability. I'd love to answer any questions you have or talk more about this possibility with you. Thank you for considering us!`,
      response: 'Connect',
      responseMessage: data.customMessage
    });
  }

  function onCompleteProfile() {
    // props.onCancel();
    props.onCompleteProfile && props.onCompleteProfile();
  }

  function onChange(name, value) {
    const nextData = _.merge({}, data);
    nextData[name] = value;
    setData(nextData);
  }

  onChange = onChange.bind(data);

  return (
    <DialogTemplate className="w-screen max-w-screen-sm">
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          customMessage: [validators.isRequired]
        }}>
          {({errors, hasError}) => (
            <Form
              data={data}
              errors={errors}
              showErrors={showErrors}
              onChange={onChange}
              onSubmit={() => hasError ? setShowErrors(true) : onSubmit()}
              onCancel={props.onCancel}
              alert={error ? (
                <RequestError request={request}/>
              ) : showErrors ? (
                <ErrorMessage>
                  Please fill in all required fields.
                </ErrorMessage>
              ) : (false && success) ? (
                <SuccessMessage>
                  Idea created.
                </SuccessMessage>
              ) : null}
              creator={creator}
              project={project}
              activity={activity}
              emailPreview={emailPreview}
            />
          )}
        </FormErrors>
      </Overlay>
    </DialogTemplate>
  );
};
