import React from 'react';
import { BuildingOfficeIcon } from '@heroicons/react/24/outline';
import { useConnect } from '@lore/query-connect';
import IconSection from '../../../_new_card_components/IconSection';
import { PayloadStates } from '@lore/utils';

export default function Organization(props) {
  const { offer } = props;

  // const organization = useConnect('organization.byId', {
  //   id: offer.data.organizationId
  // }, {
  //   enabled: !!offer.data.organizationId
  // });

  const organization = useConnect('organization.first', {
    where: {
      eager: {
        $where: {
          'jobs.userId': offer.data.creatorId
        }
      }
    }
  });

  if (organization.state === PayloadStates.FETCHING) {
    return (
      <IconSection
        icon={BuildingOfficeIcon}
        title="..."
        tooltip="Organization"
      />
    );
  }

  return (
    <IconSection
      icon={BuildingOfficeIcon}
      title={organization.data.name || '[no organization]'}
      tooltip="Organization"
    />
  );
};
