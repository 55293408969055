import React, { useState } from 'react';
import _ from 'lodash';
import { useUser } from '@lore/auth';
import Steps from './_common/Steps';
import Start from './Start';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Confirmation from './Confirmation';

export default function ProjectDuplicateDialog(props) {
  const { project, onCancel } = props;

  const user = useUser();

  const [data, setData] = useState({
    // old project data
    activity: null,
    tagNodes: null,
    uploads: null,

    // duplicated project data
    project: null,
    tags: null
  });
  const [key, setKey] = useState(0);
  const [stepIndex, setStepIndex] = useState(Steps.START);

  function onNext(newData, newStepIndex) {
    setStepIndex(newStepIndex || (stepIndex + 1));
    setData(_.extend({}, data, newData));
  }

  function onPrevious(newData, newStepIndex) {
    setStepIndex(newStepIndex || (stepIndex - 1));
    setData(_.extend({}, data, newData));
  }

  function onChangeStep(newData, newStepIndex) {
    setStepIndex(newStepIndex);
    setData(_.extend({}, data, newData));
  }

  const steps = [
    (props) => {
      return (
        <Start
          {...props}
          project={project}
          onNext={(newData) => onNext(newData, Steps.STEP1)}
          onCancel={onCancel}
        />
      );
    },
    (props) => {
      return (
        <Step1
          {...props}
          project={project}
          onNext={(newData) => onNext({
            project: newData
          }, Steps.STEP2)}
          onCancel={onCancel}
        />
      );
    },
    (props) => {
      return (
        <Step2
          {...props}
          onNext={(newData) => onNext({
            // guidances: newData
          }, Steps.STEP3)}
          onCancel={onCancel}
        />
      );
    },
    (props) => {
      return (
        <Step3
          {...props}
          onNext={(newData) => onNext({
            // guidances: newData
          }, Steps.CONFIRMATION)}
          onCancel={onCancel}
        />
      );
    },
    (props) => {
      return (
        <Confirmation
          {...props}
          onCancel={onCancel}
        />
      );
    }
  ];

  const step = steps[stepIndex]({
    user: user,
    data: data
  });

  return (
    <div key={key}>
      {step}
    </div>
  );
}
