import React, { useState } from 'react';
import { UsersIcon } from '@heroicons/react/24/outline';
import { PayloadStates } from '@lore/utils';
import useRouter from '../../hooks/useRouter';
import Urls from '../profile/Urls';
import DataCard from './DataCard';
import useUserTags from '../../hooks/useUserTags';
import TagTypes from '../../constants/TagTypes';
import useTags from '../../hooks/useTags';
import Experiences from '../setup/experience/Experiences';
import EditLink from './EditLink';

export default function ExperienceCards(props) {
  const { user } = props;

  const { history } = useRouter();

  const experiences = useTags(TagTypes.Experience);
  const userExperiences = useUserTags(user, TagTypes.Experience);

  let subtitle = '';

  if (userExperiences.state === PayloadStates.FETCHING) {
    subtitle = '...';
  } else {
    subtitle = userExperiences.data.map(function(userExperience, index) {
      return (
        <div key={index}>
          {userExperience._tag.data.name}
        </div>
      );
    });
  }

  if (
    experiences.state === PayloadStates.FETCHING ||
    userExperiences.state === PayloadStates.FETCHING ||
    userExperiences.data.length === 0
  ) {
    return (
      <DataCard
        title="Life Experience"
        icon={UsersIcon}
        subtitle={subtitle || '(not provided)'}
        onClick={() => history.push(Urls(user).EXPERIENCES)}
      />
    );
  }

  const filteredExperiences = {
    ...experiences,
    data: experiences.data.filter(function(experience) {
      return !!_.find(userExperiences.data, function(userExperience) {
        return userExperience._tag.id === experience.id;
      });
    })
  }

  return (
    <div className="space-y-4">
      <Experiences
        user={user}
        experiences={filteredExperiences}
        userExperiences={userExperiences}
        selectable={false}
      />
        <EditLink
          title="Change life experiences"
          onClick={() => history.push(Urls(user).EXPERIENCES)}
        />
    </div>
  );
};
