import React from 'react';

export default function Description(props) {
  const { claim } = props;

  if (!_.trim(claim.data.requestMessage)) {
    return null;
  }

  return (
    <div className="text-base text-cs-gray-500">
      "{claim.data.requestMessage}"
    </div>
  );
}
