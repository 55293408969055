import React from 'react';
import UserMediaSection from './UserMediaSection';
import StatusMediaSection from './StatusMediaSection';
import Card from '../../../components/Card';

export default function EventEvaluationUserStatusCard(props) {
  const { evaluation, showViewLink, showEditLink } = props;

  return (
    <Card>
      <div className="flex justify-between p-4">
        <UserMediaSection evaluation={evaluation} />
        <StatusMediaSection
          evaluation={evaluation}
          showViewLink={showViewLink}
          showEditLink={showEditLink}
        />
      </div>
    </Card>
  );
};
