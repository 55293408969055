import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

RadioButtonField.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  options: PropTypes.object.isRequired,
  label: PropTypes.node,
  optionLabel: PropTypes.string,
  placeholder: PropTypes.string,
  description: PropTypes.node,
  error: PropTypes.node,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

RadioButtonField.defaultProps = {
  options: {
    data: []
  },
  placeholder: '',
  disabled: false
};

export default function RadioButtonField(props) {
  const {
    name,
    value,
    options,
    label,
    optionLabel,
    placeholder,
    description,
    error,
    disabled,
    onChange,
    className
  } = props;

  const [groupId] = useState(Math.floor(Math.random()*100000));

  // <select
  //   className={`form-control custom-select ${error ? 'is-invalid' : ''}`}
  //   value={(value === null || value === undefined) ? '' : value}
  //   onChange={(event) => {
  //     const selectedOption = _.find(options.data, function(option) {
  //       return String(option.id) === event.target.value;
  //     });
  //     onChange(selectedOption ? selectedOption.id : null);
  //   }}
  //   style={{ width: '100%' }}
  //   disabled={disabled}
  // >
  //   {[<option key="" value="">{placeholder}</option>].concat(options.data.map((datum) => {
  //     return (
  //       <option key={datum.id || datum.cid} value={datum.id}>
  //         {_.isFunction(optionLabel) ? optionLabel(datum) : datum.data[optionLabel]}
  //       </option>
  //     );
  //   }))}
  // </select>

  return (
    <div className={`mb-8 ${className || undefined}`}>
      {label ? (
        <label className="block text-base text-cs-gray-900 mb-2">
          {label}
        </label>
      ): null}
      <div className="space-y-2">
        {options.data.map((datum, index) => {
          const groupName = `radio-${groupId}-${index}`;

          return (
            <div key={datum.id || datum.cid}>
              <label className="flex items-center">
                <input
                  type="radio"
                  className="focus:ring-cs-orange-500 h-6 w-6 text-cs-orange-500 border-cs-gray-200"
                  id={groupName}
                  name={groupName}
                  value={datum.id}
                  checked={value === datum.id}
                  onChange={(event) => {
                    const selectedOption = _.find(options.data, function(option) {
                      return String(option.id) === event.target.value;
                    });
                    onChange(selectedOption ? selectedOption.id : null);
                  }}
                />
                <label htmlFor={groupName} className="ml-3 block text-cs-gray-900">
                  {_.isFunction(optionLabel) ? optionLabel(datum) : datum.data[optionLabel]}
                </label>
              </label>
            </div>
          );
        })}
      </div>
      {error ? (
        <div className="invalid-feedback">
          {error}
        </div>
      ) : null}
      {description ? (
        <small className="form-text text-muted">
          {description}
        </small>
      ) : null}
    </div>
  );
}
