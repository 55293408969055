import React from 'react';
import PropTypes from 'prop-types';
import PostmarkMessageDeliveryStatus from './PostmarkMessageDeliveryStatus';
import SendgridMessageDeliveryStatus from './SendgridMessageDeliveryStatus';

MessageDeliveryStatus.propTypes = {
  message: PropTypes.object.isRequired
};

export default function MessageDeliveryStatus(props) {
  const { message, className } = props;

  if (message.data.postmarkMessageId) {
    return (
      <span className={className}>
        <PostmarkMessageDeliveryStatus message={message} />
      </span>
    );
  }

  if (message.data.sendgridMessageId) {
    return (
      <span className={className}>
        <SendgridMessageDeliveryStatus message={message} />
      </span>
    );
  }

  return (
    <span className={className} />
  );
};
