import React from 'react';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import ParentLayout from '../Layout';
import Navigation from '../../../dialogs_common/Navigation';
import Urls from '../Urls';
import Form from './Form';
import { PayloadStates } from '@lore/utils';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { location, history, match } = useRouter();
  const user = useUser();
  const project = useConnect('project.byId', {
    id: Number(match.params.projectId)
  });

  if (project.state === PayloadStates.FETCHING) {
    return null;
  }

  return (
    <ParentLayout
      project={project}
      page="Location"
      title="How would you and your students like to connect with partners? Does this project need to be completed in-person, virtually, or is it flexible?"
      // description="Please choose one or both."
    >
      <Form
        user={user}
        project={project}
        navigation={(
          <Navigation v5={true} activeStep={15} steps={19}/>
        )}
        onPrevious={(newData) => history.push(Urls(project).TIME)}
        onNext={(newData) => history.push(Urls(project).DOCUMENTS)}
        onSkip={() => history.push(Urls(project).DOCUMENTS)}
        onExit={() => history.push(Urls(project).PROJECT_EDIT)}
      />
    </ParentLayout>
  );
};
