import React from 'react';
import PropTypes from 'prop-types';
import FormField from '../../forms/_common/FormField';
import { OutlineButton, SolidButton } from '../../components/Button';
import FormTemplate from '../../dialogs_common/FormTemplate';
import RadioButtonField from '../../pages/_post-opportunity/_fields/RadioButtonField.cards';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  activityTypes: PropTypes.object,
  ages: PropTypes.object
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onCancel
  } = props;

  return (
    <FormTemplate
      title="Not a good fit?"
      subtitle="Sounds like this project isn't a good fit for you. Why not?"
      onClose={onCancel}
      alert={alert}
      body={(
        <>
          {/*<p className="m-modal__description">*/}
          {/*  Tell us your idea. You can always change things later.*/}
          {/*</p>*/}
          <FormField name="reason" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <RadioButtonField
                name="reason"
                // label="How did you connect?*"
                value={field.value}
                error={field.error}
                // placeholder="Select a role..."
                options={{
                  data: [
                    `I'm swamped right now, ask me later.`,
                    `The specified days/times don't fit my schedule.`,
                    `The time needed to complete is more than I can give right now.`,
                    `I don't have the needed skills or experience for this project.`,
                    `This is too far away from my home/work.`,
                    `I'm not interested in volunteering my time in this way.`
                    // `The educator stopped communicating in a timely fashion.`,
                  ].map((option) => {
                    return {
                      id: option,
                      data: {
                        name: option
                      }
                    }
                  })
                }}
                optionLabel="name"
                onChange={function(value) {
                  field.onChange(value);
                }}
              />
            )}
          </FormField>
        </>
      )}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onCancel}>
            Cancel
          </OutlineButton>
          <SolidButton onClick={onSubmit}>
            Decline
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
