import React, { useEffect, useRef, useState } from 'react';
import Navigation from '../../pages/Navigation';
import classNames from 'classnames';
import { Bars3Icon } from '@heroicons/react/24/outline';
import CloseDialogButton from '../../dialogs_common/CloseDialogButton';

export default function Sidebar(props) {
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const node = useRef();

  useEffect(() => {
    if (sidebarVisible) {
      document.addEventListener("mousedown", onClickOutside);
    } else {
      document.removeEventListener("mousedown", onClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", onClickOutside);
    };
  }, [sidebarVisible]);

  function onShowSidebar() {
    setSidebarVisible(true);
  }

  function onHideSidebar() {
    setSidebarVisible(false);
  }

  function onClickOutside(e) {
    if (node.current.contains(e.target)) {
      return;
    }

    if (sidebarVisible) {
      onHideSidebar();
    }
  }

  return (
    <div ref={node}>
      <button className="text-cs-orange-500 border-cs-orange-500 border rounded" onClick={onShowSidebar}>
        <Bars3Icon className="h-9 w-9 p-1" />
      </button>
      <div className={classNames(
        `cs-sidenav fixed z-30 bg-white h-full top-0 shadow-2xl overflow-auto transition-500 transition-position`,
        'pt-6 pb-4',
        sidebarVisible && 'right-0'
      )}>
        <div className="relative -top-2 right-2">
          <CloseDialogButton onCancel={onHideSidebar} />
        </div>
        <div className="flex flex-col flex-1">
          <Navigation />
        </div>
      </div>
    </div>
  );
}
