import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Overlay from '../../forms/_common/Overlay';
import FormErrors from '../../forms/_common/FormErrors';
import RequestError from '../../forms/_common/RequestError';
import ErrorMessage from '../../forms/_common/ErrorMessage';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import FormTemplate from './FormTemplate';
import FormField from '../../forms/_common/FormField';
import StringField from '../../forms/_fields_v2/StringField';
import SolidButton from '../../components/Button/SolidButton';
import { useQueryMutation } from '../../../hooks/@lore/query-connect';

Name.propTypes = {
  user: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired
};

export default function Name(props) {
  const {
    user,
    navigation,
    onNext,
    onPrevious,
    onSkip
  } = props;

  const [data, setData] = useState(_.pick(user.data, [
    'firstName',
    'lastName',
  ]));
  const [showErrors, setShowErrors] = useState(false);

  const mutation = useQueryMutation('currentUser.updateSingleton', user);
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      onNext(data);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate(data);
  }

  function onChange(name, value) {
    setData(_.merge({}, data, {
      [name]: value
    }));
  }

  return (
    <DialogTemplate className="w-screen max-w-lg">
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          // firstName: [validators.isRequired],
          // lastName: [validators.isRequired]
        }}>
          {({ errors, hasError }) => {
            return (
              <FormTemplate
                onPrevious={onPrevious}
                navigation={navigation}
                title="Hi! What's your name?"
                // subtitle="Locations you create are visible only to you"
                alert={error ? (
                  <RequestError request={request} />
                ) : showErrors ? (
                  <ErrorMessage>
                    Please fill in all required fields.
                  </ErrorMessage>
                ) : null}
                body={(
                  <div className="grid grid-cols-1 gap-6">
                    <FormField name="firstName" data={data} errors={showErrors && errors} onChange={onChange}>
                      {(field) => (
                        <StringField
                          label="First name"
                          value={field.value}
                          error={field.error}
                          onChange={field.onChange}
                        />
                      )}
                    </FormField>
                    <FormField name="lastName" data={data} errors={showErrors && errors} onChange={onChange}>
                      {(field) => (
                        <StringField
                          label="Last name"
                          value={field.value}
                          error={field.error}
                          onChange={field.onChange}
                        />
                      )}
                    </FormField>
                  </div>
                )}
                footer={(
                  <div className="flex items-center justify-end space-x-12">
                    <div className="text-cs-orange-500 underline cursor-pointer" onClick={onSkip}>
                      Skip this step
                    </div>
                    <SolidButton onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
                      Next
                    </SolidButton>
                  </div>
                )}
              />
            );
          }}
        </FormErrors>
      </Overlay>
    </DialogTemplate>
  );
};
