import React from 'react';
import { Link } from 'react-router-dom';
import useRouter from '../hooks/useRouter';
import classNames from 'classnames';

export default function NavigationLink(props) {
  // const { item } = props;

  const {
    name,
    icon,
    // onClick,
    to,
    count,
    highlight = false,
    isActive = false
  } = props;

  // const { location } = useRouter();

  // const currentRoute = location.pathname;

  // function isActive(currentRoute, matches) {
  //   let isActive = false;
  //
  //   matches.forEach(function(match) {
  //     if (isActive) return;
  //
  //     if (currentRoute.split('/')[1] === match) {
  //       isActive = true;
  //     }
  //   });
  //
  //   return isActive;
  // }
  //
  // const current = isActive(currentRoute, item.matches);

  return (
    <div className={classNames(
      // current && '-mx-4'
      '-mx-4'
    )}>
      <Link
        to={to}
        className={classNames(
          isActive ?
            // 'bg-cs-orange-500 bg-opacity-10 text-cs-orange-500' :
            'bg-cs-orange-100 text-cs-orange-500' :
            'bg-white text-cs-gray-500 hover:bg-cs-gray-50 hover:text-cs-gray-500 font-normal',
          'group flex items-center px-4 py-2.5 rounded-cs-10 text-base'
        )}
      >
        <props.icon
          className={classNames(
            isActive ? 'text-cs-gray-500x text-cs-orange-500' : 'text-cs-gray-500 group-hover:text-cs-gray-500',
            'mr-4 h-6 w-6'
          )}
          aria-hidden="true"
        />
        {name}
        {(count && !highlight) ? (
          <span
            className={classNames(
              // isActive ? 'bg-gray-50' : 'bg-gray-200 text-gray-600',
              isActive ? 'bg-white' : 'text-cs-gray-500',
              'ml-auto inline-block py-0.5 px-2 text-sm rounded-full'
            )}
          >
            {count}
          </span>
        ) : null}
        {(count && highlight) ? (
          <span
            className={classNames(
              isActive ? 'bg-white' : 'bg-cs-orange-500 text-white',
              'ml-auto inline-block py-0.5 px-2 text-sm rounded-full'
            )}
          >
            {count}
          </span>
        ) : null}
      </Link>
    </div>
  );
}
