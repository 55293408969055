import React from 'react';
import PropTypes from 'prop-types';
import ConnectButton from '../../../pages/_banner/ConnectButton';
import Wrapper from '../../../pages/_banner/Wrapper';
import usePageRouter from '../../_hooks/usePageRouter';

CompletedBanner.propTypes = {
  event: PropTypes.object.isRequired
};

export default function CompletedBanner(props) {
  const { event, creator } = props;

  const { history } = usePageRouter();
  const color = 'green';

  return (
    <Wrapper
      color={color}
      creator={creator}
      title={(
        <>
          This event is complete.
        </>
      )}
      subtitle={(
        <>
          Good news - there are many other opportunities to collaborate and make a
          difference! Discover what's next!
        </>
      )}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        <ConnectButton color={color} onClick={() => history.push(`/opportunities`)}>
          Explore opportunities
        </ConnectButton>
      </div>
    </Wrapper>
  );
};
