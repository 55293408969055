import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import LoadingBanner from '../../../pages/_banner/LoadingBanner';
import ClaimBanner from '../../claim_manage/Banner';
import CreatorBanner from './CreatorBanner';
import DiscoverBanner from './DiscoverBanner';
import CancelledBanner from './CancelledBanner';
import CompletedBanner from './CompletedBanner';

Banner.propTypes = {
  offer: PropTypes.object.isRequired
};

export default function Banner(props) {
  const { offer } = props;

  const currentUser = useUser();

  const creator = useConnect('user.byId', {
    id: offer.data.creatorId
  });

  const activity = useConnect('tag.byId', {
    id: offer.data.activityId
  });

  const claim = useConnect('claim.first', {
    where: {
      eager: {
        $where: {
          offerId: offer.id,
          userId: currentUser.id
        }
      }
    }
  });

  if (
    creator.state === PayloadStates.FETCHING ||
    activity.state === PayloadStates.FETCHING ||
    claim.state === PayloadStates.FETCHING
  ) {
    return (
      <LoadingBanner creator={creator} />
    );
  }

  const isCreator = currentUser.id === offer.data.creatorId;

  // return (
  //   <>
  //     <CreatorBanner
  //       offer={offer}
  //       creator={creator}
  //     />
  //     <DiscoverBanner
  //       offer={offer}
  //       creator={creator}
  //     />
  //     <ClaimBanner
  //       claim={claim}
  //       offer={offer}
  //       creator={creator}
  //     />
  //     <CancelledBanner
  //       offer={offer}
  //       creator={creator}
  //     />
  //     <CompletedBanner
  //       offer={offer}
  //       creator={creator}
  //     />
  //   </>
  // );

  if (isCreator) {
    return (
      <CreatorBanner
        offer={offer}
        creator={creator}
      />
    );
  }

  if (claim.state === PayloadStates.RESOLVED) {
    return (
      <ClaimBanner
        claim={claim}
        offer={offer}
      />
    );
  }

  if (offer.data.completed) {
    return (
      <CompletedBanner
        offer={offer}
        creator={creator}
      />
    );
  }

  if (offer.data.cancelled) {
    return (
      <CancelledBanner
        offer={offer}
        creator={creator}
      />
    );
  }

  return (
    <DiscoverBanner
      offer={offer}
      creator={creator}
    />
  );
}
