import React from 'react';
import PropTypes from 'prop-types';
import ProjectBanner from './ProjectBanner';
import EventBanner from './EventBanner';
import OfferBanner from './OfferBanner';
import RedirectBanner from './RedirectBanner';

Banner.propTypes = {
  user: PropTypes.object.isRequired
};

export default function Banner(props) {
  const {
    user,
    project,
    event,
    offer,
    onClose
  } = props;

  if (project) {
    return (
      <ProjectBanner
        user={user}
        project={project}
        onClose={onClose}
      />
    );
  }

  if (event) {
    return (
      <EventBanner
        user={user}
        event={event}
        onClose={onClose}
      />
    );
  }

  if (offer) {
    return (
      <OfferBanner
        user={user}
        offer={offer}
        onClose={onClose}
      />
    );
  }

  return (
    <RedirectBanner
      user={user}
      onClose={onClose}
    />
  );
}
