import React from 'react';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import ParentLayout from '../Layout';
import Navigation from '../../../dialogs_common/Navigation';
import Urls from '../Urls';
import Form from './Form';
import { PayloadStates } from '@lore/utils';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { location, history, match } = useRouter();
  const user = useUser();
  const offer = useConnect('offer.byId', {
    id: Number(match.params.offerId)
  });

  if (offer.state === PayloadStates.FETCHING) {
    return null;
  }

  return (
    <ParentLayout
      offer={offer}
      page="Documents"
      title="Would you like to upload any documents that would further clarify your offer to teachers or students?"
      examples={(
        <>
          <div className="font-semibold text-cs-gray-900 text-base">
            Examples
          </div>
          <ul className="list-disc ml-6 text-cs-gray-900 text-base">
            {[
              'Informational flyers',
              'Formal application',
              'Photo from a previous year',
              'Student questions',
              'Etc.'
            ].map(function(text, index) {
              return (
                <li key={index}>
                  {text}
                </li>
              );
            })}
          </ul>
        </>
      )}
    >
      <Form
        user={user}
        offer={offer}
        navigation={(
          <Navigation v5={true} activeStep={15} steps={16}/>
        )}
        onPrevious={(newData) => history.push(Urls(offer).COST)}
        onNext={(newData) => history.push(Urls(offer).WEBSITE)}
        onSkip={() => history.push(Urls(offer).WEBSITE)}
        onExit={() => history.push(Urls(offer).OFFER_EDIT)}
      />
    </ParentLayout>
  );
};
