import _ from 'lodash';
import { PayloadStates } from '@lore/utils';
import useTags from '../../hooks/useTags';
import TagTypes from '../../constants/TagTypes';
import useOfferTags from '../../hooks/useOfferTags';

function isMissing(name, missingFields) {
  return _.find(missingFields, function(missingField) {
    return missingField.key === name;
  });
}

export default function useMissingFields(offer) {
  const missingFields = [];

  const participants = useTags(TagTypes.Participant);
  const offerParticipants = useOfferTags(offer, TagTypes.Participant);
  const interactionTypes = useTags(TagTypes.InteractionType);
  const offerInteractionTypes = useOfferTags(offer, TagTypes.InteractionType);

  if (
    participants.state === PayloadStates.FETCHING ||
    offerParticipants.state === PayloadStates.FETCHING ||
    interactionTypes.state === PayloadStates.FETCHING ||
    offerInteractionTypes.state === PayloadStates.FETCHING
  ) {
    return {
      missingFields: {
        state: PayloadStates.FETCHING,
        data: missingFields
      },
      isMissing: isMissing
    };
  }

  if (!offer.data.activityId) {
    missingFields.push({
      key: 'activityId',
      name: 'Activity',
      to: `/post-offer/${offer.id}/activity`
    });
  }

  if (offerParticipants.data.length === 0) {
    missingFields.push({
      key: 'offerParticipants',
      name: 'Participants',
      to: `/post-offer/${offer.id}/participants`
    });
  }

  if (!offer.data.description) {
    missingFields.push({
      key: 'description',
      name: 'Description',
      to: `/post-offer/${offer.id}/description`
    });
  }

  if (
    offer.data.specificDate === 'Yes' &&
    !offer.data.date
  ) {
    missingFields.push({
      key: 'date',
      name: 'Date or Date Range',
      to: `/post-offer/${offer.id}/date`
    });
  }

  if (
    offer.data.specificDate === 'No' && (
      !offer.data.startDate ||
      !offer.data.endDate
    )
  ) {
    missingFields.push({
      key: 'date',
      name: 'Date or Date Range',
      to: `/post-offer/${offer.id}/date`
    });
  }

  if (offerInteractionTypes.data.length === 0) {
    missingFields.push({
      key: 'offerInteractionTypes',
      name: 'Location',
      to: `/post-offer/${offer.id}/location`
    });
  }

  return {
    missingFields: {
      state: PayloadStates.RESOLVED,
      data: missingFields
    },
    isMissing: function(name) {
      return isMissing(name, missingFields)
    }
  };
};
