import React from 'react';
import PropTypes from 'prop-types';

LabelDivider.propTypes = {
  label: PropTypes.node
};

export default function LabelDivider(props) {
  const { label } = props;

  return (
    <div className="w-full flex py-1 items-center">
      <div className="text-sm font-medium text-cs-gray-400 mr-1">
        {label}
      </div>
      <div className="h-0 border-t border-cs-gray-200 my-2 w-full" />
    </div>
  );
}

