import React from 'react';
import { PayloadStates } from '@lore/utils';
import useRouter from '../../hooks/useRouter';
import Loader from '../../components/Loader';
import EmptyState from '../../components/EmptyState';
import classNames from 'classnames';
import Story from './Story';
import { ClipboardDocumentListIcon } from '@heroicons/react/24/outline';

export default function Results(props) {
  const { result } = props;

  const { location, history } = useRouter();

  if (result.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  if (result.data.length === 0) {
    return (
      <EmptyState
        icon={ClipboardDocumentListIcon}
        title="Can't find what you're looking for?"
        subtitle={(
          <div className="max-w-screen-sm m-auto space-y-6">
            <div>
              Don't worry - there might be more stories here.
            </div>
            <div className="mt-1 text-cs-gray-500 space-x-1">
              <span>Remove a filter, or start over and</span>
              <span
                className="text-cs-orange-500 underline cursor-pointer"
                onClick={() => history.push(location.pathname)}
              >
                clear all filters
              </span>.
            </div>
          </div>
        )}
      />
    );
  }

  return (
    <div className={classNames(
      result._isPreviousData && 'opacity-50',
      'grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-6'
    )}>
      {result.data.map(function(story, index) {
        return (
          <Story
            key={index}
            story={story}
          />
        );
      })}
    </div>
  );
}
