import React from 'react';
import PropTypes from 'prop-types';
import DialogTemplate from '../../../dialogs_common/DialogTemplate';
import SolidButton from '../../../components/Button/SolidButton';
import FormTemplate from '../../../dialogs_common/FormTemplate';
import usePageRouter from '../../../dialogs-routable/_hooks/usePageRouter';

Confirmation.propTypes = {
  onCancel: PropTypes.func.isRequired
};

export default function Confirmation(props) {
  const { onCancel } = props;

  const { history } = usePageRouter();

  return (
    <DialogTemplate>
      <FormTemplate
        title="Thank you! We've received your request and will be in touch soon."
        subtitle="In the meantime, check out these opportunities to collaborate near you!"
        footer={(
          <div className="flex justify-center w-full">
            <SolidButton onClick={() => history.push(`/projects-events-populate`)}>
              Explore
            </SolidButton>
          </div>
        )}
      />
    </DialogTemplate>
  );
};
