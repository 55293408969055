import React from 'react';
import { useUser } from '@lore/auth';
import { Helmet } from 'react-helmet-async';
import BrowseLeading from './BrowseLeading';
import BrowseParticipating from './BrowseParticipating';
import PageHeader from '../people-people/PageHeader';
import Card from '../../components/Card';
import { FlagIcon, NewspaperIcon, UsersIcon } from '@heroicons/react/24/outline';
import Option from '../my-network/Option';
import useActiveInvitationsCount from '../my-participating/useActiveInvitationsCount';
import useActiveOpportunityCount from '../my-expired/useActiveOpportunityCount';
import useDraftOpportunityCount from '../my-expired/useDraftOpportunityCount';
import Divider from '../../forms/_fields_v2/Divider';
import OptionCard from '../my-network/OptionCard';
import Images from '../../constants/Images';

function Header(props) {
  const { children } = props;

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Create and Connect</title>
      </Helmet>
      <PageHeader
        title="Create and Connect"
        subtitle={(
          <>
            Create, view, and manage the projects, events, and offers you're leading,
            including any drafts. Explore activities you're contributing to, created by others.
          </>
        )}
      />
      <div className="space-y-8">
        {children}
      </div>
    </div>
  );
}

export default function Layout(props) {
  const currentUser = useUser();

  const activeInvitationsCount = useActiveInvitationsCount() || 0;
  const activeOpportunityCount = useActiveOpportunityCount() || 0;
  const draftOpportunityCount = useDraftOpportunityCount() || 0;

  const createdCount = draftOpportunityCount + activeOpportunityCount;
  const participatedCount = activeInvitationsCount;

  return (
    <Header currentUser={currentUser}>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        <OptionCard
          // imageUrl={bookshelfImage}
          imageUrl={Images.PENCIL_WRITING_ON_LIST}
          title={createdCount > 0 ? (
            <>
              Launch new ideas and manage <span
              className="text-cs-orange-500 font-semibold">{createdCount}</span> {createdCount > 1 ? 'activities' : 'activity'} you've
              created
            </>
          ) : (
            <>
              Launch new ideas and manage activities you've created
            </>
          )}
          subtitle="Create and manage your projects, events, and offers here."
          linkText="Create and manage"
          to="/my-leading"
        />
        <OptionCard
          // imageUrl={bookshelfImage}
          imageUrl={Images.HANDS_IN_A_CIRCLE}
          title={participatedCount > 0 ? (
            <>
              See <span className="text-cs-orange-500 font-semibold">
              {participatedCount}
              </span> {participatedCount > 1 ? 'activities' : 'activity'} you're
              collaborating on
            </>
          ) : (
            <>
              See activities you're collaborating on
            </>
          )}
          subtitle="View the projects, events, and offers you've expressed interest in here."
          linkText="View"
          to="/my-participating"
        />
      </div>
    </Header>
  );
}
