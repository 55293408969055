import React, { useState } from 'react';
import { UsersIcon } from '@heroicons/react/24/outline';
import useRouter from '../../hooks/useRouter';
import Urls from '../offer-create/Urls';
import DataCard from '../profile-edit/DataCard';
import { PayloadStates } from '@lore/utils';
import useOfferTags from '../../hooks/useOfferTags';
import TagTypes from '../../constants/TagTypes';
import usePageRouter from '../../dialogs-routable/_hooks/usePageRouter';

export default function Participants(props) {
  const { offer } = props;

  const { history } = usePageRouter();

  const [timestamp] = useState(new Date().toISOString());

  const offerParticipants = useOfferTags(offer, TagTypes.Participant, timestamp);

  let subtitle = '';

  if (offerParticipants.state === PayloadStates.FETCHING) {
    subtitle = '...';
  } else {
    subtitle = offerParticipants.data.map(function(offerParticipant) {
      return offerParticipant.data.name;
    }).join(', ');
  }

  return (
    <DataCard
      title="Participants"
      icon={UsersIcon}
      subtitle={subtitle || '(not provided)'}
      onClick={() => history.push(Urls(offer).PARTICIPANTS)}
    />
  );
};
