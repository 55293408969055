import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useConnect } from '@lore/query-connect';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import SolidButton from '../../../components/Button/SolidButton';
import FormTemplate2 from '../../_post-opportunity/FlowTemplate';
import EventUpload from './EventUpload';
import FormSection from '../../_post-opportunity/forms/FormSection';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';
import getUploadInfo from '../../evaluation-project-educator/Uploads/getUploadInfo';
import UploadImageDestroyable from '../../evaluation-project-educator/Uploads/UploadImageDestroyable';
import UploadDocumentDestroyable from '../../evaluation-project-educator/Uploads/UploadDocumentDestroyable';
import Card from '../../../components/Card';

Form.propTypes = {
  user: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func,
  onSkip: PropTypes.func
};

export default function Form(props) {
  const {
    user,
    event,
    navigation,
    onNext,
    onPrevious,
    onSkip,
    onExit,
    FormTemplate = FormTemplate2,
    buttonText = 'Next'
  } = props;

  const eventUploads = useConnect('upload.find', {
    where: {
      eager: {
        $where: {
          eventId: event.id
        }
      }
    }
  })

  const [data, setData] = useState(_.pick(event.data, [
    // 'field'
  ]));
  const [showErrors, setShowErrors] = useState(false);
  const [exit, setExit] = useState(false);

  const mutation = useQueryMutation('event.update', event);
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      exit ? onExit(data) : onNext(data);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate(data);
  }

  function onSubmitAndExit() {
    onSubmit();
    setExit(true);
  }

  function onChange(name, value) {
    setData(_.merge({}, data, {
      [name]: value
    }));
  }

  function onUpload(upload) {
    // mutation.mutate({
    //   eventId: event.id,
    //   uploadId: upload.id
    // })
  }

  const eventImages = {
    data: eventUploads.data.filter(function(eventUpload) {
      return getUploadInfo(eventUpload.data.name).isImage;
    })
  };

  const eventDocuments = {
    data: eventUploads.data.filter(function(eventUpload) {
      return !getUploadInfo(eventUpload.data.name).isImage;
    })
  };

  return (
    <Overlay isVisible={saving}>
      <FormErrors data={data} validators={{
        // field: [validators.isRequired]
      }}>
        {({ errors, hasError }) => {
          return (
            <FormTemplate
              onExit={() => hasError ? setShowErrors(true) : onSubmitAndExit()}
              onPrevious={onPrevious}
              navigation={navigation}
              alert={error ? (
                <RequestError request={request} />
              ) : showErrors ? (
                <ErrorMessage>
                  Please fill in all required fields.
                </ErrorMessage>
              ) : null}
              body={(
                <FormSection
                  // title="Event Documents"
                  // tooltip={(
                  //   <>
                  //     <div className="font-semibold text-cs-gray-900 text-base">
                  //       Examples
                  //     </div>
                  //     <ul className="list-disc ml-6 text-cs-gray-900 text-base">
                  //       {[
                  //         'Informational flyers',
                  //         'Formal application',
                  //         'Photo from a previous year',
                  //         'Student questions',
                  //         'Etc.'
                  //       ].map(function(text, index) {
                  //         return (
                  //           <li key={index}>
                  //             {text}
                  //           </li>
                  //         );
                  //       })}
                  //     </ul>
                  //   </>
                  // )}
                >
                  <div className="space-y-8">
                    <Card className="p-5">
                      <div className="border border-dashed border-cs-orange-500 bg-cs-orange-500 bg-opacity-20 rounded-lg text-center py-20">
                        <EventUpload
                          event={event}
                          onUpload={onUpload}
                        />
                      </div>
                    </Card>
                    {eventImages.data.length > 0 && (
                      <FormSection title="Event Images">
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4">
                          {eventImages.data.map(function(eventUpload) {
                            return (
                              <UploadImageDestroyable
                                key={eventUpload.id || eventUpload.cid}
                                upload={eventUpload}
                              />
                            );
                          })}
                        </div>
                      </FormSection>
                    )}
                    {eventDocuments.data.length > 0 && (
                      <FormSection title="Event Documents">
                        <div className="grid grid-cols-1 sm:grid-cols-2x gap-6">
                          {eventDocuments.data.map(function(eventUpload) {
                            return (
                              <UploadDocumentDestroyable
                                key={eventUpload.id || eventUpload.cid}
                                upload={eventUpload}
                              />
                            );
                          })}
                        </div>
                      </FormSection>
                    )}
                  </div>
                </FormSection>
              )}
              footer={(
                <>
                  <SolidButton onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
                    {buttonText}
                  </SolidButton>
                </>
              )}
            />
          );
        }}
      </FormErrors>
    </Overlay>
  );
};
