import React from 'react';

export default function Description(props) {
  const { invitation } = props;

  if (!_.trim(invitation.data.requestMessage)) {
    return null;
  }

  return (
    <div className="text-base text-cs-gray-500">
      "{invitation.data.requestMessage}"
    </div>
  );
}
