import React from 'react';
import { useConnect } from '@lore/query-connect';
import { SparklesIcon } from '@heroicons/react/24/outline';
import useRouter from '../../hooks/useRouter';
import Urls from '../event-create/Urls';
import DataCard from '../profile-edit/DataCard';
import usePageRouter from '../../dialogs-routable/_hooks/usePageRouter';

export default function Activity(props) {
  const { event } = props;
  const { history } = usePageRouter();

  const activty = useConnect('tag.byId', {
    id: event.data.activityId
  });

  return (
    <DataCard
      title="Activity"
      icon={SparklesIcon}
      subtitle={activty.data.name || '...'}
      onClick={() => history.push(Urls(event).ACTIVITY)}
    />
  );
};
