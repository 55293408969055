import React from 'react';
import { Helmet } from 'react-helmet-async';
import DraftLeading from './DraftLeading';
import ActiveLeading from './ActiveLeading';
import LeadingCallToAction from './LeadingCallToAction';
import PageHeader from '../people-people/PageHeader';

function Header(props) {
  const { children } = props;

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Leading</title>
      </Helmet>
      <PageHeader
        title="Projects, Events, and Offers you're leading"
        subtitle={(
          <>
            View the active projects, events, and offers you've launched or drafted below. You
            can also start creating new opportunities right here.
          </>
        )}
      />
      <div className="space-y-8">
        {children}
      </div>
    </div>
  );
}

export default function Layout(props) {
  return (
    <Header>
      <div className="space-y-8">
        <LeadingCallToAction />
        <ActiveLeading />
        <DraftLeading />
      </div>
    </Header>
  );
}
