import React, { useState } from 'react';
import { BookOpenIcon } from '@heroicons/react/24/outline';
import useRouter from '../../hooks/useRouter';
import Urls from '../offer-create/Urls';
import DataCard from '../profile-edit/DataCard';
import { PayloadStates } from '@lore/utils';
import useOfferTags from '../../hooks/useOfferTags';
import TagTypes from '../../constants/TagTypes';
import usePageRouter from '../../dialogs-routable/_hooks/usePageRouter';

export default function Subjects(props) {
  const { offer } = props;

  const { history } = usePageRouter();

  const [timestamp] = useState(new Date().toISOString());

  const offerSubjects = useOfferTags(offer, TagTypes.Subject, timestamp);

  let subtitle = '';

  if (offerSubjects.state === PayloadStates.FETCHING) {
    subtitle = '...';
  } else {
    subtitle = offerSubjects.data.map(function(offerSubject) {
      return offerSubject.data.name;
    }).join(', ');
  }

  return (
    <DataCard
      title="Related Subjects"
      icon={BookOpenIcon}
      subtitle={subtitle || '(not provided)'}
      onClick={() => history.push(Urls(offer).SUBJECTS)}
    />
  );
};
