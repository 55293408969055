import React from 'react';
import PropTypes from 'prop-types';
import FormField from '../../forms/_common/FormField';
import { OutlineButton, SolidButton } from '../../components/Button';
import FormTemplate from '../../dialogs_common/FormTemplate';
import RadioButtonField from '../../pages/_post-opportunity/_fields/RadioButtonField.cards';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';
import { useConnect } from '../../../hooks/@lore/query-connect';
import _ from 'lodash';
import { PayloadStates } from '@lore/utils';
import { TrashIcon } from '@heroicons/react/24/outline';
import VirtualAvatar from '../../components/User/VirtualAvatar';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  activityTypes: PropTypes.object,
  ages: PropTypes.object
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onCancel,
    question
  } = props;

  const recipient = useConnect('user.byId', {
    id: question.data.userId
  });

  const name = recipient.state === PayloadStates.FETCHING ?
    '...' :
    `${recipient.data.firstName} ${recipient.data.lastName}`.trim();

  const resultMap = {
  'Nothing':      (
    <>
      <strong>The partnership didn’t move forward</strong>. No advice, connections, or support
      were provided.
    </>
  ),
  'Contributed':  (
    <>
      <strong>{recipient.data.firstName} contributed </strong> advice on a project idea, shared
      resources, or referred you to another person or organization.
    </>
  ),
  'Participated': (
    <>
      <strong>{recipient.data.firstName} collaborated on this project</strong>, engaging with you
      and/or your students virtually or in person.
    </>
  )
};

  return (
    <FormTemplate
      icon={(
        <div className="flex justify-center mb-4">
          {/*<TrashIcon className="text-cs-orange-500 h-12 w-12" />*/}
          <VirtualAvatar size={14} user={recipient} />
        </div>
      )}
      title={`How did ${recipient.data.firstName} support your project?`}
      subtitle={(
        <>
          Let us know {recipient.data.firstName} supported your project or event so we can
          celebrate their contributions. Partners who worked directly with you will also be
          invited to share their own reflections on the experience
        </>
      )}
      onClose={onCancel}
      alert={alert}
      body={(
        <FormField name="result" data={data} errors={showErrors && errors} onChange={onChange}>
          {(field) => (
            <RadioButtonField
              name="result"
              // label="How did you connect?*"
              value={field.value}
              error={field.error}
              // placeholder="Select a role..."
              options={{
                data: _.keys(resultMap).map((option) => {
                  return {
                    id: option,
                    data: {
                      name: resultMap[option]
                    }
                  }
                })
              }}
              optionLabel="name"
              onChange={function(value) {
                field.onChange(value);
              }}
            />
          )}
        </FormField>
      )}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onCancel}>
            Cancel
          </OutlineButton>
          <SolidButton onClick={onSubmit}>
            Save
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
