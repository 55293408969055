import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import { useUser } from '@lore/auth';
import ClampableText from '../../components/ClampableText';
import Avatar from '../../components/Avatar';
import logo from '../../../assets/images/logo.png';
import UserOrganization from '../../pages/people/__common/UserOrganization.v2';

useOfferInterestEmailPreview.propTypes = {
  offer: PropTypes.object.isRequired
};

export default function useOfferInterestEmailPreview(offer, partner, customMessage) {
  // const { offer } = props;

  const currentUser = useUser();

  const activity = useConnect('tag.byId', {
    id: offer.data.activityId
  });

  if (activity.state === PayloadStates.FETCHING) {
    return (
      <div className="bg-cs-gray-50 p-6">
        ...
      </div>
    );
  }

  return (
    <div className="bg-cs-gray-50 p-6">
      <div className="h-1 bg-cs-orange-500 w-full mb-8"/>
      <div className="p-6 mb-8">
        <img
          className="w-24 mx-auto"
          src={logo}
          alt="logo"
        />
      </div>
      <div className="mb-8">
        <div className="text-base text-cs-gray-900">
          Hi {partner.data.firstName},
        </div>
        <div className="mt-3 text-base text-cs-gray-900">
          <ClampableText markup>
            {customMessage}
          </ClampableText>
        </div>
        <div className="mt-3 mb-3">
          <div className="text-base text-cs-gray-900">
            Cheers,
          </div>
        </div>
        <div className="flex items-center space-x-3">
          <div>
            <Avatar size={12} src={currentUser.data.avatarUrl} />
          </div>
          <div>
            <div className="text-lg text-cs-gray-900 font-semibold">
              {currentUser.data.firstName} {currentUser.data.lastName}
            </div>
            <div className="text-sm text-cs-gray-500">
              <UserOrganization user={currentUser} />
            </div>
          </div>
        </div>
      </div>
      <div className="mb-8">
        <div className="flex flex-col text-center justify-center bg-cs-orange-500 rounded-xl p-6">
          <div className="text-2xl text-white font-semibold mb-2">
            Let's keep the momentum going!
          </div>
          <div className="text-base text-white mb-4">
            Now that <span className="font-semibold">{currentUser.data.firstName}</span> has connected with
            you about <span className="font-semibold">{offer.data.title || activity.data.name}</span>, the
            next step is to respond and keep coordinating together.
          </div>
          <div className="bg-white rounded-lg w-56 text-center py-3 m-auto">
            <div className="text-base text-cs-orange-500 uppercase font-semibold">
              Respond
            </div>
          </div>
        </div>
      </div>
      <div className="h-1 bg-cs-orange-500 w-full"/>
    </div>
  );
};
