import React from 'react';
import PropTypes from 'prop-types';
import SolidButton from '../../components/Button/SolidButton';
import FormTemplate from '../../dialogs_common/FormTemplate';
import { TrashIcon } from '@heroicons/react/24/outline';

Confirmation.propTypes = {
  event: PropTypes.object
};

export default function Confirmation(props) {
  const {
    event,
    onCancel,
    onPrevious
  } = props;

  return (
    <FormTemplate
      // className="w-screen max-w-screen-sm"
      icon={(
        <div className="flex justify-center mb-4">
          <TrashIcon className="text-cs-orange-500 h-12 w-12" />
        </div>
      )}
      title="Thanks for letting us know."
      subtitle={(
        <>
          We'll close your event and record it as cancelled. You'll be able to
          find it in your Past Events for future reference or to duplicate it
          to reuse in the future.
        </>
      )}
      onClose={onCancel}
      footer={(
        <div className="space-x-4">
          {/*<OutlineButton onClick={onPrevious}>*/}
          {/*  Back*/}
          {/*</OutlineButton>*/}
          <SolidButton onClick={onCancel}>
            Close
          </SolidButton>
        </div>
      )}
    />
  );
}
