import React from 'react';
import PropTypes from 'prop-types';
import ConnectButton from '../../../pages/_banner/ConnectButton';
import Wrapper from '../../../pages/_banner/Wrapper';
import useDialogLauncher from '../../_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import { useConnect } from '../../../../hooks/@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import usePageRouter from '../../_hooks/usePageRouter';
import useRouter from '../../../hooks/useRouter';

CompletedBanner.propTypes = {
  offer: PropTypes.object.isRequired
};

export default function CompletedBanner(props) {
  const { offer, creator } = props;

  const launch = useDialogLauncher();
  const dialogRouter = useRouter();
  const pageRouter = usePageRouter();

  function onDuplicate() {
    launch(DialogManager.OfferDuplicate.Config(offer.id));
  }

  function onViewOffer() {
    dialogRouter.history.push('/view')
  }

  return (
    <Wrapper
      color="green"
      creator={creator}
      title={(
        <>
          Thanks for completing this offer!
        </>
      )}
      subtitle={(
        <>
          Take a moment to celebrate! Thanks for creating meaningful opportunities for
          learners. Want to revisit or build on this idea? You can duplicate your offer below.
        </>
      )}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        <ConnectButton outline={true} color="green" onClick={onDuplicate}>
          Duplicate your offer
        </ConnectButton>
      </div>
    </Wrapper>
  );
};
