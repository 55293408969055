import React, { useState } from 'react';
import { MapPinIcon } from '@heroicons/react/24/outline';
import useRouter from '../../hooks/useRouter';
import Urls from '../event-create/Urls';
import DataCard from '../profile-edit/DataCard';
import { PayloadStates } from '@lore/utils';
import useEventTags from '../../hooks/useEventTags';
import TagTypes from '../../constants/TagTypes';
import usePageRouter from '../../dialogs-routable/_hooks/usePageRouter';

export default function Location(props) {
  const { event } = props;

  const { history } = usePageRouter();

  const [timestamp] = useState(new Date().toISOString());

  const eventInteractionTypes = useEventTags(event, TagTypes.InteractionType, timestamp);

  let subtitle = '';

  if (eventInteractionTypes.state === PayloadStates.FETCHING) {
    subtitle = '...';
  } else {
    subtitle = eventInteractionTypes.data.map(function(eventInteractionType) {
      return eventInteractionType.data.name;
    }).join(', ');
  }

  return (
    <DataCard
      title="Location"
      icon={MapPinIcon}
      subtitle={subtitle || '(not provided)'}
      onClick={() => history.push(Urls(event).LOCATION)}
    />
  );
};
