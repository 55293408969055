import TagTypes from '../../constants/TagTypes';

export const MISSING_FIELDS = {
  // Missing activity
  activityId: {
    $exists: false
  },
  siteId: {
    $exists: false
  },
  // Missing description
  interactionDescription: {
    $or: {
      $equals: '',
      $exists: false
    }
  },
  $or: [
    // Missing date
    {
      specificDate: 'Yes',
      date: {
        $exists: false
      }
    },
    // Missing date range
    {
      specificDate: 'No',
      $or: {
        startDate: {
          $exists: false
        },
        endDate: {
          $exists: false
        }
      }
    },
    // Missing a participant
    {
      tags: {
        $tag_type_count_lte: {
          type: TagTypes.Participant,
          value: 0
        }
      }
    },
    // Missing a method of interaction
    {
      tags: {
        $tag_type_count_lte: {
          type: TagTypes.InteractionType,
          value: 0
        }
      }
    }
  ]
}

export default MISSING_FIELDS;
