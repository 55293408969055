import React from 'react';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Navigation from '../../../dialogs_common/Navigation';
import ParentLayout from '../ParentLayout';
import Urls from '../Urls';
import Form from './Form';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { location, history, match } = useRouter();
  const user = useUser();
  const evaluation = useConnect('evaluationProjectEducator.byId', {
    id: Number(match.params.evaluationId)
  })

  if (evaluation.state === PayloadStates.FETCHING) {
    return null;
  }

  return (
    <ParentLayout
      evaluation={evaluation}
      page="Adventures"
      title={(
        <>
          What adventures did you go on? Share some steps your community-engaged learning journey took.
        </>
      )}
      description={(
        <>
          <span className="font-semibold">Example:</span> I made a list of potential community
          partners who could speak about our local history and asked my students to vote on
          which partners and ideas seemed most exciting and relevant. I asked if they knew
          people in their own family or communities who might be good speakers and then looked
          on CommunityShare to find potential partners. I brought in a series of guest speakers
          to offer different perspectives – an indigenous artist, a neighborhood elder and a
          geologist. Then I invited a local oral historian to teach my students how to conduct
          oral histories and they interviewed someone in their family so they could see how history
          is alive in themselves and their own families.
        </>
      )}
    >
      <Form
        user={user}
        evaluation={evaluation}
        navigation={(
          <Navigation v5={true} activeStep={9} steps={14}/>
        )}
        onPrevious={(newData) => history.push(Urls(evaluation).JOURNEY)}
        onNext={(newData) => history.push(Urls(evaluation).CHALLENGES)}
        onSkip={() => history.push(Urls(evaluation).CHALLENGES)}
        onExit={() => history.push(Urls(evaluation).PROJECT_MANAGE)}
      />
    </ParentLayout>
  );
};
