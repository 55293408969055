import React from 'react';
import { UseQueryConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import Question from './Question';

export default function Questions(props) {
  const { questions } = props;

  return (
    <>
      {questions.data.map(function(question, index) {
        return (
          <UseQueryConnect key={index} _key="project.byId" params={{ id: question.data.projectId }}>
            {function(project) {
              if (project.state === PayloadStates.FETCHING) {
                return (
                  <Loader />
                );
              }

              return (
                <Question question={question} />
              );
            }}
          </UseQueryConnect>
        );
      })}
    </>
  );
}
