import React from 'react';
import PropTypes from 'prop-types';
import FormField from '../_common/FormField';
import SolidButton from '../../components/Button/SolidButton';
import TextAreaField from '../../forms/_fields_v2/TextAreaField';
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func
};

Form.defaultProps = {
  data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit
  } = props;

  return (
    <div>
      {alert}
      <div className={classNames(
        'flex flex-col',
        'border border-cs-gray-200 rounded-lg overflow-hidden',
        'space-y-2 bg-white'
      )}>
        <div className="w-full">
          <FormField name="content" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <TextAreaField
                shadow={false}
                focus={false}
                border={false}
                inputClassName="rounded-none border-none resize-none flex align-items-stretch focus:outline-none focus:ring-0"
                rows={2}
                placeholder="Message..."
                value={field.value}
                error={field.error}
                onChange={field.onChange}
              />
            )}
          </FormField>
        </div>
        <div className="flex justify-end bg-white px-2.5 pb-2.5">
          <SolidButton
            icon={PaperAirplaneIcon}
            iconClassName="-rotate-90"
            onClick={onSubmit}
            // shadow={false}
            // size="base"
          >
            Send message
          </SolidButton>
        </div>
      </div>
    </div>
  );
}
