import React from 'react';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Navigation from '../../../dialogs_common/Navigation';
import ParentLayout from '../ParentLayout';
import Urls from '../Urls';
import Form from './Form';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { location, history, match } = useRouter();
  const user = useUser();
  const evaluation = useConnect('evaluationEventEducator.byId', {
    id: Number(match.params.evaluationId)
  })

  if (evaluation.state === PayloadStates.FETCHING) {
    return null;
  }

  return (
    <ParentLayout
      evaluation={evaluation}
      page="Changes"
      title={(
        <>
          How were you, your students and/or your partners changed by this event?
        </>
      )}
      description={(
        <>
          <span className="font-semibold">Example:</span> I think this was the first time
          students saw the relevance of history to themselves and how it relates to
          culture and place. Students were super engaged and asking tons of questions
          when the indigenous artist showed his baskets and talked about how the
          materials woven into them reflected the history of his community and their
          relationship to the land. One neighborhood elder said: "I now feel closer
          to the kids and teachers in my community. This kind of experience gives us
          all opportunities to break down prejudices, to see each other for who we are,
          unique people with unique histories."
        </>
      )}
    >
      <Form
        user={user}
        evaluation={evaluation}
        navigation={(
          <Navigation v5={true} activeStep={8} steps={10}/>
        )}
        onPrevious={(newData) => history.push(Urls(evaluation).DESCRIPTION)}
        onNext={(newData) => history.push(Urls(evaluation).UPLOADS)}
        onSkip={() => history.push(Urls(evaluation).UPLOADS)}
        onExit={() => history.push(Urls(evaluation).PROJECT_MANAGE)}
      />
    </ParentLayout>
  );
};
