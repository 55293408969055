import React, { useState } from 'react';
import { ArchiveBoxIcon } from '@heroicons/react/24/outline';
import useRouter from '../../hooks/useRouter';
import Urls from '../offer-create/Urls';
import DataCard from '../profile-edit/DataCard';
import { PayloadStates } from '@lore/utils';
import useOfferTags from '../../hooks/useOfferTags';
import TagTypes from '../../constants/TagTypes';
import usePageRouter from '../../dialogs-routable/_hooks/usePageRouter';

export default function Supplies(props) {
  const { offer } = props;

  const { history } = usePageRouter();

  const [timestamp] = useState(new Date().toISOString());

  const offerSupplies = useOfferTags(offer, TagTypes.Supply, timestamp);

  let subtitle = '';

  if (offerSupplies.state === PayloadStates.FETCHING) {
    subtitle = '...';
  } else {
    subtitle = offerSupplies.data.map(function(offerSupply) {
      return offerSupply.data.name;
    }).join(', ');
  }

  return (
    <DataCard
      title="Necessary Supplies"
      icon={ArchiveBoxIcon}
      subtitle={subtitle || '(not provided)'}
      onClick={() => history.push(Urls(offer).SUPPLIES)}
    />
  );
};
