import React from 'react';
import PropTypes from 'prop-types';
import ConnectButton from '../../../pages/_banner/ConnectButton';
import Wrapper from '../../../pages/_banner/Wrapper';
import useDialogLauncher from '../../_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useRouter from '../../../hooks/useRouter';
import { useConnect } from '../../../../hooks/@lore/query-connect';
import { PayloadStates } from '@lore/utils';

CompletedBanner.propTypes = {
  project: PropTypes.object.isRequired
};

export default function CompletedBanner(props) {
  const { project, creator } = props;

  const launch = useDialogLauncher();
  const { history } = useRouter();

  const evaluation = useConnect('evaluationProjectEducator.first', {
    where: {
      eager: {
        $where: {
          projectId: project.id,
          userId: project.data.creatorId
        }
      }
    }
  });

  function onViewEvaluation() {
    launch(DialogManager.EvaluationProjectEducator.Config(evaluation.id));
  }

  function onDuplicate() {
    launch(DialogManager.ProjectDuplicate.Config(project.id));
  }

  return (
    <Wrapper
      color="green"
      creator={creator}
      title={(
        <>
          Your project is complete!
        </>
      )}
      subtitle={(
        <>
          Take a moment to celebrate! Thanks for creating meaningful opportunities
          for learners. We're grateful to have you in our community.
        </>
      )}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        {evaluation.state === PayloadStates.RESOLVED && (
          <ConnectButton outline={true} onClick={onViewEvaluation}>
            Revisit your impact
          </ConnectButton>
        )}
        <ConnectButton color="green" onClick={onDuplicate}>
          Duplicate your project
        </ConnectButton>
        {/*<ConnectButton disabled={true}>*/}
        {/*  This is you*/}
        {/*</ConnectButton>*/}
      </div>
    </Wrapper>
  );
};
