export default function(evaluation) {
  return {
    START: `/evaluation-project-partner/${evaluation.id}/start`,
    INTERACTIONS: `/evaluation-project-partner/${evaluation.id}/interactions`,
    HOURS: `/evaluation-project-partner/${evaluation.id}/hours`,
    CONTRIBUTION: `/evaluation-project-partner/${evaluation.id}/contribution`,
    OUTCOME: `/evaluation-project-partner/${evaluation.id}/outcome`,
    RECOMMEND: `/evaluation-project-partner/${evaluation.id}/recommend`,
    END: `/evaluation-project-partner/${evaluation.id}/end`,
    PROJECT_VIEW: `/project/${evaluation.data.projectId}/view`,
  };
};
