import React from 'react';

export default function MediaSection(props) {
  const { image, title, subtitle } = props;

  return (
    <div className="flex flex-1 items-center space-x-4">
      {image}
      <div className="flex flex-col">
        <div className="text-lg font-semibold text-gray-700">
          {title}
        </div>
        <div className="text-sm text-gray-500">
          {subtitle}
        </div>
      </div>
    </div>
  );
};
