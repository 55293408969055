import React from 'react';
import SolidButton from '../components/Button/SolidButton';
import Template from '../dialogs_common/FormTemplate';
import DialogTemplate from '../dialogs_common/DialogTemplate';

export default function BiographySuggestionsDialog(props) {
  const { onCancel } = props;

  return (
    <DialogTemplate className="w-screen max-w-screen-sm">
      <Template
        title="Biography suggestions"
        subtitle="Consider these examples when crafting your bio"
        onClose={onCancel}
        body={(
          <>
            <div className="mb-8">
              <div className="text-base font-semibold text-cs-gray-900">
                Example #1
              </div>
              <p className="text-cs-gray-500">
                As a tech geek I have spent much of my life using technology to help solve
                problems for companies and others. I feel most alive when I am taking photos, doing
                stand-up comedy, hiking in nature, and hanging out with my kids.
              </p>
            </div>
            <div className="mb-8">
              <div className="text-base font-semibold text-cs-gray-900">
                Example #2
              </div>
              <p className="text-cs-gray-500">
                I am a nurse by day and waitress by night, who is passionate about latino
                culture, community organizing, baking sweets, gardening, turtles, and
                volunteering. Life has not been easy as a first generation college graduate,
                but I am proud of the life I have built and want to support others in achieving
                their dreams.
              </p>
            </div>
            <div className="mb-8">
              <div className="text-base font-semibold text-cs-gray-900">
                Example #3
              </div>
              <p className="text-cs-gray-500">
                Growing up in a small mountain town, I loved hunting and fishing. After 20 years
                in the Air Force traveling the world, I went back to school, became a college and
                high school teacher and principal, and then finally retired, sort of, as I still
                tutor kids and play in a band on the side.
              </p>
            </div>
          </>
        )}
        footer={(
          <SolidButton onClick={onCancel}>
            Close
          </SolidButton>
        )}
      />
    </DialogTemplate>
  );
};
