import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useDialogConfig from '../_hooks/useDialogConfig';
import StoryDialog from '../../dialogs/story.view';

export default function Launcher(props) {
  const config = useDialogConfig();

  const story = useConnect('story.byId', {
    id: config.params.storyId
  });

  if (story.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <StoryDialog
      story={story}
      onCancel={props.onClose}
    />
  );
};
