import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import _ from 'lodash';
import { useConfig } from '@lore/config';
import { stringify } from 'query-string';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import DialogTemplate from '../../../dialogs_common/DialogTemplate';
import FormTemplate from '../../../dialogs_common/FormTemplate';
import SolidButton from '../../../components/Button/SolidButton';
import OutlineButton from '../../../components_deprecated/OutlineButton';
import { PayloadStates } from '@lore/utils';

Info.propTypes = {
  data: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired
};

export default function Info(props) {
  const {
    user,
    data: _data,
    onNext,
    onCancel
  } = props;

  const [data, setData] = useState(_.pick(_data, [
    'question1'
  ]));
  const [showErrors, setShowErrors] = useState(false);
  const [_request, setRequest] = useState(null);
  const [finished, setFinished] = useState(false);

  const request = _request;
  const saving = request && request.state === PayloadStates.CREATING;
  const error = request && request.state === PayloadStates.ERROR_CREATING;
  const success = request && request.state === PayloadStates.RESOLVED;

  const config = useConfig();

  useEffect(() => {
    if (success && !finished) {
      setFinished(true);
      onNext(data);
    }
  });

  const questions = [
    `Would you share the reason you're deleting your account? Is there something we can improve?`
  ];

  function onSubmit() {
    axios({
      url: 'https://api-iam.intercom.io/messenger/web/messages',
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: stringify({
        app_id: config.intercom.appId,
        user_data: JSON.stringify({
          email: user.data.email
        }),
        blocks: JSON.stringify([
          {
            type: "paragraph",
            text: `I would like to reactivate my account.`
          },
          // {
          //   type: "paragraph",
          //   text: `Q: ${questions[0]}`
          // },
          // {
          //   type: "paragraph",
          //   text: `A: ${data.question1}`
          // }
        ])
      })
    }).then(function() {
      setRequest({
        state: PayloadStates.RESOLVED
      });
    }).catch(function(error) {
      setRequest({
        state: PayloadStates.ERROR_CREATING,
        error: error
      });
    });

    setRequest({
      state: PayloadStates.CREATING
    });
  }

  function onChange(name, value) {
    setData(_.merge({}, data, {
      [name]: value
    }));
  }

  return (
    <DialogTemplate>
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          // question1: [validators.isRequired]
        }}>
          {({ errors, hasError }) => {
            return (
              <FormTemplate
                title="Reactivate your account?"
                subtitle="Confirming will send a message to support asking them to review the request and reactivate your account."
                onClose={onCancel}
                alert={error ? (
                  <RequestError request={request} />
                ) : showErrors ? (
                  <ErrorMessage>
                    Please fill in all required fields.
                  </ErrorMessage>
                ) : null}
                footer={(
                  <div className="space-x-4">
                    <OutlineButton onClick={onCancel}>
                      Cancel
                    </OutlineButton>
                    <SolidButton onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
                      Request reactivation
                    </SolidButton>
                  </div>
                )}
              />
            );
          }}
        </FormErrors>
      </Overlay>
    </DialogTemplate>
  );
};
