import React from 'react';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import QuestionsClassify from './QuestionsClassify';
import useDialogLauncher from '../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../dialogs_new/DialogManager';
import QuestionsParticipated from './QuestionsParticipated';
import QuestionsContributed from './QuestionsContributed';
import QuestionsNothing from './QuestionsNothing';
import QuestionsInvited from './QuestionsInvited';
import QuestionsDeclined from './QuestionsDeclined';
import QuestionsConnected from './QuestionsConnected';
import EmptyState from '../../components/EmptyState';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { useConnect } from '../../../hooks/@lore/query-connect';

export default function ProjectManageDialogLayout(props) {
  const { project } = props;

  const launch = useDialogLauncher();

  const questions = useConnect('question.find', {
    where: {
      eager: {
        $where: {
          projectId: project.id
        }
      }
    }
  });

  function onClick(user) {
    launch(DialogManager.Messages.Config(user.id));
  }

  if (
    project.state === PayloadStates.FETCHING ||
    questions.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  if (questions.data.length === 0) {
    return (
      <EmptyState
        icon={EnvelopeIcon}
        title="No partners were invited."
        subtitle="If they had been, this page woud provide a summary of who responded."
      />
    );
  }

  return (
    <>
      <QuestionsClassify
        project={project}
        onViewMessages={onClick}
      />
      <QuestionsParticipated
        project={project}
        onViewMessages={onClick}
      />
      <QuestionsContributed
        project={project}
        onViewMessages={onClick}
      />
      <QuestionsNothing
        project={project}
        onViewMessages={onClick}
      />
      {/*<QuestionsConnected*/}
      {/*  project={project}*/}
      {/*  onViewMessages={onClick}*/}
      {/*/>*/}
      <QuestionsInvited
        project={project}
        onViewMessages={onClick}
      />
      <QuestionsDeclined
        project={project}
        onViewMessages={onClick}
      />
    </>
  );
};
