import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import validators from '../../../utils/validators';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import FormTemplate from '../../../dialogs_common/SetupTemplate';
import PrimaryButton from '../../../components_setup/PrimarySetupButton';
import FormField from '../../../forms/_common/FormField';
import TextAreaField from '../../../forms/_fields_v2/TextAreaField';
import HOPE_MAX_LENGTH from './HOPE_MAX_LENGTH';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';

Form.propTypes = {
  user: PropTypes.object.isRequired,
  onNext: PropTypes.func,
  onPrevious: PropTypes.func,
  onSkip: PropTypes.func
};

export default function Form(props) {
  const {
    user,
    navigation,
    onNext,
    onPrevious,
    onSkip
  } = props;

  const launch = useDialogLauncher();

  const [data, setData] = useState(_.pick(user.data, [
    'biography',
    'hope'
  ]));
  const [showErrors, setShowErrors] = useState(false);

  const mutation = useQueryMutation('currentUser.updateSingleton', user);
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      onNext(data);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate(data);
  }

  function onChange(name, value) {
    setData(_.merge({}, data, {
      [name]: value
    }));
  }

  function onShowSuggestions() {
    launch(DialogManager.BiographySuggestions.Config());
  }

  return (
    <div className="my-auto">
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          hope: [validators.maxLength(HOPE_MAX_LENGTH)]
        }}>
          {({ errors, hasError }) => {
            return (
              <FormTemplate
                key={`${data.name}.${data.place_id}.${data.type}`}
                onPrevious={onPrevious}
                navigation={navigation}
                title="Share your story."
                subtitle="Painting a picture of you and your life experiences helps others who might want to connect with you."
                alert={error ? (
                  <RequestError request={request} v2={true} />
                ) : showErrors ? (
                  <ErrorMessage v2={true}>
                    Please fill in all required fields.
                  </ErrorMessage>
                ) : null}
                body={(
                  <>
                    <div className="flex flex-col max-w-4xl">
                      <div className="max-w-2xl mb-16">
                        <FormField name="biography" data={data} errors={true && errors} onChange={onChange}>
                          {(field) => (
                            <TextAreaField
                              label={(
                                <span>
                                  In 1-2 sentences how would you describe yourself? (<span className="text-cs-orange-500 underline cursor-pointer" onClick={onShowSuggestions}>Need an example?</span>)
                                </span>
                              )}
                              placeholder="Describe yourself..."
                              description={`${field.value ? field.value.length : 0}/${HOPE_MAX_LENGTH} characters`}
                              rows={4}
                              value={field.value}
                              error={field.error}
                              onChange={field.onChange}
                            />
                          )}
                        </FormField>
                      </div>
                      {/*<div className="mb-6">*/}
                      {/*  <h5 className="text-3xl text-cs-gray-900 font-semibold text-left">*/}
                      {/*    What are you hoping to gain from CommunityShare?*/}
                      {/*  </h5>*/}
                      {/*  <h6 className="font-normal mt-2 text-cs-gray-800 text-left text-lg mb-4">*/}
                      {/*    Tell us about your past experiences or hopes for the future to give others an idea of why you're here.*/}
                      {/*  </h6>*/}
                      {/*</div>*/}
                      {/*<div className="max-w-2xl">*/}
                      {/*  <FormField name="hope" data={data} errors={true && errors} onChange={onChange}>*/}
                      {/*    {(field) => (*/}
                      {/*      <TextAreaField*/}
                      {/*        label=""*/}
                      {/*        placeholder="Share your hope..."*/}
                      {/*        description={`${field.value ? field.value.length : 0}/${HOPE_MAX_LENGTH} characters`}*/}
                      {/*        rows={4}*/}
                      {/*        value={field.value}*/}
                      {/*        error={field.error}*/}
                      {/*        onChange={field.onChange}*/}
                      {/*      />*/}
                      {/*    )}*/}
                      {/*  </FormField>*/}
                      {/*</div>*/}
                    </div>
                  </>
                )}
                footer={(
                  <>
                    {/*<div className="text-cs-orange-500 underline cursor-pointer mt-6 sm:mt-0 mr-0 sm:mr-12" onClick={onSkip}>*/}
                    {/*  Skip this step*/}
                    {/*</div>*/}
                    <PrimaryButton disabled={hasError} onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
                      Next
                    </PrimaryButton>
                  </>
                )}
              />
            );
          }}
        </FormErrors>
      </Overlay>
    </div>
  );
};
