import React from 'react';
import TagTypes from '../../constants/TagTypes';
import TagSet from './TagSet';
import DataSet from './DataSet';
import TextSearch from './TextSearch';
import getSearchUtils from '../../utils/query-utils/getSearchUtils';
import PropTypes from 'prop-types';

SearchTags.propTypes = {
  search: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
}

export default function SearchTags(props) {
  const { search, onChange } = props;

  const searchUtils = getSearchUtils(search, onChange);

  return (
    <div className="flex flex-wrap gap-3">
      <TagSet type={TagTypes.Activity} {...searchUtils('activities')} />
      <TagSet type={TagTypes.Age} {...searchUtils('ages')} />
      <TagSet type={TagTypes.Commitment} {...searchUtils('commitments')} />
      <TagSet type={TagTypes.Day} {...searchUtils('days')} />
      <TagSet type={TagTypes.ExpertiseType} {...searchUtils('expertiseTypes')} label="Expertise Type" />
      <TagSet type={TagTypes.Group} {...searchUtils('groups')} label="Youth" />
      <TagSet type={TagTypes.Guidance} {...searchUtils('guidances')} label="Professional Competency" />
      <TagSet type={TagTypes.Grade} {...searchUtils('grades')} />
      <TagSet type={TagTypes.InteractionType} {...searchUtils('interactionTypes')} label="Type" />
      <TagSet type={TagTypes.Participant} {...searchUtils('participants')} />
      <TagSet type={TagTypes.Subject} {...searchUtils('subjects')} />
      <TagSet type={TagTypes.Supply} {...searchUtils('supplies')} />
      <TagSet type={TagTypes.Time} {...searchUtils('times')} />
      <DataSet
        {...searchUtils('costs')}
        type="Cost"
        data={[
          {
            id: 1,
            data: {
              name: 'Yes',
              type: 'Cost'
            }
          },
          {
            id: 2,
            data: {
              name: 'Free',
              type: 'Cost'
            }
          }
        ]}
      />
      <DataSet
        {...searchUtils('instructions')}
        type="Instruction"
        data={[
          {
            id: 1,
            data: {
              name: 'Led by partner',
              type: 'Instruction'
            }
          },
          {
            id: 2,
            data: {
              name: 'Provided to the educator to share',
              type: 'Instruction'
            }
          }
        ]}
      />
      <TagSet type={TagTypes.Practice} {...searchUtils('practices')} />
      <TagSet type={TagTypes.Experience} {...searchUtils('experiences')} />
      <TextSearch type="Organization" {...searchUtils('organization')} />
      <TextSearch type="School" {...searchUtils('school')} />
      <TextSearch type="Name" {...searchUtils('name')} />
      <TextSearch type="Title" {...searchUtils('title')} />
      <TextSearch type="Search" {...searchUtils('search')} />
      <TextSearch type="Search" {...searchUtils('searchPartner')} />
      <TextSearch type="Search" {...searchUtils('searchEducator')} />
      <TextSearch type="Search" {...searchUtils('searchOffer')} />
    </div>
  );
};
