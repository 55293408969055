import React from 'react';
import PropTypes from 'prop-types';
import FormField from '../../forms/_common/FormField';
import { OutlineButton, SolidButton } from '../../components/Button';
import StringField from '../../forms/_fields_v2/StringField';
import FormTemplate from '../../dialogs_common/FormTemplate';
import { TrashIcon } from '@heroicons/react/24/outline';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onCancel
  } = props;

  return (
    <FormTemplate
      icon={(
        <div className="flex justify-center mb-4">
          <TrashIcon className="text-cs-orange-500 h-12 w-12" />
        </div>
      )}
      title="Would you like to cancel this offer?"
      subtitle={(
        <>
          Cancelling this offer will remove it from public view (if visible) and notify all
          interested educators that the offer has been cancelled.
        </>
      )}
      onClose={onCancel}
      alert={alert}
      body={(
        <>
          <FormField name="confirmation" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <StringField
                label="Confirmation*"
                description="Type CANCEL to confirm you want to cancel this offer"
                placeholder="CANCEL"
                value={field.value}
                error={field.error}
                onChange={field.onChange}
              />
            )}
          </FormField>
        </>
      )}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onCancel}>
            Nevermind
          </OutlineButton>
          <SolidButton onClick={onSubmit}>
            Cancel Offer
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
