import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import SuccessMessage from '../_common/SuccessMessage';
import ErrorMessage from '../_common/ErrorMessage';
import RequestError from '../_common/RequestError';
import Overlay from '../_common/Overlay';
import FormErrors from '../_common/FormErrors';
import { useUser } from '@lore/auth';
import { useQueryMutation } from '../../../hooks/@lore/query-connect';
import { useQueryClient } from '@tanstack/react-query';

Template.propTypes = {
  validators: PropTypes.func.isRequired,
  successMessage: PropTypes.node.isRequired,
  children: PropTypes.func.isRequired
};

export default function Template(props) {
  const { user, validators, successMessage, children, project, event, offer } = props;
  const currentUser = useUser();
  const queryClient = useQueryClient();

  const [data, setData] = useState({
    content: '',
    projectId: project ? project.id : null,
    eventId: event ? event.id : null,
    offerId: offer ? offer.id : null
  });
  const [showErrors, setShowErrors] = useState(false);

  const mutation = useQueryMutation('message.create');
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      props.onSubmit();
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate({
      ...data,
      senderId: currentUser.id,
      recipientId: user.id
    }, {
      onSuccess: () => queryClient.invalidateQueries({ queryKey: ['message', 'find'] })
    });
  }

  return (
    <Overlay isVisible={saving}>
      <FormErrors data={data} validators={validators(data)}>
        {({ errors, hasError }) => (
          <>
            {children({
              data,
              errors,
              showErrors,
              onChange: (name, value) => {
                setData(_.merge({}, data, {
                  [name]: value
                }));
              },
              onSubmit: () => hasError ? setShowErrors(true) : onSubmit(),
              alert: error ? (
                <RequestError request={request} />
              ) : showErrors ? (
                <ErrorMessage>
                  Please fill in all required fields.
                </ErrorMessage>
              ) : success ? (
                <SuccessMessage>
                  {successMessage}
                </SuccessMessage>
              ) : null
            })}
          </>
        )}
      </FormErrors>
    </Overlay>
  );
};
