import React from 'react';
import GrayTag from './_base/GrayTag';

export default function EventTag(props) {
  const { event } = props;

  return (
    <GrayTag label="EVENT" />
  );
}
