import React, { useEffect, useState } from 'react';
import Auth0 from 'auth0-js';
import { useConfig } from '@lore/config';
import * as Sentry from '@sentry/browser';
import auth from '../utils/auth';
import storage from '../utils/storage';
import RemoveLoadingScreen from './RemoveLoadingScreen';
import SentryError from './SentryError';
import { stringify } from 'query-string';
import useRouter from '../hooks/useRouter';

export default function AuthCallback(props) {
  const { history } = useRouter();

  const [hasError, setHasError] = useState(false);

  const config = useConfig();

  function reportError(error, errorInfo) {
    if (config.raven.enabled) {
      Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
      });
    }
  }

  useEffect(() => {
    const auth0 = new Auth0.WebAuth(config.auth0);

    /*
     * Examples errors:
     *
     * err.error: invalid_token
     * err.errorDescription: `state` does not match.
     *
     * err.error: unauthorized
     * err.errorDescription: Please verify your email before logging in: email@example.com
     *
     * err = null, authResult = null
     * (not normal) this happens if no query parameters are provided, such as navigating to
     * the route directly
     *
     *
     */

    auth0.parseHash((err, authResult) => {
      // Not sure what, if anything, can trigger this error type...it might not belong here
      if (err && err.error === 'login_required') {
        return auth0.authorize();
      }

      // This error will occur the first time a user logs in if they haven't verified
      // their email. It's a custom rule we created in the Auth0 "Auth Pipeline > Rules"
      // called "Force email verification".
      if (
        err &&
        (
          err.error === 'unauthorized' ||
          err.error === 'access_denied'
        ) &&
        err.errorDescription.indexOf('Please verify your email before logging in') >= 0
      ) {
        const email = err.errorDescription.split(':')[1];

        return history.push({
          pathname: '/unauthorized',
          search: stringify({
            email: email && email.trim()
          })
        });
      }

      // If we have an authResult with these keys, login was successful, so
      // redirect the user to their previous location or to the home page.
      if (
        authResult &&
        authResult.accessToken &&
        authResult.idToken
      ) {
        auth.saveToken(authResult.idToken);

        if (storage.has('redirectUrl')) {
          const redirectUrl = storage.get('redirectUrl');
          storage.delete('redirectUrl');
          return history.push(redirectUrl);
        }

        return history.push('/');
      }

      // If we made it here, some other error or condition occurred.
      //
      // The most common example is the error 'invalid_token' with the description '`state`
      // does not match.'
      //
      // This can occur if the user is redirected to Auth0 for login, but takes too long to
      // log in and the `state` variable expires. This can be fixed by logging in again and
      // not taking as long.

      setHasError(true);
    });
  }, []);

  if (hasError) {
    return (
      <>
        <RemoveLoadingScreen />
        <SentryError
          title="There was an error logging you in."
          description={(
            <>
              This can usually be fixed by retrying login. If you continue to experience
              a problem, please contact support.
            </>
          )}
        />
      </>
    );
  }

  return null;
};
