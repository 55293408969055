import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../components/Avatar';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import Text from '../../typography';

BaseConversation.propTypes = {
  user: PropTypes.object.isRequired,
  updatedAt: PropTypes.string,
  messageCount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired
};

export default function BaseConversation(props) {
  const {
    user,
    updatedAt,
    messageCount,
    onClick,
    selected = false
  } = props;

  return (
    <div className={classNames(
      `flex rounded-cs-10 py-3 px-4 relative`,
      'shadow-cs-flat -m-0.5 border-2',
      selected ? 'border-cs-orange-500 bg-cs-orange-50' : 'border-cs-gray-100 bg-white',
      'hover:bg-cs-orange-50'
    )}>
      {/*<Link to={`/messages/${conversation.data.userId}`} className="absolute h-full w-full" />*/}
      <div onClick={onClick} className="cursor-pointer absolute h-full w-full" />
      <div className="flex flex-1 items-start space-x-3">
        <Avatar className="mt-1" size={10} src={user.data.avatarUrl}/>
        <div className="flex flex-1 flex-col">
          <Text.BaseSemibold>
            {user.data.firstName} {user.data.lastName}
          </Text.BaseSemibold>
          <div className="w-full flex flex-row justify-between items-end text-sm text-cs-gray-500">
            <Text.SmGrayMedium>
              {updatedAt ? DateTime.fromISO(updatedAt).toRelative() : null}
            </Text.SmGrayMedium>
            <Text.SmGrayMedium>
              {messageCount} messages
            </Text.SmGrayMedium>
          </div>
        </div>
      </div>
    </div>
  );
};
