import React from 'react';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';

export default function DuplicateIconWrapper(props) {
  return (
    <div className="flex justify-center mb-4">
      <DocumentDuplicateIcon className="text-cs-orange-500 h-12 w-12" />
    </div>
  );
};
