import React from 'react';
import PropTypes from 'prop-types';
import DialogTemplate from '../../../dialogs_common/DialogTemplate';
import SolidButton from '../../../components/Button/SolidButton';
import FormTemplate from '../../../dialogs_common/FormTemplate';
import usePageRouter from '../../../dialogs-routable/_hooks/usePageRouter';

ConfirmationRedirect.propTypes = {
  onCancel: PropTypes.func.isRequired
};

export default function ConfirmationRedirect(props) {
  const { onCancel } = props;

  const { history } = usePageRouter();

  return (
    <DialogTemplate>
      <FormTemplate
        title={(
          <>
            Thanks! We'll be in touch about your request. Now let's build your profile!
          </>
        )}
        subtitle={(
          <>
            This will let you share your skills, experiences, and opportunities with other
            educators and learners.
          </>
        )}
        footer={(
          <div className="flex justify-center w-full">
            <SolidButton onClick={() => history.push(`/setup/skills-introduction`)}>
              Yes, let's get started!
            </SolidButton>
          </div>
        )}
      />
    </DialogTemplate>
  );
};
