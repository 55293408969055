import { PayloadStates } from '@lore/utils';
import usePendingQuestions from './usePendingQuestions';
import usePendingInvitations from './usePendingInvitations';
import usePendingClaims from './usePendingClaims';

export default function usePendingInvitationsCount(props) {
  const questions = usePendingQuestions();
  const invitations = usePendingInvitations();
  const claims = usePendingClaims();

  if (
    questions.state === PayloadStates.FETCHING ||
    invitations.state === PayloadStates.FETCHING ||
    claims.state === PayloadStates.FETCHING
  ) {
    return '...';
  }

  if (
    questions.data.length === 0 &&
    invitations.data.length === 0 &&
    claims.data.length === 0
  ) {
    return null;
  }

  return (
    questions.data.length +
    invitations.data.length +
    claims.data.length
  );
}
