import React, { useState } from 'react';
import _ from 'lodash';
import validators from '../../utils/validators';
import Overlay from '../../forms/_common/Overlay';
import FormErrors from '../../forms/_common/FormErrors';
import ErrorMessage from '../../forms/_common/ErrorMessage';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import Form from './Form';
import usePageRouter from '../../dialogs-routable/_hooks/usePageRouter';
import ProjectEventForm from './ProjectEventForm';
import OfferConfirmation from './OfferConfirmation';
import EventConfirmation from './EventConfirmation';
import ProjectConfirmation from './ProjectConfirmation';
import SiteForm from './SiteForm';

export default function ProjectEventOfferSelector(props) {
  const { history } = usePageRouter();

  const [data, setData] = useState({
    type: '',
    site: null
  });
  const [showErrors, setShowErrors] = useState(false);

  function onSubmit() {
    if (data.type === 'event') {
      props.onCancel();
      history.push('/post-event');
    }

    if (data.type === 'project') {
      props.onCancel();
      history.push('/post-project');
    }

    if (data.type === 'offer') {
      props.onCancel();
      history.push('/post-offer');
    }
  }

  function onChange(name, value) {
    const nextData = _.merge({}, data);
    nextData[name] = value;
    setData(nextData);
    setShowErrors(false);
  }

  return (
    <DialogTemplate className="max-w-screen-sm">
      <Overlay isVisible={false}>
        <FormErrors data={data} validators={{
          type: data.type === 'project_event' ?
            [validators.isOneOf(['project', 'event'])] :
            [validators.isRequired]
        }}>
          {({errors, hasError}) => {
            if (data.type === 'project') {
              return (
                <ProjectConfirmation
                  site={data.site}
                  onPrevious={() => setData({ type: 'project_event' })}
                  onNext={(project) => history.push(`/post-project/${project.id}/activity`)}
                />
              );
            }

            if (data.type === 'event') {
              return (
                <EventConfirmation
                  site={data.site}
                  onPrevious={() => setData({ type: 'project_event' })}
                  onNext={(event) => history.push(`/post-event/${event.id}/activity`)}
                />
              );
            }

            if (data.type === 'offer') {
              return (
                <OfferConfirmation
                  onPrevious={() => setData({ type: '' })}
                  onNext={(offer) => history.push(`/post-offer/${offer.id}/activity`)}
                />
              );
            }

            if (
              data.type === 'project_event' &&
              !data.site
            ) {
              return (
                <SiteForm
                  data={data}
                  errors={errors}
                  showErrors={showErrors}
                  onChange={onChange}
                  onPrevious={() => onChange('type', '')}
                  onSubmit={(site) => onChange('site', site)}
                  onCancel={props.onCancel}
                  alert={(hasError && showErrors) ? (
                    <ErrorMessage>
                      Please fill in all required fields.
                    </ErrorMessage>
                  ) : null}
                />
              );
            }

            if (data.type === 'project_event') {
              return (
                <ProjectEventForm
                  data={data}
                  errors={errors}
                  showErrors={showErrors}
                  onChange={onChange}
                  onPrevious={() => onChange('site', null)}
                  onSubmit={() => hasError ? setShowErrors(true) : onSubmit()}
                  onCancel={props.onCancel}
                  alert={(hasError && showErrors) ? (
                    <ErrorMessage>
                      Please fill in all required fields.
                    </ErrorMessage>
                  ) : null}
                />
              );
            }

            return (
              <Form
                data={data}
                errors={errors}
                showErrors={showErrors}
                onChange={onChange}
                onSubmit={() => hasError ? setShowErrors(true) : onSubmit()}
                onCancel={props.onCancel}
                alert={(hasError && showErrors) ? (
                  <ErrorMessage>
                    Please fill in all required fields.
                  </ErrorMessage>
                ) : null}
              />
            );
          }}
        </FormErrors>
      </Overlay>
    </DialogTemplate>
  );
};
