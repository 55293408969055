import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useConfig } from '@lore/config';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import FormTemplate from '../../../dialogs_common/SetupTemplate';
import PrimaryButton from '../../../components_setup/PrimarySetupButton';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';
import SelectableIconCard from '../activities/SelectableIconCard';
import { HandRaisedIcon, HeartIcon, UserIcon } from '@heroicons/react/24/outline';

Form.propTypes = {
  user: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func,
  onSkip: PropTypes.func.isRequired
};

export default function Form(props) {
  const {
    user,
    navigation,
    onNext,
    onPrevious,
    onSkip
  } = props;

  const config = useConfig();

  const [data, setData] = useState(_.pick(user.data, [
    // 'field'
  ]));
  const [showErrors, setShowErrors] = useState(false);

  const mutation = useQueryMutation('currentUser.updateSingleton', user);
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      onNext(data);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate(data);
  }

  function onChange(name, value) {
    setData(_.merge({}, data, {
      [name]: value
    }));
  }

  return (
    <div className="my-auto">
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          // firstName: [validators.isRequired]
        }}>
          {({ errors, hasError }) => {
            return (
              <FormTemplate
                onPrevious={onPrevious}
                navigation={navigation}
                title="Your unique knowledge, skills, and experiences can make a real difference."
                subtitle={(
                  <>
                    As you answer the next questions, go beyond your resume and think about how
                    you can contribute using your head, hands, and heart.
                  </>
                )}
                alert={error ? (
                  <RequestError request={request} v2={true}/>
                ) : showErrors ? (
                  <ErrorMessage v2={true}>
                    Please fill in all required fields.
                  </ErrorMessage>
                ) : null}
                body={(
                  <div className="space-y-2">
                    <SelectableIconCard
                      icon={UserIcon}
                      name="Head"
                      description="Knowledge, experience, and expertise areas you know"
                    />
                    <SelectableIconCard
                      icon={HandRaisedIcon}
                      name="Hands"
                      description="Skills and experiences you might share"
                    />
                    <SelectableIconCard
                      icon={HeartIcon}
                      name="Heart"
                      description="What you care about or what is core to your identify"
                    />
                  </div>
                )}
                footer={(
                  <>
                    {/*<div className="text-cs-orange-500 underline cursor-pointer mt-6 sm:mt-0 mr-0 sm:mr-12" onClick={onSkip}>*/}
                    {/*  Skip this step*/}
                    {/*</div>*/}
                    <PrimaryButton disabled={hasError} onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
                      Next
                    </PrimaryButton>
                  </>
                )}
              />
            );
          }}
        </FormErrors>
      </Overlay>
    </div>
  );
};
