import React from 'react';
import PropTypes from 'prop-types';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import Project from '../../pages/project/Project';
import CloseDialogButton from '../../dialogs_common/CloseDialogButton';
import { useConnect } from '../../../hooks/@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import Banner from './Banner';

QuestionViewDialog.propTypes = {
  question: PropTypes.object.isRequired
};

export default function QuestionViewDialog(props) {
  const { question } = props;

  const project = useConnect('project.byId', {
    id: question.data.projectId
  });

  if (project.state === PayloadStates.FETCHING) {
    return (
      <DialogTemplate className="w-screen max-w-screen-lg">
        <Loader />
      </DialogTemplate>
    );
  }

  return (
    <DialogTemplate className="w-screen max-w-screen-lg">
      <CloseDialogButton onCancel={props.onCancel} />
      <div className="px-6 pt-16 pb-6">
        <div className="space-y-8">
          <Banner
            question={question}
            project={project}
          />
          <Project project={project} />
        </div>
      </div>
    </DialogTemplate>
  );
};
