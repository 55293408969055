import React from 'react';
import PropTypes from 'prop-types';
import ConnectButton from '../../../pages/_banner/ConnectButton';
import Wrapper from '../../../pages/_banner/Wrapper';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../_hooks/useDialogLauncher';

NoBanner.propTypes = {
  event: PropTypes.object.isRequired,
  invitation: PropTypes.object.isRequired
};

export default function NoBanner(props) {
  const { event, invitation, creator } = props;

  const launch = useDialogLauncher();
  const color = 'gray';

  function showYesDialog() {
    launch(DialogManager.InvitationYes.Config(invitation.id));
  }

  function showMaybeDialog() {
    launch(DialogManager.InvitationMaybe.Config(invitation.id));
  }

  return (
    <Wrapper
      color={color}
      creator={creator}
      title={(
        <>
          Changed your mind? Let {creator.data.firstName} know!
        </>
      )}
      subtitle={(
        <>
          You previously said you couldn't attend this event. If things have changed, click
          the button below to offer your help!
        </>
      )}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        <ConnectButton outline={true} onClick={showMaybeDialog}>
          Maybe, I have some questions first
        </ConnectButton>
        <ConnectButton gray={true} onClick={showYesDialog}>
          Yes, I'll attend
        </ConnectButton>
      </div>
    </Wrapper>
  );
};
