import React from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from './ErrorMessage';
import _ from 'lodash';

RequestError.propTypes = {
  request: PropTypes.object
};

export default function RequestError(props) {
  const { request, v2 = false } = props;
  const error = request.error || {};
  let text = '';

  if (_.isPlainObject(error)) {
    text = JSON.stringify(error);
  } else if (_.isArray(error)) {
    text = JSON.stringify(error);
  } else {
    text = error;
  }

  if (error.message) {
    text = error.message;
  }

  return (
    <ErrorMessage v2={v2}>
      <strong>Error!</strong> {text}
    </ErrorMessage>
  );
}
