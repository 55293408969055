import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import validators from '../../../utils/validators';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import SolidButton from '../../../components/Button/SolidButton';
import FormTemplate2 from '../../_post-opportunity/FlowTemplate';
import FormSection from '../../_post-opportunity/forms/FormSection';
import TextAreaField from '../../../forms/_fields_v2/TextAreaField';
import StringField from '../../../forms/_fields_v2/StringField';
import FormField from '../../../forms/_common/FormField';
import { useQueryMutation } from '@lore/query-connect';

Form.propTypes = {
  user: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func,
  onSkip: PropTypes.func
};

const MAX_LENGTH = 512;

export default function Form(props) {
  const {
    user,
    project,
    navigation,
    onNext,
    onPrevious,
    onSkip,
    onExit,
    FormTemplate = FormTemplate2,
    buttonText = 'Next'
  } = props;

  const [data, setData] = useState({
    budget: project.data.budget,
    request: project.data.request || 0
  });
  const [showErrors, setShowErrors] = useState(false);
  const [exit, setExit] = useState(false);

  const mutation = useQueryMutation('project.update', project);

  useEffect(() => {
    if (mutation.isSuccess) {
      exit ? onExit(data) : onNext(data);
    }
  }, [mutation.isSuccess]);

  function onSubmit() {
    mutation.mutate({
      budget: data.budget,
      request: data.request || 0
    })
  }

  function onSubmitAndExit() {
    onSubmit();
    setExit(true);
  }

  function onChange(name, value) {
    setData(_.merge({}, data, {
      [name]: value
    }));
  }

  return (
    <Overlay isVisible={mutation.isLoading}>
      <FormErrors data={data} validators={{
        budget: [validators.maxLength(MAX_LENGTH)],
        request: [validators.isInteger]
      }}>
        {({ errors, hasError }) => {
          return (
            <FormTemplate
              onExit={() => hasError ? setShowErrors(true) : onSubmitAndExit()}
              onPrevious={onPrevious}
              navigation={navigation}
              alert={mutation.isError ? (
                <RequestError request={mutation.error} />
              ) : showErrors ? (
                <ErrorMessage>
                  Please fill in all required fields.
                </ErrorMessage>
              ) : null}
              body={(
                <div className="space-y-10">
                  <FormSection
                    title="Budget"
                    tooltip={(
                      <>
                        <div className="font-semibold text-cs-gray-900 text-base">
                          Example
                        </div>
                        <ul className="list-disc ml-6 text-cs-gray-900 text-base">
                          {[
                            'Artist Partner stipend: $200-300',
                            'Clay materials: $50-100',
                            'Art exhibition supplies: $50',
                            'Food for Celebration: $50'
                          ].map(function(text, index) {
                            return (
                              <li key={index}>
                                {text}
                              </li>
                            );
                          })}
                        </ul>
                      </>
                    )}
                  >
                    <FormField name="budget" data={data} errors={showErrors && errors} onChange={onChange}>
                      {(field) => (
                        <TextAreaField
                          // label={`Desired outcome (${data.desiredOutcome ? data.desiredOutcome.length : 0}/${MAX_LENGTH} characters)`}
                          // explanation="What learning goals do you have for your students that you hope a community partner will be able to support?"
                          description={`${data.budget ? data.budget.length : 0}/${MAX_LENGTH} characters`}
                          rows={10}
                          placeholder="Sample budget..."
                          // description={(
                          //   <div className="text-cs-orange-500 cursor-pointer" onClick={onShowSuggestions}>
                          //     Need suggestions for what to include?
                          //   </div>
                          // )}
                          value={field.value}
                          error={field.error}
                          onChange={field.onChange}
                        />
                      )}
                    </FormField>
                  </FormSection>
                  <FormSection
                    title="Total Budget Request"
                    subtitle="Note: Most mini-grants will be less than $500."
                  >
                    <FormField name="request" data={data} errors={showErrors && errors} onChange={onChange}>
                      {(field) => (
                        <StringField
                          placeholder="Dollar amount..."
                          value={field.value}
                          error={field.error}
                          onChange={field.onChange}
                        />
                      )}
                    </FormField>
                  </FormSection>
                </div>
              )}
              footer={(
                <>
                  <SolidButton onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
                    {buttonText}
                  </SolidButton>
                </>
              )}
            />
          );
        }}
      </FormErrors>
    </Overlay>
  );
};
