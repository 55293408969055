import React from 'react';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import EmptyState from '../../components/EmptyState';
import SectionTitle from '../home/SectionTitle';
import useActiveProjects from './useActiveProjects';
import useActiveEvents from './useActiveEvents';
import useActiveOffers from './useActiveOffers';
import ActiveProject from './ActiveProject';
import ActiveEvent from './ActiveEvent';
import ActiveOffer from './ActiveOffer';

export default function ActiveLeading(props) {
  const title = 'Active';
  const subtitle = (
    <>
      Activities shown here have all required fields provided, and are currently being
      shown to partners in your community (unless you have chosen to explicitly hide it).
    </>
  );

  const projects = useActiveProjects();
  const events = useActiveEvents();
  const offers = useActiveOffers();

  if (
    projects.state === PayloadStates.FETCHING ||
    events.state === PayloadStates.FETCHING ||
    offers.state === PayloadStates.FETCHING
  ) {
    return (
      <div>
        <SectionTitle
          title={title}
          subtitle={subtitle}
        />
        <Loader />
      </div>
    );
  }

  if (
    projects.data.length === 0 &&
    events.data.length === 0 &&
    offers.data.length === 0
  ) {
    // return (
    //   <div>
    //     <SectionTitle
    //       title={title}
    //       subtitle={subtitle}
    //     />
    //     <EmptyState
    //       icon={SparklesIcon}
    //       title="You have no active activities."
    //       subtitle={(
    //         <>
    //           Activities you create will be displayed here while they are actively
    //           being shown to potential collaborators.
    //         </>
    //       )}
    //     />
    //   </div>
    // );
    return null;
  }

  return (
    <div>
      <SectionTitle
        title={title}
        subtitle={subtitle}
      />
      <div className="space-y-4">
        {projects.data.map(function (project) {
          return (
            <ActiveProject
              key={project.id}
              project={project}
            />
          );
        })}
        {events.data.map(function (event) {
          return (
            <ActiveEvent
              key={event.id}
              event={event}
            />
          );
        })}
        {offers.data.map(function (offer) {
          return (
            <ActiveOffer
              key={offer.id}
              offer={offer}
            />
          );
        })}
      </div>
    </div>
  );
}
