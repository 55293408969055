import React from 'react';
import { useUser } from '@lore/auth';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import { SolidButton } from '../../../components/Button';
import GrayOutlineButton from '../../../components/Button/OutlineButton.gray';
import DividerCard from '../../../components/DividerCard';
import ComposableEventHeader from '../../../components_cards/ComposableEventHeader';
import TagRow from '../../../components_cards/tags/TagRow';
import EventTag from '../../../components_cards/tags/EventTag';
import ProjectTag from '../../../components_cards/tags/ProjectTag';
import CreatorTag from '../../../components_cards/tags/CreatorTag';
import ExpiredTag from '../../../components_cards/tags/_resource/ExpiredTag';
import Invitations from '../../my-past/EventCreated/Invitations';

export default function ExpiredEvent(props) {
  const { event } = props;

  const currentUser = useUser();
  const launch = useDialogLauncher();

  const to = currentUser.id === event.data.creatorId ? `/manage` : `/view`;

  function onClickManage() {
    launch(DialogManager.EventManage.Config(event.id, to));
  }

  function onClick() {
    launch(DialogManager.EventCloseExtend.Config(event.id));
  }

  return (
    <DividerCard>
      <ComposableEventHeader
        event={event}
        tags={(
          <>
            <TagRow>
              <EventTag />
              <CreatorTag />
              <ExpiredTag resource={event} />
            </TagRow>
            <Invitations event={event} />
          </>
        )}
        showTags={false}
        showCreator={false}
      />
      <div className="w-full bg-cs-gray-50 py-4 px-4 flex justify-between items-center">
        <div/>
        <div className="flex flex-wrap gap-2">
          <GrayOutlineButton
            onClick={onClickManage}
            label="Manage Event"
          />
          <SolidButton
            onClick={onClick}
            label="Close or Extend Date"
          />
        </div>
      </div>
    </DividerCard>
  );
}
