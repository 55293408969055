import React from 'react';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

export default function InfoCard(props) {
  const { to, icon, title, subtitle, actionText = 'View'} = props;

  if (to) {
    return (
      <Link
        to={to}
        className={classNames(
          'flex bg-cs-blue-50 border border-cs-blue-100 rounded-lg py-4 px-6 space-x-4',
          'shadow-cs-flat', // shadow-md
          'hover:bg-cs-blue-100 cursor-pointer'
        )}
      >
        <div className="mt-1">
          <props.icon className="h-10 w-10 text-cs-blue-500" />
        </div>
        <div className="flex-1 max-w-screen-mdx">
          <div className="text-lg font-semibold text-cs-gray-900">
            {title}
          </div>
          <div className="text-base text-cs-gray-500 mb-1">
            {subtitle}
          </div>
          <div className="flex items-center text-cs-blue-500 text-base font-semibold space-x-1">
            <span>{actionText}</span>
            <ArrowRightIcon className="h-4 w-4 text-cs-blue-500 stroke-2"/>
          </div>
        </div>
      </Link>
    );
  }

  return (
    <div
      className={classNames(
        'flex bg-cs-blue-50 border border-cs-blue-100 rounded-lg py-4 px-6 space-x-4',
        'shadow-cs-flat', // shadow-md
        // 'hover:bg-cs-blue-100 cursor-pointer'
      )}
    >
      {icon && (
        <div className="mt-1">
          <props.icon className="h-10 w-10 text-cs-blue-500" />
        </div>
      )}
      <div className="flex-1 max-w-screen-mdx">
        <div className="text-lg font-semibold text-cs-gray-900">
          {title}
        </div>
        <div className="text-base text-cs-gray-500 mb-1">
          {subtitle}
        </div>
        {/*<div className="flex items-center text-cs-blue-500 text-base font-semibold space-x-1">*/}
        {/*  <span>{actionText}</span>*/}
        {/*  <ArrowRightIcon className="h-4 w-4 text-cs-blue-500 stroke-2"/>*/}
        {/*</div>*/}
      </div>
    </div>
  );
}
