import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import CheckboxField from '../../../forms/_fields_v2/CheckboxField';

Entries.propTypes = {
  practices: PropTypes.object.isRequired,
  userPractices: PropTypes.object.isRequired
};

export default function Entries(props) {
  const {
    practices,
    userPractices,
    onSelectPractice
  } = props;

  function renderPractices(practices) {
    return practices.map(function(practice) {
      const selectedPractice = _.find(userPractices.data, function(userPractice) {
        return userPractice._tag.id === practice.id;
      });

      const selected = !!selectedPractice;

      return (
        <CheckboxField
          key={practice.id}
          label={practice.data.name}
          description={practice.data.description}
          checked={selected}
          onChange={() => onSelectPractice(practice, selectedPractice)}
        />
      );
    });
  }

  return (
    <div className="w-full">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-12 gap-y-3">
        <div className="space-y-3">
          {renderPractices(
            practices.data.slice(0, Math.floor(practices.data.length/2))
          )}
        </div>
        <div className="space-y-3">
          {renderPractices(
            practices.data.slice(Math.floor(practices.data.length/2))
          )}
        </div>
      </div>
    </div>
  );
}
