import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import validators from '../../../utils/validators';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import FormTemplate from '../../../dialogs_common/SetupTemplate';
import FormField from '../../../forms/_common/FormField';
import StringField from '../../../forms/_fields_v2/StringField';
import PrimaryButton from '../../../components_setup/PrimarySetupButton';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';

Form.propTypes = {
  user: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func,
  onSkip: PropTypes.func.isRequired
};

export default function Form(props) {
  const {
    user,
    navigation,
    onNext,
    onPrevious,
    onSkip
  } = props;

  const [data, setData] = useState(_.pick(user.data, [
    'firstName',
    'lastName',
    'phoneNumber'
  ]));
  const [showErrors, setShowErrors] = useState(false);

  const mutation = useQueryMutation('currentUser.updateSingleton', user);
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      onNext(data);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate(data);
  }

  function onChange(name, value) {
    setData(_.merge({}, data, {
      [name]: value
    }));
  }

  return (
    <div className="my-auto">
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          firstName: [validators.isRequired],
          lastName: [validators.isRequired]
        }}>
          {({ errors, hasError }) => {
            return (
              <FormTemplate
                onPrevious={onPrevious}
                navigation={navigation}
                // title={(
                //   <>
                //     <div>
                //       Great! Now let's set up your account.
                //     </div>
                //     <div>
                //       What's your name?
                //     </div>
                //   </>
                // )}
                // subtitle="Locations you create are visible only to you"
                alert={error ? (
                  <RequestError request={request} v2={true} />
                ) : showErrors ? (
                  <ErrorMessage v2={true}>
                    Please fill in all required fields.
                  </ErrorMessage>
                ) : null}
                body={(
                  <>
                    <div className="flex flex-col mb-12">
                      <div className="mb-8">
                        <h5 className="text-3xl text-cs-gray-900 font-semibold text-left">
                          <div>
                            Great! Now let's set up your account.
                          </div>
                          <div>
                            What's your name?
                          </div>
                        </h5>
                      </div>
                      <div className="w-full sm:w-80 mb-6">
                        <FormField name="firstName" data={data} errors={showErrors && errors} onChange={onChange}>
                          {(field) => (
                            <StringField
                              label="First name"
                              required={true}
                              value={field.value}
                              error={field.error}
                              onChange={field.onChange}
                            />
                          )}
                        </FormField>
                      </div>
                      <div className="w-full sm:w-80 mb-6">
                        <FormField name="lastName" data={data} errors={showErrors && errors} onChange={onChange}>
                          {(field) => (
                            <StringField
                              label="Last name"
                              required={true}
                              value={field.value}
                              error={field.error}
                              onChange={field.onChange}
                            />
                          )}
                        </FormField>
                      </div>
                      <div className="w-full sm:w-80">
                        <FormField name="phoneNumber" data={data} errors={showErrors && errors} onChange={onChange}>
                          {(field) => (
                            <StringField
                              label="Phone"
                              placeholder="(000) 000-0000"
                              description="We'll never display your phone number on your public profile. It will only be used to help contact you in case of a cancellation or time-sensitive issue related to an activity you've created or joined."
                              value={field.value}
                              error={field.error}
                              onChange={field.onChange}
                            />
                          )}
                        </FormField>
                      </div>
                    </div>
                    {/*<div className="flex flex-col">*/}
                    {/*  <div className="mb-8">*/}
                    {/*    <h5 className="text-3xl text-cs-gray-900 font-semibold text-left">*/}
                    {/*      How can we reach you?*/}
                    {/*    </h5>*/}
                    {/*  </div>*/}
                    {/*  <div className="w-full sm:w-96 mb-6">*/}
                    {/*    <FormField name="email" data={data} errors={showErrors && errors} onChange={onChange}>*/}
                    {/*      {(field) => (*/}
                    {/*        <StringField*/}
                    {/*          label="Email"*/}
                    {/*          required={true}*/}
                    {/*          disabled={true}*/}
                    {/*          description={(*/}
                    {/*            <span>*/}
                    {/*            This is the email address associated with your account. To change it,*/}
                    {/*            please email <a href="mailto:support@communityshare.org">support@communityshare.org</a>.*/}
                    {/*          </span>*/}
                    {/*          )}*/}
                    {/*          value={user.data.email}*/}
                    {/*          error={field.error}*/}
                    {/*          onChange={field.onChange}*/}
                    {/*        />*/}
                    {/*      )}*/}
                    {/*    </FormField>*/}
                    {/*  </div>*/}
                    {/*  <div className="w-full sm:w-96">*/}
                    {/*    <FormField name="phoneNumber" data={data} errors={showErrors && errors} onChange={onChange}>*/}
                    {/*      {(field) => (*/}
                    {/*        <StringField*/}
                    {/*          label="Phone"*/}
                    {/*          placeholder="(000) 000-0000"*/}
                    {/*          description="We'll never display your phone number on your public profile. It will only be used to help contact you in case of a cancellation or time-sensitive issue related to an activity you've created or joined."*/}
                    {/*          value={field.value}*/}
                    {/*          error={field.error}*/}
                    {/*          onChange={field.onChange}*/}
                    {/*        />*/}
                    {/*      )}*/}
                    {/*    </FormField>*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                  </>
                )}
                footer={(
                  <>
                    {/*<div className="text-cs-orange-500 underline cursor-pointer mt-6 sm:mt-0 mr-0 sm:mr-12" onClick={onSkip}>*/}
                    {/*  Skip this step*/}
                    {/*</div>*/}
                    <PrimaryButton disabled={hasError} onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
                      Next
                    </PrimaryButton>
                  </>
                )}
              />
            );
          }}
        </FormErrors>
      </Overlay>
    </div>
  );
};
