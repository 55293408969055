import React from 'react';
import { getTimestampDiff } from '../../utils/dateTimeUtils';
import { SolidButton } from '../../components/Button';

export default function PendingEvaluationButton(props) {
  const { evaluation, onView, onEdit } = props;

  const diff = getTimestampDiff(evaluation.data.updatedAt, evaluation.data.createdAt);

  if (diff > 0) {
    return (
      <SolidButton
        label="Finish sharing"
        onClick={onEdit}
      />
    );
  }

  return (
    <SolidButton
      label="Start now"
      onClick={onEdit}
    />
  );
}
