import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import validators from '../../../utils/validators';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import FormField from '../../../forms/_common/FormField';
import SolidButton from '../../../components/Button/SolidButton';
import FormTemplate from '../../_post-opportunity/FlowTemplate';
import FormSection from '../../_post-opportunity/forms/FormSection';
import StringField from '../../../forms/_fields_v2/StringField';
import RadioButtonField from '../../_post-opportunity/_fields/RadioButtonField.cards';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';

Form.propTypes = {
  user: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func.isRequired,
  onSkip: PropTypes.func.isRequired
};

// const MAX_LENGTH = 1000;

export default function Form(props) {
  const {
    user,
    navigation,
    onNext,
    onPrevious,
    onSkip,
    onExit,
    evaluation
  } = props;

  const [data, setData] = useState({
    contributed: evaluation.data.contributed,
    contribution: evaluation.data.contribution,
    contributionValue: evaluation.data.contributionValue || 0
  });
  const [showErrors, setShowErrors] = useState(false);
  const [exit, setExit] = useState(false);

  const mutation = useQueryMutation('evaluationProjectPartner.update', evaluation);
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      exit ? onExit(data) : onNext(data);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate({
      contributed: data.contributed,
      contribution: data.contribution,
      contributionValue: data.contributionValue || 0
    });
  }

  function onSubmitAndExit() {
    onSubmit();
    setExit(true);
  }

  function onChange(name, value) {
    setData(_.merge({}, data, {
      [name]: value
    }));
  }

  return (
    <Overlay isVisible={saving}>
      <FormErrors data={data} validators={{
        contributionValue: [validators.isNumber]
      }}>
        {({ errors, hasError }) => {
          return (
            <FormTemplate
              onExit={() => hasError ? setShowErrors(true) : onSubmitAndExit()}
              onPrevious={onPrevious}
              navigation={navigation}
              alert={error ? (
                <RequestError request={request} />
              ) : showErrors ? (
                <ErrorMessage>
                  Please fill in all required fields.
                </ErrorMessage>
              ) : null}
              body={(
                <div className="space-y-10">
                  <FormSection
                    // title="Did you contribute any supplies, materials, or items to the educator or students to complete the project?"
                    // subtitle="Examples include: samples, lumber, models, art supplies, etc."
                  >
                    <FormField name="contributed" data={data} errors={showErrors && errors} onChange={onChange}>
                      {(field) => (
                        <>
                          <RadioButtonField
                            name="contributed"
                            // label="Age group..."
                            value={field.value}
                            error={field.error}
                            placeholder="Select..."
                            options={{
                              data: [
                                'Yes',
                                'No'
                              ].map((option) => {
                                return {
                                  id: option,
                                  data: {
                                    name: option
                                  }
                                }
                              })
                            }}
                            optionLabel="name"
                            // onChange={(value) => field.onChange(options.workingWith[value-1][1])}
                            onChange={(value) => field.onChange(value)}
                          />
                        </>
                      )}
                    </FormField>
                  </FormSection>
                  {data.contributed === 'Yes' && (
                    <>
                      <FormSection
                        title="Thank you! What supplies did you contribute?"
                      >
                        <FormField name="contribution" data={data} errors={showErrors && errors} onChange={onChange}>
                          {(field) => (
                            <>
                              <StringField
                                // label="Thank you! What supplies or materials did you contribute?"
                                value={field.value}
                                error={field.error}
                                placeholder="Describe supplies..."
                                onChange={(value) => field.onChange(value)}
                              />
                            </>
                          )}
                        </FormField>
                      </FormSection>
                      <FormSection
                        title="What is the approximate monetary value of your contribution (in dollars)?"
                      >
                        <FormField name="contributionValue" data={data} errors={showErrors && errors} onChange={onChange}>
                          {(field) => (
                            <>
                              <StringField
                                // label="What is the approximate monetary value of your contribution (in dollars)?"
                                value={field.value}
                                error={field.error}
                                placeholder="Enter monetary value..."
                                onChange={(value) => field.onChange(value)}
                              />
                            </>
                          )}
                        </FormField>
                      </FormSection>
                    </>
                  )}
                </div>
              )}
              footer={(
                <>
                  <SolidButton onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
                    Next
                  </SolidButton>
                </>
              )}
            />
          );
        }}
      </FormErrors>
    </Overlay>
  );
};
