import React from 'react';
import classNames from 'classnames';

export default function ActionBarButton(props) {
  const { label, icon, isActive = false, onClick } = props;

  return (
    <div
      onClick={onClick}
      className={classNames(
        'flex space-x-2 items-center justify-center basis-full',
        'px-4 py-3',
        'cursor-pointer',
        'rounded-full',
        'text-sm',
        isActive ? 'text-white bg-cs-blue-500' : 'text-cs-gray-500 bg-white hover:bg-cs-gray-50', // background, dark, blue

        // isActive ? 'text-cs-blue-500 bg-cs-blue-50' : 'text-cs-gray-500 bg-white hover:bg-cs-gray-50', // background, light, blue
        // isActive ? '-m-px border border-cs-blue-500': 'border-cs-gray-100', // border, blue

        // isActive ? 'text-white bg-cs-orange-500' : 'text-cs-gray-500 bg-white hover:bg-cs-gray-50', // background, dark, orange

        // isActive ? 'text-cs-orange-500 bg-cs-orange-50' : 'text-cs-gray-500 bg-white hover:bg-cs-gray-50', // background, light, orange
        // isActive ? '-m-px border border-cs-orange-500': 'border-cs-gray-100', // border, orange
      )}
    >
      {icon && (
        <props.icon className="h-5 w-5" />
      )}
      <span className="flex-1x">
        {label}
      </span>
    </div>
  );
};
