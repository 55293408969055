import React from 'react';
import Avatar from '../../components/Avatar';
import classNames from 'classnames';
import UserRole from '../people/__common/UserRole.v2';
import { InformationCircleIcon } from '@heroicons/react/24/outline';

export default function PlaceholderMessage(props) {
  const { user, className } = props;

  return (
    <div className={classNames(
      'flex space-x-4',
      className
    )}>
      <div className="hidden sm:block">
        <div className="flex flex-col space-y-2">
          <Avatar
            size={12}
            src={user.data.avatarUrl}
          />
        </div>
      </div>
      <div className="flex-1">
        <div className="mb-2">
          <div className="flex items-center justify-between space-x-2">
            <div className="text-xl font-semibold text-cs-gray-900">
              {user.data.firstName} {user.data.lastName}
            </div>
          </div>
          <div className="text-sm text-cs-gray-500 h-5 line-clamp-1">
            <UserRole user={user} />
          </div>
        </div>
        <div className="border-2 border-cs-orange-500 rounded-lg bg-cs-orange-50 py-4 px-4">
          <div className="flex items-start space-x-2">
            <InformationCircleIcon className="text-cs-orange-500 h-6 w-6" />
            <span className="flex-1">
              You have no conversation history with {user.data.firstName}. Send a message to get started.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
