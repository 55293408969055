import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import LoadingBanner from '../../../pages/_banner/LoadingBanner';
import InvitationBanner from '../../invitation_manage/Banner';
import CreatorBanner from './CreatorBanner';
import DiscoverBanner from './DiscoverBanner';
import CancelledBanner from './CancelledBanner';
import CompletedBanner from './CompletedBanner';

Banner.propTypes = {
  event: PropTypes.object.isRequired
};

export default function Banner(props) {
  const { event } = props;

  const currentUser = useUser();

  const creator = useConnect('user.byId', {
    id: event.data.creatorId
  });

  const activity = useConnect('tag.byId', {
    id: event.data.activityId
  });

  const invitation = useConnect('invitation.first', {
    where: {
      eager: {
        $where: {
          eventId: event.id,
          userId: currentUser.id
        }
      }
    }
  });

  if (
    creator.state === PayloadStates.FETCHING ||
    activity.state === PayloadStates.FETCHING ||
    invitation.state === PayloadStates.FETCHING
  ) {
    return (
      <LoadingBanner creator={creator} />
    );
  }

  const isCreator = currentUser.id === event.data.creatorId;

  // return (
  //   <>
  //     <CreatorBanner
  //       event={event}
  //       creator={creator}
  //     />
  //     <DiscoverBanner
  //       event={event}
  //       creator={creator}
  //     />
  //     <InvitationBanner
  //       invitation={invitation}
  //       event={event}
  //       creator={creator}
  //     />
  //     <CancelledBanner
  //       event={event}
  //       creator={creator}
  //     />
  //     <CompletedBanner
  //       event={event}
  //       creator={creator}
  //     />
  //   </>
  // );

  if (isCreator) {
    return (
      <CreatorBanner
        event={event}
        creator={creator}
      />
    );
  }

  if (invitation.state === PayloadStates.RESOLVED) {
    return (
      <InvitationBanner
        invitation={invitation}
        event={event}
      />
    );
  }

  if (event.data.completed) {
    return (
      <CompletedBanner
        event={event}
        creator={creator}
      />
    );
  }

  if (event.data.cancelled) {
    return (
      <CancelledBanner
        event={event}
        creator={creator}
      />
    );
  }

  return (
    <DiscoverBanner
      event={event}
      creator={creator}
    />
  );
}
