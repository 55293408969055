import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';

UserRole.propTypes = {
  user: PropTypes.object.isRequired
};

export default function UserRole(props) {
  const { user } = props;

  const params = {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    },
    pagination: {
      page: 1,
      pageSize: 100,
      order: 'createdAt asc'
    }
  };

  const roles = useConnect('role.find', params);
  const jobs = useConnect('job.find', params);

  if (
    roles.state === PayloadStates.FETCHING ||
    jobs.state === PayloadStates.FETCHING
  ) {
    return '...';
  }

  const role = roles.data[0];
  const job = jobs.data[0];

  return (
    role?.data.role ||
    job?.data.role ||
    null
  );
}
