import React from 'react';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useDialogLauncher from '../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../dialogs_new/DialogManager';
import InvitationsYes from './InvitationsYes';
import InvitationsMaybe from './InvitationsMaybe';
import InvitationsInvited from './InvitationsInvited';
import InvitationsNo from './InvitationsNo';
import { useConnect } from '../../../hooks/@lore/query-connect';
import EmptyState from '../../components/EmptyState';
import { EnvelopeIcon } from '@heroicons/react/24/outline';

export default function EventManageDialogLayout(props) {
  const { event } = props;

  const launch = useDialogLauncher();

  const invitations = useConnect('invitation.find', {
    where: {
      eager: {
        $where: {
          eventId: event.id
        }
      }
    }
  });

  function onClick(user) {
    launch(DialogManager.Messages.Config(user.id));
  }

  if (
    event.state === PayloadStates.FETCHING ||
    invitations.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  if (invitations.data.length === 0) {
    return (
      <EmptyState
        icon={EnvelopeIcon}
        title="No partners were invited."
        subtitle="If they had been, this page woud provide a summary of who responded."
      />
    );
  }

  return (
    <>
      <InvitationsYes
        event={event}
        onViewMessages={onClick}
      />
      <InvitationsMaybe
        event={event}
        onViewMessages={onClick}
      />
      <InvitationsInvited
        event={event}
        onViewMessages={onClick}
      />
      <InvitationsNo
        event={event}
        onViewMessages={onClick}
      />
    </>
  );
};
