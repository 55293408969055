import React from 'react';
import PropTypes from 'prop-types';
import ConnectButton from '../../../pages/_banner/ConnectButton';
import Wrapper from '../../../pages/_banner/Wrapper';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../_hooks/useDialogLauncher';
import usePageRouter from '../../_hooks/usePageRouter';

CancelledBanner.propTypes = {
  project: PropTypes.object.isRequired,
  question: PropTypes.object.isRequired
};

export default function CancelledBanner(props) {
  const { project, question, creator } = props;

  const launch = useDialogLauncher();
  const { history } = usePageRouter();
  const color = 'gray';

  function onViewMessages() {
    launch(DialogManager.Messages.Config(creator.id, {
      projectId: question.data.projectId
    }));
  }

  return (
    <Wrapper
      color={color}
      creator={creator}
      title={(
        <>
          This project was cancelled.
        </>
      )}
      subtitle={(
        <>
          Project cancelled? It happens sometimes. Get back out there and start exploring
          opportunities to collaborate and make an impact.
        </>
      )}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        <ConnectButton outline={true} color={color} onClick={onViewMessages}>
          View messages
        </ConnectButton>
        <ConnectButton color={color} onClick={() => history.push(`/opportunities`)}>
          Explore opportunities
        </ConnectButton>
      </div>
    </Wrapper>
  );
};
