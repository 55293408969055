import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import _ from 'lodash';
import { PayloadStates } from '@lore/utils';
import { useUser } from '@lore/auth';
import Bookmark from './Bookmark';
import { useQueryMutation } from '../../hooks/@lore/query-connect';

UserBookmark.propTypes = {
  user: PropTypes.object.isRequired
};

export default function UserBookmark(props) {
  const { user } = props;

  const currentUser = useUser();

  const mutation_create = useQueryMutation('bookmark.create');
  const mutation_destroy = useQueryMutation('bookmark.destroy2');

  const userBookmarks = useConnect('bookmark.find', {
    where: {
      eager: {
        $where: {
          creatorId: currentUser.id,
          userId: user.id
        }
      }
    }
  });

  const userBookmark = _.find(userBookmarks.data, function(userBookmark) {
    return (
      userBookmark.data.creatorId === currentUser.id &&
      userBookmark.data.userId === user.id &&
      userBookmark.state !== PayloadStates.DELETED
    );
  }) || {
    state: PayloadStates.NOT_FOUND
  };

  function onCreateBookmark() {
    mutation_create.mutate({
      creatorId: currentUser.id,
      userId: user.id
    }, {
      onSuccess: payload => userBookmarks._add(payload)
    });
  }

  function onDestroyBookmark() {
    mutation_destroy.mutate(userBookmark, {
      onSuccess: payload => userBookmarks._remove(payload)
    });
  }

  return (
    <PureUserBookmark
      userBookmark={userBookmark}
      onBookmark={onCreateBookmark}
      onRemoveBookmark={onDestroyBookmark}
    />
  );
};

PureUserBookmark.propTypes = {
  userBookmark: PropTypes.object,
  onBookmark: PropTypes.func.isRequired,
  onRemoveBookmark: PropTypes.func.isRequired
};

export function PureUserBookmark(props) {
  const {
    userBookmark,
    onBookmark,
    onRemoveBookmark
  } = props;

  if (userBookmark.state === PayloadStates.FETCHING) {
    return (
      <Bookmark
        className="a-bookmark--fetching"
        tooltip="Fetching status..."
      />
    );
  }

  if (
    userBookmark.state === PayloadStates.NOT_FOUND ||
    userBookmark.state === PayloadStates.DELETED
  ) {
    return (
      <Bookmark
        onClick={onBookmark}
        tooltip="Bookmark person"
      />
    );
  }

  return (
    <Bookmark
      selected={true}
      onClick={onRemoveBookmark}
      tooltip="Remove bookmark"
    />
  );
}
