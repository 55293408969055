import React from 'react';
import { GlobeAltIcon } from '@heroicons/react/24/outline';
import useRouter from '../../hooks/useRouter';
import Urls from '../offer-create/Urls';
import DataCard from '../profile-edit/DataCard';
import usePageRouter from '../../dialogs-routable/_hooks/usePageRouter';

export default function Website(props) {
  const { offer } = props;
  const { history } = usePageRouter();

  return (
    <DataCard
      title="Website"
      icon={GlobeAltIcon}
      subtitle={offer.data.website || '(not provided)'}
      onClick={() => history.push(Urls(offer).WEBSITE)}
    />
  );
};
