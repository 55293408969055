import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Overlay from '../../forms/_common/Overlay';
import FormErrors from '../../forms/_common/FormErrors';
import RequestError from '../../forms/_common/RequestError';
import ErrorMessage from '../../forms/_common/ErrorMessage';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import FormTemplate from '../../dialogs_common/FormTemplate';
import Loader from '../../components/Loader';
import { useQueryMutation } from '../../../hooks/@lore/query-connect';

Step1.propTypes = {
  data: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired
};

export default function Step1(props) {
  const {
    data: _data,
    onNext,
    onCancel,
    project
  } = props;

  const { activity } = _data;

  const [data, setData] = useState(_.pick(_data, [
    // 'question1'
  ]));
  const [showErrors, setShowErrors] = useState(false);

  const mutation = useQueryMutation('project.create');
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      onNext(request);
    }
  }, [success]);

  useEffect(function() {
    mutation.mutate({
      ..._.omit(project.data, [
        'id',
        'title',
        'date',
        'startDate',
        'endDate',
        'published',
        'completed',
        'cancelled',
        'cancelledAt',
        'cancelledMessage',
        'createdAt',
        'updatedAt',
      ]),
      title: `${project.data.title || activity.data.name}`
    });
  }, []);

  return (
    <DialogTemplate>
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          // question1: [validators.isRequired]
        }}>
          {({ errors, hasError }) => {
            return (
              <FormTemplate
                title="Duplicating Project"
                subtitle={(
                  <span>
                    Creating new project...
                  </span>
                )}
                onClose={onCancel}
                alert={error ? (
                  <RequestError request={request} />
                ) : showErrors ? (
                  <ErrorMessage>
                    Please fill in all required fields.
                  </ErrorMessage>
                ) : null}
                body={saving && (
                  <div className="flex pb-12">
                    <Loader />
                  </div>
                )}
                // footer={(
                //   <FormTemplateButtonFooter>
                //     <OutlineButton onClick={onCancel}>
                //       Cancel
                //     </OutlineButton>
                //     <PrimaryButton onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
                //       Next
                //     </PrimaryButton>
                //   </>
                // )}
              />
            );
          }}
        </FormErrors>
      </Overlay>
    </DialogTemplate>
  );
};
