import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Overlay from '../../forms/_common/Overlay';
import FormErrors from '../../forms/_common/FormErrors';
import RequestError from '../../forms/_common/RequestError';
import ErrorMessage from '../../forms/_common/ErrorMessage';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import FormTemplate from '../../dialogs_common/FormTemplate';
import StringField from '../../forms/_fields_v2/StringField';
import FormField from '../../forms/_common/FormField';
import { EnvelopeIcon } from '@heroicons/react/24/outline';
import { OutlineButton, SolidButton } from '../../components/Button';
import validators from '../../utils/validators';
import { useQueryMutation } from '../../../hooks/@lore/query-connect';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';

Start.propTypes = {
  data: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired
};

export default function Start(props) {
  const {
    data: _data,
    onNext,
    onCancel,
    user
  } = props;

  const [data, setData] = useState(_.pick(_data, [
    'email'
  ]));
  const [showErrors, setShowErrors] = useState(false);

  const mutation = useQueryMutation('emailChangeRequest.create');
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      onNext(request);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate({
      userId: user.id,
      email: data.email
    });
  }

  function onChange(name, value) {
    setData(_.merge({}, data, {
      [name]: value
    }));
  }

  return (
    <DialogTemplate>
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          email: [
            validators.isRequired,
            validators.isEmail
          ]
        }}>
          {({ errors, hasError }) => {
            return (
              <FormTemplate
                icon={(
                  <div className="flex justify-center mb-4">
                    <EnvelopeIcon className="text-cs-orange-500 h-12 w-12" />
                  </div>
                )}
                title="Change Email"
                subtitle="Enter the email address you would like to use for your account."
                onClose={onCancel}
                alert={error ? (
                  <RequestError request={request} />
                ) : showErrors ? (
                  <ErrorMessage>
                    Please fill in all required fields.
                  </ErrorMessage>
                ) : null}
                body={(
                  <FormField name="email" data={data} errors={showErrors && errors} onChange={onChange}>
                    {(field) => (
                      <StringField
                        // label="New Email"
                        placeholder="name@example.com"
                        value={field.value}
                        error={field.error}
                        onChange={field.onChange}
                      />
                    )}
                  </FormField>
                )}
                footer={(
                  <FormTemplateButtonFooter>
                    <OutlineButton onClick={onCancel}>
                      Cancel
                    </OutlineButton>
                    <SolidButton onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
                      Next
                    </SolidButton>
                  </FormTemplateButtonFooter>
                )}
              />
            );
          }}
        </FormErrors>
      </Overlay>
    </DialogTemplate>
  );
};
