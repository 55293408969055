import React, { useState } from 'react';
import _ from 'lodash';
import validators from '../../utils/validators';
import Overlay from '../../forms/_common/Overlay';
import FormErrors from '../../forms/_common/FormErrors';
import ErrorMessage from '../../forms/_common/ErrorMessage';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import Form from './Form';
import usePageRouter from '../../dialogs-routable/_hooks/usePageRouter';

export default function ProjectEventSelector(props) {
  const { history } = usePageRouter();

  const [data, setData] = useState({
    type: ''
  });
  const [showErrors, setShowErrors] = useState(false);

  function onSubmit() {
    if (data.type === 'fixed') {
      props.onCancel();
      history.push('/post-event');
    }

    if (data.type === 'flexible') {
      props.onCancel();
      history.push('/post-project');
    }
  }

  function onChange(name, value) {
    const nextData = _.merge({}, data);
    nextData[name] = value;
    setData(nextData);
  }

  return (
    <DialogTemplate className="max-w-screen-sm">
      <Overlay isVisible={false}>
        <FormErrors data={data} validators={{
          type: [validators.isRequired]
        }}>
          {({errors, hasError}) => {
            return (
              <Form
                data={data}
                errors={errors}
                showErrors={showErrors}
                onChange={onChange}
                onSubmit={() => hasError ? setShowErrors(true) : onSubmit()}
                onCancel={props.onCancel}
                alert={showErrors ? (
                  <ErrorMessage>
                    Please fill in all required fields.
                  </ErrorMessage>
                ) : null}
              />
            )
          }}
        </FormErrors>
      </Overlay>
    </DialogTemplate>
  );
};
