import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { PayloadStates } from '@lore/utils';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import Loader from '../../components/Loader';
import useUserProfileMissingFields from '../../hooks/useUserProfileMissingFields';
import Navigation from '../../dialogs_common/Navigation';
import Blocker from '../../dialogs_common/Blocker';
import Name from './Name';
import ZipCode from './ZipCode';
import Urls from './Urls';
import Skills from './Skills';
import Confirmation from './Confirmation';
import useTags from '../../hooks/useTags';
import TagTypes from '../../constants/TagTypes';
import useUserTags from '../../hooks/useUserTags';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline';

ProfileMissingDialog.propTypes = {
  user: PropTypes.object.isRequired
};

export default function ProfileMissingDialog(props) {
  const { user, title = '', subtitle = '', initialMissingFields } = props;
  // const missingFields = useUserProfileMissingFields();

  const [missingFields] = useState(initialMissingFields);

  const expertiseTypes = useTags(TagTypes.ExpertiseType);
  const userExpertises = useUserTags(user, TagTypes.Expertise);

  const [activeStep, setActiveStep] = useState(0);

  function getSteps() {
    const result= [];

    result.push(Urls.INTRODUCTION);

    if (
        _.find(missingFields.profile, { field: 'firstName'}) ||
        _.find(missingFields.profile, { field: 'lastName'})
    ) {
      result.push(Urls.NAME);
    }

    if (_.find(missingFields.profile, { field: 'zipCode'})) {
      result.push(Urls.ZIP_CODE);
    }

    if (_.find(missingFields.interests, { field: 'expertises'})) {
      result.push(Urls.EXPERTISE_AREAS);
    }

    result.push(Urls.CONFIRMATION);

    return result;
  }

  if (
    !missingFields ||
    expertiseTypes.state === PayloadStates.FETCHING ||
    userExpertises.state === PayloadStates.FETCHING
  ) {
    return (
      <DialogTemplate>
        <Loader />
      </DialogTemplate>
    );
  }

  const steps = getSteps();

  const onPrevious = activeStep === 0 ? undefined : () => setActiveStep(activeStep - 1);
  const onNext = activeStep === steps.length - 1 ? props.onCancel : () => setActiveStep(activeStep + 1);
  const onSkip = activeStep === steps.length - 1 ? props.onCancel : () => setActiveStep(activeStep + 1);

  const navigation = (
    <div className="flex items-center justify-between">
      <div className="p-4 -m-4 cursor-pointer hover:opacity-60" onClick={onPrevious}>
        <ArrowLeftIcon className="w-6 h-6 text-cs-orange-500 stroke-2" />
      </div>
      <Navigation v2={false} activeStep={activeStep} steps={steps.length - 2} />
      <div className="p-4 -m-4 cursor-pointer  hover:opacity-60 invisible" onClick={onNext}>
        <ArrowRightIcon className="w-6 h-6 text-cs-orange-500 stroke-2" />
      </div>
    </div>
  );

  if (steps[activeStep] === Urls.INTRODUCTION) {
    return (
      <DialogTemplate>
        <Blocker
          title={title || `Looks like you want to send a message`}
          subtitle={subtitle || `You need to fully complete your profile before you can send messages.`}
          onSubmit={onNext}
          onCancel={props.onCancel}
        />
      </DialogTemplate>
    );
  }

  if (steps[activeStep] === Urls.NAME) {
    return (
      <Name
        user={user}
        navigation={navigation}
        onPrevious={onPrevious}
        onNext={onNext}
        onSkip={onSkip}
      />
    );
  }

  if (steps[activeStep] === Urls.ZIP_CODE) {
    return (
      <ZipCode
        user={user}
        navigation={navigation}
        onPrevious={onPrevious}
        onNext={onNext}
        onSkip={onSkip}
      />
    );
  }

  if (steps[activeStep] === Urls.EXPERTISE_AREAS) {
    return (
      <Skills
        user={user}
        expertiseTypes={expertiseTypes}
        userExpertises={userExpertises}
        navigation={navigation}
        onPrevious={onPrevious}
        onNext={onNext}
        onSkip={onSkip}
      />
    );
  }

  if (steps[activeStep] === Urls.CONFIRMATION) {
    return (
      <Confirmation
        user={user}
        navigation={navigation}
        onPrevious={onPrevious}
        onNext={onNext}
        onSkip={onSkip}
      />
    );
  }

  return null;
};
