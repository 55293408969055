import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import Text from '../../typography';
import Tooltip from '../../pages/_post-opportunity/forms/Tooltip';
import classNames from 'classnames';

SelectField.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  options: PropTypes.object.isRequired,
  label: PropTypes.node,
  optionLabel: PropTypes.string,
  placeholder: PropTypes.string,
  description: PropTypes.node,
  error: PropTypes.node,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

SelectField.defaultProps = {
  options: {
    data: []
  },
  placeholder: '',
  disabled: false
};

export default function SelectField(props) {
  const {
    value,
    options,
    label,
    optionLabel,
    placeholder,
    description,
    error,
    disabled,
    onChange,
    optional = false,
    required = false,
    tooltip,
    icon
  } = props;

  const emptyOption = (
    <Listbox.Option
      key=""
      className={({ active }) => classNames(
        'relative cursor-default select-none py-2 pl-10 pr-4',
        'text-base',
        active ? 'bg-cs-orange-100 text-cs-orange-500' : 'text-cs-gray-900'
      )}
      value=""
    >
      {({ selected }) => (
        <span className={classNames(
          'block truncate',
          'font-normal'
        )}>
          {placeholder}
        </span>
      )}
    </Listbox.Option>
  );

  const mappedOptions = options.data.map((datum) => {
    return (
      <Listbox.Option
        key={datum.id || datum.cid}
        className={({ active }) => classNames(
          'relative cursor-default select-none py-2 pl-10 pr-4',
          'text-base',
          active ? 'bg-cs-orange-100 text-cs-orange-500' : 'text-cs-gray-900'
        )}
        value={datum.id}
      >
        {({ selected }) => (
          <>
            <span className={classNames(
              // 'block truncate',
              selected ? 'font-semibold' : 'font-normal'
            )}>
              {_.isFunction(optionLabel) ? optionLabel(datum) : datum.data[optionLabel]}
            </span>
            {selected ? (
              <span className="absolute inset-y-0 left-0 flex items-center top-2x pl-3 text-cs-orange-500">
                <CheckIcon className="h-5 w-5" aria-hidden="true" />
              </span>
            ) : null}
          </>
        )}
      </Listbox.Option>
    );
  });

  function getValue() {
    return (value === null || value === undefined) ? '' : value;
  }

  function getSelectedOption(value) {
    return _.find(options.data, function(option) {
      return String(option.id) === value;
    });
  }

  const selectedLabel = getSelectedOption(value)?.data.name;

  return (
    <div>
      {label && (
        <div className="relative flex space-x-0.5 text-sm text-cs-gray-500 mb-1">
          {required && (
            <span className="inline-block text-cs-orange-500">*</span>
          )}
          <span className="flex items-center space-x-1">
            <Text.Base>
              {label}
            </Text.Base>
            {tooltip && (
              <div>
                <Tooltip tooltip={tooltip} label={true} />
              </div>
            )}
          </span>
          {optional && (
            <div className="absolute top-0 right-0 text-cs-gray-300 text-sm leading-6">
              Optional
            </div>
          )}
        </div>
      )}
      <Listbox
        value={getValue()}
        onChange={(nextValue) => {
          const selectedOption = getSelectedOption(nextValue);
          onChange(selectedOption ? selectedOption.id : null);
        }}
      >
        <div className="relative mt-1">
          <Listbox.Button
            className={classNames(
          'relative w-full cursor-default',
              'py-2 pl-3 pr-10 text-left',
              // 'shadow-cs-flat',
              '-m-px border border-cs-gray-300 rounded-md',
              'focus:ring-cs-blue-500 focus:border-cs-blue-500',
              disabled ? 'bg-cs-gray-100' : 'bg-white'
            )}
          >
            <span className={classNames(
              'blockx line-clamp-2',
              selectedLabel ? 'text-cs-gray-900' : 'text-cs-gray-400', // placeholder
            )}>
              {selectedLabel || placeholder}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-cs-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute z-20 mt-1 max-h-64 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none">
              {[emptyOption].concat(mappedOptions)}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
      {error ? (
        <div className="mt-2 text-sm text-red-500">
          {error}
        </div>
      ) : null}
      {description ? (
        <Text.SmGrayMedium className="mt-2">
          {description}
        </Text.SmGrayMedium>
      ) : null}
    </div>
  );
}
