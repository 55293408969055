import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card from '../../../../components/Card';
import Activity from '../../../event-edit/Activity';
import { SparklesIcon } from '@heroicons/react/24/outline';
import Urls from '../../../event-create/Urls';
import DataCard from '../../../profile-edit/DataCard';

InteractionType.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default function InteractionType(props) {
  const {
    title,
    subtitle,
    icon,
    selected,
    onClick
  } = props;

  return (
    <Card
      border={false}
      rounded={false}
      background={false}
      className={classNames(
        'flex p-4 space-x-4',
        'rounded-cs-10',
        '-m-px border',
        onClick && 'cursor-pointer hover:bg-cs-orange-50',
        selected ? 'bg-cs-orange-50 border-cs-orange-500' : 'border-cs-gray-100 bg-white'
      )}
      onClick={onClick}
    >
      <div>
        <props.icon className="w-8 h-8 text-cs-orange-500" />
      </div>
      <div className="flex-1">
        <h2 className="text-base font-semibold text-cs-gray-900">
          {title}
        </h2>
        <div className="text-sm text-cs-gray-500">
          {subtitle}
        </div>
      </div>
    </Card>
  );
}
