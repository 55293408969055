import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useUser } from '@lore/auth';
import { useConfig } from '@lore/config';
import * as FullStory from '@fullstory/browser';
import ScrollToTop from '../components/ScrollToTop';
import useRouter from '../hooks/useRouter';
import useIntercom from '../hooks/useIntercom';
import Impersonation from './Impersonation';
import ErrorBoundary from './ErrorBoundary';
import { Outlet } from 'react-router-dom';

Layout.propTypes = {
  children: PropTypes.node
};

export default function Layout(props) {
  const { children } = props;
  const { location, history } = useRouter();
  const user = useUser();
  const config = useConfig();

  useIntercom();

  useEffect(() => {
    const { enabled } = config.fullstory;

    if (enabled) {
      FullStory.setUserVars({
        uid: user.id,
        displayName: `${user.data.firstName} ${user.data.lastName}`,
        email: user.data.email,
      });
    }
  }, []);

  return (
    <>
      <ScrollToTop />
      <Impersonation />
      <ErrorBoundary key={location.pathname} config={config}>
        <Outlet />
      </ErrorBoundary>
    </>
  );
};
