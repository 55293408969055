import React from 'react';
import PropTypes from 'prop-types';
import SolidButton from '../../components/Button/SolidButton';
import FormTemplate from '../../dialogs_common/FormTemplate';
import { ClipboardDocumentListIcon, EyeSlashIcon } from '@heroicons/react/24/outline';

Confirmation.propTypes = {
  event: PropTypes.object
};

export default function Confirmation(props) {
  const {
    event,
    onCancel,
    onPrevious
  } = props;

  return (
    <FormTemplate
      icon={(
        <div className="flex justify-center mb-4">
          {event.data.published ? (
            <ClipboardDocumentListIcon className="text-cs-orange-500 h-12 w-12" />
          ) : (
            <EyeSlashIcon className="text-cs-orange-500 h-12 w-12" />
          )}
        </div>
      )}
      title={`Event is now ${event.data.published ? 'visible' : 'hidden'}!`}
      // subtitle="Just come back here when it's finished and we'll get it recorded for you."
      onClose={onCancel}
      footer={(
        <div className="space-x-4">
          {/*<OutlineButton onClick={onPrevious}>*/}
          {/*  Back*/}
          {/*</OutlineButton>*/}
          <SolidButton onClick={onCancel}>
            Close
          </SolidButton>
        </div>
      )}
    />
  );
}
