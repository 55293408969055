import React from 'react';
import PropTypes from 'prop-types';
import ConnectButton from '../../../pages/_banner/ConnectButton';
import Wrapper from '../../../pages/_banner/Wrapper';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../_hooks/useDialogLauncher';

PendingBanner.propTypes = {
  project: PropTypes.object.isRequired,
  question: PropTypes.object.isRequired
};

export default function PendingBanner(props) {
  const { project, question, creator } = props;

  const launch = useDialogLauncher();

  function showInterestedDialog() {
    launch(DialogManager.QuestionConnect.Config(question.id));
  }

  function showDeclineDialog() {
    launch(DialogManager.QuestionDecline.Config(question.id));
  }

  return (
    <Wrapper
      color="yellow"
      creator={creator}
      title={(
        <>
          {creator.data.firstName} invited you to collaborate! Are you available?
        </>
      )}
      subtitle={(
        <>
          When you let {creator.data.firstName} know you're interested, you can ask
          questions, offer ideas, or even refer someone else who might be a good fit.
        </>
      )}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        <ConnectButton outline={true} onClick={showDeclineDialog}>
          Sorry, I can't help right now
        </ConnectButton>
        <ConnectButton color="yellow" onClick={showInterestedDialog}>
          I'm interested
        </ConnectButton>
      </div>
    </Wrapper>
  );
};
