import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useDialogConfig from '../_hooks/useDialogConfig';
import InvitationDiscoverYesDialog from '../../dialogs/invitation.discover.yes';
import { useQueryClient } from '@tanstack/react-query';

export default function Launcher(props) {
  const config = useDialogConfig();
  const queryClient = useQueryClient();

  const event = useConnect('event.byId', {
    id: config.params.eventId
  });

  const creator = useConnect('user.byId', {
    id: event.data.creatorId
  }, {
    enabled: !!event.data.creatorId
  });

  if (
    event.state === PayloadStates.FETCHING ||
    creator.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  return (
    <InvitationDiscoverYesDialog
      creator={creator}
      event={event}
      onCancel={props.onClose}
      onSubmit={function(request) {
        queryClient.invalidateQueries({ queryKey: ['invitation'] });
      }}
    />
  );
};
