import React from 'react';
import { DocumentTextIcon } from '@heroicons/react/24/outline';
import useRouter from '../../hooks/useRouter';
import Urls from '../event-create/Urls';
import DataCard from '../profile-edit/DataCard';
import usePageRouter from '../../dialogs-routable/_hooks/usePageRouter';

export default function EventSummary(props) {
  const { event } = props;
  const { history } = usePageRouter();

  return (
    <DataCard
      title="Event Summary"
      icon={DocumentTextIcon}
      subtitle={event.data.interactionDescription || '(not provided)'}
      onClick={() => history.push(Urls(event).SUMMARY)}
    />
  );
};
