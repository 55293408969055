import React from 'react';
import { useUser } from '@lore/auth';
import { Helmet } from 'react-helmet-async';
import Section from '../../components/Section';
import PrimaryHeader from '../../components/PrimaryHeader';
import NeedsAttention from './NeedsAttention';
import RecentMessages from './RecentMessages';
import Involvement from './Involvement';
import GetInvolved from './GetInvolved';
import Images from '../../constants/Images';
import OptionCard from '../my-network/OptionCard';
import logoImage from '../../../assets/images/logo_square.png';
import Recommended from './Recommended';

function Header(props) {
  const { currentUser, children } = props;

  return (
    <div className="">
      <Helmet>
        <title>Home</title>
      </Helmet>
      <PrimaryHeader
        style="simple"
        // title={(
        //   <>
        //     <div className="text-lg font-semibold text-cs-gray-900">
        //       Hi{currentUser.data.firstName ? ` ${currentUser.data.firstName}` : ''},
        //     </div>
        //     <div className="text-4xl font-semibold text-cs-gray-900 space-x-4">
        //       <span>Welcome back</span>
        //       <span>🎉</span>
        //     </div>
        //   </>
        // )}
        title={(
          <div className="text-4xl font-semibold text-cs-gray-900 space-x-4">
            <span>Welcome back{currentUser.data.firstName ? `, ${currentUser.data.firstName}!` : '!'}</span>
            <span>🎉</span>
          </div>
        )}
        textClassName="text-cs-orange-500"
      />
      <div className="px-8 mb-24 w-full lg:mx-auto">
        <Section className="space-y-12">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3x gap-4">
            {/*<StewardNotification audience="educator" />*/}
            {/*<HelpNotification onClick={onChooseProjectEvent} />*/}
            <OptionCard
              // imageUrl={bookshelfImage}
              // centeredImageBackgroundColor="bg-"
              centeredImage={(
                <img className="h-24 w-24" src={logoImage} alt="Logo" />
              )}
              // imageUrl={Images.LOGO_ON_ORANGE_BACKGROUND}
              title="Create and launch activities"
              subtitle="Start a new project, event, or offer. We'll help every step of the way!"
              linkText="Create"
              to="/my-leading"
            />
            <OptionCard
              // imageUrl={bookshelfImage}
              imageUrl={Images.FIND_SUPPORT_RESOURCES}
              title="Find support and resources"
              subtitle="Get in touch with your local support team, find tools to get started, and more!"
              linkText="Get support"
              to="/my-network"
            />
          </div>
          {children}
        </Section>
      </div>
    </div>
  );
}

export default function Layout(props) {
  const currentUser = useUser();

  return (
    <Header currentUser={currentUser}>
      <NeedsAttention />
      <Involvement />
      <GetInvolved />
      {/*<Recommended />*/}
      <RecentMessages />
    </Header>
  );
};
