import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';

SendgridMessageDeliveryStatus.propTypes = {
  message: PropTypes.object.isRequired
};

export default function SendgridMessageDeliveryStatus(props) {
  const { message } = props;

  const sendgridEvent = useConnect('sendgridEvent.first', {
    where: {
      eager: {
        $where: {
          sg_message_id: {
            $like: `${message.data.sendgridMessageId}%`
          }
        }
      }
    },
    pagination: {
      order: 'timestamp desc'
    }
  }, {
    enabled: !!message.data.sendgridMessageId
  });

  if (!message.data.sendgridMessageId) {
    return 'No delivery information';
  }

  if (sendgridEvent.state === PayloadStates.FETCHING) {
    return '...';
  }

  if (sendgridEvent.state === PayloadStates.NOT_FOUND) {
    return 'Sent';
  }

  // return (
  //   <div>
  //     {sendgridEvent.data.event} @ {moment.unix(sendgridEvent.data.timestamp).format('h:mm A, MMM D YYYY')}
  //   </div>
  // );

  const events = {
    open: 'Opened',
    click: 'Opened',
    delivered: 'Unread',
    dropped: 'Undeliverable',
    bounced: 'Undeliverable',
    bounce: 'Undeliverable'
  };

  return `${events[sendgridEvent.data.event] || sendgridEvent.data.event}`;
};
