import React from 'react';
import PropTypes from 'prop-types';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import Loader from '../../components/Loader';
import useUserProfileMissingFields from '../../hooks/useUserProfileMissingFields';
import ProfileMissingDialog from './Dialog';

ProfileMissingDialogWrapper.propTypes = {
  user: PropTypes.object.isRequired
};

export default function ProfileMissingDialogWrapper(props) {
  const missingFields = useUserProfileMissingFields();

  if (!missingFields) {
    return (
      <DialogTemplate>
        <Loader />
      </DialogTemplate>
    );
  }

  return (
    <ProfileMissingDialog
      {...props}
      initialMissingFields={missingFields}
    />
  );
};
