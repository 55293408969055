import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { DateTime } from 'luxon';
import validators from '../../../utils/validators';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import SolidButton from '../../../components/Button/SolidButton';
import FormTemplate2 from '../../_post-opportunity/FlowTemplate';
import TimeField from '../../_post-opportunity/_fields/TimeField.v2';
import FormSection from '../../_post-opportunity/forms/FormSection';
import useTags from '../../../hooks/useTags';
import TagTypes from '../../../constants/TagTypes';
import useProjectTags from '../../../hooks/useProjectTags';
import NodeEntries from '../NodeEntries';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';

Form.propTypes = {
  user: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func,
  onSkip: PropTypes.func
};

export default function Form(props) {
  const {
    user,
    project,
    navigation,
    onNext,
    onPrevious,
    onSkip,
    onExit,
    FormTemplate = FormTemplate2,
    buttonText = 'Next'
  } = props;

  const [data, setData] = useState({
    specificTime: project.data.specificTime || 'Yes',
    startTime: project.data.startTime || new Date().toISOString(),
    endTime: project.data.endTime || new Date().toISOString()
  });
  const [showErrors, setShowErrors] = useState(false);
  const [exit, setExit] = useState(false);

  const mutation = useQueryMutation('project.update', project);
  const { saving, success, error, request } = mutation;

  const times = useTags(TagTypes.Time);
  const projectTimes = useProjectTags(project, TagTypes.Time);

  useEffect(() => {
    if (success) {
      exit ? onExit(data) : onNext(data);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate(data);
  }

  function onSubmitAndExit() {
    onSubmit();
    setExit(true);
  }

  // function onChange(name, value) {
  //   setData(_.merge({}, data, {
  //     [name]: value
  //   }));
  // }

  function onChange(name, value) {
    const nextData = _.merge({}, data);
    nextData[name] = value;

    // if (
    //   name === 'startTime' &&
    //   (
    //     !nextData.endTime ||
    //     DateTime.fromISO(nextData.endTime).diff(DateTime.fromISO(value)).milliseconds < 0
    //   )
    // ) {
    //   nextData.endTime = value;
    // }
    //
    // if (
    //   name === 'endTime' &&
    //   (
    //     !nextData.startTime ||
    //     DateTime.fromISO(nextData.startTime).diff(DateTime.fromISO(value)).milliseconds > 0
    //   )
    // ) {
    //   nextData.startTime = value;
    // }

    setData(nextData);
  }

  return (
    <Overlay isVisible={saving}>
      <FormErrors data={data} validators={data.specificTime === 'Yes' ? {
        startTime: [validators.time.isBefore(data.endTime, 'Start time must be before end time')],
        endTime: [validators.time.isAfter(data.startTime, 'End time must be after start time')]
      } : {}}>
        {({ errors, hasError }) => {
          return (
            <FormTemplate
              onExit={() => hasError ? setShowErrors(true) : onSubmitAndExit()}
              onPrevious={onPrevious}
              navigation={navigation}
              alert={error ? (
                <RequestError request={request} />
              ) : showErrors ? (
                <ErrorMessage>
                  Please fill in all required fields.
                </ErrorMessage>
              ) : null}
              body={(
                <div className="space-y-10">
                  {/*<FormSection*/}
                  {/*  title="Does this project need to occur at a specific time or is it flexible?"*/}
                  {/*  // subtitle="Does this project have a specific time?"*/}
                  {/*>*/}
                  {/*  <FormField name="specificTime" data={data} errors={showErrors && errors} onChange={onChange}>*/}
                  {/*    {(field) => (*/}
                  {/*      <RadioButtonField*/}
                  {/*        name="specificTime"*/}
                  {/*        // label="Does this event have a specific time?"*/}
                  {/*        value={field.value}*/}
                  {/*        error={field.error}*/}
                  {/*        placeholder="Select a role..."*/}
                  {/*        options={{*/}
                  {/*          data: [*/}
                  {/*            ['Yes', 'Specific Time'],*/}
                  {/*            ['No', 'Time is Flexible']*/}
                  {/*          ].map((option) => {*/}
                  {/*            return {*/}
                  {/*              id: option[0],*/}
                  {/*              data: {*/}
                  {/*                name: option[1]*/}
                  {/*              }*/}
                  {/*            }*/}
                  {/*          })*/}
                  {/*        }}*/}
                  {/*        columns={2}*/}
                  {/*        optionLabel="name"*/}
                  {/*        onChange={field.onChange}*/}
                  {/*      />*/}
                  {/*    )}*/}
                  {/*  </FormField>*/}
                  {/*</FormSection>*/}
                  {data.specificTime === 'Yes' ? (
                    <>
                      <FormSection
                        title="Start Time"
                      >
                        <TimeField
                          // key={data.startTime}
                          time={data.startTime}
                          error={showErrors && errors.startTime}
                          onChange={(value) => {
                            return onChange('startTime', value);
                          }}
                        />
                      </FormSection>
                      <FormSection
                        title="End Time"
                      >
                        <TimeField
                          // key={data.endTime}
                          time={data.endTime}
                          error={showErrors && errors.endTime}
                          onChange={(value) => {
                            return onChange('endTime', value);
                          }}
                        />
                      </FormSection>
                    </>
                  ) : (
                    <FormSection
                      // title="Possible times"
                      // subtitle="Select one or many."
                      // tooltip="Is there an ideal time of day you need the interaction to occur?"
                    >
                      <NodeEntries
                        project={project}
                        tags={times}
                        nodes={projectTimes}
                      />
                    </FormSection>
                  )}
                </div>
              )}
              footer={(
                <>
                  <SolidButton onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
                    {buttonText}
                  </SolidButton>
                </>
              )}
            />
          );
        }}
      </FormErrors>
    </Overlay>
  );
};
