export default {
  EXPLORE_EDUCATORS: 'https://cdn.filestackcontent.com/2hF1MLGxRsi2uhIx4Nq3',
  FIND_PARTNERS: 'https://cdn.filestackcontent.com/7lLZlW5JRnqPd265ua6X',
  ACTIVE_PROJECTS: 'https://cdn.filestackcontent.com/QgfVwdmnSMyTTl3Ny70b',
  ACTIVE_OFFERS: 'https://cdn.filestackcontent.com/DHP1DkcyTHmuzXv5ztKP',
  FIND_SUPPORT_RESOURCES: 'https://cdn.filestackcontent.com/6tgEkBUUQAyp6r0rYC8i',
  LOGO_ON_ORANGE_BACKGROUND: 'https://cdn.filestackcontent.com/a4iMxeUhRXKlLB0qDuqR',
  HANDS_IN_A_CIRCLE: 'https://cdn.filestackcontent.com/T8LZ6iRhT8FHujVZ6Us0',
  PENCIL_WRITING_ON_LIST: 'https://cdn.filestackcontent.com/0UGBeXqJT7OrPVaK7eYe'
}
