import React from 'react';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import ParentLayout from '../Layout';
import Urls from '../Urls';
import Sites from './Sites';
import { PayloadStates } from '@lore/utils';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { history, match } = useRouter();

  const user = useUser();

  const project = useConnect('project.byId', {
    id: Number(match.params.projectId)
  });

  const roles = useConnect('role.find', {
    where: {
      eager: {
        $where: {
          userId: user.id,
          siteId: {
            $exists: true
          }
        }
      }
    },
    pagination: {
      page: 1,
      pageSize: 1000,
      order: 'createdAt asc'
    }
  });

  if (
    project.state === PayloadStates.FETCHING ||
    roles.state === PayloadStates.FETCHING
  ) {
    return null;
  }

  return (
    <ParentLayout
      project={project}
      page="Site"
      title={(
        <>
          Which school or site is associated with this project?
        </>
      )}
      description={(
        <div className="space-y-4">
          <div>
            This is where the learners are! We've listed the schools or sites you
            specified as places you work. Feel free to delete or add new sites.
          </div>
          <div className="font-semibold">
            Choose ONE school or site for this project.
          </div>
        </div>
      )}
    >
      <Sites
        user={user}
        project={project}
        roles={roles}
        // navigation={(
        //   <Navigation v5={true} activeStep={1} steps={19}/>
        // )}
        // onPrevious={(newData) => history.push(Urls(project).HOME)}
        onNext={(newData) => history.push(Urls(project).ACTIVITY)}
        onSkip={() => history.push(Urls(project).ACTIVITY)}
      />
    </ParentLayout>
  );
};
