import React from 'react';
import PropTypes from 'prop-types';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import FormTemplate from './FormTemplate';
import SolidButton from '../../components/Button/SolidButton';
import Expertises from '../../pages/setup/skills/Expertises';

Skills.propTypes = {
  userExpertises: PropTypes.object
};

export default function Skills(props) {
  const {
    user,
    expertiseTypes,
    userExpertises,
    navigation,
    onNext,
    onPrevious,
    onSkip
  } = props;

  return (
    <DialogTemplate className="w-screen max-w-xl">
      <FormTemplate
        onPrevious={onPrevious}
        navigation={navigation}
        title="What work experiences and interests could you share about the topics below with youth and educators?"
        subtitle="Think about your past and current experiences. Select as many fields as you want below."
        body={(
          <div>
            <Expertises
              user={user}
              expertiseTypes={expertiseTypes}
              userExpertises={userExpertises}
            />
          </div>
        )}
        footer={(
          <div className="flex items-center justify-end space-x-12">
            <div className="text-cs-orange-500 underline cursor-pointer mt-6 sm:mt-0" onClick={onSkip}>
              Skip this step
            </div>
            <SolidButton onClick={onNext}>
              Next
            </SolidButton>
          </div>
        )}
      />
    </DialogTemplate>
  );
}
