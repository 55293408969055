import React from 'react';
import { Helmet } from 'react-helmet-async';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import PageHeader from '../people-people/PageHeader';
import useExpiredProjects from './useExpiredProjects';
import useExpiredEvents from './useExpiredEvents';
import EmptyState from '../../components/EmptyState';
import { ClockIcon } from '@heroicons/react/24/outline';
import ExpiredProject from './ExpiredProject';
import ExpiredEvent from './ExpiredEvent';

function Header(props) {
  const { children } = props;

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Close Activities</title>
      </Helmet>
      <PageHeader
        title="Close Activities"
        subtitle={(
          <>
            Have you completed your project/event or need more time? Keep your activities
            up-to-date by updating or closing it, so partners know whether it's active or completed.
          </>
        )}
      />
      <div className="space-y-8">
        {children}
      </div>
    </div>
  );
}

export default function Layout(props) {
  const projects = useExpiredProjects();
  const events = useExpiredEvents();

  if (
    projects.state === PayloadStates.FETCHING ||
    events.state === PayloadStates.FETCHING
  ) {
    return (
      <Header>
        <Loader />
      </Header>
    );
  }

  if (
    projects.data.length === 0 &&
    events.data.length === 0
  ) {
    return (
      <Header>
        <EmptyState
          icon={ClockIcon}
          title="You're all caught up!"
          subtitle={(
            <>
              There are no activities ready to close at the moment. As your project and
              events end, they'll appear here for you to review, extend the timeline, or close.
            </>
          )}
        />
      </Header>
    );
  }

  return (
    <Header>
      <div className="space-y-4">
        {projects.data.map(function(project) {
          return (
            <ExpiredProject
              key={project.id}
              project={project}
            />
          );
        })}
        {events.data.map(function(event) {
          return (
            <ExpiredEvent
              key={event.id}
              event={event}
            />
          );
        })}
      </div>
    </Header>
  );
}
