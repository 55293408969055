export default function(offer) {
  return {
    ORGANIZATION: `/post-offer/${offer.id}/organization`,

    ACTIVITY: `/post-offer/${offer.id}/activity`,

    DESCRIPTION:  `/post-offer/${offer.id}/description`,
    AUDIENCE:  `/post-offer/${offer.id}/audience`,
    OBJECTIVES:  `/post-offer/${offer.id}/objectives`,
    TITLE:  `/post-offer/${offer.id}/title`,
    SUBJECTS:  `/post-offer/${offer.id}/subjects`,
    SUPPLIES:  `/post-offer/${offer.id}/supplies`,

    PARTICIPANTS:  `/post-offer/${offer.id}/participants`,
    STUDENT_GROUPS:  `/post-offer/${offer.id}/student-groups`,

    DESIGN: `/post-offer/${offer.id}/design`,

    COMPETENCIES: `/post-offer/${offer.id}/competencies`,
    DATE: `/post-offer/${offer.id}/date`,
    TIME: `/post-offer/${offer.id}/time`,
    LOCATION: `/post-offer/${offer.id}/location`,
    COST: `/post-offer/${offer.id}/cost`,

    DOCUMENTS: `/post-offer/${offer.id}/documents`,
    WEBSITE: `/post-offer/${offer.id}/website`,

    EXPLANATION: `/post-offer/${offer.id}/explanation`,
    DASHBOARD: `/`,
    OFFER_EDIT: `/offer/${offer.id}/edit`,
    OFFER_INVITE: `/offer/${offer.id}/invite-populate`,
    OFFER_MANAGE: `/offer/${offer.id}/manage`,
  }
};
