import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const defaultClassNames = {
  display: 'flex items-center space-x-2',
  padding: 'py-2 px-4',
  rounded:  'rounded-cs-10',
  background:  'bg-cs-orange-500',
  border: '-m-px border border-cs-orange-500',
  text:  'text-base leading-6',
  textColor:  'text-white',
  shadow:  'shadow-cs-flat',
  hover:  'cursor-pointer hover:bg-cs-orange-400',
  disabled:  'opacity-50 cursor-not-allowed',
  custom: ''
};

Button.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  overrideClassNames: PropTypes.object
};

export default function Button(props) {
  const {
    icon,
    label,
    onClick,
    disabled,
    overrideClassNames = {}
  } = props;

  const cn = {
    ...defaultClassNames,
    ...overrideClassNames
  };

  return (
    <button
      className={classNames(
        cn.display,
        cn.padding,
        cn.rounded,
        cn.background,
        cn.border,
        cn.text,
        cn.textColor,
        cn.shadow,
        !disabled && cn.hover,
        disabled && cn.disabled,
        cn.custom
      )}
      onClick={disabled ? undefined : onClick}
    >
      {icon}
      <span className="text-center w-full">
        {label}
      </span>
    </button>
  );
};
