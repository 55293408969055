import React from 'react';
import { useUser } from '@lore/auth';
import { Helmet } from 'react-helmet-async';
import MissingFields from '../profile/MissingFields';
import DialogLayout from './DialogLayout';

export default function Layout(props) {
  const currentUser = useUser();

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Edit Profile</title>
      </Helmet>
      <div className="space-y-8 mb-8">
        <div className="text-3xl font-semibold text-cs-gray-900">
          Edit Profile
        </div>
        <MissingFields user={currentUser} />
      </div>
      <DialogLayout />
    </div>
  );
};
