import React from 'react';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import ParentLayout from '../Layout';
import Navigation from '../../../dialogs_common/Navigation';
import Urls from '../Urls';
import Form from './Form';
import { PayloadStates } from '@lore/utils';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { location, history, match } = useRouter();
  const user = useUser();
  const offer = useConnect('offer.byId', {
    id: Number(match.params.offerId)
  })

  if (offer.state === PayloadStates.FETCHING) {
    return null;
  }

  return (
    <ParentLayout
      offer={offer}
      page="Audience"
      title="Is the activity designed for a certain group of students?"
      // description="Description"
      description={(
        <>
          <div className="mb-2 font-semibold">
            For example...
          </div>
          <ul className="list-disc ml-6 space-y-1">
            {[
              'Teens who are about to earn their first paycheck',
              'Encouraging young women to pursue STEM careers'
            ].map(function(text, index) {
              return (
                <li key={index}>
                  {text}
                </li>
              );
            })}
          </ul>
        </>
      )}
    >
      <Form
        user={user}
        offer={offer}
        navigation={(
          <Navigation v5={true} activeStep={2} steps={16}/>
        )}
        onPrevious={(newData) => history.push(Urls(offer).DESCRIPTION)}
        onNext={(newData) => history.push(Urls(offer).OBJECTIVES)}
        onSkip={() => history.push(Urls(offer).OBJECTIVES)}
        onExit={() => history.push(Urls(offer).OFFER_EDIT)}
      />
    </ParentLayout>
  );
};
