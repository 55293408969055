import React from 'react';
import SolidButton from '../components/Button/SolidButton';
import FormTemplate from '../dialogs_common/FormTemplate';
import DialogTemplate from '../dialogs_common/DialogTemplate';

export default function ExpertiseSuggestionsDialog(props) {
  const { onCancel } = props;

  return (
    <DialogTemplate className="w-screen max-w-screen-sm">
      <FormTemplate
        title="Describing your expertise"
        subtitle="Consider these examples for how to explain your experience and/or specialization"
        onClose={onCancel}
        size="medium"
        body={(
          <>
            <div className="mb-8">
              <div className="font-semibold">
                Pottery and Sculpture
              </div>
              <div className="italic">
                Arts: Visual, Performing, and Fine Arts
              </div>
              <p>
                I was a hobby potter for more than 10 years. I could teach a basic clay class (wheel
                throwing or hand building) and talk about the firing and glazing processes.
              </p>
            </div>
            <div className="mb-8">
              <div className="font-semibold">
                Data Analysis and Visualization
              </div>
              <div className="italic">
                Mathematics and Data Analysis
              </div>
              <p>
                Create graphs and charts that tell a story or answer a question using data.
              </p>
            </div>
            <div className="mb-8">
              <div className="font-semibold">
                Linguistics and Languages
              </div>
              <div className="italic">
                Social Sciences and Languages
              </div>
              <p>
                I am a fluent Spanish speaker and could tutor or practice conversation with students.
              </p>
            </div>
            <div className="mb-8">
              <div className="font-semibold">
                Web Development
              </div>
              <div className="italic">
                Computer Science and Information Technology
              </div>
              <p>
                I build web applications that solve problems for people. I could
                talk about the software industry, the process of building digital products,
                and also provide a demonstration of how to build and deploy a website.
              </p>
            </div>
          </>
        )}
        footer={(
          <SolidButton onClick={onCancel}>
            Close
          </SolidButton>
        )}
      />
    </DialogTemplate>
  );
};
