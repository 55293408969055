import React, { useState } from 'react';
import { useConnect } from '@lore/query-connect';
import useRouter from '../../hooks/useRouter';
import Urls from '../profile/Urls';
import DataCard from './DataCard';
import { PayloadStates } from '@lore/utils';
import Role from '../setup/school/Role';
import EditLink from './EditLink';

export default function Sites(props) {
  const { user } = props;

  const { history } = useRouter();

  const roles = useConnect('role.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    },
    pagination: {
      page: 1,
      pageSize: 100,
      order: 'createdAt asc'
    }
  });

  return (
    <div className="space-y-6">
      {roles.data.map(function(role) {
        return (
          <Role
            key={role.id}
            role={role}
          />
        );
      })}
      <EditLink
        title="Add or change schools/sites"
        onClick={() => history.push(Urls(user).SITES)}
      />
    </div>
  );
};
