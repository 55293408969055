import React from 'react';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import { SolidButton } from '../../../components/Button';
import GrayOutlineButton from '../../../components/Button/OutlineButton.gray';
import ComposableEventHeader from '../../../components_cards/ComposableEventHeader';
import DividerCard from '../../../components/DividerCard';
import TagRow from '../../../components_cards/tags/TagRow';
import EventTag from '../../../components_cards/tags/EventTag';
import ExpiredTag from '../../../components_cards/tags/_resource/ExpiredTag';
import CreatorTag from '../../../components_cards/tags/CreatorTag';
import EventMissingFieldsTag from '../../../components_cards/ComposableEventHeader/EventMissingFieldsTag';
import Invitations from '../../my-past/EventCreated/Invitations';

export default function ActiveEvent(props) {
  const { event } = props;

  const launch = useDialogLauncher();

  function onClickView() {
    launch(DialogManager.EventManage.Config(event.id, `/view`));
  }

  function onClickEdit() {
    launch(DialogManager.EventManage.Config(event.id, `/edit`));
  }

  function onClickInvite() {
    launch(DialogManager.EventManage.Config(event.id, `/invite-populate`));
  }

  function onClickManage() {
    launch(DialogManager.EventManage.Config(event.id, `/manage`));
  }

  function onClickClose() {
    launch(DialogManager.EventClose.Config(event.id));
  }

  return (
    <DividerCard>
      <ComposableEventHeader
        event={event}
        tags={(
          <>
            <TagRow>
              <EventTag />
              {/*<CreatorTag />*/}
              {/*<EventMissingFieldsTag event={event} />*/}
              {/*<ExpiredTag resource={event} />*/}
            </TagRow>
            <Invitations event={event} />
          </>
        )}
        showTags={false}
        showCreator={false}
      />
      <div className="w-full bg-cs-gray-50 py-4 px-4 flex justify-between items-center flex-wrap gap-2">
        <div className="flex flex-wrap gap-2">
          {/*<GrayOutlineButton*/}
          {/*  onClick={onClickView}*/}
          {/*  label="View event"*/}
          {/*/>*/}
          {/*<GrayOutlineButton*/}
          {/*  onClick={onClickEdit}*/}
          {/*  label="Edit event"*/}
          {/*/>*/}
        </div>
        <div className="flex flex-wrap gap-2">
          <GrayOutlineButton
            onClick={onClickClose}
            label="Close event"
          />
          {/*<GrayOutlineButton*/}
          {/*  onClick={onClickInvite}*/}
          {/*  label="Invite partners"*/}
          {/*/>*/}
          <SolidButton
            onClick={onClickManage}
            label="Manage partners"
          />
        </div>
      </div>
    </DividerCard>
  );
}
