import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PayloadStates } from '@lore/utils';
import { useConnect } from '@lore/query-connect';
import DialogTemplate from '../../../dialogs_common/DialogTemplate';
import { OutlineButton, SolidButton } from '../../../components/Button';
import FormTemplate from '../../../dialogs_common/FormTemplate';
import Loader from '../../../components/Loader';
import DuplicateIconWrapper from '../DuplicateIconWrapper';
import FormTemplateButtonFooter from '../../../dialogs_common/FormTemplateButtonFooter';

Start.propTypes = {
  data: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired
};

export default function Start(props) {
  const {
    data,
    onNext,
    onCancel,
    offer
  } = props;

  const [timestamp] = useState(new Date().toISOString());

  const params = {
    where: {
      eager: {
        $where: {
          offerId: offer.id,
          createdAt: {
            $lt: timestamp
          }
        }
      }
    }
  }

  const activity = useConnect('tag.byId', {
    id: offer.data.activityId
  });
  const tagNodes = useConnect('tagNode.find', params);
  const uploads = useConnect('upload.find', params);

  if (
    activity.state === PayloadStates.FETCHING ||
    tagNodes.state === PayloadStates.FETCHING ||
    uploads.state === PayloadStates.FETCHING
  ) {
    return (
      <DialogTemplate>
        <Loader />
      </DialogTemplate>
    )
  }

  function onSubmit() {
    onNext({
      activity,
      tagNodes,
      uploads
    });
  }

  return (
    <DialogTemplate>
      <FormTemplate
        icon={(
          <DuplicateIconWrapper />
        )}
        title="Duplicate Offer"
        subtitle={(
          <div className="space-y-2">
            <div>
              Duplicating an offer will create a new offer and copy over information
              such title and description, as well as subject areas, youth groups, and
              information about your ideal partner (such as their skills and experience).
            </div>
            <div>
              You'll need to update the offer with new dates before we start sharing it
              with potential educators!
            </div>
          </div>
        )}
        onClose={onCancel}
        footer={(
          <FormTemplateButtonFooter>
            <OutlineButton onClick={onCancel}>
              Cancel
            </OutlineButton>
            <SolidButton onClick={onSubmit}>
              Duplicate this Offer
            </SolidButton>
          </FormTemplateButtonFooter>
        )}
      />
    </DialogTemplate>
  );
};
