import React from 'react';
import classNames from 'classnames';

export default function PrimaryHeader(props) {
  const { title, children, style = "bold", className, textClassName } = props;

  if (style === 'simple') {
    return (
      <div className={classNames(
        'flex flex-row items-center justify-between py-6 px-8',
        className
      )}>
        <h2 className={classNames(
          'text-3xl font-semibold',
          textClassName || 'text-cs-gray-900'
        )}>
          {title}
        </h2>
        {children}
      </div>
    );
  }

  return (
    <div className="flex flex-row items-center justify-between bg-gradient-to-r from-cs-orange-500 to-cs-yellow-500 py-6 px-8 shadow-md">
      <h2 className="text-3xl font-semibold text-white">
        {title}
      </h2>
      {children}
    </div>
  );
};
