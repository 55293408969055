import React from 'react';
import PropTypes from 'prop-types';

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.node
};

export default function SectionHeader(props) {
  const { title, subtitle, icon } = props;

  const header = (
    <div className="space-y-1">
      <div className="text-2xl text-cs-gray-900 font-semibold">
        {title}
      </div>
      {subtitle && (
        <div className="text-base text-cs-gray-500">
          {subtitle}
        </div>
      )}
    </div>
  );

  if (icon) {
    return (
      <div className="flex space-x-2">
        <props.icon className="h-8 w-8 text-cs-gray-900" />
        {header}
      </div>
    );
  }

  return header;
};
