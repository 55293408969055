import React from 'react';
import ActiveBanner from './ActiveBanner';
import { useConnect } from '../../../../hooks/@lore/query-connect';
import useMissingFields from '../../../pages/offer-manage/useMissingFields';
import DraftBanner from './DraftBanner';
import CompletedBanner from './CompletedBanner';
import CancelledBanner from './CancelledBanner';
import ExpiredBanner from './ExpiredBanner';
import { DateTime } from 'luxon';
import { getTimestampDiff } from '../../../utils/dateTimeUtils';

export default function Banner(props) {
  const { offer } = props;

  const { missingFields } = useMissingFields(offer);

  const creator = useConnect('user.byId', {
    id: offer.data.creatorId
  });

  // return (
  //   <>
  //     <ActiveBanner
  //       offer={offer}
  //       creator={creator}
  //     />
  //     <DraftBanner
  //       offer={offer}
  //       creator={creator}
  //     />
  //     <CompletedBanner
  //       offer={offer}
  //       creator={creator}
  //     />
  //     <CancelledBanner
  //       offer={offer}
  //       creator={creator}
  //     />
  //     <ExpiredBanner
  //       offer={offer}
  //       creator={creator}
  //     />
  //   </>
  // );

  if (offer.data.completed) {
    return (
      <CompletedBanner
        offer={offer}
        creator={creator}
      />
    );
  }

  if (offer.data.cancelled) {
    return (
      <CancelledBanner
        offer={offer}
        creator={creator}
      />
    );
  }

  if (missingFields.data.length > 0) {
    return (
      <DraftBanner
        offer={offer}
        creator={creator}
      />
    );
  }

  const diff = getTimestampDiff(DateTime.now().toISO(), offer.data.endDate);

  if (diff > 0) {
    return (
      <ExpiredBanner
        offer={offer}
        creator={creator}
      />
    );
  }

  return (
    <ActiveBanner
      offer={offer}
      creator={creator}
    />
  );
};
