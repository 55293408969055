import React from 'react';
import Activity from './Activity';
import OfferDescription from './OfferDescription';
import OfferObjectives from './OfferObjectives';
import OfferTitle from './OfferTitle';
import Audience from './Audience';
import StudentGroups from './StudentGroups';
import Participants from './Participants';
import Subjects from './Subjects';
import Competencies from './Competencies';
import Location from './Location';
import Documents from './Documents';
import Date from './Date';
import Time from './Time';
import Supplies from './Supplies';
import Website from './Website';
import GroupSize from './GroupSize';
import Cost from './Cost';
import Divider from '../../forms/_fields_v2/Divider';
import Section from '../profile-edit/Section';

export default function Layout(props) {
  const { offer } = props;

  return (
    <div className="space-y-8">
      {/*<div className="text-2xl font-semibold text-cs-gray-900">*/}
      {/*  Edit Offer*/}
      {/*</div>*/}
      {/*<Divider />*/}
      <Section
        title="Vision"
        subtitle={(
          <>
            What are you offering?
          </>
        )}
      >
        <div className="grid grid-cols-1 gap-6">
          <Activity offer={offer} />
          <OfferDescription offer={offer} />
          <OfferObjectives offer={offer} />
          <OfferTitle offer={offer} />
        </div>
      </Section>
      <Divider />
      <Section
        title="Audience"
        subtitle={(
          <>
            Who are you hoping to work with?
          </>
        )}
      >
        <div className="grid grid-cols-1 gap-6">
          <Audience offer={offer} />
          <Participants offer={offer} />
          <StudentGroups offer={offer} />
          <GroupSize offer={offer} />
        </div>
      </Section>
      <Divider />
      <Section
        title="Subject Areas"
        subtitle={(
          <>
            What subjects or student needs does your offer address?
          </>
        )}
      >
        <div className="grid grid-cols-1 gap-6">
          <Subjects offer={offer} />
          <Competencies offer={offer} />
        </div>
      </Section>
      <Divider />
      <Section
        title="Cost & Supplies"
        subtitle={(
          <>
            What does the educator need to provide?
          </>
        )}
      >
        <div className="grid grid-cols-1 gap-6">
          <Cost offer={offer} />
          <Supplies offer={offer} />
        </div>
      </Section>
      <Divider />
      <Section
        title="Timing & Location"
        subtitle={(
          <>
            When are you available for this offer?
          </>
        )}
      >
        <div className="grid grid-cols-1 gap-6">
          <Date offer={offer} />
          <Time offer={offer} />
          <Location offer={offer} />
        </div>
      </Section>
      <Divider />
      <Section
        title="Additional Information"
        subtitle={(
          <>
            What other information do educators or students need to know?
          </>
        )}
      >
        <div className="grid grid-cols-1 gap-6">
          <Documents offer={offer} />
          <Website offer={offer} />
        </div>
      </Section>
    </div>
  );
};
