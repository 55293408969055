import React from 'react';
import { useConnect } from '@lore/query-connect';
import { BuildingOfficeIcon } from '@heroicons/react/24/outline';
import IconSection from '../../_new_card_components/IconSection';

export default function School(props) {
  const { event } = props;

  const organization = useConnect('organization.byId', {
    id: event.data.organizationId
  }, {
    enabled: !!event.data.organizationId
  });

  if (!event.data.organizationId) {
    return null;
  }

  return (
    <IconSection
      icon={BuildingOfficeIcon}
      title={organization.data.name}
    />
  );
}
