import React from 'react';
import SiteMissingDialog from '../../dialogs/school.missing';

export default function Launcher(props) {
  return (
    <SiteMissingDialog
      onCancel={props.onClose}
    />
  );
};
