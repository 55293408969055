import React from 'react';
import Markdown from './Markdown';
import { useUser } from '@lore/auth';
import useNetwork from '../hooks/useNetwork';
import Banner from './Banner';

export default function AnnouncementBanner(props) {
  const currentUser = useUser();
  const network = useNetwork();

  if (
    currentUser.data.isEducator &&
    network.data.educatorBanner
  ) {
    return (
      <Banner>
        <Markdown white={true} text={network.data.educatorBanner} />
      </Banner>
    );
  }

  if (
    !currentUser.data.isEducator &&
    network.data.partnerBanner
  ) {
    return (
      <Banner>
        <Markdown white={true} text={network.data.partnerBanner} />
      </Banner>
    );
  }

  return null;
};
