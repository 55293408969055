import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import CheckboxField from '../../../forms/_fields_v2/CheckboxField';

Entries.propTypes = {
  groups: PropTypes.object.isRequired,
  userGroups: PropTypes.object.isRequired,
  selectedGroups: PropTypes.array
};

export default function Entries(props) {
  const {
    groups,
    userGroups,
    onSelectGroup
  } = props;

  const selectedGroups = props.selectedGroups || _.cloneDeep(groups.data.filter(function(group) {
    return !!_.find(userGroups.data, function(userGroup) {
      return userGroup._tag.id === group.id;
    });
  }));

  function renderGroups(groups) {
    return groups.map(function(group) {
      const selectedGroup = _.find(selectedGroups, function(selectedGroup) {
        return group.id === selectedGroup.id;
      });

      const selected = !!selectedGroup;

      return (
        <CheckboxField
          key={group.id}
          label={group.data.name}
          description={group.data.description}
          checked={selected}
          onChange={() => onSelectGroup(group, selectedGroup)}
        />
      );
    });
  }

  return (
    <div className="w-full">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-12 gap-y-3">
        <div className="space-y-3">
          {renderGroups(
            groups.data.slice(0, Math.floor(groups.data.length/2))
          )}
        </div>
        <div className="space-y-3">
          {renderGroups(
            groups.data.slice(Math.floor(groups.data.length/2))
          )}
        </div>
      </div>
    </div>
  );
}
