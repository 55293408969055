export default function(event) {
  return {
    HOME: `/post-event`,
    SCHOOL: `/post-event/${event.id}/site`,
    ACTIVITY: `/post-event/${event.id}/activity`,

    PARTICIPANTS:  `/post-event/${event.id}/participants`,
    STUDENT_COUNT:  `/post-event/${event.id}/student-count`,
    STUDENT_AGE:  `/post-event/${event.id}/student-age`,
    STUDENT_SIZE:  `/post-event/${event.id}/student-size`,

    TITLE:  `/post-event/${event.id}/title`,
    SUMMARY:  `/post-event/${event.id}/summary`,

    SUBJECTS:  `/post-event/${event.id}/subjects`,
    SLOTS: `/post-event/${event.id}/slots`,
    PARTNER: `/post-event/${event.id}/partner`,

    DATE: `/post-event/${event.id}/date`,
    TIME: `/post-event/${event.id}/time`,
    LOCATION: `/post-event/${event.id}/location`,

    DOCUMENTS: `/post-event/${event.id}/documents`,

    SEARCH_EXPLANATION: `/post-event/${event.id}/search-explanation`,
    EVENT_INVITE: `/event/${event.id}/invite-populate`,
    EVENT_MANAGE: `/event/${event.id}/manage`,
    EVENT_EDIT: `/event/${event.id}/edit`,
  }
};
