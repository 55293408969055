import React, { useState } from 'react';
import { SparklesIcon } from '@heroicons/react/24/outline';
import { PayloadStates } from '@lore/utils';
import useRouter from '../../hooks/useRouter';
import Urls from '../profile/Urls';
import DataCard from './DataCard';
import useUserTags from '../../hooks/useUserTags';
import TagTypes from '../../constants/TagTypes';

export default function Activities(props) {
  const { user } = props;

  const { history } = useRouter();

  const [timestamp] = useState(new Date().toISOString());

  const userActivities = useUserTags(user, TagTypes.Activity, timestamp);

  let subtitle = '';

  if (userActivities.state === PayloadStates.FETCHING) {
    subtitle = '...';
  } else {
    subtitle = userActivities.data.map(function(userActivity, index) {
      return (
        <div key={index}>
          {userActivity._tag.data.name}
        </div>
      );
    });
  }

  return (
    <DataCard
      title="Activities"
      icon={SparklesIcon}
      subtitle={subtitle || '(not provided)'}
      onClick={() => history.push(Urls(user).ACTIVITIES)}
    />
  );
};
