import React from 'react';
import { DateTime } from 'luxon';
import RightMediaSection from '../../_common/RightMediaSection';
import MiddleDot from '../../_common/MiddleDot';

export default function Incomplete(props) {
  const { evaluation, onClickView, onClickEdit } = props;

  return (
    <RightMediaSection
      // title={(
      //   <div className="text-yellow-700 space-x-1">
      //     <span>Started</span>
      //     <span>·</span>
      //     <span>Incomplete</span>
      //   </div>
      // )}
      title={(
        <span className="text-orange-500">
          Evaluation unfinished
        </span>
      )}
      subtitle={(
        <div className="flex space-x-1">
          <span>
            Last updated {DateTime.fromISO(evaluation.data.updatedAt).toRelative()}
          </span>
          {onClickEdit && (
            <>
              <span>
                <MiddleDot />
              </span>
              <span className="text-blue-500 hover:text-blue-300 cursor-pointer" onClick={onClickEdit}>
                Resume
              </span>
            </>
          )}
          {onClickView && (
            <>
              <span>
                <MiddleDot />
              </span>
              <span className="text-blue-500 hover:text-blue-300 cursor-pointer" onClick={onClickView}>
                View progress
              </span>
            </>
          )}
        </div>
      )}
    />
  );
};
