import React from 'react';
import classNames from 'classnames';
import getUrlForUpload from './getUrlForUpload';

export default function UploadImage(props) {
  const { upload } = props;

  const url = getUrlForUpload(upload);

  return (
    <div className={classNames(
      'flex flex-col relative h-32',
      'bg-cs-gray-200 rounded-2xl overflow-hidden',
      'shadow-cs-flat -m-px border border-cs-gray-100'
    )}>
      <img
        className="absolute left-0 top-0 object-cover object-center h-full w-full"
        src={url}
        alt="image"
      />
    </div>
  );
};
