import React from 'react';

export default function RatingTitle(props) {
  const {
    supertitle = 'Please rate the extent to which your students',
    subtitle = 'as a result of this activity.',
    children
  } = props;

  return (
    <>
      {supertitle} <span className="underline">{children}</span> {subtitle}
    </>
  );

  return (
    <div className="space-y-2">
      <div className="font-normal text-purple-200 text-3xl">
        {supertitle}
      </div>
      <div className="font-semibold">
        {children}
      </div>
      <div className="font-normal text-purple-200 text-3xl">
        {subtitle}
      </div>
    </div>
  );
};
