import React from 'react';
import { useUser } from '@lore/auth';
import { useConnect } from '../../../../hooks/@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Questions from './Questions';
import ComposableProjectHeader from '../../../components_cards/ComposableProjectHeader';
import Card from '../../../components/Card';
import ProjectTag from '../../../components_cards/tags/ProjectTag';
import TagRow from '../../../components_cards/tags/TagRow';
import CreatorTag from '../../../components_cards/tags/CreatorTag';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import OutlineButtonGray from '../../../components/Button/OutlineButton.gray';
import CompletedTag from '../../../components_cards/tags/_resource/CompletedTag';
import CancelledTag from '../../../components_cards/tags/_resource/CancelledTag';
import ExpiredTag from '../../../components_cards/tags/_resource/ExpiredTag';
import usePageRouter from '../../../dialogs-routable/_hooks/usePageRouter';
import EvaluationText from '../EvaluationText';
import EvaluationButton from '../EvaluationButton';
import LazyCard from '../../../components/LazyCard';

export default function ProjectCreated(props) {
  const { project } = props;

  const currentUser = useUser();
  const launch = useDialogLauncher();
  const { history } = usePageRouter();

  const evaluation = useConnect('evaluationProjectEducator.first', {
    where: {
      eager: {
        $where: {
          projectId: project.id,
          userId: currentUser.id
        }
      }
    }
  });

  function onViewProject() {
    launch(DialogManager.ProjectManage.Config(project.id, '/view'));
  }

  function onDuplicateProject() {
    launch(DialogManager.ProjectDuplicate.Config(project.id));
  }

  function onViewEvaluation() {
    launch(DialogManager.EvaluationProjectEducator.Config(evaluation.id));
  }

  function redirectToEvaluation() {
    history.push(`/evaluation-project-educator/${evaluation.id}`);
  }

  return (
    <LazyCard divider={true}>
      <ComposableProjectHeader
        project={project}
        showTags={false}
        tags={(
          <>
            <TagRow>
              <ProjectTag />
              <CreatorTag />
              <CompletedTag resource={project} />
              <CancelledTag resource={project} />
              <ExpiredTag resource={project} />
            </TagRow>
            <Questions project={project} />
          </>
        )}
      />
      <div className="flex justify-between p-4 bg-gray-50 flex-wrap gap-2">
        <div>
          {evaluation.state === PayloadStates.RESOLVED && (
            <EvaluationText
              pendingTitle="Share the impact of your project"
              evaluation={evaluation}
              onView={onViewEvaluation}
            />
          )}
        </div>
        <div className="flex flex-wrap gap-2">
          <OutlineButtonGray
            label="View project"
            onClick={onViewProject}
          />
          <OutlineButtonGray
            label="Duplicate project"
            onClick={onDuplicateProject}
          />
          {evaluation.state === PayloadStates.RESOLVED && (
            <EvaluationButton
              evaluation={evaluation}
              onEdit={redirectToEvaluation}
            />
          )}
        </div>
      </div>
    </LazyCard>
  );
}
