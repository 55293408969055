import React from 'react';
import classNames from 'classnames';
import Organization from '../../pages/my-offers/Offer/Header-new/Organization';
import DateRange from '../../pages/my-offers/Offer/Header-new/DateRange';
import Creator from '../../pages/my-offers/Offer/Header-new/Creator';
import Title from './Title';
import Description from './Description';
import StatusTags from '../../pages/my-offers/Offer/Header-new/StatusTags';
import { useConnect } from '../../../hooks/@lore/query-connect';
import Distance from '../../pages/offers/Offer/Distance';
import ClaimMeta from './ClaimMeta';
import Meta from './Meta';

export default function ComposableClaimHeader(props) {
  const {
    claim,
    tags,
    onClick,
    showTags = true,
    showTitle = true,
    showDescription = true,
    showDateRange = true,
    showCreator = true,
    showOrganization = true,
    showDistance = true,
    showClaimMeta = true
  } = props;

  const offer = useConnect('offer.byId', {
    id: claim.data.offerId
  });

  return (
    <div className="flex flex-col p-4 space-y-2">
      <div className="space-y-2">
        {tags}
        {showTags && (
          <StatusTags offer={offer}/>
        )}
        <div
          className={classNames(
            'space-y-1 pr-10',
            onClick && 'cursor-pointer hover:text-cs-gray-500'
          )}
          onClick={onClick ? () => onClick(offer) : undefined}
        >
          {showTitle && (
            <Title claim={claim} />
          )}
          {showDescription && (
            <Description claim={claim}/>
          )}
        </div>
      </div>
      <div className="flex justify-between flex-wrap gap-x-4 gap-y-2">
        <div className="flex flex-wrap gap-x-4 gap-y-2">
          {showDateRange && (
            <DateRange offer={offer}/>
          )}
          {showCreator && (
            <Creator offer={offer}/>
          )}
          {showOrganization && (
            <Organization offer={offer}/>
          )}
          {/*{showClaimMeta && (*/}
          {/*  <ClaimMeta claim={claim} />*/}
          {/*)}*/}
          {showDistance && (
            <Distance offer={offer}/>
          )}
        </div>
        {showClaimMeta && (
          <Meta claim={claim}/>
        )}
      </div>
    </div>
  );
}
