import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useDialogConfig from '../_hooks/useDialogConfig';
import QuestionViewDialog from '../question_manage';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import CloseDialogButton from '../../dialogs_common/CloseDialogButton';
import EmptyState from '../../components/EmptyState';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';

export default function Launcher(props) {
  const config = useDialogConfig();

  const question = useConnect('question.byId', {
    id: config.params.questionId
  });

  if (question.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  if (question.state === PayloadStates.ERROR_FETCHING) {
    return (
      <DialogTemplate className="max-w-screen-lg" overflow={false}>
        <CloseDialogButton onCancel={props.onClose} />
        <EmptyState
          border={false}
          icon={ExclamationTriangleIcon}
          title="You do not have permission to view this invitation."
          subtitle="Only the intended recipient can view this page."
        />
      </DialogTemplate>
    );
  }

  return (
    <QuestionViewDialog
      question={question}
      onCancel={props.onClose}
    />
  );
};
