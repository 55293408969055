import React from 'react';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import ParentLayout from '../Layout';
import Navigation from '../../../dialogs_common/Navigation';
import Urls from '../Urls';
import Form from './Form';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { location, history, match } = useRouter();
  const user = useUser();
  const project = useConnect('project.byId', {
    id: Number(match.params.projectId)
  });

  if (project.state === PayloadStates.FETCHING) {
    return null;
  }

  return (
    <ParentLayout
      project={project}
      page="Summary"
      title="In what ways would you like partners to engage in this project with you and your students?"
      description={(
        <>
          <span className="font-semibold italic">TIP</span>: What do you hope partners will do or share? You
          can list multiple needs or ideas here associated with your project. For example, you might be
          looking for a guest speaker to orient students to a specific topic first and then need mentors
          to assist students with creating their public products. Be as specific as you can for now. You
          can always refine as you talk with the partner later.
        </>
      )}
    >
      <Form
        user={user}
        project={project}
        navigation={(
          <Navigation v5={true} activeStep={4} steps={19}/>
        )}
        onPrevious={(newData) => history.push(Urls(project).DEMONSTRATION)}
        onNext={(newData) => history.push(Urls(project).PARTICIPANTS)}
        onSkip={() => history.push(Urls(project).PARTICIPANTS)}
        onExit={() => history.push(Urls(project).PROJECT_EDIT)}
      />
    </ParentLayout>
  );
};
