import React from 'react';
import { useConnect } from '@lore/query-connect';
import { getActivityIconMap } from '../../../maps/activityIconMap';
import Text, { TextColor } from '../../../typography';

export default function Header(props) {
  const { project } = props;

  const activity = useConnect('tag.byId', {
    id: project.data.activityId
  });

  const iconMap = getActivityIconMap(activity);

  return (
    <div className="flex flex-col space-y-2">
      <div className="flex items-center space-x-1">
        <iconMap.icon className="text-cs-orange-500 h-5 w-5"/>
        <div className="text-sm text-cs-orange-500 font-normal">
          {activity.data.name || '...'}
        </div>
      </div>
      <div className="space-y-0.5">
        <Text.LgSemibold>
          {project.data.title || activity.data.name}
        </Text.LgSemibold>
        <Text.BaseGrayMedium className="line-clamp-3">
          {project.data.interactionDescription || activity.data.description}
        </Text.BaseGrayMedium>
      </div>
    </div>
  );
}
