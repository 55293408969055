import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import { useUser } from '@lore/auth';
import ClampableText from '../../components/ClampableText';
import logo from '../../../assets/images/logo.png';

useEventYesEmailPreview.propTypes = {
  event: PropTypes.object.isRequired
};

export default function useEventYesEmailPreview(event, educator, customMessage) {
  // const { event } = props;

  const currentUser = useUser();

  const activity = useConnect('tag.byId', {
    id: event.data.activityId
  });

  if (activity.state === PayloadStates.FETCHING) {
    return (
      <div className="bg-cs-gray-50 p-6">
        ...
      </div>
    );
  }

  return (
    <div className="bg-cs-gray-50 p-6">
      <div className="h-1 bg-cs-orange-500 w-full mb-8"/>
      <div className="p-6 mb-8">
        <img
          className="w-24 mx-auto"
          src={logo}
          alt="logo"
        />
      </div>
      <div className="mb-8">
        <div className="text-base text-cs-gray-900">
          Hi {educator.data.firstName},
        </div>
        <div className="mt-3 text-base text-cs-gray-900">
          <ClampableText markup>
            {`${currentUser.data.firstName} ${currentUser.data.lastName} sent you an RSVP to your event **${event.data.title || activity.data.name}**.`}
          </ClampableText>
        </div>
      </div>
      <div className="mb-8">
        <div className="flex flex-col text-center justify-center bg-cs-orange-500 rounded-xl p-6">
          <div className="text-2xl text-white font-semibold mb-2">
            Let's see how they responded.
          </div>
          <div className="text-base text-white mb-4">
            We'll work together to get the right partners in place to make your event a success!
          </div>
          <div className="bg-white rounded-lg w-56 text-center py-3 m-auto">
            <div className="text-base text-cs-orange-500 uppercase font-semibold">
              Manage Partners
            </div>
          </div>
        </div>
      </div>
      <div className="mb-8 space-y-4">
        <div className="bg-white rounded-lg p-6 space-y-8">
          <div>
            <div className="text-2xl font-semibold text-cs-gray-900 mb-2">
              Need a little extra guidance on how to communicate with your partners?
            </div>
            <div className="text-base text-cs-gray-500">
              Check your messages to see if any of your partners have questions as you
              prepare for your event.
            </div>
          </div>
          <div>
            <div className="text-xl font-semibold text-cs-gray-900">
              Be Proactive
            </div>
            <div className="text-base text-cs-gray-500 mb-6">
              What will help your partner look forward to attending your event?
            </div>
            <ul className="ml-6 list-disc text-base text-cs-gray-500">
              <li>
                Thanking partners as they indicate they will attend always helps. Invite
                any questions or share information you already know.
              </li>
              <li>
                Are there student questions, materials, or other materials your partners
                can review before the event to help them feel more prepared?
              </li>
            </ul>
          </div>
          <div>
            <div className="text-xl font-semibold text-cs-gray-900">
              Visit Logistics
            </div>
            <div className="text-base text-cs-gray-500 mb-6">
              What does the partner need to know about visiting your class?
            </div>
            <ul className="ml-6 list-disc text-base text-cs-gray-500">
              <li>
                My school's policies regarding visitors are....
              </li>
              <li>
                You can park at...and check-in and get a badge at...
              </li>
              <li>
                You will be able to get to my class by...
              </li>
              <li>
                I'm attaching a map with your room assignment.
              </li>
              <li>
                What do you need in terms of classroom set-up and support?
              </li>
              <li>
                Do you need internet access?
              </li>
              <li>
                Do you need any specific equipment (e.g. projector, whiteboard, speakers etc...)?
              </li>
              <li>
                Will you have handouts that need to be printed?
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="h-1 bg-cs-orange-500 w-full"/>
    </div>
  );
};
