import React, { useState } from 'react';
import { BookOpenIcon } from '@heroicons/react/24/outline';
import useRouter from '../../hooks/useRouter';
import Urls from '../profile/Urls';
import DataCard from './DataCard';
import { PayloadStates } from '@lore/utils';
import useUserTags from '../../hooks/useUserTags';
import TagTypes from '../../constants/TagTypes';

export default function Grades(props) {
  const { user } = props;

  const { history } = useRouter();

  const [timestamp] = useState(new Date().toISOString());

  const userGrades = useUserTags(user, TagTypes.Grade, timestamp);

  let subtitle = '';

  if (userGrades.state === PayloadStates.FETCHING) {
    subtitle = '...';
  } else {
    subtitle = userGrades.data.map(function(userGrade, index) {
      return (
        <div key={index}>
          {userGrade.data.name}
        </div>
      );
    });
  }

  return (
    <DataCard
      title="Grades"
      icon={BookOpenIcon}
      subtitle={subtitle || '(not provided)'}
      onClick={() => history.push(Urls(user).GRADES)}
    />
  );
};
