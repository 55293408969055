import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { OutlineButton, SolidButton } from '../../components/Button';
import FormTemplate from '../../dialogs_common/FormTemplate';
import { CakeIcon } from '@heroicons/react/24/outline';
import { v4 as uuidv4 } from 'uuid';
import Confetti from '../project.close/confetti';
import EmptyState from '../../components/EmptyState/EmptyState.legacy';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';

Congratulations.propTypes = {
  event: PropTypes.object
};

export default function Congratulations(props) {
  const {
    event,
    onCancel,
    onPrevious,
    onNext
  } = props;

  const node = useRef();
  const [uuid] = useState(uuidv4());

  function mountConfetti() {
    // Pass in the id of an element
    let confetti = new Confetti(uuid);

    // Edit given parameters
    // confetti.setCount(75);
    confetti.setCount(150);
    confetti.setSize(1);
    // confetti.setPower(25);
    confetti.setPower(35);
    // confetti.setPower(45);
    confetti.setFade(false);
    // confetti.setFade(true);
    confetti.destroyTarget(false);
  }

  useEffect(() => {
    mountConfetti();
    setTimeout(function() {
      launchConfetti({ x: -160, delay: 0});
      launchConfetti({ x: -80, delay: 200 });
      launchConfetti({ x: 0, delay: 400 });
      launchConfetti({ x: 80, delay: 600 });
      launchConfetti({ x: 160, delay: 800 });
    }, 200);
  }, []);

  function launchConfetti({ x = 0, y = 0, delay = 0 }) {
    const clientRect = node.current.getBoundingClientRect();

    const clientX = clientRect.x + clientRect.width /2 + x;
    const clientY = clientRect.y + clientRect.height / 2;

    const event = new PointerEvent('click', {
      clientX,
      clientY
    });

    setTimeout(function() {
      // If user navigates away from dialog before confetti finishes firing
      // node.current will not exist, so only call dispatchEvent if it does
      return node.current?.dispatchEvent(event);
    }, delay);
  }

  function onClick(e) {
    launchConfetti({});
  }

  return (
    <FormTemplate
      icon={(
        <div className="flex justify-center mb-4">
          <CakeIcon className="text-cs-orange-500 h-12 w-12" />
        </div>
      )}
      title="Congratulations!"
      subtitle={(
        <>
          We'll close your event and record it as successfully completed. You'll be
          able to find it in your Past Events for future reference or to duplicate
          it to reuse in the future.
        </>
      )}
      onClose={onCancel}
      body={(
        <div className="flex justify-center space-y-8">
          <div
            ref={node}
            id={uuid}
          >
            <EmptyState>
              🎉 More confetti please! 🎉
            </EmptyState>
          </div>
        </div>
      )}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onCancel}>
            Close
          </OutlineButton>
          <SolidButton onClick={onNext}>
            Next
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
