import React from 'react';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import ParentLayout from '../Layout';
import Navigation from '../../../dialogs_common/Navigation';
import Urls from '../Urls';
import Form from './Form';
import { PayloadStates } from '@lore/utils';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { history, match } = useRouter();
  const user = useUser();
  const event = useConnect('event.byId', {
    id: Number(match.params.eventId)
  });

  if (event.state === PayloadStates.FETCHING) {
    return null;
  }

  return (
    <ParentLayout
      event={event}
      page="Student Age"
      title="How old are the students?"
      description="Please select ALL that apply."
    >
      <Form
        user={user}
        event={event}
        navigation={(
          <Navigation v5={true} activeStep={3} steps={13}/>
        )}
        onPrevious={(newData) => history.push(Urls(event).STUDENT_COUNT)}
        onNext={(newData) => history.push(Urls(event).STUDENT_SIZE)}
        onSkip={() => history.push(Urls(event).STUDENT_SIZE)}
        onExit={() => history.push(Urls(event).EVENT_EDIT)}
      />
    </ParentLayout>
  );
};
