import React from 'react';
import ContentCard from './ContentCard';
import { getActivityIconMap } from '../maps/activityIconMap';
import SubSectionHeader from './SubSectionHeader';
import { SparklesIcon } from '@heroicons/react/24/outline';

BaseActivity.Loading = function(props) {
  return (
    <ContentCard>
      <SubSectionHeader
        icon={SparklesIcon}
        iconSize="large"
        iconColor="yellow"
        title="..."
        subtitle="..."
      />
    </ContentCard>
  );
}

export default function BaseActivity(props) {
  const { activity } = props;

  const iconMap = getActivityIconMap(activity);

  return (
    <ContentCard>
      <SubSectionHeader
        icon={iconMap.icon}
        iconSize="large"
        iconColor="yellow"
        title={activity.data.name}
        subtitle={activity.data.description}
      />
    </ContentCard>
  );
};
