import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../../pages/_post-opportunity/forms/Tooltip';
import classNames from 'classnames';
import Text from '../../typography';

StringField.propTypes = {
  value: PropTypes.string,
  label: PropTypes.node,
  placeholder: PropTypes.string,
  description: PropTypes.node,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.node,
  optional: PropTypes.bool
};

StringField.defaultProps = {
  disabled: false
};

export default function StringField(props) {
  const {
    value,
    label,
    placeholder,
    description,
    disabled,
    onChange,
    error,
    rows,
    ref,
    optional = false,
    required = false,
    tooltip,
    icon,
    inputClassName,
    shadow = true,
    border = true,
    focus = true
  } = props;

  return (
    <div>
      {label && (
        <div className="relative flex space-x-0.5 text-sm text-cs-gray-500 mb-1">
          {required && (
            <span className="inline-block text-cs-orange-500">*</span>
          )}
          <span className="flex items-center space-x-1">
            <Text.Base>
              {label}
            </Text.Base>
            {tooltip && (
              <div>
                <Tooltip tooltip={tooltip} label={true} />
              </div>
            )}
          </span>
          {optional && (
            <div className="absolute top-0 right-0 text-cs-gray-300 text-sm leading-6">
              Optional
            </div>
          )}
        </div>
      )}
      <div className="relative">
        {icon && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <props.icon className="h-5 w-5 text-cs-gray-400" aria-hidden="true" />
          </div>
        )}
        <textarea
          ref={ref}
          rows={rows}
          className={classNames(
            icon && 'pl-10',
            // 'focus:ring-orange-500 focus:border-cs-orange-500',
            shadow && 'shadow-cs-flat',
            focus && 'focus:ring-cs-blue-500 focus:border-cs-blue-500',
            'w-full py-2',
            border && '-m-px border border-cs-gray-300 rounded-md',
            'placeholder:text-cs-gray-400 placeholder:font-normal text-cs-gray-900',
            disabled ? 'bg-cs-gray-100' : 'bg-white',
            inputClassName
          )}
          value={value}
          placeholder={placeholder}
          readOnly={disabled}
          onChange={(event) => {
            onChange(event.target.value)
          }}
        />
      </div>
      {error && (
        <div className="mt-2 text-sm text-red-500">
          {error}
        </div>
      )}
      {description && (
        <Text.SmGrayMedium className="mt-2">
          {description}
        </Text.SmGrayMedium>
      )}
    </div>
  );
}
