import React, { useState } from 'react';
import { useConnect } from '@lore/query-connect';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import useRouter from '../../hooks/useRouter';
import Urls from '../event-create/Urls';
import DataCard from '../profile-edit/DataCard';
import { PayloadStates } from '@lore/utils';
import usePageRouter from '../../dialogs-routable/_hooks/usePageRouter';

export default function Documents(props) {
  const { event } = props;

  const { history } = usePageRouter();

  const [timestamp] = useState(new Date().toISOString());

  const uploads = useConnect('upload.find', {
    where: {
      eager: {
        $where: {
          eventId: event.id,
          createdAt: {
            $lte: timestamp
          }
        }
      }
    }
  });

  let subtitle = '';

  if (uploads.state === PayloadStates.FETCHING) {
    subtitle = '...';
  } else {
    subtitle = `${uploads.data.length} document${uploads.data.length === 1 ? '' : 's'}`
  }

  return (
    <DataCard
      title="Documents"
      icon={DocumentDuplicateIcon}
      subtitle={subtitle}
      onClick={() => history.push(Urls(event).DOCUMENTS)}
    />
  );
};
