import React from 'react';
import _ from 'lodash';
import { DocumentDuplicateIcon, EyeIcon, TrashIcon } from '@heroicons/react/24/outline';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../_hooks/useDialogLauncher';
import ActionBarMenuBase from '../../_components_action_bar/ActionBarMenuBase';

export default function ActionBarMenu(props) {
  const { offer } = props;

  const launch = useDialogLauncher();

  function onCancel() {
    launch(DialogManager.OfferCancel.Config(offer.id));
  }

  function onChangeVisibility() {
    launch(DialogManager.OfferVisibility.Config(offer.id));
  }

  function onDuplicate() {
    launch(DialogManager.OfferDuplicate.Config(offer.id));
  }

  const links = _.remove([
    {
      type: 'button',
      icon: TrashIcon,
      label: offer.data.cancelled ? 'Cancelled' : 'Cancel',
      onClick: onCancel,
      active: offer.data.cancelled,
      activeColor: 'red'
    },
    {
      type: 'button',
      icon: DocumentDuplicateIcon,
      label: 'Duplicate Offer',
      onClick: onDuplicate,
      active: false
    },
    {
      type: 'button',
      icon: EyeIcon,
      label: offer.data.published ? 'Set Offer Visibility' : 'Set Offer Visibility',
      onClick: onChangeVisibility,
      active: offer.data.published,
      activeColor: 'green'
    },
  ], entry => entry);

  return (
    <ActionBarMenuBase links={links} />
  );
};
