import React from 'react';
import { UseQueryConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import Invitation from './Invitation';

export default function Invitations(props) {
  const { invitations } = props;

  return (
    <>
      {invitations.data.map(function(invitation, index) {
        return (
          <UseQueryConnect key={index} _key="event.byId" params={{ id: invitation.data.eventId }}>
            {function(event) {
              if (event.state === PayloadStates.FETCHING) {
                return (
                  <Loader />
                );
              }

              return (
                <Invitation invitation={invitation} />
              );
            }}
          </UseQueryConnect>
        );
      })}
    </>
  );
}
