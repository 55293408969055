import React from 'react';
import { DateTime } from 'luxon';
import { PayloadStates } from '@lore/utils';
import { CalendarIcon } from '@heroicons/react/24/outline';
import MissingField from './MissingField';
import HiddenField from './HiddenField';
import useMissingFields from './useMissingFields';

export default function MissingFields(props) {
  const { project } = props;

  const { missingFields } = useMissingFields(project);

  if (missingFields.state === PayloadStates.FETCHING) {
    return null;
  }

  if (
    missingFields.data.length > 0
  ) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {missingFields.data.map(function(missingField) {
          return (
            <MissingField
              key={missingField.key}
              name={missingField.name}
              description={(
                <>
                  You must provide this field before you can connect with partners,
                  or before your project will be shown to others.
                </>
              )}
              to={missingField.to}
            />
          );
        })}
      </div>
    );
  }

  const inPast = (
    project.data.specificDate === 'Yes' &&
    project.data.date &&
    DateTime.now().diff(DateTime.fromISO(project.data.date)).milliseconds > 0
  ) || (
    project.data.endDate &&
    DateTime.now().diff(DateTime.fromISO(project.data.endDate)).milliseconds > 0
  );

  const matchingDates = project.data.startDate === project.data.endDate;

  if (inPast || matchingDates) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {inPast && (
          <HiddenField
            name="Your project has expired!"
            description={(
              <>
                Partners can no longer see your project because it's set in the
                past. Change the date to make it visible again.
              </>
            )}
            to={`/post-project/${project.id}/date`}
          />
        )}
        {matchingDates && (
          <HiddenField
            icon={CalendarIcon}
            name="Starting and ending date is the same."
            description={(
              <>
                Did you mean to provide a range? If your project must occur on a
                specific date, you can ignore this notice.
              </>
            )}
            to={`/post-project/${project.id}/date`}
          />
        )}
      </div>
    );
  }

  return null;
};
