import React from 'react';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import { stringify } from 'query-string';
import { BeakerIcon } from '@heroicons/react/24/outline';
import OptionCard from '../../my-network/OptionCard';
import useQuery from '../../projects/useQuery';
import useUserTags from '../../../hooks/useUserTags';
import TagTypes from '../../../constants/TagTypes';
import Loader from '../../../components/Loader';

export default function MatchingOffers(props) {
  const currentUser = useUser();

  const userSubjects = useUserTags(currentUser, TagTypes.Subject);

  const params = {
    search: {
      networks: `${currentUser.data.networkId}`,
      locationCondition: JSON.stringify({
        address: currentUser.data.zipCode,
        latitude: currentUser.data.latitude,
        longitude: currentUser.data.longitude,
        radius: 50
      }),
      subjects: userSubjects.data.length > 0 ?
        userSubjects.data.map(userSubject => userSubject.data.tagId).join(',') :
        undefined
    }
  };

  const offers = useQuery(params);

  if (
    userSubjects.state === PayloadStates.FETCHING ||
    offers.state === PayloadStates.FETCHING
  ) {
    return (
      <Loader />
    );
  }

  if (
    userSubjects.meta?.totalCount === 0 ||
    offers.meta?.totalCount === 0
  ) {
    return null;
  }

  return (
    <OptionCard
      count={offers.meta?.totalCount}
      // imageUrl={bookshelfImage}
      icon={BeakerIcon}
      title="See offers that match your subject areas"
      subtitle="Community partners are offering exciting opportunities for learners in your area."
      linkText="View"
      to={{
        pathname: '/offers',
        search: stringify(params.search)
      }}
    />
  );
}
