import React from 'react';
import PropTypes from 'prop-types';
import FormField from '../../forms/_common/FormField';
import { OutlineButton, SolidButton } from '../../components/Button';
import FormTemplate from '../../dialogs_common/FormTemplate';
import FormSection from '../../pages/_post-opportunity/forms/FormSection';
import RadioButtonField from '../../pages/_post-opportunity/_fields/RadioButtonField.cards';
import { CalendarIcon, FolderIcon } from '@heroicons/react/24/outline';
import Type from './Type';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  activityTypes: PropTypes.object,
  ages: PropTypes.object
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onCancel
  } = props;

  return (
    <FormTemplate
      // title={(
      //   <div className="text-2xl font-normal">
      //     We're here to help you find the right partner(s) to make your idea a reality. Which
      //     sounds <span className="font-semibold underline">more</span> like who you're hoping to find with
      //     CommunityShare?
      //   </div>
      // )}
      title="Let's get started."
      // title="Let's get started. First, tell us which best describes what you want to create:"
      subtitle="First, tell us which best describes what you want to create:"
      onClose={onCancel}
      alert={alert}
      body={(
        <div className="space-y-8">
          <FormSection
            // title="Date is..."
            // subtitle="Select one or many."
          >
            <FormField name="type" data={data} errors={showErrors && errors} onChange={onChange}>
              {(field) => (
                <>
                  <RadioButtonField
                    name="type"
                    // label="Does your partner need to come from specific career field(s) or have special expertise?"
                    value={field.value}
                    error={field.error}
                    placeholder="Select a statement..."
                    options={{
                      data: [
                        [
                          'project_event',
                          <>
                            I want to create a project or event with my learners and invite partners
                            from our community.
                          </>
                        ],
                        [
                          `offer`,
                          <>
                            I want to offer an experience to educators or learners. (e.g. event, tour,
                            competition, etc.)
                          </>
                        ]
                      ].map((option) => {
                        return {
                          id: option[0],
                          data: {
                            name: option[1]
                          }
                        }
                      })
                    }}
                    optionLabel="name"
                    onChange={(value) => field.onChange(value)}
                  />

                </>
              )}
            </FormField>
          </FormSection>
        </div>
      )}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onCancel}>
            Cancel
          </OutlineButton>
          <SolidButton onClick={onSubmit}>
            {
              data.type === 'fixed' ? 'Create Event' :
              data.type === 'flexible' ? 'Create Project' :
              'Next'
            }
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
