import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import OrganizationCreateDialog from '../../dialogs/organization.create';

export default function Launcher(props) {
  const queryClient = useQueryClient();

  return (
    <OrganizationCreateDialog
      onCancel={props.onClose}
      onSubmit={function(request) {
        queryClient.invalidateQueries({ queryKey: ['job'] });
      }}
    />
  );
};
