import React from 'react';

export default function Section(props) {
  const { title, subtitle, children } = props;

  return (
    <div>
      <div className="mb-6">
        <h2 className="text-2xl font-semibold text-cs-gray-900">
          {title}
        </h2>
        <div className="text-cs-gray-500">
          {subtitle}
        </div>
      </div>
      {children}
    </div>
  );
};
