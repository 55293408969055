import React from 'react';
import PropTypes from 'prop-types';
import { getActivityIconMap } from '../../../maps/activityIconMap';
import SelectableIconCard from './SelectableIconCard';

Activity.propTypes = {
  activity: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};

export default function Activity(props) {
  const {
    activity,
    onClick,
    selected = false,
    className
  } = props;

  const iconMap = getActivityIconMap(activity);

  return (
    <SelectableIconCard
      icon={iconMap.icon}
      name={activity.data.name}
      description={activity.data.description}
      onClick={onClick}
      selected={selected}
      className={className}
    />
  );
}
