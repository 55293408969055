import React from 'react';
import PropTypes from 'prop-types';
import { TrashIcon } from '@heroicons/react/24/outline';
import { useQueryMutation } from '@lore/query-connect';
import { useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import UploadImage from '../../user/User/UploadImage';

UploadImageDestroyable.propTypes = {
  upload: PropTypes.object
};

export default function UploadImageDestroyable(props) {
  const { upload } = props;

  const queryClient = useQueryClient();

  const mutation = useQueryMutation('upload.destroy', upload);
  const { saving, success, error, request } = mutation;

  function onDestroy() {
    mutation.mutate(null, {
      onSuccess: function() {
        queryClient.invalidateQueries({ queryKey: ['upload', 'find'] });
      }
    });
  }

  return (
    <div className={classNames(
      'relative',
      saving && 'opacity-50'
    )}>
      <UploadImage
        upload={upload}
      />
      <div className="absolute top-1.5 right-2">
        <div
          className="p-1 bg-white bg-cs-gray-100x border border-cs-gray-500 rounded-full opacity-70 hover:opacity-100 cursor-pointer"
          onClick={onDestroy}
        >
          <TrashIcon className="h-5 w-5 text-cs-blue-500x text-cs-gray-500" />
        </div>
      </div>
    </div>
  );
}
