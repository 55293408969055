import React from 'react';
import PropTypes from 'prop-types';
import { OutlineButton } from '../components/Button';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';

PropTypes.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
};

export default function SubheaderButton(props) {
  const {
    icon= ArrowLeftIcon,
    label='Back',
    onClick=() => {}
  } = props;

  return (
    <OutlineButton onClick={onClick} icon={icon}>
      <span className="o-subheader__button-label">
        {label}
      </span>
    </OutlineButton>
  );
};
