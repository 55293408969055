import React from 'react';
import PropTypes from 'prop-types';
import { useUser } from '@lore/auth';
import ConnectButton from './ConnectButton';
import Wrapper from './Wrapper';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';

RedirectBanner.propTypes = {
  user: PropTypes.object.isRequired
};

export default function RedirectBanner(props) {
  const { user, onClose } = props;

  const currentUser = useUser();
  const launch = useDialogLauncher();

  function showInviteDialog() {
    if (user.data.isEducator) {
      launch(DialogManager.RedirectConnect.Config(user.id));
    } else {
      launch(DialogManager.RedirectConnect.Config(user.id));
    }
  }

  if (user.id === currentUser.id) {
    return (
      <Wrapper user={user}>
        <ConnectButton disabled={true}>
          This is you
        </ConnectButton>
      </Wrapper>
    );
  }

  return (
    <Wrapper user={user}>
      <ConnectButton onClick={showInviteDialog}>
        Connect
      </ConnectButton>
    </Wrapper>
  );
};
