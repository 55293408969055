import React from 'react';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Navigation from '../../../dialogs_common/Navigation';
import ParentLayout from '../ParentLayout';
import Urls from '../Urls';
import Form from './Form';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { location, history, match } = useRouter();
  const user = useUser();
  const evaluation = useConnect('evaluationProjectEducator.byId', {
    id: Number(match.params.evaluationId)
  })

  if (evaluation.state === PayloadStates.FETCHING) {
    return null;
  }

  return (
    <ParentLayout
      evaluation={evaluation}
      page="Journey"
      title={(
        <>
          What led you to this learning journey?
        </>
      )}
      description={(
        <>
          <span className="font-semibold">Example:</span> I wanted to figure out how to cover
          our state's history standards in ways that would help my students see that history
          is alive today and can be explored in creative ways.
        </>
      )}
    >
      <Form
        user={user}
        evaluation={evaluation}
        navigation={(
          <Navigation v5={true} activeStep={8} steps={14}/>
        )}
        onPrevious={(newData) => history.push(Urls(evaluation).PRACTICED)}
        onNext={(newData) => history.push(Urls(evaluation).ADVENTURES)}
        onSkip={() => history.push(Urls(evaluation).ADVENTURES)}
        onExit={() => history.push(Urls(evaluation).PROJECT_MANAGE)}
      />
    </ParentLayout>
  );
};
