/**
 * This component sets the sentry scope to link errors to the current user
 */

import { useEffect } from 'react';
import * as Sentry from '@sentry/browser';
import { useUser } from '@lore/auth';
import { useConfig } from '@lore/config';

export default function SentryUser(props) {
  const user = useUser();
  const config = useConfig();

  useEffect(()=> {
    if (config.raven.enabled) {
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: user.id,
          username: user.data.username,
          email: user.data.email
        });
      });
    }
  }, []);

  return null;
};
