import React from 'react';
import MessageCreateForm from '../../forms/MessageCreateForm.v2';
import isSleeping from '../../utils/isSleeping';
import Alert from '../../components/Alert';
import { ExclamationTriangleIcon, MoonIcon } from '@heroicons/react/24/outline';
import { DateTime } from 'luxon';
import classNames from 'classnames';
import { useUser } from '@lore/auth';

export default function Footer(props) {
  const { user, project, event, offer } = props;

  const currentUser = useUser();

  const className = classNames(
    'bg-cs-gray-50 p-6',
    '-mt-px border-t border-cs-gray-200 shadow-cs-flat'
  );

  if (user.data.inactive) {
    return (
      <div className={className}>
        <Alert
          icon={ExclamationTriangleIcon}
          color="gray"
          title="This profile is inactive"
          subtitle={(
            <>
              {user.data.firstName} is no longer an active member of CommunityShare. Messaging is disabled.
            </>
          )}
        />
      </div>
    );
  }

  if (isSleeping(user)) {
    return (
      <div className={className}>
        <Alert
          icon={MoonIcon}
          color="blue"
          title="This profile is asleep."
          subtitle={(
            <>
              Messaging is disabled for sleeping profiles. {user.data.firstName} will return to CommunityShare
              on <span className="font-semibold">{DateTime.fromISO(user.data.sleepingEndingAt).setZone(currentUser.data.timezone).toLocaleString(DateTime.DATE_MED)}</span>.
              Please check back then if you want to connect.
            </>
          )}
        />
      </div>
    );
  }

  return (
    <div className={className}>
      <MessageCreateForm
        user={user}
        project={project}
        event={event}
        offer={offer}
      />
    </div>
  );
};
