import React from 'react';
import EmptyState, { EmptyStateActions } from '../../components/EmptyState';
import { LightBulbIcon } from '@heroicons/react/24/outline';
import useDialogLauncher from '../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../dialogs_new/DialogManager';
import ProtectedButton from '../../components/Button/ProtectedButton';
import { SolidButton } from '../../components/Button';

export default function LeadingCallToAction(props) {
  const launch = useDialogLauncher();

  function onChooseProjectEventOffer() {
    launch(DialogManager.SelectorProjectEventOffer.Config());
  }

  return (
    <EmptyState
      icon={LightBulbIcon}
      title="Let's create something incredible!"
      subtitle={(
        <>
          Create a project, event, or offer.
        </>
      )}
    >
      <EmptyStateActions>
        <ProtectedButton
          button={SolidButton}
          label="Create something new!"
          onClick={onChooseProjectEventOffer}
        />
      </EmptyStateActions>
    </EmptyState>
  );
}
