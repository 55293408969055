import React from 'react';
import { Helmet } from 'react-helmet-async';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import RemoveLoadingScreen from '../RemoveLoadingScreen';
import SolidButton from '../../components/Button/SolidButton';
import useIntercom from '../../hooks/useIntercom';
import Card from '../../components/Card';
import { DialogManager } from '../../dialogs_new/DialogManager';
import useDialogLauncher from '../../dialogs-routable/_hooks/useDialogLauncher';

export default function Layout(props) {
  const launch = useDialogLauncher();
  useIntercom();

  function onShowDialog() {
    launch(DialogManager.ProfileReactivate.Config());
  }

  return (
    <div className="p-2 md:p-6">
      <Helmet>
        <title>Inactive</title>
      </Helmet>
      <RemoveLoadingScreen/>
      <Card className="shadow-welcome max-w-screen-md mx-auto space-y-12">
        <div className="pt-8 md:pt-12 px-10 md:px-20">
          <div className="mb-12">
            <ExclamationTriangleIcon className="h-32 text-red-700 m-auto bg-red-200 rounded-full p-6" alt="icon"/>
          </div>
          <h5 className="text-3xl font-semibold text-cs-gray-900 text-center max-w-xl m-auto">
            Your account is currently inactive, but we'd love to have you back!
          </h5>
          <div className="font-normal mt-4 text-cs-gray-700 text-center text-lg space-y-4">
            <div>
              Our goal is to connect people. Responsive communities are happy communities. We
              deactivate accounts if a community member hasn't logged in over a year, has an
              invalid email address, or has NOT replied to three (3) or more invitations.
            </div>
            <div>
              Reactivate your account by clicking the button below, which sends a request to
              our CommunityShare team. We'll do a quick check to make sure we have the right
              email and phone number on record and have you back in the community right away!
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <SolidButton className="px-20" onClick={onShowDialog}>
            Reactivate my account
          </SolidButton>
        </div>
        <div className="flex flex-col-reverse sm:flex-row items-center justify-end px-10 pb-16 md:px-20">
          <div className="text-center m-auto">
            <div>Need help?</div>
            <div>
              We're here for you at <a className="text-cs-orange-500" href="mailto:support@communityshare.org">support@communityshare.org</a>.
            </div>
          </div>
        </div>
      </Card>
      <div className="bg-cs-yellow-500 fixed top-0 bottom-0 left-0 right-0 -z-1" />
    </div>
  );
}
