import getQueryUtils, { attributeKeys } from './queryUtils';
import { useConnect } from '@lore/query-connect';
import { parse } from 'query-string';

export default function useQuery(props) {
  const {
    search = {},
    pageSize = 6
  } = props;

  const queryUtils = getQueryUtils(search, attributeKeys);

  return useConnect('story.find', {
    where: {
      eager: {
        $where: {
          $and: [
            ...queryUtils.where
          ]
        }
      }
    },
    pagination: {
      page: parse(location.search).page || '1',
      pageSize: pageSize,
      order: 'createdAt desc'
    }
  }, { keepPreviousData : true });
}
