import { useConnect } from '@lore/query-connect';
import useTags from './useTags';
import useTagTrees from './useTagTrees';

export default function useUserTags(user, type) {
  const tags = useTags();
  const tagTrees = useTagTrees();

  const tagNodes = useConnect('tagNode.find', {
    where: {
      eager: {
        $where: {
          'tag.type': type,
          userId: user.id
        }
      }
    },
    pagination: {
      pageSize: 1000
    }
  });

  return {
    ...tagNodes,
    data: tagNodes.data.map(function(tagNode) {
      const tag = _.find(tags.data, tag => tag.id === tagNode.data.tagId);
      const tagTree = _.find(tagTrees.data, tagTree => tagTree.data.childId === tagNode.data.tagId);
      let parentTag = null;

      if (tagTree) {
        parentTag = _.find(tags.data, tag => tag.id === tagTree.data.parentId);
      }

      return {
        id: tagNode.id,
        cid: tagNode.cid,
        data: tag.data,
        _tag: tag,
        _tagNode: tagNode,
        _tagTree: tagTree,
        _parentTag: parentTag,
        _meta: 'generated in useUserTags',
      }
    })
  }
}
