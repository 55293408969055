import React from 'react';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Navigation from '../../../dialogs_common/Navigation';
import ParentLayout from '../ParentLayout';
import Urls from '../Urls';
import Form from './Form';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { location, history, match } = useRouter();
  const user = useUser();
  const evaluation = useConnect('evaluationProjectEducator.byId', {
    id: Number(match.params.evaluationId)
  })

  if (evaluation.state === PayloadStates.FETCHING) {
    return null;
  }

  return (
    <ParentLayout
      evaluation={evaluation}
      page="Challenges"
      title={(
        <>
          What challenges did you face along the way and how did you work to overcome them?
        </>
      )}
      description={(
        <>
          <span className="font-semibold">Example:</span> The biggest challenge was coordinating
          times with all the different speakers and figuring out how to best integrate them
          into my history curriculum so that my students saw a common thread throughout. It
          was critical to develop a clear essential question guiding our learning in order to
          see how and when different speakers and activities directly supported the learning
          goals behind our question.
        </>
      )}
    >
      <Form
        user={user}
        evaluation={evaluation}
        navigation={(
          <Navigation v5={true} activeStep={10} steps={14}/>
        )}
        onPrevious={(newData) => history.push(Urls(evaluation).ADVENTURES)}
        onNext={(newData) => history.push(Urls(evaluation).CHANGES)}
        onSkip={() => history.push(Urls(evaluation).CHANGES)}
        onExit={() => history.push(Urls(evaluation).PROJECT_MANAGE)}
      />
    </ParentLayout>
  );
};
