import React, { useState } from 'react';
import { ClockIcon } from '@heroicons/react/24/outline';
import useRouter from '../../hooks/useRouter';
import Urls from '../event-create/Urls';
import DataCard from '../profile-edit/DataCard';
import { useUser } from '@lore/auth';
import { PayloadStates } from '@lore/utils';
import useEventTags from '../../hooks/useEventTags';
import TagTypes from '../../constants/TagTypes';
import { formatTimeRange } from '../../utils/dateTimeUtils';
import usePageRouter from '../../dialogs-routable/_hooks/usePageRouter';

export default function Time(props) {
  const { event } = props;
  const [timestamp] = useState(new Date().toISOString());

  const { history } = usePageRouter();
  const currentUser = useUser();

  let subtitle = '';

  const eventTimes = useEventTags(event, TagTypes.Time, timestamp);

  if (event.data.specificTime === 'Yes') {
    subtitle = formatTimeRange(event.data.startTime, event.data.endTime, currentUser.data.timezone);
  } else if(eventTimes.state === PayloadStates.FETCHING) {
    subtitle = '...';
  } else {
    subtitle = eventTimes.data.map(function(eventTime) {
      return eventTime.data.name;
    }).join(', ');
  }

  return (
    <DataCard
      title="Time"
      icon={ClockIcon}
      subtitle={subtitle || '(not provided)'}
      onClick={() => history.push(Urls(event).TIME)}
    />
  );
};
