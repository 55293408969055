import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button, { defaultClassNames } from './Button';

OutlineButtonBlue100.propTypes = {
  icon: PropTypes.object,
  label: PropTypes.node,
  children: PropTypes.node,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

export default function OutlineButtonBlue100(props) {
  const {
    children,
    label,
    onClick,
    disabled,
    icon
  } = props;

  return (
    <Button
      icon={icon && (
        <props.icon className={classNames(
          'h-5 w-5',
          'text-cs-blue-100'
        )} />
      )}
      label={label || children}
      onClick={onClick}
      disabled={disabled}
      overrideClassNames={{
        ...defaultClassNames,
        background:  'bg-transparent',
        border: '-m-px border border-cs-blue-100 border-opacity-30x',
        textColor:  'text-cs-blue-100',
        hover:  'cursor-pointer hover:bg-cs-blue-400',
      }}
    />
  );
};
