import React from 'react';
import { Helmet } from 'react-helmet-async';
import { stringify } from 'query-string';
import Redirect from '../../components/Redirect';
import { useUser } from '@lore/auth';
import PageHeader from '../people-people/PageHeader';

export default function Layout(props) {
  const currentUser = useUser();

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Find partners</title>
      </Helmet>
      <PageHeader
        title="Find partners"
        subtitle={(
          <>
            Explore and connect with community members with skills and experiences
            to help bring your project or event to life. Use filters to search by skills,
            life experiences, and more.
          </>
        )}
      />
      <Redirect
        to={{
          pathname: '/partners',
          search: stringify({
            locationCondition: JSON.stringify({
              address: currentUser.data.zipCode,
              latitude: currentUser.data.latitude,
              longitude: currentUser.data.longitude,
              radius: 50
            })
          })
        }}
        replace={true}
      />
    </div>
  );
}
