import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

/*
 * Launchers
 */
import Project from '../dialogs-routable/_launchers/project';
import Event from '../dialogs-routable/_launchers/event';
import Offer from '../dialogs-routable/_launchers/offer';
import Story from '../dialogs-routable/_launchers/story';
import ProjectManage from '../dialogs-routable/_launchers/project_manage';
import ProjectClose from '../dialogs-routable/_launchers/project_close';
import ProjectCloseExtend from '../dialogs-routable/_launchers/project_close_extend';
import ProjectDuplicate from '../dialogs-routable/_launchers/project_duplicate';
import ProjectVisibility from '../dialogs-routable/_launchers/project_visibility';
import EventManage from '../dialogs-routable/_launchers/event_manage';
import EventClose from '../dialogs-routable/_launchers/event_close';
import EventCloseExtend from '../dialogs-routable/_launchers/event_close_extend';
import EventDuplicate from '../dialogs-routable/_launchers/event_duplicate';
import EventVisibility from '../dialogs-routable/_launchers/event_visibility';
import OfferManage from '../dialogs-routable/_launchers/offer_manage';
import OfferCancel from '../dialogs-routable/_launchers/offer_cancel';
import OfferDuplicate from '../dialogs-routable/_launchers/offer_duplicate';
import OfferVisibility from '../dialogs-routable/_launchers/offer_visibility';
import Messages from '../dialogs-routable/_launchers/messages';
import User from '../dialogs-routable/_launchers/user';
import SiteCreate from '../dialogs-routable/_launchers/site_create';
import SiteMissing from '../dialogs-routable/_launchers/site_missing';
import OrganizationCreate from '../dialogs-routable/_launchers/organization_create';
import BiographySuggestions from '../dialogs-routable/_launchers/biography_suggestions';
import ExpertiseSuggestions from '../dialogs-routable/_launchers/expertise_suggestions';
import RoleUpdate from '../dialogs-routable/_launchers/role_update';
import RoleDestroy from '../dialogs-routable/_launchers/role_destroy';
import JobUpdate from '../dialogs-routable/_launchers/job_update';
import JobDestroy from '../dialogs-routable/_launchers/job_destroy';
import TagNodeUpdate from '../dialogs-routable/_launchers/tagNode_update';
import TagNodeDestroy from '../dialogs-routable/_launchers/tagNode_destroy';
import TagNodeCreate from '../dialogs-routable/_launchers/tagNode_create';
import FiltersPartner from '../dialogs-routable/_launchers/filters_partner';
import FiltersEducator from '../dialogs-routable/_launchers/filters_educator';
import FiltersProject from '../dialogs-routable/_launchers/filters_project';
import FiltersEvent from '../dialogs-routable/_launchers/filters_event';
import FiltersProjectEvent from '../dialogs-routable/_launchers/filters_project_event';
import FiltersOffer from '../dialogs-routable/_launchers/filters_offer';
import FiltersStory from '../dialogs-routable/_launchers/filters_story';
import Welcome from '../dialogs-routable/_launchers/welcome';
import SelectorProjectEvent from '../dialogs-routable/_launchers/selector_project_event';
import SelectorProjectEventOffer from '../dialogs-routable/_launchers/selector_project_event_offer';
import QuestionDiscover from '../dialogs-routable/_launchers/question_discover';
import QuestionConnect from '../dialogs-routable/_launchers/question_connect';
import QuestionDecline from '../dialogs-routable/_launchers/question_decline';
import QuestionInvite from '../dialogs-routable/_launchers/question_invite';
import QuestionView from '../dialogs-routable/_launchers/question_view';
import QuestionClassify from '../dialogs-routable/_launchers/question_classify';
import ClaimDiscover from '../dialogs-routable/_launchers/claim_discover';
import ClaimConnect from '../dialogs-routable/_launchers/claim_connect';
import ClaimDecline from '../dialogs-routable/_launchers/claim_decline';
import ClaimInvite from '../dialogs-routable/_launchers/claim_invite';
import ClaimView from '../dialogs-routable/_launchers/claim_view';
import InvitationDiscoverMaybe from '../dialogs-routable/_launchers/invitation_discover_maybe';
import InvitationDiscoverYes from '../dialogs-routable/_launchers/invitation_discover_yes';
import InvitationInvite from '../dialogs-routable/_launchers/invitation_invite';
import InvitationMaybe from '../dialogs-routable/_launchers/invitation_maybe';
import InvitationNo from '../dialogs-routable/_launchers/invitation_no';
import InvitationYes from '../dialogs-routable/_launchers/invitation_yes';
import InvitationView from '../dialogs-routable/_launchers/invitation_view';
import RedirectConnect from '../dialogs-routable/_launchers/redirect_connect';
import ChangeEmail from '../dialogs-routable/_launchers/change_email';
import ProfileManage from '../dialogs-routable/_launchers/profile_manage';
import ProfileDelete from '../dialogs-routable/_launchers/profile_delete';
import ProfileMissing from '../dialogs-routable/_launchers/profile_missing';
import ProfileReactivate from '../dialogs-routable/_launchers/profile_reactivate';

import EvaluationProjectEducator from '../dialogs-routable/_launchers/evaluation_project_educator';
import EvaluationProjectPartner from '../dialogs-routable/_launchers/evaluation_project_partner';
import EvaluationEventEducator from '../dialogs-routable/_launchers/evaluation_event_educator';
import EvaluationEventPartner from '../dialogs-routable/_launchers/evaluation_event_partner';

function format(type, params = {}, route = '/', options = {}) {
  return {
    id: uuidv4(),
    type: type,
    params: params,
    route: route,
    options: options
  };
}

export const DialogManager = {
  Project: {
    Key: 'project',
    Config: function(id) {
      return format('project', {
        projectId: id
      }, undefined, { topAlign: true });
    },
    Launcher: Project
  },
  Event: {
    Key: 'event',
    Config: function(id) {
      return format('event', {
        eventId: id
      }, undefined, { topAlign: true });
    },
    Launcher: Event
  },
  Offer: {
    Key: 'offer',
    Config: function(id) {
      return format('offer', {
        offerId: id
      }, undefined, { topAlign: true });
    },
    Launcher: Offer
  },
  Story: {
    Key: 'story',
    Config: function(id) {
      return format('story', {
        storyId: id
      }, undefined, { topAlign: true });
    },
    Launcher: Story
  },
  ProjectManage: {
    Key: 'project_manage',
    Config: function(id, route = '/manage') {
      return format('project_manage', {
        projectId: id
      }, route, { topAlign: true });
    },
    Launcher: ProjectManage
  },
  ProjectClose: {
    Key: 'project_close',
    Config: function(id) {
      return format('project_close', {
        projectId: id
      });
    },
    Launcher: ProjectClose
  },
  ProjectCloseExtend: {
    Key: 'project_close_extend',
    Config: function(id) {
      return format('project_close_extend', {
        projectId: id
      });
    },
    Launcher: ProjectCloseExtend
  },
  ProjectDuplicate: {
    Key: 'project_duplicate',
    Config: function(id) {
      return format('project_duplicate', {
        projectId: id
      });
    },
    Launcher: ProjectDuplicate
  },
  ProjectVisibility: {
    Key: 'project_visibility',
    Config: function(id) {
      return format('project_visibility', {
        projectId: id
      });
    },
    Launcher: ProjectVisibility
  },
  EventManage: {
    Key: 'event_manage',
    Config: function(id, route = '/manage') {
      return format('event_manage', {
        eventId: id
      }, route, { topAlign: true });
    },
    Launcher: EventManage
  },
  EventClose: {
    Key: 'event_close',
    Config: function(id) {
      return format('event_close', {
        eventId: id
      });
    },
    Launcher: EventClose
  },
  EventCloseExtend: {
    Key: 'event_close_extend',
    Config: function(id) {
      return format('event_close_extend', {
        eventId: id
      });
    },
    Launcher: EventCloseExtend
  },
  EventDuplicate: {
    Key: 'event_duplicate',
    Config: function(id) {
      return format('event_duplicate', {
        eventId: id
      });
    },
    Launcher: EventDuplicate
  },
  EventVisibility: {
    Key: 'event_visibility',
    Config: function(id) {
      return format('event_visibility', {
        eventId: id
      });
    },
    Launcher: EventVisibility
  },
  OfferManage: {
    Key: 'offer_manage',
    Config: function(id, route = '/manage') {
      return format('offer_manage', {
        offerId: id
      }, route, { topAlign: true });
    },
    Launcher: OfferManage
  },
  OfferCancel: {
    Key: 'offer_cancel',
    Config: function(id) {
      return format('offer_cancel', {
        offerId: id
      });
    },
    Launcher: OfferCancel
  },
  OfferDuplicate: {
    Key: 'offer_duplicate',
    Config: function(id) {
      return format('offer_duplicate', {
        offerId: id
      });
    },
    Launcher: OfferDuplicate
  },
  OfferVisibility: {
    Key: 'offer_visibility',
    Config: function(id) {
      return format('offer_visibility', {
        offerId: id
      });
    },
    Launcher: OfferVisibility
  },
  Messages: {
    Key: 'messages',
    Config: function(id, extra = {}) {
      return format('messages', {
        userId: id,
        ...extra
      });
    },
    Launcher: Messages
  },
  User: {
    Key: 'user',
    Config: function(id, extra = {}) {
      return format('user', {
        userId: id,
        ...extra
      }, undefined, { topAlign: true });
    },
    Launcher: User
  },
  SiteCreate: {
    Key: 'site_create',
    Config: function(id) {
      return format('site_create', {
        // userId: id
      });
    },
    Launcher: SiteCreate
  },
  SiteMissing: {
    Key: 'site_missing',
    Config: function(id) {
      return format('site_missing', {
        // userId: id
      });
    },
    Launcher: SiteMissing
  },
  BiographySuggestions: {
    Key: 'biography_suggestions',
    Config: function(id) {
      return format('biography_suggestions', {
        // userId: id
      });
    },
    Launcher: BiographySuggestions
  },
  ExpertiseSuggestions: {
    Key: 'expertise_suggestions',
    Config: function(id) {
      return format('expertise_suggestions', {
        // userId: id
      });
    },
    Launcher: ExpertiseSuggestions
  },
  Welcome: {
    Key: 'welcome',
    Config: function(id) {
      return format('welcome', {
        // userId: id
      });
    },
    Launcher: Welcome
  },
  SelectorProjectEvent: {
    Key: 'selector_project_event',
    Config: function(id) {
      return format('selector_project_event', {
        // userId: id
      });
    },
    Launcher: SelectorProjectEvent
  },
  SelectorProjectEventOffer: {
    Key: 'selector_project_event_offer',
    Config: function(id) {
      return format('selector_project_event_offer', {
        // userId: id
      });
    },
    Launcher: SelectorProjectEventOffer
  },
  OrganizationCreate: {
    Key: 'organization_create',
    Config: function(id) {
      return format('organization_create', {
        // userId: id
      });
    },
    Launcher: OrganizationCreate
  },
  RoleUpdate: {
    Key: 'role_update',
    Config: function(id) {
      return format('role_update', {
        roleId: id
      });
    },
    Launcher: RoleUpdate
  },
  RoleDestroy: {
    Key: 'role_destroy',
    Config: function(id) {
      return format('role_destroy', {
        roleId: id
      });
    },
    Launcher: RoleDestroy
  },
  JobUpdate: {
    Key: 'job_update',
    Config: function(id) {
      return format('job_update', {
        jobId: id
      });
    },
    Launcher: JobUpdate
  },
  JobDestroy: {
    Key: 'job_destroy',
    Config: function(id) {
      return format('job_destroy', {
        jobId: id
      });
    },
    Launcher: JobDestroy
  },
  TagNodeUpdate: {
    Key: 'tagNode_update',
    Config: function(id) {
      return format('tagNode_update', {
        tagNodeId: id
      });
    },
    Launcher: TagNodeUpdate
  },
  TagNodeDestroy: {
    Key: 'tagNode_destroy',
    Config: function(id) {
      return format('tagNode_destroy', {
        tagNodeId: id
      });
    },
    Launcher: TagNodeDestroy
  },
  TagNodeCreate: {
    Key: 'tagNode_create',
    Config: function(id, extra = {}) {
      return format('tagNode_create', {
        tagId: id,
        ...extra
      });
    },
    Launcher: TagNodeCreate
  },
  FiltersPartner: {
    Key: 'filters_partner',
    Config: function(id) {
      return format('filters_partner', {
        parentDialogId: id,
      });
    },
    Launcher: FiltersPartner
  },
  FiltersEducator: {
    Key: 'filters_educator',
    Config: function(id) {
      return format('filters_educator', {
        parentDialogId: id,
      });
    },
    Launcher: FiltersEducator
  },
  FiltersProject: {
    Key: 'filters_project',
    Config: function(id, extra = {}) {
      return format('filters_project', {
        parentDialogId: id,
        showPast: extra.showPast
      });
    },
    Launcher: FiltersProject
  },
  FiltersEvent: {
    Key: 'filters_event',
    Config: function(id, extra = {}) {
      return format('filters_event', {
        parentDialogId: id,
        showPast: extra.showPast
      });
    },
    Launcher: FiltersEvent
  },
  FiltersProjectEvent: {
    Key: 'filters_project_event',
    Config: function(id, extra = {}) {
      return format('filters_project_event', {
        parentDialogId: id,
        showPast: extra.showPast
      });
    },
    Launcher: FiltersProjectEvent
  },
  FiltersOffer: {
    Key: 'filters_offer',
    Config: function(id) {
      return format('filters_offer', {
        parentDialogId: id,
      });
    },
    Launcher: FiltersOffer
  },
  FiltersStory: {
    Key: 'filters_story',
    Config: function(id) {
      return format('filters_story', {
        parentDialogId: id,
      });
    },
    Launcher: FiltersStory
  },
  QuestionDiscover: {
    Key: 'question_discover',
    Config: function(id) {
      return format('question_discover', {
        projectId: id,
      });
    },
    Launcher: QuestionDiscover
  },
  QuestionConnect: {
    Key: 'question_connect',
    Config: function(id) {
      return format('question_connect', {
        questionId: id,
      });
    },
    Launcher: QuestionConnect
  },
  QuestionDecline: {
    Key: 'question_decline',
    Config: function(id) {
      return format('question_decline', {
        questionId: id,
      });
    },
    Launcher: QuestionDecline
  },
  QuestionInvite: {
    Key: 'question_invite',
    Config: function(projectId, userId) {
      return format('question_invite', {
        projectId: projectId,
        userId: userId,
      });
    },
    Launcher: QuestionInvite
  },
  QuestionView: {
    Key: 'question_view',
    Config: function(id) {
      return format('question_view', {
        questionId: id,
      }, undefined, { topAlign: true });
    },
    Launcher: QuestionView
  },
  QuestionClassify: {
    Key: 'question_classify',
    Config: function(id) {
      return format('question_classify', {
        questionId: id,
      });
    },
    Launcher: QuestionClassify
  },
  ClaimDiscover: {
    Key: 'claim_discover',
    Config: function(id) {
      return format('claim_discover', {
        offerId: id,
      });
    },
    Launcher: ClaimDiscover
  },
  ClaimConnect: {
    Key: 'claim_connect',
    Config: function(id) {
      return format('claim_connect', {
        claimId: id,
      });
    },
    Launcher: ClaimConnect
  },
  ClaimDecline: {
    Key: 'claim_decline',
    Config: function(id) {
      return format('claim_decline', {
        claimId: id,
      });
    },
    Launcher: ClaimDecline
  },
  ClaimInvite: {
    Key: 'claim_invite',
    Config: function(offerId, userId) {
      return format('claim_invite', {
        offerId: offerId,
        userId: userId,
      });
    },
    Launcher: ClaimInvite
  },
  ClaimView: {
    Key: 'claim_view',
    Config: function(id) {
      return format('claim_view', {
        claimId: id,
      });
    },
    Launcher: ClaimView
  },
  InvitationDiscoverMaybe: {
    Key: 'invitation_discover_maybe',
    Config: function(id) {
      return format('invitation_discover_maybe', {
        eventId: id,
      });
    },
    Launcher: InvitationDiscoverMaybe
  },
  InvitationDiscoverYes: {
    Key: 'invitation_discover_yes',
    Config: function(id) {
      return format('invitation_discover_yes', {
        eventId: id,
      });
    },
    Launcher: InvitationDiscoverYes
  },
  InvitationInvite: {
    Key: 'invitation_invite',
    Config: function(eventId, userId) {
      return format('invitation_invite', {
        eventId: eventId,
        userId: userId,
      });
    },
    Launcher: InvitationInvite
  },
  InvitationMaybe: {
    Key: 'invitation_maybe',
    Config: function(id) {
      return format('invitation_maybe', {
        invitationId: id,
      });
    },
    Launcher: InvitationMaybe
  },
  InvitationNo: {
    Key: 'invitation_no',
    Config: function(id) {
      return format('invitation_no', {
        invitationId: id,
      });
    },
    Launcher: InvitationNo
  },
  InvitationYes: {
    Key: 'invitation_yes',
    Config: function(id) {
      return format('invitation_yes', {
        invitationId: id,
      });
    },
    Launcher: InvitationYes
  },
  InvitationView: {
    Key: 'invitation_view',
    Config: function(id) {
      return format('invitation_view', {
        invitationId: id,
      });
    },
    Launcher: InvitationView
  },
  RedirectConnect: {
    Key: 'redirect_connect',
    Config: function(id) {
      return format('redirect_connect', {
        userId: id,
      });
    },
    Launcher: RedirectConnect
  },
  ChangeEmail: {
    Key: 'change_email',
    Config: function(id) {
      return format('change_email', {
        // userId: id,
      });
    },
    Launcher: ChangeEmail
  },
  ProfileManage: {
    Key: 'profile_manage',
    Config: function(route = '/view') {
      return format('profile_manage', {
        // projectId: id
      }, route, { topAlign: true });
    },
    Launcher: ProfileManage
  },
  ProfileDelete: {
    Key: 'profile_delete',
    Config: function(id) {
      return format('profile_delete', {
        // userId: id,
      });
    },
    Launcher: ProfileDelete
  },
  ProfileMissing: {
    Key: 'profile_missing',
    Config: function(id) {
      return format('profile_missing', {
        // userId: id,
      });
    },
    Launcher: ProfileMissing
  },
  ProfileReactivate: {
    Key: 'profile_reactivate',
    Config: function(id) {
      return format('profile_reactivate', {
        // userId: id,
      });
    },
    Launcher: ProfileReactivate
  },
  EvaluationProjectEducator: {
    Key: 'evaluation_project_educator',
    Config: function(id) {
      return format('evaluation_project_educator', {
        evaluationProjectEducatorId: id
      });
    },
    Launcher: EvaluationProjectEducator
  },
  EvaluationProjectPartner: {
    Key: 'evaluation_project_partner',
    Config: function(id) {
      return format('evaluation_project_partner', {
        evaluationProjectPartnerId: id
      });
    },
    Launcher: EvaluationProjectPartner
  },
  EvaluationEventEducator: {
    Key: 'evaluation_event_educator',
    Config: function(id) {
      return format('evaluation_event_educator', {
        evaluationEventEducatorId: id
      });
    },
    Launcher: EvaluationEventEducator
  },
  EvaluationEventPartner: {
    Key: 'evaluation_event_partner',
    Config: function(id) {
      return format('evaluation_event_partner', {
        evaluationEventPartnerId: id
      });
    },
    Launcher: EvaluationEventPartner
  }
}

export const DialogManagerQueryParamMap = _.reduce(DialogManager, function(result, value, key) {
  result[value.Key] = {
    ...value,
    Key: key
  };
  return result;
}, {});
