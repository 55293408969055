import React from 'react';
import PropTypes from 'prop-types';
import { PayloadStates } from '@lore/utils';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import Loader from '../../components/Loader';
import Layout from './DialogLayout';
import CloseDialogButton from '../../dialogs_common/CloseDialogButton';

// ProjectManageDialog.propTypes = {
//   project: PropTypes.object.isRequired
// };

export default function ProjectManageDialog(props) {
  // const { project } = props;
  //
  // if (project.state === PayloadStates.FETCHING) {
  //   return (
  //     <DialogTemplate>
  //       <Loader />
  //     </DialogTemplate>
  //   );
  // }

  return (
    <DialogTemplate className="w-screen max-w-screen-lg">
      <CloseDialogButton onCancel={props.onCancel} />
      <div className="px-6 pt-16 pb-6">
        <Layout
          // project={project}
          onClose={props.onCancel}
        />
      </div>
    </DialogTemplate>
  );
};
