import React from 'react';
import _ from 'lodash';
import { useConnect } from '@lore/query-connect';
import User from '../people-partners/User';
import mapResponse from '../../utils/mapResponse';

export default function ProjectUser(props) {
  const { user, project } = props;

  const questions = useConnect('question.find', {
    where: {
      eager: {
        $where: {
          projectId: project.id
        }
      }
    },
    pagination: {
      pageSize: 1000
    }
  });

  const question = _.find(questions.data, function(question) {
    return question.data.userId === user.id;
  });

  const selected = !!question;

  return (
    <User
      user={user}
      project={project}
      selected={selected}
      footer={question ? mapResponse(question.data.response) : undefined}
      to={`/project/${project.id}/invite/${user.id}`}
    />
  );
}
