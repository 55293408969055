import React from 'react';
import PropTypes from 'prop-types';
import ConnectButton from '../../../pages/_banner/ConnectButton';
import Wrapper from '../../../pages/_banner/Wrapper';
import useDialogLauncher from '../../_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import { useConnect } from '../../../../hooks/@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import usePageRouter from '../../_hooks/usePageRouter';
import useRouter from '../../../hooks/useRouter';

CompletedBanner.propTypes = {
  event: PropTypes.object.isRequired
};

export default function CompletedBanner(props) {
  const { event, creator } = props;

  const launch = useDialogLauncher();
  const dialogRouter = useRouter();
  const pageRouter = usePageRouter();

  const evaluation = useConnect('evaluationEventEducator.first', {
    where: {
      eager: {
        $where: {
          eventId: event.id,
          userId: event.data.creatorId
        }
      }
    }
  });

  function onViewEvaluation() {
    launch(DialogManager.EvaluationEventEducator.Config(evaluation.id));
  }

  function onDuplicate() {
    launch(DialogManager.EventDuplicate.Config(event.id));
  }

  function onViewEvent() {
    dialogRouter.history.push('/view')
  }

  function onEditEvaluation() {
    pageRouter.history.push(`/evaluation-event-educator/${evaluation.id}`);
  }

  if (evaluation.state === PayloadStates.FETCHING) {
    return (
      <Wrapper
        color="green"
        creator={creator}
        title={(
          <div className="w-24">
            <Loader />
          </div>
        )}
      />
    );
  }

  if (
    evaluation.state === PayloadStates.RESOLVED &&
    !evaluation.data.completed
  ) {
    return (
      <Wrapper
        color="green"
        creator={creator}
        title={(
          <>
            Your event is complete. Let's tell people about it!
          </>
        )}
        subtitle={(
          <>
            Congratulations on bringing your event to life! Share your experience with our
            community to highlight your impact and inspire others.
          </>
        )}
      >
        <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
          <ConnectButton outline={true} onClick={onViewEvent}>
            View your event
          </ConnectButton>
          <ConnectButton color="green" onClick={onEditEvaluation}>
            Share your impact!
          </ConnectButton>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper
      color="green"
      creator={creator}
      title={(
        <>
          Thanks for completing this event!
        </>
      )}
      subtitle={(
        <>
          Take a moment to celebrate! Thanks for creating meaningful opportunities for
          learners. Want to revisit or build on this idea? You can duplicate your event below.
        </>
      )}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        {evaluation.state === PayloadStates.RESOLVED && (
          <ConnectButton outline={true} onClick={onViewEvaluation}>
            Revisit your impact
          </ConnectButton>
        )}
        <ConnectButton outline={true} color="green" onClick={onDuplicate}>
          Duplicate your event
        </ConnectButton>
      </div>
    </Wrapper>
  );
};
