import React from 'react';
import PropTypes from 'prop-types';
import FocusAreaEntry from '../../../components/FocusAreaEntry';
import classNames from 'classnames';

RadioButtonFieldCards.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  options: PropTypes.object.isRequired,
  onChange: PropTypes.func
};

RadioButtonFieldCards.defaultProps = {
  options: {
    data: []
  }
};

export default function RadioButtonFieldCards(props) {
  const {
    value,
    options,
    onChange,
    columns = 1,
    error
  } = props;

  const columnMap = {
    1: 'grid-cols-1',
    2: 'grid-cols-2',
    3: 'grid-cols-3'
  };

  return (
    <div className={classNames(
      'grid gap-3',
      columnMap[columns]
    )}>
      {options.data.map((datum, index) => {
        const checked = value === datum.id;

        return (
          <FocusAreaEntry
            key={datum.id || datum.cid}
            entry={datum}
            // subtitle={`${participant.data.description}`}
            selectable={true}
            selected={checked}
            // onClick={() => onSelectParticipant(participant, selectedParticipant)}
            onClick={() => onChange(datum ? datum.id : null)}
          />
        );
      })}
      {error ? (
        <div className="mt-1 text-base text-red-500">
          {error}
        </div>
      ) : null}
    </div>
  );
}
