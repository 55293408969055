import React from 'react';
import PropTypes from 'prop-types';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import ConnectButton from './ConnectButton';
import { PayloadStates } from '@lore/utils';
import Wrapper from './Wrapper';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';

OfferBanner.propTypes = {
  user: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired
};

export default function OfferBanner(props) {
  const {
    user,
    offer,
    onClose
  } = props;

  const currentUser = useUser();
  const launch = useDialogLauncher();

  const claims = useConnect('claim.find', {
    where: {
      eager: {
        $where: {
          userId: user.id,
          offerId: offer.id
        }
      }
    }
  })

  function showInviteDialog() {
    launch(DialogManager.ClaimInvite.Config(offer.id, user.id));
  }

  if (claims.state === PayloadStates.FETCHING) {
    return (
      <Wrapper user={user}>
        <ConnectButton disabled={true}>
          ...
        </ConnectButton>
      </Wrapper>
    );
  }

  if (user.id === currentUser.id) {
    return (
      <Wrapper user={user}>
        <ConnectButton disabled={true}>
          This is you
        </ConnectButton>
      </Wrapper>
    );
  }

  if (claims.data.length > 0) {
    return (
      <Wrapper user={user}>
        <ConnectButton disabled={true}>
          Connected
        </ConnectButton>
      </Wrapper>
    );
  }

  return (
    <Wrapper user={user}>
      <ConnectButton onClick={showInviteDialog}>
        Connect
      </ConnectButton>
    </Wrapper>
  );
};
