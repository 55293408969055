export default function(user) {
  return {
    PROFILE_VIEW: `/profile/view`,
    PROFILE_EDIT: `/profile/edit`,

    // basic
    NAME: `/profile/edit/name`,
    ABOUT: `/profile/edit/about`,
    AVATAR: `/profile/edit/avatar`,
    DEMOGRAPHICS: `/profile/edit/demographics`,
    PHONE: `/profile/edit/phone`,
    OPPORTUNITIES: `/profile/edit/opportunities`,

    // location and time
    LOCATION: `/profile/edit/location`,

    // workplace
    ORGANIZATIONS: `/profile/edit/organizations`,
    SITES: `/profile/edit/sites`,

    // engagement preferences
    AGES: `/profile/edit/ages`,
    ACTIVITIES: `/profile/edit/activities`,
    GROUPS: `/profile/edit/groups`,

    // past work skills
    SKILLS: `/profile/edit/skills`,

    // life experience
    EXPERIENCES: `/profile/edit/experience`,
    GUIDANCES: `/profile/edit/guidance`,

    // teaching
    GRADES:  `/profile/edit/grades`,
    SUBJECTS:  `/profile/edit/subjects`,
    PRACTICES:  `/profile/edit/practices`,

    // social
    WEBSITE: `/profile/edit/social`,
    LINKEDIN: `/profile/edit/social`,
    TWITTER: `/profile/edit/social`
  }
};
