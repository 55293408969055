import React from 'react';
import PropTypes from 'prop-types';
import LoaderButton from './LoaderButton';
import Wrapper from './Wrapper';

LoadingBanner.propTypes = {
  creator: PropTypes.object.isRequired
};

export default function LoadingBanner(props) {
  const { creator, color } = props;

  return (
    <Wrapper
      color={color}
      creator={creator}
      title={(
        <div className="invisible">...</div>
      )}
      subtitle={(
        <>
          <div className="invisible">...</div>
          <div className="invisible">...</div>
        </>
      )}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        <LoaderButton />
      </div>
    </Wrapper>
  );
}
