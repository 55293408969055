import React, { Fragment } from 'react';
import { useUser } from '@lore/auth';
import Section from '../../my-network/Section';
import OptionCard from '../../my-network/OptionCard';
import useDraftOpportunityCount from '../../my-expired/useDraftOpportunityCount';
import useActiveOpportunityCount from '../../my-expired/useActiveOpportunityCount';
import useActiveInvitationsCount from '../../my-participating/useActiveInvitationsCount';
import canvaCalendar from '../../../../assets/images/canva/canva-calendar.png';
import canvaHands from '../../../../assets/images/canva/canva-hands.png';

export default function GetInvolved(props) {
  const currentUser = useUser();

  const draftOpportunityCount = useDraftOpportunityCount() || 0;
  const activeOpportunityCount = useActiveOpportunityCount() || 0;
  const activeInvitationsCount = useActiveInvitationsCount() || 0;

  const opportunityCount = (
    draftOpportunityCount +
    activeOpportunityCount
  );

  const totalCount = (
    opportunityCount +
    activeInvitationsCount
  );

  if (totalCount === 0) {
    return null;
  }

  return (
    <Section
      title="Get involved"
      // subtitle={(
      //   <>
      //     Discover educators who are leading exciting projects and events you might
      //     contribute to. Find community partners who have skills, knowledge, and experiences
      //     to help bring your projects to life.
      //   </>
      // )}
      subtitle={(
        <>
          Explore opportunities to get involved.
        </>
      )}
    >
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3x gap-4">
        <OptionCard
          // imageUrl={involvementImage}
          // imageUrl={Images.ACTIVE_OFFERS}
          centeredImage={(
            <img className="h-40 w-40" src={canvaCalendar} alt="Logo"/>
          )}
          // centeredImageBackgroundColor="bg-cs-gray-100"
          centeredImageBackgroundColor="bg-cs-blue-500"
          // centeredImageBackgroundColor="bg-cs-orange-50"
          title="Explore current activities that match your interests"
          subtitle="Discover opportunities to get involved!"
          linkText="View"
          to="/opportunities"
        />
        <OptionCard
          centeredImage={(
            <img className="h-40 w-40" src={canvaHands} alt="Logo"/>
          )}
          // centeredImageBackgroundColor="bg-cs-gray-100"
          centeredImageBackgroundColor="bg-cs-blue-500"
          title="Discover people in your community"
          subtitle="Explore educators and find community partners here."
          linkText="Explore"
          to="/people"
        />

        {/*<OptionCard*/}
        {/*  // imageUrl={bookshelfImage}*/}
        {/*  imageUrl={Images.EXPLORE_EDUCATORS}*/}
        {/*  title="Explore educators to collaborate with"*/}
        {/*  subtitle="Find educators leading projects where you can contribute your skills."*/}
        {/*  linkText="Browse educators"*/}
        {/*  to="/educators-populate"*/}
        {/*/>*/}
        {/*<OptionCard*/}
        {/*  // imageUrl={teamworkImage}*/}
        {/*  imageUrl={Images.FIND_PARTNERS}*/}
        {/*  title="Find partners to help bring your project to life"*/}
        {/*  subtitle="Discover partners with skills and experience to contribute to your projects."*/}
        {/*  linkText="Browse partners"*/}
        {/*  to="/partners-populate"*/}
        {/*/>*/}
        {/*<OptionCard*/}
        {/*  // imageUrl={involvementImage}*/}
        {/*  imageUrl={Images.ACTIVE_OFFERS}*/}
        {/*  title="Explore current opportunities to get involved"*/}
        {/*  subtitle="Discover projects and events you can contribute to."*/}
        {/*  linkText="Browse opportunities"*/}
        {/*  to="/opportunities"*/}
        {/*/>*/}
      </div>
    </Section>
  );
};
