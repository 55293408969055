import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { PencilSquareIcon } from '@heroicons/react/24/outline';

DataCard.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default function DataCard(props) {
  const {
    title,
    subtitle,
    onClick
  } = props;

  return (
    <div
      className={classNames(
        'w-full flex flex-col space-y-0.5',
        onClick && 'hover:opacity-70 cursor-pointer'
      )}
      onClick={onClick}
    >
      <div className="flex items-center space-x-1">
        <div className="text-base text-cs-gray-900">
          {title}
        </div>
        {onClick && (
          <PencilSquareIcon className="w-4 h-4 text-cs-blue-500" />
        )}
      </div>
      <div className="text-sm text-cs-gray-500">
        {subtitle}
      </div>
    </div>
  );
}
