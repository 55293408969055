import { useConnect } from '@lore/query-connect';
import { useUser } from '@lore/auth';

const timestamp = new Date().toISOString();

export default function usePastClaims(props) {
  const currentUser = useUser();

  return useConnect('claim.find', {
    where: {
      eager: {
        $where: {
          userId: currentUser.id,
          response: 'Connect',
          // 'offer.completed': false,
          // 'offer.cancelled': false,
          $or: [
            { 'offer.completed': true },
            { 'offer.cancelled': true },
            {
              'offer.specificDate': 'Yes',
              'offer.date': {
                $lte: timestamp
              }
            },
            {
              'offer.specificDate': 'No',
              'offer.endDate': {
                $lte: timestamp
              }
            }
          ]
        }
      }
    },
    pagination: {
      pageSize: 100,
      order: 'createdAt desc'
    }
  });
}
