import React from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { CalendarIcon } from '@heroicons/react/24/outline';
import HeaderIcon from './HeaderIcon';

Date.propTypes = {
  story: PropTypes.object.isRequired
};

export default function DateIcon(props) {
  const { story } = props;

  const startingAt = DateTime.fromISO(story.data.startingAt);
  const endingAt = DateTime.fromISO(story.data.endingAt);

  const startMonth = startingAt.toFormat("MMM");
  const startYear = startingAt.toFormat("yyyy");
  const endMonth = endingAt.toFormat("MMM");
  const endYear = endingAt.toFormat("yyyy");

  let date = null;

  if (startMonth === endMonth) {
    if (startYear === endYear) {
      date = `${startMonth} ${startYear}`;
    } else {
      date = `${startMonth} ${startYear} - ${endMonth} ${endYear}`;
    }
  } else {
    if (startYear === endYear) {
      date = `${startMonth} - ${endMonth} ${startYear}`;
    } else {
      date = `${startMonth} ${startYear} - ${endMonth} ${endYear}`;
    }
  }

  return (
    <HeaderIcon
      icon={CalendarIcon}
      label={date}
    />
  );
};
