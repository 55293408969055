import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import validators from '../../utils/validators';
import Overlay from '../../forms/_common/Overlay';
import FormErrors from '../../forms/_common/FormErrors';
import RequestError from '../../forms/_common/RequestError';
import ErrorMessage from '../../forms/_common/ErrorMessage';
import SuccessMessage from '../../forms/_common/SuccessMessage';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import Form from './Form';
import FindOrCreateOrganization from './FindOrCreateOrganization';
import { useQueryMutation } from '../../../hooks/@lore/query-connect';
import { useUser } from '@lore/auth';

export default function CreateOrganizationDialog(props) {
  const { onCancel } = props;

  const user = useUser();

  const [data, setData] = useState({
    place_id: '',
    name: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    city: '',
    state: '',
    zipCode: '',
    title: '',
    type: ''
  });
  const [showErrors, setShowErrors] = useState(false);
  const [finished, setFinished] = useState(false);

  const mutation = useQueryMutation('job.create');
  const { saving, success, error, request } = mutation;

  const [place_id, setPlaceId] = useState(null);
  const [organizationFinished, setOrganizationFinished] = useState(false);

  const [organizationRequest, setOrganizationRequest] = useState({
    request: undefined,
    saving: false,
    success: false,
    error: false
  });

  useEffect(() => {
    if (organizationRequest && organizationRequest.success && !organizationFinished) {
      setOrganizationFinished(true);
      mutation.mutate({
        userId: user.id,
        organizationId: organizationRequest.request.id,
        title: data.title
      });
    }

    if (success && !finished) {
      setFinished(true);
      props.onCancel();
      props.onSubmit && props.onSubmit(request);
    }
  });

  function onSubmit() {
    setPlaceId(data.place_id || data.name)
  }

  return (
    <DialogTemplate className="w-screen max-w-md">
      {place_id && (
        <FindOrCreateOrganization
          key={place_id}
          place_id={place_id}
          data={data}
          user={user}
          onSubmit={(result) => setOrganizationRequest(result)}
        />
      )}
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          name: [validators.isRequired],
          city: [validators.isRequired],
          state: [validators.isRequired],
          zipCode: [validators.isRequired],
          title: [validators.isRequired],
          type: [validators.isRequired]
        }}>
          {({ errors, hasError }) => (
            <Form
              user={user}
              data={data}
              errors={errors}
              showErrors={showErrors}
              onChange={(name, value) => {
                setData(_.merge({}, data, {
                  [name]: value
                }));
              }}
              onChangeMultiple={(values) => {
                // if (values.place_id) {
                //   findOrganization(values);
                // }
                setData(_.merge({}, data, values));
              }}
              onSubmit={() => hasError ? setShowErrors(true) : onSubmit()}
              onCancel={onCancel}
              alert={error ? (
                <RequestError request={request} />
              ) : showErrors ? (
                <ErrorMessage>
                  Please fill in all required fields.
                </ErrorMessage>
              ) : (false && success) ? (
                <SuccessMessage>
                  Contact information updated.
                </SuccessMessage>
              ) : null}
            />
          )}
        </FormErrors>
      </Overlay>
    </DialogTemplate>
  );
};
