import React from 'react';
import PropTypes from 'prop-types';
import SolidButton from '../../components/Button/SolidButton';
import FormTemplate from '../../dialogs_common/FormTemplate';
import { TrashIcon } from '@heroicons/react/24/outline';

Confirmation.propTypes = {
  offer: PropTypes.object
};

export default function Confirmation(props) {
  const {
    offer,
    onCancel,
    onPrevious
  } = props;

  return (
    <FormTemplate
      icon={(
        <div className="flex justify-center mb-4">
          <TrashIcon className="text-cs-orange-500 h-12 w-12" />
        </div>
      )}
      title="Offer cancelled!"
      // subtitle="Just come back here when it's finished and we'll get it recorded for you."
      onClose={onCancel}
      footer={(
        <div className="space-x-4">
          {/*<OutlineButton onClick={onPrevious}>*/}
          {/*  Back*/}
          {/*</OutlineButton>*/}
          <SolidButton onClick={onCancel}>
            Close
          </SolidButton>
        </div>
      )}
    />
  );
}
