import React from 'react';
import { useUser } from '@lore/auth';
import ParentLayout from '../EditLayout';
import Form from './Form';
import Urls from '../Urls';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { history } = useRouter();

  const currentUser = useUser();

  return (
    <ParentLayout
      page="Subjects"
      title="What subject areas do you teach?"
      description="Knowing the subject areas you teach will help us connect you with relevant ideas."
    >
      <Form
        user={currentUser}
        // navigation={(
        //   <Navigation v5={true} activeStep={2} steps={16}/>
        // )}
        // onPrevious={(newData) => history.push(Urls(offer).DESCRIPTION)}
        // onNext={(newData) => history.push(Urls(offer).OBJECTIVES)}
        // onSkip={() => history.push(Urls(offer).OBJECTIVES)}
        onExit={() => history.push(Urls(currentUser).PROFILE_EDIT)}
      />
    </ParentLayout>
  );
};
