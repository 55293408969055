import React from 'react';
import YellowTag from '../_base/YellowTag';

export default function HiddenTag(props) {
  const { resource } = props;

  if (resource.data.published) {
    return null;
  }

  return (
    <YellowTag label="HIDDEN" />
  );
}
