import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import _ from 'lodash';
import { useConfig } from '@lore/config';
import { stringify } from 'query-string';
import validators from '../../../utils/validators';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import DialogTemplate from '../../../dialogs_common/DialogTemplate';
import FormTemplate from '../../../dialogs_common/FormTemplate';
import FormField from '../../../forms/_common/FormField';
import StringField from '../../../forms/_fields_v2/StringField';
import TextAreaField from '../../../forms/_fields_v2/TextAreaField';
import SolidButton from '../../../components/Button/SolidButton';
import { PayloadStates } from '@lore/utils';
import { BuildingOfficeIcon } from '@heroicons/react/24/outline';
import { OutlineButton } from '../../../components/Button';
import FormTemplateButtonFooter from '../../../dialogs_common/FormTemplateButtonFooter';

Info.propTypes = {
  data: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired
};

export default function Info(props) {
  const {
    user,
    data: _data,
    onNext,
    onCancel
  } = props;

  const [data, setData] = useState(_.pick(_data, [
    'question1',
    'question2',
    'question3',
    'question4',
    'question5',
    'question6',
    'question7',
    'question8'
  ]));
  const [showErrors, setShowErrors] = useState(false);
  const [_request, setRequest] = useState(null);
  const [finished, setFinished] = useState(false);

  const request = _request;
  const saving = request && request.state === PayloadStates.CREATING;
  const error = request && request.state === PayloadStates.ERROR_CREATING;
  const success = request && request.state === PayloadStates.RESOLVED;

  const config = useConfig();

  useEffect(() => {
    if (success && !finished) {
      setFinished(true);
      onNext(data);
    }
  });

  const questions = [
    `School Name`,
    `Phone Number`,
    `Website`,
    `Address`,
    `City`,
    `State`,
    `ZIP Code`,
    `Are there any other details you'd like to share that would help us look up your school?`
  ];

  function onSubmit() {
    axios({
      url: 'https://api-iam.intercom.io/messenger/web/messages',
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: stringify({
        app_id: config.intercom.appId,
        user_data: JSON.stringify({
          email: user.data.email
        }),
        blocks: JSON.stringify([
          {
            type: "paragraph",
            text: `My school is missing.`
          },
          {
            type: "paragraph",
            text: `Q: ${questions[0]}`
          },
          {
            type: "paragraph",
            text: `A: ${data.question1}`
          },
          {
            type: "paragraph",
            text: `Q: ${questions[1]}`
          },
          {
            type: "paragraph",
            text: `A: ${data.question2}`
          },
          {
            type: "paragraph",
            text: `Q: ${questions[2]}`
          },
          {
            type: "paragraph",
            text: `A: ${data.question3}`
          },
          {
            type: "paragraph",
            text: `Q: ${questions[3]}`
          },
          {
            type: "paragraph",
            text: `A: ${data.question4}`
          },
          {
            type: "paragraph",
            text: `Q: ${questions[4]}`
          },
          {
            type: "paragraph",
            text: `A: ${data.question5}`
          },
          {
            type: "paragraph",
            text: `Q: ${questions[5]}`
          },
          {
            type: "paragraph",
            text: `A: ${data.question6}`
          },
          {
            type: "paragraph",
            text: `Q: ${questions[6]}`
          },
          {
            type: "paragraph",
            text: `A: ${data.question7}`
          },
          {
            type: "paragraph",
            text: `Q: ${questions[7]}`
          },
          {
            type: "paragraph",
            text: `A: ${data.question8}`
          }
        ])
      })
    }).then(function() {
      setRequest({
        state: PayloadStates.RESOLVED
      });
    }).catch(function(error) {
      setRequest({
        state: PayloadStates.ERROR_CREATING,
        error: error
      });
    });

    setRequest({
      state: PayloadStates.CREATING
    });
  }

  function onChange(name, value) {
    setData(_.merge({}, data, {
      [name]: value
    }));
  }

  return (
    <DialogTemplate className="w-screen max-w-lg">
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          question1: [validators.isRequired],
          question2: [validators.isRequired],
          question3: [validators.isRequired],
          question4: [validators.isRequired],
          question5: [validators.isRequired],
          question6: [validators.isRequired],
          question7: [validators.isRequired]
        }}>
          {({ errors, hasError }) => {
            return (
              <FormTemplate
                icon={(
                  <div className="flex justify-center mb-4">
                    <BuildingOfficeIcon className="text-cs-orange-500 h-12 w-12" />
                  </div>
                )}
                title="Is your school or site missing from this list?"
                subtitle="Tell us a bit more and we'll look into adding your site!"
                onClose={onCancel}
                alert={error ? (
                  <RequestError request={request} />
                ) : showErrors ? (
                  <ErrorMessage>
                    Please fill in all required fields.
                  </ErrorMessage>
                ) : null}
                body={(
                  <div className="space-y-4">
                    <FormField name="question1" data={data} errors={showErrors && errors} onChange={onChange}>
                      {(field) => (
                        <StringField
                          label={`${questions[0]}`}
                          placeholder="Name..."
                          value={field.value}
                          error={field.error}
                          onChange={field.onChange}
                        />
                      )}
                    </FormField>
                    <FormField name="question2" data={data} errors={showErrors && errors} onChange={onChange}>
                      {(field) => (
                        <StringField
                          label={`${questions[1]}`}
                          placeholder="Phone Number..."
                          value={field.value}
                          error={field.error}
                          onChange={field.onChange}
                        />
                      )}
                    </FormField>
                    <FormField name="question3" data={data} errors={showErrors && errors} onChange={onChange}>
                      {(field) => (
                        <StringField
                          label={`${questions[2]}`}
                          placeholder="Website..."
                          value={field.value}
                          error={field.error}
                          onChange={field.onChange}
                        />
                      )}
                    </FormField>
                    <FormField name="question4" data={data} errors={showErrors && errors} onChange={onChange}>
                      {(field) => (
                        <StringField
                          label={`${questions[3]}`}
                          placeholder="Address..."
                          value={field.value}
                          error={field.error}
                          onChange={field.onChange}
                        />
                      )}
                    </FormField>
                    <FormField name="question5" data={data} errors={showErrors && errors} onChange={onChange}>
                      {(field) => (
                        <StringField
                          label={`${questions[4]}`}
                          placeholder="City..."
                          value={field.value}
                          error={field.error}
                          onChange={field.onChange}
                        />
                      )}
                    </FormField>
                    <FormField name="question6" data={data} errors={showErrors && errors} onChange={onChange}>
                      {(field) => (
                        <StringField
                          label={`${questions[5]}`}
                          placeholder="State..."
                          value={field.value}
                          error={field.error}
                          onChange={field.onChange}
                        />
                      )}
                    </FormField>
                    <FormField name="question7" data={data} errors={showErrors && errors} onChange={onChange}>
                      {(field) => (
                        <StringField
                          label={`${questions[6]}`}
                          placeholder="ZIP Code..."
                          value={field.value}
                          error={field.error}
                          onChange={field.onChange}
                        />
                      )}
                    </FormField>
                    <FormField name="question8" data={data} errors={showErrors && errors} onChange={onChange}>
                      {(field) => (
                        <TextAreaField
                          label={`${questions[7]}`}
                          placeholder="More information..."
                          rows={3}
                          value={field.value}
                          error={field.error}
                          onChange={field.onChange}
                        />
                      )}
                    </FormField>
                  </div>
                )}
                footer={(
                  <FormTemplateButtonFooter>
                    <OutlineButton onClick={onCancel}>
                      Cancel
                    </OutlineButton>
                    <SolidButton onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
                      Add my site
                    </SolidButton>
                  </FormTemplateButtonFooter>
                )}
              />
            );
          }}
        </FormErrors>
      </Overlay>
    </DialogTemplate>
  );
};
