import React from 'react';
import * as Sentry from '@sentry/browser';
import logo from '../../assets/images/logo.png';
import SolidButton from '../components/Button/SolidButton';
import { useConfig } from '@lore/config';
import useRouter from '../hooks/useRouter';
import Card from '../components/Card';

export default function SentryError(props) {
  const {
    title = 'Something went wrong 😲',
    description = 'It looks like there was an error on this page.'
  } = props;

  const config = useConfig();
  const { history } = useRouter();

  // const oldSentryExperience = (
  //  <>
  //    <div>
  //     It looks like there was an error on this page. The team has been notified. If you'd like to
  //     help, you can provide feedback about what happened.
  //   </div>
  //   <div className="text-cs-orange-500 underline cursor-pointer font-normal mt-6" onClick={() => Sentry.showReportDialog()}>
  //     Report feedback
  //   </div>
  //  </>
  // );

  return (
    <div className="flex justify-center h-full bg-cs-yellow-100">
      <div className="space-y-4 w-screen-sm p-8 max-w-screen-md">
        <Card className="text-center p-12 space-y-6">
          <div className="flex justify-center">
            <img className="h-32 w-32" src={logo} alt="logo" />
          </div>
          <h1 className="text-2xl text-cs-gray-900">
            {title}
          </h1>
          <div className="text-base text-cs-gray-500">
            {description}
          </div>
          <div className="flex justify-center">
            <SolidButton onClick={() => history.push('/logout')}>
              Retry login
            </SolidButton>
          </div>
        </Card>
        <div className="text-sm text-center">
          *If this error persists, please contact <a className="text-cs-blue-500" href="mailto:support@communityshare.org">support@communityshare.org</a>.
        </div>
      </div>
    </div>
  );
};
