import React, { useState } from 'react';
// import { useIntersectionObserver } from 'usehooks-ts';
import { useIntersectionObserver } from '../../lib/usehooks-ts/useIntersectionObserver';
import Loader from './Loader';

export default function RenderOnlyWhenInViewport(props) {
  const { children } = props;

  const { isIntersecting, ref } = useIntersectionObserver({
    threshold: 0.25,
    onChange: onChange
  });

  const [hasIntersected, setHasIntersected] = useState(false);

  function onChange(isIntersecting, entry) {
    if (!hasIntersected && isIntersecting) {
      // console.log(`${props.title} has intersected`);
      setHasIntersected(true);
    }
  }

  // console.log(`Render Section ${props.title}`, {
  //   isIntersecting,
  // });

  // if (isIntersecting) {
  //   console.log(`Render Section ${props.title}`, {
  //     isIntersecting,
  //   });
  // }

  // return null;
  // return (
  //   <div ref={ref} />
  // );
  if (hasIntersected) {
    // console.log(`Rendering Section ${props.title}`);

    return (
      <div ref={ref}>
        {children}
      </div>
    );
  }

  // return null;
  return (
    <div
      ref={ref}
      className="h-[800px]"
    >
      <Loader />
    </div>
  );
}
