import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useConnect } from '@lore/query-connect';
import { useUser } from '@lore/auth';
import validators from '../../utils/validators';
import Overlay from '../../forms/_common/Overlay';
import FormErrors from '../../forms/_common/FormErrors';
import RequestError from '../../forms/_common/RequestError';
import ErrorMessage from '../../forms/_common/ErrorMessage';
import SuccessMessage from '../../forms/_common/SuccessMessage';
import Form from './Form';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import { useQueryMutation } from '../../../hooks/@lore/query-connect';

const responseMap = {
  [`I'm too busy right now, but ask me later.`]: `Sorry, I'm too busy right now, but ask me later.`,
  [`I can't make the day or time work in my schedule.`]: `Sorry, I can't make the day or time work in my schedule.`,
  [`I can't invest the time this will take.`]: `Sorry, I can't invest the time this will take.`,
  [`I don't think my learners are ready for this.`]: `Sorry, I don't think my learners are ready for this.`,
  [`This is located too far away for me and my learners.`]: `Sorry, this is located too far away for me and my learners.`,
  [`This just isn't a good fit for my curriculum or needs.`]: `Sorry, this just isn't a good fit for my curriculum or needs.`
};

export default function OfferDeclineDialog(props) {
  const { offer, claim } = props;

  const currentUser = useUser();

  const user = useConnect('user.byId', {
    id: offer.data.creatorId
  });

  const [data, setData] = useState({
    reason: ''
  });
  const [showErrors, setShowErrors] = useState(false);

  const mutation = useQueryMutation('claim.update', claim);
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      props.onCancel();
      props.onSubmit && props.onSubmit(request);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate({
      response: 'Decline',
      responseMessage: responseMap[data.reason] || data.reason
    });
  }

  function onChange(name, value) {
    const nextData = _.merge({}, data);
    nextData[name] = value;
    setData(nextData);
  }

  onChange = onChange.bind(data);

  return (
    <DialogTemplate className="w-screen max-w-screen-sm">
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          reason: [validators.isRequired]
        }}>
          {({errors, hasError}) => (
            <Form
              data={data}
              errors={errors}
              showErrors={showErrors}
              onChange={onChange}
              onSubmit={() => hasError ? setShowErrors(true) : onSubmit()}
              onCancel={props.onCancel}
              alert={error ? (
                <RequestError request={request}/>
              ) : showErrors ? (
                <ErrorMessage>
                  Please select a reason.
                </ErrorMessage>
              ) : (false && success) ? (
                <SuccessMessage>
                  Idea created.
                </SuccessMessage>
              ) : null}
            />
          )}
        </FormErrors>
      </Overlay>
    </DialogTemplate>
  );
};
