import { useConnect } from '@lore/query-connect';
import TagTypes from '../constants/TagTypes';

function byIndex(values = []) {
  return function(tag) {
    const index = values.indexOf(tag.data.name);
    return index >= 0 ? index : 9999;
  };
}

function byName(tag) {
  return tag.data.name;
}

export const sortByMap = {
  [TagTypes.Activity]: byName,
  [TagTypes.Age]: byIndex([
    'Children 4-12',
    'Teens 13-18',
    'Adults 18+'
  ]),
  [TagTypes.Commitment]: byIndex([
    'One-time',
    '2-3 visits',
    'Weekly',
    'Monthly',
    'Other ongoing relationship',
    'Not sure'
  ]),
  [TagTypes.Day]: byIndex([
    'Mon',
    'Tue',
    'Wed',
    'Thu',
    'Fri',
    'Sat',
    'Sun'
  ]),
  [TagTypes.Experience]: byName,
  [TagTypes.Expertise]: byName,
  [TagTypes.ExpertiseType]: byName,
  [TagTypes.Grade]: byIndex([
    'PK-2',
    '3-5',
    '6-8',
    '9-12',
    'College',
    'Adult'
  ]),
  [TagTypes.Group]: byName,
  [TagTypes.Guidance]: byName,
  [TagTypes.InteractionType]: byIndex([
    'In-person',
    'Virtual'
  ]),
  [TagTypes.Location]: byIndex([
    'At school or educational site',
    `At a partner's workplace`,
    'Other community location'
  ]),
  [TagTypes.Participant]: byIndex([
    'Educator',
    'Students'
  ]),
  [TagTypes.Practice]: byName,
  [TagTypes.Size]: byIndex([
    'Students individually',
    'Small group',
    'Whole class or group'
  ]),
  [TagTypes.Subject]: byName,
  [TagTypes.Supply]: byIndex([
    'Basic art supplies (crayons, markers, scissors)',
    'Other special supplies',
    'No supplies needed.'
  ]),
  [TagTypes.Time]: byIndex([
    '7am-9am (early morning)',
    '9am-11am (morning)',
    '11am-1pm (lunchtime)',
    '1pm-4pm (afternoon)',
    '4pm-6pm (afterschool)',
    '6pm-9pm (evening)',
    'No specific time required'
  ])
}

export default function useTags(type) {
  const tags = useConnect('tag.find', {
    pagination: {
      pageSize: 1000,
      order: 'name asc'
    }
  });

  const filteredTags = tags.data.filter(function(tag) {
    if (type) {
      return tag.data.type === type;
    }

    return true;
  });

  const sortedTags = _.sortBy(filteredTags, function(tag) {
    if (type && sortByMap[type]) {
      return sortByMap[type](tag);
    }

    return true;
  });

  return {
    ...tags,
    data: sortedTags
  };
}
