import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

Alert.propTypes = {
  type: PropTypes.string,
  heading: PropTypes.node
};

Alert.defaultProps = {
  type: 'info'
};

export default function Alert(props) {
  const { type, heading, className, children, onClose } = props;

  // This is wrapped in an empty div so
  return (
    <div className={classNames(
      'bg-cs-orange-100 border border-cs-orange-500 p-8 mb-8 rounded-lg',
      className
    )}>
      {heading && (
        <h4 className="text-xl font-semibold text-cs-gray-900 mb-2">
          {heading}
        </h4>
      )}
      {children}
    </div>
  );
};
