/**
 * This component sets the Google user_id to link analytics to the current user
 */

import { useEffect } from 'react';
import { useUser } from '@lore/auth';
import { useConfig } from '@lore/config';
import { install } from 'ga-gtag';

export default function GoogleUser(props) {
  const user = useUser();
  const { google } = useConfig();

  useEffect(()=> {
    if (google.enabled && google.measurementId) {
      install(google.measurementId, {
        user_id: user.id
      });
    }
  }, []);

  return null;
};
