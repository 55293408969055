import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useDialogConfig from '../_hooks/useDialogConfig';
import Dialog from '../../dialogs/evaluation_project_educator';

export default function Launcher(props) {
  const config = useDialogConfig();

  const evaluationProjectEducator = useConnect('evaluationProjectEducator.byId', {
    id: config.params.evaluationProjectEducatorId
  });

  if (evaluationProjectEducator.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <Dialog
      evaluationProjectEducator={evaluationProjectEducator}
      onClose={props.onClose}
    />
  );
};
