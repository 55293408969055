import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import LocationMap from '../../profile/organizations/LocationMap';
import classNames from 'classnames';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import Card from '../../../components/Card';

Role.propTypes = {
  role: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired
};

export default function Role(props) {
  const {
    role,
    onClick
  } = props;

  const site = useConnect('site.byId', {
    id: role.data.siteId
  });

  const launch = useDialogLauncher();

  function onUpdateRole() {
    launch(DialogManager.RoleUpdate.Config(role.id));
  }

  function onDestroyRole() {
    launch(DialogManager.RoleDestroy.Config(role.id));
  }

  if (site.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <Card
      className={classNames(
        // 'flex flex-col bg-white shadow rounded-xl overflow-hidden',
        'hover:shadow-mdx hover:bg-cs-orange-50 relative z-10'
      )}
    >
      <div
        className="w-full h-48 opacity-70 cursor-pointer"
        onClick={() => onClick(site)}
      >
        <LocationMap location={site} />
      </div>
      <div
        className="flex items-center overflow-hidden px-6 pt-4 pb-3 space-x-5 cursor-pointer"
        onClick={() => onClick(site)}
      >
        {/*<div>*/}
        {/*  <div className="bg-cs-yellow-500 p-3 rounded-full">*/}
        {/*    <BuildingOfficeIcon className="w-8 h-8 text-white" />*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="flex-1 p-6x">
          <div>
            <h2 className="font-semibold text-cs-gray-900">
              {site.data.name}
            </h2>
          </div>
          {/*<div className="text-sm text-cs-gray-500">*/}
          {/*  {role.data.title}*/}
          {/*</div>*/}
          <div className="flex justify-between mt-2x">
            <div className="text-sm text-cs-gray-500">
              {site.data.addressLine1}, {site.data.city}, {site.data.state}
            </div>
          </div>
        </div>
      </div>
      <div className="bg-cs-gray-50x px-6 pt-1x pb-4">
        <div className="flex justify-between mt-2x">
          {/*<div className="text-sm text-cs-blue-500 cursor-pointer hover:underline" onClick={() => onUpdateRole(role)}>*/}
          {/*  Edit role*/}
          {/*</div>*/}
          <div className="text-sm text-cs-blue-500 cursor-pointer hover:underline" onClick={() => onDestroyRole(role)}>
            No longer work here?
          </div>
        </div>
      </div>
    </Card>
  );
}
