import React, { useState } from 'react';
import classNames from 'classnames';

export default function Image(props) {
  const { image, imageHeight } = props;

  const [height, setHeight] = useState(image.data.height);
  const [width, setWidth] = useState(image.data.width);

  function onLoad(event) {
    setHeight(event.target.naturalHeight);
    setWidth(event.target.naturalWidth);
  }

  return (
    <div className={classNames(
      'flex flex-col relative',
      imageHeight || 'h-64',
      'bg-cs-gray-500 rounded-2xl overflow-hidden',
      'shadow-cs-flat -m-px border border-cs-gray-100',
      'cursor-pointer hover:bg-cs-orange-50x'
    )}>
      <a
        href={image.data.imageUrl}
        data-pswp-width={width}
        data-pswp-height={height}
        target="_blank"
        rel="noreferrer"
      >
        <img
          className="absolute left-0 top-0 object-cover object-center h-full w-full"
          src={image.data.imageUrl}
          alt="image"
          onLoad={onLoad}
        />
      </a>
    </div>
  );
};
