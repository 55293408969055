import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import Users from './Users';
import Uploads from './Uploads';
import ImageGallery from './ImageGallery';
import Header from './Header';
import Summary from './Summary';
import Ages from './Ages';
import Activities from './Activities';
import Subjects from './Subjects';
import Card from '../../../components/Card';

export default function Story(props) {
  const { story } = props;

  const images = useConnect('image.find', {
    where: {
      eager: {
        $where: {
          storyId: story.id
        }
      }
    },
    pagination: {
      page: 1,
      pageSize: 100,
      order: 'id asc'
    }
  });

  if (images.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <div className="space-y-8">
      <div className="flex flex-col xl:flex-row mb-0x xl:mb-8x gap-8">
        <div className="w-full pr-0 xl:w-2/3 space-y-8">
          <Header story={story} />
          <Summary story={story} />
          <Users story={story} />
          <Uploads story={story} />
        </div>
        <div className="hidden xl:block w-full xl:w-1/3">
          {/*<div className="bg-white shadow-cs-flat -m-px border border-cs-gray-100 rounded-cs-10 overflow-hidden">*/}
          <Card>
            <div className="px-6 py-6 space-y-6">
              {/*<Date story={story} />*/}
              <Ages story={story} />
              <Activities story={story} />
              <Subjects story={story} />
            </div>
          </Card>
        </div>
      </div>
      {images.data.length > 0 && (
        <ImageGallery images={images} />
      )}
    </div>
  );
};
