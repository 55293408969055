import React from 'react';
import { PayloadStates } from '@lore/utils';
import useRouter from '../../hooks/useRouter';
import Loader from '../../components/Loader';
import EmptyState from '../../components/EmptyState';
import classNames from 'classnames';
import Project from './Project';
import { FolderIcon } from '@heroicons/react/24/outline';

export default function Results(props) {
  const { result } = props;

  const { location, history } = useRouter();

  if (result.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  if (result.data.length === 0) {
    return (
      <EmptyState
        icon={FolderIcon}
        title="Having trouble finding what you're looking for?"
        subtitle={(
          <div className="max-w-screen-sm m-auto space-y-6">
            <div className="text-cs-gray-500 space-x-1">
              <span>Try removing a filter or start fresh by</span>
              <span
                className="text-cs-orange-500 underline cursor-pointer"
                onClick={() => history.push(location.pathname)}
              >
                clearing all filters
              </span>.
            </div>
          </div>
        )}
      />
    );
  }

  return (
    <div className={classNames(
      result._isPreviousData && 'opacity-50',
      'w-full grid grid-cols-1 gap-4'
    )}>
      {result.data.map(function(project, index) {
        return (
          <Project
            key={index}
            project={project}
          />
        );
      })}
    </div>
  );
}
