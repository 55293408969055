import React from 'react';
import GrayTag from '../_base/GrayTag';

export default function CancelledTag(props) {
  const { resource } = props;

  if (!resource.data.cancelled) {
    return null;
  }

  return (
    <GrayTag label="CANCELLED" />
  );
}
