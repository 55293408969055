import React from 'react';
import PropTypes from 'prop-types';
import ConnectButton from '../../../pages/_banner/ConnectButton';
import Wrapper from '../../../pages/_banner/Wrapper';
import useDialogLauncher from '../../_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import usePageRouter from '../../_hooks/usePageRouter';

ExpiredBanner.propTypes = {
  offer: PropTypes.object.isRequired
};

export default function ExpiredBanner(props) {
  const { offer, creator } = props;

  const launch = useDialogLauncher();
  const { history } = usePageRouter();

  function onCancel() {
    launch(DialogManager.OfferCancel.Config(offer.id));
  }

  return (
    <Wrapper
      color="yellow"
      creator={creator}
      title={(
        <>
          Your offer timeline has ended.
        </>
      )}
      subtitle={(
        <>
          Educators can no longer see your offer because it's set in the past. Update your
          timeline to share it again. Or cancel your offer if it has ended.
        </>
      )}
    >
      <div className="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0">
        <ConnectButton outline={true} onClick={onCancel}>
          Cancel your offer
        </ConnectButton>
        <ConnectButton outline={true} onClick={() => history.push(`/post-offer/${offer.id}/date`)}>
          Extend your timeline
        </ConnectButton>
      </div>
    </Wrapper>
  );
};
