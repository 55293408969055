/* eslint consistent-return: "off" */

import PropTypes from 'prop-types';
import _ from 'lodash';

export default function FormErrors(props) {
  FormErrors.propTypes = {
    data: PropTypes.object.isRequired,
    validators: PropTypes.object,
    children: PropTypes.func.isRequired
  };

  FormErrors.defaultProps = {
    validators: {}
  };

  function getErrors(validatorDictionary, data) {
    // make sure we include errors for validator keys that aren't in data yet
    const _data = _.merge({}, data);
    _.keys(validatorDictionary).map(function(key, index) {
      if (_data[key] === undefined) {
        _data[key] = undefined;
      }
    });

    return _.mapValues(_data, function(value, key) {
      const validators = validatorDictionary[key];
      let error = null;
      if (validators) {
        validators.forEach(function(validator) {
          error = error || validator(value);
        });
      }
      return error;
    });
  }

  function getHasError(errors) {
    const errorCount = _.reduce(errors, function(result, value, key) {
      if (value) {
        return result + 1;
      }

      return result;
    }, 0);
    return errorCount > 0;
  }

  const { data, validators, children } = props;
  const errors = getErrors(validators, data);
  const hasError = getHasError(errors);

  return children({
    errors: errors,
    hasError: hasError
  });
}
