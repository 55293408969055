import React from 'react';
import ChildLayout from '../user/Layout'
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import { Helmet } from 'react-helmet-async';
import useRouter from '../../hooks/useRouter';

export default function Layout(props) {
  const { location, history, match } = useRouter();

  const event = useConnect('event.byId', {
    id: Number(match.params.eventId)
  })

  if (event.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <>
      <Helmet>
        <title>Event #{`${event.id}`} - Invite Partner</title>
      </Helmet>
      <ChildLayout
        {...props}
        event={event}
        header={false}
      />
    </>
  );
};
