import React from 'react';
import Text from '../../typography';

export default function TextDivider(props) {
  const { title } = props;

  return (
    <div className="flex items-center space-x-3">
      <div className="flex-1 h-px bg-cs-gray-100 w-full" />
      <Text.SmGrayMedium className="opacity-50">
        {title}
      </Text.SmGrayMedium>
      <div className="flex-1 h-px bg-cs-gray-100 w-full" />
    </div>
  );
};
