import React from 'react';
import { useUser } from '@lore/auth';
import { Helmet } from 'react-helmet-async';
import PageHeader from '../people-people/PageHeader';
import OptionCard from '../my-network/OptionCard';
import bookshelfImage from '../../../assets/bookshelf.jpg';
import teamworkImage from '../../../assets/teamwork.jpg';
import involvementImage from '../../../assets/involvement.jpg';
import butterflyImage from '../../../assets/boy_magnifying_glass_butterfly.jpg';
import SectionHeader from '../../components_profiles/SectionHeader';
import useProjects from '../projects/useQuery';
import useEvents from '../events/useQuery';
import useOffers from '../offers/useQuery';
import { stringify } from 'query-string';
import MatchingEvents from './Recommended/MatchingEvents';
import MatchingOffers from './Recommended/MatchingOffers';
import MatchingProjects from './Recommended/MatchingProjects';
import Images from '../../constants/Images';
import MatchingProjectsEvents from './Recommended/MatchingProjectsEvents';
import NearbyProjectsEvents from './Recommended/NearbyProjectsEvents';
import Recommended from './Recommended';

function Header(props) {
  const { children } = props;

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Explore opportunities</title>
      </Helmet>
      <PageHeader
        title="Explore opportunities"
        subtitle={(
          <>
            Check out the active projects and events where educators are seeking
            collaborators. Explore exciting offers created by partners for educators
            and learners.
          </>
        )}
      />
      <div className="space-y-8">
        {children}
      </div>
    </div>
  );
}

window.stringify = stringify;

export default function Layout(props) {
  const currentUser = useUser();

  const params = {
    search: {
      networks: `${currentUser.data.networkId}`,
      locationCondition: JSON.stringify({
        address: currentUser.data.zipCode,
        latitude: currentUser.data.latitude,
        longitude: currentUser.data.longitude,
        radius: 50
      })
    }
  };

  const projects = useProjects(params);
  const events = useEvents(params);
  const offers = useOffers(params);

  return (
    <Header currentUser={currentUser}>
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4">
        <OptionCard
          // count={projects.meta?.totalCount}
          // imageUrl={involvementImage}
          imageUrl={Images.ACTIVE_PROJECTS}
          title="Explore active projects and events"
          subtitle="Discover projects and events where educators are seeking partners."
          linkText="Explore"
          to="/projects-events-populate"
        />
        {/*<OptionCard*/}
        {/*  // count={projects.meta?.totalCount}*/}
        {/*  // imageUrl={involvementImage}*/}
        {/*  imageUrl={Images.ACTIVE_PROJECTS}*/}
        {/*  title="Explore active projects"*/}
        {/*  subtitle="Discover projects where educators are seeking partners."*/}
        {/*  linkText="Explore"*/}
        {/*  to="/projects-populate"*/}
        {/*/>*/}
        {/*<OptionCard*/}
        {/*  // count={events.meta?.totalCount}*/}
        {/*  // imageUrl={involvementImage}*/}
        {/*  imageUrl={Images.ACTIVE_PROJECTS}*/}
        {/*  title="Explore active events"*/}
        {/*  subtitle="Discover events where educators are seeking collaborators."*/}
        {/*  linkText="Explore"*/}
        {/*  to="/events-populate"*/}
        {/*/>*/}
        <OptionCard
          // count={offers.meta?.totalCount}
          // imageUrl={butterflyImage}
          imageUrl={Images.ACTIVE_OFFERS}
          title="Discover offers for educators and learners"
          subtitle="Take a look at the offers in your community."
          linkText="Discover"
          to="/offers-populate"
        />
      </div>
      <Recommended />
      {/*<div className="space-y-4">*/}
      {/*  <SectionHeader*/}
      {/*    title="Recommended just for you"*/}
      {/*    subtitle={(*/}
      {/*      <>*/}
      {/*        Explore activities tailored to your interests and experiences.*/}
      {/*      </>*/}
      {/*    )}*/}
      {/*  />*/}
      {/*  <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4">*/}
      {/*    <MatchingProjectsEvents />*/}
      {/*    /!*<MatchingProjects />*!/*/}
      {/*    /!*<MatchingEvents />*!/*/}
      {/*    <MatchingOffers />*/}
      {/*    <NearbyProjectsEvents />*/}
      {/*  </div>*/}
      {/*</div>*/}
    </Header>
  );
}
