import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import validators from '../../utils/validators';
import Overlay from '../../forms/_common/Overlay';
import FormErrors from '../../forms/_common/FormErrors';
import RequestError from '../../forms/_common/RequestError';
import ErrorMessage from '../../forms/_common/ErrorMessage';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import Form from '../tagNode.create/Form';
import DESCRIPTION_MAX_LENGTH from '../tagNode.create/DESCRIPTION_MAX_LENGTH';
import utils from '../tagNode.create/utils';
import { useQueryMutation } from '../../../hooks/@lore/query-connect';

TagNodeUpdateDialog.propTypes = {
  tag: PropTypes.object.isRequired,
  tagNode: PropTypes.object.isRequired,
  onSubmit: PropTypes.func
};

export default function TagNodeUpdateDialog(props) {
  const {
    title,
    subtitle,
    tag,
    tagNode,
    onCancel
  } = props;

  const [data, setData] = useState({
    description: tagNode.data.description
  });
  const [showErrors, setShowErrors] = useState(false);

  const mutation = useQueryMutation('tagNode.update', tagNode);
  const { request, saving, success, error } = mutation;

  useEffect(() => {
    if (mutation.isSuccess) {
      props.onCancel();
      props.onSubmit && props.onSubmit(request);
    }
  }, [mutation.isSuccess]);

  function onSubmit() {
    mutation.mutate({
      description: data.description
    });
  }

  const isRequired = utils.isRequired(tag);
  const prompts = utils.prompts(tag);

  return (
    <DialogTemplate className="w-screen max-w-md">
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          description: isRequired ? [
            validators.isRequired,
            validators.maxLength(DESCRIPTION_MAX_LENGTH)
          ] : [
            validators.maxLength(DESCRIPTION_MAX_LENGTH)
          ]
        }}>
          {({ errors, hasError }) => (
            <Form
              icon={prompts.icon}
              title={title || prompts.title}
              subtitle={subtitle || prompts.subtitle}
              label={`${prompts.label}${isRequired ? '*': ''}`}
              placeholder={prompts.placeholder}
              data={data}
              errors={errors}
              showErrors={showErrors}
              onChange={(name, value) => {
                setData(_.merge({}, data, {
                  [name]: value
                }));
              }}
              onSubmit={() => hasError ? setShowErrors(true) : onSubmit()}
              onCancel={onCancel}
              alert={error ? (
                <RequestError request={request} />
              ) : showErrors ? (
                <ErrorMessage>
                  Please fill in all required fields.
                </ErrorMessage>
              ) : null}
            />
          )}
        </FormErrors>
      </Overlay>
    </DialogTemplate>
  );
};
