import { useConnect } from '@lore/query-connect';

export default function UserJob(props) {
  const { job } = props;

  const organization = useConnect('organization.byId', {
    id: job.data.organizationId
  });

  return [
    job.data.title || '...',
    ' at ',
    organization.data.name || '...'
  ].join('');
}
