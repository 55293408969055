import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useConnect } from '@lore/query-connect';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import FormTemplate from '../../_post-opportunity/FlowTemplate';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';
import EmptyState from '../../../components/EmptyState/EmptyState.legacy';
import Roles from '../../setup/school/Roles';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';

Form.propTypes = {
  user: PropTypes.object.isRequired
};

export default function Form(props) {
  const {
    user,
    navigation,
    onNext,
    onPrevious,
    onSkip,
    onExit,
    buttonText = 'Next'
  } = props;

  const launch = useDialogLauncher();

  const roles = useConnect('role.find', {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    },
    pagination: {
      page: 1,
      pageSize: 1000,
      order: 'createdAt asc'
    }
  });

  const [data, setData] = useState(_.pick(user.data, [
    // 'field'
  ]));
  const [showErrors, setShowErrors] = useState(false);
  const [exit, setExit] = useState(false);

  const mutation = useQueryMutation('currentUser.updateSingleton', user);
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      exit ? onExit(data) : onNext(data);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate(data);
  }

  function onSubmitAndExit() {
    onSubmit();
    setExit(true);
  }

  function onChange(name, value) {
    setData(_.merge({}, data, {
      [name]: value
    }));
  }

  function onCreateSite() {
    launch(DialogManager.SiteCreate.Config());
  }

  return (
    <Overlay isVisible={saving}>
      <FormErrors data={data} validators={{
        // field: [validators.isRequired]
      }}>
        {({ errors, hasError }) => {
          return (
            <FormTemplate
              onExit={() => hasError ? setShowErrors(true) : onSubmitAndExit()}
              alert={error ? (
                <RequestError request={request} />
              ) : showErrors ? (
                <ErrorMessage>
                  Please fill in all required fields.
                </ErrorMessage>
              ) : null}
              body={(
                <div className="space-y-6">
                  <div className="grid grid-cols-1 gap-3">
                    <Roles roles={roles}/>
                  </div>
                  <div className="hover:shadow hover:opacity-80 cursor-pointer" onClick={onCreateSite}>
                    <EmptyState
                      title="Add school/site"
                    />
                  </div>
                </div>
              )}
              // footer={(
              //   <>
              //     <PrimaryButton onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
              //       {buttonText}
              //     </PrimaryButton>
              //   </>
              // )}
            />
          );
        }}
      </FormErrors>
    </Overlay>
  );
};
