import React from 'react';
import Loader from '../../components/Loader';

export default function LoaderButton(props) {
  return (
    <div className="px-8 py-0.5 border-cs-gray-200 w-56 min-w-fit">
      <div className="-my-2">
        <Loader className="text-white -my-1x" />
      </div>
    </div>
  );
};
