import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import useProjectTags from '../../../hooks/useProjectTags';
import TagTypes from '../../../constants/TagTypes';
import BaseParticipation from '../../../components_profiles/BaseParticipation';

Participation.propTypes = {
  project: PropTypes.object.isRequired
};

export default function Participation(props) {
  const { project } = props;

  const creator = useConnect('user.byId', {
    id: project.data.creatorId
  });

  const projectInteractionTypes = useProjectTags(project, TagTypes.InteractionType);

  return (
    <BaseParticipation
      creator={creator}
      interactionTypes={projectInteractionTypes}
    />
  );
}
