import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import { DateTime } from 'luxon';
import Avatar from '../../../components/Avatar';
import MediaSection from '../_common/MediaSection';
import MiddleDot from '../_common/MiddleDot';
import { PayloadStates } from '@lore/utils';

export default function UserMediaSection(props) {
  const { evaluation } = props;

  const user = useConnect('user.byId', {
    id: evaluation.data.userId
  });

  const project = useConnect('project.byId', {
    id: evaluation.data.projectId
  });

  const role = project.state === PayloadStates.FETCHING ?
    '...' :
    project.data.creatorId === user.id ? 'Creator' : 'Participant';

  return (
    <MediaSection
      image={(
        <Avatar size={12} src={user.data.avatarUrl}/>
      )}
      title={`${user.data.firstName} ${user.data.lastName}`}
      subtitle={(
        <div className="space-x-1">
          <span>
            {role}
          </span>
          <span>
            <MiddleDot />
          </span>
          <span>
            Evaluation created {DateTime.fromISO(evaluation.data.createdAt).toRelative()}
          </span>
        </div>
      )}
    />
  );
};
