import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

ErrorMessage.propTypes = {
  children: PropTypes.node
};

export default function ErrorMessage(props) {
  const { children, className, v2 = false } = props;

  return (
    <div className={classNames(
      'bg-red-200 text-red-900 py-6',
      v2 ? 'mt-16 px-24' : 'my-4 px-8',
      className
    )}>
      {children}
    </div>
  );
};
