import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import SectionTitle from '../project-manage/SectionTitle';
// import Question from '../../dialogs/project.close/Classify/Question';
import Question from '../project-manage/Question';
import { SolidButton } from '../../components/Button';
import useDialogLauncher from '../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../dialogs_new/DialogManager';

export default function QuestionsClassify(props) {
  const { project, onViewMessages } = props;

  const launch = useDialogLauncher();

  const questions = useConnect('question.find', {
    where: {
      eager: {
        $where: {
          projectId: project.id,
          response: 'Connect',
          result: {
            $exists: false
          }
        }
      }
    },
    pagination: {
      pageSize: 100
    }
  });

  function onClassify(question) {
    launch(DialogManager.QuestionClassify.Config(question.id));
  }

  if (questions.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  if (questions.data.length === 0) {
    return null;
  }

  return (
    <SectionTitle
      title="How did partners support your project?"
      subtitle={(
        <div className="space-y-2">
          <div>
            The people you connected with on the platform are listed below. We’d love to hear
            how they supported your project or event so we can celebrate their contributions. The
            individuals you worked with directly will also be invited to share their own reflections
            about the experience.
          </div>
          <div>
            Please select the level of contribution:
          </div>
          <ul className="list-disc list-inside ml-2">
            <li>
              <strong>The partnership didn’t move forward</strong>. No advice, connections, or
              support were provided.
            </li>
            <li>
              <strong>The partner contributed</strong> advice on a project idea, shared resources,
              or referred you to another person or organization.
            </li>
            <li>
              <strong>The partner collaborated on this project</strong>, engaging with you or your
              students virtually or in person.
            </li>
          </ul>
        </div>
      )}
    >
      <div className="grid grid-cols-1 gap-4">
        {questions.data.map(function (question, index) {
          return (
            <Question
              key={question.id || question.cid}
              question={question}
              onViewMessages={onViewMessages}
              primaryButton={(
                <SolidButton
                  label="Select contribution"
                  onClick={() => onClassify(question)}
                />
              )}
            />
          );
        })}
      </div>
    </SectionTitle>
  );
};
