import React from 'react';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import SectionHeader from '../../../components_profiles/SectionHeader';
import useHasChildren from '../../../components_cards/useHasChildren';
import NearbyPartners from './NearbyPartners';
import MatchingEducators from './MatchingEducators';
import EmptyState, { EmptyStateActions } from '../../../components/EmptyState';
import { LinkButton, OutlineButton } from '../../../components/Button';

export default function Recommended(props) {
  const cards = [
    <NearbyPartners key="nearby-partners" />,
    <MatchingEducators key="matching-educators" />
  ];

  const hasChildren = useHasChildren(cards);

  // if (!hasChildren) {
  //   return null;
  // }

  return (
    <div className="space-y-4">
      <SectionHeader
        title="Recommendations just for you"
        subtitle={(
          <>
            Our community is full of potential connections — here are a few people we
            think you may want to collaborate with!
          </>
        )}
      />
      {hasChildren ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4">
          {cards}
        </div>
      ) : (
        <EmptyState
          icon={UserCircleIcon}
          title="Recommendations are coming soon — just share a few more details!"
          subtitle={(
            <>
              The more you build your profile and engage with others, the more tailored
              recommendations will appear here. Update your profile details, and check
              back soon for new suggestions.
            </>
          )}
        >
          <EmptyStateActions>
            <LinkButton
              button={OutlineButton}
              to={`/profile/edit`}
              label="Update profile"
            />
          </EmptyStateActions>
        </EmptyState>
      )}
    </div>
  );
}
