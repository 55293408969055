import React from 'react';
import { DateTime } from 'luxon';
import Complete from './status/Complete';
import Incomplete from './status/Incomplete';
import NotStarted from './status/NotStarted';
import RightMediaSection from '../_common/RightMediaSection';

StatusMediaSection.Loading = function(props) {
  return (
    <RightMediaSection
      title="..."
      subtitle="..."
    />
  );
}

export default function StatusMediaSection(props) {
  const { evaluation, onClickView, onClickEdit } = props;

  const diff = DateTime.fromISO(evaluation.data.updatedAt).toMillis() - DateTime.fromISO(evaluation.data.createdAt).toMillis();

  if (evaluation.data.completed) {
    return (
      <Complete
        evaluation={evaluation}
        onClickView={onClickView}
        onClickEdit={onClickEdit}
      />
    );
  }

  if (diff > 100) {
    return (
      <Incomplete
        evaluation={evaluation}
        onClickView={onClickView}
        onClickEdit={onClickEdit}
      />
    );
  }

  return (
    <NotStarted
      evaluation={evaluation}
      onClickView={onClickView}
      onClickEdit={onClickEdit}
    />
  );
};
