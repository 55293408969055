import { useConnect } from '@lore/query-connect';
import { useUser } from '@lore/auth';
import { useState } from 'react';

export default function useActiveInvitations(props) {
  const currentUser = useUser();
  const [timestamp] = useState(new Date().toISOString());

  return useConnect('invitation.find', {
    where: {
      eager: {
        $where: {
          userId: currentUser.id,
          // $or: [
          //   { response: 'Yes' },
          //   { response: 'Maybe' }
          // ],
          // 'event.completed': false,
          // 'event.cancelled': false,
          $and: [
            {
              $or: [
                { response: 'Yes' },
                { response: 'Maybe' }
              ]
            },
            { 'event.completed': false },
            { 'event.cancelled': false },
            {
              $or: [
                {
                  'event.specificDate': 'Yes',
                  'event.date': {
                    $gt: timestamp
                  }
                },
                {
                  'event.specificDate': 'No',
                  'event.endDate': {
                    $gt: timestamp
                  }
                }
              ]
            }
          ]
        }
      }
    },
    pagination: {
      pageSize: 100,
      order: 'createdAt desc'
    }
  });
}
