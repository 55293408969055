import React from 'react';
import PropTypes from 'prop-types';
import FormField from '../../forms/_common/FormField';
import { OutlineButton, SolidButton } from '../../components/Button';
import FormTemplate from '../../dialogs_common/FormTemplate';
import RadioButtonField from '../../pages/_post-opportunity/_fields/RadioButtonField.cards';
import FormSection from '../../pages/_post-opportunity/forms/FormSection';
import { FolderIcon } from '@heroicons/react/24/outline';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  activityTypes: PropTypes.object,
  ages: PropTypes.object
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onCancel,
    project
  } = props;

  return (
    <FormTemplate
      icon={(
        <div className="flex justify-center mb-4">
          <FolderIcon className="text-cs-orange-500 h-12 w-12" />
        </div>
      )}
      title="Would you like to close the project or extend the date range?"
      // subtitle="text"
      onClose={onCancel}
      alert={alert}
      body={(
        <div className="space-y-8">
          <FormSection
            // title="How did you connect?*"
            // subtitle="Select one or many."
          >
            <FormField name="action" data={data} errors={showErrors && errors} onChange={onChange}>
              {(field) => (
                <RadioButtonField
                  name="action"
                  // label="How did you connect?*"
                  value={field.value}
                  error={field.error}
                  // placeholder="Select a role..."
                  options={{
                    data: [
                      {
                        id: 'close',
                        data: {
                          name: (
                            <>
                              I want to <span className="font-semibold text-cs-orange-500">
                              close the project</span> and mark it as either cancelled or completed.
                            </>
                          )
                        }
                      },
                      {
                        id: 'extend',
                        data: {
                          name: (
                            <>
                              I want to <span className="font-semibold text-cs-orange-500">
                              extend the date</span> because the project is still ongoing and I want
                              more partners to be able to participate.
                            </>
                          )
                        }
                      }
                    ]
                  }}
                  // options={{
                  //   data: [
                  //     <>
                  //       I want to <span className="font-semibold text-cs-orange-500">
                  //       close the project</span> and mark it as either cancelled or completed.
                  //     </>,
                  //     <>
                  //       I want to <span className="font-semibold text-cs-orange-500">
                  //       extend the date</span> because the project is still ongoing and I want
                  //       more partners to be able to participate.
                  //     </>
                  //   ].map((option, index) => {
                  //     return {
                  //       id: index + 1,
                  //       data: {
                  //         name: option
                  //       }
                  //     }
                  //   })
                  // }}
                  optionLabel="name"
                  onChange={field.onChange}
                  // onChange={function(value) {
                  //   if (value === 1) {
                  //     onChange('action', 'close');
                  //   } else {
                  //     onChange('action', 'extend');
                  //   }
                  // }}
                />
              )}
            </FormField>
          </FormSection>
        </div>
      )}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onCancel}>
            Cancel
          </OutlineButton>
          <SolidButton disabled={!data.action} onClick={onSubmit}>
            Next
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
