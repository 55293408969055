import React from 'react';
import PropTypes from 'prop-types';
import FormField from '../../forms/_common/FormField';
import { OutlineButton, SolidButton } from '../../components/Button';
import FormTemplate from '../../dialogs_common/FormTemplate';
import FormSection from '../../pages/_post-opportunity/forms/FormSection';
import RadioButtonField from '../../pages/_post-opportunity/_fields/RadioButtonField.cards';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';

ProjectEventForm.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  activityTypes: PropTypes.object,
  ages: PropTypes.object
};

ProjectEventForm.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function ProjectEventForm(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onPrevious,
    onSubmit,
    onCancel
  } = props;

  return (
    <FormTemplate
      title={(
        <>
          Great! Will this activity be a one-time event with a specific date?
        </>
      )}
      onClose={onCancel}
      alert={alert}
      body={(
        <div className="space-y-8">
          <FormSection
            // title="Date is..."
            // subtitle="Select one or many."
          >
            <FormField name="type" data={data} errors={showErrors && errors} onChange={onChange}>
              {(field) => (
                <>
                  <RadioButtonField
                    name="type"
                    // label="Does your partner need to come from specific career field(s) or have special expertise?"
                    value={field.value}
                    error={field.error}
                    placeholder="Select a statement..."
                    options={{
                      data: [
                        [
                          'event',
                          <>
                            Yes
                          </>
                        ],
                        [
                          `project`,
                          <>
                            No, this is an ongoing project
                          </>
                        ]
                      ].map((option) => {
                        return {
                          id: option[0],
                          data: {
                            name: option[1]
                          }
                        }
                      })
                    }}
                    optionLabel="name"
                    onChange={(value) => field.onChange(value)}
                  />
                </>
              )}
            </FormField>
          </FormSection>
        </div>
      )}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onPrevious}>
            Back
          </OutlineButton>
          <SolidButton onClick={onSubmit}>
            Next
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
