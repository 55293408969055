import React from 'react';
import classNames from 'classnames';
import Partners from '../../pages/my-offers/Offer/Header-new/Partners';
import Organization from '../../pages/my-offers/Offer/Header-new/Organization';
import DateRange from '../../pages/my-offers/Offer/Header-new/DateRange';
import Creator from '../../pages/my-offers/Offer/Header-new/Creator';
import Title from '../../pages/my-offers/Offer/Header-new/Title';
import StatusTags from '../../pages/my-offers/Offer/Header-new/StatusTags';
import Description from '../../pages/my-offers/Offer/Header-new/Description';
import Distance from '../../pages/offers/Offer/Distance';

export default function ComposableOfferHeader(props) {
  const {
    offer,
    tags,
    onClick,
    showTags = true,
    showTitle = true,
    showDescription = true,
    showDateRange = true,
    showCreator = true,
    showOrganization = true,
    showPartners = true,
    showDistance = false
  } = props;

  return (
    <div className="flex flex-col p-4 space-y-2">
      <div className="space-y-2">
        {tags}
        {showTags && (
          <StatusTags offer={offer} />
        )}
        <div
          className={classNames(
            'space-y-1 pr-10',
            onClick && 'cursor-pointer hover:text-cs-gray-500'
          )}
          onClick={onClick ? () => onClick(offer) : undefined}
        >
          {showTitle && (
            <Title offer={offer} />
          )}
          {showDescription && (
            <Description offer={offer} />
          )}
        </div>
      </div>
      <div className="flex justify-between flex-wrap gap-x-4 gap-y-2">
        <div className="flex flex-wrap gap-x-4 gap-y-2">
          {showDateRange && (
            <DateRange offer={offer}/>
          )}
          {showCreator && (
            <Creator offer={offer}/>
          )}
          {showOrganization && (
            <Organization offer={offer}/>
          )}
        </div>
        {showPartners && (
          <Partners offer={offer}/>
        )}
        {showDistance && (
          <Distance offer={offer}/>
        )}
      </div>
    </div>
  );
}
