import React from 'react';
import SectionTitle from '../home/SectionTitle';
import usePastQuestions from './usePastQuestions';
import usePastInvitations from './usePastInvitations';
import usePastClaims from './usePastClaims';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import EmptyState from '../../components/EmptyState';
import { ArchiveBoxIcon } from '@heroicons/react/24/outline';
import Question from './Question';
import Invitation from './Invitation';
import Claim from './Claim';

export default function PastParticipatedActivities(props) {
  const title = 'Activities you participated in';

  const questions = usePastQuestions();
  const invitations = usePastInvitations();
  const claims = usePastClaims();

  if (
    questions.state === PayloadStates.FETCHING ||
    invitations.state === PayloadStates.FETCHING ||
    claims.state === PayloadStates.FETCHING
  ) {
    return (
      <div>
        <SectionTitle title={title}/>
        <Loader/>
      </div>
    );
  }

  if (
    questions.data.length === 0 &&
    invitations.data.length === 0 &&
    claims.data.length === 0
  ) {
    return (
      <div>
        <SectionTitle title={title}/>
        <EmptyState
          icon={ArchiveBoxIcon}
          title="You have not participated in any activities."
          subtitle="Activities you participate in will appear here after they are closed."
        />
      </div>
    );
  }

  return (
    <div>
      <SectionTitle title={title} />
      <div className="space-y-4">
        {questions.data.map(function(question) {
          return (
            <Question
              key={question.id}
              question={question}
            />
          );
        })}
        {invitations.data.map(function(invitation) {
          return (
            <Invitation
              key={invitation.id}
              invitation={invitation}
            />
          );
        })}
        {claims.data.map(function(claim) {
          return (
            <Claim
              key={claim.id}
              claim={claim}
            />
          );
        })}
      </div>
    </div>
  );
}
