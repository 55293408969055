import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import PageHeader from '../people-people/PageHeader';
import useRouter from '../../hooks/useRouter';
import Redirect from '../../components/Redirect';
import { DateTime } from 'luxon';

function Header(props) {
  const { children } = props;

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Your Events</title>
      </Helmet>
      <PageHeader
        title="Your Events"
      />
      <div className="space-y-8">
        {children}
      </div>
    </div>
  );
}

export default function Layout(props) {
  const { match, location } = useRouter();

  const event = useConnect('event.byId', {
    id: Number(match.params.eventId)
  });

  if (event.state === PayloadStates.FETCHING) {
    return (
      <Header>
        <Loader className="mx-7" />
      </Header>
    );
  }

  const diff = DateTime.now().diff(DateTime.fromISO(event.data.date)).as('days');
  // const diff = DateTime.now().diff(DateTime.fromISO(event.data.date)).milliseconds;

  if (diff > 0) {
    if (
      event.data.completed ||
      event.data.cancelled
    ) {
      return (
        <Header>
          <Redirect to={{
            pathname: '/my-past',
            search: location.search
          }} />
        </Header>
      );
    } else {
      return (
        <Header>
          <Redirect to={{
            pathname: '/my-expired',
            search: location.search
          }} />
        </Header>
      );
    }
  }

  return (
    <Header>
      <Redirect to={{
        pathname: '/my-leading',
        search: location.search
      }} />
    </Header>
  );
}
