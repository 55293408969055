import React from 'react';
import { ClockIcon } from '@heroicons/react/24/outline';
import useRouter from '../../hooks/useRouter';
import Urls from '../profile/Urls';
import DataCard from './DataCard';

export default function Timezone(props) {
  const { user } = props;

  const { history } = useRouter();

  const timezones = {
    'America/New_York':               '(GMT-04:00) Eastern Time', // (GMT-05:00) Eastern Time - New York
    // 'America/Detroit':                '(GMT-05:00) Eastern Time - Detroit',
    // 'America/Kentucky/Louisville':    '(GMT-05:00) Eastern Time - Louisville',
    // 'America/Kentucky/Monticello':    '(GMT-05:00) Eastern Time - Monticello, Kentucky',
    // 'America/Indiana/Indianapolis',   '(GMT-05:00) Eastern Time - Indianapolis',
    // 'America/Indiana/Vincennes':      '(GMT-05:00) Eastern Time - Vincennes, Indiana',
    // 'America/Indiana/Winamac':        '(GMT-05:00) Eastern Time - Winamac, Indiana',
    // 'America/Indiana/Marengo':        '(GMT-05:00) Eastern Time - Marengo, Indiana',
    // 'America/Indiana/Petersburg':     '(GMT-05:00) Eastern Time - Petersburg, Indiana',
    // 'America/Indiana/Vevay':          '(GMT-05:00) Eastern Time - Vevay, Indiana',
    'America/Chicago':                '(GMT-05:00) Central Time', // (GMT-06:00) Central Time - Chicago
    // 'America/Indiana/Tell_City':      '(GMT-06:00) Central Time - Tell City, Indiana',
    // 'America/Indiana/Knox':           '(GMT-06:00) Central Time - Knox, Indiana',
    // 'America/Menominee':              '(GMT-06:00) Central Time - Menominee',
    // 'America/North_Dakota/Center':    '(GMT-06:00) Central Time - Center, North Dakota',
    // 'America/North_Dakota/New_Salem', '(GMT-06:00) Central Time - New Salem, North Dakota',
    // 'America/North_Dakota/Beulah':    '(GMT-06:00) Central Time - Beulah, North Dakota',
    'America/Denver':                 '(GMT-06:00) Mountain Time', // (GMT-07:00) Mountain Time - Denver
    // 'America/Boise':                  '(GMT-07:00) Mountain Time - Boise',
    'America/Phoenix':                '(GMT-07:00) Arizona', // (GMT-07:00) Mountain Standard Time - Phoenix
    'America/Los_Angeles':            '(GMT-07:00) Pacific Time', // (GMT-08:00) Pacific Time - Los Angeles
    'America/Anchorage':              '(GMT-08:00) Alaska', // (GMT-09:00) Alaska Time - Anchorage
    // 'America/Juneau':                 '(GMT-09:00) Alaska Time - Juneau',
    // 'America/Sitka':                  '(GMT-09:00) Alaska Time - Sitka',
    // 'America/Metlakatla':             '(GMT-09:00) Alaska Time - Metlakatla',
    // 'America/Yakutat':                '(GMT-09:00) Alaska Time - Yakutat',
    // 'America/Nome':                   '(GMT-09:00) Alaska Time - Nome',
    // 'America/Adak':                   '(GMT-10:00) Hawaii-Aleutian Time',
    'Pacific/Honolulu':               '(GMT-10:00) Hawaii' // (GMT-10:00) Hawaii-Aleutian Standard Time
  };

  return (
    <DataCard
      title="Timezone"
      icon={ClockIcon}
      subtitle={timezones[user.data.timezone] || '(not provided)'}
      onClick={() => history.push(Urls(user).LOCATION)}
    />
  );
};
