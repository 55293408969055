import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';

UserOrganization.propTypes = {
  user: PropTypes.object.isRequired
};

export default function UserOrganization(props) {
  const { user } = props;

  const params = {
    where: {
      eager: {
        $where: {
          userId: user.id
        }
      }
    },
    pagination: {
      page: 1,
      pageSize: 100,
      order: 'createdAt asc'
    }
  };

  const roles = useConnect('role.find', params);
  const jobs = useConnect('job.find', params);

  const role = roles.data[0];
  const job = jobs.data[0];

  const organization = useConnect('organization.byId', {
    id: job?.data.organizationId
  }, {
    enabled: !!job?.data.organizationId
  });

  const site = useConnect('site.byId', {
    id: role?.data.siteId
  }, {
    enabled: !!role?.data.siteId
  });

  if (
    roles.state === PayloadStates.FETCHING ||
    jobs.state === PayloadStates.FETCHING
  ) {
    return '...';
  }

  if (!role && !job) {
    return null;
  }

  if (site?.data.name) {
    return site.data.name;
  }

  if (organization?.data.name) {
    return organization.data.name;
  }

  return null;
}
