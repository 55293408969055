import React, { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { useUser } from '@lore/auth';
import avatar from '../../assets/images/avatar.svg';
import { Link } from 'react-router-dom';
import { useConnect } from '@lore/query-connect';
import { useQueryMutation } from '../../hooks/@lore/query-connect';
import { useConfig } from '@lore/config';
import classNames from 'classnames';
import { DialogManager } from '../dialogs_new/DialogManager';
import useDialogLauncher from '../dialogs-routable/_hooks/useDialogLauncher';

function MenuItem(props) {
  const { to, link = false, children } = props;

  return (
    <Menu.Item>
      {({ active }) => link ? (
        <a
          href={to}
          className={classNames(
            active ? 'bg-cs-gray-100 text-cs-gray-900' : 'text-cs-gray-900',
            'block px-4 py-2 text-base'
          )}
        >
          {children}
        </a>
      ) : (
        <Link
          to={to}
          className={classNames(
            active ? 'bg-cs-gray-100 text-cs-gray-900' : 'text-cs-gray-900',
            'block px-4 py-2 text-base'
          )}
        >
          {children}
        </Link>
      )}
    </Menu.Item>
  );
}

function MenuItemCallback(props) {
  const { onClick, children } = props;

  return (
    <Menu.Item>
      {({ active }) => (
        <div
          onClick={onClick}
          className={classNames(
            active ? 'bg-cs-gray-100 text-cs-gray-900' : 'text-cs-gray-900',
            'block px-4 py-2 text-base cursor-pointer'
          )}
        >
          {children}
        </div>
      )}
    </Menu.Item>
  );
}

export default function Account(props) {
  const user = useUser();

  const config = useConfig();
  const stewardUrl = config.custom.stewardUrl();
  const launch = useDialogLauncher();

  const mutation = useQueryMutation('currentUser.updateSingleton', user);

  function getAdminUrl(network) {
    return `${stewardUrl}?networks=${network.id}`;
  }
  // const adminUrl = `${protocol}//${tenant}-admin.${tokens[1]}.${tokens[2]}`;

  const networks = useConnect('network.find', {
    where: {
      eager: {
        $where: {
          isActive: true
        }
      }
    }
  });

  const memberNetworks = useConnect('network.find', {
    where: {
      eager: {
        $where: {
          // 'members.isStaff': true,
          'networkStewards.userId': user.id
        }
      }
    }
  });

  function onChangeProfileType() {
    mutation.mutate({
      isEducator: !user.data.isEducator
    });
  }

  function onChangeCommunity(network) {
    mutation.mutate({
      networkId: network.id
    });
  }

  return (
    <Menu as="div" className="relative inline-block text-left w-full">
      <div>
        <Menu.Button className={classNames(
          'group w-full bg-white rounded-cs-10 p-3',
          'shadow-cs-flat -m-px border border-cs-gray-200',
          'hover:bg-cs-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-cs-orange-500'
        )}>
          <div className="flex space-x-3 text-left">
            <img
              className="w-12 h-12 rounded-full"
              // src="https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80"
              src={user.data.avatarUrl || avatar}
              alt=""
            />
            <span className="flex-1 flex flex-col min-w-0">
              <span className="text-cs-gray-900 text-lg font-semibold">
                {`${user.data.firstName} ${user.data.lastName}`.trim() || 'Profile'}
              </span>
              <span className="text-cs-gray-500 text-sm truncate">
                {user.data.isEducator ? 'Educator' : 'Partner'}
              </span>
            </span>
          </div>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="z-10 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
          <div className="py-1">
            <MenuItem to={`/profile/view`}>
              View profile
            </MenuItem>
            {/*<MenuItemCallback onClick={() => launch(DialogManager.ProfileManage.Config('/view'))}>*/}
            {/*  View profile (dialog)*/}
            {/*</MenuItemCallback>*/}
            <MenuItem to={`/profile/edit`}>
              Edit profile
            </MenuItem>
            <MenuItem to={`/profile/settings`}>
              Settings
            </MenuItem>
          </div>
          {memberNetworks.data.length > 0 && (
            <div className="py-1">
              {memberNetworks.data.map(function(network, index) {
                return (
                  <MenuItem key={index} to={getAdminUrl(network)} link={true}>
                    {memberNetworks.data.length > 1 ?
                      `Admin: ${network.data.name}` :
                      `Admin`
                    }
                  </MenuItem>
                );
              })}
            </div>
          )}
          {user.data.isStaff && (
            <div className="py-1">
              <MenuItem to={stewardUrl} link={true}>
                Admin
              </MenuItem>
            </div>
          )}
          {/*{memberNetworks.data.map(function(network) {*/}
          {/*  getAdminUrl(network)*/}
          {/*})}*/}
          {/*{user.data.isStaff && (*/}
          {/*  <div className="py-1">*/}
          {/*    <MenuItem to={adminUrl} link={true}>*/}
          {/*      Admin*/}
          {/*    </MenuItem>*/}
          {/*  </div>*/}
          {/*)}*/}
          {user.data.isSuperuser && (
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={onChangeProfileType}
                    className={classNames(
                      active ? 'bg-cs-gray-100 text-cs-gray-900' : 'text-cs-gray-900',
                      'block px-4 py-2 text-base cursor-pointer bg-cs-orange-50'
                    )}
                  >
                    Switch profile type to <span className="text-cs-orange-500 font-semibold">{user.data.isEducator ? 'Partner' : 'Educator'}</span>
                  </div>
                )}
              </Menu.Item>
            </div>
          )}
          {user.data.isSuperuser && (
            <div className="py-1">
              <div
                className={classNames(
                  'text-cs-gray-900',
                  'block px-4 py-2 text-base cursor-pointer bg-cs-orange-50'
                )}
              >
                Switch community to
              </div>
              {networks.data.map(function(network) {
                return (
                  <Menu.Item key={network.id}>
                    {({ active }) => (
                      <div
                        onClick={() => onChangeCommunity(network)}
                        className={classNames(
                          active ? 'bg-cs-gray-100 text-cs-gray-900' : 'text-cs-gray-900',
                          'block px-4 py-2 text-base cursor-pointer bg-cs-orange-50'
                        )}
                      >
                        <span className="text-cs-orange-500 font-semibold">{network.data.name}</span>
                      </div>
                    )}
                  </Menu.Item>
                );
              })}
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={() => onChangeCommunity({ id: null })}
                    className={classNames(
                      active ? 'bg-cs-gray-100 text-cs-gray-900' : 'text-cs-gray-900',
                      'block px-4 py-2 text-base cursor-pointer bg-cs-orange-50'
                    )}
                  >
                    <span className="text-cs-orange-500 font-semibold">[None]</span>
                  </div>
                )}
              </Menu.Item>
            </div>
          )}
          <div className="py-1">
            <MenuItem to="/logout">
              Logout
            </MenuItem>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
