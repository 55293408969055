import React from 'react';
import { PayloadStates } from '@lore/utils';
import { ClockIcon } from '@heroicons/react/24/outline';
import IconSection from '../../_new_card_components/IconSection';
import { useUser } from '@lore/auth';
import useEventTags from '../../../hooks/useEventTags';
import TagTypes from '../../../constants/TagTypes';
import { formatTimeRange } from '../../../utils/dateTimeUtils';

export default function Date(props) {
  const { event } = props;

  const currentUser = useUser();
  const eventTimes = useEventTags(event, TagTypes.Time);

  if (eventTimes.state === PayloadStates.FETCHING) {
    return (
      <IconSection
        icon={ClockIcon}
        title="..."
        subtitle="..."
      />
    );
  }

  if (event.data.specificTime === 'Yes') {
    return (
      <IconSection
        icon={ClockIcon}
        title={(
          <div>
            {formatTimeRange(event.data.startTime, event.data.endTime, currentUser.data.timezone)}
          </div>
        )}
      />
    );
  }

  if (eventTimes.data.length > 0) {
    return (
      <IconSection
        icon={ClockIcon}
        title={eventTimes.data.map(function(eventTime) {
          return eventTime.data.name.split('(')[0];
        }).join(', ')}
        // title={eventTimes.data.map(function(eventTime) {
        //   return (
        //     <div key={eventTime.id}>
        //       {eventTime.data.name.split('(')[0]}
        //     </div>
        //   );
        // })}
      />
    );
  }

  return null;
}
