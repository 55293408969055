import React from 'react';

export default function RightMediaSection(props) {
  const { title, subtitle } = props;

  return (
    <div className="flex flex-col text-right">
      <div className="text-lg font-semibold text-gray-700">
        {title}
      </div>
      <div className="flex text-sm text-gray-500 ">
        {subtitle}
      </div>
    </div>
  );
};
