import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import { Link } from 'react-router-dom';
import Avatar from '../../components/Avatar';
import Loader from '../../components/Loader';
import UserOrganization from '../people/__common/UserOrganization.v2';
import { DateTime } from 'luxon';

export default function Invitation(props) {
  const { invitation, onViewMessages } = props;

  const recipient = useConnect('user.byId', {
    id: invitation.data.userId
  });

  const event = useConnect('event.byId', {
    id: invitation.data.eventId
  });

  if (
    recipient.state === PayloadStates.FETCHING ||
    event.state === PayloadStates.FETCHING
  ) {
    return (
      <div className="flex bg-white shadow-lg rounded-xl p-2.5 w-full">
        <Loader />
      </div>
    );
  }

  return (
    <div className="flex bg-whitex shadow-lgx rounded-xlx p-4 w-full relative hover:bg-cs-gray-50">
      {onViewMessages ? (
        <div
          className="absolute w-full h-full cursor-pointer"
          onClick={() => onViewMessages(recipient)}
        />
      ) : (
        <Link to={`/event/${invitation.data.eventId}/messages/${invitation.data.userId}`} className="absolute w-full h-full" />
      )}
      <div className="flex flex-1 items-center space-x-4">
        <Avatar size={12} src={recipient.data.avatarUrl}/>
        <div className="flex flex-1 flex-col">
          <div className="text-lg font-semibold text-cs-gray-900">
            {recipient.data.firstName} {recipient.data.lastName}
          </div>
          <div className="text-sm text-cs-gray-500 space-x-1">
            <span>{DateTime.fromISO(invitation.data.createdAt).toRelative()}</span>
            <span>·</span>
            <UserOrganization user={recipient}/>
            {invitation.data.response === 'No' && (
              <>
                <span>·</span>
                <span className="text-cs-orange-500 font-medium">{invitation.data.response}</span>
                <span>·</span>
                <span>{invitation.data.responseMessage}</span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
