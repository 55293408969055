import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useUser } from '@lore/auth';
import classNames from 'classnames';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';
import { useQueryClient } from '@tanstack/react-query';

Impersonate.propTypes = {
  user: PropTypes.object.isRequired
};

export default function Impersonate(props) {
  const { user } = props;

  const currentUser = useUser();
  const queryClient = useQueryClient();

  const mutation = useQueryMutation('impersonation.create');
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      queryClient.invalidateQueries();
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate({
      userId: currentUser.id,
      impersonatedId: user.id
    });
  }

  if (!currentUser.data.isSuperuser) {
    return null;
  }

  if (user.id === currentUser.id) {
    return (
      <div
        className={classNames(
          'bg-cs-orange-100 text-cs-orange-500 font-semibold uppercase rounded-lg text-center py-3 px-3 w-56x',
          'opacity-80'
        )}
        onClick={onSubmit}
      >
        This is you
      </div>
    );
  }

  return (
    <div
      className={classNames(
        'bg-cs-orange-100 text-cs-orange-500 font-semibold uppercase rounded-lg text-center py-3 px-3 w-56x',
        saving && 'opacity-50',
        !saving && 'cursor-pointer hover:bg-cs-orange-50 shadow-lg'
      )}
      onClick={onSubmit}
    >
      Impersonate
    </div>
  );
}
