import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Overlay from '../../../forms/_common/Overlay';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';
import TagCheckboxFields from '../_common/TagCheckboxFields';

Grades.propTypes = {
  userGrades: PropTypes.object
};

export default function Grades(props) {
  const {
    user,
    grades,
    userGrades
  } = props;

  const mutation_create = useQueryMutation('tagNode.create');
  const mutation_destroy = useQueryMutation('tagNode.destroy2');

  const saving = (
    mutation_create.isLoading ||
    mutation_destroy.isLoading
  );

  function onSelectGrade(grade) {
    const userGrade = _.find(userGrades.data, function(userGrade) {
      return userGrade._tag.id === grade.id;
    });

    if (!userGrade) {
      mutation_create.mutate({
        userId: user.id,
        tagId: grade.id
      }, {
        onSuccess: payload => userGrades._add(payload)
      });
    } else {
      mutation_destroy.mutate(userGrade._tagNode, {
        onSuccess: payload => userGrades._remove(payload)
      });
    }
  }

  return (
    <Overlay isVisible={saving}>
      <TagCheckboxFields
        className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6"
        tags={grades}
        facadeTagNodes={userGrades}
        onChange={onSelectGrade}
        selectable={true}
      />
    </Overlay>
  );
}
