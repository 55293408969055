import React from 'react';

export default function SectionTitle(props) {
  const { title, subtitle, link } = props;

  return (
    <div className="flex items-end mb-4">
      <div className="flex-1">
        <h2 className="font-semibold text-xl text-cs-gray-900">
          {title}
        </h2>
        {subtitle && (
          <div className="text-base text-cs-gray-500">
            {subtitle}
          </div>
        )}
      </div>
      {link}
    </div>
  );
}
