import React, { useState } from 'react';
import useRouter from '../../hooks/useRouter';
import Urls from '../profile/Urls';
import DataCard from './DataCard';
import { PayloadStates } from '@lore/utils';
import useUserTags from '../../hooks/useUserTags';
import TagTypes from '../../constants/TagTypes';

export default function Practices(props) {
  const { user } = props;

  const { history } = useRouter();

  const [timestamp] = useState(new Date().toISOString());

  const userPractices = useUserTags(user, TagTypes.Practice, timestamp);

  let subtitle = '';

  if (userPractices.state === PayloadStates.FETCHING) {
    subtitle = '...';
  } else {
    subtitle = userPractices.data.map(function(userPractice, index) {
      return (
        <div key={index}>
          {userPractice.data.name}
        </div>
      );
    });
  }

  return (
    <DataCard
      title="Practices"
      subtitle={subtitle || '(not provided)'}
      onClick={() => history.push(Urls(user).PRACTICES)}
    />
  );
};
