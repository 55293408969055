import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import { useUser } from '@lore/auth';
import { DateTime } from 'luxon';
import _ from 'lodash';

export default function useConversations(props) {
  const currentUser = useUser();

  const messages = useConnect('message.find', {
    where: {
      eager: {
        $where: {
          $or: {
            senderId: currentUser.id,
            recipientId: currentUser.id
          }
        }
      }
    },
    pagination: {
      pageSize: 1000,
      order: 'createdAt desc'
    }
  });

  if (messages.state === PayloadStates.FETCHING) {
    return {
      state: PayloadStates.FETCHING,
      data: []
    };
  }

  if (messages.data.length === 0) {
    return {
      state: PayloadStates.RESOLVED,
      data: []
    };
  }

  const groupedMessages = _.groupBy(messages.data, function(message) {
    return currentUser.id === message.data.senderId ?
      message.data.recipientId :
      message.data.senderId;
  });

  const conversations = {
    state: PayloadStates.RESOLVED,
    data: []
  };

  _.mapValues(groupedMessages, function(value, key) {
    const sortedMessages = _.sortBy(value, function(message) {
      return -DateTime.fromISO(message.data.createdAt).toMillis();
    });

    const message = sortedMessages[0];
    const userId = currentUser.id === message.data.senderId ?
      message.data.recipientId :
      message.data.senderId;

    conversations.data.push({
      data: {
        key: key,
        userId: userId,
        messageCount: value.length,
        updatedAt: message.data.createdAt
      }
    })
  });

  conversations.data = _.sortBy(conversations.data, function(conversation) {
    return -DateTime.fromISO(conversation.data.updatedAt).toMillis();
  });

  return conversations;
};
