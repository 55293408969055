import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import FormField from '../../../forms/_common/FormField';
import SolidButton from '../../../components/Button/SolidButton';
import FormTemplate2 from '../../_post-opportunity/FlowTemplate';
import TextAreaField from '../../../forms/_fields_v2/TextAreaField';
import FormSection from '../../_post-opportunity/forms/FormSection';
import RadioButtonField from '../../_post-opportunity/_fields/RadioButtonField.cards';
import NodeEntries from '../NodeEntries';
import useTags from '../../../hooks/useTags';
import TagTypes from '../../../constants/TagTypes';
import useOfferTags from '../../../hooks/useOfferTags';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';

Form.propTypes = {
  user: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func,
  onSkip: PropTypes.func
};

const MAX_LENGTH = 512;

export default function Form(props) {
  const {
    user,
    offer,
    navigation,
    onNext,
    onPrevious,
    onSkip,
    onExit,
    FormTemplate = FormTemplate2,
    buttonText = 'Next'
  } = props;

  const [data, setData] = useState(_.pick(offer.data, [
    'supplies',
    'suppliesProvidedBy'
  ]));
  const [showErrors, setShowErrors] = useState(false);
  const [exit, setExit] = useState(false);

  const mutation = useQueryMutation('offer.update', offer);
  const { saving, success, error, request } = mutation;

  const supplies = useTags(TagTypes.Supply);
  const offerSupplies = useOfferTags(offer, TagTypes.Supply);

  const specialSuppliesNeeded = !!_.find(offerSupplies.data, function(offerSupply) {
    return offerSupply.data.name === 'Other special supplies';
  });

  const suppliesNeeded = !!_.find(offerSupplies.data, function(offerSupply) {
    return offerSupply.data.supplyId !== 'No supplies needed.';
  });

  useEffect(() => {
    if (success) {
      exit ? onExit(data) : onNext(data);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate(data);
  }

  function onSubmitAndExit() {
    onSubmit();
    setExit(true);
  }

  function onChange(name, value) {
    setData(_.merge({}, data, {
      [name]: value
    }));
  }

  return (
    <Overlay isVisible={saving}>
      <FormErrors data={data} validators={{
        // field: [validators.maxLength(MAX_LENGTH)]
      }}>
        {({ errors, hasError }) => {
          return (
            <FormTemplate
              onExit={() => hasError ? setShowErrors(true) : onSubmitAndExit()}
              onPrevious={onPrevious}
              navigation={navigation}
              alert={error ? (
                <RequestError request={request} />
              ) : showErrors ? (
                <ErrorMessage>
                  Please fill in all required fields.
                </ErrorMessage>
              ) : null}
              body={(
                <div className="space-y-10">
                  <FormSection
                    // title="Necessary Supplies"
                    // subtitle="Please check all that apply."
                  >
                    <NodeEntries
                      offer={offer}
                      tags={supplies}
                      nodes={offerSupplies}
                    />
                  </FormSection>
                  {specialSuppliesNeeded && (
                    <FormSection
                      title="What special supplies are needed?"
                    >
                      <FormField name="supplies" data={data} errors={showErrors && errors} onChange={onChange}>
                        {(field) => (
                          <TextAreaField
                            // label="What special supplies are needed?"
                            // explanation="What learning goals do you have for your students that you hope a community partner will be able to support?"
                            // description={`${data.interactionDescription ? data.interactionDescription.length : 0}/${MAX_LENGTH} characters`}
                            rows={5}
                            placeholder="Special supplies..."
                            // description={(
                            //   <div className="text-cs-orange-500 cursor-pointer" onClick={onShowSuggestions}>
                            //     Need suggestions for what to include?
                            //   </div>
                            // )}
                            value={field.value}
                            error={field.error}
                            onChange={field.onChange}
                          />
                        )}
                      </FormField>
                    </FormSection>
                  )}
                  {suppliesNeeded && (
                    <FormSection
                      title="Will you or your organization provide these supplies?"
                    >
                      <FormField name="suppliesProvidedBy" data={data} errors={showErrors && errors} onChange={onChange}>
                        {(field) => (
                          <>
                            <RadioButtonField
                              name="suppliesProvidedBy"
                              // label="Will you or your organization provide these supplies?"
                              // value={field.value === 'Yes' ? 'Yes' : 'No'}
                              value={field.value}
                              error={field.error}
                              placeholder="Select a value..."
                              options={{
                                data: [
                                  'Partner will provide supplies',
                                  'Educator needs to provide all supplies'
                                ].map((option) => {
                                  return {
                                    id: option,
                                    data: {
                                      name: option
                                    }
                                  }
                                })
                              }}
                              optionLabel="name"
                              onChange={(value) => field.onChange(value)}
                            />
                          </>
                        )}
                      </FormField>
                    </FormSection>
                  )}
                </div>
              )}
              footer={(
                <>
                  <SolidButton onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
                    {buttonText}
                  </SolidButton>
                </>
              )}
            />
          );
        }}
      </FormErrors>
    </Overlay>
  );
};
