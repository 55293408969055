import React, { useEffect, useState } from 'react';
import MobileHeader from './MobileHeader';

export default function Header(props) {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, []);

  function onResize() {
    setWidth(window.innerWidth);
  }

  if (width < 1024) {
    return (
      <MobileHeader {...props} />
    );
  }

  return null;
};
