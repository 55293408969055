import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import { useUser } from '@lore/auth';
import ClampableText from '../../components/ClampableText';
import Avatar from '../../components/Avatar';
import logo from '../../../assets/images/logo.png';
import { getDistanceFromLatLonInMiles } from '../../components/User/utils';
import UserOrganization from '../../pages/people/__common/UserOrganization.v2';
import useTags from '../../hooks/useTags';
import TagTypes from '../../constants/TagTypes';
import useEventTags from '../../hooks/useEventTags';
import { formatDate, formatDateRange, formatTimeRange } from '../dateTimeUtils';

useEventInviteEmailPreview.propTypes = {
  event: PropTypes.object.isRequired
};

export default function useEventInviteEmailPreview(event, partner, customMessage) {
  // const { event } = props;

  const currentUser = useUser();
  const educator = currentUser;
  const distance = getDistanceFromLatLonInMiles(partner.data, currentUser.data);

  const activity = useConnect('tag.byId', {
    id: event.data.activityId
  });

  const days = useTags(TagTypes.Day);
  const eventDays = useEventTags(event, TagTypes.Day);

  const times = useTags(TagTypes.Time);
  const eventTimes = useEventTags(event, TagTypes.Time);

  const commitments = useTags(TagTypes.Commitment);
  const eventCommitments = useEventTags(event, TagTypes.Commitment);

  const eventInteractionTypes = useEventTags(event, TagTypes.InteractionType);

  if (
    activity.state === PayloadStates.FETCHING ||
    days.state === PayloadStates.FETCHING ||
    eventDays.state === PayloadStates.FETCHING ||
    times.state === PayloadStates.FETCHING ||
    eventTimes.state === PayloadStates.FETCHING ||
    commitments.state === PayloadStates.FETCHING ||
    eventCommitments.state === PayloadStates.FETCHING ||
    eventInteractionTypes.state === PayloadStates.FETCHING
  ) {
    return '...';
  }

  function getActivity() {
    return {
      title: `${activity.data.name}`,
      text: `${activity.data.description}`
    };
  }

  function getSummary() {
    return {
      title: `${event.data.title || activity.data.name}`,
      text: `${event.data.interactionDescription || activity.data.description}`
    };
  }

  function getOutcome() {
    if (event.data.desiredOutcome) {
      return {
        title: `Desired Outcome`,
        text: `${event.data.desiredOutcome}`
      };
    }
  }

  function getDate() {
    if (
      event.data.specificDate === 'Yes' &&
      event.data.date
    ) {
      return {
        title: `Date`,
        text: formatDate(event.data.date)
      };
    }

    if (
      event.data.specificDate === 'No' &&
      event.data.startDate &&
      event.data.endDate
    ) {
      return {
        title: `Date Window`,
        text: formatDateRange(event.data.startDate, event.data.endDate)
      };
    }

    return {
      title: `Date`,
      text: `(not provided)`
    };
  }

  function getDays() {
    if (eventDays.data.length > 0) {
      return {
        title: `Possible Days`,
        text: eventDays.data.map(function(eventDay) {
          return eventDay.data.name;
        }).join('\n')
      };
    }
  }

  function getTime() {
    if (
      event.data.specificTime === 'Yes' &&
      event.data.startTime &&
      event.data.endTime
    ) {
      return {
        title: `Time`,
        text: formatTimeRange(event.data.startTime, event.data.endTime, currentUser.data.timezone)
      };
    }

    if (eventTimes.data.length > 0) {
      return {
        title: `Possible Times`,
        text: eventTimes.data.map(function(eventTime) {
          return eventTime.data.name;
        }).join('\n')
      };
    }

    return {
      title: `Time`,
      text: `(not provided)`
    };
  }

  function getCommitment() {
    if (eventCommitments.data.length > 0) {
      return {
        title: `Commitment`,
        text: eventCommitments.data.map(function(eventCommitment) {
          return eventCommitment.data.name;
        }).join('\n')
      };
    }
  }

  function getInPerson() {
    return !!_.find(eventInteractionTypes.data, function(eventInteractionType) {
      return eventInteractionType.data.name === 'In-person';
    });
  }

  function getVirtual() {
    return !!_.find(eventInteractionTypes.data, function(eventInteractionType) {
      return eventInteractionType.data.name === 'Virtual';
    });
  }

  const data = {
    activity: getActivity(),
    summary: getSummary(),
    // outcome: getOutcome(),
    date: getDate(),
    // days: getDays(),
    time: getTime(),
    // commitment: getCommitment(),
    inPerson: getInPerson(),
    virtual: getVirtual()
  }

  return (
    <div className="bg-cs-gray-50 p-6">
      <div className="h-1 bg-cs-orange-500 w-full mb-8"/>
      <div className="p-6 mb-8">
        <img
          className="w-24 mx-auto"
          src={logo}
          alt="logo"
        />
      </div>
      <div className="mb-8">
        <div className="text-base text-cs-gray-900">
          Hi {partner.data.firstName},
        </div>
        <div className="mt-3 text-base text-cs-gray-900">
          <ClampableText markup>
            {customMessage}
          </ClampableText>
        </div>
        <div className="mt-3 mb-3">
          <div className="text-base text-cs-gray-900">
            Cheers,
          </div>
        </div>
        <div className="flex items-center space-x-3">
          <div>
            <Avatar size={12} src={currentUser.data.avatarUrl} />
          </div>
          <div>
            <div className="text-lg text-cs-gray-900 font-semibold">
              {currentUser.data.firstName} {currentUser.data.lastName}
            </div>
            <div className="text-sm text-cs-gray-500">
              <UserOrganization user={currentUser} />
            </div>
          </div>
        </div>
      </div>
      <div className="mb-8">
        <div className="flex flex-col text-center justify-center bg-cs-orange-500 rounded-xl p-6">
          <div className="text-2xl text-white font-semibold mb-2">
            Are you available?
          </div>
          <div className="text-base text-white mb-4">
            Let {currentUser.data.firstName} know whether you plan to attend, have questions,
            or have to send your regrets this time.
          </div>
          <div className="bg-white rounded-lg w-56 text-center py-3 m-auto">
            <div className="text-base text-cs-orange-500 uppercase font-semibold">
              RSVP Now
            </div>
          </div>
        </div>
      </div>
      <div className="mb-8 space-y-4">
        <div className="text-2xl font-semibold">
          Details
        </div>
        <div className="bg-white rounded-lg p-6 space-y-8">
          <div className="text-2xl font-semibold">
            {data.summary.title}
          </div>
          <div>
            <div className="text-xl font-semibold text-cs-gray-900">
              {data.date.title}
            </div>
            <div className="text-cs-gray-500">
              <ClampableText markup>
                {data.date.text}
              </ClampableText>
            </div>
          </div>
          <div>
            <div className="text-xl font-semibold text-cs-gray-900">
              {data.time.title}
            </div>
            <div className="text-cs-gray-500">
              <ClampableText markup>
                {data.time.text}
              </ClampableText>
            </div>
          </div>
          <div>
            <div className="text-xl font-semibold text-cs-gray-900">
              Collaboration Options
            </div>
            {!data.virtual && !data.inPerson && (
              <div>
                <div className="text-cs-gray-900">
                  (not provided)
                </div>
                <div className="text-xs text-cs-gray-500">
                  {distance <= 1 ? '1 mile away' : `${distance} miles away`}
                </div>
              </div>
            )}
            {data.virtual && (
              <div>
                <div className="text-cs-gray-900 mb-1">
                  Virtual
                </div>
              </div>
            )}
            {data.inPerson && (
              <div>
                <div className="text-cs-gray-900">
                  In-person
                </div>
                <div className="text-xs text-cs-gray-500">
                  {distance <= 1 ? '1 mile away' : `${distance} miles away`}
                </div>
              </div>
            )}
          </div>
          <div>
            <div className="text-xl font-semibold text-cs-gray-900">
              Description
            </div>
            <div className="text-base text-cs-gray-500">
              <ClampableText markup>
                {data.summary.text}
              </ClampableText>
            </div>
          </div>
        </div>
        <div className="text-2xl font-semibold">
          Activity
        </div>
        <div className="bg-white rounded-lg p-6">
          <div className="text-xl font-semibold text-cs-gray-900">
            {data.activity.title}
          </div>
          <div className="text-cs-gray-500">
            {data.activity.text}
          </div>
        </div>
      </div>
      <div className="mb-8">
        <div className="flex flex-col text-center justify-center bg-cs-orange-500 rounded-xl p-6">
          <div className="text-2xl text-white font-semibold mb-2">
            Are you available?
          </div>
          <div className="text-base text-white mb-4">
            Let {currentUser.data.firstName} know whether you plan to attend, have questions,
            or have to send your regrets this time.
          </div>
          <div className="bg-white rounded-lg w-56 text-center py-3 m-auto">
            <div className="text-base text-cs-orange-500 uppercase font-semibold">
              RSVP Now
            </div>
          </div>
        </div>
      </div>
      <div className="h-1 bg-cs-orange-500 w-full"/>
    </div>
  );
};
