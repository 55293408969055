import React from 'react';
import { getTimestampDiff } from '../../utils/dateTimeUtils';
import { SolidButton } from '../../components/Button';
import Complete from '../evaluations/ProjectEvaluation/status/Complete';

export default function EvaluationButton(props) {
  const { evaluation, onView, onEdit } = props;

  const diff = getTimestampDiff(evaluation.data.updatedAt, evaluation.data.createdAt);

  if (evaluation.data.completed) {
    return null;
    // return (
    //   <Complete
    //     evaluation={evaluation}
    //     onClickView={onView}
    //     onClickEdit={onEdit}
    //   />
    // );
  }

  if (diff > 0) {
    return (
      <SolidButton
        label="Finish sharing"
        onClick={onEdit}
      />
    );
  }

  return (
    <SolidButton
      label="Start now"
      onClick={onEdit}
    />
  );
}
