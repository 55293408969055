import React from 'react';
import { Helmet } from 'react-helmet-async';
import Stories from './Stories';
import Partners from './Partners';
import Educators from './Educators';
import PageHeader from '../people-people/PageHeader';

export default function Layout(props) {
  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Your bookmarks</title>
      </Helmet>
      <PageHeader
        title="Your bookmarks"
        subtitle={(
          <>
            Looking for someone to collaborate with on a future project? Want to revisit
            an inspiring impact story? Bookmark them and come back to view them anytime, right here.
          </>
        )}
      />
      <div className="space-y-10">
        <Partners />
        <Stories />
        <Educators />
      </div>
    </div>
  );
};
