import React from 'react';
import PropTypes from 'prop-types';
import { useConnect } from '@lore/query-connect';
import StoryBookmark from '../../../components/StoryBookmark';
import { useUser } from '@lore/auth';
import DateIcon from './DateIcon';
import HeaderIcon from './HeaderIcon';
import { BuildingOfficeIcon } from '@heroicons/react/24/outline';

Header.propTypes = {
  story: PropTypes.object.isRequired
};

export default function Header(props) {
  const { story } = props;

  const currentUser = useUser();

  const place = useConnect('place.byId', {
    id: story.data.placeId
  }, {
    enabled: !!story.data.placeId
  });

  return (
    <div className="relative">
      <div className="h-64 rounded-cs-10 overflow-hidden w-full relative bg-cs-gray-500  mb-4">
        <div className="absolute z-10 top-2 right-4">
          <StoryBookmark
            story={story}
            user={currentUser}
          />
        </div>
        <img
          className="absolute left-0 top-0 object-cover object-center h-full w-full"
          src={story.data.imageUrl}
          alt="image"
        />
      </div>
      <div className="text-3xl text-cs-gray-900 font-semibold mb-2">
        {story.data.title}
      </div>
      <div className="flex items-center space-x-4">
        <DateIcon story={story} />
        {place.data.name && (
          <HeaderIcon
            icon={BuildingOfficeIcon}
            label={place.data.name}
          />
        )}
      </div>
    </div>
  );
};
