import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as filestack from 'filestack-js';
import { useConfig } from '@lore/config';
import OutlineButton from '../../../components_deprecated/OutlineButton';
// import { OutlineButton } from '../../../components/Button';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';
import { useQueryClient } from '@tanstack/react-query';

EvaluationUpload.propTypes = {
  evaluation: PropTypes.object.isRequired,
  onUpload: PropTypes.func
};

export default function EvaluationUpload(props) {
  const { evaluation, onUpload } = props;
  const config = useConfig();
  const queryClient = useQueryClient();

  const mutation = useQueryMutation('upload.create');
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      onUpload(request);
    }
  }, [success]);

  function onSubmit(uploadedFile) {
    mutation.mutate({
      name: uploadedFile.filename,
      url: uploadedFile.url,
      evaluationProjectEducatorId: evaluation.id
    }, {
      onSuccess: function() {
        queryClient.invalidateQueries({ queryKey: ['upload', 'find'] });
      }
    });
  }

  function onClick() {
    const {
      apiKey,
      imageMax,
      accept
    } = config.filestack;

    const client = filestack.init(apiKey);
    client.picker({
      accept: accept,
      imageMax: imageMax,
      uploadInBackground: false,
      onUploadDone: function(result) {
        // console.log(JSON.stringify(result.filesUploaded));
        const uploadedFile = result.filesUploaded[0];

        if (uploadedFile) {
          onSubmit(uploadedFile);
        }
      }
    }).open();
  }

  return (
    <OutlineButton onClick={onClick}>
      Upload document
    </OutlineButton>
  );
}
