import React from 'react';
import { Helmet } from 'react-helmet-async';
import Educators from './Educators';
import useRouter from '../../hooks/useRouter';
import PageHeader from '../people-people/PageHeader';

export default function Layout(props) {
  const { offer } = props;

  const router = useRouter();

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Explore educators</title>
      </Helmet>
      <PageHeader
        title="Explore educators"
        subtitle={(
          <>
            Connect with inspiring educators and explore their projects and events. Use
            filters to search by grade levels, subjects, and more.
          </>
        )}
      />
      <Educators
        offer={offer}
        router={router}
      />
    </div>
  );
}
