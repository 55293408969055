import React from 'react';
import PropTypes from 'prop-types';
import FormField from '../../forms/_common/FormField';
import { OutlineButton, SolidButton } from '../../components/Button';
import TextAreaField from '../../forms/_fields_v2/TextAreaField';
import FormTemplate from '../../dialogs_common/FormTemplate';
import DESCRIPTION_MAX_LENGTH from './DESCRIPTION_MAX_LENGTH';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

Form.defaultProps = {
  data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    title,
    subtitle,
    label,
    placeholder,
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onCancel,
    icon
  } = props;

  return (
    <FormTemplate
      icon={icon && (
        <div className="flex justify-center mb-4">
        <props.icon className="text-cs-orange-500 h-12 w-12" />
        </div>
      )}
      title={title}
      subtitle={subtitle}
      onClose={onCancel}
      alert={alert}
      body={(
        <>
          <FormField name="description" data={data} errors={showErrors && errors} onChange={onChange}>
            {(field) => (
              <TextAreaField
                // label="Description*"
                label={label}
                placeholder={placeholder}
                description={(
                  <span className={field.value && field.value.length > DESCRIPTION_MAX_LENGTH ? 'text-red-600' : ''}>
                    {`${field.value ? field.value.length : 0}/${DESCRIPTION_MAX_LENGTH} characters`}
                  </span>
                )}
                rows={4}
                value={field.value}
                error={field.error}
                onChange={field.onChange}
              />
            )}
          </FormField>
        </>
      )}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onCancel}>
            Cancel
          </OutlineButton>
          <SolidButton onClick={onSubmit}>
            Save
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
