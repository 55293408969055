import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useUser } from '@lore/auth';
import validators from '../../utils/validators';
import Template from './Template';
import Form from './Form';

MessageCreateForm.propTypes = {
  user: PropTypes.object.isRequired
};

export default function MessageCreateForm(props) {
  const { user, project, event, offer } = props;

  const [key, setKey] = useState(0);
  const [showNotification, setShowNotification] = useState(false);

  const currentUser = useUser();

  function onSubmit() {
    setKey(key + 1);
    setShowNotification(true);
  }

  return (
    <div key={key}>
      <Template
        user={user}
        project={project}
        event={event}
        offer={offer}
        validators={function(data) {
          return {
            content: [validators.isRequired]
          };
        }}
        successMessage="Message sent"
        onSubmit={onSubmit}
      >
        {({ data, errors, showErrors, onChange, onSubmit, alert }) => (
          <Form
            data={data}
            errors={errors}
            showErrors={showErrors}
            onChange={onChange}
            onSubmit={onSubmit}
            alert={alert}
          />
        )}
      </Template>
    </div>
  );
}
