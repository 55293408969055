import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { useUser } from '@lore/auth';
import { v4 as uuidv4 } from 'uuid';
import { Datepicker } from 'tw-elements';

DateRangeField.propTypes = {
  value: PropTypes.string,
  label: PropTypes.node,
  placeholder: PropTypes.string,
  description: PropTypes.node,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  error: PropTypes.node,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onStartDateChange: PropTypes.func,
  onEndDateChange: PropTypes.func,
};

DateRangeField.defaultProps = {
  disabled: false
};

export default function DateRangeField(props) {
  const {
    value,
    label,
    placeholder,
    description,
    disabled,
    onChange,
    error,
    startDate,
    endDate,
    onStartDateChange,
    onEndDateChange
  } = props;

  const startDateRef = useRef(null);
  const endDateRef = useRef(null);

  const currentUser = useUser();

  const [startFormId] = useState(`f-${uuidv4()}`);
  const [endFormId] = useState(`f-${uuidv4()}`);

  /*
   * Start Date
   */
  useEffect(() => {
    const datepickerNode = document.querySelector(`#${startFormId}`);
    const datepicker = new Datepicker(datepickerNode, {
      confirmDateOnSelect: true,
      removeClearBtn: true
    });

    datepickerNode.addEventListener("dateChange.te.datepicker", (event) => {
      const year = event.date.getFullYear();
      const month = event.date.getMonth() + 1;
      const day = event.date.getDate();

      const nextDate = [
        year,
        month < 10 ? `0${month}` : month,
        day < 10 ? `0${day}` : day
      ].join('-');

      onStartDateChange(nextDate);
    });
  }, []);

  /*
   * End Date
   */
  useEffect(() => {
    const datepickerNode = document.querySelector(`#${endFormId}`);
    const datepicker = new Datepicker(datepickerNode, {
      confirmDateOnSelect: true,
      removeClearBtn: true
    });

    datepickerNode.addEventListener("dateChange.te.datepicker", (event) => {
      const year = event.date.getFullYear();
      const month = event.date.getMonth() + 1;
      const day = event.date.getDate();

      const nextDate = [
        year,
        month < 10 ? `0${month}` : month,
        day < 10 ? `0${day}` : day
      ].join('-');

      onEndDateChange(nextDate);
    });
  }, []);

  return (
    <div className="form-group">
      {label ? (
        <label className="text-base font-semibold text-cs-gray-900">
          {label}
        </label>
      ): null}
      <div className="flex items-center input-group input-daterange">
        <div
          id={startFormId}
          className="flex-1"
          data-te-datepicker-init
          data-te-format="mmm d, yyyy"
        >
          <input
            type="text"
            value={DateTime.fromISO(startDate).setZone(currentUser.data.timezone).toLocaleString(DateTime.DATE_MED)}
            className="form-control"
            onChange={() => {}}
            data-te-toggle={startFormId}
            data-te-datepicker-toggle-ref
            data-te-datepicker-toggle-button-ref
          />
        </div>
        <div className="input-group-addon px-4 text-cs-gray-900">to</div>
        <div
          id={endFormId}
          className="flex-1"
          data-te-datepicker-init
          data-te-format="mmm d, yyyy"
        >
          <input
            type="text"
            value={DateTime.fromISO(endDate).setZone(currentUser.data.timezone).toLocaleString(DateTime.DATE_MED)}
            className="form-control"
            onChange={() => {}}
            data-te-toggle={endFormId}
            data-te-datepicker-toggle-ref
            data-te-datepicker-toggle-button-ref
          />
        </div>
      </div>
      <div className={`form-control ${error ? 'is-invalid' : ''}`} style={{ display: 'none'}}/>
      {error ? (
        <div className="invalid-feedback">
          {error}
        </div>
      ) : null}
      {description ? (
        <small className="form-text text-muted">
          {description}
        </small>
      ) : null}
    </div>
  );
}
