import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import ActionBar from './ActionBar';
import Project from '../../pages/project/Project';
import ProjectEditLayout from '../../pages/project-edit/Dialog';
import ProjectManageLayout from '../../pages/project-manage/Dialog';
import ProjectClassifyLayout from '../../pages/project-classify/Dialog';
import ProjectInvitePopulateLayout from '../../pages/project-invite-populate/Dialog';
import ProjectInviteLayout from '../../pages/project-invite/Dialog';
import useDialogConfig from '../_hooks/useDialogConfig';
import useDialogRouter from '../_hooks/useDialogRouter';
import MissingFields from '../../pages/project-manage/MissingFields';
import Banner from './Banner';

export default function ProjectManageDialogLayout(props) {
  const { project } = props;

  const { id, route } = useDialogConfig();
  const router = useDialogRouter(id);

  useEffect(() => {
    if (route === '/') {
      router.navigate('/manage');
    }
  }, []);

  if (project.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  const pageMap = {
    '/manage': 'Manage',
    '/view': 'View',
    '/edit': 'Edit',
    '/invite-populate': 'Invite',
    '/invite': 'Invite',
    '/classify': 'Classify'
  };

  const pathname = route.split('?')[0];

  // const page = pageMap[route] || route;
  const page = pageMap[pathname] || pathname;

  return (
    <div className="space-y-6">
      <Helmet>
        <title>
          {project.data.title ? `${project.data.title}: ${page}` : `Project #${project.id}: ${page}`}
        </title>
      </Helmet>
      <Banner project={project} />
      {/*<div className="-mx-6">*/}
        <ActionBar project={project} />
      {/*</div>*/}
      <div className="space-y-12">
        {pathname === `/view` && (
          <Project project={project} />
        )}
        {pathname === `/edit` && (
          <>
            <MissingFields project={project} />
            <ProjectEditLayout
              project={project}
              onClose={props.onCancel}
            />
          </>
        )}
        {pathname === `/invite-populate` && (
          <ProjectInvitePopulateLayout
            project={project}
            onClose={props.onCancel}
          />
        )}
        {pathname === `/invite` && (
          <ProjectInviteLayout
            project={project}
            router={router}
            onClose={props.onCancel}
          />
        )}
        {pathname === `/manage` && (
          <ProjectManageLayout
            project={project}
            onClose={props.onCancel}
          />
        )}
        {pathname === `/classify` && (
          <ProjectClassifyLayout
            project={project}
            onClose={props.onCancel}
          />
        )}
      </div>
    </div>
  );
};
