import React from 'react';
import { useConnect } from '@lore/query-connect';
import BaseActivity from '../../../components_profiles/BaseActivity';
import { PayloadStates } from '@lore/utils';

export default function Activity(props) {
  const { project } = props;

  const activity = useConnect('tag.byId', {
    id: project.data.activityId
  });

  if (activity.state === PayloadStates.FETCHING) {
    return (
      <BaseActivity.Loading />
    );
  }

  return (
    <BaseActivity activity={activity} />
  );
};
