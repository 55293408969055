import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import Card from '../../../components/Card';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import DividerCard from '../../../components/DividerCard';
import ComposableQuestionHeader from '../../../components_cards/ComposableQuestionHeader';
import { SolidButton } from '../../../components/Button';
import ProjectTag from '../../../components_cards/tags/ProjectTag';
import TagRow from '../../../components_cards/tags/TagRow';
import Creator from './Creator';
import Meta from '../../../components_cards/ComposableQuestionHeader/Meta';

export default function Question(props) {
  const { question } = props;

  const launch = useDialogLauncher();

  const recipient = useConnect('user.byId', {
    id: question.data.userId
  });

  const creator = useConnect('user.byId', {
    id: question.data.creatorId
  });

  const project = useConnect('project.byId', {
    id: question.data.projectId
  });

  const projectCreator = useConnect('user.byId', {
    id: project.data.creatorId
  }, {
    enabled: !!project.data.creatorId
  });

  if (
    recipient.state === PayloadStates.FETCHING ||
    project.state === PayloadStates.FETCHING ||
    creator.state === PayloadStates.FETCHING ||
    projectCreator.state === PayloadStates.FETCHING
  ) {
    return (
      <Card>
        <Loader />
      </Card>
    );
  }

  function onViewProject() {
    launch(DialogManager.QuestionView.Config(question.id));
  }

  return (
    <DividerCard className="overflow-hidden -m-px border border-cs-orange-500" border={false}>
      <ComposableQuestionHeader
        question={question}
        creator={creator}
        tags={(
          <TagRow>
            <ProjectTag />
          </TagRow>
        )}
        showTags={false}
        showCreator={false}
      />
      <div className="w-full bg-cs-gray-50 py-4 px-4 flex justify-between items-center">
        <Creator project={project} />
        <div className="flex items-center space-x-2">
          {/*<GrayOutlineButton*/}
          {/*  onClick={onViewProject}*/}
          {/*  label="View project"*/}
          {/*/>*/}
          <SolidButton
            onClick={onViewProject}
            label="Check it out!"
          />
          {/*<div className="flex flex-col items-end space-y-2">*/}
          {/*  <SolidButton*/}
          {/*    onClick={onViewProject}*/}
          {/*    label="Check it out!"*/}
          {/*  />*/}
          {/*  <Meta question={question} />*/}
          {/*</div>*/}
        </div>
      </div>
    </DividerCard>
  );
};
