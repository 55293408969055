import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import {
  ChatBubbleLeftRightIcon,
  HeartIcon,
  KeyIcon,
  UserGroupIcon
} from '@heroicons/react/24/outline';
import PrimaryHeader from '../../components/PrimaryHeader';
import EmptyState from '../../components/EmptyState';
import useNetwork from '../../hooks/useNetwork';
import Loader from '../../components/Loader';
import Steward from './Steward';
import Section from './Section';
import Option from './Option';
import { useUser } from '@lore/auth';
import Services from './Services';
import Card from '../../components/Card';
import Divider from '../../forms/_fields_v2/Divider';

export default function Layout(props) {
  const network = useNetwork();

  const currentUser = useUser();

  const stewards = useConnect('steward.find', {
    where: {
      eager: {
        $where: {
          networkId: network.id
        }
      }
    }
  });

  if (stewards.state === PayloadStates.FETCHING) {
    return (
      <>
        <Helmet>
          <title>Your network resources</title>
        </Helmet>
        <PrimaryHeader
          style="simple"
          title="Your network resources"
        />
        <div className="container page">
          <Loader />
        </div>
      </>
    );
  }

  if (stewards.data.length === 0) {
    return (
      <>
        <Helmet>
          <title>Your network resources</title>
        </Helmet>
        <PrimaryHeader
          style="simple"
          title="Your network resources"
        />
        <div className="container page">
          <EmptyState
            title="There are currently no stewards supporting this community."
          />
        </div>
      </>
    );
  }

  const placeholderLink = [
    `mailto:placeholder@example.com`,
    `?subject=`,
    encodeURIComponent(`Subject Placeholder`),
    `&body=`,
    encodeURIComponent(`This is ${currentUser.data.firstName} ${currentUser.data.lastName} and I have a question.`)
  ].join('');

  return (
    <>
      <Helmet>
        <title>Your network resources</title>
      </Helmet>
      <PrimaryHeader
        style="simple"
        title="Your network resources"
      />
      <div className="containerx pagex px-8 mb-12 space-y-12">
        <Section
          title="Your local stewards"
          subtitle={(
            <>
              Your local stewards are here to help you build connections and turn your ideas into
              reality! Explore the ways they can support you below.
            </>
          )}
        >
          <div className="grid grid-cols-1 gap-6">
            {stewards.data.map(function(steward, index) {
              return (
                <Steward
                  key={index}
                  steward={steward}
                />
              );
            })}
          </div>
        </Section>
        {/*{currentUser.data.isEducator && (*/}
          <Services />
        {/*)}*/}
        {/*{currentUser.data.isEducator && (*/}
          <Section
            title="Dive into community-engaged learning"
            // subtitle={(
            //   <>
            //     Our network-wide Learning Designer is available on a first come, first served
            //     basis to talk through project ideas and shape your learning experience.
            //   </>
            // )}
          >
            <Card className="overflow-hidden">
              <Option
                icon={UserGroupIcon}
                title="Join our National Educator Fellowship"
                subtitle={(
                  <>
                    Activate learner engagement and agency by co-creating real-world learning
                    experiences with your students and community. Connect with fellow educators,
                    receive personalized coaching, and take a dive deep into Community-Engaged Learning.
                  </>
                )}
                linkText="Learn more"
                link="https://www.communityshare.org/educator-fellowship"
              />
              <div className="px-4">
                <Divider />
              </div>
              <Option
                // icon={AcademicCapIcon}
                icon={KeyIcon}
                title="Explore our Community-Engaged Learning Framework"
                subtitle={(
                  <>
                    Discover how Community-Engaged Learning connects curiosity, community, and
                    meaningful contribution. Learn about the possibilities these experiences unlock
                    for learners.
                  </>
                )}
                linkText="Explore"
                link="https://www.communityshare.org/community-engaged-learning-framework/"
              />
              <div className="px-4">
                <Divider />
              </div>
              <Option
                icon={ChatBubbleLeftRightIcon}
                title="Talk with our Professional Learning Designer"
                subtitle="Connect to talk through ideas and begin shaping a community-engaged learning experience."
                linkText="Connect"
                link="https://calendar.app.google/swMxnJTbn7iMri3t7"
              />
            </Card>
          </Section>
        <Section
          title="Explore additional resources"
          // subtitle={(
          //   <>
          //     Our network-wide Learning Designer is available on a first come, first served
          //     basis to talk through project ideas and shape your learning experience.
          //   </>
          // )}
        >
          <Card className="overflow-hidden">
            <Option
              icon={HeartIcon}
              title="Sharing about CommunityShare"
              subtitle={(
                <>
                  Love CommunityShare? Help us spread the word with resources like fliers,
                  social posts, presentations, and other tools.
                </>
              )}
              linkText="Find tools"
              link="https://sites.google.com/communityshare.org/ambassadors/home"
            />
          </Card>
        </Section>
        {/*)}*/}
      </div>
    </>
  );
};
