/**
 * Configuration file for Google Analytics
 *
 * This file is where you define overrides for the default google parameters.
 */

export default {
  measurementId: 'G-MQ0J80YJ7P',
  enabled: false
};
