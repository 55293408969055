import React from 'react';
import { useUser } from '@lore/auth';
import { useConnect } from '@lore/query-connect';
import ParentLayout from '../Layout';
import Navigation from '../../../dialogs_common/Navigation';
import Urls from '../Urls';
import Form from './Form';
import { PayloadStates } from '@lore/utils';
import useRouter from '../../../hooks/useRouter';

export default function Layout(props) {
  const { location, history, match } = useRouter();
  const user = useUser();
  const offer = useConnect('offer.byId', {
    id: Number(match.params.offerId)
  })

  if (offer.state === PayloadStates.FETCHING) {
    return null;
  }

  return (
    <ParentLayout
      offer={offer}
      page="Supplies"
      title="What supplies (if any) do educators and students need in order to participate in this Offer?"
      description="Please check all that apply."
    >
      <Form
        user={user}
        offer={offer}
        navigation={(
          <Navigation v5={true} activeStep={6} steps={16}/>
        )}
        onPrevious={(newData) => history.push(Urls(offer).SUBJECTS)}
        onNext={(newData) => history.push(Urls(offer).PARTICIPANTS)}
        onSkip={() => history.push(Urls(offer).PARTICIPANTS)}
        onExit={() => history.push(Urls(offer).OFFER_EDIT)}
      />
    </ParentLayout>
  );
};
