import React from 'react';
import PropTypes from 'prop-types';
import Job from './Job';

Jobs.propTypes = {
  jobs: PropTypes.object.isRequired
};

export default function Jobs(props) {
  const { jobs, children } = props;

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 gap-6">
        {jobs.data.map(function(job) {
          return (
            <Job
              key={job.id}
              job={job}
            />
          );
        })}
      </div>
      {children}
    </div>
  );
};
