import { PayloadStates } from '@lore/utils';
import usePendingProjectEducatorEvaluations from './usePendingProjectEducatorEvaluations';
import usePendingProjectPartnerEvaluations from './usePendingProjectPartnerEvaluations';
import usePendingEventEducatorEvaluations from './usePendingEventEducatorEvaluations';
import usePendingEventPartnerEvaluations from './usePendingEventPartnerEvaluations';

export default function usePendingEvaluationsCount(props) {
  const evaluationProjectEducators = usePendingProjectEducatorEvaluations();
  const evaluationProjectPartners = usePendingProjectPartnerEvaluations();
  const evaluationEventEducators = usePendingEventEducatorEvaluations();
  const evaluationEventPartners = usePendingEventPartnerEvaluations();

  if (
    evaluationProjectEducators.state === PayloadStates.FETCHING ||
    evaluationProjectPartners.state === PayloadStates.FETCHING ||
    evaluationEventEducators.state === PayloadStates.FETCHING ||
    evaluationEventPartners.state === PayloadStates.FETCHING
  ) {
    return '...';
  }

  if (
    evaluationProjectEducators.data.length === 0 &&
    evaluationProjectPartners.data.length === 0 &&
    evaluationEventEducators.data.length === 0 &&
    evaluationEventPartners.data.length === 0
  ) {
    return null;
  }

  return (
    evaluationProjectEducators.data.length +
    evaluationProjectPartners.data.length +
    evaluationEventEducators.data.length +
    evaluationEventPartners.data.length
  );
}
