import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useDialogConfig from '../_hooks/useDialogConfig';
import UserProfileDialog from '../../dialogs/user.view';

export default function Launcher(props) {
  const config = useDialogConfig();

  const user = useConnect('user.byId', {
    id: config.params.userId
  });

  // to do: remove reference to user -- it's a hack to prevent throwing an error
  // when no modelName is provided
  const model = useConnect(`${config.params.modelName || 'user'}.byId`, {
    id: config.params.modelId
  }, {
    enabled: !!(config.params.modelName && config.params.modelId)
  });

  if (
    user.state === PayloadStates.FETCHING ||
    (
      config.params.modelName &&
      config.params.modelId &&
      model.state === PayloadStates.FETCHING
    )
  ) {
    return (
      <Loader />
    );
  }

  const extra = {};

  if (
    config.params.modelName &&
    config.params.modelId
  ) {
    extra[config.params.modelName] = model;
  }

  return (
    <UserProfileDialog
      user={user}
      {...extra}
      onCancel={props.onClose}
    />
  );
};
