import React from 'react';
import { useConnect } from '@lore/query-connect';
import ClampableText from '../../../components/ClampableText';
import ContentCard from '../../../components_profiles/ContentCard';
import TextContentSection from '../../../components_profiles/TextContentSection';

export default function Description(props) {
  const { project } = props;

  const activity = useConnect('tag.byId', {
    id: project.data.activityId
  });

  return (
    <ContentCard className="space-y-4">
      <div className="text-2xl text-cs-gray-900 font-semibold">
        {project.data.title || activity.data.name}
      </div>
      <TextContentSection title="Summary">
        <ClampableText markup>
          {project.data.interactionDescription || activity.data.description}
        </ClampableText>
      </TextContentSection>
      {project.data.desiredOutcome && (
        <TextContentSection title="Desired outcome">
          <ClampableText markup>
            {project.data.desiredOutcome}
          </ClampableText>
        </TextContentSection>
      )}
    </ContentCard>
  );
};
