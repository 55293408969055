import React from 'react';
import { useConnect } from '@lore/query-connect';
import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';
import avatar from '../../../assets/images/avatar.svg';
import Card from '../../components/Card';

export default function Steward(props) {
  const { steward } = props;

  const user = useConnect('user.byId', {
    id: steward.data.userId
  });

  const phoneLink = steward.data.phone
    .replace('(','')
    .replace(')','')
    .replace(' ','')
    .replace('-','');

  return (
    <Card>
      {/*<div className="p-6 space-y-6">*/}
      <div className="flex flex-col sm:flex-row px-4 py-3 space-x-0 sm:space-x-4 space-y-2 sm:space-y-0">
        <div className="h-14 w-14">
          <img className="object-cover rounded-full aspect-1" src={steward.data.photo || avatar} alt="" />
        </div>
        <div className="flex-1 space-y-1">
          <div>
            <div className="text-lg font-semibold text-cs-gray-900">
              {steward.data.firstName} {steward.data.lastName}
            </div>
            <div className="text-sm text-cs-gray-500">
              {steward.data.about}
            </div>
          </div>
          <div className="flex flex-col md:flex-row space-y-1 space-x-0 md:space-y-0 md:space-x-4">
            {steward.data.email && (
              <div className="flex items-center space-x-1 text-cs-blue-500">
                <EnvelopeIcon className="h-5 w-5"/>
                <div className="flex-1">
                  <a className="block text-sm text-cs-blue-500" href={`mailto:${steward.data.email}`}>
                    {steward.data.email}
                  </a>
                </div>
              </div>
            )}
            {steward.data.phone && (
              <div className="flex items-center space-x-1 text-cs-blue-500">
                <PhoneIcon className="h-5 w-5"/>
                <div className="flex-1">
                  <a className="block text-sm text-cs-blue-500" href={`tel:${phoneLink}`}>
                    {steward.data.phone}
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};
