import React from 'react';
import { Helmet } from 'react-helmet-async';
import PastCreatedActivities from './PastCreatedActivities';
import PastParticipatedActivities from './PastParticipatedActivities';
import PageHeader from '../people-people/PageHeader';

function Header(props) {
  const { children } = props;

  return (
    <div className="p-8 space-y-8">
      <Helmet>
        <title>Past Activities</title>
      </Helmet>
      <PageHeader
        title="See Past Activities"
      />
      <div className="space-y-8">
        {children}
      </div>
    </div>
  );
}

export default function Layout(props) {
  return (
    <Header>
      <div className="space-y-8">
        <PastCreatedActivities />
        <PastParticipatedActivities />
      </div>
    </Header>
  );
}
