import { TextColor, TextWeight, TextSize } from './utils';
import Base from './Base';
import BaseGrayMedium from './BaseGrayMedium';
import BaseSemibold from './BaseSemibold';
import Xl from './Xl';
import Lg from './Lg';
import LgSemibold from './LgSemibold';
import Sm from './Sm';
import SmGrayMedium from './SmGrayMedium';
import XlSemibold from './XlSemibold';

export default {
  Base,
  BaseGrayMedium,
  BaseSemibold,
  Xl,
  XlSemibold,
  Lg,
  LgSemibold,
  Sm,
  SmGrayMedium
};

export {
  TextColor,
  TextWeight,
  TextSize
};
