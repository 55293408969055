import React from 'react';
import TagRow from '../../../components_cards/tags/TagRow';
import EventTag from '../../../components_cards/tags/EventTag';
import CreatorTag from '../../../components_cards/tags/CreatorTag';
import CompletedTag from '../../../components_cards/tags/_resource/CompletedTag';
import CancelledTag from '../../../components_cards/tags/_resource/CancelledTag';
import ExpiredTag from '../../../components_cards/tags/_resource/ExpiredTag';

export default function Tags(props) {
  const { event } = props;

  return (
    <TagRow>
      <EventTag />
      <CreatorTag />
      <CompletedTag resource={event} />
      <CancelledTag resource={event} />
      <ExpiredTag resource={event} />
    </TagRow>
  );
}
