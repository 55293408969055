import React from 'react';

export default function Header(props) {
  return (
    <div className="flex flex-col">
      <div className="mb-6">
        <h5 className="text-3xl text-cs-gray-900 font-semibold text-left">
          What's your organization?
        </h5>
        <h6 className="font-normal mt-2 text-cs-gray-800 text-left text-lg mb-4">
          <span className="block mb-2">
            Tell us the organization you belong to that is most relevant to your reason for joining us.
          </span>
          <span className="block mb-2">
            This could be your school, employer, or volunteer organization.
          </span>
          <span className="block">
            Don't have an organization? No worries - you can always add this later.
          </span>
        </h6>
      </div>
    </div>
  );
};
