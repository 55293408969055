import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import validators from '../../utils/validators';
import Overlay from '../../forms/_common/Overlay';
import FormErrors from '../../forms/_common/FormErrors';
import RequestError from '../../forms/_common/RequestError';
import ErrorMessage from '../../forms/_common/ErrorMessage';
import SuccessMessage from '../../forms/_common/SuccessMessage';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import Form from './Form';
import { useQueryMutation } from '../../../hooks/@lore/query-connect';

const responseMap = {
  [`I'm swamped right now, ask me later.`]: `Sorry, I'm swamped right now, ask me later.`,
  [`The specified days/times don't fit my schedule.`]: `Sorry, the specified days/times don't fit my schedule.`,
  [`The time needed to complete is more than I can give right now.`]: `Sorry, the time needed to complete is more than I can give right now.`,
  [`I don't have the needed skills or experience for this project.`]: `Sorry, I don't have the needed skills or experience for this project.`,
  [`This is too far away from my home/work.`]: `Sorry, this is too far away from my home/work.`,
  [`I'm not interested in volunteering my time in this way.`]: `Sorry, I'm not interested in volunteering my time in this way.`
};

export default function ProjectDeclineDialog(props) {
  const { project, question } = props;

  const [data, setData] = useState({
    reason: ''
  });
  const [showErrors, setShowErrors] = useState(false);

  const mutation = useQueryMutation('question.update', question);
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      props.onCancel();
      props.onSubmit && props.onSubmit(request);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate({
      response: 'Decline',
      responseMessage: responseMap[data.reason] || data.reason
    });
  }

  function onChange(name, value) {
    const nextData = _.merge({}, data);
    nextData[name] = value;
    setData(nextData);
  }

  onChange = onChange.bind(data);

  return (
    <DialogTemplate className="w-screen max-w-screen-sm">
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={{
          reason: [validators.isRequired]
        }}>
          {({errors, hasError}) => (
            <Form
              data={data}
              errors={errors}
              showErrors={showErrors}
              onChange={onChange}
              onSubmit={() => hasError ? setShowErrors(true) : onSubmit()}
              onCancel={props.onCancel}
              alert={error ? (
                <RequestError request={request}/>
              ) : showErrors ? (
                <ErrorMessage>
                  Please select a reason.
                </ErrorMessage>
              ) : (false && success) ? (
                <SuccessMessage>
                  Idea created.
                </SuccessMessage>
              ) : null}
            />
          )}
        </FormErrors>
      </Overlay>
    </DialogTemplate>
  );
};
