import React from 'react';
import { useConnect } from '@lore/query-connect';
import { PayloadStates } from '@lore/utils';
import ParentLayout from '../messages/Layout';
import Loader from '../../components/Loader';
import useRouter from '../../hooks/useRouter';
import EmptyState from '../../components/EmptyState';
import Launcher from './Launcher';

export default function Layout(props) {
  const { match, location } = useRouter();

  const user = useConnect('user.byId', {
    id: Number(match.params.userId)
  });

  if (user.state === PayloadStates.FETCHING) {
    return (
      <ParentLayout>
        <div className="page">
          <Loader />
        </div>
      </ParentLayout>
    );
  }

  if (user.state === PayloadStates.NOT_FOUND) {
    return (
      <ParentLayout>
        <div className="page">
          <EmptyState
            title="There is no conversation to be found here"
          />
        </div>
      </ParentLayout>
    );
  }

  return (
    <ParentLayout>
      {/*<MessageList*/}
      {/*  key={location.pathname}*/}
      {/*  user={user}*/}
      {/*/>*/}
      <Launcher
        key={location.pathname}
        user={user}
      />
    </ParentLayout>
  );
};
