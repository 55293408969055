import React from 'react';
import { PayloadStates } from '@lore/utils';
import Loader from '../../../components/Loader';
import { useConnect } from '../../../../hooks/@lore/query-connect';
import Card from '../../../components/Card';
import ComposableOfferHeader from '../../../components_cards/ComposableOfferHeader';
import TagRow from '../../../components_cards/tags/TagRow';
import OfferTag from '../../../components_cards/tags/OfferTag';
import OutlineButtonGray from '../../../components/Button/OutlineButton.gray';
import ParticipantTag from '../../../components_cards/tags/ParticipantTag';
import { DialogManager } from '../../../dialogs_new/DialogManager';
import useDialogLauncher from '../../../dialogs-routable/_hooks/useDialogLauncher';
import CompletedTag from '../../../components_cards/tags/_resource/CompletedTag';
import CancelledTag from '../../../components_cards/tags/_resource/CancelledTag';
import ExpiredTag from '../../../components_cards/tags/_resource/ExpiredTag';
import LazyCard from '../../../components/LazyCard';

export default function Claim(props) {
  const { claim } = props;

  const launch = useDialogLauncher();

  const offer = useConnect('offer.byId', {
    id: claim.data.offerId
  });

  function onViewOffer() {
    launch(DialogManager.ClaimView.Config(claim.id));
  }

  if (offer.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  return (
    <LazyCard divider={true}>
      <ComposableOfferHeader
        offer={offer}
        showTags={false}
        tags={(
          <TagRow>
            <OfferTag />
            <ParticipantTag />
            <CompletedTag resource={offer} />
            <CancelledTag resource={offer} />
            <ExpiredTag resource={offer} />
          </TagRow>
        )}
        showPartners={false}
      />
      <div className="flex justify-between p-4 bg-gray-50 flex-wrap gap-2">
        <div />
        <div className="flex flex-wrap gap-2">
          <OutlineButtonGray
            label="View offer"
            onClick={onViewOffer}
          />
        </div>
      </div>
    </LazyCard>
  );
}
