import React, { useState } from 'react';
import { UsersIcon } from '@heroicons/react/24/outline';
import { PayloadStates } from '@lore/utils';
import useRouter from '../../hooks/useRouter';
import Urls from '../profile/Urls';
import DataCard from './DataCard';
import useUserTags from '../../hooks/useUserTags';
import TagTypes from '../../constants/TagTypes';
import Guidances from '../setup/experience/Guidances';
import EditLink from './EditLink';
import useTags from '../../hooks/useTags';

export default function GuidanceCards(props) {
  const { user } = props;

  const { history } = useRouter();

  const guidances = useTags(TagTypes.Guidance);
  const userGuidances = useUserTags(user, TagTypes.Guidance);

  let subtitle = '';

  if (userGuidances.state === PayloadStates.FETCHING) {
    subtitle = '...';
  } else {
    subtitle = userGuidances.data.map(function(userGuidance, index) {
      return (
        <div key={index}>
          {userGuidance._tag.data.name}
        </div>
      );
    });
  }

  if (
    guidances.state === PayloadStates.FETCHING ||
    userGuidances.state === PayloadStates.FETCHING ||
    userGuidances.data.length === 0
  ) {
    return (
      <DataCard
        title="Professional Competencies"
        icon={UsersIcon}
        subtitle={subtitle || '(not provided)'}
        onClick={() => history.push(Urls(user).GUIDANCES)}
      />
    );
  }

  const filteredGuidances = {
    ...guidances,
    data: guidances.data.filter(function(guidance) {
      return !!_.find(userGuidances.data, function(userGuidance) {
        return userGuidance._tag.id === guidance.id;
      });
    })
  }

  return (
    <div className="space-y-4">
      <Guidances
        user={user}
        guidances={filteredGuidances}
        userGuidances={userGuidances}
        selectable={false}
        describable={false}
      />
      <EditLink
        title="Change professional competencies"
        onClick={() => history.push(Urls(user).GUIDANCES)}
      />
    </div>
  );
};
