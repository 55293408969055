import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { DateTime } from 'luxon';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import SolidButton from '../../../components/Button/SolidButton';
import FormTemplate2 from '../../_post-opportunity/FlowTemplate';
import DateField from '../../_post-opportunity/_fields/DateField.v2';
import FormSection from '../../_post-opportunity/forms/FormSection';
import { useQueryMutation } from '@lore/query-connect';

Form.propTypes = {
  user: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func,
  onSkip: PropTypes.func
};

export default function Form(props) {
  const {
    user,
    project,
    navigation,
    onNext,
    onPrevious,
    onSkip,
    onExit,
    FormTemplate = FormTemplate2,
    buttonText = 'Next'
  } = props;

  const [data, setData] = useState({
    specificDate: project.data.specificDate || 'No',
    date: project.data.date || new Date().toISOString(),
    startDate: project.data.startDate || new Date().toISOString(),
    endDate: project.data.endDate || new Date().toISOString()
  });
  const [showErrors, setShowErrors] = useState(false);
  const [exit, setExit] = useState(false);

  const mutation = useQueryMutation('project.update', project);
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      exit ? onExit(data) : onNext(data);
    }
  }, [success]);

  function onSubmit() {
    if (data.specificDate === 'Yes') {
      mutation.mutate({
        ...data,
        startDate: data.date,
        endDate: data.date
      });
    } else {
      mutation.mutate(data);
    }
  }

  function onSubmitAndExit() {
    onSubmit();
    setExit(true);
  }

  // function onChange(name, value) {
  //   setData(_.merge({}, data, {
  //     [name]: value
  //   }));
  // }

  function onChange(name, value) {
    const nextData = _.merge({}, data);
    nextData[name] = value;

    if (
      name === 'startDate' &&
      (!nextData.endDate || DateTime.fromISO(nextData.endDate).diff(DateTime.fromISO(value)).milliseconds < 0)
    ) {
      nextData.endDate = value;
    }

    if (
      name === 'endDate' &&
      (!nextData.startDate || DateTime.fromISO(nextData.startDate).diff(DateTime.fromISO(value)).milliseconds > 0)
    ) {
      nextData.startDate = value;
    }

    setData(nextData);
  }

  return (
    <Overlay isVisible={saving}>
      <FormErrors data={data} validators={{
        // firstName: [validators.isRequired],
        // lastName: [validators.isRequired]
      }}>
        {({ errors, hasError }) => {
          return (
            <FormTemplate
              onExit={() => hasError ? setShowErrors(true) : onSubmitAndExit()}
              onPrevious={onPrevious}
              navigation={navigation}
              alert={error ? (
                <RequestError request={request} />
              ) : showErrors ? (
                <ErrorMessage>
                  Please fill in all required fields.
                </ErrorMessage>
              ) : null}
              body={(
                <div className="space-y-10">
                  {data.specificDate === 'Yes' ? (
                    <FormSection
                      title="What is the anticipated date?"
                    >
                      <DateField
                        key={`${data.date}`}
                        // label="What is the anticipated date?"
                        date={data.date}
                        error={showErrors && errors.date}
                        onChange={(value) => {
                          return onChange('date', value);
                        }}
                      />
                    </FormSection>
                  ) : (
                    <>
                      <FormSection
                        title="What is the earliest date this could take place?"
                      >
                        <DateField
                          key={`${data.startDate}`}
                          // label="What is the anticipated date?"
                          date={data.startDate}
                          error={showErrors && errors.startDate}
                          onChange={(value) => {
                            return onChange('startDate', value);
                          }}
                        />
                      </FormSection>
                      <FormSection
                        title="What is the latest date this could take place?"
                      >
                        <DateField
                          key={`${data.endDate}`}
                          // label="What is the anticipated date?"
                          date={data.endDate}
                          error={showErrors && errors.endDate}
                          onChange={(value) => {
                            return onChange('endDate', value);
                          }}
                        />
                      </FormSection>
                    </>
                  )}
                </div>
              )}
              footer={(
                <>
                  <SolidButton onClick={() => hasError ? setShowErrors(true) : onSubmit()}>
                    {buttonText}
                  </SolidButton>
                </>
              )}
            />
          );
        }}
      </FormErrors>
    </Overlay>
  );
};
