import React from 'react';

export default function InactiveBadge(props) {
  const { isCentered = true } = props;

  return (
    <div className={`flex ${isCentered ? 'justify-center' : ''} items-center`}>
      {/*<div className="p-1 text-white bg-cs-yellow-500 rounded-full z-40 shadow">*/}
      {/*  <GlobeAltIcon className="h-6 w-6" aria-hidden="true" />*/}
      {/*</div>*/}
      <div className="text-xs text-cs-gray-50 bg-cs-gray-500 py-1 px-3 h-6 leading-tight rounded-md shadow-sm min-w-fit">
        Inactive
      </div>
    </div>
  );
}
