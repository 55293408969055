import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Overlay from '../../../forms/_common/Overlay';
import FormErrors from '../../../forms/_common/FormErrors';
import RequestError from '../../../forms/_common/RequestError';
import ErrorMessage from '../../../forms/_common/ErrorMessage';
import FormTemplate from '../../_post-opportunity/FlowTemplate';
import { useQueryMutation } from '../../../../hooks/@lore/query-connect';
import Activity from '../../setup/activities/Activity';

Activities.propTypes = {
  user: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrevious: PropTypes.func,
  onSkip: PropTypes.func.isRequired
};

export default function Activities(props) {
  const {
    user,
    offer,
    activities,
    navigation,
    onNext,
    onPrevious,
    onSkip
  } = props;

  const [data, setData] = useState(_.pick(offer.data, [
    'activityId'
  ]));
  const [showErrors, setShowErrors] = useState(false);

  const mutation = useQueryMutation('offer.update', offer);
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      onNext(data);
    }
  }, [success]);

  function onSubmit(activity) {
    if (activity) {
      mutation.mutate({
        activityId: activity.id
      });
      setData({
        activityId: activity.id
      });
    } else {
      onSkip();
    }
  }

  function onChange(name, value) {
    setData(_.merge({}, data, {
      [name]: value
    }));
  }

  return (
    <Overlay isVisible={saving}>
      <FormErrors data={data} validators={{
        // firstName: [validators.isRequired],
        // lastName: [validators.isRequired]
      }}>
        {({ errors, hasError }) => {
          return (
            <FormTemplate
              onPrevious={onPrevious}
              navigation={navigation}
              // title="Step 2: Activity Type"
              // subtitle="I'm ready to access my community's unique expertise. I'm looking to engage around the following activity..."
              alert={error ? (
                <RequestError request={request} />
              ) : showErrors ? (
                <ErrorMessage>
                  Please fill in all required fields.
                </ErrorMessage>
              ) : null}
              body={(
                <div className="space-y-3">
                  {/*<div className="text-xl font-semibold text-cs-gray-900">*/}
                  {/*  Choose ONE Activity Type*/}
                  {/*</div>*/}
                  <div className="grid grid-cols-1 gap-3">
                    {_.sortBy(activities.data, function(activity) {
                      return activity.data.name;
                    }).map(function(activity) {
                      return (
                        <Activity
                          key={activity.id}
                          activity={activity}
                          selected={offer.data.activityId === activity.id}
                          // activityTypes={activityTypes}
                          // activityActivityTypes={activityActivityTypes}
                          onClick={() => onSubmit(activity)}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
              footer={(
                <>
                  {/*<div className="text-cs-orange-500 underline cursor-pointer mt-6 sm:mt-0 mr-0 sm:mr-12" onClick={onSkip}>*/}
                  {/*  Skip this step*/}
                  {/*</div>*/}
                  {/*<PrimaryButton onClick={() => hasError ? setShowErrors(true) : onSubmit()}>*/}
                  {/*  Next*/}
                  {/*</PrimaryButton>*/}
                </>
              )}
            />
          );
        }}
      </FormErrors>
    </Overlay>
  );
};
