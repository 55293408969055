import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import validators from '../../utils/validators';
import Overlay from '../../forms/_common/Overlay';
import FormErrors from '../../forms/_common/FormErrors';
import RequestError from '../../forms/_common/RequestError';
import ErrorMessage from '../../forms/_common/ErrorMessage';
import SuccessMessage from '../../forms/_common/SuccessMessage';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import Confirmation from './Confirmation';
import Form from './Form';
import Congratulations from './Congratulations';
import Classify from './Classify';
import Finish from './Finish';
import usePageRouter from '../../dialogs-routable/_hooks/usePageRouter';
import { useQueryMutation } from '../../../hooks/@lore/query-connect';

ProjectCloseDialog.propTypes = {
  project: PropTypes.object.isRequired
};

export default function ProjectCloseDialog(props) {
  const { project } = props;

  const { history } = usePageRouter();

  const [data, setData] = useState({
    completed: project.data.completed,
    cancelled: project.data.cancelled,
    cancelledMessage: project.data.cancelledMessage
  });
  const [showErrors, setShowErrors] = useState(false);
  const [step, setStep] = useState(1);

  const mutation = useQueryMutation('project.update', project);
  const { saving, success, error, request } = mutation;

  useEffect(() => {
    if (success) {
      // props.onCancel();
      props.onSubmit && props.onSubmit(request);
    }
  }, [success]);

  function onSubmit() {
    mutation.mutate(data, {
      // onSuccess: payload => project._update(payload)
    });
  }

  function onChange(name, value) {
    const nextData = _.merge({}, data);
    nextData[name] = value;

    if (name === 'completed' && value === true) {
      nextData.cancelled = false;
      nextData.cancelledMessage = '';
    }

    if (name === 'cancelled' && value === true) {
      nextData.completed = false;
    }

    setData(nextData);
  }

  function redirectToEvaluation(evaluation) {
    props.onCancel();
    history.push(`/evaluation-project-educator/${evaluation.id}`);
  }

  return (
    <DialogTemplate className="w-screen max-w-screen-md">
      <Overlay isVisible={saving}>
        <FormErrors data={data} validators={data.cancelled ? {
          cancelledMessage: [validators.isRequired]
        } : {}}>
          {({errors, hasError}) => {
            if (step === 3) {
              return (
                <Classify
                  project={project}
                  onCancel={props.onCancel}
                  onPrevious={() => setStep(2)}
                  onNext={() => setStep(4)}
                />
              );
            }

            if (step === 4) {
              return (
                <Finish
                  project={project}
                  onCancel={props.onCancel}
                  onPrevious={() => setStep(3)}
                  onNext={redirectToEvaluation}
                />
              );
            }

            if (success) {
              return project.data.completed ? (
                <Congratulations
                  project={project}
                  onCancel={props.onCancel}
                  onPrevious={() => mutation.reset()}
                  onNext={() => setStep(3)}
                />
              ) : (
                <Confirmation
                  project={project}
                  onCancel={props.onCancel}
                  onPrevious={() => mutation.reset()}
                />
              );
            }

            return (
              <Form
                data={data}
                errors={errors}
                showErrors={showErrors}
                onChange={onChange}
                onSubmit={() => hasError ? setShowErrors(true) : onSubmit()}
                onCancel={props.onCancel}
                alert={error ? (
                  <RequestError request={request}/>
                ) : showErrors ? (
                  <ErrorMessage>
                    Please fill in all required fields.
                  </ErrorMessage>
                ) : (false && success) ? (
                  <SuccessMessage>
                    Idea created.
                  </SuccessMessage>
                ) : null}
                project={project}
              />
            )
          }}
        </FormErrors>
      </Overlay>
    </DialogTemplate>
  );
};
