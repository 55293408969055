import React from 'react';
import SolidButton from '../components/Button/SolidButton';
import FormTemplate from '../dialogs_common/FormTemplate';
import DialogTemplate from '../dialogs_common/DialogTemplate';

export default function WelcomeDialog(props) {
  const { onCancel } = props;

  return (
    <DialogTemplate>
      <FormTemplate
        title="Your account is ready!"
        onClose={onCancel}
        body={(
          <>
            <div className="text-center text-cs-gray-500">
              <p>
                Thanks for setting up your account.
              </p>
              <p>
                We've started to build your profile with the basic information you gave
                us, but now is a great time to add a photo and finish your profile so other
                community members can get to know you better.
              </p>
            </div>
          </>
        )}
        footer={(
          <SolidButton onClick={onCancel}>
            OK
          </SolidButton>
        )}
      />
    </DialogTemplate>
  );
};
