import React from 'react';
import PropTypes from 'prop-types';
import { OutlineButton, SolidButton } from '../../components/Button';
import FormTemplate from '../../dialogs_common/FormTemplate';
import { ClipboardDocumentListIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import Setting from '../project.visibility/Setting';
import FormTemplateButtonFooter from '../../dialogs_common/FormTemplateButtonFooter';

Form.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  showErrors: PropTypes.bool,
  alert: PropTypes.node,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

Form.defaultProps = {
  // data: {},
  errors: {},
  showErrors: false,
  onChange: () => {}
};

export default function Form(props) {
  const {
    data,
    errors,
    showErrors,
    alert,
    onChange,
    onSubmit,
    onCancel
  } = props;

  return (
    <FormTemplate
      icon={(
        <div className="flex justify-center mb-4">
          <EyeIcon className="text-cs-orange-500 h-12 w-12" />
        </div>
      )}
      title="Event Visibility"
      subtitle={(
        <>
          The highlighted option reflects the current visibility setting.
        </>
      )}
      onClose={onCancel}
      alert={alert}
      body={(
        <>
          <div className="grid grid-cols-1 sm:grid-cols-1 gap-3">
            <Setting
              icon={ClipboardDocumentListIcon}
              title="Visible"
              description={(
                <>
                  Your event will be shared with others on the platform and they'll be able
                  to find it and express interest. You can still edit it at any time, so don't
                  be afraid to share your idea!
                </>
              )}
              onClick={() => onChange('published', true)}
              selected={data.published === true}
            />
            <Setting
              icon={EyeSlashIcon}
              title="Hidden"
              description={(
                <>
                  Your event will be hidden from others. You'll still be able to invite others
                  to connect, but no one will discover your event on their own. Only those you
                  invite will learn about it.
                </>
              )}
              onClick={() => onChange('published', false)}
              selected={data.published === false}
            />
          </div>
        </>
      )}
      footer={(
        <FormTemplateButtonFooter>
          <OutlineButton onClick={onCancel}>
            Cancel
          </OutlineButton>
          <SolidButton onClick={onSubmit}>
            Change Visibility
          </SolidButton>
        </FormTemplateButtonFooter>
      )}
    />
  );
}
