import React from 'react';
import PropTypes from 'prop-types';
import { ArrowLeftIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

FormTemplate.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  alert: PropTypes.node,
  body: PropTypes.node,
  footer: PropTypes.node,
  onClose: PropTypes.func,
  onPrevious: PropTypes.func,
  showClose: PropTypes.bool
};

FormTemplate.defaultProps = {
  showClose: true
};

export default function FormTemplate(props) {
  const {
    header,
    navigation,
    title,
    subtitle,
    alert,
    body,
    footer,
    onClose,
    onPrevious,
    className,
    showClose
  } = props;

  return (
    <div className={classNames(
      'px-6 py-8 space-y-8',
      className
    )}>
      {header}
      <div className="space-y-4">
        {navigation}
        <div className="space-y-2">
          <h5 className="text-3xl text-cs-gray-900 font-semibold text-center">
            {title}
          </h5>
          {subtitle && (
            <h6 className="text-cs-gray-500 text-center">
              {subtitle}
            </h6>
          )}
        </div>
      </div>
      {alert}
      {body}
      {footer}
    </div>
  );
}
