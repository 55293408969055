import React from 'react';
import { CalendarIcon } from '@heroicons/react/24/outline';
import useRouter from '../../hooks/useRouter';
import Urls from '../event-create/Urls';
import DataCard from '../profile-edit/DataCard';
import { useUser } from '@lore/auth';
import { formatDate, formatDateRange } from '../../utils/dateTimeUtils';
import usePageRouter from '../../dialogs-routable/_hooks/usePageRouter';

export default function Date(props) {
  const { event } = props;
  const { history } = usePageRouter();

  const currentUser = useUser();

  const subtitle = event.data.specificDate === 'Yes' ?
    formatDate(event.data.date, currentUser.data.timezone) :
    formatDateRange(event.data.startDate, event.data.endDate, currentUser.data.timezone);

  return (
    <DataCard
      title="Date"
      icon={CalendarIcon}
      subtitle={subtitle}
      onClick={() => history.push(Urls(event).DATE)}
    />
  );
};
