import React from 'react';
import { PayloadStates } from '@lore/utils';
import Loader from '../../components/Loader';
import useMissingFields from '../offer-manage/useMissingFields';
import EmptyState from '../../components/EmptyState/EmptyState.legacy';
import Educators from '../people-educators/Educators';

export default function Layout(props) {
  const { offer, router } = props;

  const { missingFields } = useMissingFields(offer);

  if (missingFields.state === PayloadStates.FETCHING) {
    return (
      <Loader />
    );
  }

  if (missingFields.data.length > 0) {
    return (
      <EmptyState
        title="Your offer is missing required fields."
        subtitle="You must provide this information before you can invite educators."
      />
    );
  }

  return (
    <div className="space-y-8">
      <Educators
        offer={offer}
        router={router}
      />
    </div>
  );
};
