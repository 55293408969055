import React from 'react';
import PropTypes from 'prop-types';
import DialogTemplate from '../../dialogs_common/DialogTemplate';
import Offer from '../../pages/offer/Offer'
import CloseDialogButton from '../../dialogs_common/CloseDialogButton';
import Banner from './Banner';

OfferViewDialog.propTypes = {
  offer: PropTypes.object.isRequired
};

export default function OfferViewDialog(props) {
  const { offer } = props;

  return (
    <DialogTemplate className="max-w-screen-lg">
      <CloseDialogButton onCancel={props.onCancel} />
      <div className="px-6 pt-16 pb-6">
        <div className="space-y-8">
          <Banner offer={offer} />
          <Offer offer={offer} />
        </div>
      </div>
    </DialogTemplate>
  );
};
